import Fields1 from './Fields1'
import Fields3 from './Fields3'
import { FormControls } from '../../../../components/FormUtilities'
import Fields2 from './Fields2'

export default function Information() {
    return (
        <FormControls>
            <Fields1 />
            <Fields2 />
            <Fields3 />
        </FormControls>
    )
}
