import { createContext } from 'react'
import CU from '../config/types/cu'

export interface AuthData {
    user: {
        id: number
        companyId: number
        fullName: string
        avatar: string
        email: string
    }
    expireIn: string
    CUs: CU[]
}

export const initialAuthContext = {
    user: {
        id: null,
        companyId: null,
        fullName: '',
        avatar: '',
        email: ''
    },
    expireIn: null,
    CUs: []
}

const AuthContext = createContext<AuthData>(initialAuthContext)

export default AuthContext
