import ListFormControl from '../../sections/ListForm/ListFormControl'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import {
    FormAddress,
    FormAgent,
    FormContact,
    TableContact,
    TableAddress,
    StoragePage
} from './types'

export const storagePage: StoragePage = {
    agent: null,
    mode: 'creation'
}

export const formAgent = new FormEvents<FormAgent>(Keys.FORM_AGENT, {
    country: null,
    type_agents: [],
    name: '',
    fiscal_address: ''
})
formAgent.setParams('country', { limit: 10 }, false)

export const formContact = new FormEvents<FormContact>(Keys.FORM_CONTACT, {
    name: '',
    position: '',
    email: '',
    phone_number: ''
})

export const formAddress = new FormEvents<FormAddress>(Keys.FORM_ADDRESS, {
    address: '',
    country: null,
    locate: ''
})
formAddress.setParams('country', { limit: 10 }, false)

export const tableContacts = new ListFormControl<TableContact>(
    Keys.FORM_CONTACT,
    ['Nombres', 'Principal', 'Cargo', 'Email', 'Teléfono', 'Acciones']
)

export const tableAddress = new ListFormControl<TableAddress>(
    Keys.TABLE_ADDRESS,
    ['Lugar de retiro', 'País', 'Principal', 'Dirección', 'Acciones']
)
