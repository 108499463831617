import { LOGISTICAL_CONCEPT__DUTY } from '../../../../../config/constants'
import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowDuty(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [{ value: 'Aranceles' }]
    data.forEach(d => {
        const duty_cost = d.nationalization_costs.find(
            c => c.logistical_concept_id === LOGISTICAL_CONCEPT__DUTY
        )
        row.push({
            value: duty_cost ? duty_cost.original_cost : 0,
            format: '0.00'
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: duty_cost
                ? duty_cost.local_cost + duty_cost.setting_local_cost
                : 0,
            format: '0.00'
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
