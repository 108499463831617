import {
    FormControl,
    FormControls,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import SearchResults from '../../../../components/SearchResult'
import Product from '../../../../models/Product'
import { formPresentation } from '../../storage'
import TableProducts from './TableProducts'

function Name() {
    const { props, getError } = formPresentation.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe una marca con el mismo nombre'
    )
    return (
        <FormControl label="Nombre de grupo" col="span-6" required>
            <InputField
                {...props}
                placeholder="Ingresar nombre de grupo de presentación"
                autoFocus
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

function PresentationProducts() {
    const { props, data: products } = formPresentation.useResults<Product>(
        'presentationProducts',
        () => `/v5.6/apis/group_presentations/new_presentation_potentials`
    )

    return (
        <FormControl label="Agregar presentaciones relacionadas" col="span-6">
            <SearchResults {...props} placeholder="Seleccionar producto">
                <IterateOptions
                    data={products}
                    label="description"
                    label2="code"
                />
            </SearchResults>
        </FormControl>
    )
}

export default function Form() {
    return (
        <>
            <FormControls>
                <Name />
                <PresentationProducts />
            </FormControls>
            <TableProducts />
        </>
    )
}
