import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import SelectTouch from '../../../components/SelectTouch'
import Departament from '../../../models/Departament'
import { formRoles } from '../storage'

function Rol() {
    const { props, getError } = formRoles.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un rol con el mismo nombre'
    )

    return (
        <FormControl label="Rol" required className="mb-4">
            <InputField
                placeholder="Ingresar nombre"
                autoFocus
                {...props}
                helper={props.helper || helper}
            />
        </FormControl>
    )
}

function Department() {
    const { props, data } = formRoles.useSelect<Departament>(
        'department_id',
        '/v5.6/apis/departments?is_enabled=true'
    )

    return (
        <FormControl label="Departamento" required>
            <SelectTouch
                placeholder={'Seleccionar un departamento'}
                label="Seleccione un departamento"
                {...props}
            >
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function Form() {
    return (
        <>
            <Rol />
            <Department />
        </>
    )
}
