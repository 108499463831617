import moment from 'moment'
import { companySelected } from '../storage'
import { GetInputPart, MinimalStowageItem } from '../type'

export default function GeneralInformation({ data }: { data: GetInputPart }) {
    const company = companySelected

    return (
        <div className="body__cards mb-4">
            <div className="column">
                <div className="card_info">
                    <h3>INFORMACIÓN GENERAL</h3>
                    <ul className="list_info">
                        <li>
                            <p>Usuario</p>
                            <p>
                                <span
                                    className={
                                        data.document.requesting_user.is_enabled
                                            ? ''
                                            : 'line-through'
                                    }
                                >
                                    {data.document.requesting_user.name}{' '}
                                    {data.document.requesting_user.lastname}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>Fecha de solicitud</p>
                            <p>
                                {moment(data.document.request_date)
                                    .tz(company.timezone)
                                    .format('DD/MM/YYYY')}
                            </p>
                        </li>
                        <li>
                            <p>Etiqueta</p>
                            <p>
                                <span
                                    className={
                                        !data.input_part_label.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {data.input_part_label.name}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>Motivo</p>
                            <p>{data.document.description || '-'}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <div className="card_info">
                    <h3>BODEGA DESTINO</h3>
                    <ul className="list_info">
                        <li>
                            <p>Bodega destino</p>
                            <p>
                                <span
                                    className={
                                        !data.minimal_stowage.stowage.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {data.minimal_stowage.stowage.name}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p> Sub bodega destino</p>
                            <p>{getSubStowageName(data.minimal_stowage)}</p>
                        </li>
                        <li>
                            <p> Almacén</p>
                            <p>
                                <span
                                    className={
                                        !data.company_warehouse.warehouse
                                            .is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {data.company_warehouse.warehouse.name}
                                </span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

function getSubStowageName(minimal_stowage: MinimalStowageItem) {
    if (minimal_stowage.order_sub_stowage) {
        return (
            <span
                className={
                    !minimal_stowage.order_sub_stowage.is_enabled
                        ? 'line-through'
                        : ''
                }
            >
                {minimal_stowage.order_sub_stowage.order.number +
                    ' - ' +
                    minimal_stowage.order_sub_stowage.order.client.name}
            </span>
        )
    } else if (minimal_stowage.gp_sub_stowage) {
        return (
            <span
                className={
                    !minimal_stowage.gp_sub_stowage.is_enabled
                        ? 'line-through'
                        : ''
                }
            >
                {minimal_stowage.gp_sub_stowage.purchase.code}
            </span>
        )
    } else {
        return <>{'-'}</>
    }
}
