import { Empty, FormControl } from '../../../../../components/FormUtilities'
import Option from '../../../../../components/Option'
import ReadOnly from '../../../../../components/ReadOnly'
import SelectTouch from '../../../../../components/SelectTouch'
import Textarea from '../../../../../components/Textarea'
import { formDispatch, storagePage } from '../../../storage'

export default function AddressFields() {
    const { props } = formDispatch.useSelect('address_select')
    const { props: propsInput } = formDispatch.useInput('address')
    const value = formDispatch.store().type_dispatch?.value || null

    if (value === '1') {
        return <AddressSelect props={props} />
    }

    if (value === '2' || value === '3') {
        return <AddressReadOnly props={propsInput} />
    }

    return <AddressInput props={propsInput} />
}

export function AddressSelect({ props }) {
    const { currentAgentWithdrawalAddresses } = storagePage
    return (
        <FormControl label="Dirección" required className="mb-2">
            <SelectTouch
                disabled
                {...props}
                placeholder="Seleccionar una dirección"
            >
                {currentAgentWithdrawalAddresses
                    .filter(
                        awa =>
                            awa.address.country_id ===
                            parseInt(formDispatch.store().country?.value)
                    )
                    .map(awa => (
                        <Option
                            key={awa.id}
                            label={awa.address.address}
                            value={awa.id.toString()}
                            icon={awa.is_main ? 'star' : 'label'}
                        />
                    ))}
                <Empty data={currentAgentWithdrawalAddresses} />
            </SelectTouch>
        </FormControl>
    )
}

export function AddressReadOnly({ props }) {
    return (
        <FormControl label="Dirección" required className="mb-2">
            <ReadOnly value={props.value} />
        </FormControl>
    )
}

export function AddressInput({ props }) {
    return (
        <FormControl label="Dirección" required className="mb-2">
            <Textarea
                disabled
                {...props}
                placeholder="Ingresar dirección"
                style={{ maxHeight: '100px' }}
            />
        </FormControl>
    )
}
