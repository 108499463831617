import FormEvents from '../../utilities/FormEvents'
import ListFormControl from '../../sections/ListForm/ListFormControl'
import { Keys } from './constants'
import { StoragePage, Form, TableProduct } from './types'
import { dataMemory } from '../../utilities/dataTools'

export const storagePage: StoragePage = {
    license: null,
    products: dataMemory()
}

export const formProductLicense = new FormEvents<Form>('PRODUCT_LICENSE', {
    products: []
})

export const tableProducts = new ListFormControl<TableProduct>(
    Keys.TABLE_PRODUCTS,
    ['Producto', 'Marca', 'Subcategoría', 'Acciones']
)
