import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import Table from './Table'
import Controls from './Controls'

export default function ProviderCatalog() {
    const pageState = useInitial()

    return (
        <Initial pageState={pageState}>
            <Panel>
                <PanelBody>
                    <Controls />
                    <Table />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
