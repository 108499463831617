export default function buildNumberFormat(
    number: number,
    minimumFractionDigits = 2
): string {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits }).format(
        parseFloat(number.toFixed(2))
    )
}

export function clearFractionNumber(number: string) {
    return Number(number.split(',').join(''))
}
