import { ChangeEvent } from 'react'
import Checkbox from '../../../../../../components/Checkbox'
import { LabelInline } from '../../../../../../components/FormUtilities'
import { formCostByProduct } from '../../storageStep3'
import { RCProps } from '../types'

export default function RC(props: RCProps) {
    function onChange(ev: ChangeEvent<HTMLInputElement>) {
        const checked = ev.target.checked
        const { rcs } = formCostByProduct.store()
        if (!checked) {
            const rcsFiltered = rcs.filter(rc => rc.value !== props.value)
            formCostByProduct.setValue('rcs', rcsFiltered)
        } else {
            if (!rcs.find(rc => rc.value === props.value)) {
                formCostByProduct.setValue('rcs', [
                    ...rcs,
                    { value: props.value, label: props.label }
                ])
            }
        }
    }

    return (
        <LabelInline label={props.label} className="mb-2">
            <Checkbox checked={props.checked} onChange={onChange} />
        </LabelInline>
    )
}
