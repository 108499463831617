import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { GetConsumerVoucher } from '../type'

export default function ConsumerVoucherProducts({
    data
}: {
    data: GetConsumerVoucher
}) {
    const total = data.consumer_voucher_products
        .filter(x => !x.deleted)
        .filter(x => x.is_enabled)
        .map(x => x.quantity * x.operation.average_unit_cost)
        .reduce((a, b) => a + b, 0)

    return (
        <div className="body__tables">
            <div className="column">
                <div className="card_info">
                    <h3>PRODUCTOS</h3>
                    <table className="table_info">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Código</th>
                                <th>Descripción</th>
                                <th>Presentación</th>
                                <th>Cantidad</th>
                                <th>Costo Promedio Unit.</th>
                                <th>Sub Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.consumer_voucher_products
                                .filter(x => !x.deleted)
                                .filter(x => x.is_enabled)
                                .map((item, i) => (
                                    <tr key={item.id}>
                                        <td>{i + 1}</td>
                                        <td>{item.product.code}</td>
                                        <td>{item.product.description}</td>
                                        <td>
                                            {item.product.presentation_product
                                                ? item.product
                                                      .presentation_product
                                                      .amount
                                                : '-'}
                                        </td>
                                        <td>{item.quantity}</td>
                                        <td>
                                            {
                                                data.company_warehouse.company
                                                    .currency.symbol
                                            }{' '}
                                            {buildNumberFormat(
                                                item.operation.average_unit_cost
                                            )}
                                        </td>
                                        <td>
                                            {
                                                data.company_warehouse.company
                                                    .currency.symbol
                                            }{' '}
                                            {buildNumberFormat(
                                                item.quantity *
                                                    item.operation
                                                        .average_unit_cost
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                        <tfoot className="tfoot">
                            <tr>
                                <td
                                    colSpan={6}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Total
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {
                                        data.company_warehouse.company.currency
                                            .symbol
                                    }{' '}
                                    {buildNumberFormat(total)}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}
