import { useHistory } from 'react-router'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import Rules from '../../../components/Rules'
import SearchField from '../../../components/SearchField'
import useStorage from '../../../hooks/useStorage'
import { controlList, filterForm, storagePage } from '../storage'
import FilterForm from './FilterForm'
import { Option as OptionType } from '../../../config/types/component'
import { formInformation } from './../../Requisition/storage'
import { render, useRender } from 'redity'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { setCompanySelected } from '../../../utilities/controlStorage'

function CompanyField() {
    const { props } = controlList.useFilter('company_id')
    const { companiesFromSession } = useStorage()

    function handleChange(option: OptionType) {
        controlList.filter.params.page = 1
        controlList.filter.deleteFilter('stowage_id')
        filterForm.setValue('stowage_id', null)
        controlList.renderForm()
        const company = companiesFromSession.find(
            _company => _company.id === parseInt(option.value)
        )
        if (company.is_enabled) {
            formInformation.setValue('company', option)
            storagePage.company_enabled = true
        } else {
            formInformation.setValue('company', null)
            storagePage.company_enabled = false
        }
        setCompanySelected(company)
        storagePage.timezone = company.timezone
        props.onChange(option)
        render('button_new')
    }

    return (
        <Dropdown
            {...props}
            onChange={handleChange}
            label="Compañía"
            showLabel={false}
            clear={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    name: company.name,
                    image_url: company.country.image_url,
                    id: company.id,
                    disabled: !company.is_enabled
                }))}
                label="name"
                image="image_url"
                strikeOut="disabled"
            />
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()

    return (
        <SearchField
            {...props}
            placeholder="Buscar por código de requisición o pedido"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

function ButtonNew() {
    const { push } = useHistory()
    useRender('button_new')
    return (
        <Button
            disabled={!storagePage.company_enabled}
            color="accent"
            className=""
            size="sm"
            onClick={() => push('/requisitions/creation')}
        >
            Nuevo
        </Button>
    )
}

export default function FilterRequisitions() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <CompanyField />
                    <FilterForm />
                </FlexLeft>
                <FlexRight>
                    <Rules cu="CU198">
                        <ButtonNew />
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
