import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import { useParams } from 'react-router'
import useStorage from '../../hooks/useStorage'
import {
    formInputPart,
    productsControl,
    reqProducts,
    reqStowage,
    reqSubStowage,
    reqWarehouse,
    reqWorkingPeriod,
    storagePage
} from './storage'
import { GETInputPart } from './types'
import useInitialEdition from './useInitialEdition'
import moment from 'moment'

export default function useInitial() {
    const { input_part_id } = useParams<{ input_part_id?: string }>()
    const [pageState, setState] = useState<StateCase>(StateCase.LOADING)
    const { companiesFromSession } = useStorage()
    const loadEdition = useInitialEdition()

    storagePage.companies = companiesFromSession

    let title = 'Nuevo parte de entrada'
    if (input_part_id) {
        storagePage.mode = 'edition'
        title = 'Editar parte de entrada'
    }

    async function load() {
        if (storagePage.mode !== 'creation') {
            const response = await get<GETInputPart>(
                `/v5.6/apis/input_parts/${input_part_id}`
            )

            if (response.error) {
                setState(StateCase.ERROR)
                return
            }
            storagePage.inputPart = response.data.result
            storagePage.timezone = companiesFromSession.find(
                c => c.id === response.data.result.company_warehouse.company_id
            ).timezone
            loadEdition(response.data.result)
        } else {
            if (formInputPart.store().company) {
                storagePage.symbol = companiesFromSession.find(
                    c => c.id === parseInt(formInputPart.store().company.value)
                ).local_currency.symbol

                storagePage.timezone = companiesFromSession.find(
                    c => c.id === parseInt(formInputPart.store().company.value)
                ).timezone

                reqWarehouse.setQuery(
                    'company_id',
                    formInputPart.store().company.value
                )
                reqStowage.setQuery(
                    'company_id',
                    formInputPart.store().company.value
                )
                reqWorkingPeriod.setParam(
                    'company_id',
                    formInputPart.store().company.value
                )
            } else {
                const companies = companiesFromSession.filter(
                    _company => _company.memory
                )
                const company = companies[0]
                formInputPart.setValue('company', {
                    label: company.name,
                    value: company.id.toString(),
                    image: company.country.image_url
                })

                storagePage.symbol = companiesFromSession.find(
                    c => c.id === company.id
                ).local_currency.symbol
                storagePage.timezone = companiesFromSession.find(
                    c => c.id === company.id
                ).timezone

                reqWarehouse.setQuery('company_id', company.id)
                reqStowage.setQuery('company_id', company.id)
                reqWorkingPeriod.setParam('company_id', company.id)
            }
            const date = moment().tz(storagePage.timezone).parseZone()
            formInputPart.setValue('request_date', date.toISOString(true))

            reqProducts.setQuery(
                'operation_date',
                moment(formInputPart.store().request_date).format('YYYY-MM-DD')
            )
        }

        setState(StateCase.SUCCESS)
    }

    useEffect(() => {
        reqWarehouse.setQuery('is_enabled', true)
        load()
        return () => {
            storagePage.inputPart = null
            storagePage.mode = 'creation'
            storagePage.current_type_stowage_id_origin = null
            formInputPart.init()
            formInputPart.clearProps()
            reqWarehouse.init()
            reqStowage.init()
            reqSubStowage.init()
            reqWorkingPeriod.init()
            reqProducts.init()
            productsControl.setList([])
        }
    }, [])

    return {
        pageState,
        title
    }
}
