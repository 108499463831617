import moment from 'moment'
import buildCompanyName from '../../utilities/buildCompanyName'
import { parseDriveFile } from '../../utilities/fileTools'
import { get } from '../../utilities/http'
import {
    formPurchaseReceipt,
    productsControl,
    reqProducts,
    reqPurchase,
    reqWorkingPeriod,
    storagePage
} from './storage'
import { GETProductsForTraspase, GetReception } from './types'

export default function useInitialEdition() {
    return async function load(reception: GetReception) {
        const {
            company_warehouse,
            type_reception,
            document,
            purchase,
            reception_products,
            exchange_rate,
            provider_local_amount,
            files,
            number_shipment,
            reception_date,
            number_invoice_final,
            number_invoice_shipping,
            invoice_final_date,
            invoice_shipping_date,
            id
        } = reception

        const company = company_warehouse.company
        const warehouse = company_warehouse.warehouse

        formPurchaseReceipt.setValue('company', {
            label: buildCompanyName(company.name),
            value: company.id.toString(),
            image: warehouse.country.image_url
        })
        formPurchaseReceipt.setProps('company', { disabled: true })
        storagePage.timezone = company.timezone

        formPurchaseReceipt.setValue('warehouse', {
            label: warehouse.name,
            value: warehouse.id.toString()
        })
        formPurchaseReceipt.setProps('warehouse', { disabled: true })

        reqPurchase.setParam('company_id', company.id)

        formPurchaseReceipt.setValue('typeRC', {
            label: type_reception.name,
            value: type_reception.id.toString()
        })
        formPurchaseReceipt.setProps('typeRC', { disabled: true })
        formPurchaseReceipt.setProps('receiptDate', { disabled: true })

        formPurchaseReceipt.setValue('comment', document.description || '')
        formPurchaseReceipt.setValue('shipment', number_shipment || '')

        formPurchaseReceipt.setValue(
            'numberInvoiceShipping',
            number_invoice_shipping || ''
        )
        formPurchaseReceipt.setValue(
            'numberInvoiceFinal',
            number_invoice_final || ''
        )

        formPurchaseReceipt.setValue(
            'invoiceShippingDate',
            moment(invoice_shipping_date).tz('UTC').toISOString(true)
        )

        formPurchaseReceipt.setValue(
            'invoiceFinalDate',
            moment(invoice_final_date).tz('UTC').toISOString(true)
        )

        formPurchaseReceipt.setValue(
            'receiptDate',
            moment(reception_date).tz(storagePage.timezone).toISOString(true)
        )

        formPurchaseReceipt.setValue('purchase', {
            label: purchase.code,
            value: purchase.id.toString()
        })

        formPurchaseReceipt.setValue(
            'files',
            files.map(f => parseDriveFile(f))
        )

        formPurchaseReceipt.setValue('exchangeRate', exchange_rate.toString())
        formPurchaseReceipt.setValue(
            'providerLocalAmount',
            provider_local_amount.toFixed(2)
        )

        reqWorkingPeriod.setParam('company_id', company_warehouse.company_id)

        reqProducts.setParam('purchase_id', purchase.id)
        reqProducts.setQuery('reception_id', id)

        const response = await get<GETProductsForTraspase[]>(
            `/v5.6/apis/purchases/${purchase.id}/purchase_products_to_receive?to_create=false&reception_id=${id}`
        )

        const loadedList = response.data.result
            .filter(d => d.quantity - d.quantity_received_other_rcs > 0)
            .map(purchase_product => {
                const findedProduct = reception_products
                    .filter(reception_product => reception_product.is_enabled)
                    .find(
                        reception_product =>
                            purchase_product.id ===
                            reception_product.purchase_product_id
                    )

                if (findedProduct) {
                    return {
                        ...purchase_product,
                        quantity_requested: findedProduct.quantity.toString(),
                        confirmed: true,
                        helper: ''
                    }
                } else {
                    return {
                        ...purchase_product,
                        quantity_requested: '',
                        confirmed: false,
                        helper: ''
                    }
                }
            })

        productsControl.setList(loadedList)

        productsControl.renderMain()
    }
}
