import { Option } from '../../../config/types/component'
import useGet from '../../../hooks/useGet'
import Stowage from '../../../models/Stowage'
import {
    filterForm,
    reqStowageDestination,
    reqSubStowageDestination
} from '../storage'

export default function useStowageDestination() {
    const { props } = filterForm.useSelect<Stowage>('stowage_destination_id')

    const { data, props: propsSearch } = useGet<Stowage[]>(
        reqStowageDestination.observer(
            '/v5.6/apis/stowages/destination_stowages_for_traspase',
            reqStowageDestination.hasQuery('origin_stowage_id')
        ),
        []
    )

    function handleChange(option: Option) {
        if (option.value === '0') {
            filterForm.setProps('minimal_stowage_destination_id', {
                disabled: true
            })
            filterForm.setValue('minimal_stowage_destination_id', null)
        } else {
            const { type_stowage_id } = data.result.find(
                _stowage => _stowage.id === parseInt(option.value)
            )

            // Pedidos (P) o Remisionada (R) o Garantia Proveedor
            const isPR =
                type_stowage_id === 2 ||
                type_stowage_id === 11 ||
                type_stowage_id === 6
            if (isPR) {
                reqSubStowageDestination.setParam(
                    'destination_stowage_id',
                    option.value
                )
                filterForm.setProps('minimal_stowage_destination_id', {
                    disabled: false
                })
                reqSubStowageDestination.load()
            } else {
                filterForm.setProps('minimal_stowage_destination_id', {
                    disabled: true
                })
                filterForm.setValue('minimal_stowage_destination_id', null)
            }
            props.onChange(option)
        }
    }

    return {
        props,
        propsSearch,
        handleChange,
        data
    }
}
