import { FormControls } from '../../../../components/FormUtilities'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import Fields2 from './Fields2'
import Fields1 from './Fields1'
import TableStorage from './TableStorage'
import Alert from '../../../../components/Alert'
import Fields3 from './Fields3'
import Button from '../../../../components/Button'
import useSubmitNorms from '../../useSubmitNorms'
import { storagePage } from '../../storage'
import { useEffect } from 'react'
import setParamsSearchProducts from '../../utilities/setParamsSearchProducts'

export default function Step2() {
    const submitNorms = useSubmitNorms(storagePage.product?.id)
    useEffect(() => {
        setParamsSearchProducts(true)
    }, [])
    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Normas">
                    {storagePage.mode === 'edition' && (
                        <Button
                            size="sm"
                            color="accent"
                            onClick={() => submitNorms()}
                        >
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <FormControls>
                        <Fields1 />
                        <Fields2 />
                    </FormControls>
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Reglas de Almacenaje">
                    {storagePage.mode === 'edition' && (
                        <Button
                            size="sm"
                            color="accent"
                            onClick={() => submitNorms()}
                        >
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <Alert className="mb-2">
                        En esta sección se listarán los productos que no pueden
                        ser almacenados con el producto a crear.
                    </Alert>
                    <FormControls>
                        <Fields3 />
                    </FormControls>
                    <TableStorage />
                </PanelBody>
            </Panel>
        </>
    )
}
