import moment from 'moment'
import buildCompanyName from '../../utilities/buildCompanyName'
import { reqStowageDestination } from '../Traspases/storage'
import {
    formTraspase,
    reqWarehouse,
    storagePage,
    reqStowageOrigin,
    reqProducts,
    productsControl,
    reqWorkingPeriod
} from './storage'
import { GetTraspase, TableProductTraspase } from './types'

export default function useInitialEdition() {
    // const { companies } = useStorage()
    // const traspase = storagePage.traspase

    // return function initialize() {
    //     const company = companies.find(
    //         _company => traspase.company_warehouse.company_id === _company.id
    //     )
    //     formTraspase.setValue('company', {
    //         label: company.name,
    //         value: company.id.toString(),
    //         image: company.country.image_url
    //     })
    // }

    return function load(traspase: GetTraspase) {
        const {
            company_warehouse,
            origin_minimal_stowage,
            destination_minimal_stowage,
            document,
            classic_traspase_products
        } = traspase

        const company = company_warehouse.company
        formTraspase.setValue('company', {
            label: buildCompanyName(company.name),
            value: company.id.toString(),
            image: company.country.image_url
        })

        storagePage.symbol = storagePage.companies.find(
            c => c.id === company.id
        ).local_currency.symbol

        reqWarehouse.setQuery('company_id', company.id)
        reqStowageOrigin.setQuery('company_id', company.id)
        reqStowageDestination.setQuery('company_id', company.id)
        formTraspase.setProps('company', {
            disabled: true
        })

        const warehouse = company_warehouse.warehouse
        formTraspase.setValue('warehouse', {
            label: warehouse.name,
            value: warehouse.id.toString()
        })
        formTraspase.setProps('warehouse', { disabled: true })
        formTraspase.setValue(
            'date_request',
            moment(document.request_date)
                .tz(storagePage.timezone)
                .toISOString(true)
        )
        formTraspase.setProps('date_request', { disabled: true })
        reqProducts.setQuery(
            'operation_date',
            moment(document.request_date)
                .tz(storagePage.timezone)
                .format('YYYY-MM-DD')
        )

        const ori_stowage = origin_minimal_stowage.stowage
        formTraspase.setValue('stowage_origin', {
            label: ori_stowage.name,
            value: ori_stowage.id.toString()
        })
        formTraspase.setProps('stowage_origin', { disabled: true })
        const des_stowage = destination_minimal_stowage.stowage
        formTraspase.setValue('stowage_destination', {
            label: des_stowage.name,
            value: des_stowage.id.toString()
        })
        formTraspase.setProps('stowage_destination', { disabled: true })

        const listStowages = [2, 6, 11]
        formTraspase.setProps('sub_stowage_origin', { disabled: true })
        if (
            listStowages.includes(
                origin_minimal_stowage.stowage.type_stowage_id
            )
        ) {
            formTraspase.setValue('sub_stowage_origin', {
                value: origin_minimal_stowage.id.toString(),
                label: origin_minimal_stowage.order_sub_stowage.order.number
            })
        }
        formTraspase.setProps('sub_stowage_destination', { disabled: true })
        if (
            listStowages.includes(
                destination_minimal_stowage.stowage.type_stowage_id
            )
        ) {
            formTraspase.setValue('sub_stowage_destination', {
                value: destination_minimal_stowage.id.toString(),
                label: destination_minimal_stowage.order_sub_stowage.order
                    .number
            })
        }

        formTraspase.setValue('description', document.description)

        const logicalProductForTraspase: TableProductTraspase[] =
            classic_traspase_products.map(ctp => ({
                id: ctp.traspase_product.id,
                name: ctp.traspase_product.product.description,
                code: ctp.traspase_product.product.code,
                logical_product_id:
                    ctp.traspase_product.output_operation.logical_product_id,
                amount_to_transfer: ctp.traspase_product.quantity.toString(),
                quantity_available:
                    ctp.traspase_product.output_operation.logical_product
                        .max_quantity_to_move + ctp.traspase_product.quantity
            }))
        productsControl.setList(logicalProductForTraspase)
        storagePage.current_traspase_products = classic_traspase_products
            .map(ctp => ctp.traspase_product)
            .filter(tp => tp.is_enabled && !tp.deleted)
        const options = logicalProductForTraspase.map(lpft => ({
            label: lpft.name,
            value: lpft.logical_product_id.toString()
        }))
        formTraspase.setValue('traspase_products', options)
        formTraspase.setProps('traspase_products', { disabled: false })
        reqWorkingPeriod.setParam('company_id', company.id)
        reqProducts.setQuery(
            'origin_minimal_stowage_id',
            origin_minimal_stowage.id
        )
        // reqProducts.setQuery(
        //     'not_logical_product_ids',
        //     options.map(option => option.value)
        // )
    }
}
