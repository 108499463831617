export default function WifiOff(props: any) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 46 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.167 3.792L5.104.853l35.354 35.354-2.937 2.938-14.75-14.75a14.58 14.58 0 00-10.188 4.291l-4.166-4.166c2.645-2.625 5.812-4.396 9.187-5.292l-4.667-4.667a26.352 26.352 0 00-8.687 5.792L.083 16.187a32.713 32.713 0 018.438-6.041L2.167 3.79zm43.75 12.395l-4.167 4.167c-5.23-5.23-12.125-7.813-19-7.77l-5.375-5.376c10.062-1.75 20.77 1.209 28.541 8.98zm-16.084 3.48c2.833 1 5.5 2.604 7.75 4.854l-1.458 1.437-6.292-6.291zM16.75 32.853l6.25 6.25 6.25-6.25c-3.438-3.458-9.042-3.458-12.5 0z"
            />
        </svg>
    )
}
