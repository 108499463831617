import {
    FormControl,
    IterateOptions
} from '../../../../../../components/FormUtilities'
import SelectTouch from '../../../../../../components/SelectTouch'
import Agent from '../../../../../../models/Agent'
import { formCostGeneral } from '../../../SectionAdditional/storageStep3'

function FieldAgent() {
    const { props, data } = formCostGeneral.useSelect<Agent>(
        'agent',
        '/v5.6/apis/agents?is_enabled=true'
    )
    return (
        <FormControl label="Agente">
            <SelectTouch {...props} placeholder="Seleccionar agente">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default FieldAgent
