import { Moment } from 'moment'
import { createContext } from 'react'

interface ContextData {
    date: Moment
    format: string
    onChange: (isoString: string) => void
    minDate?: Moment
    maxDate?: Moment
}

const Context = createContext<ContextData>({
    date: null,
    onChange: () => null,
    format: '',
    minDate: null,
    maxDate: null
})

export default Context
