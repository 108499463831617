import FileModel from '../../../../../models/FileModel'
import { parseDriveFile, parseFile } from '../../../../../utilities/fileTools'
import { storagePage } from '../../../storage'
import Aditional from '../aditional'
import { formCostByProduct, tableCostByProductControl } from '../storageStep3'

export function loadForm(response: Aditional.ResponseLCSpecific) {
    const {
        id,
        logistical_concept,
        currency,
        original_cost,
        exchange_to_local,
        files,
        logistical_cost_receptions,
        logistical_specific_costs,
        agent,
        document_number
    } = response
    tableCostByProductControl.setList(
        logistical_specific_costs.map(lsp => ({
            id: lsp.product_id,
            product_name: lsp.product.name,
            product_code: lsp.product.code,
            original_cost: lsp.original_cost.toString(),
            cost: 0 // este campo es innecesario
        }))
    )

    formCostByProduct.init({
        logistical_cost_id: id,
        logistical_concept: {
            value: logistical_concept.id.toString(),
            label: logistical_concept.name
        },
        currency: {
            value: currency.id.toString(),
            label: `${currency.name} (${currency.symbol})`
        },
        cost: original_cost.toString(),
        exchange_to_local: exchange_to_local.toString(),
        total_cost: (original_cost * exchange_to_local).toString(),
        rcs: logistical_cost_receptions.map(lcr => ({
            value: lcr.reception_id.toString(),
            label: 'no es necesario para el checking'
        })),
        files: files.map(file => parseDriveFile(file)),
        document_number: document_number && document_number.toString(),
        agent: null,
        productIds: []
    })
    if (agent) {
        formCostByProduct.setValue('agent', {
            value: agent.id.toString(),
            label: agent.name
        })
    }
    runOperationFields()
}

export function parseBodyEdition(
    f: Aditional.FormLCSpecific,
    currentFiles: FileModel[]
): Aditional.BodyLCSpecificUpdate {
    const data = tableCostByProductControl.getDataList()

    return {
        agent_id: f.agent && parseInt(f.agent.value),
        document_number: f.document_number,
        currency_id: parseInt(f.currency.value),
        original_cost_products: data.map(d => ({
            product_id: d.id,
            original_cost: Number(d.original_cost)
        })),
        exchange_to_local: Number(f.exchange_to_local),
        reception_ids: f.rcs.map(rc => parseInt(rc.value)),
        new_files: f.files
            .filter(file => !file.id)
            .map(file => parseFile(file)),
        files_to_delete: currentFiles
            .filter(file => !f.files.find(_file => _file.id === file.id))
            .map(file => file.id)
    }
}

export function parseBodyCreation(
    f: Aditional.FormLCSpecific,
    type: 'national' | 'international'
): Aditional.BodyLCSpecificCreate {
    const data = tableCostByProductControl.getDataList()

    return {
        agent_id: f.agent && parseInt(f.agent.value),
        document_number: f.document_number,
        type_logistical_cost_source_id: type === 'national' ? 4 : 3,
        currency_id: parseInt(f.currency.value),
        logistical_concept_id: parseInt(f.logistical_concept.value),
        logistical_declaration_id: storagePage.logistical_declaration.id,
        version: storagePage.version,
        original_cost_products: data.map(d => ({
            product_id: d.id,
            original_cost: Number(d.original_cost)
        })),
        exchange_to_local: Number(f.exchange_to_local),
        reception_ids: f.rcs.map(rc => parseInt(rc.value)),
        files: f.files.map(file => parseFile(file))
    }
}

export function runOperationFields() {
    const cost = tableCostByProductControl
        .getDataList()
        .reduce(function (a, b) {
            return a + Number(b.original_cost)
        }, 0)
    formCostByProduct.setValue('cost', cost.toFixed(2))
    const { exchange_to_local } = formCostByProduct.store()
    formCostByProduct.setValue(
        'total_cost',
        (cost * Number(exchange_to_local)).toFixed(2)
    )
}
