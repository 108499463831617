import Typography from '../../../../components/Typography'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { GetReception } from '../../type'

export default function TableReceptionProducts({
    data
}: {
    data: GetReception
}) {
    const localCurrency = data.company_warehouse.company.currency

    return (
        <table className="table_info">
            <thead>
                <tr>
                    <th>#</th>
                    <th>RQ</th>
                    <th>Bodega destino</th>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Valor unitario</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {data.reception_products
                    .filter(item => item.is_enabled)
                    .map((item, i) => (
                        <tr key={item.id}>
                            <td>{1 + i}</td>
                            <td>
                                {
                                    item.purchase_product.requisition_product
                                        .classic_requisition_products[0]
                                        .requisition.code
                                }
                            </td>
                            <td>
                                <p
                                    className={
                                        !item.operation.logical_product
                                            .minimal_stowage.stowage.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {Array.isArray(
                                        item.operation.logical_product
                                            .minimal_stowage.order_sub_stowages
                                    ) &&
                                    item.operation.logical_product
                                        .minimal_stowage.order_sub_stowages
                                        .length > 0
                                        ? item.operation.logical_product
                                              .minimal_stowage
                                              .order_sub_stowages[0].order
                                              .number +
                                          ' - ' +
                                          item.operation.logical_product
                                              .minimal_stowage
                                              .order_sub_stowages[0].order
                                              .client?.name
                                        : item.operation.logical_product
                                              .minimal_stowage.stowage.name}
                                </p>
                            </td>
                            <td>
                                <p className="">
                                    {
                                        item.purchase_product
                                            .requisition_product.product
                                            .description
                                    }
                                </p>
                                <Typography type="small" color="gray">
                                    {
                                        item.purchase_product
                                            .requisition_product.product.code
                                    }
                                </Typography>
                            </td>
                            <td>{item.quantity}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                {localCurrency.symbol}{' '}
                                {buildNumberFormat(
                                    item.operation.income_unit_cost
                                )}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                {localCurrency.symbol}{' '}
                                {buildNumberFormat(
                                    item.operation.income_unit_cost *
                                        item.quantity
                                )}
                            </td>
                        </tr>
                    ))}
            </tbody>
            <tfoot>
                <tr>
                    <td
                        colSpan={6}
                        style={{
                            textAlign: 'right',
                            paddingRight: '0.5rem'
                        }}
                    >
                        TOTAL
                    </td>
                    <td
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {localCurrency.symbol}{' '}
                        {buildNumberFormat(
                            data.reception_products
                                .filter(
                                    item => item.is_enabled && !item.deleted
                                )
                                .reduce(function (a, b) {
                                    return (
                                        a +
                                        b.quantity *
                                            b.operation.income_unit_cost
                                    )
                                }, 0)
                        )}
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}
