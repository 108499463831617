import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import TablePaymentConcept from './TablePaymentConcept'
import FiltersPaymentConcept from './FiltersPaymentConcept'

export default function Requisitions() {
    const state = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FiltersPaymentConcept />
                    <TablePaymentConcept />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
