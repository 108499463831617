import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import FilterProducts from './FilterProviders'
import TableProducts from './TableProviders'

export default function MainProvider() {
    const state = useInitial()
    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterProducts />
                    <TableProducts />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
