import Button from '../../../components/Button'
import { controlList } from '../storage'
import SearchField from '../../../components/SearchField'
import Rules from '../../../components/Rules'
import useSubmit from '../useSubmit'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por rol"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

export default function FilterRoles() {
    const open = useSubmit()
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft></FlexLeft>
                <FlexRight>
                    <Rules cu="CU002">
                        <Button
                            size="sm"
                            className="ml-2"
                            onClick={() => open()}
                        >
                            Nuevo
                        </Button>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
