import useStorage from '../../../hooks/useStorage'
import { formInformation } from '../storage'

export function getCurrency() {
    const { companiesFromSession } = useStorage()
    const currency = companiesFromSession.find(
        company =>
            company.id === parseInt(formInformation.store().company.value)
    ).local_currency

    return currency.symbol
}
