import { SimpleOption } from '../../../../components/Option'
import { controlList, storagePage, filterForm } from '../../storage'
import writeXlsxFile, { Schema } from 'write-excel-file'
import { get } from '../../../../utilities/http'
import { buildPath } from '../../../../sections/ModalPrint/utils'
import moment from 'moment'

export default function OptionExcel() {
    async function download() {
        const { data } = await get(
            buildPath('/v5.6/apis/receptions', [
                [
                    'includes_logistical_costs',
                    controlList.filter.getParam('includes_logistical_costs')
                ],
                ['company_id', controlList.filter.getParam('company_id')],
                ['start_date', controlList.filter.getParam('start_date')],
                ['end_date', controlList.filter.getParam('end_date')],
                ['warehouse_id', controlList.filter.getParam('warehouse_id')],
                ['provider_id', controlList.filter.getParam('provider_id')],
                [
                    'type_reception_id',
                    controlList.filter.getParam('type_purchase_id')
                ],
                [
                    'type_purchase_id',
                    controlList.filter.getParam('type_purchase_id')
                ]
            ])
        )
        const list_data: any = data.result
        const firstProvider = list_data[0].purchase.provider.business_name
        const allSameProvider = list_data.every(
            reception =>
                reception.purchase.provider.business_name === firstProvider
        )
        const name_provider = allSameProvider ? firstProvider : ''
        const excelData = []
        const start_date_param = controlList.filter.getParam('start_date')
        const start_date = start_date_param
            ? moment(start_date_param).format('DD/MM/YYYY')
            : ''
        const end_date_param = controlList.filter.getParam('start_date')
        const end_date = end_date_param
            ? moment(end_date_param).format('DD/MM/YYYY')
            : ''
        excelData.push([
            {
                value: 'RELACIÓN DETALLADA DE RECEPCIONES DE COMPRAS',
                fontWeight: 'bold'
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: 'Fecha Inicial',
                wrap: true
            },
            {
                value: start_date,
                wrap: true
            }
        ])
        excelData.push([
            {
                value: 'Fecha Final',
                wrap: true
            },
            {
                value: end_date,
                wrap: true
            }
        ])
        excelData.push([
            {
                value: 'Proveedor',
                wrap: true
            },
            {
                value: name_provider,
                wrap: true
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: 'Recep N°',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Fecha',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Proveedor',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'No.Factura',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Orden de C.',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Bodega',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Código',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Descripción',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Cant',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'C/U',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Total',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Total RC',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            }
        ])
        let totalSum = 0
        list_data.forEach(reception => {
            // Agregar una fila en blanco si no es la primera recepción
            let totalCostRc = 0
            reception.reception_products.forEach(item => {
                let name_stowage = ''
                if (
                    item.operation.logical_product.minimal_stowage.stowage &&
                    !item.operation.logical_product.minimal_stowage.stowage
                        .is_enabled
                ) {
                    name_stowage = ''
                } else if (
                    item.operation.logical_product.minimal_stowage
                        .order_sub_stowages &&
                    item.operation.logical_product.minimal_stowage
                        .order_sub_stowages.length > 0
                ) {
                    name_stowage =
                        item.operation.logical_product.minimal_stowage
                            .order_sub_stowages[0].order.number +
                        ' - ' +
                        item.operation.logical_product.minimal_stowage
                            .order_sub_stowages[0].order.client?.name
                } else if (
                    !item.operation.logical_product.minimal_stowage
                        .order_sub_stowages ||
                    item.operation.logical_product.minimal_stowage
                        .order_sub_stowages.length === 0
                ) {
                    name_stowage =
                        item.operation.logical_product.minimal_stowage.stowage
                            .name
                }
                // Construir un objeto con los datos de la recepción y el producto
                const rowData = {
                    reception_code: reception.code,
                    date: moment(reception.reception_date)
                        .tz(storagePage.timezone)
                        .format('DD/MM/YYYY'),
                    provider: reception.purchase.provider.business_name,
                    invoice: reception.number_invoice_shipping,
                    purchase_code: reception.purchase.code,
                    product_code:
                        item.purchase_product.requisition_product.product.code,
                    product_description:
                        item.purchase_product.requisition_product.product
                            .description,
                    quantity: item.quantity,
                    average_unit_cost: item.operation.income_unit_cost,
                    total_cost: item.operation.income_unit_cost * item.quantity,
                    stowage: name_stowage,
                    full_cost: ''
                }
                totalCostRc += item.quantity * item.operation.income_unit_cost
                // Agregar la fila al arreglo de datos del Excel
                excelData.push([
                    {
                        value: rowData.reception_code,
                        wrap: true
                    },
                    {
                        value: rowData.date,
                        wrap: true
                    },
                    {
                        value: rowData.provider,
                        wrap: true
                    },
                    {
                        value: rowData.invoice,
                        wrap: true
                    },
                    {
                        value: rowData.purchase_code,
                        wrap: true
                    },
                    {
                        value: rowData.stowage,
                        wrap: true
                    },
                    {
                        value: rowData.product_code,
                        wrap: true
                    },
                    {
                        value: rowData.product_description,
                        wrap: true
                    },
                    {
                        value: rowData.quantity,
                        align: 'right',
                        wrap: true
                    },
                    {
                        value: rowData.average_unit_cost,
                        align: 'right',
                        wrap: true
                    },
                    {
                        value: rowData.total_cost,
                        align: 'right',
                        wrap: true
                    },
                    {
                        value: '',
                        wrap: true
                    }
                ])
            })
            totalSum += totalCostRc
            excelData.push([
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    align: 'right',
                    wrap: true
                },
                {
                    value: '',
                    align: 'right',
                    wrap: true
                },
                {
                    value: '',
                    align: 'right',
                    wrap: true
                },
                {
                    value: totalCostRc,
                    wrap: true,
                    fontWeight: 'bold',
                    align: 'right'
                }
            ])
        })
        excelData.push([
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: 'TOTALES',
                wrap: true,
                align: 'right',
                fontWeight: 'bold'
            },
            {
                value: '',
                align: 'right',
                wrap: true
            },
            {
                value: '',
                align: 'right',
                wrap: true
            },
            {
                value: '',
                align: 'right',
                wrap: true
            },
            {
                value: totalSum,
                wrap: true,
                fontWeight: 'bold',
                align: 'right'
            }
        ])
        // const schema: Schema<any> = [
        //     {
        //         column: 'Recep N°',
        //         width: 20,
        //         value: d => {
        //             return d.reception_code
        //         }
        //     },
        //     {
        //         column: 'Fecha',
        //         width: 20,
        //         value: d => {
        //             return d.date
        //         }
        //     },
        //     {
        //         column: 'Proveedor',
        //         width: 30,
        //         value: d => {
        //             return d.provider
        //         }
        //     },
        //     {
        //         column: 'No.Factura',
        //         width: 20,
        //         value: d => {
        //             return d.invoice
        //         }
        //     },
        //     {
        //         column: 'Orden de C.',
        //         width: 20,
        //         value: d => {
        //             return d.purchase_code
        //         }
        //     },
        //     {
        //         column: 'Bodega',
        //         width: 20,
        //         value: d => {
        //             return d.stowage
        //         }
        //     },
        //     {
        //         column: 'Código',
        //         width: 30,
        //         value: d => {
        //             return d.product_code
        //         }
        //     },
        //     {
        //         column: 'Descripción',
        //         width: 60,
        //         value: d => {
        //             return d.product_description
        //         }
        //     },
        //     {
        //         column: 'Cant',
        //         width: 20,
        //         type: Number,
        //         format: '0',
        //         value: d => {
        //             return d.quantity
        //         }
        //     },
        //     {
        //         column: 'C/U',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.average_unit_cost
        //         }
        //     },
        //     {
        //         column: 'Total',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.total_cost
        //         }
        //     },
        //     {
        //         column: 'Total RC',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.full_cost
        //         }
        //     }
        // ]
        await writeXlsxFile(excelData, {
            fileName: `relacion_recepciones_de_compra.xlsx`,
            columns: [20, 20, 30, 20, 20, 30, 30, 60, 10, 15, 25, 30].map(
                width => ({ width })
            ),
            fontSize: 10
        })
    }

    return <SimpleOption onClick={download}>Exportar en excel</SimpleOption>
}
