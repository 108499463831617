import classNames from 'classnames'
import '../datepicker.css'
import Icon from '../../Icon'
import { getOptionMonth, getOptionYear, MONTHS, YEARS } from '../utilities'
import {
    PropsSelectorMonth,
    PropsSelectorMonthYear,
    PropsSelectorYear
} from '../type'

function SelectorMonth({ month, onSelect }: PropsSelectorMonth) {
    return (
        <div className={classNames(`selector`)}>
            <select
                onChange={ev => onSelect('month', ev.target.value)}
                value={getOptionMonth(MONTHS, month).value}
                className="select select-bordered select-xs selector-select-month"
            >
                {MONTHS.map((_month, i) => (
                    <option key={i} value={(i + 1).toString()}>
                        {_month}
                    </option>
                ))}
            </select>
        </div>
    )
}

function SelectorYear({ year, onSelect }: PropsSelectorYear) {
    return (
        <div className={classNames(`selector`)}>
            <select
                onChange={ev => onSelect('year', ev.target.value)}
                value={getOptionYear(YEARS, year).label}
                className="select select-bordered select-xs selector-select-year"
            >
                {YEARS.map((_year, i) => {
                    return (
                        <option key={i} value={Number(_year)}>
                            {_year}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}

export default function SelectorMonthYear({
    viewDate,
    onBack,
    onChange
}: PropsSelectorMonthYear) {
    function handleLeft() {
        onBack(true)
    }
    function handleRight() {
        onBack(false)
    }
    return (
        <div
            className={classNames(
                `w-full flex items-center justify-around py-3 px-0 relative`
            )}
        >
            <Icon name="arrow-left" onClick={handleLeft} />
            <SelectorMonth month={viewDate.month()} onSelect={onChange} />
            <SelectorYear year={viewDate.year()} onSelect={onChange} />
            <Icon name="arrow-right" onClick={handleRight} />
        </div>
    )
}
