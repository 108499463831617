import 'moment/locale/es'
import Ul from '../../../../components/Ul'
import Typography from '../../../../components/Typography'
import moment from 'moment'
import { GetTraspase } from '../../types'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import { storagePage } from '../../storage'

export default function Information({ data }: { data: GetTraspase }) {
    return (
        <>
            <Typography type="header-3" className="mb-4">
                Información de traspaso
            </Typography>
            <div className="grid grid-cols-2 gap-4 mb-6">
                <Ul className="mb-4">
                    <li>
                        Compañía:
                        <b className="ml-2">
                            <span
                                className={
                                    !data.company_warehouse.company.is_enabled
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                <img
                                    src={
                                        data.company_warehouse.company.country
                                            .image_url
                                    }
                                    className="mr-2 w-6 inline-block"
                                />
                                {buildCompanyName(
                                    data.company_warehouse.company.name
                                )}
                            </span>
                        </b>
                    </li>
                    <li>
                        Almacén:{' '}
                        <b>
                            <span
                                className={
                                    !data.company_warehouse.warehouse.is_enabled
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                {data.company_warehouse.warehouse.name}
                            </span>
                        </b>
                    </li>
                    <li>
                        Bodega origen:{' '}
                        <b>
                            {data.origin_minimal_stowage.type_stowage.id ===
                                7 && (
                                <span
                                    className={
                                        !data.origin_minimal_stowage
                                            .client_sub_stowage.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {
                                        data.origin_minimal_stowage
                                            .client_sub_stowage.client.name
                                    }
                                </span>
                            )}
                            {data.origin_minimal_stowage.type_stowage.id !==
                                7 && (
                                <span
                                    className={
                                        !data.origin_minimal_stowage.stowage
                                            .is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {data.origin_minimal_stowage.stowage.name}
                                </span>
                            )}
                        </b>
                    </li>
                    <li>
                        Sub bodega origen:{' '}
                        <b>
                            {[2, 11].includes(
                                data.origin_minimal_stowage.type_stowage.id
                            ) && (
                                <span
                                    className={
                                        !data.origin_minimal_stowage
                                            .order_sub_stowage.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {
                                        data.origin_minimal_stowage
                                            .order_sub_stowage.order.number
                                    }
                                </span>
                            )}
                            {data.origin_minimal_stowage.type_stowage.id ===
                                6 && (
                                <span
                                    className={
                                        !data.origin_minimal_stowage
                                            .gp_sub_stowage.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {
                                        data.origin_minimal_stowage
                                            .gp_sub_stowage.purchase.code
                                    }
                                </span>
                            )}
                            {![2, 6, 11].includes(
                                data.origin_minimal_stowage.type_stowage.id
                            ) && ' - '}
                        </b>
                    </li>
                    <li>
                        Motivo: <b>{data.document.description}</b>
                    </li>
                </Ul>
                <Ul className="mb-4">
                    <li>
                        Solicitante:{' '}
                        <b>
                            <span
                                className={
                                    !data.document.requesting_user.is_enabled
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                {data.document.requesting_user.name}{' '}
                                {data.document.requesting_user.lastname}
                            </span>
                        </b>
                    </li>
                    <li>
                        Fecha de solicitud:{' '}
                        <b>
                            {moment(data.document.request_date)
                                .tz(storagePage.timezone)
                                .format('DD/MM/YYYY')}
                        </b>
                    </li>
                    <li>
                        Bodega destino:{' '}
                        <b>
                            {data.destination_minimal_stowage.type_stowage
                                .id === 7 &&
                                data.destination_minimal_stowage
                                    .client_sub_stowage.client.name}
                            {data.destination_minimal_stowage.type_stowage
                                .id !== 7 &&
                                data.destination_minimal_stowage.stowage.name}
                        </b>
                    </li>
                    <li>
                        Sub bodega destino:{' '}
                        <b>
                            {[2, 11].includes(
                                data.destination_minimal_stowage.type_stowage.id
                            ) &&
                                data.destination_minimal_stowage
                                    .order_sub_stowage.order.number}
                            {data.destination_minimal_stowage.type_stowage
                                .id === 6 &&
                                data.destination_minimal_stowage.gp_sub_stowage
                                    .order.number}
                            {![2, 6, 11].includes(
                                data.destination_minimal_stowage.type_stowage.id
                            ) && ' - '}
                        </b>
                    </li>
                    <li>
                        Fecha de aprobación:
                        <b>
                            {moment(data.document.decision_date)
                                .tz(storagePage.timezone)
                                .format('DD/MM/YYYY')}
                        </b>
                    </li>
                </Ul>
            </div>
        </>
    )
}
