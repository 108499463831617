import './menu.css'
import Item from './Items/Item'
import { ReactNode, useContext, useState } from 'react'
import RoutingContext from '../../contexts/routing-context'
import Icon from '../Icon'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { RoutingPage } from '../../config/routes/types'
import { IoIosArrowUp } from 'react-icons/io'

interface PropsBlock {
    children: ReactNode
    mod: RoutingPage
    modeLink: boolean
}

function Block({ mod, children, modeLink }: PropsBlock) {
    const route = mod.routes ? mod.routes.find(_route => _route.label) : mod
    const { pathname } = useLocation()
    const math = pathname.search(mod.path) > -1
    const [open, setOpen] = useState(false)
    return (
        <li className="sidebar-block">
            {modeLink && (
                <Link
                    to={route.path}
                    className={classNames('sidebar-menu-item', {
                        'bg-base-200 font-bold': math
                    })}
                >
                    <div className="sidebar-menu-item">
                        <div className="sidebar-menu-icon">
                            <Icon size={20} name={mod.icon} strokeWidth={0.5} />
                        </div>
                        <div
                            className={classNames('sidebar-menu-label', {
                                'bg-base-200 font-bold': math
                            })}
                        >
                            {route.label}
                        </div>
                    </div>
                </Link>
            )}
            {!modeLink && (
                <>
                    <div
                        className="sidebar-menu-item"
                        onClick={() => setOpen(!open)}
                    >
                        <div className="sidebar-menu-icon">
                            <Icon size={20} name={mod.icon} strokeWidth={0.5} />
                        </div>
                        <div
                            className={classNames('sidebar-menu-label', {
                                'bg-base-200 font-bold': math
                            })}
                        >
                            {mod.label}
                        </div>
                        <div className="sidebar-menu-arrow-icon">
                            <IoIosArrowUp
                                className={classNames('anchor', { open })}
                            />
                        </div>
                    </div>
                    <ul className={classNames('sidebar-links', { open })}>
                        {children}
                    </ul>
                </>
            )}
        </li>
    )
}

export default function Menu() {
    const { epicsModules } = useContext(RoutingContext)

    function getBlock(mod: any) {
        let hasItem = false

        if (mod.routes) {
            for (const route of mod.routes) {
                if (route.label) {
                    if (route.routes && !route.Component) {
                        for (const sub of route.routes) {
                            if (sub.label) {
                                hasItem = true
                            }
                        }
                    } else {
                        hasItem = true
                    }
                }
            }
        }
        if (!mod.routes && mod.cu) {
            hasItem = true
        }
        if (!hasItem) return null
        return (
            <Block key={mod.label} mod={mod} modeLink={mod.Component}>
                {mod.routes &&
                    mod.routes.map((route, _i) =>
                        route.label ? (
                            <Item
                                subitems={route.routes}
                                key={_i}
                                path={route.path}
                                hasComponent={route.Component}
                            >
                                {route.label}
                            </Item>
                        ) : null
                    )}
            </Block>
        )
    }
    return <ul id="sidebar-menu">{epicsModules.map(mod => getBlock(mod))}</ul>
}
