import { Option } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import useStorage from '../../../../../hooks/useStorage'
import { GetProvider } from '../../../../../types/provider'
import { setCompanySelected } from '../../../../../utilities/controlStorage'
import { formOC } from '../../../storage'

export default function useDestinationCompany() {
    const { props } = formOC.useSelect('destination_company')
    const { data } = useGet<GetProvider[]>(
        '2>/v5.6/apis/providers?is_enabled=true&limit=20',
        []
    )
    const { companiesFromSession } = useStorage()

    function handleChange(option: Option) {
        props.onChange(option)

        const { provider: providerSelect } = formOC.store()

        if (!providerSelect) {
            formOC.setValue('type_oc', '')
            return
        }

        const provider = data.result.find(
            prov => prov.id.toString() === providerSelect.value
        )

        const company = companiesFromSession.find(
            _com => _com.id.toString() === option.value
        )
        setCompanySelected(company)

        if (provider.country_id !== company.country_id) {
            formOC.setValue('type_oc', 'Internacional')
        } else {
            formOC.setValue('type_oc', 'Nacional')
        }
    }

    return {
        props,
        handleChange
    }
}
