import { Option } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import { GetLocateAddress } from '../../../../../types/locate'
import { formOC, reqLocate, storagePage } from '../../../storage'

export default function usePlaceDispatchPickUp() {
    const { props } = formOC.useSelect('place_dispatch_pick_up')
    // GET /v5.6/apis/providers/{provider_id}/dispatch_addresses?country_id=
    // GET /v5.6/apis/providers/{provider_id}/withdrawal_addresses?country_id=
    const { data } = useGet<GetLocateAddress[]>(
        reqLocate.observer('/v5.6/apis/providers/:provider_id/:mode_address'),
        []
    )

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value === '0') {
            formOC.setProps('address', { disabled: true }, false)
        } else {
            const currentLocate = data.result.find(
                provider_address => provider_address.locate === option.value
            )
            storagePage.addresses = currentLocate.addresses

            formOC.setProps('address', { disabled: false }, false)
        }
        formOC.setValue('address', null)
    }

    return {
        props,
        places: data.result,
        handleChange
    }
}
