import moment from 'moment'
import 'moment-timezone'
import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import useStorage from '../../hooks/useStorage'
import { getCompanySelected } from '../../utilities/controlStorage'
import { controlList, storagePage } from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)
    const { currencies } = useStorage()

    useEffect(() => {
        load().then(pageState => setState(pageState))
    }, [])

    async function load() {
        controlList.filter.changeParams('sort', null)
        const company = getCompanySelected()
        storagePage.timezone = company.timezone
        controlList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })

        const date = moment().tz(company.timezone).format('YYYY-MM-DD')
        controlList.filter.setParams(
            'start_date',
            moment.tz('2020-01-01 12:00', company.timezone).format('YYYY-MM-DD')
        )
        controlList.filter.setParams('end_date', date)

        storagePage.currency =
            company.local_currency || currencies.find(c => c.id === 1)

        return StateCase.SUCCESS
    }

    return { state }
}
