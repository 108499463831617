import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { GetReception } from '../../type'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC',
        borderBottom: '1px solid #CCCCCC'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    table__td: {
        width: '13%',
        padding: '6px',
        fontSize: fontSize,
        borderLeft: '1px solid #CCCCCC'
    },
    table__th: {
        width: '13%',
        backgroundColor: '#CCCCCC',
        padding: '4px',
        fontSize: fontSize,
        fontFamily: 'helvetica-bold'
    },
    textRight: {
        textAlign: 'right'
    },
    cellCount: {
        width: '6%'
    },
    cellRq: {
        width: '12%'
    },
    cellStowage: {
        width: '13%'
    },
    cellProductCode: {
        width: '13%'
    },
    cellProductDescription: {
        width: '21%'
    },
    cellQuantity: {
        width: '6%'
    },
    cellUnitValue: {
        width: '14%'
    },
    cellTotal: {
        width: '15%'
    },
    table__footer__td: {
        padding: '2px',
        fontSize: fontSize,
        backgroundColor: 'rgb(229,231,235)'
    },
    cellFooterName: {
        width: '85%',
        fontFamily: 'helvetica-bold'
    },
    cellTotalRC: {
        width: '15%'
    }
})

export default function Body({ data }: { data: GetReception }) {
    const { reception_products } = data
    const currencySymbol = data.company_warehouse.company.currency.symbol
    const totalValue = reception_products
        .filter(rp => rp.is_enabled && !rp.deleted)
        .reduce(function (a, b) {
            return a + b.quantity * b.operation.income_unit_cost
        }, 0)

    function getMinimalStowageName(minimal_stowage) {
        let name = minimal_stowage.stowage.name

        if (minimal_stowage.order_sub_stowages.length > 0) {
            name =
                minimal_stowage.order_sub_stowages[0].order.number +
                ' - ' +
                minimal_stowage.order_sub_stowages[0].order.client?.name
        }

        if (minimal_stowage.gp_sub_stowages.length > 0) {
            name =
                name + ' - ' + minimal_stowage.gp_sub_stowages[0].purchase.code
        }
        return name
    }

    return (
        <View style={styles.table}>
            <View style={styles.row}>
                <Text style={[styles.table__th, styles.cellCount]}>#</Text>
                <Text style={[styles.table__th, styles.cellRq]}>RQ</Text>
                <Text style={[styles.table__th, styles.cellStowage]}>
                    {'Bodega \n destino'}
                </Text>
                <Text style={[styles.table__th, styles.cellProductCode]}>
                    Código de producto
                </Text>
                <Text style={[styles.table__th, styles.cellProductDescription]}>
                    Descripción de producto
                </Text>
                <Text style={[styles.table__th, styles.cellQuantity]}>
                    Cant.
                </Text>
                <Text style={[styles.table__th, styles.cellUnitValue]}>
                    Valor unit.
                </Text>
                <Text style={[styles.table__th, styles.cellTotal]}>Total</Text>
            </View>
            {reception_products
                .filter(item => item.is_enabled)
                .map((item, i) => (
                    <View key={i} style={styles.row}>
                        <Text style={[styles.table__td, styles.cellCount]}>
                            {i + 1}
                        </Text>
                        <Text style={[styles.table__td, styles.cellRq]}>
                            {
                                item.purchase_product.requisition_product
                                    .classic_requisition_products[0].requisition
                                    .code
                            }
                        </Text>
                        <Text style={[styles.table__td, styles.cellStowage]}>
                            {getMinimalStowageName(
                                item.operation.logical_product.minimal_stowage
                            )}
                        </Text>
                        <Text
                            style={[styles.table__td, styles.cellProductCode]}
                        >
                            {
                                item.purchase_product.requisition_product
                                    .product.code
                            }
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellProductDescription
                            ]}
                        >
                            {
                                item.purchase_product.requisition_product
                                    .product.description
                            }
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellQuantity,
                                styles.textRight
                            ]}
                        >
                            {item.quantity}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellUnitValue,
                                styles.textRight
                            ]}
                        >
                            {currencySymbol}{' '}
                            {buildNumberFormat(item.operation.income_unit_cost)}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellTotal,
                                styles.textRight
                            ]}
                        >
                            {currencySymbol}{' '}
                            {buildNumberFormat(
                                item.quantity * item.operation.income_unit_cost
                            )}
                        </Text>
                    </View>
                ))}
            <View style={styles.row}>
                <Text
                    style={[
                        styles.table__footer__td,
                        styles.cellFooterName,
                        styles.textRight
                    ]}
                >
                    TOTAL
                </Text>
                <Text
                    style={[
                        styles.table__footer__td,
                        styles.cellTotalRC,
                        styles.textRight
                    ]}
                >
                    {currencySymbol} {buildNumberFormat(totalValue)}
                </Text>
            </View>
        </View>
    )
}
