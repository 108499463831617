import ExcelField from '../../../components/ExcelField'
import { FormControl } from '../../../components/FormUtilities'
import DownloadTemplate from './DownloadTemplate'
import useImportSection from './useImportSection'

export default function ImportSection() {
    const { handleChange, file, handleLoad } = useImportSection()

    return (
        <>
            <FormControl label="Subir archivo de importación">
                <ExcelField
                    file={file}
                    maxSizeFile={100000000}
                    onChange={handleChange}
                    onLoad={handleLoad}
                    placeholder="Seleccione un archivo Excel Máx. tamaño: 100mb"
                    dontDownload
                />
            </FormControl>

            <DownloadTemplate />
        </>
    )
}
