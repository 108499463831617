import moment from 'moment'
import Button from '../../../components/Button'
import useRule from '../../../hooks/useRule'
import useStorage from '../../../hooks/useStorage'
import WorkingPeriod from '../../../models/WorkingPeriod'
import OpenDialog from '../../../sections/OpenDialog'
import { controlList, reqYears } from '../storage'

export default function ClosePeriod({
    workingPeriod
}: {
    workingPeriod: WorkingPeriod
}) {
    const company_id = controlList.filter.params?.company_id || '0'
    const { companiesFromSession } = useStorage()

    const company = companiesFromSession.find(
        com => com.id.toString() === company_id
    )
    const math = useRule('CU132')
    return (
        <OpenDialog
            icon="bs-calendar-x"
            color="error"
            title="Cerrar periodo de trabajo"
            element={click => (
                <Button
                    onClick={click}
                    size="sm"
                    color="info"
                    disabled={!company?.is_enabled || !math}
                >
                    Cerrar Periodo
                </Button>
            )}
            path="/v5.6/apis/working_periods/start_working_period"
            body={{
                working_period_id: workingPeriod.id,
                company_id: parseInt(company_id)
            }}
            accept="Sí, cerrar periodo"
            control={async () => {
                if (workingPeriod.month === 12) {
                    reqYears.load()
                    const option = controlList.filter.getOption('year')
                    const year = (parseInt(option.value) + 1).toString()
                    controlList.filter.setOption('year', {
                        label: year,
                        value: year
                    })
                }
                await controlList.load()
                controlList.render()
            }}
        >
            {workingPeriod.month === 12 ? (
                <MessageNextYear />
            ) : (
                <Message
                    mount={moment(
                        `01/${workingPeriod.month}/${workingPeriod.year}`,
                        'DD/MM/YYYY'
                    ).format('MMMM')}
                />
            )}
        </OpenDialog>
    )
}

function Message({ mount }: { mount: string }) {
    return (
        <>
            Al cerrar este periodo de trabajo, ya no se podrán realizar
            operaciones de inventario. ¿Está seguro de cerrar el periodo de
            trabajo de <b>{mount}</b>?
        </>
    )
}

function MessageNextYear() {
    return (
        <>
            Al cerrar este periodo de trabajo, ya no se podrán realizar
            operaciones de inventario para el mes de diciembre y se iniciará a
            un nuevo año.
            <br />
            ¿Está seguro de cerrar el periodo de trabajo <b>Diciembre</b>?
        </>
    )
}
