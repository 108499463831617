import Initial from '../../../components/Initial'
import Header from './Header'
import { useParams } from 'react-router'
import SectionGeneralInformation from './SectionGeneralInformation'
import SectionCosts from './SectionCosts'
import SectionCostDistribution from './SectionCostDistribution'
import { controlTabsNational, storagePage } from '../storage'
import useInitial from '../useInitial'
import { Popup } from '../../../utilities/popup'
import { tableRCsControl } from './SectionCosts/storageStep2'
import SectionSettings from './SectionSettings'

controlTabsNational.setSection('Información General', SectionGeneralInformation)
controlTabsNational.setSection('Costos', SectionCosts)
controlTabsNational.setSection('Ajustes', SectionSettings)
controlTabsNational.setSection('Distribución', SectionCostDistribution)

export default function CostNational() {
    const { pageState, cancel } = useInitial(2)
    type Params = { logistical_declaration_id?: string }
    const { logistical_declaration_id } = useParams<Params>()
    const value = controlTabsNational.useStep(to => {
        if (
            (to === 'step3' || to === 'step4') &&
            tableRCsControl.getDataList().length === 0 &&
            controlTabsNational.value === 'step2'
        ) {
            Popup.error(
                'Costeo incompleto',
                'Debe de seleccionar al menos una recepción en el costeo.'
            )
            return false
        }
        if (storagePage.mode === 'edition') return true
        if (to !== 'step1' && !storagePage.step1Done) {
            return false
        }
        return true
    })

    return (
        <Initial pageState={pageState}>
            <Header cancel={cancel} />
            <controlTabsNational.SectionTabs
                value={value}
                type={logistical_declaration_id ? 'tab' : 'step'}
                className="max-w-screen-2xl"
            />
        </Initial>
    )
}
