import Option from '../../../../../components/Option'
import { GetProviderCatalog } from '../../../../../types/provider_catalog'
import { providerList } from '../../../storage'
import writeXlsxFile, { Schema } from 'write-excel-file'
import moment from 'moment'
import { get } from '../../../../../utilities/http'
import { buildPath } from '../../../../../sections/ModalPrint/utils'

export default function OptionExport() {
    async function download() {
        const { data } = await get<GetProviderCatalog[]>(
            buildPath('/v5.6/apis/product_provider_companies', [
                ['sort', 'product_name'],
                ['provider_id', providerList.filter.getParam('provider_id')],
                ['company_id', providerList.filter.getParam('company_id')],
                ['search', providerList.filter.getParam('search')],
                ['to_excel', 'true']
            ])
        )
        const schema: Schema<GetProviderCatalog> = [
            {
                column: 'codigo_producto',
                width: 20,
                value: d => {
                    return d.product_company.product_country.product.code
                }
            },
            {
                column: 'codigo_producto_proveedor',
                width: 30,
                value: d => {
                    return d.provider_code
                }
            },
            {
                column: 'moneda',
                width: 10,
                value: d => {
                    return d.company_currency.currency.symbol
                }
            },
            {
                column: 'precio_compra',
                width: 20,
                value: d => {
                    return d.purchase_price
                }
            },
            {
                column: 'descripcion_producto_proveedor',
                width: 40,
                value: d => {
                    return d.provider_description
                }
            }
        ]

        const date = moment().format('YYYY_MM_DD_HH_mm_ss')

        await writeXlsxFile(data.result, {
            fileName: `catalogo_provedor_${date}.xlsx`,
            schema
        })
    }

    return (
        <Option
            label="Exportar en Excel"
            value="3"
            icon="download"
            onClick={download}
        />
    )
}
