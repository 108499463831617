import Button from '../../../../components/Button'
import SearchProviderCatalog from './SearchProviderCatalog'
import { CompanyFilter } from './Filters'
import useSubmitProductCatalog from '../../useSubmitProductCatalog'
import Rules from '../../../../components/Rules'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../../components/UtilsResponsive/UtilsResponsive.style'
import PopoverMore from './PopoverMore'

export default function Controls() {
    const openModalForm = useSubmitProductCatalog()

    return (
        <SectionFiltersGrid className="control">
            <SearchProviderCatalog />
            <FlexBox>
                <FlexLeft>
                    <CompanyFilter />
                </FlexLeft>
                <FlexRight>
                    <PopoverMore />
                    <Rules cu="CU110">
                        <Button
                            size="sm"
                            className="ml-2"
                            onClick={openModalForm}
                        >
                            Nuevo
                        </Button>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
