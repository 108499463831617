import BtnActionTable from '../../../../components/BtnActionTable'
import { Step1 } from '../../types'
import useContact from './useContact'

export default function EditContact({
    contact,
    index
}: {
    contact: Step1.TableProviderContact
    index: number
}) {
    const open = useContact(index)

    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={() => open(contact)}
        />
    )
}
