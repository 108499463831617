import BtnActionTable from '../../../../components/BtnActionTable'
import Icon from '../../../../components/Icon'
import NumeralField from '../../../../components/NumeralField'
import ReactPopover from '../../../../components/ReactPopover'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { Popup } from '../../../../utilities/popup'
import { tableSaleDiscountsControl, storagePage } from '../../storage'
import { Step2 } from '../../types'

export default function TableDiscounts() {
    return (
        <>
            <TableList
                control={tableSaleDiscountsControl}
                scrollHeight={350}
                className="mb-4"
            >
                {(_, control, e) => (
                    <Row key={e} control={control} index={e - 1} />
                )}
            </TableList>
        </>
    )
}

function Row({
    control,
    index
}: {
    control: FormEvents<Step2.TableSaleDiscounts>
    index: number
}) {
    const input_min_quantity = control.useInput('min_quantity')
    const input_max_quantity = control.useInput('max_quantity')
    const input_discount = control.useInput('discount')

    const tooltip =
        input_min_quantity.props.helper ||
        input_max_quantity.props.helper ||
        input_discount.props.helper

    const disabled = storagePage.modeStep2 === 'view'

    return (
        <tr className={tooltip && 'bg-red-100'}>
            <td>{index + 1}</td>
            <td>
                {!disabled && (
                    <NumeralField
                        {...input_min_quantity.props}
                        helper=""
                        autoFocus
                        max={9999999999}
                    />
                )}
                {disabled && input_min_quantity.props.value}
            </td>
            <td>
                {!disabled && (
                    <NumeralField
                        {...input_max_quantity.props}
                        helper=""
                        max={9999999999}
                    />
                )}
                {disabled && input_max_quantity.props.value}
            </td>
            <td>
                {!disabled && (
                    <NumeralField
                        {...input_discount.props}
                        helper=""
                        max={100}
                        decimal={2}
                    />
                )}
                {disabled &&
                    '% ' + Number(input_discount.props.value).toFixed(2)}
            </td>
            <td>
                <div className="group-btn-options">
                    {!disabled && (
                        <BtnActionTable
                            title="Eliminar"
                            iconName="bs-trash"
                            onClick={() => handleDel(index)}
                        />
                    )}
                    {tooltip && (
                        <ReactPopover
                            component={
                                <Icon
                                    className="text-red-600 ml-2"
                                    name="warning"
                                />
                            }
                        >
                            <p>{tooltip}</p>
                        </ReactPopover>
                    )}
                </div>
            </td>
        </tr>
    )
}

async function handleDel(index: number) {
    const { id } = tableSaleDiscountsControl.get(index)
    if (id) {
        const result = await Popup.delete(
            'Eliminar registro',
            <>
                Se removerá el descuento por volumen de la lista. ¿Estás seguro
                de eliminarlo?
            </>
        )
        if (!result) return
    }
    tableSaleDiscountsControl.del(index)
}
