import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import Option from '../../../../components/Option'
import SelectTouch from '../../../../components/SelectTouch'
import Textarea from '../../../../components/Textarea'
import useStorage from '../../../../hooks/useStorage'
import { formPaymentCondition } from '../../storage'

function Pay() {
    const { props } = formPaymentCondition.useSelect('payment_condition')
    return (
        <FormControl label="Condición de pago" required className="mb-2">
            <SelectTouch
                {...props}
                placeholder="Ingresar monto de línea"
                label="Seleccionar condición"
            >
                <Option value="as" label="asff" />
            </SelectTouch>
        </FormControl>
    )
}

function Comany() {
    const { props } = formPaymentCondition.useSelect('company')
    const { storage } = useStorage()
    return (
        <FormControl label="Compañias aplicables" required className="mb-2">
            <SelectTouch
                {...props}
                placeholder="Seleccionar compañía"
                label="Compañía"
            >
                <IterateOptions
                    data={storage.companies.map(c => ({
                        ...c,
                        image_url: c.country.image_url
                    }))}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}

function Description() {
    const { props } = formPaymentCondition.useInput('description')
    return (
        <FormControl label="Descripción">
            <Textarea {...props} placeholder="Ingrese una descripción" />
        </FormControl>
    )
}

export default function FormPaymentCondition() {
    return (
        <>
            <Pay />
            <Comany />
            <Description />
        </>
    )
}
