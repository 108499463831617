import { Page, Document } from '@react-pdf/renderer'
import { LogisticalDeclarationPDFData } from '../../types'
import moment from 'moment'
import Header from './Header'
import { controlList, storagePage } from '../../storage'
import Body from './Body'
import { generalStyles as styles } from './GeneralStyles'

export default function Pdf({ data }: { data: LogisticalDeclarationPDFData }) {
    const company = storagePage.originalCompanies.find(
        comp =>
            comp.id ===
            parseInt(controlList.filter.getOption('company_id').value)
    )

    return (
        <Document title={moment().format('DD-MM-YYYY')}>
            <Page size="A4" style={styles.section}>
                <Header company={company} data={data} />
                <Body data={data} />
            </Page>
        </Document>

        // <Document title={moment().format('DD-MM-YYYY')}>
        //     <Page size="A4">
        //         <View>
        //             <Text>COMPAÑÍA</Text>
        //             <Text>Dirección</Text>
        //             <Text>{logistical_declaration.company.address}</Text>
        //             <Text>Documento</Text>
        //             <Text>
        //                 {logistical_declaration.company.type_fiscal_document}
        //             </Text>
        //             <Text>
        //                 {logistical_declaration.company.fiscal_document}
        //             </Text>
        //             <Text>{logistical_declaration.company.address}</Text>
        //         </View>
        //         <Text>----</Text>
        //         <Text>COSTEO - DETALLE DE COSTOS</Text>
        //         <Text>----</Text>
        //         <Text>RECEPCIONES DE COMPRA</Text>
        //         <View>
        //             {logisticalReceptions.map((element, i) => {
        //                 return (
        //                     <Text key={i}>
        //                         {element.reception.code}-
        //                         {element.reception.purchase.code}-
        //                         {
        //                             element.reception.purchase.provider_company
        //                                 .provider.commercial_name
        //                         }
        //                         - $ {element.purchase_dolar_cost}-
        //                         {element.exchange_dolar_to_local} - S/{' '}
        //                         {element.purchase_local_cost}
        //                     </Text>
        //                 )
        //             })}
        //             <Text>
        //                 FOB Dolar: $ {dolarFob} - FOB Local: S/ {localFob}
        //             </Text>
        //         </View>
        //         <Text>----</Text>
        //         <Text>COSTOS INTERNACIONALES</Text>
        //         <View>
        //             {internationalCosts.map((element, i) => {
        //                 return (
        //                     <Text key={i}>
        //                         {element.logistical_concept.name}
        //                     </Text>
        //                 )
        //             })}
        //         </View>
        //         <Text>CIF Local: {localCif}</Text>
        //         <Text>CIF Dolar: {dolarCif}</Text>
        //     </Page>
        // </Document>
    )
}
