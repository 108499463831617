import modal from '../../sections/modal'
import { ModalTools } from '../../sections/modal/types'
import { Popup } from '../../utilities/popup'
import submit from '../../utilities/submit'
import {
    controlList,
    listProductProviderCompany,
    productCompanyData
} from './storage'
import loadProductProviderCompany from './Utilities/loadProductCompany'
import AssignProvider from './View/AssignMainProvider'

export default function useSubmit(productCompanyId?: number) {
    async function handleSubmit(ev: any, modalTools: ModalTools) {
        ev.preventDefault()
        const provider = listProductProviderCompany
            .getDataList()
            .find(d => d.is_main)

        if (!provider) {
            listProductProviderCompany.setMessage(
                'Se requiere de un proveedor principal'
            )
            return
        }

        modalTools.close()
        const { success, error } = await submit(
            `/v5.6/apis/product_companies/${productCompanyId}/principal_provider`,
            {
                method: 'put',
                message:
                    '¿Estás seguro de guardar los cambios del proveedor principal?',
                body: {
                    provider_id: provider.id
                }
            }
        )

        if (error) {
            Popup.error(
                'Algo salió mal...',
                'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
            )
        }

        if (success) {
            await controlList.done()
            controlList.renderMain()
        }
    }

    return function openModal() {
        modal(
            AssignProvider,
            async () => {
                const result = await loadProductProviderCompany(
                    productCompanyId
                )
                if (!result) {
                    Popup.error(
                        'Algo salió mal...',
                        'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
                    )
                    return null
                }
                productCompanyData.productCompany = result
                return {
                    title: 'Cambiar proveedor principal',
                    onSubmit: handleSubmit
                }
            },
            'md'
        )
    }
}
