import BtnActionTable from '../../../../components/BtnActionTable'
import { TableList } from '../../../../sections/ListForm'
import { formNorms, tableProductsControl } from '../../storage'
import setParamsSearchProducts from '../../utilities/setParamsSearchProducts'

export default function TableStorage() {
    function handleDel(product_id: number) {
        const index = tableProductsControl
            .getDataList()
            .findIndex(tableProduct => tableProduct.id === product_id)
        tableProductsControl.del(index)

        const optionsFiltered = formNorms
            .store()
            .storable_products.filter(
                option => parseInt(option.value) !== product_id
            )

        formNorms.setValue('storable_products', optionsFiltered)
        setParamsSearchProducts()
    }

    return (
        <>
            <TableList
                control={tableProductsControl}
                className="mt-6"
                scrollHeight={250}
            >
                {(product, _, e) => (
                    <tr key={e}>
                        <td>{e}</td>
                        <td>
                            <p className="font-univers-bold -mb-1">
                                {product.name}
                            </p>
                            <span className="text-2xs">{product.code}</span>
                        </td>
                        <td>{product.category_name}</td>
                        <td>{product.subcategory_name}</td>
                        <td>
                            <div className="group-btn-options">
                                <BtnActionTable
                                    title="Eliminar"
                                    iconName="bs-trash"
                                    onClick={() => handleDel(product.id)}
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}
