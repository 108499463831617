import moment from 'moment'
import 'moment/locale/es'
import Badge from '../../../components/Badge'
import DataTable, { TBody, THead } from '../../../components/DataTable'
import Typography from '../../../components/Typography'
import useGet from '../../../hooks/useGet'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { controlList, storagePage, filterForm } from '../storage'
import { GeneralInformationByProduct, BalanceHistoryResponse } from '../types'
import { getMinimalStowageName } from '../utilities/getMinimalStowageName'

export default function InfoView({ data }: { data: any[] }) {
    moment.locale('es')

    const sym = storagePage.currency.symbol

    return (
        <>
            <Typography type="header-3" className="mb-4">
                Listado por OC
            </Typography>

            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th style={{ whiteSpace: 'normal' }}>N° OC</th>
                        <th style={{ whiteSpace: 'normal' }}>Estado</th>
                        <th style={{ whiteSpace: 'normal' }}>Bodega destino</th>
                        <th style={{ whiteSpace: 'normal' }}>
                            Cantidad en tránsito
                        </th>
                    </tr>
                </THead>
                <TBody>
                    {data.map((d, i) => (
                        <tr key={d.id}>
                            <td>{i + 1}</td>
                            <td>{d.purchase_code}</td>
                            <td>{d.purchase_status_name}</td>
                            <td>{d.destination_stowage_name}</td>
                            <td>{d.quantity}</td>
                        </tr>
                    ))}
                </TBody>
            </DataTable>
        </>
    )
}
