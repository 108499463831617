import { GetProduct } from '../../types/product'
import { parseFile } from '../../utilities/fileTools'
import { controlTabs, storagePage, formInformation } from './storage'
import { BodyInformation } from './types'

export default function useSubmitInfo(product_id?: number) {
    const { submit, validation, observer } =
        formInformation.useSubmit<GetProduct>(
            product_id
                ? `/v5.6/apis/products/${product_id}/general_information`
                : '/v5.6/apis/products/general_information',
            {
                message:
                    '¿Está seguro de guardar cambios de Información General?',
                expectChange: storagePage.mode === 'creation',
                method: product_id ? 'put' : 'post',
                done: product => {
                    storagePage.product = {
                        ...storagePage.product,
                        ...product
                    }
                    formInformation.store.init(formInformation.store)
                    controlTabs.next()
                }
            }
        )

    validation((v, form) => {
        v.name
            .minLength(2, 'El número de caracteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el nombre')
        v.manufacturer_code
            .minLength(2, 'El número de caracteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el código')
        v.brand.isEmpty('Se requiere seleccionar la marca')

        v.type_product.isEmpty('Se requiere seleccionar el tipo de producto')
        if (form.type_product?.value === '2') {
            v.type_communications.isEmpty(
                'Se requiere de un tipo de comunicación como mínimo'
            )
        }
        v.category.isEmpty('Se requiere seleccionar la categoría')
        v.subcategory.isEmpty('Se requiere seleccionar la subcategoría')
    })

    observer<BodyInformation>(form => {
        function getFiles() {
            if (storagePage.mode === 'creation') {
                return {
                    new_files: form.product_files
                        .filter(f => !f.id)
                        .map(f => parseFile(f)),
                    files_to_delete: []
                }
            }
            return {
                new_files: form.product_files
                    .filter(f => !f.id)
                    .map(f => parseFile(f)),
                files_to_delete: storagePage.product.files
                    .filter(f => !form.product_files.find(pf => pf.id === f.id))
                    .map(f => f.id)
            }
        }

        return {
            name: form.name.trim(),
            manufacturer_code: form.manufacturer_code.trim(),
            presentation: form.presentation || null,
            rank: form.rank || null,
            model: form.model || null,
            quantity: form.quantity || null,
            ...(!product_id
                ? {
                      files: form.product_files.map(f => parseFile(f))
                  }
                : getFiles()),
            is_precursor: form.precursor,
            is_hazardous: form.hazardous,
            brand_id: parseInt(form.brand.value),
            type_product_id: parseInt(form.type_product.value),
            type_communication_ids: form.type_communications.map(type =>
                parseInt(type.value)
            ),
            subcategory_id: parseInt(form.subcategory.value)
        }
    })

    return submit
}
