import { formCatalog } from '../storage'

export default function initCreation() {
    formCatalog.store.on('company', option => {
        if (option) {
            formCatalog.setProps('product', {
                disabled: false
            })
            formCatalog.setValue('currency', null, false)
            formCatalog.setProps('currency', {
                disabled: false
            })
            formCatalog.setParams('currency', {
                company_id: option.value
            })
            formCatalog.setParams('product', {
                limit: 10,
                company_id: option.value
            })
            formCatalog.setValue('product', null)
        } else {
            formCatalog.setValue('product', null, false)
            formCatalog.setValue('currency', null, false)
            formCatalog.setProps('product', {
                disabled: true
            })
            formCatalog.setProps('currency', {
                disabled: true
            })
        }
    })

    formCatalog.setProps(
        'company',
        {
            disabled: false
        },
        false
    )
    formCatalog.setProps(
        'product',
        {
            disabled: true
        },
        false
    )
    formCatalog.setProps(
        'currency',
        {
            disabled: true
        },
        false
    )
}
