import moment from 'moment'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList, storagePage } from '../storage'
import { Head } from '../types'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import ModalTraspase from './ModalTraspase'
import ModalReception from '../../../modals/ModalReception'
import ModalInvoice from '../../../modals/ModalInvoice'
import ModalCreditNote from '../../../modals/ModalCreditNote'
import {
    TYPE_DOCUMENT__CONSUMER_VOUCHER,
    TYPE_DOCUMENT__CREDIT_NOTE,
    TYPE_DOCUMENT__INPUT_PART,
    TYPE_DOCUMENT__INVOICE,
    TYPE_DOCUMENT__RECEPTION,
    TYPE_DOCUMENT__TRASPASE
} from '../../../config/constants'
import { isCUAccesible } from '../../../components/Rules'
import CU from '../../../config/types/cu'
import ModalInputPart from '../../../modals/ModalInputPart'
import ModalConsumerVoucher from '../../../modals/ModalConsumerVoucher'

export default function TableKardex() {
    function handleView(data: any) {
        if (data.document.type_document.id === 11) {
            const trapases_products =
                data.traspase_products_of_input ||
                data.traspase_products_of_output
            const traspase_id =
                trapases_products.classic_traspase_products[0].traspase_id
            view(`/v5.6/apis/traspases/${traspase_id}`, () => ({
                title: `TRASPASO ${data.document.code}`,
                Component: ModalTraspase,
                badge: data.document.type_document_approval.name,
                colorBadge:
                    data.document.type_document_approval_id === 3
                        ? 'success'
                        : 'info'
            }))
        } else if (data.document.type_document.id === 12) {
            const consumer_voucher_id =
                data.consumer_voucher_products[0].consumer_voucher_id
            view(
                `/v5.6/apis/consumer_voucher/${consumer_voucher_id}`,
                () => ({
                    title: `DETALLE DE VALE DE CONSUMO`,
                    Component: ModalConsumerVoucher
                }),
                'lg'
            )
        } else if (data.document.type_document.id === 13) {
            const input_part_id = data.input_part_products[0].input_part_id
            view(
                `/v5.6/apis/input_parts/${input_part_id}`,
                () => ({
                    title: `DETALLE DE PARTE DE ENTRADA`,
                    Component: ModalInputPart
                }),
                'lg'
            )
        } else if (
            data.document.type_document.id === TYPE_DOCUMENT__RECEPTION
        ) {
            const reception_id = data.reception_products[0].reception_id
            view(
                `/v5.6/apis/receptions/${reception_id}`,
                () => ({
                    title: `DETALLE DE RC`,
                    Component: ModalReception
                }),
                'lg'
            )
        } else if (data.document.type_document.id === TYPE_DOCUMENT__INVOICE) {
            const invoice_id = data.invoice_products[0].invoice_id
            view(
                `/v5.6/apis/invoices/${invoice_id}`,
                () => ({
                    title: `DETALLE DE FACTURA`,
                    Component: ModalInvoice
                }),
                'lg'
            )
        } else if (
            data.document.type_document.id === TYPE_DOCUMENT__CREDIT_NOTE
        ) {
            const credit_note_id = data.credit_note_products[0].credit_note_id
            view(
                `/v5.6/apis/credit_notes/${credit_note_id}`,
                () => ({
                    title: `DETALLE DE NOTA DE CRÉDITO`,
                    Component: ModalCreditNote
                }),
                'lg'
            )
        }
    }

    function TdDocumentCode(_data: any) {
        const data = _data.data
        const cu_documents: Array<{ type_document_id: number; cu: CU }> = [
            { type_document_id: TYPE_DOCUMENT__RECEPTION, cu: 'CU304' },
            { type_document_id: TYPE_DOCUMENT__INPUT_PART, cu: 'CU305' },
            { type_document_id: TYPE_DOCUMENT__CONSUMER_VOUCHER, cu: 'CU306' },
            { type_document_id: TYPE_DOCUMENT__TRASPASE, cu: 'CU307' },
            { type_document_id: TYPE_DOCUMENT__CREDIT_NOTE, cu: 'CU308' },
            { type_document_id: TYPE_DOCUMENT__INVOICE, cu: 'CU309' }
        ]
        const cu_document = cu_documents.find(
            cu_doc => cu_doc.type_document_id === data.document.type_document_id
        )
        if (isCUAccesible(cu_document.cu)) {
            return (
                <td>
                    <b
                        className="underline cursor-pointer"
                        onClick={() => handleView(data)}
                    >
                        {data.document.code}
                    </b>
                </td>
            )
        } else {
            return (
                <td>
                    <b>{data.document.code}</b>
                </td>
            )
        }
    }

    return (
        <List
            theadWhiteSpace={'normal'}
            control={controlList}
            head={(response: Head) => (
                <tr>
                    <td />
                    <td />
                    <td>{response.transaction}</td>
                    <td />
                    <td />
                    <td />
                    <td>{response.input}</td>
                    <td>{response.output}</td>
                    <td>{response.total_inventory}</td>
                    <td>{`${storagePage.currency.symbol} ${response.income_unit_cost}`}</td>
                    <td>
                        {' '}
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            response.average_unit_cost
                        )}`}
                    </td>
                    <td>
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            response.has_to
                        )}`}
                    </td>
                    <td>
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            response.to_have
                        )}`}
                    </td>
                    <td>
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            response.cost_balance
                        )}`}
                    </td>
                </tr>
            )}
        >
            {(data, e) => (
                <tr key={data.id}>
                    <td>{e}</td>
                    <TdDocumentCode data={data} />
                    <td>{data.type_operation.name}</td>
                    <td>{moment(data.operation_date).format('DD/MM/YYYY')}</td>
                    <td
                        className={
                            !data.stowage.is_enabled ? 'line-through' : ''
                        }
                        style={{ whiteSpace: 'normal' }}
                    >
                        {data.minimal_stowage_name}
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {data.document.type_document.name}
                    </td>
                    <td>{data.input}</td>
                    <td>{data.output}</td>
                    <td>{data.total_inventory}</td>
                    <td>
                        {' '}
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            data.income_unit_cost
                        )}`}
                    </td>
                    <td>
                        {' '}
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            data.average_unit_cost
                        )}`}
                    </td>
                    <td>
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            data.hast_to
                        )}`}
                    </td>
                    <td>
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            data.to_have
                        )}`}
                    </td>
                    <td>
                        {`${storagePage.currency.symbol} ${buildNumberFormat(
                            data.cost_balance
                        )}`}
                    </td>
                </tr>
            )}
        </List>
    )
}
