import ListFormControl from '../../sections/ListForm/ListFormControl'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import {
    FormCreditLine,
    FormPaymentCondition,
    FormPaymentMethod,
    TableCreditLineData,
    TablePaymentConditionData,
    TablePaymentMethodData
} from './types'

export const tableCreditLine = new ListFormControl<TableCreditLineData>(
    Keys.TABLE_CREDIT_LINE,
    ['Monto de línea', 'Moneda', 'Compañías aplicables', 'Acciones']
)

export const formCreditLine = new FormEvents<FormCreditLine>(
    Keys.FORM_CREDIT_LINE,
    {
        amount: '',
        currency: null,
        company: null
    }
)

export const tablePaymentConditions =
    new ListFormControl<TablePaymentConditionData>(
        Keys.TABLE_PAYMENT_CONDITIONS,
        ['Condición de pago', 'Compañias aplicables', 'Descripción', 'Acciones']
    )

export const formPaymentCondition = new FormEvents<FormPaymentCondition>(
    Keys.FORM_PAYMENT_CONDITION,
    {
        payment_condition: null,
        company: null,
        description: ''
    }
)

export const tablePaymentMethods = new ListFormControl<TablePaymentMethodData>(
    Keys.TABLE_PAYMENT_METHODS,
    ['Medio de pago', 'Compañias aplicables', 'Descripción', 'Acciones']
)

export const formPaymentMethod = new FormEvents<FormPaymentMethod>(
    Keys.FORM_PAYMENT_CONDITION,
    {
        payment_method: null,
        company: null,
        description: ''
    }
)
