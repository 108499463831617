import Icon from '../../../../components/Icon'
import Ul from '../../../../components/Ul'
import { UploadStatesProps } from './types'

export default function UploadStates({ uploadStates }: UploadStatesProps) {
    return (
        <Ul className={uploadStates.length > 0 ? 'my-3' : ''}>
            {uploadStates.map((data, i) => (
                <li key={i} className="flex justify-between m-0">
                    <p>
                        {i + 1}. {data.message}
                    </p>
                    {data.status === 'loading' && (
                        <Icon name="loading" className="animate-spin" />
                    )}
                    {data.status === 'done' && (
                        <Icon name="check-cirle" className="text-success" />
                    )}
                    {data.status === 'error' && (
                        <Icon name="times" className="text-error" />
                    )}
                </li>
            ))}
        </Ul>
    )
}
