import moment from 'moment'
import buildCompanyName from '../../../utilities/buildCompanyName'
import { parseDriveFile } from '../../../utilities/fileTools'
import { formLicense, storagePage } from '../storage'

function buildDataEditionRenovate() {
    const currentDate = moment().toISOString(true)
    const { mode, license } = storagePage
    const version =
        mode === 'edition' ? `v${license.version}` : `v${license.version + 1}`
    const issue_date = mode === 'edition' ? license.issue_date : currentDate
    const due_date = mode === 'edition' ? license.due_date : null
    const due_date_is_enabled = mode === 'edition' ? !!license.due_date : false
    const files =
        mode === 'renovate'
            ? []
            : license.files.map(file => parseDriveFile(file))

    return {
        version,
        issue_date,
        due_date,
        due_date_is_enabled,
        files
    }
}

export default function build() {
    const currentDate = moment().toISOString(true)
    const { mode, license } = storagePage

    if (mode === 'creation' || mode === 'edition') {
        formLicense.store.set('issue_date', currentDate)
        formLicense.setProps('issue_date', {
            maxDate: currentDate
        })
    }

    if (mode === 'edition' || mode === 'renovate') {
        formLicense.store({
            company: {
                label: buildCompanyName(license.company.name),
                value: license.company.id.toString(),
                image: license.company.country.image_url
            },
            type_licenses: license.license_type_licenses
                .filter(ltl => ltl.memory)
                .map(ltl => ({
                    label: ltl.type_license.name,
                    value: ltl.type_license.id.toString()
                })),
            code: license.code,
            name: license.name,
            description: license.description,
            ...buildDataEditionRenovate()
        })
    }

    if (mode === 'renovate') {
        formLicense.setProps(
            'issue_date',
            {
                minDate: license.issue_date,
                maxDate: currentDate
            },
            false
        )
    }

    formLicense.setProps(
        'due_date',
        {
            disabled: storagePage.license
                ? !storagePage.license.due_date
                : false,
            minDate: moment(currentDate).add(1, 'day').toISOString()
        },
        false
    )

    formLicense.init(formLicense.store())
}
