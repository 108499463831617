import classNames from 'classnames'
import Button from '../Button'
import Icon from '../Icon'
import { PropsUpload } from './types'
import './upload.css'
import { useRef } from 'react'
import FileItem from './FileItem'
import Helper from '../Helper'
import useFiles from '../../hooks/useFiles'
import { parseFileSize } from '../../utilities/fileTools'

export default function Upload({
    extensions = [
        'png',
        'jpg',
        'jpeg',
        'pdf',
        'doc',
        'docx',
        'PNG',
        'JPG',
        'xlsx',
        'XLSX'
    ],
    files = [],
    className = '',
    onChange = () => null,
    maxSizeFile = 10000000,
    helper = ''
}: PropsUpload) {
    const dragRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const { handleChange, filesData, handleDelete, refInput } = useFiles({
        extensions,
        files,
        maxSizeFile,
        onChange
    })
    const handleStyleDrag = () => {
        contentRef.current.classList.add('desactive-background')
        dragRef.current.classList.add('active-drag')
    }
    const handleStyleDragLeave = () => {
        dragRef.current.classList.remove('active-drag')
        contentRef.current.classList.remove('desactive-background')
    }
    const handleStyleDrop = () => {
        dragRef.current.classList.remove('active-drag')
        contentRef.current.classList.remove('desactive-background')
    }

    return (
        <>
            <div
                className={classNames('upload', className, {
                    'has-file': filesData.length > 0,
                    helper: !!helper
                })}
            >
                <div
                    className="upload-input relative"
                    onDragOver={handleStyleDrag}
                    onDragLeave={handleStyleDragLeave}
                    onDrop={handleStyleDrop}
                >
                    <div
                        ref={dragRef}
                        className="absolute w-full invisible h-full flex items-center justify-center rounded-lg
"
                    >
                        <p className="text-info font-semibold">
                            Suelte aqui su archivo
                        </p>
                    </div>
                    <div ref={contentRef} className="absolute text-center">
                        <Icon
                            name="cloud-upload"
                            className="text-accent pointer-events-none"
                            size={34}
                        />
                        <div className="text-center mt-2 text-gray-400 text-xs pointer-events-none">
                            <p>Arrastra aquí</p>
                            <p>o</p>
                        </div>
                        <Button size="sm" className="mt-1 cursor-pointer">
                            Selecciona archivos
                        </Button>
                        <label className="block text-xs text-gray-400 mt-1">
                            Max tamaño: {parseFileSize(maxSizeFile)}
                        </label>
                    </div>
                    <input
                        type="file"
                        multiple
                        className="h-44 w-full opacity-0 cursor-pointer"
                        onChange={handleChange}
                        ref={refInput}
                    />
                </div>
                <div className="upload-files">
                    {filesData.map((file, i) => (
                        <FileItem
                            name={file.name}
                            size={file.size}
                            lastModified={file.format}
                            key={i}
                            driveId={file.drive_id}
                            onDelete={() => handleDelete(i)}
                        />
                    ))}
                </div>
            </div>
            <span className="text-gray-400 text-2xs mt-1">
                Archivos permitidos: {extensions.join(', ')}
            </span>
            <Helper>{helper}</Helper>
        </>
    )
}
