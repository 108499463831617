import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import SelectTouch from '../../../components/SelectTouch'
import Category from '../../../models/Category'
import { formSubcategory } from '../storage'

function Name() {
    const { props, getError } = formSubcategory.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe una subcategoría con el mismo nombre'
    )

    return (
        <FormControl label="Subcategoría" required className="mb-4">
            <InputField
                placeholder="Ingresar nombre"
                autoFocus
                {...props}
                helper={props.helper || helper}
            />
        </FormControl>
    )
}

function ProductCategory() {
    const { props, result } = formSubcategory.useSelect<Category>(
        'category_id',
        '/v5.6/apis/categories'
    )

    return (
        <FormControl label="Categoría" required>
            <SelectTouch
                placeholder="Seleccionar una categoría"
                label="Seleccione una categoría"
                {...props}
            >
                <IterateOptions data={result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function Form() {
    return (
        <>
            <Name />
            <ProductCategory />
        </>
    )
}
