import 'moment/locale/es'
import { GetTraspase } from '../../types'
import Information from './Information'
import TableApproval from './TableApproval'
import TableProducts from './TableProducts'

export default function ModalTraspase({ data }: { data: GetTraspase }) {
    return (
        <>
            <Information data={data} />
            <TableApproval data={data} />
            <TableProducts data={data} />
        </>
    )
}
