import { useRender } from 'redity'
import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import Loading from '../../../../components/Loading'
import {
    formSaleDiscounts,
    tableSaleDiscountsControl,
    storagePage
} from '../../storage'
import Fields from './Fields'
import TableDiscounts from './TableDiscounts'

export default function SaleDiscounts() {
    useRender('SaleDiscounts')

    function addSaleDiscount() {
        tableSaleDiscountsControl.push({
            min_quantity: '0',
            max_quantity: '0',
            discount: '0'
        })
    }

    if (formSaleDiscounts.store().company == null) return null
    else {
        if (storagePage.loadingSaleDiscounts) return <Loading />
        else {
            return (
                <>
                    <Fields />
                    <TableDiscounts />
                    {storagePage.modeStep2 === 'edition' && (
                        <Button
                            color="ghost"
                            className="w-full"
                            onClick={() => addSaleDiscount()}
                        >
                            <Icon name="plus" className="mr-2" />
                            Agregar fila
                        </Button>
                    )}
                </>
            )
        }
    }
}
