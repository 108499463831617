import { render } from 'redity'
import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import { formTraspase, reqProducts, reqSubStowageDestiny } from '../../storage'

export default function useSubStowageDestiny() {
    const {
        data,
        props: propsSearch,
        loading
    } = useGet<any>(
        reqSubStowageDestiny.observer(
            '/v5.6/apis/minimal_stowages/destination_substowages_for_traspase?is_enabled=true'
        ),
        []
    )
    const { props } = formTraspase.useSelect('sub_stowage_destination')

    function getSubStowages() {
        const minimalSubStowages: DestinyStowageValue[] = []

        if (data.result) {
            data.result
                .filter(minimal_stowage => {
                    const subStowageDestination =
                        formTraspase.store().sub_stowage_destination
                    if (subStowageDestination) {
                        return (
                            minimal_stowage.id !==
                            parseInt(subStowageDestination.value)
                        )
                    }
                    return true
                })
                .forEach(minimal_stowage => {
                    let name = ''

                    if (minimal_stowage.client_sub_stowage) {
                        name = minimal_stowage.client_sub_stowage.client.name
                    }

                    if (minimal_stowage.order_sub_stowage) {
                        name = minimal_stowage.order_sub_stowage.order.number
                    }

                    if (minimal_stowage.gp_sub_stowage) {
                        name = minimal_stowage.gp_sub_stowage.purchase.code
                    }

                    if (minimal_stowage.is_enabled) {
                        minimalSubStowages.push({
                            id: minimal_stowage.id,
                            name
                        })
                    }
                })
        }
        return minimalSubStowages
    }

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value !== '0') {
            reqProducts.setQuery('destination_minimal_stowage_id', option.value)
            if (reqProducts.hasQuery('origin_minimal_stowage_id')) {
                reqProducts.load()
            }
        }
        render(formTraspase.keyRender, 'sub_stowage_origin')
    }

    function disable(value: string) {
        const { sub_stowage_origin } = formTraspase.store()

        if (sub_stowage_origin?.value === value) return true
        return false
    }

    return {
        props,
        propsSearch,
        minimalSubStowages: getSubStowages(),
        loading,
        handleChange,
        disable
    }
}

interface DestinyStowageValue {
    id: number
    name: string
}
