import Country from '../../../models/Country'
import { formAgent, tableAddress, tableContacts } from '../storage'
import { AgentGet } from '../types'

export default function prepareEdition(agent: AgentGet, countries: Country[]) {
    formAgent.init({
        country: {
            label: agent.country.name,
            value: agent.country_id.toString(),
            image: agent.country.image_url
        },
        type_agents: agent.agent_type_agents
            .filter(agent_type_agent => agent_type_agent.memory)
            .map(agent_type_agent => ({
                label: agent_type_agent.type_agent.name,
                value: agent_type_agent.type_agent_id.toString()
            })),
        name: agent.name,
        fiscal_address: agent.fiscal_address
    })

    const getCountry = (id: number) =>
        countries.find(country => country.id === id)

    tableAddress.setList(
        agent.agent_withdrawal_addresses.map(withdrawal => ({
            id: withdrawal.id,
            is_main: withdrawal.is_main,
            country_id: withdrawal.address.country_id,
            address: withdrawal.address.address,
            locate: withdrawal.address.locate,
            country_image: getCountry(withdrawal.address.country_id).image_url,
            country_name: getCountry(withdrawal.address.country_id).name
        }))
    )

    tableContacts.setList(
        agent.agent_contacts.map(agent_contact => ({
            id: agent_contact.id,
            is_main: agent_contact.is_main,
            name: agent_contact.contact.name,
            position: agent_contact.contact.position,
            phone_number: agent_contact.contact.phone_number,
            email: agent_contact.contact.email
        }))
    )
}
