import useStorage from '../../../../hooks/useStorage'
import './modalInfo.css'
import { ViewProductPrice } from '../../../../types/product_price'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

export default function ModalInfo({ data }: { data: ViewProductPrice }) {
    const { countries } = useStorage()

    const currentCountry = countries.find(
        country => country.id === data.company.country_id
    )

    const currentSubcategoryValue =
        data.product_country.product.subcategory.subcategory_values.find(
            subcategory_value =>
                subcategory_value.company_id === data.company.id
        ) || null

    return (
        <>
            <div className="body__head">
                <div className="title">
                    <h1>{data.product_country.product.description}</h1>
                    <p>{data.product_country.product.code}</p>
                </div>
                <div>
                    <div className="company_badge">
                        <img src={currentCountry.image_url} />
                        <b>{buildCompanyName(data.company.name)}</b>
                    </div>
                </div>
            </div>
            <div className="body__prices">
                <div className="prices">
                    <p className="title_price">Precio de venta</p>
                    <p>
                        {data.product_provider_company !== null
                            ? ` ${
                                  data.product_provider_company.company_currency
                                      .currency.symbol
                              } ${buildNumberFormat(
                                  data.product_provider_company.sale_price
                              )}`
                            : ' -'}
                    </p>
                </div>
                <div className="prices">
                    <p className="title_price">Precio de venta con descuento</p>
                    <p>
                        {data.product_provider_company !== null
                            ? ` ${
                                  data.product_provider_company.company_currency
                                      .currency.symbol
                              } ${buildNumberFormat(
                                  data.product_provider_company.discount_price
                              )}`
                            : ' -'}
                    </p>
                </div>
                <div className="prices">
                    <p className="title_price">Precio de proveedor</p>
                    <p>
                        {data.product_provider_company !== null
                            ? ` ${
                                  data.product_provider_company.company_currency
                                      .currency.symbol
                              } ${buildNumberFormat(
                                  data.product_provider_company.purchase_price
                              )}`
                            : ' -'}
                    </p>
                </div>
            </div>
            <div className="body__cards">
                <div className="column">
                    <div className="card_info">
                        <h3>FACTORES Y DESCUENTO</h3>
                        <ul className="list_info">
                            <li>
                                <p>Factor especial</p>
                                <p>{data.special_factor || '-'}</p>
                            </li>
                            <li>
                                <p>Factor de subcategoría</p>
                                <p>
                                    {currentSubcategoryValue
                                        ? ` ${currentSubcategoryValue.factor}`
                                        : ' -'}
                                </p>
                            </li>
                            <li>
                                <p>Descuento de subcategoría</p>
                                <p>
                                    {currentSubcategoryValue
                                        ? ` ${currentSubcategoryValue.discount}%`
                                        : ' -'}
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="card_info">
                        <h3>DESCUENTO POR VOLUMEN</h3>
                        <table className="table_info">
                            <thead>
                                <tr>
                                    <td>Rango</td>
                                    <td>Descuento</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.sale_discounts.map(sale_discount => (
                                    <tr key={sale_discount.id}>
                                        <td>
                                            {sale_discount.min_quantity} -{' '}
                                            {sale_discount.max_quantity}
                                        </td>
                                        <td>{sale_discount.discount}%</td>
                                    </tr>
                                ))}
                                {data.sale_discounts.length === 0 && (
                                    <tr>
                                        <td
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Sin descuentos
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="column">
                    <div className="card_info">
                        <h3>PROVEEDOR</h3>
                        <ul className="list_info">
                            <li>
                                <p>Nombre comercial</p>
                                <p>
                                    {data.product_provider_company !== null
                                        ? data.product_provider_company
                                              .provider_company.provider
                                              .commercial_name
                                        : ' -'}
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="card_info">
                        <h3>COSTO PROMEDIO Y MARGEN</h3>
                        <ul className="list_info">
                            <li>
                                <p>Costo promedio del producto</p>
                                <p>
                                    {data.last_operation !== null
                                        ? ` ${
                                              data.company.currency.symbol
                                          } ${buildNumberFormat(
                                              data.last_operation
                                                  .average_unit_cost
                                          )}`
                                        : ' -'}
                                </p>
                            </li>
                            <li>
                                <p>Margen de venta</p>
                                <p>
                                    {data.product_provider_company !== null &&
                                    data.last_operation !== null
                                        ? `${buildNumberFormat(
                                              ((data.product_provider_company
                                                  .sale_price -
                                                  data.last_operation
                                                      .average_unit_cost) /
                                                  data.product_provider_company
                                                      .sale_price) *
                                                  100
                                          )} %`
                                        : ' -'}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
