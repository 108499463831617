import AdministrationRoute from './administration.route'
import ConfigurationRoute from './configuration.route'
import RequisitionRoute from './requisition.route'
import PricesRoute from './prices.route'
import MovementsRoute from './movements.route'
import CostingRoute from './costing.route'
import ReportRoute from './reports.route'
import PurchaseOrderRoute from './purchaseOrder.route'
import { RoutingPage } from './types'
import PurchaseOrders from '../../pages/PurchaseOrders'

export default function routes(): RoutingPage[] {
    return [
        {
            label: 'Precios',
            icon: 'bs-tag',
            path: '/prices',
            routes: PricesRoute
        },
        {
            label: 'Requisiciones',
            icon: 'bs-inbox',
            path: '/requisitions',
            routes: RequisitionRoute
        },
        {
            label: 'Órdenes de compra',
            icon: 'bs-truck',
            title: 'Órdenes de compra',
            path: '/purchase_orders',
            cu: 'CU243',
            Component: PurchaseOrders
        },
        {
            label: 'Orden de compra',
            icon: 'bs-truck',
            path: '/purchase_orders',
            cu: 'CU243',
            routes: PurchaseOrderRoute
        },
        {
            label: 'Movimientos',
            icon: 'bs-arrow-left-right',
            path: '/movements',
            routes: MovementsRoute
        },
        {
            label: 'Costeo',
            icon: 'bs-file-spread-sheet',
            path: '/costs',
            routes: CostingRoute
        },
        {
            label: 'Reportes',
            icon: 'bs-files',
            path: '/reports',
            routes: ReportRoute
        },
        {
            label: 'Administración',
            icon: 'bs-diagram-3',
            path: '/administration',
            routes: AdministrationRoute
        },
        {
            label: 'Configuración',
            icon: 'bs-gear',
            path: '/configuration',
            routes: ConfigurationRoute
        }
    ]
}
