import { useEffect } from 'react'
import SearchField from '../../../../components/SearchField'
import List from '../../../../sections/List'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { tableProductsControl } from '../../storage'

function Search() {
    const { props } = tableProductsControl.useSearch()

    return (
        <SearchField
            placeholder="Buscar producto afectado"
            className="mt-3 mb-4"
            fullWidth
            size="full"
            {...props}
        />
    )
}

export default function TableProducts() {
    useEffect(() => {
        tableProductsControl.done().then(() => {
            tableProductsControl.renderMain()
        })
    }, [])

    return (
        <>
            <Search />
            <List
                control={tableProductsControl}
                enumeration={false}
                scrollHeight={250}
            >
                {(simulate, i) => (
                    <tr key={i}>
                        <td>
                            <p>{simulate.code}</p>
                            <p>{simulate.name}</p>
                        </td>
                        <td>
                            {simulate.simulated_price.price === null
                                ? '-'
                                : buildNumberFormat(
                                      simulate.simulated_price.price
                                  )}
                        </td>
                        <td>
                            {simulate.simulated_price.new_price === null
                                ? '-'
                                : buildNumberFormat(
                                      simulate.simulated_price.new_price
                                  )}
                        </td>
                        <td>
                            {simulate.simulated_price.discount === null
                                ? '-'
                                : buildNumberFormat(
                                      simulate.simulated_price.discount
                                  )}
                        </td>
                        <td>
                            {simulate.simulated_price.new_discount === null
                                ? '-'
                                : buildNumberFormat(
                                      simulate.simulated_price.new_discount
                                  )}
                        </td>
                    </tr>
                )}
            </List>
        </>
    )
}
