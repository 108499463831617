import Checking from '../../../../components/Checking'
import Col from '../../../../components/Col'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import { GetProvider } from '../../../../types/provider'
import buildCompanyName from '../../../../utilities/buildCompanyName'

export default function Config({ data: provider }: { data: GetProvider }) {
    return (
        <>
            <Typography type="header-3" className="mb-2">
                Compañias habilitadas para compras
            </Typography>
            <DataTable className="mb-4">
                <THead>
                    <tr>
                        <th>País</th>
                        <th>Compañía</th>
                        <th>Compra</th>
                    </tr>
                </THead>
                <TBody>
                    {provider.provider_companies
                        .filter(
                            provider_companies =>
                                provider_companies.company.is_enabled
                        )
                        .map(provider_companies => (
                            <tr key={provider_companies.id}>
                                <td>
                                    <div className="flex items-center">
                                        <img
                                            src={
                                                provider_companies.company
                                                    .country.image_url
                                            }
                                            alt=""
                                            className="mr-2 w-8"
                                        />
                                        <p>
                                            {
                                                provider_companies.company
                                                    .country.name
                                            }
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    {buildCompanyName(
                                        provider_companies.company.name
                                    )}
                                </td>
                                <td>
                                    <Checking
                                        checked={provider_companies.is_enabled}
                                    />
                                </td>
                            </tr>
                        ))}
                    {provider.provider_companies.filter(
                        provider_companies =>
                            provider_companies.company.is_enabled
                    ).length === 0 && (
                        <Caption colSpan={3}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
            <Typography type="header-3" className="mb-2">
                Configuraciones de proveedor
            </Typography>
            <Col>
                <p className="text-xs text-gray-400">
                    ¿Proveedor con tracking number específico?:{' '}
                    <b>{provider.specific_tracking_number ? 'Si' : 'No'}</b>
                </p>
                <p className="text-xs text-gray-400">
                    ¿Proveedor suministra packing list específico?:{' '}
                    <b>{provider.specific_packing_list ? 'Si' : 'No'}</b>
                </p>
            </Col>
        </>
    )
}
