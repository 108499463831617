import { useContext } from 'react'
import BtnActionTable from '../../components/BtnActionTable'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import Option from '../../components/Option'
import Context from './context'
import { PropsActionActiveDeactive } from './types'

export default function Active({
    children,
    accept,
    label,
    notify = 'La operación se ha completado exitosamente',
    title = 'Activar registro',
    ...props
}: PropsActionActiveDeactive) {
    const { onClick, className, isEnabled, type, disabled, iconHide } =
        useContext(Context)
    if (isEnabled) return null
    function handleClick() {
        onClick({
            ...props,
            title,
            notify,
            accept: accept || 'Sí, activar registro',
            message: children
        })
    }

    return (
        <>
            {!label && (
                <BtnActionTable
                    title="Activar"
                    iconName="bs-unlock"
                    disabled={disabled}
                    onClick={handleClick}
                />
            )}
            {label && (
                <>
                    {type === 'option' && (
                        <Option
                            onClick={handleClick}
                            icon="bs-unlock"
                            disabled={disabled}
                            value="0"
                            label={label}
                        />
                    )}
                    {type === 'button' && (
                        <Button
                            className={className}
                            onClick={handleClick}
                            size="sm"
                            color="success"
                            disabled={disabled}
                        >
                            {!iconHide && (
                                <Icon
                                    name="bs-unlock"
                                    size={14}
                                    className="mr-1"
                                />
                            )}{' '}
                            {label}
                        </Button>
                    )}
                </>
            )}
        </>
    )
}
