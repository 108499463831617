import 'moment/locale/es'
import Ul from '../../../../components/Ul'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import useGet from '../../../../hooks/useGet'
import { ProductInformation } from '../../types'
import { InventoryInformation } from '../../../Requisition/types'
import { storagePage } from '../../storage'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

export default function ProductInventoryView({
    data
}: {
    data: ProductInformation
}) {
    const { data: data_inventory } = useGet<InventoryInformation[]>(
        `/v5.6/apis/requisitions/products/inventory?product_id=${data.product_company.product_country.product.id}&company_id=${storagePage.requisition.minimal_stowage.stowage.company_id}`,
        []
    )
    const items = []
    if (data_inventory.result.length !== 0) {
        data_inventory.result.forEach(item =>
            item.logical_products.forEach(logical_product => {
                let name = logical_product.minimal_stowage.stowage.name
                if (logical_product.minimal_stowage.client_sub_stowage) {
                    name =
                        logical_product.minimal_stowage.client_sub_stowage
                            .client.name
                }

                if (logical_product.minimal_stowage.order_sub_stowage) {
                    name =
                        logical_product.minimal_stowage.order_sub_stowage.order
                            .number
                }

                items.push({
                    presentation: item.presentation_product
                        ? item.presentation_product.amount
                        : '-',
                    quantity: logical_product.quantity_available,
                    quantity_locked: logical_product.quantity_locked,
                    name
                })
            })
        )
    }
    return (
        <>
            <Typography type="header-3" className="mb-4">
                Información ERP
            </Typography>
            <Ul className="mb-4">
                <li>
                    Descripción de producto:
                    <b className="ml-2">
                        {
                            data.product_company.product_country.product
                                .description
                        }
                    </b>
                </li>
                <li>
                    Código:
                    <b className="ml-2">
                        {data.product_company.product_country.product.code}
                    </b>
                </li>
                <li>
                    Grupo de presentación:
                    <b className="ml-2">
                        {data.product_company.product_country.product
                            .presentation_products.length === 0
                            ? '-'
                            : `${data.product_company.product_country.product.presentation_products[0].group_presentation.name} (${data.product_company.product_country.product.presentation_products[0].amount})`}
                    </b>
                </li>
            </Ul>
            <Typography type="header-3" className="mb-4">
                Información del catálogo
            </Typography>
            <Ul className="mb-4">
                <li>
                    Proveedor:
                    <b className="ml-2">
                        {data.provider_company.provider.business_name}
                    </b>
                </li>
                <li>
                    Descripción: <b>{data.provider_description}</b>
                </li>
            </Ul>
            <div className="grid grid-cols-2 gap-4">
                <Ul className="mb-4">
                    <li>
                        Código: <b>{data.provider_code}</b>
                    </li>
                </Ul>
                <Ul className="mb-4">
                    <li>
                        Costo en catálogo:{' '}
                        <b>{`${
                            data.company_currency.currency.symbol
                        } ${buildNumberFormat(data.purchase_price)}`}</b>
                    </li>
                </Ul>
            </div>
            <Typography type="header-3" className="mb-4">
                Inventario actual del producto
            </Typography>
            <DataTable scrollHeight={250}>
                <THead>
                    <tr>
                        <th>Información de inventario</th>
                        <th>Presentación</th>
                        <th>Cantidad disponible</th>
                        <th>Cantidad bloqueada</th>
                    </tr>
                </THead>
                <TBody>
                    {items.map(item => (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.presentation}</td>
                            <td>{item.quantity}</td>
                            <td>{item.quantity_locked}</td>
                        </tr>
                    ))}
                    {items.length === 0 && (
                        <Caption colSpan={5}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
