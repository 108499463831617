import Dialog from './components/Dialog'
import { ModalSection } from './sections/modal'
import ModalSectionDeprecated from './sections/ModalSection'
import View from './sections/View'
import ModalPrint from './sections/ModalPrint'
import NotifyComponent from './utilities/Notify/Component'
import { PopupComponent } from './utilities/popup'
import { Progress, ProgressComponent } from './utilities/progress'
import { states, KEY } from './hooks/useDialog'
import { render, useRender } from 'redity'
import Notify from './utilities/Notify'

export default function GlobalComponents() {
    return (
        <>
            <PopupComponent />
            <NotifyComponent />
            <ProgressComponent />
            <View />
            <ModalSectionDeprecated />
            <ModalSection />
            <ControlDialog />
            <ModalPrint />
        </>
    )
}

function ControlDialog() {
    useRender(KEY)
    const { isOpen, yes, no, children, on, loadingMsg, ...props } = states

    async function handleResult(result) {
        if (!result) {
            states.isOpen = false
            render(KEY)
            return
        }
        states.isOpen = false
        render(KEY)
        Progress.open(loadingMsg)
        const isSuccess = await on()
        Progress.close()
        if (isSuccess || isSuccess === undefined) {
            Notify.success('La operación se ha completado exitosamente')
        }
    }

    return (
        <Dialog
            open={isOpen}
            {...props}
            accept={yes}
            cancel={no}
            onResult={handleResult}
        >
            {isOpen && children}
        </Dialog>
    )
}
