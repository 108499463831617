import BtnActionTable from '../../../components/BtnActionTable'
import { TableList } from '../../../sections/ListForm'
import { formProductLicense, storagePage, tableProducts } from '../storage'
import setParamsSearch from '../utilities/setParamsSearch'

export default function TableProducts() {
    const remove = function (product_id: number) {
        const filtered = storagePage.products
            .getCurrents()
            .filter(product => product.id !== product_id)
            .map(product => ({
                label: product.name,
                value: product.id.toString()
            }))
        formProductLicense.setValue('products', filtered)
        const index = tableProducts
            .getDataList()
            .findIndex(prod => prod.id === product_id)
        tableProducts.del(index)
        setParamsSearch(true)
    }

    return (
        <>
            <TableList
                control={tableProducts}
                className="mt-6"
                scrollHeight={350}
            >
                {(data, _, e) => (
                    <tr key={e}>
                        <td>{data.product_name}</td>
                        <td>{data.brand_name}</td>
                        <td>{data.subcategory_name}</td>
                        <td>
                            <div className="group-btn-options">
                                <BtnActionTable
                                    title="Eliminar"
                                    iconName="bs-trash"
                                    onClick={() => remove(data.id)}
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}
