import ControlList from '../../utilities/Control/ControlList'
import ControlTabs from '../../utilities/ControlTabs'
import FormEvents from '../../utilities/FormEvents'
import { FilterFormFields, GetRequisitions, StoragePage } from './types'
import { Keys, tHeadsPending } from './constants'

export const controlTabs = new ControlTabs()

export const controlList = new ControlList<GetRequisitions>(Keys.MAIN, {
    path: '/v5.6/apis/requisitions',
    theads: tHeadsPending
})

controlList.badgeRemove('company_id', false)

export const filterForm = new FormEvents<FilterFormFields>(Keys.FORM_FILTER, {
    stowage_id: null,
    provider_id: null
})

export const storagePage: StoragePage = {
    company_enabled: false,
    timezone: ''
}
