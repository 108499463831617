import Checking from '../../../../../components/Checking'
import DataTable, { TBody, THead } from '../../../../../components/DataTable'
import UploadField from '../../../../../components/UploadField'
import { parseDriveFile } from '../../../../../utilities/fileTools'
import { ProductView } from '../../../types'

export default function TablePermissions({
    productCountries
}: {
    productCountries: ProductView['product_countries']
}) {
    return (
        <DataTable className="mb-3" scrollHeight={250}>
            <THead>
                <tr>
                    <th>País</th>
                    <th>Nacionalización</th>
                    <th>Embarque</th>
                    <th>Ministro de Salud</th>
                </tr>
            </THead>
            <TBody>
                {productCountries.map(pc => (
                    <tr key={pc.id}>
                        <td>
                            <div className="flex items-center">
                                <img
                                    src={
                                        pc.product_companies[0].company.country
                                            .image_url
                                    }
                                    className="w-8"
                                />
                                <p className="ml-2">
                                    {
                                        pc.product_companies[0].company.country
                                            .name
                                    }
                                </p>
                            </div>
                        </td>
                        <td>
                            <Checking checked={pc.nationalization_permission} />
                        </td>
                        <td>
                            <Checking checked={pc.shipment_permission} />
                        </td>
                        <td>
                            <div className="flex items-center">
                                <Checking
                                    className="mr-2"
                                    checked={pc.ministry_health_permission}
                                />
                                {pc.ministry_health_permission &&
                                    pc.files.length > 0 && (
                                        <UploadField
                                            files={pc.files.map(f =>
                                                parseDriveFile(f)
                                            )}
                                            disabled
                                        />
                                    )}
                            </div>
                        </td>
                    </tr>
                ))}
            </TBody>
        </DataTable>
    )
}
