import Icon from '../../../../components/Icon'
import Tooltip from '../../../../components/Tooltip'
import { tableDispatch, tableWithdrawal } from '../../storage'

export function TooltipDispatch() {
    const { message } = tableDispatch.useHelpers()
    return (
        <>
            {message && (
                <Tooltip content={message}>
                    <Icon name="warning" className="text-red-500" />
                </Tooltip>
            )}
        </>
    )
}

export function TooltipRetreat() {
    const { message } = tableWithdrawal.useHelpers()
    return (
        <>
            {message && (
                <Tooltip content={message}>
                    <Icon name="warning" className="text-red-500" />
                </Tooltip>
            )}
        </>
    )
}
