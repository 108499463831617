import DataTable, { Caption, TBody, THead } from '../../../components/DataTable'
import Typography from '../../../components/Typography'
import Ul from '../../../components/Ul'
import useStorage from '../../../hooks/useStorage'
import { AgentView } from '../types'

export default function Info({ data }: { data: AgentView }) {
    const { storage } = useStorage()
    const countries = storage.countries

    const getCoutryElement = (country_id: number) => {
        const currentCountry = countries.find(
            country => country.id === country_id
        )

        return (
            <div className="flex items-center">
                <img src={currentCountry.image_url} className="mr-2 w-6" />
                <p>{currentCountry.name}</p>
            </div>
        )
    }

    return (
        <>
            <Typography type="header-3" className="mb-4">
                Información general
            </Typography>
            <Ul className="mb-4">
                <li>
                    Agente: <b>{data.name}</b>
                </li>
                <li>
                    Pais de ubicación: <b>{data.country.name}</b>
                </li>
                <li>
                    Tipo de agente:{' '}
                    <b>
                        {data.agent_type_agents
                            .filter(ata => ata.memory)
                            .map(ata => ata.type_agent.name)
                            .join(' - ')}
                    </b>
                </li>
                <li>
                    Dirección fiscal: <b>{data.fiscal_address}</b>
                </li>
            </Ul>
            <Typography type="header-3" className="mb-2">
                Personal de contacto
            </Typography>
            <DataTable className="mb-4" scrollHeight={250}>
                <THead>
                    <tr>
                        <th>Nombres</th>
                        <th>Principal</th>
                        <th>Cargo</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                    </tr>
                </THead>
                <TBody>
                    {data.agent_contacts.map(agent_contact => (
                        <tr key={agent_contact.id}>
                            <td>{agent_contact.contact.name}</td>
                            <td>{agent_contact.is_main ? 'Si' : 'No'}</td>
                            <td>{agent_contact.contact.position}</td>
                            <td>{agent_contact.contact.email}</td>
                            <td>{agent_contact.contact.phone_number}</td>
                        </tr>
                    ))}
                    {data.agent_contacts.length === 0 && (
                        <Caption colSpan={5}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
            <Typography type="header-3" className="mb-2">
                Direcciones de retiro
            </Typography>
            <DataTable className="mb-4" scrollHeight={250}>
                <THead>
                    <tr>
                        <th>Lugar de retiro</th>
                        <th>País</th>
                        <th>Principal</th>
                        <th>Dirección</th>
                    </tr>
                </THead>
                <TBody>
                    {data.agent_withdrawal_addresses.map(awa => (
                        <tr key={awa.id}>
                            <td>{awa.address.locate}</td>
                            <td>{getCoutryElement(awa.address.country_id)}</td>
                            <td>{awa.is_main ? 'Si' : 'No'}</td>
                            <td>{awa.address.address}</td>
                        </tr>
                    ))}
                    {data.agent_withdrawal_addresses.length === 0 && (
                        <Caption colSpan={4}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
