import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import {
    storagePage,
    formTraspase,
    productsControl,
    reqWarehouse,
    reqStowageOrigin,
    reqSubStowageDestiny,
    reqProducts
} from './storage'
import { useParams } from 'react-router'
import { GetTraspase } from './types'
import useStorage from '../../hooks/useStorage'
import moment from 'moment'
import useInitialEdition from './useInitialEdition'
import { reqStowageDestination } from '../Traspases/storage'

export default function useInitial() {
    type ParamData = { traspase_id?: string }
    const { traspase_id } = useParams<ParamData>()
    const { companiesFromSession } = useStorage()
    const [pageState, setState] = useState<StateCase>(StateCase.LOADING)
    const loadEdition = useInitialEdition()

    storagePage.companies = companiesFromSession

    let title = 'Nuevo traspaso'
    if (traspase_id) {
        storagePage.mode = 'edition'
        title = 'Editar traspaso'
    }

    async function load() {
        if (storagePage.mode !== 'creation') {
            const response = await get<GetTraspase>(
                `/v5.6/apis/traspases/${traspase_id}`
            )

            if (response.error) {
                setState(StateCase.ERROR)
                return
            }
            storagePage.traspase = response.data.result
            storagePage.timezone = companiesFromSession.find(
                c => c.id === response.data.result.company_warehouse.company_id
            ).timezone
            storagePage.current_traspase_products = []
            loadEdition(response.data.result)
        } else {
            if (formTraspase.store().company) {
                reqWarehouse.setQuery(
                    'company_id',
                    formTraspase.store().company.value
                )
                reqStowageOrigin.setQuery(
                    'company_id',
                    formTraspase.store().company.value
                )
                reqStowageDestination.setQuery(
                    'company_id',
                    formTraspase.store().company.value
                )
                const company = companiesFromSession.find(
                    _company =>
                        _company.is_enabled &&
                        _company.id ===
                            parseInt(formTraspase.store().company.value)
                )
                storagePage.timezone = company.timezone
            } else {
                const companies = companiesFromSession.filter(
                    _company => _company.is_enabled
                )
                const company = companies[0]
                formTraspase.setValue('company', {
                    label: company.name,
                    image: company.country.image_url,
                    value: company.id.toString()
                })
                storagePage.timezone = company.timezone
                reqWarehouse.setQuery('company_id', company.id)
                reqStowageOrigin.setQuery('company_id', company.id)
                reqStowageDestination.setQuery(
                    'company_id',
                    formTraspase.store().company.value
                )
            }

            const date = moment().tz(storagePage.timezone).parseZone()
            formTraspase.setValue('date_request', date.toISOString(true))

            reqProducts.setQuery(
                'operation_date',
                moment(formTraspase.store().date_request).format('YYYY-MM-DD')
            )
        }
        setState(StateCase.SUCCESS)
    }

    useEffect(() => {
        reqWarehouse.setQuery('is_enabled', true)
        load()
        return () => {
            storagePage.mode = 'creation'
            formTraspase.init()
            formTraspase.clearProps()
            productsControl.setList([])
            reqWarehouse.init()
            reqStowageOrigin.init()
            reqSubStowageDestiny.init()
            reqProducts.init()
            formTraspase.setProps(
                'stowage_destination',
                { disabled: true },
                false
            )
            formTraspase.setProps(
                'sub_stowage_origin',
                { disabled: true },
                false
            )
            formTraspase.setProps(
                'sub_stowage_destination',
                { disabled: true },
                false
            )
            formTraspase.setProps(
                'traspase_products',
                { disabled: true },
                false
            )
        }
    }, [])

    return {
        pageState,
        title
    }
}
