import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import WorkingPeriod from '../../models/WorkingPeriod'
import ControlRequest from '../../utilities/ControlRequest'

export const controlList = new ControlList<WorkingPeriod>(Keys.MAIN, {
    path: '/v5.6/apis/working_periods/all',
    theads: []
})

export const reqYears = new ControlRequest()
