import DataTable, { TBody, THead } from '../../../../../components/DataTable'
import { ProductView } from '../../../types'

export default function TableIva({
    productCountries
}: {
    productCountries: ProductView['product_countries']
}) {
    return (
        <DataTable className="mb-3" scrollHeight={250}>
            <THead>
                <tr>
                    <th>País</th>
                    <th>IVA</th>
                </tr>
            </THead>
            <TBody>
                {productCountries.map(pc => (
                    <tr key={pc.id}>
                        <td>
                            <div className="flex">
                                <img
                                    src={
                                        pc.product_companies[0].company.country
                                            .image_url
                                    }
                                    className="w-8 mr-2"
                                />
                                <p>
                                    {
                                        pc.product_companies[0].company.country
                                            .name
                                    }
                                </p>
                            </div>
                        </td>
                        <td>{pc.tax}%</td>
                    </tr>
                ))}
            </TBody>
        </DataTable>
    )
}
