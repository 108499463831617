// ===================================== //
// STYLE
// ===================================== //
export const SIDEBAR_WIDTH = '240px'
export const NAVBAR_HEIGHT = '65px'
/**
 * LINKS DRIVE
 */
export const DRIVE_UPLOAD = 'https://drive.google.com/a/bitzone.lat/uc?id='
export const DRIVE_DOWNLOAD = 'https://drive.google.com/uc?export=download&id='
/**
 * Storage KEYS
 */
export const STORAGE_KEY = 'storage-data'
export const STORAGE_COMPANY_SELECTED = 'storage-company_selected'
/**
 * Admin Users by ID
 */
export const ADMINISTRATORS = [1, 2]
/**
 * Sessión
 */
export const SESSION = {
    username: process.env.REACT_APP_USERNAME || '',
    password: process.env.REACT_APP_PASSWORD || '',
    core_username: process.env.REACT_APP_CORE_USERNAME,
    core_password: process.env.REACT_APP_CORE_PASSWORD
}
