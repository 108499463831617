export default function Bell(props: any) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 20.923c1.238 0 2.25-.97 2.25-2.154h-4.5c0 1.19 1.007 2.154 2.25 2.154zm6.75-6.384V9.154c0-3.306-1.845-6.074-5.063-6.806v-.733C10.688.722 9.934 0 9 0S7.312.722 7.312 1.615v.733C4.085 3.08 2.25 5.837 2.25 9.154v5.385L0 16.692v1.077h18v-1.077l-2.25-2.153z"
            />
        </svg>
    )
}
