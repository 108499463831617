import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import TableHistory from './TableHistory'
import useInitial from '../useInitial'
import { useHistory } from 'react-router'

export default function LicenseHistory() {
    const pageState = useInitial()
    const { push } = useHistory()
    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title="Historial de licencia" />
                    <PanelBody>
                        <TableHistory />
                    </PanelBody>
                </Panel>
            </div>
            <PageHeader subtitle="Información">
                <Button
                    color="accent"
                    onClick={() => push('/configuration/licenses')}
                >
                    Volver
                </Button>
            </PageHeader>
        </Initial>
    )
}
