import moment from 'moment'
import useRule from '../../../hooks/useRule'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { controlList, storagePage, filterForm } from '../storage'
import { BalanceHistory } from '../types'
import InfoView from './InfoView'

export default function Table() {
    function handleView(balance_history: BalanceHistory) {
        const date = moment(filterForm.store().date).format('YYYY-MM-DD')
        const company_id = controlList.filter.params.company_id
        view(
            `/v5.6/apis/balance_histories/${balance_history.product_id}?date=${date}&company_id=${company_id}`,
            () => ({
                title: `${balance_history.product_code} - ${balance_history.product_description}`,
                Component: InfoView,
                props: {
                    product_id: balance_history.product_id
                }
            })
        )
    }

    const match = useRule('CU108')

    const propsProduct = (balance_history: BalanceHistory) =>
        match
            ? {
                  className: 'underline cursor-pointer font-semibold',
                  onClick: () => handleView(balance_history)
              }
            : {}

    return (
        <List control={controlList}>
            {(data, e) => (
                <tr key={data.product_id}>
                    <td>{e}</td>
                    <td {...propsProduct(data)}>{data.product_code}</td>
                    <td className="max-w-md" style={{ whiteSpace: 'normal' }}>
                        {data.product_description}
                    </td>
                    <td>{data.category_name}</td>
                    <td>{data.quantity}</td>
                    <td>
                        {storagePage.currency.symbol}{' '}
                        {buildNumberFormat(data.average_unit_cost)}
                    </td>
                    <td>
                        {storagePage.currency.symbol}{' '}
                        {buildNumberFormat(
                            data.average_unit_cost * data.quantity
                        )}
                    </td>
                    <td>{data.cost_alert ? 'SIN COSTEAR' : 'COSTEADO'}</td>
                </tr>
            )}
        </List>
    )
}
