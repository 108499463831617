import { useRender } from 'redity'
import Icon from '../../../components/Icon'
import Ul from '../../../components/Ul'
import { Keys } from '../constant'
import { storagePage } from '../storage'

export default function ReportState() {
    useRender(Keys.REPORT_STATE)
    const { importState } = storagePage

    let messageState = 'En espera.'
    switch (importState) {
        case 'importing':
            messageState = 'Importando...'
            break
        case 'validating':
            messageState = 'Validando...'
            break
        case 'waiting':
            messageState = 'A la espera de importación...'
            break
        case 'done':
            messageState = 'Completado'
            break
    }

    return (
        <>
            <p className="text-sm mb-4">{messageState}</p>
            <Ul className="mb-4">
                {storagePage.messagesResult.map((data, i) => (
                    <li key={i} className="flex justify-between">
                        <p>
                            {i + 1}. {data.message}
                        </p>
                        {data.type === 'loading' && (
                            <div>
                                <Icon name="loading" className="animate-spin" />
                            </div>
                        )}
                        {data.type === 'success' && (
                            <Icon name="check-cirle" className="text-success" />
                        )}
                        {data.type === 'error' && (
                            <Icon name="times" className="text-error" />
                        )}
                    </li>
                ))}
            </Ul>
        </>
    )
}
