import { storagePage, formNorms, tableProductsControl } from '../storage'

export default function setParamsSearchProducts(execute = false) {
    const not_product_ids = tableProductsControl.getDataList().map(p => p.id)

    if (storagePage.mode === 'edition') {
        not_product_ids.push(storagePage.product.id)
    }

    if (not_product_ids.length === 0) {
        formNorms.setParams(
            'storable_products',
            {
                sort: 'name'
            },
            execute
        )
    } else {
        formNorms.setParams(
            'storable_products',
            {
                not_product_ids,
                sort: 'name'
            },
            execute
        )
    }
}
