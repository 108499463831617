import { FormControl } from '../../../../components/FormUtilities'
import Toggle from '../../../../components/Toggle'
import { formInformation } from '../../storage'

function Precursor() {
    const { props } = formInformation.useCheck('precursor')
    return (
        <FormControl label="Producto Precursor" col="span-2">
            <Toggle {...props} size="md" />
        </FormControl>
    )
}

function Hazardous() {
    const { props } = formInformation.useCheck('hazardous')
    return (
        <FormControl label="Producto Hazardous" col="span-2">
            <Toggle {...props} size="md" />
        </FormControl>
    )
}

export default function Toggles() {
    return (
        <>
            <Precursor />
            <Hazardous />
        </>
    )
}
