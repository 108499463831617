import ControlTabs from '../../utilities/ControlTabs'
import { ListFormControl } from '../../sections/ListForm'
import ControlRequest from '../../utilities/ControlRequest'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { StoragePage, FormInformation, FormProduct } from './types'
import { dataMemory } from '../../utilities/dataTools'

export const controlTabs = new ControlTabs()

export const storagePage: StoragePage = {
    requisition: null,
    requisitionId: null,
    mode: 'creation',
    modeModal: 'creation',
    step1Done: false,
    loadingProductForm: false,
    products: dataMemory(),
    stowages_destination: [],
    current_type_stowage_id_destination: null,
    minimal_stowage_destiny_id: null,
    is_inventory: false
}

export const formInformation = new FormEvents<FormInformation>(
    Keys.INFORMATION_GENERAL,
    {
        company: null,
        stowage_destination: null,
        sub_stowage_destination: null,
        provider: null,
        catalogue_company: null,
        files: [],
        comment: ''
    }
)

export const formProduct = new FormEvents<FormProduct>(
    Keys.INFORMATION_PRODUCT,
    {
        product: null,
        catalogue_products: null,
        quantity: null,
        inventory: []
    }
)

export const reqStowageDestiny = new ControlRequest()
export const reqProducts = new ControlRequest()
export const reqProviders = new ControlRequest()
export const reqSubStowageDestiny = new ControlRequest()
export const reqCatalogueCompany = new ControlRequest()

export const tableProductsControl = new ListFormControl<any>(
    Keys.TABLE_PRODUCTS,
    [
        '#',
        'Descripción de producto (ERP) ',
        'Descripción de producto (Proveedor) ',
        'Cantidad solicitada',
        'Alerta',
        'Acciones'
    ]
)
