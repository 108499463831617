import { render } from 'redity'
import { FormControl } from '../../../../components/FormUtilities'
import NumeralField from '../../../../components/NumeralField'
import { ModalTools } from '../../../../sections/modal/types'
import FormEvents from '../../../../utilities/FormEvents'
import { Keys } from '../../constants'
import { formQuantity, storagePage, tableRequisition } from '../../storage'
import { TableRQ } from '../../types'

export default function useQuantityBuy(
    control: FormEvents<TableRQ>,
    index: number
) {
    const { id, provider_price, pending_amount, checkbox } = control.store()
    const { props } = control.useValue('quantity_buy')

    function handleSubmit(ev, modal: ModalTools) {
        ev.preventDefault()
        const result = formQuantity.test(f => {
            f.quantity
                .isMinor(1, 'El valor mínimo es 1')
                .isEmpty('Se requiere ingresar la cantidad')
        })

        if (result) {
            modal.close()
            tableRequisition.put(index, {
                quantity_buy: parseInt(formQuantity.store().quantity)
            })
            storagePage.req_products_actived[id] = {
                provider_price,
                quantity_buy: parseInt(formQuantity.store().quantity)
            }
            render(Keys.MOUNT)
        }
    }

    function Form() {
        const { props: nextProps, getError } = formQuantity.useInput('quantity')
        const helper = getError(null, null, 'quantity_to_buy')
        return (
            <FormControl label="Cantidad" required>
                <NumeralField
                    {...nextProps}
                    autoFocus
                    helper={nextProps.helper || helper}
                    max={pending_amount}
                />
            </FormControl>
        )
    }

    const open = formQuantity.useModal(Form, {
        title: 'Editar cantidad',
        onSubmit: handleSubmit,
        size: 'sm'
    })

    return {
        quantity_buy: props.value,
        open,
        checkbox
    }
}
