export default function fixValue(
    fieldName: string,
    value: string | number
): string | number | boolean {
    switch (fieldName) {
        case 'tiempo_de_vida':
            return toIntegerOrDefault(value)
        case 'temp_max':
        case 'temp_min':
            return toNumberOrDefault(value)
        case 'precursor':
        case 'hazardous':
        case 'refrigeracion':
        case 'transporte_via_aerea':
        case 'transporte_via_maritima':
            return toBooleanOrDefault(value)
        default:
            if (fieldName.search('comp_') === 0) return value
            if (fieldName.search('codigo_fiscal_') === 0) return value
            return value !== undefined ? value.toString() : value
    }
}

function isBoolean(str) {
    if (!isNaN(str) && Number.isInteger(parseFloat(str))) {
        if (parseFloat(str) === 1 || parseFloat(str) === 0) {
            return true
        }
        return false
    }
    return false
}

function toBooleanOrDefault(value) {
    if (isBoolean(value)) {
        return !!Number(value)
    } else {
        return (value || '').toString() || undefined
    }
}

function toIntegerOrDefault(value) {
    if (!isNaN(value) && Number.isInteger(parseFloat(value))) {
        return parseInt(value)
    }
    return (value || '').toString() || undefined
}

function toNumberOrDefault(value) {
    if (!isNaN(value)) {
        return Number(value)
    }
    return (value || '').toString() || undefined
}
