import Dropdown from '../../../../components/Dropdown'
import Rules from '../../../../components/Rules'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../../components/UtilsResponsive/UtilsResponsive.style'
import OptionExcel from './Export/OptionExcel'
import OptionPDF from './Export/OptionPDF'
import CompanyFilter from './Filters/CompanyFilter'
import Search from './Filters/Search'

export default function Control() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <CompanyFilter />
                </FlexLeft>
                <FlexRight>
                    <Dropdown label="Exportar" icon="download" clear={false}>
                        {/* <Rules cu="CU130"> */}
                        <OptionExcel />
                        <OptionPDF />
                        {/* </Rules> */}
                    </Dropdown>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
