import { render } from 'redity'
import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import MinimalStowage from '../../../../models/MinimalStowage'
import {
    formTraspase,
    productsControl,
    reqProducts,
    reqSubStowageOrigin,
    reqSubStowageDestiny
} from '../../storage'

export default function useSubStowageOrigin() {
    const {
        data,
        props: propsSearch,
        loading
    } = useGet<OriginalStowageOption[]>(
        reqSubStowageOrigin.observer(
            '/v5.6/apis/stowages/:stowage_id/substowages'
        ),
        []
    )
    const { props } = formTraspase.useSelect('sub_stowage_origin')

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value === '0') {
            formTraspase.setProps('traspase_products', { disabled: true })
            reqProducts.deleteQuery('origin_minimal_stowage_id')
        } else {
            formTraspase.setProps('traspase_products', { disabled: false })
            reqProducts.setQuery('origin_minimal_stowage_id', option.value)
            reqSubStowageDestiny.setQuery(
                'origin_minimal_stowage_id',
                option.value
            )
        }
        productsControl.setList([])
        formTraspase.setValue('traspase_products', [])
        reqProducts.deleteQuery('not_logical_product_ids')
        reqProducts.load()
        productsControl.renderMain()
        render(formTraspase.keyRender, 'sub_stowage_destination')
    }

    function disable(value: string) {
        const { sub_stowage_destination } = formTraspase.store()

        if (sub_stowage_destination?.value === value) return true
        return false
    }

    return {
        props,
        loading,
        propsSearch,
        data: data,
        handleChange,
        disable
    }
}

interface OriginalStowageOption extends MinimalStowage {
    substowage_name: string
}
