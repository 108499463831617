import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import Filters from './Filters'
import { useHistory } from 'react-router-dom'
import Button from '../../../components/Button'
import Tables from './Tables'
import useIsCompanyDisabled from '../useIsCompanyDisabled'
import { useRender } from 'redity'
import { Keys } from '../constants'
import Rules from '../../../components/Rules'
import { ocList } from '../storage'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'

export default function PurchaseOrders() {
    const { pageState } = useInitial()

    return (
        <Initial pageState={pageState}>
            <Panel>
                <PanelBody>
                    <Header />
                    <Tables />
                </PanelBody>
            </Panel>
        </Initial>
    )
}

function Search() {
    const { props } = ocList.useSearch()

    return (
        <SearchField
            {...props}
            placeholder="Buscar por código de orden de compra"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

function Header() {
    useRender(Keys.PANEL_HEADER)
    const { push } = useHistory()
    const disabled = useIsCompanyDisabled()

    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Filters />
                </FlexLeft>
                <FlexRight>
                    <Rules cu={'CU244'}>
                        <Button
                            size="sm"
                            className="ml-2"
                            disabled={disabled}
                            onClick={() => push('/purchase_orders/creation')}
                        >
                            Nuevo
                        </Button>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
