import DropContent from '../../../components/DropContent'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectTouch from '../../../components/SelectTouch'
import useGet from '../../../hooks/useGet'
import Provider from '../../../models/Provider'
import TypePurchase from '../../../models/TypePurchase'
import { formFilter, ocList, reqProvider } from '../storage'

export default function FiltersContainer() {
    const { props } = ocList.useForm(formFilter)

    return (
        <DropContent {...props} label="Filtros">
            <TypeOC />
            <ProviderField />
        </DropContent>
    )
}

function TypeOC() {
    const { props } = formFilter.useSelect('type_purchase_id')
    const { data } = useGet<TypePurchase[]>('/v5.6/apis/type_purchases/all', [])

    return (
        <FormControl label="Tipo de orden de compra">
            <SelectTouch
                {...props}
                label="Tipo de orden de compra"
                placeholder="Seleccionar tipo de orden de compra"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function ProviderField() {
    const { props } = formFilter.useSelect<Provider>('provider_id')
    const {
        data,
        loading,
        props: propsSearch
    } = useGet<Provider[]>(
        reqProvider.observer('/v5.6/apis/providers?limit=20', true),
        []
    )

    return (
        <FormControl label="Proveedor" className="mt-2">
            <SelectTouch
                {...props}
                {...propsSearch}
                loading={loading}
                placeholder="Seleccionar proveedor"
            >
                <IterateOptions data={data.result} label="commercial_name" />
            </SelectTouch>
        </FormControl>
    )
}
