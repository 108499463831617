import moment from 'moment'
import useRule from '../../../hooks/useRule'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList, storagePage, filterForm } from '../storage'
import InfoView from './InfoView'
import BtnActionTable from '../../../components/BtnActionTable'

export default function Table() {
    function handleView(data: any) {
        const company_id = controlList.filter.params.company_id
        view(
            `/v5.6/apis/inventory_transit/${data.product_id}/ocs?company_id=${company_id}`,
            () => ({
                title: `${data.product_code} - ${data.product_description}`,
                Component: InfoView,
                props: {}
            })
        )
    }

    return (
        <List control={controlList}>
            {(data, e) => (
                <tr key={data.product_id}>
                    <td>{e}</td>
                    <td>{data.product_code}</td>
                    <td style={{ whiteSpace: 'normal', maxWidth: 500 }}>
                        {data.product_description}
                    </td>
                    <td
                        style={{
                            whiteSpace: 'normal',
                            textAlign: 'center',
                            maxWidth: 180
                        }}
                    >
                        {data.quantity}
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <BtnActionTable
                                title="Listar inventario por OC"
                                iconName="bs-eye"
                                onClick={() => handleView(data)}
                                disabled={false}
                            />
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
