import moment from 'moment'
import 'moment/locale/es'
import { useRender } from 'redity'
import Badge from '../../../components/Badge'
import useGet from '../../../hooks/useGet'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { Keys } from '../constants'
import { reqSummaryData, storagePage } from '../storage'
import { GeneralInformation } from '../types'

export default function Data() {
    const { data } = useGet<GeneralInformation>(
        reqSummaryData.observer(
            `/v5.6/apis/balance_histories/general_information`,
            true
        ),
        {
            total_balance: 0,
            quantity_cost_alerts: 0,
            quantity_products: 0,
            current_date: moment().format('YYYY-MM-DD')
        }
    )
    const { total_balance, quantity_products, current_date } = data.result
    useRender(Keys.HEADER)

    moment.locale('es')
    const date = moment(current_date).format('LL')

    const getSymbol = () => storagePage.currency.symbol

    return (
        <div className="w-full stats mb-4 border-2">
            <div className="stat place-items-center place-content-center">
                <div className="stat-title">Saldo total en la compañía</div>
                <div className="stat-value">
                    {getSymbol()} {buildNumberFormat(total_balance)}
                </div>
            </div>
            <div className="stat place-items-center place-content-center">
                <div className="stat-title">
                    Cantidad productos en la compañía
                </div>
                <div className="stat-value">{quantity_products}</div>
            </div>
            <div className="stat place-items-center place-content-center">
                <div className="stat-title">Inventario hasta el día</div>
                <div className="stat-desc">
                    <Badge color="info">{date}</Badge>
                </div>
            </div>
        </div>
    )
}
