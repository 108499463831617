import Information from './Information'
import Instruction from './Instruction'

export default function GeneralInformation() {
    return (
        <>
            <Information />
            <Instruction />
        </>
    )
}
