import BtnActionTable from '../../../../../components/BtnActionTable'
import Icon from '../../../../../components/Icon'
import Delete from '../../../../../sections/Delete'
import { TableList } from '../../../../../sections/ListForm'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import Aditional from '../aditional'
import useSubmitCostByProduct from '../FormCostByProduct/useSubmitCostByProduct'
import useSubmitCostGeneral from '../FormCostGeneral/useSubmitCostGeneral'
import { reqLogisticalCost3 } from '../storageStep3'
import useTableAditionalInternationalCosts from './useTableAditionalInternationalCosts'

export default function TableAditionalInternationalCosts() {
    const { tableAditionalInternationalCostControl, symbol } =
        useTableAditionalInternationalCosts()

    return (
        <TableList
            className="sticky"
            control={tableAditionalInternationalCostControl}
            scrollHeight={350}
        >
            {(item, _, e) => (
                <tr key={item.id}>
                    <td>{e}</td>
                    <td>
                        {item.has_files && (
                            <Icon
                                name="description"
                                className="text-info mr-2"
                                size={20}
                            />
                        )}
                        {item.concept}
                    </td>
                    <td>
                        <div>{item.agent_name}</div>
                        <div>{item.document_number}</div>
                    </td>
                    <td>{item.type}</td>
                    <td>{item.cost_to_receipt}</td>
                    <td>
                        {item.symbol} {buildNumberFormat(item.original_cost)}
                    </td>
                    <td>{item.exchange_rate.toFixed(4)}</td>
                    <td>
                        {symbol} {buildNumberFormat(item.local_cost)}
                    </td>
                    <Actions logistical_cost={item} />
                </tr>
            )}
        </TableList>
    )
}

function Actions({ logistical_cost }: { logistical_cost: Aditional.TableICA }) {
    const openCG = useSubmitCostGeneral('international', logistical_cost.id)
    const openCBP = useSubmitCostByProduct('international', logistical_cost.id)

    const type_logistica_cost_id = logistical_cost.type_logistica_cost_id

    return (
        <td>
            <div className="group-btn-options">
                <BtnActionTable
                    title="Editar"
                    iconName="bs-pencil"
                    onClick={type_logistica_cost_id === 2 ? openCBP : openCG}
                />
                <Delete
                    title="Remover costo general"
                    path={`/v5.6/apis/logistical_costs/${logistical_cost.id}`}
                    control={async () => {
                        await reqLogisticalCost3.load()
                    }}
                >
                    Al remover el costo, se recalculará el monto a costear.
                    ¿Está seguro de remover el costo{' '}
                    <b>{logistical_cost.concept}</b> del proceso de costeo?
                </Delete>
            </div>
        </td>
    )
}
