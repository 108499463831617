import BtnActionTable from '../../../../components/BtnActionTable'
import Toggle from '../../../../components/Toggle'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { tableAddress } from '../../storage'
import { TableAddress } from '../../types'
import DeleteAddress from './DeleteAddress'
import useAddress from './useAddress'

function Edit({ data, index }: { data: TableAddress; index: number }) {
    const open = useAddress(index)
    function handleClick() {
        open({
            address: data.address,
            country: {
                label: data.country_name,
                value: data.country_id.toString(),
                image: data.country_image
            },
            locate: data.locate
        })
    }

    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={handleClick}
        />
    )
}

function Row({
    control,
    index
}: {
    control: FormEvents<TableAddress>
    index: number
}) {
    const data = control.store()

    function handleChange(ev) {
        const checked = ev.target.checked
        props.onChange(ev)
        if (checked) {
            tableAddress.list.forEach((d, i) => {
                if (i !== index && d.store().country_id === data.country_id) {
                    d.setValue('is_main', false)
                }
            })
        } else {
            for (let i = 0; i < tableAddress.list.length; i++) {
                const formAddress = tableAddress.list[i]
                if (
                    i !== index &&
                    formAddress.store().country_id === data.country_id
                ) {
                    formAddress.setValue('is_main', true)
                    break
                }
            }
        }
    }

    const { props } = control.useCheck('is_main')

    return (
        <tr>
            <td>{data.locate}</td>
            <td>
                <div className="flex items-center">
                    <img src={data.country_image} className="mr-2 w-8" />
                    {data.country_name}
                </div>
            </td>
            <td>
                <Toggle {...props} onChange={handleChange} />
            </td>
            <td>{data.address}</td>
            <td>
                <div className="group-btn-options">
                    <Edit data={data} index={index} />
                    <DeleteAddress data={data} index={index} />
                </div>
            </td>
        </tr>
    )
}

export default function TableAdresses() {
    return (
        <TableList control={tableAddress} scrollHeight={350}>
            {(_, control, e) => <Row key={e} control={control} index={e - 1} />}
        </TableList>
    )
}
