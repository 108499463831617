import moment from 'moment'
import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import useStorage from '../../hooks/useStorage'
import { getCompanySelected } from '../../utilities/controlStorage'
import { controlList, filterForm, reqSummaryData, storagePage } from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)
    const { companiesFromSession, currencies, originalCompanies } = useStorage()
    useEffect(() => {
        load().then(pageState => setState(pageState))

        return () => {
            filterForm.init()
        }
    }, [])

    async function load() {
        const company = getCompanySelected()
        const companyId = company.id

        controlList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })
        const date = moment()
        controlList.filter.setOption('date', {
            value: date.format('YYYY-MM-DD'),
            label: date.format('DD/MM/YYYY')
        })
        controlList.filter.setParams('with_stock', 'true')
        controlList.filter.changeParams('sort', 'product_code')
        await controlList.load()

        reqSummaryData.setQuery('company_id', companyId)
        reqSummaryData.setQuery('date', moment().format('YYYY-MM-DD'))
        reqSummaryData.deleteQuery('category_id')
        reqSummaryData.deleteQuery('minimal_stowage_id')
        reqSummaryData.deleteQuery('stowage_id')
        reqSummaryData.deleteQuery('cost_alert')
        await reqSummaryData.load()

        storagePage.companies = companiesFromSession
        storagePage.originalCompanies = originalCompanies
        filterForm.setParams('stowage_id', {})

        // if (!result || response.error) return StateCase.ERROR

        // storagePage.header = response.data.result
        storagePage.currency =
            company.local_currency || currencies.find(c => c.id === 1)

        return StateCase.SUCCESS
    }

    return {
        state
    }
}
