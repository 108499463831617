import moment from 'moment'
import FileModel from '../models/FileModel'
import { DriveFile } from './Drive'
import docSVG from '../assets/img/doc.svg'
import docxSVG from '../assets/img/docx.svg'
import jpgSVG from '../assets/img/jpg.svg'
import pngSVG from '../assets/img/png.svg'
import pdfSVG from '../assets/img/pdf.svg'
import xlsSVG from '../assets/img/xls.svg'
import questionSVG from '../assets/img/questionmark.svg'

export function support(files: File[], extenstions: string[]) {
    let include = true
    for (const file of files) {
        const splits = file.name.split('.')
        include = extenstions.includes(splits[splits.length - 1])
        if (!include) break
    }
    return include
}

export function expectSize(files: File[], maxSize: number) {
    let pass = true
    for (const file of files) {
        if (file.size > maxSize) {
            pass = false
            break
        }
    }
    return pass
}

export function parseFileSize(
    fileSize: number,
    type: 'kb' | 'bytes' = 'bytes'
): string {
    const sizes = {
        B: (size: number) => {
            return `${size} B`
        },
        KB: (size: number) => {
            return `${Math.round(size / 1000)} KB`
        },
        MB: (size: number) => {
            return `${Math.round(size / 1000000)} MB`
        },
        GB: (size: number) => {
            return `${Math.round(size / 1000000000)} GB`
        }
    }

    const fs = type === 'bytes' ? fileSize : fileSize * 1000

    if (fs < 1000) {
        return sizes.B(fs)
    } else if (fs >= 1000 && fs < 1000000) {
        return sizes.KB(fs)
    } else if (fs >= 1000000 && fs < 1000000000) {
        return sizes.MB(fs)
    } else if (fs >= 1000000000 && fs < 1000000000000) {
        return sizes.GB(fs)
    }
}

export function parseDriveFile(file: FileModel): DriveFile {
    return {
        filename: file.filename,
        size: file.size,
        id: file.id,
        drive_id: file.drive_id,
        type: file.type,
        modified: moment(file.updated_at || file.created_at).unix(),
        format: moment(file.updated_at || file.created_at).toISOString()
    }
}

export interface DataFile {
    filename: string
    size: number
    drive_id: string
    type: string
}

export function parseFile(file: DriveFile): DataFile {
    return {
        filename: file.filename,
        size: file.size,
        drive_id: file.drive_id,
        type: file.type
    }
}

export function getIconFile(filename: string): string {
    const splits = filename.split('.')
    let icon
    switch (splits[splits.length - 1]) {
        case 'jpg':
            icon = jpgSVG
            break
        case 'jpeg':
            icon = jpgSVG
            break
        case 'png':
            icon = pngSVG
            break
        case 'docx':
            icon = docxSVG
            break
        case 'doc':
            icon = docSVG
            break
        case 'pdf':
            icon = pdfSVG
            break
        case 'xls':
        case 'xlsx':
            icon = xlsSVG
            break
        default:
            icon = questionSVG
            break
    }
    return icon
}
