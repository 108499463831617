import moment from 'moment'
import { render } from 'redity'
import { FormControl } from '../../../../components/FormUtilities'
import Textarea from '../../../../components/Textarea'
import {
    formTraspase,
    reqWorkingPeriod,
    reqProducts,
    productsControl
} from '../../storage'
import DatePicker from '../../../../components/Datepicker'
import useStorage from '../../../../hooks/useStorage'
import useGet from '../../../../hooks/useGet'
import useRule from '../../../../hooks/useRule'
import WorkingPeriod from '../../../../models/WorkingPeriod'

function IssueDate() {
    const { companiesFromSession } = useStorage()
    const company = companiesFromSession.find(
        c => c.id === parseInt(formTraspase.store().company.value)
    )
    const { props, getError } = formTraspase.useDate('date_request')
    const math = useRule('CU996')

    const helper = getError('ERROR-0000104')

    const { data } = useGet<WorkingPeriod>(
        reqWorkingPeriod.observer(
            '/v5.6/apis/working_periods/first_working_period/:company_id',
            true
        )
    )

    const { month, year, created_at } = data.result || {}
    const minDate =
        month && year
            ? moment(created_at)
                  .tz(company.timezone)
                  .parseZone()
                  .toISOString(true)
            : null

    function handleChange(isoString: string) {
        props.onChange(isoString)
        reqProducts.setQuery(
            'operation_date',
            moment(isoString).format('YYYY-MM-DD')
        )
        render('badge_label')
        if (reqProducts.hasQuery('origin_minimal_stowage_id')) {
            formTraspase.setValue('traspase_products', [])
            reqProducts.deleteQuery('not_logical_product_ids')
            productsControl.setList([])
            reqProducts.load()
            productsControl.renderMain()
        }
    }

    return (
        <FormControl label="Fecha de emisión" required>
            <DatePicker
                disabled={!math && month && year}
                minDate={minDate}
                maxDate={moment()
                    .tz(company.timezone)
                    .parseZone()
                    .toISOString(true)}
                {...props}
                onChange={handleChange}
                helper={props.helper || helper}
                placeholder="Seleccionar fecha de emisión"
            />
        </FormControl>
    )
}

function Reason() {
    const { props } = formTraspase.useInput('description')
    return (
        <FormControl label="Motivo" col="span-6">
            <Textarea {...props} placeholder="Descripción" />
        </FormControl>
    )
}

export default function Fields3() {
    return (
        <>
            <IssueDate />
            <Reason />
        </>
    )
}
