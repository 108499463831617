import { FormControl } from '../../../../components/FormUtilities'
import Option from '../../../../components/Option'
import SelectTouch from '../../../../components/SelectTouch'
import useGet from '../../../../hooks/useGet'
import Company from '../../../../models/Company'
import Country from '../../../../models/Country'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import { providerList } from '../../storage'
import { BuyerCompanyFieldProps } from './types'

export default function BuyerCompanyField({
    option,
    setOption,
    helper
}: BuyerCompanyFieldProps) {
    const { data } = useGet<Array<Company & { country: Country }>>(
        `/v5.6/apis/companies/provider_companies_from_session?provider_id=${providerList.filter.params.provider_id}&is_enabled=true`,
        []
    )

    return (
        <FormControl label="Compañía compradora" required className="mb-2">
            <SelectTouch
                placeholder="Seleccionar compañía"
                option={option}
                onChange={setOption}
                helper={helper}
            >
                {data.result.map(company => (
                    <Option
                        key={company.id}
                        label={buildCompanyName(company.name)}
                        image={company.country.image_url}
                        value={company.id.toString()}
                    />
                ))}
            </SelectTouch>
        </FormControl>
    )
}
