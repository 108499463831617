export type KeysCollection<T> = { MAIN: string } & Record<keyof T, string>

export default function createKeys<T>(
    keyMain: string,
    keyValues: Array<keyof T>
) {
    const keys: Record<any, string> = {
        MAIN: keyMain
    }

    for (const key of keyValues) {
        keys[key] = `${keyMain}_${key}`
    }

    return keys as KeysCollection<T>
}
