import Col from '../../../../components/Col'
import Typography from '../../../../components/Typography'
import Ul from '../../../../components/Ul'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { GetProductCompany } from '../../types'

export default function Info({ data }: { data: GetProductCompany }) {
    const { principal_product_provider_company } = data

    const price_buy = `${
        principal_product_provider_company?.company_currency.currency.symbol
    } ${
        principal_product_provider_company?.purchase_price
            ? buildNumberFormat(
                  principal_product_provider_company.purchase_price
              )
            : ''
    }`

    return (
        <>
            <Typography type="header-3" className="mb-4">
                Información de registro
            </Typography>
            <Ul>
                <li>
                    Compañía compradora:
                    <img
                        className="w-6 inline mx-2"
                        src={data.company.country.image_url}
                    />{' '}
                    <b>{data.company.name}</b>
                </li>
                <li>
                    Proveedor principal:{' '}
                    {data?.principal_product_provider_company?.provider_company
                        ?.provider && (
                        <img
                            className="w-6 inline mx-2"
                            src={
                                data.principal_product_provider_company
                                    .provider_company.provider.country.image_url
                            }
                        />
                    )}{' '}
                    <b>
                        {data?.principal_product_provider_company
                            ?.provider_company?.provider.commercial_name ||
                            ' -'}
                    </b>
                </li>
                <li>
                    Precio de compra:{' '}
                    <b>
                        {principal_product_provider_company?.purchase_price
                            ? price_buy
                            : ' -'}
                    </b>
                </li>
                <li>
                    Estado:{' '}
                    <b>
                        {data?.principal_product_provider_company?.is_enabled
                            ? 'Activo'
                            : 'Inactivo'}
                    </b>
                </li>
            </Ul>
            <Typography type="header-3" className="mb-4 mt-4">
                Dependencias
            </Typography>
            <Col>
                <Ul>
                    <li>
                        Compañía habilitada para compra:{' '}
                        <b>{data.can_buy ? 'Si' : 'No'}</b>
                    </li>
                </Ul>
                <Ul>
                    <li>
                        Proveedor habilitado para compra:{' '}
                        <b>
                            {data.principal_product_provider_company
                                ?.provider_company?.is_enabled
                                ? 'Si'
                                : 'No'}
                        </b>
                    </li>
                </Ul>
            </Col>
        </>
    )
}
