import { FormControl } from '../../../../components/FormUtilities'
import Upload from '../../../../components/Upload'
import { formInformation } from '../../storage'

export default function UploadFiles() {
    const { props } = formInformation.useUploads('product_files')
    return (
        <FormControl label="Documentos" col="span-6" row="span-3">
            <Upload {...props} />
        </FormControl>
    )
}
