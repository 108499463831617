import moment from 'moment'
import { useRef, useState } from 'react'
import { Extension } from '../config/types/component'
import Drive, { DriveFile } from '../utilities/Drive'
import { expectSize, support, parseFileSize } from '../utilities/fileTools'

interface UseFileData {
    files: DriveFile[]
    extensions: Extension[]
    /**
     * Bytes
     */
    maxSizeFile: number
    onChange: (files: DriveFile[]) => void
}

export default function useFiles({
    files,
    extensions,
    maxSizeFile,
    onChange
}: UseFileData) {
    const [fileList, setFiles] = useState<File[]>([])
    const refInput = useRef<HTMLInputElement>()

    async function handleChange(ev: any) {
        const _files: File[] = Array.from(ev.target.files)
        if (_files.length === 0) return
        if (!support(_files, extensions)) {
            alert('archivo no soportado')
            return
        }
        if (!expectSize(_files, maxSizeFile)) {
            alert(`Soporte máximo de ${parseFileSize(maxSizeFile)}`)
            return
        }
        setFiles([...fileList, ..._files])
        const drive = new Drive()
        drive.getAuth()
        const responses = await Promise.all(
            _files.map(file => drive.upload(file))
        )
        setFiles([])
        onChange([...files, ...responses])
    }

    function handleDelete(index: number) {
        if (refInput.current) {
            refInput.current.value = null
            refInput.current.files = null
        }
        const filesFiltered = files.filter((_, i) => i !== index)
        onChange(filesFiltered)
    }

    function getFiles() {
        const filesData = files.map(f => ({
            name: f.filename,
            size: f.size,
            drive_id: f.drive_id,
            modified: f.modified,
            format: moment(f.format).format('DD/MM/YYYY')
        }))
        if (fileList.length > 0) {
            for (const f of fileList) {
                filesData.push({
                    name: f.name,
                    size: f.size,
                    drive_id: null,
                    modified: f.lastModified,
                    format: moment(f.lastModified).format('DD/MM/YYYY')
                })
            }
        }
        return filesData
    }

    return {
        handleChange,
        filesData: getFiles(),
        handleDelete,
        refInput
    }
}
