import { parseFile } from '../../../../utilities/fileTools'
import { controlTabs, formInformation, storagePage } from '../../storage'
import { InformationBody } from '../../types'

export default function useStep1Submit() {
    const { observer, validation, submit } = formInformation.useSubmit<any>(
        `/v5.6/apis/requisitions/information`,
        {
            message: '¿Está seguro de guardar cambios de Información General?',
            done: requisition => {
                storagePage.requisitionId = requisition.id
                storagePage.step1Done = true
                controlTabs.next()
            },
            method: 'post'
        }
    )

    validation(v => {
        v.company.isEmpty('Se requiere seleccionar una compañía')
        v.provider.isEmpty('Se requiere seleccionar un proveedor')
        v.catalogue_company.isEmpty(
            'Se requiere seleccionar un catálogo de proveedor'
        )
        v.stowage_destination.isEmpty(
            'Se requiere seleccionar una bodega destino'
        )
        v.files.isEmpty('Se requiere de un archivo como mínimo')
        const { current_type_stowage_id_destination } = storagePage
        if (
            current_type_stowage_id_destination === 2 ||
            current_type_stowage_id_destination === 11
        ) {
            v.sub_stowage_destination.isEmpty(
                'Se requiere seleccionar una sub bodega destino'
            )
        }
    })

    observer<InformationBody>(form => ({
        requisition_id: storagePage.requisitionId || null,
        company_id: parseInt(form.company.value),
        provider_id: parseInt(form.provider.value),
        catalogue_company_id: parseInt(form.catalogue_company.value),
        minimal_stowage_id: form.sub_stowage_destination
            ? parseInt(form.sub_stowage_destination.value)
            : storagePage.minimal_stowage_destiny_id,
        files: form.files.map(f => parseFile(f)),
        comment: form.comment.trim() || null
    }))

    return submit
}
