import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import { GetWarehouses } from '../../types/warehouses'

export const controlList = new ControlList<GetWarehouses>(Keys.MAIN, {
    path: '/v5.6/apis/warehouses',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'name', label: 'Marca' },
        { value: 'is_enabled', label: 'Estado' }
    ]
})
