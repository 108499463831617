import DropContent from '../../../components/DropContent'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectTouch from '../../../components/SelectTouch'
import ProviderType from '../../../models/Provider'
import StowageType from '../../../models/Stowage'
import { controlList, filterForm } from '../storage'

function Stowage() {
    const { props, data } = filterForm.useSelect<StowageType>(
        'stowage_id',
        () => {
            const company_id = controlList.filter.params.company_id
            return `/v5.6/apis/requisitions/stowages?company_id=${company_id}`
        }
    )
    return (
        <FormControl label="Bodega destino">
            <SelectTouch {...props} placeholder="Seleccionar bodega destino">
                <IterateOptions data={data || []} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Provider() {
    const { props, data } = filterForm.useSelect<ProviderType>(
        'provider_id',
        () => {
            return '/v5.6/apis/providers'
        }
    )

    return (
        <FormControl label="Proveedor">
            <SelectTouch {...props} placeholder="Seleccionar proveedor">
                <IterateOptions data={data} label="commercial_name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function FilterForm() {
    const { props } = controlList.useForm(filterForm)
    async function handleApply() {
        controlList.filter.params.page = 1
        await props.onApply()
    }
    return (
        <DropContent {...props} onApply={handleApply} label="Filtros">
            <Stowage />
            <Provider />
        </DropContent>
    )
}
