import { InitialData } from '../../../config/load'
import { GetProvider } from '../../../types/provider'
import {
    formConfig,
    formInfo,
    storagePage,
    tableCompaniesControl,
    tableContactsControl,
    tableDispatch,
    tableWithdrawal
} from '../storage'
import { Step1, Step2, Step3 } from '../types'

export function initStep1(provider: Step1.ResponseResult) {
    formInfo.init({
        business_name: provider.business_name,
        commercial_name: provider.commercial_name,
        type_tax_identification: {
            label: provider.type_tax_identification.name,
            value: provider.type_tax_identification.id.toString()
        },
        tax_identification_number: provider.tax_identification_number || '',
        country: {
            label: provider.country.name,
            value: provider.country.id.toString(),
            image: provider.country.image_url
        },
        link_web_page: provider.link_web_page || '',
        fiscal_address: provider.fiscal_address.address,
        description: provider.description || ''
    })

    tableContactsControl.setList(
        provider.provider_contacts.map(providerContact => ({
            id: providerContact.id,
            name: providerContact.contact.name,
            position: providerContact.contact.position,
            phone_number: providerContact.contact.phone_number,
            email: providerContact.contact.email,
            is_main: providerContact.is_main
        }))
    )
}

export function initStep2(provider: Step2.ResponseResult) {
    tableCompaniesControl.setList(
        provider.provider_companies
            .filter(providerCompany => providerCompany.company.is_enabled)
            .map(providerCompany => ({
                id: providerCompany.company_id,
                name: providerCompany.company.name,
                image: providerCompany.company.country.image_url,
                is_enabled: providerCompany.is_enabled
            }))
            .sort((a, b) => {
                return a.id - b.id
            })
    )

    formConfig.init({
        specific_packing_list: provider.specific_packing_list,
        specific_tracking_number: provider.specific_tracking_number
    })
}

export default function initialEdition(storage: InitialData) {
    const provider = storagePage.provider

    /**
     * step 1
     */

    initStep1(provider)

    /**
     * step 2
     */

    initStep2(provider)

    /**
     * step 3
     */

    const getDataDispatch = (
        dispatchAddress:
            | GetProvider['warehouse_dispatch_addresses'][number]
            | GetProvider['agent_dispatch_addresses'][number]
            | GetProvider['company_dispatch_addresses'][number]
            | GetProvider['other_dispatch_addresses'][number]
    ) => ({
        id: dispatchAddress.id,
        is_main: dispatchAddress.is_main,
        country: {
            id: dispatchAddress.country.id,
            image_url: dispatchAddress.country.image_url,
            name: dispatchAddress.country.name
        },
        type_dispatch: {
            id: dispatchAddress.type_dispatch_id,
            name: dispatchAddress.type_dispatch.name
        },
        enabled: dispatchAddress.is_enabled
    })

    const warehouseDispatches: Step3.TableDispatch[] =
        provider.warehouse_dispatch_addresses.map(warehouseDispatchAddress => ({
            ...getDataDispatch(warehouseDispatchAddress),
            place_dispatch_id:
                warehouseDispatchAddress.warehouse_dispatch.warehouse.id,
            place_dispatch:
                warehouseDispatchAddress.warehouse_dispatch.warehouse.name,
            address_id:
                warehouseDispatchAddress.warehouse_dispatch.warehouse.address
                    .id,
            address:
                warehouseDispatchAddress.warehouse_dispatch.warehouse.address
                    .address
        }))

    const agentDispatchAddresses: Step3.TableDispatch[] =
        provider.agent_dispatch_addresses.map(agentDispatchAddress => ({
            ...getDataDispatch(agentDispatchAddress),
            place_dispatch_id:
                agentDispatchAddress.agent_dispatch.agent_withdrawal_address
                    .agent.id,
            place_dispatch:
                agentDispatchAddress.agent_dispatch.agent_withdrawal_address
                    .agent.name,
            address_id:
                agentDispatchAddress.agent_dispatch.agent_withdrawal_address.id,
            address:
                agentDispatchAddress.agent_dispatch.agent_withdrawal_address
                    .address.address
        }))

    const companiesDispatchCompanies: Step3.TableDispatch[] =
        provider.company_dispatch_addresses.map(companyDispatchAddress => ({
            ...getDataDispatch(companyDispatchAddress),
            place_dispatch_id:
                companyDispatchAddress.company_dispatch.company.id,
            place_dispatch:
                companyDispatchAddress.company_dispatch.company.name,
            address_id: null,
            address: companyDispatchAddress.company_dispatch.company.address
        }))

    const otherDispatchCompanies: Step3.TableDispatch[] =
        provider.other_dispatch_addresses.map(otherDispatchAddress => ({
            ...getDataDispatch(otherDispatchAddress),
            place_dispatch_id: otherDispatchAddress.other_dispatch.id,
            place_dispatch:
                otherDispatchAddress.other_dispatch.address.locate || '',
            address_id: otherDispatchAddress.other_dispatch.address.id,
            address: otherDispatchAddress.other_dispatch.address.address
        }))

    tableDispatch.setList([
        ...warehouseDispatches,
        ...agentDispatchAddresses,
        ...companiesDispatchCompanies,
        ...otherDispatchCompanies
    ])

    const getCountry = (country_id: number) => {
        const countryFinded = storage.countries.find(
            country => country.id === country_id
        )

        return {
            id: countryFinded.id,
            image_url: countryFinded.image_url,
            name: countryFinded.name
        }
    }

    tableWithdrawal.setList(
        provider.provider_withdrawal_addresses.map(
            providerWithdrawalAddress => ({
                id: providerWithdrawalAddress.id,
                country: getCountry(
                    providerWithdrawalAddress.address.country_id
                ),
                place_retreat: providerWithdrawalAddress.address.locate,
                address: providerWithdrawalAddress.address.address,
                is_main: providerWithdrawalAddress.is_main,
                enabled: providerWithdrawalAddress.is_enabled
            })
        )
    )
}
