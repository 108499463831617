import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import Table from './Table'
import Filters from './Filters'

export default function Traspases() {
    const { state } = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <Filters />
                    <Table />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
