import { THeadValue } from '../../utilities/Control/ControlList'
import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('REQUISITIONS', ['FORM_FILTER'])

function getTHeads(dateLabel: string): THeadValue[] {
    return [
        '#',
        { value: 'requisition_code', label: 'Código' },
        'Bodega destino',
        { value: 'provider_name', label: 'Proveedor' },
        { value: 'request_user', label: 'Solicitante' },
        // 'Aprobadores',
        { value: 'desicion_date', label: dateLabel },
        'Cliente'
        // 'Acciones'
    ]
}

export const tHeadsPending: THeadValue[] = getTHeads('Fecha de solicitud')
export const tHeadsApproved: THeadValue[] = getTHeads('Fecha de aprobación')
export const tHeadsRejected: THeadValue[] = getTHeads('Fecha de rechazo')
export const tHeadsCanceled: THeadValue[] = getTHeads('Fecha de cancelación')
