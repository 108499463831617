import { formProductLicense } from '../storage'

export default function setParamsSearch(execute = false) {
    // const not_product_ids = tableProducts.getDataList().map(p => p.id)

    // if (not_product_ids.length === 0) {
    formProductLicense.setParams(
        'products',
        {
            sort: 'name',
            limit: 10
        },
        execute
    )
    // } else {
    //     formProductLicense.setParams(
    //         'products',
    //         {
    //             not_product_ids,
    //             sort: 'name',
    //             limit: 10
    //         },
    //         execute
    //     )
    // }
}
