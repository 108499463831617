import ExcelField from '../../../../components/ExcelField'
import { ExcelRendered } from '../../../../components/ExcelField/types'
import { FormControl } from '../../../../components/FormUtilities'
import Icon from '../../../../components/Icon'
import { ImportFieldProps } from './types'
import writeXlsxFile, { Schema } from 'write-excel-file'

export default function ImportField({
    file,
    setFile,
    setExcelRendered,
    helper
}: ImportFieldProps) {
    function handleChange(_file: File, excelRendered: ExcelRendered) {
        setFile(_file)
        setExcelRendered(excelRendered)
    }

    return (
        <FormControl
            label="Archivo de importación"
            required
            labelComponent={download(file)}
        >
            <ExcelField
                file={file}
                onChange={handleChange}
                dontDownload
                helper={helper}
            />
        </FormControl>
    )
}

function download(file?: File) {
    if (file) return null

    async function download() {
        const schema: Schema<unknown> = [
            {
                column: 'codigo_producto',
                width: 20,
                value: () => ''
            },
            {
                column: 'codigo_producto_proveedor',
                width: 30,
                value: () => ''
            },
            {
                column: 'moneda',
                width: 10,
                value: () => ''
            },
            {
                column: 'precio_compra',
                width: 20,
                value: () => ''
            },
            {
                column: 'descripcion_producto_proveedor',
                width: 40,
                value: () => ''
            }
        ]

        await writeXlsxFile([], {
            fileName: 'plantilla_de_importación_productos.xlsx',
            schema
        })
    }

    return (
        <Icon
            name="download"
            className="text-success"
            pointer
            onClick={download}
        />
    )
}
