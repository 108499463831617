import { tableDispatch, tableWithdrawal } from '../storage'

export default function valStep3() {
    let pass = true

    const notPass = () => {
        if (!pass) return
        pass = false
    }

    /**
     * Dispatch Y Withdrawal
     */

    if (
        tableDispatch.getDataList().length === 0 &&
        tableWithdrawal.getDataList().length === 0
    ) {
        tableDispatch.setMessage(
            'Se requiere registrar al menos una dirección de despacho o retiro'
        )
        notPass()
    }

    /**
     * Dispatch
     */

    for (const data of tableDispatch.getDataList()) {
        const finded = tableDispatch
            .getDataList()
            .find(
                dataTable =>
                    dataTable.is_main &&
                    dataTable.country.id === data.country.id
            )

        if (!finded) {
            notPass()
            tableDispatch.setMessage(
                'Se requiere de al menos una dirección principal por país'
            )
        }
    }

    /**
     * Withdrawal
     */

    for (const data of tableWithdrawal.getDataList()) {
        const finded = tableWithdrawal
            .getDataList()
            .find(
                dataTable =>
                    dataTable.is_main &&
                    dataTable.country.id === data.country.id
            )

        if (!finded) {
            notPass()
            tableWithdrawal.setMessage(
                'Se requiere de al menos una dirección principal por país'
            )
        }
    }

    return pass
}
