import { ReactNode } from 'react'
import { render } from 'redity'
import { DataNotify } from '../../components/Notification'
import { generateId } from '../generateId'

export const KEY_COMPONENT = 'NOTIFICATION'
const data: Map<string, DataNotify> = new Map()
export const TRANSITION = 0.5
export const LIFE = 5000 // Ver ./App.css notification-fade-in
export function getItems(): DataNotify[] {
    return Array.from(data.values())
}

function timeout(key) {
    function execute() {
        data.get(key).show = false
        render(KEY_COMPONENT)
        setTimeout(() => {
            data.delete(key)
            render(KEY_COMPONENT)
        }, TRANSITION * 1000)
    }

    const timeout1 = setTimeout(execute, LIFE)

    return function () {
        clearInterval(timeout1)
        execute()
    }
}

const Notify = {
    success(children: ReactNode, onClick: (ev: any) => void = () => null) {
        const key = generateId()
        data.set(key, {
            key,
            title: 'Éxito',
            children,
            show: true,
            type: 'success',
            onClick: click
        })
        render(KEY_COMPONENT)
        const close = timeout(key)
        function click(ev: any) {
            close()
            onClick(ev)
        }
    },
    waring(children: ReactNode, onClick: (ev: any) => void = () => null) {
        const key = generateId()
        data.set(key, {
            key,
            title: 'Alerta',
            children,
            show: true,
            type: 'warning',
            onClick: click
        })
        render(KEY_COMPONENT)
        const close = timeout(key)
        function click(ev: any) {
            close()
            onClick(ev)
        }
    },
    error(children: ReactNode, onClick: (ev: any) => void = () => null) {
        const key = generateId()
        data.set(key, {
            key,
            title: 'Error',
            children,
            show: true,
            type: 'error',
            onClick: click
        })
        render(KEY_COMPONENT)
        const close = timeout(key)
        function click(ev: any) {
            close()
            onClick(ev)
        }
    }
}

export default Notify
