import { useHistory } from 'react-router'
import { Popup } from '../../utilities/popup'
import submit from '../../utilities/submit'
import {
    storagePage,
    tableFiscalCodeControl,
    tableTaxesControl
} from './storage'
import getBodyAvailability from './utilities/getBodyAvailability'

export default function useSubmitAvailability() {
    const { push } = useHistory()
    return async function handleSubmit() {
        const result = tableTaxesControl.test(v => {
            v.tax.isEmpty('Se requiere ingresar el iva')
        })

        const resulTableFiscalCode = tableFiscalCodeControl.test((v, f) => {
            if (f.fiscal_code) {
                v.fiscal_code.minLength(2, 'Longitud mínima de carácteres es 2')
            }
        })

        if (!result || !resulTableFiscalCode) return

        const body = getBodyAvailability()

        const { error, success } = await submit(
            `/v5.6/apis/products/${storagePage.product.id}/availability`,
            {
                body,
                method: storagePage.mode === 'edition' ? 'put' : 'post',
                confirm: '¿Está seguro de guardar cambios de Disponibilidad?'
            }
        )

        if (error) {
            Popup.error(
                'Algo salió mal...',
                'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
            )
        }

        if (success) {
            push('/configuration/products')
        }
    }
}
