import { TableList } from '../../../../../sections/ListForm'
import useSubmit from '../FormRC/useSubmitRC'
import Delete from '../../../../../sections/Delete'
import useTableRC from './useTableRC'
import Cost from '../cost'
import { reqLogisticalCost1, reqLogisticalCost2, reqRC } from '../storageStep2'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../storage'
import { reqLogisticalCost4 } from '../../SectionAdditional/storageStep3'
import BtnActionTable from '../../../../../components/BtnActionTable'
import { reqRC2 } from '../../SectionSettings/storage'
import ModalReception from '../../../../../modals/ModalReception'
import ModalPurchase from '../../../../../modals/ModalPurchase'
import { view } from '../../../../../sections/View'

export default function TableRCs() {
    const { tableRCsControl, fobDolar, fobLocal, localSymbol } = useTableRC()

    return (
        <>
            <TableList control={tableRCsControl} scrollHeight={350}>
                {(item, _, e) => (
                    <tr key={item.id}>
                        <td>{e}</td>
                        <td>
                            <b
                                {...{
                                    className: 'underline cursor-pointer',
                                    onClick: () =>
                                        view(
                                            `/v5.6/apis/receptions/${item.reception_id}`,
                                            () => ({
                                                title: `DETALLE DE RC`,
                                                Component: ModalReception
                                            }),
                                            'lg'
                                        )
                                }}
                            >
                                {item.rc}
                            </b>
                        </td>

                        <td>
                            <b
                                {...{
                                    className: 'underline cursor-pointer',
                                    onClick: () =>
                                        view(
                                            `/v5.6/apis/purchases/${item.purchase_id}`,
                                            () => ({
                                                title: `DETALLE DE OC`,
                                                Component: ModalPurchase
                                            }),
                                            'lg'
                                        )
                                }}
                            >
                                {item.oc}
                            </b>
                        </td>
                        <td>
                            {item.symbol}{' '}
                            {buildNumberFormat(item.provider_cost)}
                        </td>
                        <td>{item.exchange_rc_to_dolar.toFixed(4)}</td>
                        <td>$ {buildNumberFormat(item.purchase_dolar_cost)}</td>
                        <td>{item.exchange_dolar_to_local.toFixed(4)}</td>
                        <td>
                            {storagePage.currentCurrency.symbol}{' '}
                            {buildNumberFormat(item.purchase_local_cost)}
                        </td>
                        <td>
                            <div className="group-btn-options">
                                <Edit trc={item} />
                                <Delete
                                    title="Remover RC a costear"
                                    path={`/v5.6/apis/logistical_declaration_receptions/${item.id}`}
                                    control={async () => {
                                        await reqRC.load()
                                        await reqLogisticalCost1.load()
                                        reqLogisticalCost2.load()
                                        reqLogisticalCost4.load()
                                        reqRC2.load()
                                    }}
                                >
                                    Al remover la recepción de compra, se
                                    recalculará el monto a costear. ¿Está seguro
                                    de remover la recepción de compra{' '}
                                    <b>{item.rc}</b> del proceso de costeo?
                                </Delete>
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
            <div className="flex justify-end mt-4 font-bold text-xs">
                <div className="bg-gray-200 py-2 px-4">
                    FOB $ {buildNumberFormat(fobDolar)}
                </div>
                <div className="bg-gray-200 py-2 px-4">
                    {localSymbol} {buildNumberFormat(fobLocal)}
                </div>
            </div>
        </>
    )
}

function Edit({ trc }: { trc: Cost.TableRC }) {
    const open = useSubmit(trc.id)
    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={() =>
                open(
                    {
                        purchase_dolar_cost: trc.provider_cost.toString(),
                        exchange_rc_to_dolar:
                            trc.exchange_rc_to_dolar.toString(),
                        purchase_dolar_cost_total:
                            trc.purchase_dolar_cost.toString(),
                        purchase_local_cost: trc.purchase_local_cost.toString(),
                        exchange_dolar_to_local:
                            trc.exchange_dolar_to_local.toString(),
                        purchase_local_cost_total:
                            trc.purchase_local_cost.toString(),
                        rc: {
                            value: trc.reception_id.toString(),
                            label: `${trc.rc} - ${trc.oc} ${
                                trc.is_costing_estimate ? '(Anticipada)' : ''
                            }`
                        }
                    },
                    trc.symbol
                )
            }
        />
    )
}
