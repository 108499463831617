import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { storagePage } from '../../../storage'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { LogisticalDeclarationPDFData } from '../../../types'
import HelveticaBold from '../../../../../assets/fonts/HelveticaBold.otf'
import { generalStyles } from '../GeneralStyles'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const styles = StyleSheet.create({
    ...generalStyles,
    cellCount: {
        width: '6%'
    },
    cellReceptionCode: {
        width: '25%'
    },
    cellProvider: {
        width: '21%'
    },
    cellAmountDolar: {
        width: '18%'
    },
    cellExchange: {
        width: '12%'
    },
    cellAmountLocal: {
        width: '18%'
    },
    cellFooterName: {
        width: '58%',
        fontFamily: 'helvetica-bold'
    }
})

export default function TableLogisticalReceptions({
    data
}: {
    data: LogisticalDeclarationPDFData
}) {
    const sym = storagePage.currency

    return (
        <View>
            <Text style={styles.container__title}>Recepciones de Compra</Text>
            <View style={styles.table}>
                <View style={styles.row}>
                    <Text style={[styles.table__th, styles.cellCount]}>#</Text>
                    <Text style={[styles.table__th, styles.cellReceptionCode]}>
                        Concepto
                    </Text>
                    <Text style={[styles.table__th, styles.cellProvider]}>
                        Proveedor
                    </Text>
                    <Text style={[styles.table__th, styles.cellAmountDolar]}>
                        Monto
                    </Text>
                    <Text style={[styles.table__th, styles.cellExchange]}>
                        TC
                    </Text>
                    <Text style={[styles.table__th, styles.cellAmountLocal]}>
                        Monto
                    </Text>
                </View>
                {data.logistical_declaration_receptions.map((d, i) => (
                    <View key={i} style={styles.row}>
                        <Text style={[styles.table__td, styles.cellCount]}>
                            {i + 1}
                        </Text>
                        <Text
                            style={[styles.table__td, styles.cellReceptionCode]}
                        >
                            {d.reception.code}-{d.purchase.code}
                        </Text>
                        <Text style={[styles.table__td, styles.cellProvider]}>
                            {d.provider.commercial_name}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellAmountDolar,
                                styles.textRight
                            ]}
                        >
                            $ {buildNumberFormat(d.purchase_dolar_cost)}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellExchange,
                                styles.textRight
                            ]}
                        >
                            {buildNumberFormat(d.exchange_dolar_to_local)}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellAmountLocal,
                                styles.textRight
                            ]}
                        >
                            {sym} {''}
                            {buildNumberFormat(d.purchase_local_cost)}
                        </Text>
                    </View>
                ))}
                <View style={styles.row}>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellFooterName,
                            styles.textRight
                        ]}
                    >
                        FOB
                    </Text>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellExchange,
                            styles.textRight
                        ]}
                    >
                        $ {buildNumberFormat(data.data_summary.fob_dolar)}
                    </Text>
                    <Text
                        style={[styles.table__footer__td, styles.cellExchange]}
                    ></Text>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellAmountLocal,
                            styles.textRight
                        ]}
                    >
                        {sym} {''}
                        {buildNumberFormat(data.data_summary.fob_local)}
                    </Text>
                </View>
            </View>
        </View>
    )
}
