import FormEvents from '../../utilities/FormEvents'
import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
import { FormFactor, SimulateSubcategoryValue, StoragePage } from './types'

export const storagePage: StoragePage = {
    subcategoryValues: []
}

export const formFactor = new FormEvents<FormFactor>(Keys.FORM, {
    factor: '',
    discount: '',
    subcategory_name: '',
    category_name: '',
    current_discount: '',
    current_factor: ''
})

export const tableProductsControl = new ControlList<SimulateSubcategoryValue>(
    Keys.TABLE_PRODUCTS,
    {
        theads: [
            'Producto',
            'P.V. actual',
            'P.V. nuevo',
            'P.V. desc actual',
            'P.V. desc nuevo'
        ],
        method: 'post'
    }
)

formFactor.store.on('factor', value => {
    const fac = Number(value)
    if (fac > 1.25) {
        tableProductsControl
            .done(null, {
                fac,
                desc: Number(formFactor.store().discount)
            })
            .then(() => tableProductsControl.renderMain())
    }
})

formFactor.store.on('discount', value => {
    const desc = value === '' ? null : Number(value)
    const factor = formFactor.store().factor
    tableProductsControl
        .done(null, {
            desc,
            fac: factor === '' ? null : Number(formFactor.store().factor)
        })
        .then(() => tableProductsControl.renderMain())
})
