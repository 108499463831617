import { formProduct, tableProductsControl } from '../storage'

export function addProduct() {
    const { product, quantity } = formProduct.store()
    const list = tableProductsControl.list.map(d => d.store())

    tableProductsControl.setList([
        ...list,
        {
            product_data: product,
            quantity: quantity
        }
    ])
}

export function updateProduct(index: number) {
    const { product, quantity } = formProduct.store()
    tableProductsControl.setList(
        tableProductsControl.list.map((d, i) => {
            if (i === index) {
                return {
                    ...d.store(),
                    product_data: product,
                    quantity: quantity
                }
            }
            return d.store()
        })
    )
}
