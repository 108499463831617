import { useState } from 'react'
import { render, useRender } from 'redity'
import { post } from '../../../utilities/http'
import Notify from '../../../utilities/Notify'
import { Keys } from '../constant'
import { storagePage } from '../storage'
import { ResultProductValidate } from '../types'
import excelToBody from '../utilities/excelToBody'
import validateFormatExcel from '../utilities/validateFormatExcel'

export default function useImport() {
    const [isLoading, setLoading] = useState(false)
    useRender(Keys.BUTTONS)

    async function handleClick() {
        storagePage.messagesResult = []
        storagePage.reports = []

        const { excelDataRendered } = storagePage

        storagePage.messagesResult.push({
            message: 'Formato excel validado',
            type: 'success'
        })

        render(Keys.REPORT_STATE)

        if (!validateFormatExcel(excelDataRendered)) {
            storagePage.reports.push({
                row: 1,
                error: 'El archivo no corresponde al formato entregado en la plantilla, descargue la plantilla.'
            })
            storagePage.messagesResult[0] = {
                message: 'Formato excel validado',
                type: 'error'
            }
            render(Keys.REPORT_STATE)
            render(Keys.TABLE_RESULT)
            return
        }

        setLoading(true)

        const body = excelToBody(excelDataRendered)

        storagePage.importState = 'validating'
        storagePage.messagesResult.push({
            message: `Validando ${body.products.length} productos`,
            type: 'loading'
        })

        render(Keys.BUTTON_BACK)
        render(Keys.REPORT_STATE)
        render(Keys.TABLE_RESULT)
        render(Keys.BUTTONS)
        const { data, error } = await post<ResultProductValidate>(
            '/v5.6/apis/products/validate',
            body
        )

        if (error) {
            storagePage.importState = 'nothing'
            storagePage.messagesResult[1] = {
                message: `Validando ${body.products.length} productos`,
                type: 'error'
            }
            setLoading(false)
            render(Keys.REPORT_STATE)
            render(Keys.BUTTONS)
            return
        }

        if (!data.result.success) {
            data.result.errors.forEach(err => {
                storagePage.reports.push({
                    row: err.index + 1,
                    error: err.message
                })
            })
            storagePage.messagesResult[1] = {
                message: `Validando ${body.products.length} productos`,
                type: 'error'
            }
            storagePage.importState = 'nothing'
            Notify.error('Se encontraron algunos errores en los datos')
            setLoading(false)
            render(Keys.REPORT_STATE)
            render(Keys.BUTTONS)
            render(Keys.TABLE_RESULT)
            return
        } else {
            const isPlural = body.products.length > 1
            storagePage.messagesResult[1] = {
                message: `Validando ${body.products.length} producto${
                    isPlural ? 's' : ''
                }`,
                type: 'success'
            }
        }

        storagePage.importState = 'validating'

        const LENGTH = 100
        let piecesOfProducts = [body]
        if (body.products.length > LENGTH) {
            piecesOfProducts = []
            for (let i = 0; i < body.products.length; i += LENGTH) {
                const pieces = body.products.slice(i, i + LENGTH)
                piecesOfProducts.push({
                    products: pieces
                })
            }
        }

        let hasError = false
        for (let i = 0; i < piecesOfProducts.length; i++) {
            const pp = piecesOfProducts[i]
            const count = i === 0 ? 1 : i * LENGTH + 1
            const restCount =
                LENGTH * (i + 1) > body.products.length
                    ? body.products.length
                    : LENGTH * (i + 1)

            storagePage.messagesResult.push({
                message: `Importando ${count} - ${restCount} productos`,
                type: 'loading'
            })
            render(Keys.REPORT_STATE)
            render(Keys.BUTTONS)
            const res = await post('/v5.6/apis/products/uploads', pp)
            storagePage.messagesResult[i + 2] = {
                message: `Importando ${count} - ${restCount} productos`,
                type: res.error ? 'error' : 'success'
            }
            render(Keys.REPORT_STATE)
            render(Keys.BUTTONS)
            if (res.error) {
                hasError = !!res.error
                break
            }
        }

        if (hasError) {
            storagePage.importState = 'nothing'
            Notify.error(
                'Hubo problemas en la importación, por favor vuelva a reintentar'
            )
        } else {
            Notify.success('Importación exitosa')
            storagePage.importState = 'done'
            storagePage.file = null
            render(Keys.IMPORT)
        }

        setLoading(false)
        render(Keys.REPORT_STATE)
        render(Keys.BUTTONS)
        render(Keys.BUTTON_BACK)
    }

    const isDisabled =
        storagePage.reports.length > 0 || storagePage.file === null

    const canItBeCancelled =
        storagePage.importState === 'validating' ||
        storagePage.importState === 'importing'

    return { handleClick, isLoading, isDisabled, canItBeCancelled }
}
