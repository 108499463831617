import Ul from '../../../components/Ul'
import buildCompanyName from '../../../utilities/buildCompanyName'
import { ProductPrividerCompanyGet } from '../types'

export default function ProductInfo({
    data
}: {
    data: ProductPrividerCompanyGet
}) {
    return (
        <>
            <Ul>
                <li>
                    Compañía compradora:{' '}
                    <img
                        src={data.product_company.company.country.image_url}
                        className="w-5 inline mr-2"
                    />
                    <b
                        className={
                            data.product_company.company.memory
                                ? ''
                                : 'line-through'
                        }
                    >
                        {buildCompanyName(data.product_company.company.name)}
                    </b>
                </li>
                <li>
                    Código producto (proveedor): <b>{data.provider_code}</b>
                </li>
                <li>
                    Precio compra:{' '}
                    <b>
                        {data.company_currency.currency.symbol}{' '}
                        {data.purchase_price}
                    </b>
                </li>
                <li>
                    Estado: <b>{data.is_enabled ? 'Activo' : 'Desactivado'}</b>
                </li>
                <li>
                    Descripción: <b>{data.provider_description || '-'}</b>
                </li>
            </Ul>
        </>
    )
}
