import Logo from '../../../../assets/img/logo.png'
import {
    Page,
    Text,
    Document,
    StyleSheet,
    Image,
    View,
    Font
} from '@react-pdf/renderer'
import Table1 from './Table1'
import Table2 from './Table2'
import Univers from '../../../../assets/fonts/UniversLTStd.otf'
import UniversLTStdBold from '../../../../assets/fonts/UniversLTStdBold.otf'
import moment from 'moment'
import { GetPurchase } from '../../../../types/purchase'
import Table3 from './Table3'

Font.register({ family: 'univers', src: Univers })
Font.register({ family: 'univers-bold', src: UniversLTStdBold })

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    subrow: {
        width: '100%',
        flexDirection: 'column'
    },
    bold: {
        fontSize: '9px',
        fontFamily: 'univers-bold',
        textAlign: 'center',
        padding: '5px',
        color: '#2A2A5B'
    },
    labelRed: {
        color: '#E42629',
        fontSize: '9px',
        textAlign: 'center',
        fontFamily: 'univers-bold',
        padding: '5px'
    },
    section: {
        padding: '40px 30px'
    },
    title: {
        fontSize: '16px',
        textAlign: 'center',
        color: '#2A2A5B',
        fontWeight: 'bold',
        fontFamily: 'univers-bold'
    },
    logo: {
        width: '100px',
        marginTop: '20px',
        marginBottom: '10px'
    },
    companyName: {
        fontSize: '10px',
        marginBottom: '5px',
        color: '#2A2A5B',
        fontFamily: 'univers-bold',
        textTransform: 'uppercase'
    },
    companyFiscalDocument: {
        fontFamily: 'univers-bold',
        fontSize: '9px',
        marginBottom: '5px',
        color: '#2A2A5B'
    },
    page: {
        fontSize: '9px',
        marginBottom: '10px',
        fontFamily: 'univers-bold',
        color: '#2A2A5B',
        padding: '5px'
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        border: '1px solid #E2E2E2',
        marginTop: '12px'
    },
    viewContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap'
        // marginBottom: '100px'
    },
    viewContainer2: {
        flexDirection: 'row',
        flexWrap: 'wrap'
        // marginTop: 'auto'
    },
    flex: {
        flexDirection: 'row',
        alignItems: 'center'
    }
})

export default function Pdf({ data }: { data: GetPurchase }) {
    return (
        <Document title={moment().format('DD-MM-YYYY')}>
            <Page size="A4" style={styles.section}>
                <Text style={styles.title}>ORDEN DE COMPRA</Text>
                <View style={styles.row}>
                    <View style={styles.subrow}>
                        <Image src={Logo} style={styles.logo} />
                        <Text style={styles.companyName}>
                            {data.destination_company.name}
                        </Text>
                        {data.destination_company.fiscal_document === '' && (
                            <Text style={styles.companyFiscalDocument}>
                                {data.destination_company.type_fiscal_document}:{' '}
                                {data.destination_company.fiscal_document}
                            </Text>
                        )}
                    </View>
                    <View style={styles.subrow}>
                        <View style={styles.row}>
                            <Text style={styles.bold}>NRO DOC.</Text>
                            <Text style={styles.labelRed}>{data.code}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text
                                style={styles.page}
                                render={({ pageNumber, totalPages }) =>
                                    `Páginas: Pág ${pageNumber} de ${totalPages}`
                                }
                                fixed
                            />
                        </View>
                    </View>
                </View>
                <View style={styles.tableContainer}>
                    <Table1 data={data} />
                </View>
                <View style={styles.tableContainer}>
                    <Table2 data={data} />
                </View>
                <View style={styles.viewContainer}>
                    <Table3 data={data} />
                </View>
            </Page>
        </Document>
    )
}
