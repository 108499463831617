import classNames from 'classnames'
import { ReactNode, useState } from 'react'
import { IoIosArrowUp } from 'react-icons/io'
import { Link, useRouteMatch } from 'react-router-dom'

interface SubItemProps {
    to: string
    children: ReactNode
}

export default function SubItem({ to, children }: SubItemProps) {
    const math = useRouteMatch({
        path: to,
        exact: true
    })
    return (
        <li
            className={classNames('sidebar-menu-item', {
                active: math
            })}
        >
            <Link to={to}>
                <div
                    className={classNames('sidebar-menu-label', {
                        'bg-base-200 font-bold': math
                    })}
                >
                    {children}
                </div>
            </Link>
        </li>
    )
}

export function BlockSubItem({ label, children }) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <div className="sidebar-menu-item" onClick={() => setOpen(!open)}>
                <div className={classNames('sidebar-menu-label')}>{label}</div>
                <div className="sidebar-menu-arrow-icon">
                    <IoIosArrowUp className={classNames('anchor', { open })} />
                </div>
            </div>
            <ul
                className={classNames('sidebar-subitems', {
                    open
                })}
            >
                {children}
            </ul>
        </>
    )
}
