import { useEffect } from 'react'
import buildCompanyName from '../../../utilities/buildCompanyName'
import { ctsView, companySelected } from '../storage'
import { GetCreditNote } from '../type'
import GeneralInformation from './GeneralInformation'
import Products from './Products'

ctsView.setSection('Información general', GeneralInformation)
ctsView.setSection('Productos', Products)

export default function ModalInfo({ data }: { data: GetCreditNote }) {
    const value = ctsView.useStep()
    useEffect(() => {
        return () => {
            ctsView.value = 'step1'
        }
    })

    return (
        <>
            <div className="body__head">
                <div className="title">
                    <h1>NOTA DE CRÉDITO {data.credit_note_number}</h1>
                </div>
                <div>
                    <div className="company_badge">
                        <img src={companySelected.country.image_url} />
                        <b>{buildCompanyName(companySelected.name)}</b>
                    </div>
                </div>
            </div>
            <ctsView.SectionTabs
                value={value}
                type="tab"
                mode="simple"
                className="mb-4"
                data={data}
            />
        </>
    )
}
