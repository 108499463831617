import writeXlsxFile, { Schema } from 'write-excel-file'
import BtnActionTable from '../../../../components/BtnActionTable'
import { get } from '../../../../utilities/http'
import { ClassicTraspaseProductExcel, GetTraspaseExcel } from '../../types'

export default function ExportExcel({ traspase_id }: { traspase_id: number }) {
    async function download() {
        const { data } = await get<GetTraspaseExcel>(
            `/v5.6/apis/traspases/${traspase_id}/excel`
        )
        const schema: Schema<ClassicTraspaseProductExcel> = [
            {
                column: 'Bodega de ingreso',
                width: 20,
                value: () => {
                    return getMinimalStowageName(
                        data.result.destination_minimal_stowage
                    )
                }
            },
            {
                column: 'Bodega de salida',
                width: 20,
                value: () => {
                    return getMinimalStowageName(
                        data.result.origin_minimal_stowage
                    )
                }
            },
            {
                column: 'Código de producto',
                width: 20,
                value: d => {
                    return d.traspase_product.product.code
                }
            },
            {
                column: 'Descripción de producto',
                width: 40,
                value: d => {
                    return d.traspase_product.product.description
                }
            },
            {
                column: 'Marca',
                width: 20,
                value: d => {
                    return d.traspase_product.product.brand.name
                }
            },
            {
                column: 'Cantidad',
                width: 20,
                type: Number,
                format: '0',
                value: d => {
                    return d.traspase_product.input_operation.quantity_marginal
                }
            },
            {
                column: 'Costo Promedio Unitario',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return d.traspase_product.input_operation.average_unit_cost
                }
            },
            {
                column: 'Subtotal',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return (
                        d.traspase_product.input_operation.average_unit_cost *
                        d.traspase_product.input_operation.quantity_marginal
                    )
                }
            }
        ]
        await writeXlsxFile(data.result.classic_traspase_products, {
            fileName: `${data.result.document.code}.xlsx`,
            schema
        })
    }

    return (
        <BtnActionTable
            title="Excel"
            iconName="bs-file-earmark-excel"
            onClick={download}
        />
    )
}

function getMinimalStowageName(minimal_stowage: any) {
    let name = minimal_stowage.stowage.name
    if (minimal_stowage.order_sub_stowage) {
        name = name + ' - ' + minimal_stowage.order_sub_stowage.order.number
    } else if (minimal_stowage.gp_sub_stowage) {
        name = name + ' - ' + minimal_stowage.gp_sub_stowage.purchase.code
    }
    return name
}
