import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import useSubmit from './../useSubmit'
import useCancel from '../../../hooks/useCancel'
import { formConsumptionVoucher, storagePage } from './../storage'

export default function Header() {
    const submit = useSubmit(storagePage.consumptionVoucher?.id || undefined)
    const { onClick } = useCancel(
        formConsumptionVoucher,
        '/movements/consumption_vouchers'
    )
    return (
        <PageHeader subtitle="(*) Complete todos los campos para agregar">
            <Button color="ghost" onClick={onClick}>
                Cancelar
            </Button>
            <Button className="ml-2" onClick={() => submit()}>
                Guardar
            </Button>
        </PageHeader>
    )
}
