import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import SelectTouch from '../../../../components/SelectTouch'
import Textarea from '../../../../components/Textarea'
import Country from '../../../../models/Country'
import { formWithdrawal } from '../../storage'

function CountrySelect() {
    const { props, data } = formWithdrawal.useSelect<Country>(
        'country',
        '/v5.6/apis/countries'
    )

    return (
        <FormControl label="País" className="mb-2" required>
            <SelectTouch {...props} placeholder="Seleccionar país" label="País">
                <IterateOptions data={data} label="name" image="image_url" />
            </SelectTouch>
        </FormControl>
    )
}

function Retrait() {
    const { props } = formWithdrawal.useInput('place_retreat')
    return (
        <FormControl label="Lugar de retiro" className="mb-2" required>
            <InputField {...props} placeholder="Ingresar lugar de retiro" />
        </FormControl>
    )
}

function Address() {
    const { props } = formWithdrawal.useInput('address')
    return (
        <FormControl label="Dirección" className="mb-2" required>
            <Textarea {...props} placeholder="Ingresar dirección" />
        </FormControl>
    )
}

export default function FormWithdrawal() {
    return (
        <>
            <CountrySelect />
            <Retrait />
            <Address />
        </>
    )
}
