import { useHistory } from 'react-router'
import Icon from '../../../components/Icon'
import Option, { SimpleOption } from '../../../components/Option'
import Popover from '../../../components/Popover'
import Pdf from './Pdf'
import { GetTraspase } from '../types'
import useRule from '../../../hooks/useRule'
import Rules from '../../../components/Rules'
import { viewPrint } from '../../../sections/ModalPrint'
import { storagePage } from '../storage'
import BtnActionTable from '../../../components/BtnActionTable'

export function ActionPending({ traspase }: { traspase: any }) {
    const { push } = useHistory()
    const cuExportar = useRule('CU148')
    const cuEditar = useRule('CU145')
    return (
        <>
            {(cuExportar || cuEditar) && (
                <Popover
                    className="ml-2"
                    position="bottom-right-left"
                    element=".panel--form-datatable"
                    component={<Icon name="bs-three-dots" />}
                >
                    {cuEditar && (
                        <Option
                            label="Editar"
                            value="0"
                            icon="pencil"
                            disabled={!storagePage.company_enabled}
                            onClick={() =>
                                push(
                                    `/movements/traspases/${traspase.id}/edition`
                                )
                            }
                        />
                    )}
                    {cuExportar && <LoadPDFPopover traspase={traspase} />}
                </Popover>
            )}
        </>
    )
}

export function ActionApproved({ traspase }: { traspase: any }) {
    return (
        <>
            <Rules cu={'CU148'}>
                <LoadPDF traspase={traspase} />
            </Rules>
        </>
    )
}

export function ActionOther({ traspase }: { traspase: any }) {
    return (
        <>
            <Rules cu={'CU148'}>
                <LoadPDF traspase={traspase} />
            </Rules>
        </>
    )
}

function LoadPDF({ traspase }: { traspase: GetTraspase }) {
    function handleClick() {
        viewPrint(`/v5.6/apis/traspases/${traspase.id}`, () => ({
            Component: Pdf
        }))
    }

    return (
        <BtnActionTable
            title="Imprimir"
            iconName="bs-printer"
            onClick={handleClick}
        />
    )
}

function LoadPDFPopover({ traspase }: { traspase: GetTraspase }) {
    const math = useRule('CU148')

    function handleClick() {
        viewPrint(`/v5.6/apis/traspases/${traspase.id}`, () => ({
            Component: Pdf
        }))
    }

    if (math) {
        return (
            <SimpleOption onClick={handleClick}>Exportar en .pdf</SimpleOption>
        )
    }
}
