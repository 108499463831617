import classNames from 'classnames'
import { PropProgress } from './types'

export default function Progress({
    className = '',
    number = 20,
    color = 'success'
}: PropProgress) {
    return (
        <div className="flex justify-items-center">
            <div className="relative flex items-center">
                <div
                    className={`overflow-hidden h-2 text-xs flex rounded w-24 bg-${color} bg-opacity-20`}
                >
                    <div
                        className={classNames(
                            `shadow-none flex flex-col text-center whitespace-nowrap rounded justify-center bg-${color}`,
                            className
                        )}
                        style={{
                            width: `${number}px`
                        }}
                    ></div>
                </div>
            </div>
            <div className="ml-2 text-sm text-gray-600">{number} %</div>
        </div>
    )
}
