import { render } from 'redity'
import { mergeExpect } from '../../../../utilities/dataTools'
import { Popup } from '../../../../utilities/popup'
import submit from '../../../../utilities/submit'
import {
    storagePage,
    tableSaleDiscountsControl,
    formSaleDiscounts
} from '../../storage'
import { Step2 } from '../../types'
import valStep2 from '../../utilities/valStep2'

async function sendSubmit(body: Step2.BodyProductCompany) {
    const { company } = formSaleDiscounts.store()
    const { success, error, data } =
        await submit<Step2.ProductCompanySaleDiscount>(
            `/v5.6/apis/product_companies/${company.value}/sale_discounts`,
            {
                body,
                message:
                    '¿Está seguro de guardar cambios en descuentos por volumen?',
                method: 'put'
            }
        )

    if (error) {
        Popup.error(
            'Algo salió mal...',
            'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
        )
    }

    if (success) {
        storagePage.productPrice.sale_discounts = data.sale_discounts
        storagePage.productPrice.inventory_barrier = data.inventory_barrier
        tableSaleDiscountsControl.setList(
            data.sale_discounts.map(sale_discount => ({
                id: sale_discount.id,
                min_quantity: sale_discount.min_quantity.toString(),
                max_quantity: sale_discount.max_quantity.toString(),
                discount: sale_discount.discount.toString()
            }))
        )

        storagePage.modeStep2 = 'view'
        formSaleDiscounts.setProps('company', { disabled: false })
        render('Step2')
    }

    return success
}

export default function useSubmit() {
    async function handleSubmit() {
        if (!valStep2()) return

        tableSaleDiscountsControl.setMessage('')

        const getSaleDiscounts = () =>
            mergeExpect(
                tableSaleDiscountsControl.getDataList(),
                storagePage.productPrice.sale_discounts
            )
                .mapping(d => {
                    return {
                        ...(d.id && { deleted: false }),
                        ...d,
                        min_quantity: parseInt(d.min_quantity),
                        max_quantity: parseInt(d.max_quantity),
                        discount: parseFloat(d.discount),
                        deleted: false
                    }
                })
                .deleted(d => ({
                    id: d.id,
                    min_quantity: d.min_quantity,
                    max_quantity: d.max_quantity,
                    discount: d.discount,
                    deleted: true
                }))

        await sendSubmit({
            inventory_barrier: parseInt(
                formSaleDiscounts.store().inventory_barrier
            ),
            sale_discounts: getSaleDiscounts()
        })
    }

    return handleSubmit
}
