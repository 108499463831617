import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ListFormControl from '../sections/ListForm/ListFormControl'
import hasChanges, { ExpectControls } from '../utilities/hasChanges'
import { Popup } from '../utilities/popup'

export default function useCancel(controls: ExpectControls, redirect: string) {
    const { push } = useHistory()
    const [listLengths, update] = useState<number[]>(() => updateLenght())

    function updateLenght() {
        if (controls instanceof ListFormControl) {
            return [controls.list.length]
        }
        if (Array.isArray(controls)) {
            const lengths: number[] = []
            for (const control of controls) {
                if (control instanceof ListFormControl) {
                    lengths.push(control.list.length)
                }
            }
            return lengths
        }
    }

    async function showAlert() {
        const result = await Popup.cancel(
            '¿Desea cancelar sin guardar cambios?'
        )
        if (result) push(redirect)
    }

    function onClick() {
        let pass = true

        if (controls instanceof ListFormControl) {
            if (controls.list.length !== listLengths[0]) {
                pass = false
            }
        }

        if (Array.isArray(controls)) {
            let i = 0
            for (const control of controls) {
                if (control instanceof ListFormControl) {
                    pass = control.list.length === listLengths[i]
                    i++
                }
                if (!pass) break
            }
        }

        if (pass && !hasChanges(controls)) {
            push(redirect)
        } else {
            showAlert()
        }
    }

    function init() {
        update(updateLenght())
    }

    return {
        onClick,
        init
    }
}
