import Button from '../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import { storagePage } from '../../storage'
import TableDispatch from './TableDispatch'
import TableWithdrawal from './TableWithdrawal'
import { TooltipDispatch, TooltipRetreat } from './Tooltips'
import useDispatch from './useDispatch'
import useRetreat from './useRetreat'
import useStep3Submit from './useStep3Submit'

export default function Step3() {
    const openDispatch = useDispatch()
    const openRetreat = useRetreat()
    const submit = useStep3Submit()
    const { mode } = storagePage

    return (
        <>
            <Panel mode="form">
                <PanelHeader
                    title="Direcciones de Despacho"
                    helper={<TooltipDispatch />}
                >
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => openDispatch()}
                    >
                        + Agregar
                    </Button>
                    {mode === 'edition' && (
                        <Button
                            size="sm"
                            onClick={() => submit()}
                            className="ml-2"
                        >
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TableDispatch />
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader
                    title="Direcciones de retiro"
                    helper={<TooltipRetreat />}
                >
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => openRetreat()}
                    >
                        + Agregar
                    </Button>
                    {mode === 'edition' && (
                        <Button
                            size="sm"
                            onClick={() => submit()}
                            className="ml-2"
                        >
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TableWithdrawal />
                </PanelBody>
            </Panel>
        </>
    )
}
