import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import Information from './Information'
import Products from './Products'
import Header from './Header'
import useInitial from '../useInitial'

export default function License() {
    const { pageState } = useInitial()

    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title={'Información de la Parte de Entrada'} />
                    <PanelBody>
                        <Information />
                    </PanelBody>
                </Panel>
                <Panel mode="form">
                    <PanelHeader title={'Productos del Movimiento'} />
                    <PanelBody>
                        <Products />
                    </PanelBody>
                </Panel>
            </div>
            <Header />
        </Initial>
    )
}
