import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import useStowageDestiny from './useStowageDestiny'
import useStowageOrigin from './useStowageOrigin'
import useSubStowageDestiny from './useSubStowageDestiny'
import useSubStowageOrigin from './useSubStowageOrigin'

function StowageOrigin() {
    const { props, stowages, handleChange, propsSearch } = useStowageOrigin()
    return (
        <FormControl label="Bodega origen" required>
            <SelectTouch
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar bodega origen"
                label="Bodega origen"
            >
                <IterateOptions data={stowages} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function StowageDestination() {
    const { props, stowages, handleChange, loading, propsSearch } =
        useStowageDestiny()

    return (
        <FormControl label="Bodega destino" required>
            <SelectTouch
                disabled
                {...props}
                {...propsSearch}
                loading={loading}
                onChange={handleChange}
                placeholder="Seleccionar bodega destino"
                label="Bodega destino"
            >
                <IterateOptions data={stowages} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function SubStowageOrigin() {
    const { props, data, handleChange, propsSearch, loading } =
        useSubStowageOrigin()

    return (
        <FormControl
            label="Sub bodega origen"
            required={props.disabled === false}
        >
            <SelectTouch
                disabled
                {...props}
                {...propsSearch}
                onChange={handleChange}
                loading={loading}
                placeholder="Seleccionar sub bodega origen"
                label="Sub bodega origen"
            >
                <IterateOptions data={data.result} label="substowage_name" />
            </SelectTouch>
        </FormControl>
    )
}

function SubStowageDestination() {
    const { props, minimalSubStowages, loading, handleChange, propsSearch } =
        useSubStowageDestiny()

    return (
        <FormControl
            label="Sub bodega destino"
            required={props.disabled === false}
        >
            <SelectTouch
                disabled
                {...props}
                {...propsSearch}
                onChange={handleChange}
                loading={loading}
                placeholder="Seleccionar sub bodega destino"
                label="Sub bodega destino"
            >
                <IterateOptions data={minimalSubStowages} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function Fields2() {
    return (
        <>
            <StowageOrigin />
            <StowageDestination />
            <SubStowageOrigin />
            <SubStowageDestination />
        </>
    )
}
