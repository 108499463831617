import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('PRODUCT', [
    'STEP1',
    'CONDIFICATION',
    'STEP2',
    'TABLE_PRODUCTS',
    'COMPANIES',
    'FISCAL_CODE',
    'TAXES',
    'PERMITS'
])
