import { memo, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { NAVBAR_HEIGHT } from '../../config/app'
import RoutingContext from '../../contexts/routing-context'
import { mathPath } from '../../utilities/routing'
import Icon from '../Icon'
import SessionUser from './SessionUser'

function Navbar({ onClickMenu }: { onClickMenu: (ev: any) => void }) {
    const { pages } = useContext(RoutingContext)
    const { pathname } = useLocation()
    const page = pages.find(d => mathPath(pathname, d.path) && d.Component)
    return (
        <nav
            className="w-full bg-primary flex space-between items-center px-4 shadow-md relative z-1"
            style={{ height: NAVBAR_HEIGHT }}
        >
            <div className="flex items-center justify-between text-white w-full">
                <div className="flex items-center gap-4">
                    <Icon
                        name="menu"
                        size={18}
                        color="white"
                        className=""
                        pointer
                        onClick={onClickMenu}
                    />
                    <h1 className="text-3xl font-univers-bold-cn uppercase">
                        {page?.Component ? page.title : 'Página No Encontrada'}
                    </h1>
                </div>
                <SessionUser />
            </div>
        </nav>
    )
}

export default memo(Navbar)
