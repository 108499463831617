import { ListFormControl } from '../../../../sections/ListForm'
import ControlRequest from '../../../../utilities/ControlRequest'
import { Keys } from '../../constants'
import { TableSettings } from './types'

/**
 * @deprecated
 */
export const tableSettings = new ListFormControl<TableSettings>(
    Keys.TABLE_SETTINGS,
    [
        '#',
        'Concepto de costo',
        'Tipo',
        'Costea a RCs',
        'Monto ($)',
        'TC',
        'Monto (€)',
        'Acciones'
    ]
)

export const reqRC2 = new ControlRequest()
