import { useHistory } from 'react-router-dom'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import Rules from '../../../components/Rules'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import ProductCategory from '../../../models/ProductCategory'
import { controlList } from '../storage'

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por código o nombre de producto"
            size="auto"
            fullWidth={true}
        />
    )
}

function Category() {
    const { data, props } = controlList.useFilter<ProductCategory>(
        'category_id',
        '/v5.6/apis/categories'
    )
    return (
        <Dropdown {...props} label="Categoría">
            <IterateOptions data={data} label="name" />
        </Dropdown>
    )
}

export default function FilterProducts() {
    const { push } = useHistory()

    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Category />
                </FlexLeft>
                <FlexRight>
                    <Rules cu="CU291">
                        <Button
                            onClick={() =>
                                push('/configuration/products/imports')
                            }
                            size="sm"
                        >
                            Importar
                        </Button>
                    </Rules>
                    <Rules cu="CU064">
                        <Button
                            size="sm"
                            onClick={() =>
                                push('/configuration/products/creation')
                            }
                        >
                            Nuevo
                        </Button>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
