import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
import { LicenseVersionData } from './types'

export const tableLicense = new ControlList<LicenseVersionData>(Keys.TABLE, {
    theads: [
        { value: 'version', label: 'Versión' },
        'Última versión',
        { value: 'user_name', label: 'Usuario renovador' }
    ]
})
