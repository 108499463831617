import { useContext } from 'react'
import CU from '../config/types/cu'
import AuthContext from '../contexts/auth-context'
import { isAdmin } from '../utilities/auth/tools'

export default function useRule(cu: CU | CU[]) {
    const { CUs, user } = useContext(AuthContext)
    if (isAdmin(user.id)) return true
    let isAccesible = false
    if (Array.isArray(cu)) {
        isAccesible = !!CUs.find(_cu => cu.find(__cu => __cu === _cu))
    } else {
        isAccesible = !!CUs.find(_cu => _cu === cu)
    }
    return isAccesible
}
