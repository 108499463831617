import styled, { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`

export const LoadingStyled = styled.div`
    animation: ${fadeIn} 1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: ${props => props.height};
`

export const ContainerText = styled.div`
    position: relative;
    color: #b2b2b2;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    max-width: 350px;
`

const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`
const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`
const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`

export const LdsEllipsis = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 60px;
    div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
        background-color: #a29dae;
        left: 8px;
        animation: ${ldsEllipsis1} 0.6s infinite;
    }

    div:nth-child(2) {
        background-color: #2a2a5b;
        left: 8px;
        animation: ${ldsEllipsis2} 0.6s infinite;
    }
    div:nth-child(3) {
        background-color: #e4272a;
        left: 32px;
        animation: ${ldsEllipsis2} 0.6s infinite;
    }
    div:nth-child(4) {
        background-color: #29a6ff;
        left: 56px;
        animation: ${ldsEllipsis3} 0.6s infinite;
    }
`
