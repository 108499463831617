import { ErrorStack, ErrorTypes, Exception, TypeException } from './type'

export default function parseError(exception): Exception {
    let fields: Record<string, ErrorStack> = {}
    let type: ErrorTypes = 'network'
    const message = exception?.message || ''
    const errors: Array<any> = exception?.response?.data.errors || []
    const response: Record<string, any> =
        exception?.response?.data?.response || null
    const typeException: TypeException = exception?.response?.data.type

    errors.forEach(err => {
        const stack: ErrorStack = err
        fields = {
            ...fields,
            [err.field_error]: stack
        }
    })

    if (exception.name === 'AbortError') {
        type = 'cancel'
    } else if (typeException === 'CustomerException') {
        type = 'customer'
    } else if (typeException === 'DependencyException') {
        type = 'dependency'
    } else if (typeException === 'DevelopmentException') {
        type = 'development'
    } else {
        type = null
    }

    return {
        type,
        errors: fields,
        message,
        response
    }
}
