import moment from 'moment'
import { ListFormControl } from '../../sections/ListForm'
import ControlRequest from '../../utilities/ControlRequest'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import {
    StoragePage,
    Form,
    DestinationStowageForTraspaseResponse,
    TableProductTraspase
} from './types'

export const storagePage: StoragePage = {
    traspase: null,
    mode: 'creation',
    stowages_origin: [],
    current_type_stowage_id_origin: null,
    current_type_stowage_id_destination: null,
    minimal_stowage_origin_id: null,
    minimal_stowage_destiny_id: null,
    companies: [],
    symbol: '',
    timezone: '',
    current_traspase_products: []
}

export const formTraspase = new FormEvents<Form>(Keys.FORM, {
    company: null,
    warehouse: null,
    stowage_origin: null,
    stowage_destination: null,
    sub_stowage_origin: null,
    sub_stowage_destination: null,
    date_request: moment().toISOString(),
    description: '',
    traspase_products: []
})

export const reqWarehouse = new ControlRequest()
export const reqStowageOrigin = new ControlRequest()
export const reqStowageDestiny = new ControlRequest<
    DestinationStowageForTraspaseResponse[]
>()
export const reqWorkingPeriod = new ControlRequest()
export const reqSubStowageOrigin = new ControlRequest()
export const reqSubStowageDestiny = new ControlRequest()
export const reqProducts = new ControlRequest()
export const productsControl = new ListFormControl<TableProductTraspase>(
    Keys.TABLE_PRODUCTS,
    ['#', 'Producto', 'Cantidad a solicitar', 'Cantidad disponible', 'Acciones']
)
