import Spinner from './Spinner'
import { LoadingStyled, ContainerText } from './Loading.style'
import { PropsLoading } from './types'
import { ReactNode } from 'react'

export default function Loading({
    height = '100%',
    className = '',
    scale = 1,
    children
}: PropsLoading & { children?: ReactNode }) {
    return (
        <LoadingStyled height={height} className={className}>
            <div className="flex flex-col">
                <div className="text-center">
                    <Spinner style={{ transform: `scale(${scale})` }} />
                </div>
                {children && <ContainerText>{children}</ContainerText>}
            </div>
        </LoadingStyled>
    )
}
