import DataTable, { TBody, THead } from '../../../../../components/DataTable'
import buildCompanyName from '../../../../../utilities/buildCompanyName'
import { ProductView } from '../../../types'

export default function TableFiscalCode({
    productCountries
}: {
    productCountries: ProductView['product_countries']
}) {
    return (
        <DataTable className="mb-3" scrollHeight={250}>
            <THead>
                <tr>
                    <th>País</th>
                    <th>Código fiscal de producto</th>
                </tr>
            </THead>
            <TBody>
                {productCountries.map(pc =>
                    pc.product_companies.map(pcom => (
                        <tr key={pcom.id}>
                            <td>
                                <img
                                    src={pcom.company.country.image_url}
                                    className="w-8 inline mr-2"
                                />
                                <div
                                    className={`${
                                        !pcom.company.memory && 'line-through'
                                    } inline`}
                                >
                                    {buildCompanyName(pcom.company.name)}
                                </div>
                            </td>
                            <td>{pcom.fiscal_code}</td>
                        </tr>
                    ))
                )}
            </TBody>
        </DataTable>
    )
}
