import moment from 'moment'
import { ListFormControl } from '../../sections/ListForm'
import ControlRequest from '../../utilities/ControlRequest'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { StoragePage, Form, LogicalProductForVoucher } from './types'

export const storagePage: StoragePage = {
    consumptionVoucher: null,
    mode: 'creation',
    current_type_stowage_id_origin: null,
    current_stowage_origin_id: null,
    companies: [],
    symbol: '',
    timezone: '',
    current_consumer_voucher_products: []
}

export const formConsumptionVoucher = new FormEvents<Form>(Keys.FORM, {
    company: null,
    warehouse: null,
    stowage: null,
    tag: null,
    sub_stowage: null,
    request_date: moment().toISOString(),
    description: '',
    products: []
})

export const productsControl = new ListFormControl<LogicalProductForVoucher>(
    Keys.TABLE_PRODUCTS,
    [
        '#',
        'Producto',
        'Presentación',
        'Cantidad de salida',
        'Stock en la bodega',
        'Costo promedio unitario',
        'Valor total de salida',
        'Acciones'
    ]
)

export const reqWarehouse = new ControlRequest()
export const reqStowage = new ControlRequest()
export const reqSubStowage = new ControlRequest()
export const reqProducts = new ControlRequest()
export const reqWorkingPeriod = new ControlRequest()
