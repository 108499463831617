import { mergeExpect } from '../../../../utilities/dataTools'
import {
    controlTabs,
    formInfo,
    storagePage,
    tableContactsControl
} from '../../storage'
import { Step1 } from '../../types'
import { initStep1 } from '../../utilities/initialEdition'

export default function useStep1Submit() {
    const { mode, providerId } = storagePage
    const path =
        mode === 'edition' || providerId ? `/${storagePage.providerId}` : ''

    const { observer, validation, submit } =
        formInfo.useSubmit<Step1.ResponseResult>(
            `/v5.6/apis/providers${path}/general_information`,
            {
                message:
                    '¿Está seguro de guardar cambios de Información General?',
                done: provider => {
                    storagePage.providerId = provider.id
                    storagePage.step1Done = true
                    storagePage.provider = {
                        ...storagePage.provider,
                        ...provider
                    }
                    initStep1(provider)
                    controlTabs.next()
                },
                method: mode === 'edition' || providerId ? 'put' : 'post'
            }
        )

    validation(v => {
        v.business_name
            .minLength(2, 'El número de carácteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar la razón social')
        v.commercial_name
            .minLength(2, 'El número de carácteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el nombre comercial')
        v.type_tax_identification.isEmpty(
            'Se requiere seleccionar el tipo de identificación fiscal'
        )
        v.tax_identification_number
            .minLength(2, 'El número de carácteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el documento de identificación')
        v.country.isEmpty('Se requiere seleccionar el país de procedencia')
        v.fiscal_address
            .minLength(2, 'El número de carácteres válidos es 2 - 300')
            .isEmpty('Se requiere ingresar la dirección fiscal')
    })

    const getProviderContacts = () =>
        mergeExpect(
            tableContactsControl.getDataList(),
            storagePage.provider.provider_contacts
        )
            .mapping(d => {
                return {
                    ...(d.id && { deleted: false }),
                    ...d
                }
            })
            .deleted(d => ({
                id: d.id,
                email: d.contact.email,
                is_main: d.is_main,
                name: d.contact.name,
                phone_number: d.contact.phone_number,
                position: d.contact.position,
                deleted: true
            }))

    observer<Step1.BodyGeneralInformation>(form => ({
        business_name: form.business_name.trim(),
        commercial_name: form.commercial_name.trim(),
        type_tax_identification_id: parseInt(
            form.type_tax_identification.value
        ),
        tax_identification_number: form.tax_identification_number.trim(),
        country_id: parseInt(form.country.value),
        link_web_page: form.link_web_page.trim() || null,
        fiscal_address: form.fiscal_address.trim(),
        description: form.description.trim() || null,
        provider_contacts: !storagePage.providerId
            ? tableContactsControl.getDataList()
            : getProviderContacts()
    }))

    return submit
}
