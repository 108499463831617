import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import Option from '../../../../components/Option'
import SelectTouch from '../../../../components/SelectTouch'
import Textarea from '../../../../components/Textarea'
import useStorage from '../../../../hooks/useStorage'
import { formPaymentMethod } from '../../storage'

function Pay() {
    const { props } = formPaymentMethod.useSelect('payment_method')
    return (
        <FormControl label="Medio de pago" required className="mb-2">
            <SelectTouch
                {...props}
                placeholder="Ingresar medio de pago"
                label="Seleccionar medio de pago"
            >
                <Option value="as" label="asff" />
            </SelectTouch>
        </FormControl>
    )
}

function Companies() {
    const { props } = formPaymentMethod.useSelect('company')
    const { storage } = useStorage()
    return (
        <FormControl label="Compañias aplicables" required className="mb-2">
            <SelectTouch
                {...props}
                placeholder="Seleccionar compañías"
                label="Seleccionar compañías"
            >
                <IterateOptions
                    data={storage.companies.map(c => ({
                        ...c,
                        image_url: c.country.image_url
                    }))}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}

function Description() {
    const { props } = formPaymentMethod.useInput('description')
    return (
        <FormControl label="Descripción">
            <Textarea {...props} placeholder="Ingrese una descripción" />
        </FormControl>
    )
}

export default function FormPaymentMethod() {
    return (
        <>
            <Pay />
            <Companies />
            <Description />
        </>
    )
}
