import { Empty } from '../../../../../components/FormUtilities'
import Option from '../../../../../components/Option'
import SelectSearch from '../../../../../components/SelectSearch'
import { Option as OptionType } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import { storagePage } from '../../../storage'
import { formGeneral } from '../../../View/SectionGeneralInformation/storageStep1'
import { formRC, reqModalRC, tableRCsControl } from '../storageStep2'
import Cost from '../cost'
import runOperationModalReception from '../../../utilities/runOperationModalReception'
import { render } from 'redity'

export default function Search() {
    const { props, getError } = formRC.useSelect('rc')
    const { data, props: propsSearch } = useGet<Cost.ResponseReception[]>(
        reqModalRC.observer(
            `/v5.6/apis/receptions/?type_purchase_id=${
                storagePage.type === 'national' ? '1' : '2'
            }&company_id=${
                formGeneral.store().company.value
            }&includes_logistical_costs=false`,
            true
        ),
        []
    )

    const helper1 = getError('ERROR-0000303', null, 'reception_id')
    const helper2 = getError('ERROR-0000301', null, 'reception_id')

    function handleChange(option: OptionType) {
        props.onChange(option)
        if (option.value === '0') {
            formRC.setValue('purchase_dolar_cost', '')
            if (tableRCsControl.getDataList().length === 0) {
                reqModalRC.deleteQuery('not_reception_ids')
            } else {
                reqModalRC.setQuery(
                    'not_reception_ids',
                    tableRCsControl.getDataList().map(d => d.reception_id)
                )
            }
        } else {
            const reception = data.result.find(
                requisition => requisition.id.toString() === option.value
            )
            storagePage.currentReception = reception

            const { purchase_original_cost } = reception
            const not_reception_ids =
                (reqModalRC.getQuery('not_reception_ids') as number[]) || []

            if (!not_reception_ids.find(id => id === parseInt(option.value))) {
                reqModalRC.setQuery('not_reception_ids', [
                    ...not_reception_ids,
                    parseInt(option.value)
                ])
            }
            formRC.setValue(
                'purchase_dolar_cost',
                purchase_original_cost.toString()
            )
        }
        runOperationModalReception()
        render(formRC.keyRender, 'exchange_rc_to_dolar')
        render(formRC.keyRender, 'exchange_dolar_to_local')
    }

    return (
        <SelectSearch
            {...props}
            {...propsSearch}
            helper={props.helper || helper1 || helper2}
            placeholder="Buscar por código de RC o OC"
            onChange={handleChange}
            className="mb-4"
            autoFocus
        >
            {data.result
                .filter(rc => !rc.has_logistical_declaration)
                .map(reception => (
                    <Option
                        value={reception.id.toString()}
                        key={reception.id}
                        label={`${reception.code} - ${reception.purchase.code}${
                            reception.costing_estimate ? ' - (Anticipada)' : ''
                        }`}
                    />
                ))}
            <Empty
                data={data.result.filter(rc => !rc.has_logistical_declaration)}
            />
        </SelectSearch>
    )
}
