import moment from 'moment'
import { Option } from '../../../../../config/types/component'
import useStorage from '../../../../../hooks/useStorage'
import { formOC, storagePage } from '../../../storage'

export default function useBillingCompany() {
    const { props } = formOC.useSelect('billing_company')
    const { companiesFromSession } = useStorage()

    function handleChange(option: Option) {
        storagePage.timezone = companiesFromSession.find(
            c => c.id === parseInt(option.value)
        ).timezone
        const date = moment().tz(storagePage.timezone).parseZone()
        formOC.setValue('date', date.toISOString(true))
        props.onChange(option)
    }

    return {
        props,
        handleChange
    }
}
