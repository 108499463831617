import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import { formConsumptionVoucher } from '../ConsumptionVoucher/storage'
import { controlList, reqStowage, reqWarehouse, storagePage } from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)

    async function load() {
        controlList.filter.changeParams('sort', 'code')
        controlList.filter.changeParams('order', 'desc')
        const company = getCompanySelected()

        const option = {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url,
            span: company.local_currency.symbol
        }

        storagePage.company_enabled = company.is_enabled
        storagePage.timezone = company.timezone
        controlList.filter.setOption('company_id', option)
        formConsumptionVoucher.setValue('company', option)
        reqWarehouse.setQuery('company_id', option.value)
        reqStowage.setQuery('company_id', option.value)

        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })
    }

    useEffect(() => {
        load()

        return () => {
            storagePage.consumer_voucher = null
            storagePage.company_enabled = false
            storagePage.timezone = ''
            reqStowage.init()
            reqWarehouse.init()
        }
    }, [])

    return { state }
}
