import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import {
    storagePage,
    formConsumptionVoucher,
    reqWarehouse,
    reqStowage,
    reqWorkingPeriod,
    reqSubStowage,
    reqProducts,
    productsControl
} from './storage'
import { useParams } from 'react-router'
import useStorage from '../../hooks/useStorage'
import { GETConsumerVoucher } from './types'
import useInitialEdition from './useInitialEdition'
import moment from 'moment'

export default function useInitial() {
    type ParamData = { consumer_voucher_id?: string }
    const { consumer_voucher_id } = useParams<ParamData>()
    const [pageState, setState] = useState<StateCase>(StateCase.LOADING)
    const { companiesFromSession } = useStorage()
    const loadEdition = useInitialEdition()

    storagePage.companies = companiesFromSession

    let title = 'Nuevo vale de consumo'
    if (consumer_voucher_id) {
        storagePage.mode = 'edition'
        title = 'Editar vale de consumo'
    }

    async function load() {
        if (storagePage.mode !== 'creation') {
            const response = await get<GETConsumerVoucher>(
                `/v5.6/apis/consumer_voucher/${consumer_voucher_id}`
            )

            if (response.error) {
                setState(StateCase.ERROR)
                return
            }
            storagePage.consumptionVoucher = response.data.result
            storagePage.timezone = companiesFromSession.find(
                c => c.id === response.data.result.company_warehouse.company_id
            ).timezone
            storagePage.current_consumer_voucher_products = []
            loadEdition(response.data.result)
        } else {
            if (formConsumptionVoucher.store().company) {
                storagePage.symbol = companiesFromSession.find(
                    c =>
                        c.id ===
                        parseInt(formConsumptionVoucher.store().company.value)
                ).local_currency.symbol
                storagePage.timezone = companiesFromSession.find(
                    c =>
                        c.id ===
                        parseInt(formConsumptionVoucher.store().company.value)
                ).timezone

                reqWarehouse.setQuery(
                    'company_id',
                    formConsumptionVoucher.store().company.value
                )
                reqStowage.setQuery(
                    'company_id',
                    formConsumptionVoucher.store().company.value
                )
                reqWorkingPeriod.setParam(
                    'company_id',
                    formConsumptionVoucher.store().company.value
                )
            } else {
                const companies = companiesFromSession.filter(
                    _company => _company.is_enabled
                )
                const company = companies[0]
                formConsumptionVoucher.setValue('company', {
                    label: company.name,
                    value: company.id.toString(),
                    image: company.country.image_url
                })

                storagePage.symbol = companiesFromSession.find(
                    c => c.id === company.id
                ).local_currency.symbol
                storagePage.timezone = companiesFromSession.find(
                    c => c.id === company.id
                ).timezone

                reqWarehouse.setQuery('company_id', company.id)
                reqStowage.setQuery('company_id', company.id)
                reqWorkingPeriod.setParam('company_id', company.id)
            }

            const date = moment().tz(storagePage.timezone).parseZone()
            formConsumptionVoucher.setValue(
                'request_date',
                date.toISOString(true)
            )

            reqProducts.setQuery(
                'operation_date',
                moment(formConsumptionVoucher.store().request_date).format(
                    'YYYY-MM-DD'
                )
            )
        }

        setState(StateCase.SUCCESS)
    }

    useEffect(() => {
        reqWarehouse.setQuery('is_enabled', true)
        load()
        return () => {
            storagePage.consumptionVoucher = null
            storagePage.current_type_stowage_id_origin = null
            storagePage.mode = 'creation'
            formConsumptionVoucher.init()
            formConsumptionVoucher.clearProps()
            reqWarehouse.init()
            reqStowage.init()
            reqSubStowage.init()
            reqWorkingPeriod.init()
            reqProducts.init()
            productsControl.setList([])
        }
    }, [])

    return {
        pageState,
        title
    }
}
