import SearchField from '../../../../components/SearchField'
import { providerList } from '../../storage'

export default function SearchProviderCatalog() {
    const { props } = providerList.useSearch()

    return (
        <SearchField
            {...props}
            placeholder="Buscar por código o nombre de producto "
            size="auto"
            fullWidth={true}
        />
    )
}
