import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('COST', [
    'GENERAL',
    'FORM_RC',
    'FORM_LOGISTICAL_COST',
    'FORM_TARIFF_ISC_IVA',
    'TABLE_RCS',
    'TABLE_INTERNATIONAL_COSTS',
    'TABLE_NATIONALIZATION',
    'TABLE_TARIFFS',
    'TABLE_ISC',
    'TABLE_IVA',
    'TABLE_DISTRIBUTION_AMOUNT_OF_PAID',
    'TABLE_ADITIONAL_INTERNATIONAL_COSTS',
    'TABLE_ADITIONAL_INTERNATIONAL_COSTS2',
    'FORM_COST_GENERAL',
    'FORM_COST_BY_PRODUCT',
    'TABLE_COST_BY_PRODUCT',
    'TABLE_DISTRIBUTION_BY_RC',
    'TABLE_DISTRIBUTION_BY_PRODUCT',
    'TABLE_DISTRIBUTION_BY_PRODUCT_SPECIFICATIONS',
    'TABLE_STEP_2_DUTY_SPECIFICATIONS',
    'TABLE_STEP_2_IVA_SPECIFICATIONS',
    'TABLE_STEP_2_ISC_SPECIFICATIONS',
    'TOTAL_COST',
    'TABLE_SETTINGS'
])
