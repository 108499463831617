import { useHistory } from 'react-router'
import BtnActionTable from '../../../components/BtnActionTable'
import Option from '../../../components/Option'
import Popover from '../../../components/Popover'
import Rules from '../../../components/Rules'
import Delete from '../../../sections/Delete'
import { controlList } from '../storage'
import { LicenseData } from '../types'
import ActiveDeactivate from './ActiveDeactivate'

export default function Actions({ license }: { license: LicenseData }) {
    const { push } = useHistory()
    return (
        <>
            <Rules cu="CU054">
                <BtnActionTable
                    title="Editar"
                    iconName="bs-pencil"
                    disabled={!license.company.is_enabled}
                    onClick={() =>
                        push(`/configuration/licenses/${license.id}/edition`)
                    }
                />
            </Rules>
            <Rules cu="CU057">
                <Delete
                    path={`/v5.6/apis/licenses/${license.id}`}
                    loadingMessage="Eliminando Licencia"
                    control={controlList}
                    disabled={!license.company.memory}
                >
                    ¿Está seguro de eliminar la licencia <b>{license.name}</b>?
                </Delete>
            </Rules>
            <Rules cu={['CU055', 'CU056', 'CU059', 'CU072', 'CU058']}>
                <Popover
                    position="bottom-right-left"
                    element=".panel--form-datatable"
                    component={<BtnActionTable iconName="bs-three-dots" />}
                >
                    <ActiveDeactivate license={license} />
                    <Rules cu="CU059">
                        <Option
                            label="Renovar licencia"
                            value="0"
                            icon="bs-arrow-repeat"
                            disabled={
                                !license.company.memory || !license.is_enabled
                            }
                            onClick={() =>
                                push(
                                    `/configuration/licenses/${license.id}/renovate`
                                )
                            }
                        />
                    </Rules>
                    <Rules cu="CU072">
                        <Option
                            label="Historial de licencia"
                            value="1"
                            icon="history"
                            onClick={() =>
                                push(
                                    `/configuration/licenses/${license.id}/history`
                                )
                            }
                        />
                    </Rules>
                    <Rules cu="CU058">
                        <Option
                            label="Asignar productos"
                            value="2"
                            icon="hand"
                            disabled={
                                !license.company.memory || !license.is_enabled
                            }
                            onClick={() =>
                                push(
                                    `/configuration/licenses/${license.id}/products`
                                )
                            }
                        />
                    </Rules>
                </Popover>
            </Rules>
        </>
    )
}
