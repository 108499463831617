import SearchField from '../../../../../components/SearchField'
import { controlList } from '../../../storage'

export default function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por código o nombre del producto"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}
