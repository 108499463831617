import { ModalTools } from '../../../../sections/ModalSection/types'
import { formWithdrawal, tableWithdrawal } from '../../storage'
import FormWithdrawal from './FormWithdrawal'
import { Step3 } from '../../types'
import { retreatExist } from '../../utilities/expectTableRetreat'

export default function useRetreat(index?: number) {
    function handleSubmit(ev, modalState: ModalTools) {
        ev.preventDefault()
        const form = formWithdrawal.store()
        const result = formWithdrawal.test(v => {
            v.country.isEmpty('Se requiere seleccionar el país')
            v.place_retreat
                .minLength(2, 'El número de caracteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el lugar de retiro')
            v.address
                .minLength(2, 'El número de caracteres válidos es 2 - 3000')
                .isEmpty('Se requiere ingresar la dirección')
                .condition(
                    value =>
                        retreatExist(value as string, form, index) &&
                        'Ya existe un registro con la misma dirección'
                )
        })

        if (result) {
            const { country, place_retreat, address } = formWithdrawal.store()

            if (index === undefined) {
                const hasMain = !!tableWithdrawal
                    .getDataList()
                    .find(
                        d =>
                            d.is_main &&
                            d.country.id === parseInt(country.value)
                    )

                tableWithdrawal.push({
                    country: {
                        id: parseInt(country.value),
                        image_url: country.image,
                        name: country.label
                    },
                    place_retreat,
                    address,
                    is_main: !hasMain,
                    enabled: true
                })
            } else {
                tableWithdrawal.put(index, {
                    country: {
                        id: parseInt(country.value),
                        image_url: country.image,
                        name: country.label
                    },
                    place_retreat,
                    address
                })
            }
            modalState.close()
        }
    }

    const openModal = formWithdrawal.useModal(FormWithdrawal, {
        title:
            index === undefined
                ? 'Nueva dirección de retiro'
                : 'Editar dirección de retiro',
        size: 'sm',
        onSubmit: handleSubmit
    })

    function handleOpenModal(data?: Step3.FormWithdrawal) {
        if (index !== undefined) {
            formWithdrawal.setProps('country', {
                disabled: true
            })
        } else {
            formWithdrawal.setProps('country', {
                disabled: false
            })
        }
        openModal(data)
    }

    return handleOpenModal
}
