import moment from 'moment'
import { DRIVE_CREDENTIALS, DRIVE_FOLDERS } from '../config/credentials'

export interface DriveFile {
    id?: number
    drive_id: string
    type: string
    filename: string
    /**
     * Kilobytes
     */
    size: number
    modified: number
    format: string
}

interface DriveAuth {
    access_token: string
    expires_in: number
    scope: string
    token_type: string
}

interface DriveData {
    id: string
    kind: string
    mimeType: string
    name: string
}

export default class Drive {
    private auth: DriveAuth = null

    async getAuth() {
        const res = await fetch('https://www.googleapis.com/oauth2/v4/token', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(DRIVE_CREDENTIALS)
        })
        this.auth = await res.json()
        setTimeout(() => {
            this.auth = null
        }, this.auth.expires_in)
        return this.auth
    }

    async upload(file: File, folder = DRIVE_FOLDERS.TEST): Promise<DriveFile> {
        if (!this.auth) {
            await this.getAuth()
        }
        const { token_type, access_token } = this.auth
        const metadata = {
            name: file.name,
            mimeType: file.type,
            parents: [folder],
            filename: file.name
        }
        const formData = new FormData()
        formData.append(
            'metadata',
            new Blob([JSON.stringify(metadata)], { type: 'application/json' })
        )
        formData.append('file', file)
        const res = await fetch(
            `https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`,
            {
                headers: { Authorization: `${token_type} ${access_token}` },
                method: 'POST',
                body: formData
            }
        )

        const data: DriveData = await res.json()

        return {
            size: Math.round(file.size / 1000),
            type: file.type,
            filename: file.name,
            drive_id: data.id,
            modified: file.lastModified,
            format: moment(file.lastModified).toISOString()
        }
    }
}
