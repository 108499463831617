import { useState, useEffect } from 'react'
import { StateCase } from '../../../config/enums'
import { controlList, storagePage } from '../storage'
import { getCompanySelected } from '../../../utilities/controlStorage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)

    useEffect(() => {
        controlList.filter.changeParams('sort', 'name')
        const company = getCompanySelected()

        const option = {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url,
            span: company.local_currency.symbol
        }

        storagePage.company = option

        controlList.filter.setOption('company_id', option)

        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })
    }, [])

    return state
}
