import { ModalTools } from '../../../../sections/ModalSection/types'
import { formAddress } from '../../storage'
import { addAddress, updateAddress } from '../../utilities/crudFormAddress'
import FormAddress from './FormAddress'

export default function useAddress(index?: number) {
    function handleSubmit(ev, modal: ModalTools) {
        ev.preventDefault()
        const result = formAddress.test(v => {
            v.locate
                .minLength(2, 'El número de caracteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el lugar de retiro')
            v.country.isEmpty('Se requiere seleccionar el país')
            v.address
                .minLength(2, 'El número de caracteres válidos es 2 - 3000')
                .isEmpty('Se requiere ingresar la dirección')
        })
        if (result) {
            modal.close()
            index !== undefined ? updateAddress(index) : addAddress()
        }
    }

    return formAddress.useModal(FormAddress, {
        title: index === undefined ? 'Agregar Dirección' : 'Editar Dirección',
        size: 'sm',
        onSubmit: handleSubmit
    })
}
