import { useHistory } from 'react-router'
import BtnActionTable from '../../../components/BtnActionTable'
import Enable from '../../../components/Enable'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { GetAgents } from '../../../types/agent'
import { controlList } from '../storage'
import { AgentView } from '../types'
import ActiveDeactivate from './ActiveDeactivate'
import Info from './Info'

function Name({ agent }: { agent: GetAgents[number] }) {
    const math = useRule('CU080')

    function handleClick() {
        view<AgentView>(`/v5.6/apis/agents/${agent.id}`, agentResponse => ({
            title: agentResponse.name,
            Component: Info
        }))
    }

    if (math) {
        return (
            <td>
                <b
                    onClick={handleClick}
                    className={`cursor-pointer ${
                        agent.is_enabled ? 'underline' : 'line-through'
                    }`}
                >
                    {agent.name}
                </b>
            </td>
        )
    }

    return <td className={!agent.is_enabled && 'line-through'}>{agent.name}</td>
}

export default function Table() {
    const { push } = useHistory()
    return (
        <List control={controlList}>
            {(agent, i) => (
                <tr key={agent.id}>
                    <td>{i}</td>
                    <Name agent={agent} />
                    <td>
                        <div className="flex items-center">
                            <img
                                src={agent.country.image_url}
                                className="mr-2 w-8"
                            />
                            <p>{agent.country.name}</p>
                        </div>
                    </td>
                    <td>
                        {agent.agent_type_agents
                            .filter(agent_type_agent => agent_type_agent.memory)
                            .map(
                                agent_type_agent =>
                                    agent_type_agent.type_agent.name
                            )
                            .join(' - ')}
                    </td>
                    <td>
                        <Enable enabled={agent.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU076">
                                <BtnActionTable
                                    title="Editar"
                                    iconName="bs-pencil"
                                    onClick={() =>
                                        push(
                                            `/configuration/agents/${agent.id}/edition`
                                        )
                                    }
                                />
                            </Rules>
                            <Rules cu="CU079">
                                <Delete
                                    path={`/v5.6/apis/agents/${agent.id}`}
                                    loadingMessage="Eliminando Agente"
                                    control={controlList}
                                >
                                    ¿Está seguro de eliminar el agente{' '}
                                    <b className="text-primary">{agent.name}</b>
                                    ?
                                </Delete>
                            </Rules>
                            <ActiveDeactivate agent={agent} />
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
