import BtnActionTable from '../../../../../components/BtnActionTable'
import Icon from '../../../../../components/Icon'
import { TableList } from '../../../../../sections/ListForm'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import useSubmitLogiticalCost from '../FormLogisticalCost/useSubmitLogiticalCost'
import useTableInternationalCost from './useTableInternationalCost'

export default function TableInternationalCosts() {
    const {
        tableInternationalCostsControl,
        cifDolar,
        cifLocal,
        currentSymbol
    } = useTableInternationalCost()

    return (
        <>
            <TableList
                className="sticky"
                control={tableInternationalCostsControl}
                scrollHeight={350}
            >
                {(item, _, e) => (
                    <tr key={item.id}>
                        <td>{e}</td>
                        <td>
                            {item.has_files && (
                                <Icon
                                    name="description"
                                    className="text-info mr-2"
                                    size={20}
                                />
                            )}
                            {item.concept}
                        </td>
                        <td>
                            <div>{item.agent_name}</div>
                            <div>{item.document_number}</div>
                        </td>
                        <td>{item.type}</td>
                        <td>{item.cost_to_receipt}</td>
                        <td>
                            {item.symbol}{' '}
                            {buildNumberFormat(item.original_cost)}
                        </td>
                        <td>{item.exchange_rate.toFixed(4)}</td>
                        <td>
                            {currentSymbol} {buildNumberFormat(item.local_cost)}
                        </td>
                        <td>
                            <div className="group-btn-options">
                                <Edit
                                    logistical_cost_id={item.id}
                                    logistical_concept_id={
                                        item.logistical_concept_id
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
            <div className="flex justify-end mt-4 font-bold text-xs">
                <div className="bg-gray-200 py-2 px-4 pr-4">
                    CIF ( FOB + Flete + Seguro) $ {buildNumberFormat(cifDolar)}
                </div>
                <div className="bg-gray-200 py-2 px-4">
                    {currentSymbol} {buildNumberFormat(cifLocal)}
                </div>
            </div>
        </>
    )
}

// Este es para el modal Flete (General) y Seguro(General), Tasa sin IVA y Tasa con IVA
function Edit({
    logistical_cost_id,
    logistical_concept_id
}: {
    logistical_cost_id: number
    logistical_concept_id: number
}) {
    const open = useSubmitLogiticalCost(
        logistical_cost_id,
        logistical_concept_id
    )
    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={() => open()}
        />
    )
}
