import NumeralField from '../../../../../components/NumeralField'
import Typography from '../../../../../components/Typography'
import useGet from '../../../../../hooks/useGet'
import useStorage from '../../../../../hooks/useStorage'
import { TableList } from '../../../../../sections/ListForm'
import FormEvents from '../../../../../utilities/FormEvents'
import { storagePage } from '../../../storage'
import Cost from '../cost'
import {
    formDutyISCIVA,
    reqLDCostDistribution,
    tableISCControl
} from '../storageStep2'
import { ISCSpecifications } from './Specifications'
import useIscRow from './useIscRow'

export default function TableISC(props: Cost.ResponseLCSpecific) {
    const { logistical_declaration, version } = storagePage
    const symbol = storagePage.currentCurrency?.symbol
    const { currencies } = useStorage()
    const { data } = useGet<Cost.ResponseLDProductsCostDistribution[]>(
        reqLDCostDistribution.observer(
            `/v5.6/apis/logistical_declaration/${logistical_declaration.id}/version/${version}/get_cost_distributions`,
            true
        ),
        []
    )

    const getOriginalCost = (product_id: number) => {
        const originalCost = props.logistical_specific_costs.find(
            lic => lic.product_id === product_id
        )?.original_cost

        if (originalCost) return originalCost.toFixed(2)
        return '0.00'
    }

    tableISCControl.setList(
        data.result.map(d => ({
            id: d.product_id,
            product_id: d.product.id,
            product_name: d.product.name,
            product_code: d.product.code,
            original_cost: getOriginalCost(d.product_id),
            isc: 0,
            symbol: '$'
        }))
    )

    const { currency: currencyOption } = formDutyISCIVA.store()
    const currency = currencies.find(
        c => c.id.toString() === currencyOption.value
    )
    if (currency) {
        tableISCControl.thead = [
            'Producto',
            `Costo (${currency.symbol})`,
            `Costo (${symbol})`
        ]
    }

    return (
        <>
            <label className="label">
                <span className="label-text text-gray-500 w-full">
                    ISC por producto
                </span>
            </label>
            <TableList
                control={tableISCControl}
                scrollHeight={350}
                className="mb-4"
            >
                {(_, control, e) => <Row key={e} control={control} />}
            </TableList>
            <ISCSpecifications />
        </>
    )
}

function Row({ control }: { control: FormEvents<Cost.ModalTableISC> }) {
    const { tooltip, props, data, handleChange } = useIscRow(control)
    const { exchange_to_local } = formDutyISCIVA.store()
    const symbol = storagePage.currentCurrency?.symbol

    return (
        <tr className={tooltip && 'bg-red-100'}>
            <td>
                <Typography type="paragraph" className="font-bold">
                    {data.product_name}
                </Typography>
                <Typography type="small">{data.product_code}</Typography>
            </td>
            <td>
                <NumeralField
                    {...props}
                    onChange={handleChange}
                    helper=""
                    max={9999999999}
                    decimal={2}
                />
            </td>
            <td>
                {symbol}{' '}
                {Number(data.original_cost) * Number(exchange_to_local)}
            </td>
        </tr>
    )
}
