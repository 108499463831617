import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import { providerList } from './storage'

export default function useInitial() {
    const [pageState, setPageState] = useState(StateCase.LOADING)
    const { provider_id } = useParams<{ provider_id: string }>()

    useEffect(() => {
        providerList.filter.changeParams('sort', 'product_code')
        providerList.filter.setParams('provider_id', provider_id)
        const company = getCompanySelected()

        providerList.filter.setOption('company_id', {
            label: company.name,
            value: company.id.toString(),
            image: company.country.image_url
        })

        providerList.done().then(result => {
            if (result) {
                setPageState(StateCase.SUCCESS)
            } else {
                setPageState(StateCase.ERROR)
            }
        })
    }, [])

    return pageState
}
