import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import ControlTabs from '../../utilities/ControlTabs'
import { FilterFormFields, RequisitionItem, StoragePage } from './types'
import ControlRequest from '../../utilities/ControlRequest'

export const controlTabs = new ControlTabs()

export const controlList = new ControlList<RequisitionItem>(Keys.MAIN, {
    path: '/v5.6/apis/requisitions/search',
    theads: [
        '#',
        { value: 'requisition_code', label: 'Código' },
        'Bodega destino',
        { value: 'provider_name', label: 'Proveedor' },
        { value: 'request_user', label: 'Solicitante' },
        'Cliente',
        { value: 'desicion_date', label: 'Fecha de aprobación' },
        'Progreso',
        'Acciones'
    ]
})

controlList.badgeRemove('company_id', false)

export const filterForm = new FormEvents<FilterFormFields>(Keys.FILTER_FORM, {
    stowage_id: null,
    provider_id: null
})

export const reqStowage = new ControlRequest()
export const reqProvider = new ControlRequest()

export const storagePage: StoragePage = {
    company_enabled: false,
    timezone: ''
}
