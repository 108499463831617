import styled from 'styled-components'

export const Page500Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--white);
`

export const Title = styled.h1`
    font-size: 18px;
`

export const IconError = styled.i.attrs(() => ({
    className: 'fa fa-exclamation-triangle'
}))`
    font-size: 120px;
`

export const P = styled.p`
    text-align: center;
    margin-top: 0.5em;
    font-size: 13px;
    color: var(--text-gray);
`
