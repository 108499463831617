import classNames from 'classnames'
import { Option } from '../../config/types/component'
import { SelectListProvider } from '../../contexts/select-context'
import useOut from '../../hooks/useOut'
import Badge from '../Badge'
import Helper from '../Helper'
import Icon from '../Icon'
import Loading from '../Loading'
import './selectMultiple.css'
import { PropsInputSelect, PropsSelectMultiple } from './types'

export default function SelectMultiple({
    label = 'Seleccionar',
    options = [],
    onChange = () => null,
    children,
    onSearch = null,
    search = '',
    placeholder = '',
    disabled = false,
    helper = '',
    loading = false
}: PropsSelectMultiple) {
    const { ref, isOpen, setOpen } = useOut([])
    function handleOption(opt: Option) {
        if (opt.value === '0' || opt.value === '') {
            onChange([])
            return
        }
        const finded = options.find(_op => _op.value === opt.value)
        if (finded) {
            const optionsFiltered = options.filter(
                option => option.value !== opt.value
            )
            onChange(optionsFiltered)
        } else {
            onChange([...options, opt])
        }
    }

    function handleClick(ev) {
        if (disabled) return
        const nodeName = ev.target.nodeName
        if (ev.target.classList.contains('arrow-bottom')) {
            setOpen(true)
            return
        }
        if (nodeName === 'svg' || nodeName === 'path') return
        setOpen(true)
    }

    function handleFocus() {
        setOpen(true)
    }

    function handleRemove(opt: Option) {
        const optionsFiltered = options.filter(
            option => option.value !== opt.value
        )
        onChange(optionsFiltered)
    }

    return (
        <div ref={ref} className="w-full relative">
            <a onFocus={handleFocus} href="#"></a>
            <InputSelect
                disabled={disabled}
                helper={helper}
                options={options}
                onClick={handleClick}
                placeholder={placeholder}
                onRemove={handleRemove}
            />
            <Icon
                name="arrow-bottom"
                className="arrow-bottom absolute top-3 right-3 text-gray-400"
                size={16}
                onClick={handleClick}
            />
            <SelectListProvider
                isOpen={isOpen}
                onOption={handleOption}
                label={label}
                onSearch={onSearch}
                search={search}
                onAccept={() => setOpen(false)}
                className={classNames(
                    'absolute w-full top-10 z-select-options',
                    {}
                )}
                optionsChecks={options.map(option => option.value)}
            >
                {loading ? <Loading scale={0.7} /> : children}
            </SelectListProvider>
        </div>
    )
}

function InputSelect({
    disabled,
    helper,
    options,
    onClick,
    placeholder,
    onRemove
}: PropsInputSelect) {
    return (
        <>
            <div
                className={classNames('select-input', {
                    disabled,
                    helper: !!helper
                })}
                onClick={onClick}
            >
                {options.map((option, i) => (
                    <Badge
                        className="mr-1"
                        key={i}
                        onClose={() => onRemove(option)}
                    >
                        {option.image && <img src={option.image} />}{' '}
                        {option.label}
                    </Badge>
                ))}
                {options.length === 0 && (
                    <span className="text-sm text-gray-400 font-univers">
                        {placeholder}
                    </span>
                )}
            </div>
            <Helper>{helper}</Helper>
        </>
    )
}
