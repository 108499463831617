import React, { useEffect } from 'react'
import { PropsSearchField } from './type'
import classnames from 'classnames'
import Icon from '../Icon'
import InputField from '../InputField'
import RegexTypes from '../../utilities/regexTypes'

function use(
    onEnter: (ev: any) => void,
    onEscape: (ev: any) => void,
    value: string
) {
    const ref = React.createRef<HTMLInputElement>()
    useEffect(() => {
        if (ref.current) {
            const el = ref.current
            el.onkeydown = e => {
                if (e.key === 'Enter') {
                    onEnter(e)
                }
                if (e.key === 'Escape') {
                    onEscape(e)
                }
            }
        }
    }, [value])

    return ref
}

export default function SearchField({
    className = '',
    style = {},
    onChange = () => null,
    onEnter = () => null,
    value = '',
    loading = false,
    onEscape = () => null,
    onClear = () => null,
    fullWidth = false,
    size = 'md',
    ...props
}: PropsSearchField) {
    const ref = use(onEnter, onEscape, value)

    let minWidth = '100%'
    minWidth = size === 'md' ? '350px' : minWidth
    minWidth = size === 'auto' ? 'auto' : minWidth

    return (
        <div
            className={classnames(
                'relative inline-block fill-current text-primary',
                {
                    'w-full': fullWidth
                },
                className
            )}
            style={style}
        >
            <InputField
                ref={ref}
                {...props}
                value={value}
                style={{ minWidth }}
                regex={RegexTypes.search}
                onChange={onChange}
                maxLength={200}
                size={size === 'full' ? 'normal' : 'sm'}
                className={classnames('pl-8 pr-2', {
                    'w-full': fullWidth
                })}
            />
            <Icon
                name="search"
                className={`absolute left-2 top-${size === 'full' ? '3' : '2'}`}
            />
            {loading && (
                <Icon
                    name="loading"
                    className={`absolute right-2 top-${
                        size === 'full' ? '3' : '2'
                    } animate-spin`}
                />
            )}
            {!loading && value !== '' && (
                <Icon
                    name="circle-close"
                    className={`absolute right-2 top-${
                        size === 'full' ? '3' : '2'
                    } cursor-pointer`}
                    size={16}
                    onClick={() => onClear()}
                />
            )}
        </div>
    )
}
