import { useHistory } from 'react-router-dom'
import Popover from '../../../components/Popover'
import Rules from '../../../components/Rules'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import useSubmit from '../useSubmit'
import { controlList } from '../storage'
import { RoleData } from '../types'
import RoleActivateDeactivate from './RoleActivateDeactivate'
import Enable from '../../../components/Enable'
import BtnActionTable from '../../../components/BtnActionTable'

function Edit({ role }: { role: RoleData }) {
    const open = useSubmit(role.id)

    return (
        <Rules cu="CU003">
            <BtnActionTable
                title="Editar"
                iconName="bs-pencil"
                onClick={() =>
                    open({
                        name: role.name,
                        department_id: {
                            label: role.department.name,
                            value: role.department.id.toString()
                        }
                    })
                }
            />
        </Rules>
    )
}

export default function TableRoles() {
    const history = useHistory()

    return (
        <List control={controlList}>
            {(role, e) => (
                <tr key={role.id}>
                    <td>{e}</td>
                    <td className={`${!role.is_enabled && 'line-through'}`}>
                        {role.name}
                    </td>
                    <td>{role.department.name}</td>
                    <td>
                        {role.n_permissions === 0 || role.n_permissions === null
                            ? '-'
                            : role.n_permissions}
                    </td>
                    <td>
                        <Enable enabled={role.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Edit role={role} />
                            <Rules cu="CU004">
                                <BtnActionTable
                                    title="Configurar permisos"
                                    iconName="bs-table"
                                    disabled={!role.is_enabled}
                                    onClick={() =>
                                        history.push(
                                            `/administration/roles/${role.id}/permissions`
                                        )
                                    }
                                />
                            </Rules>
                            <Rules cu={['CU005', 'CU006', 'CU007']}>
                                <Popover
                                    component={
                                        <BtnActionTable iconName="bs-three-dots" />
                                    }
                                    position="bottom-right-left"
                                    element=".panel--form-datatable"
                                >
                                    <RoleActivateDeactivate role={role} />
                                    <Rules cu="CU007">
                                        <Delete
                                            path={`/v5.6/apis/roles/${role.id}`}
                                            control={controlList}
                                            label="Eliminar rol"
                                        >
                                            ¿Está seguro de eliminar el rol{' '}
                                            <b className="text-primary">
                                                {role.name}
                                            </b>
                                            ?
                                        </Delete>
                                    </Rules>
                                </Popover>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
