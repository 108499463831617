import { useContext } from 'react'
import CU from '../../config/types/cu'
import AuthContext from '../../contexts/auth-context'
import { isAdmin } from '../../utilities/auth/tools'
import { PropsRules } from './types'

export default function Rules({ children, cu }: PropsRules) {
    const isAccesible = isCUAccesible(cu)
    return isAccesible ? <>{children}</> : null
}

export function isCUAccesible(cus: CU | CU[]) {
    const { CUs, user } = useContext(AuthContext)
    if (isAdmin(user.id)) return true
    let isAccesible = false
    if (Array.isArray(cus)) {
        isAccesible = !!CUs.find(x => cus.find(_cu => _cu === x))
    } else {
        isAccesible = !!CUs.find(x => x === cus)
    }
    return isAccesible
}
