import writeXlsxFile, { Schema } from 'write-excel-file'
import BtnActionTable from '../../../../components/BtnActionTable'
import { get } from '../../../../utilities/http'
import { InputPartExcel, InputPartProductExcel } from '../../types'

export default function ExportExcel({
    input_part_id
}: {
    input_part_id: number
}) {
    async function download() {
        const { data } = await get<InputPartExcel>(
            `/v5.6/apis/input_parts/${input_part_id}/excel`
        )
        const schema: Schema<InputPartProductExcel> = [
            {
                column: 'Código de producto',
                width: 20,
                value: d => {
                    return d.product.code
                }
            },
            {
                column: 'Descripción de producto',
                width: 40,
                value: d => {
                    return d.product.description
                }
            },
            {
                column: 'Marca',
                width: 20,
                value: d => {
                    return d.product.brand.name
                }
            },
            {
                column: 'Cantidad',
                width: 20,
                type: Number,
                format: '0',
                value: d => {
                    return d.quantity
                }
            },
            {
                column: 'Valor Ingreso Unitario',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return d.income_unit_cost
                }
            },
            {
                column: 'Subtotal',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return d.income_unit_cost * d.quantity
                }
            }
        ]
        await writeXlsxFile(data.result.input_part_products, {
            fileName: `${data.result.document.code}.xlsx`,
            schema
        })
    }

    return (
        <BtnActionTable
            title="Excel"
            iconName="bs-file-earmark-excel"
            onClick={download}
        />
    )
}
