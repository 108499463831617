import DataTable, { TBody, THead } from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { storagePage } from '../../storage'
import useTableDistributionByProduct from './useTableDistributionByProduct'

export default function TableDistributionByProduct() {
    const { product_cost_distributions } = useTableDistributionByProduct()
    const symbol = storagePage.currentCurrency.symbol

    return (
        <DataTable scrollHeight={350} className="sticky">
            <THead>
                <tr>
                    <th>#</th>
                    <th>Recepción</th>
                    <th>Requisición</th>
                    <th>Producto</th>
                    <th>Valor de ingreso unitario actual</th>
                    <th>Valor de ingreso nuevo</th>
                </tr>
            </THead>
            <TBody>
                {product_cost_distributions.map((item, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.reception.code}</td>
                        <td>{item.requisition.code}</td>
                        <td>
                            <Typography type="paragraph">
                                {item.product.name}
                            </Typography>
                            <Typography type="small">
                                {item.product.code}
                            </Typography>
                        </td>
                        <td>
                            {symbol}{' '}
                            {item.current_income_unit_cost
                                ? buildNumberFormat(
                                      item.current_income_unit_cost
                                  )
                                : '-'}
                        </td>
                        <td>
                            {symbol}{' '}
                            {item.new_income_unit_cost
                                ? buildNumberFormat(item.new_income_unit_cost)
                                : '-'}
                        </td>
                    </tr>
                ))}
            </TBody>
        </DataTable>
    )
}
