import { render } from 'redity'
import { Empty, FormControl } from '../../../../components/FormUtilities'
import Option from '../../../../components/Option'
import SearchResults from '../../../../components/SearchResult'
import { Option as OptionType } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import {
    formTraspase,
    productsControl,
    reqProducts,
    storagePage
} from '../../storage'
import { LogicalProductToMove } from '../../types'

let value = ''

export default function SearchProducts() {
    const { props } = formTraspase.useSelects('traspase_products')
    const { loading, data } = useGet<LogicalProductToMove[]>(
        reqProducts.observer(
            '/v5.6/apis/logical_products/logical_products_to_move?limit=10&sort=product_name&order=asc&type_order_operation_id=6',
            storagePage.mode === 'edition'
        ),
        []
    )

    function handleSelect(nextOptions: OptionType[]) {
        props.onChange(nextOptions)
        if (nextOptions.length === 0) {
            productsControl.setList([])
            // reqProducts.deleteQuery('not_logical_product_ids')
            reqProducts.load()
            productsControl.renderMain()
            return
        }

        productsControl.mapping(lProduct => {
            if (
                !nextOptions.find(
                    option =>
                        parseInt(option.value) === lProduct.logical_product_id
                )
            ) {
                return null
            }
            return lProduct
        })

        nextOptions.forEach(option => {
            const productId = parseInt(option.value)
            if (
                productsControl
                    .getDataList()
                    .find(
                        _lProduct => _lProduct.logical_product_id === productId
                    )
            ) {
                return
            }

            const logicalProduct = data.result.find(
                _lProduct => productId === _lProduct.id
            )

            const current_traspase_product =
                storagePage.current_traspase_products.find(
                    traspase_product =>
                        traspase_product.output_operation.logical_product_id ===
                        logicalProduct.id
                )

            productsControl.push(
                {
                    id: current_traspase_product
                        ? current_traspase_product.id
                        : undefined,
                    logical_product_id: logicalProduct.id,
                    name: logicalProduct.product.name,
                    code: logicalProduct.product.code,
                    amount_to_transfer: '',
                    quantity_available: current_traspase_product
                        ? current_traspase_product.quantity +
                          logicalProduct.max_quantity_to_move
                        : logicalProduct.max_quantity_to_move
                },
                false
            )
        })

        // const ids = productsControl
        //     .getDataList()
        //     .map(lprod => lprod.logical_product_id)
        // reqProducts.setQuery('not_logical_product_ids', ids)

        productsControl.renderMain()
    }

    function handleChange(ev) {
        value = ev.target.value
        if (value === '') {
            reqProducts.deleteQuery('search')
        } else {
            reqProducts.setQuery('search', value)
        }
        reqProducts.load()
        render(formTraspase.keyRender, 'traspase_products')
    }

    const current_logical_product_ids =
        storagePage.current_traspase_products.map(
            ctp => ctp.output_operation.logical_product_id
        )

    const listAvailable = data.result.filter(
        item =>
            item.max_quantity_to_move > 0 ||
            current_logical_product_ids.includes(item.id)
    )

    return (
        <FormControl label="Productos" col="span-4" required>
            <SearchResults
                disabled={props.disabled === undefined ? true : props.disabled}
                options={props.options}
                helper={props.helper}
                loading={loading}
                onChange={handleChange}
                onSelect={handleSelect}
                placeholder="Seleccionar productos"
                label="Productos"
                value={value}
            >
                {listAvailable.map(logicalProduct => (
                    <Option
                        key={logicalProduct.id}
                        value={logicalProduct.id.toString()}
                        label={logicalProduct.product.name}
                        label2={logicalProduct.product.code}
                    />
                ))}
                <Empty data={listAvailable} />
            </SearchResults>
        </FormControl>
    )
}
