import { SimpleOption } from '../../../../../components/Option'
import { controlList } from '../../../storage'
import writeXlsxFile, { Schema } from 'write-excel-file'
import { get } from '../../../../../utilities/http'
import { buildPath } from '../../../../../sections/ModalPrint/utils'
import { BalanceHistoryExcel } from '../../../types'

export default function OptionExcel() {
    async function download() {
        const { data } = await get<BalanceHistoryExcel[]>(
            buildPath('/v5.6/apis/balance_histories/excel', [
                ['search', controlList.filter.getParam('search')],
                ['company_id', controlList.filter.getParam('company_id')],
                ['date', controlList.filter.getParam('date')],
                ['category_id', controlList.filter.getParam('category_id')],
                ['stowage_id', controlList.filter.getParam('stowage_id')],
                [
                    'minimal_stowage_id',
                    controlList.filter.getParam('minimal_stowage_id')
                ],
                ['only_orders', controlList.filter.getParam('only_orders')],
                ['only_clients', controlList.filter.getParam('only_clients')]
            ])
        )
        const schema: Schema<BalanceHistoryExcel> = [
            {
                column: 'Código de producto',
                width: 20,
                value: d => {
                    return d.product_code
                }
            },
            {
                column: 'Decripción de producto',
                width: 40,
                value: d => {
                    return d.product_description
                }
            },
            {
                column: 'Categoría',
                width: 20,
                value: d => {
                    return d.category_name
                }
            },
            {
                column: 'Marca',
                width: 20,
                value: d => {
                    return d.brand_name
                }
            },
            {
                column: 'Bodega',
                width: 20,
                value: d => {
                    return d.stowage_name
                }
            },
            {
                column: 'Subbodega',
                width: 20,
                value: d => {
                    return d.substowage_name
                }
            },
            {
                column: 'Cantidad',
                width: 20,
                type: Number,
                format: '0',
                value: d => {
                    return d.quantity
                }
            },
            {
                column: 'Costo Promedio',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return d.average_unit_cost
                }
            },
            {
                column: 'Saldo',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return d.quantity * d.average_unit_cost
                }
            },
            {
                column: 'Alerta costeo',
                width: 20,
                value: d => {
                    return d.cost_alert ? 'SI' : 'NO'
                }
            }
        ]
        await writeXlsxFile(data.result, {
            fileName: `reporte_inventario_${controlList.filter.getParam(
                'date'
            )}.xlsx`,
            schema
        })
    }

    return <SimpleOption onClick={download}>Exportar en excel</SimpleOption>
}
