import Checkbox from '../../../../../../components/Checkbox'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../../../components/DataTable'
import {
    ControlDivider,
    FormControl,
    LabelInline
} from '../../../../../../components/FormUtilities'
import useGet from '../../../../../../hooks/useGet'
import { storagePage } from '../../../../storage'
import { parse } from 'fter'
import Product from '../../../../../../models/Product'
import Loading from '../../../../../../components/Loading'
import { FieldApplyRCsProps } from './types'
import { ChangeEvent } from 'react'

export default function FieldApplyRCs({
    rcs,
    productIds,
    setProductIds
}: FieldApplyRCsProps) {
    const company_id = storagePage.logistical_declaration.company_id
    const queries = parse(
        {
            reception_ids: rcs.map(rc => rc.reception_id)
        },
        `/v5.6/apis/companies/${company_id}/receptions/products`
    )
    const { data, loading } = useGet<Product[]>(queries, [])

    const isChecked = (productId: number) =>
        !!productIds.find(id => id === productId)

    function checkHandler(ev: ChangeEvent<HTMLInputElement>) {
        const productId = Number(ev.target.value)
        if (isChecked(productId)) {
            setProductIds(productIds.filter(id => id !== productId))
        } else {
            setProductIds([...productIds, productId])
        }
    }

    return (
        <>
            <FormControl label="Aplicar a RCs" required>
                <ControlDivider divider="3">
                    {rcs.map(rc => (
                        <LabelInline label={rc.reception.code} key={rc.id}>
                            <Checkbox checked onChange={() => null} disabled />
                        </LabelInline>
                    ))}
                </ControlDivider>
            </FormControl>
            <DataTable>
                <THead>
                    <tr>
                        <th>Productos a costear</th>
                        <th />
                    </tr>
                </THead>
                <TBody>
                    {data.result.map(product => (
                        <tr key={product.id}>
                            <td>{product.description}</td>
                            <td>
                                <Checkbox
                                    value={product.id}
                                    checked={isChecked(product.id)}
                                    onChange={checkHandler}
                                />
                            </td>
                        </tr>
                    ))}
                    {data.result.length === 0 && !loading && (
                        <Caption colSpan={2}>No hay productos</Caption>
                    )}
                    {loading && (
                        <Caption colSpan={2}>
                            <Loading height="auto" scale={0.8} />
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
