import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import {
    formInputPart,
    productsControl,
    reqProducts,
    reqStowage,
    reqSubStowage,
    storagePage
} from '../../storage'
import { GetStowage } from '../../types'

export default function useStowage() {
    const { props } = formInputPart.useSelect('stowage')
    const { data, props: propsSearch } = useGet<GetStowage[]>(
        reqStowage.observer(
            '/v5.6/apis/stowages?limit=10&is_enabled=true&require_all_substowages=false',
            true
        ),
        []
    )

    function handleChange(option: Option) {
        props.onChange(option)

        formInputPart.setValue('sub_stowage', null)
        formInputPart.setValue('products', [])
        reqProducts.deleteQuery('not_logical_product_ids')
        productsControl.setList([])

        if (option.value === '0') {
            formInputPart.setProps(
                'sub_stowage',
                {
                    disabled: true
                },
                false
            )
            formInputPart.setProps('products', { disabled: true }, false)
        } else {
            const { type_stowage_id, minimal_stowage_principal }: any =
                data.result.find(
                    stowage => stowage.id === parseInt(option.value)
                )

            storagePage.current_type_stowage_id_origin = type_stowage_id

            const isPGR =
                type_stowage_id === 2 || // Pedidos (P)
                type_stowage_id === 6 || // Garantía proveedor (G)
                type_stowage_id === 11 // Remisionada (R)

            if (isPGR) {
                reqSubStowage.setParam('stowage_id', option.value)
                reqSubStowage.load()
            } else {
                reqProducts.setQuery(
                    'origin_minimal_stowage_id',
                    minimal_stowage_principal.id
                )
                storagePage.current_stowage_origin_id =
                    minimal_stowage_principal.id
                reqProducts.load()
            }

            formInputPart.setProps('sub_stowage', { disabled: !isPGR }, false)
            formInputPart.setProps('products', { disabled: isPGR }, false)
        }

        productsControl.renderMain()
    }

    return { props, stowages: data.result, handleChange, propsSearch }
}
