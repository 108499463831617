import { ModalTools } from '../../../../sections/ModalSection/types'
import { formContact, tableContacts } from '../../storage'
import {
    addContact,
    emailExists,
    nameExists,
    updateContact
} from '../../utilities/crudFormContact'
import FormContact from './FormContact'

export default function useContact(index?: number) {
    function handleSubmit(ev, modal: ModalTools) {
        ev.preventDefault()
        const result = formContact.test(v => {
            v.name
                .minLength(2, 'El número de caracteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el nombre')
                .condition(
                    value =>
                        nameExists(value, index) &&
                        'Ya existe un contacto con el mismo nombre'
                )
            v.position
                .minLength(2, 'El número de caracteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el cargo')
            v.email
                .minLength(2, 'El número de caracteres válidos es 2 - 180')
                .email('Se requiere ingresar un email válido')
                .isEmpty('Se requiere ingresar el email')
                .condition(
                    value =>
                        emailExists(value, index) &&
                        'Ya existe un contacto con el mismo email'
                )
            v.phone_number
                .minLength(2, 'El número de caracteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el teléfono')
        })
        if (result) {
            modal.close()
            index !== undefined ? updateContact(index) : addContact()
            tableContacts.renderMain()
        }
    }

    return formContact.useModal(FormContact, {
        title: index ? 'Editar Contacto' : 'Agregar Contacto',
        size: 'sm',
        onSubmit: handleSubmit
    })
}
