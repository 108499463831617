import test from './test'
import { Val } from './type'

export default function val<T>(data: T): Val<T> {
    const fields: Record<string, any> = {}
    const errors: Partial<Record<keyof T, string>> = {}

    function getTools(key: keyof T) {
        function to(fieldname: keyof T) {
            return test(fieldname, data[key], catch_error)
        }

        return {
            to
        }
    }

    for (const key in data) {
        fields[key] = {
            ...test(key, data[key], catch_error),
            ...getTools(key)
        }
    }

    function catch_error(key: string, message: string): void {
        errors[key] = message
    }

    function runtest(): null | Partial<Record<keyof T, string>> {
        if (Object.values(errors).length > 0) return errors
        return null
    }

    const methods = { ...fields, runtest }
    return methods as Val<T>
}
