import { useHistory } from 'react-router'
import {
    formInformation,
    storagePage,
    tableProductsControl
} from '../../storage'
import { ProductBody, FormProduct } from '../../types'

export default function useStep1Submit() {
    const { push } = useHistory()

    const { observer, validation, submit } =
        formInformation.useSubmit<FormProduct>(
            `/v5.6/apis/requisition/products`,
            {
                message: '¿Está seguro de guardar la requisición?',
                done: () => {
                    push('/requisitions')
                },
                method: 'put',
                onError: error => {
                    tableProductsControl.setError(error)
                }
            }
        )

    validation(() => {
        let pass = true
        if (tableProductsControl.list.length === 0) {
            pass = false
            tableProductsControl.setMessage(
                'Se requiere registrar al menos un producto'
            )
        }
        return pass
    })

    observer<ProductBody>(() => {
        const products = tableProductsControl.getDataList().map(item => ({
            product_id:
                item.product_data.product_company.product_country.product.id,
            quantity: parseInt(item.quantity)
        }))
        return { requisition_id: storagePage.requisitionId, products }
    })

    return submit
}
