import { THeadValue } from '../../utilities/Control/ControlList'
import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('COSTS', ['FORM_FILTER'])

export const tHeadsDrafts: THeadValue[] = [
    '#',
    'Nombre',
    'Tipo',
    'Periodo fiscal',
    { value: 'purchase_local_cost', label: 'Costo de compra' },
    { value: 'logistical_cost', label: 'Monto a costear' },
    'Monto a costear/Costo de compra',
    'Acciones'
]
export const tHeadsCosting: THeadValue[] = [
    '#',
    { value: 'document_code', label: 'Código' },
    { value: 'number_declaration', label: 'N° de declaración' },
    'Periodo fiscal',
    { value: 'purchase_local_cost', label: 'Costo de compra' },
    { value: 'logistical_cost', label: 'Monto a costear' },
    'Monto a costear/Costo de compra',
    'Acciones'
]
