import { memo } from 'react'
import { FormControl } from '../../../../../../components/FormUtilities'
import InputField from '../../../../../../components/InputField'
import { storagePage } from '../../../../storage'

function FieldLocalCost({ value }: FieldLocalCostProps) {
    return (
        <FormControl
            label={`Costo (${storagePage.currentCurrency.symbol})`}
            col="span-2"
        >
            <InputField disabled value={value} />
        </FormControl>
    )
}

export default memo(FieldLocalCost)

export interface FieldLocalCostProps {
    value: string
}
