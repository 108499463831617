import { Option } from '../../config/types/component'
import { SelectListProvider } from '../../contexts/select-context'
import useOut from '../../hooks/useOut'
import Loading from '../Loading'
import InputField from '../InputField'
import './selectTouch.css'
import { PropsSelectSearch } from './types'
import { createPortal } from 'react-dom'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import Icon from '../Icon'

export default function SelectSearch({
    label = 'Seleccionar',
    option = null,
    onChange = () => null,
    children,
    onSearch = null,
    search = '',
    loading = false,
    className = '',
    ...props
}: PropsSelectSearch) {
    const { ref, isOpen, setOpen } = useOut([])
    const selectBodyRef = useRef<HTMLDivElement>()
    const [isSearch, setModeSearch] = useState(false)
    function setPosition() {
        if (!selectBodyRef.current) return
        const y = ref.current?.getBoundingClientRect().y
        const x = ref.current?.getBoundingClientRect().x
        const scrollY = window.pageYOffset
        const widthSelect = ref.current?.clientWidth

        selectBodyRef.current.style.width = `${widthSelect + 3}px`
        selectBodyRef.current.style.top = `${y + scrollY + 42}px`
        selectBodyRef.current.style.left = `${x}px`
    }
    useEffect(() => {
        const timer = setTimeout(() => setPosition(), 200)

        window.addEventListener('scroll', setPosition)
        window.addEventListener('resize', setPosition)
        return () => {
            clearTimeout(timer)
            window.removeEventListener('scroll', setPosition)
            window.removeEventListener('resize', setPosition)
        }
    }, [])

    function handleOption(opt: Option) {
        onChange(opt)
        setModeSearch(false)
        setOpen(false)
    }

    function handleClick() {
        setOpen(true)
    }

    function handleFocus() {
        setOpen(true)
    }

    function handleSearch(e) {
        onSearch(e)
        setModeSearch(true)
    }

    function handleClear() {
        onChange({ value: '0', label: '' })
        handleSearch({ target: { value: '' } })
    }

    return (
        <div
            ref={ref}
            className={classNames('select-search w-full', className)}
        >
            <a href="" onFocus={handleFocus}></a>
            <Icon name="search" className={'absolute left-2 top-3'} />
            <InputField
                {...props}
                onClick={handleClick}
                value={isSearch ? search : option?.label || search}
                className={`w-full pl-8`}
                onChange={handleSearch}
            />
            {search && option && (
                <Icon
                    name="circle-close"
                    className={`absolute right-2 top-3 cursor-pointer`}
                    size={16}
                    onClick={handleClear}
                />
            )}
            {createPortal(
                <SelectListProvider
                    isOpen={isOpen}
                    onOption={handleOption}
                    label={label}
                    className="w-full top-auto z-select-options"
                    clear={false}
                    optionsChecks={option ? [option.value] : []}
                    ref={selectBodyRef}
                >
                    {loading ? <Loading scale={0.7} /> : children}
                </SelectListProvider>,
                document.querySelector('#select-contain')
            )}
        </div>
    )
}
