import { STORAGE_COMPANY_SELECTED, STORAGE_KEY } from '../config/app'
import { initialData, InitialData } from '../config/load'
import Company from '../models/Company'
import Country from '../models/Country'
import Currency from '../models/Currency'
import buildCompanyName from './buildCompanyName'
import { get } from './http'
import { HttpResponse } from './http/type'

export function initialStorage(initData: InitialData): InitialData {
    const storage = localStorage.getItem(STORAGE_KEY) || null
    return JSON.parse(storage) || initData
}

/**
 * Guarda los datos
 * al storage
 */
export function localStorageInitialData(data: InitialData) {
    data.originalCompanies = data.originalCompanies.map(company => {
        return {
            ...company,
            name: company.name
        }
    })
    data.companies = data.companies.map(company => {
        return {
            ...company,
            name: buildCompanyName(company.name)
        }
    })
    data.companiesFromSession = data.companiesFromSession.map(company => {
        return {
            ...company,
            name: buildCompanyName(company.name)
        }
    })
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
    localStorage.setItem(
        STORAGE_COMPANY_SELECTED,
        JSON.stringify(data.companiesFromSession[0])
    )
}

export function getCompanySelected(): Company & { country: Country } & {
    local_currency: Currency | null
} {
    // let company = companiesFromSession[0]
    // for (const allowedCompany of companiesFromSession) {
    //     if (allowedCompany.id === user.companyId) {
    //         company = allowedCompany
    //     }
    // }
    // console.log()
    let company = null
    if (localStorage.getItem(STORAGE_COMPANY_SELECTED) !== 'undefined') {
        company = JSON.parse(localStorage.getItem(STORAGE_COMPANY_SELECTED))
    }
    return company
}

export function setCompanySelected(
    company: Company & { country: Country } & {
        local_currency: Currency | null
    }
) {
    if (company !== null) {
        localStorage.setItem(STORAGE_COMPANY_SELECTED, JSON.stringify(company))
    }
}

export class Req {
    private gets: Partial<
        Record<keyof InitialData, Promise<HttpResponse<any[]>>>
    > = {}

    private order(key: keyof InitialData, path: string) {
        this.gets[key] = get<any[]>(path)
    }

    constructor(paths: Record<keyof InitialData, string>) {
        Object.keys(paths).forEach((key: keyof InitialData) => {
            this.order(key, paths[key])
        })
    }

    async result(): Promise<InitialData> {
        const gets = Object.values(this.gets)
        let responses: HttpResponse<any[]>[] = []

        if (gets.length < 20) {
            responses = await Promise.all(gets)
        } else {
            const matrix: Array<any[]> = []
            let ten = []
            for (let i = 0; i < gets.length; i++) {
                ten.push(gets[i])
                if (i < (matrix.length + 1) * 20) {
                    matrix.push(ten)
                    ten = []
                }
            }
            const matrixResponses = await Promise.all(
                matrix.map(list => Promise.all(list))
            )
            responses = matrixResponses.flat()
        }

        const dataList = initialData

        for (let i = 0; i < Object.keys(this.gets).length; i++) {
            const key = Object.keys(this.gets)[i]
            const error = responses[i].error
            if (error) {
                dataList[key] = []
            } else {
                dataList[key] = responses[i].data.result
            }
        }

        return dataList
    }
}
