import { useContext } from 'react'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import LayoutContext from '../../../contexts/layout-context'
import {
    controlTabsNational,
    controlTabsInternational,
    storagePage
} from '../storage'
import useSubmit1 from './SectionGeneralInformation/useSubmit1'
import useSubmit4 from './SectionCostDistribution/useSumit4'

export default function Header({ cancel }: { cancel: () => void }) {
    let nextText = 'Siguiente'
    let description = ''
    const { open } = useContext(LayoutContext)

    if (storagePage.type === 'international') {
        description =
            '(*) Complete todos los campos para el costeo de importación'
    } else {
        description = '(*) Complete todos los campos para el costeo nacional'
    }
    if (
        (storagePage.type === 'national' &&
            controlTabsNational.value === 'step4') ||
        (storagePage.type === 'international' &&
            controlTabsInternational.value === 'step5')
    ) {
        nextText = 'Guardar'
    }

    if (
        storagePage.mode === 'edition' &&
        controlTabsInternational.value === 'step1'
    ) {
        nextText = 'Guardar'
    }

    const submitStep1 = useSubmit1()
    const submitStep4 = useSubmit4()

    function handleClick() {
        if (
            (storagePage.type === 'international' &&
                controlTabsInternational.value === 'step1') ||
            (storagePage.type === 'national' &&
                controlTabsNational.value === 'step1')
        ) {
            submitStep1()
        } else if (
            (storagePage.type === 'national' &&
                controlTabsNational.value === 'step4') ||
            (storagePage.type === 'international' &&
                controlTabsInternational.value === 'step5')
        ) {
            submitStep4()
        } else {
            controlTabsInternational.next()
            controlTabsNational.next()
        }
    }

    const value =
        storagePage.type === 'international'
            ? controlTabsInternational.value
            : controlTabsNational.value

    function handleCancel() {
        if (value === 'step1' || storagePage.mode === 'edition') {
            cancel()
        } else {
            controlTabsInternational.prev()
            controlTabsNational.prev()
        }
        open()
    }

    return (
        <PageHeader subtitle={description}>
            <Button color="ghost" onClick={handleCancel}>
                {value === 'step1' || storagePage.mode === 'edition'
                    ? 'Cancelar'
                    : 'Volver'}
            </Button>
            <Button className="ml-2" onClick={handleClick}>
                {nextText}
            </Button>
        </PageHeader>
    )
}
