import { GetInvoice } from '../type'

export default function GeneralInformation({ data }: { data: GetInvoice }) {
    return (
        <div className="body__cards">
            <div className="column">
                <div className="card_info">
                    <h3>INFORMACIÓN GENERAL</h3>
                    <ul className="list_info">
                        {/* <li>
                            <p>Serie</p>
                            <p>A</p>
                        </li> */}
                        <li>
                            <p>Factura</p>
                            <p>{data.invoice_number}</p>
                        </li>
                        <li>
                            <p>Fecha</p>
                            <p>{data.date}</p>
                        </li>
                    </ul>
                </div>
                <div className="card_info">
                    <h3>DATOS DEL CLIENTE</h3>
                    <ul className="list_info">
                        <li>
                            <p>Documento fiscal</p>
                            <p>{data.client.document}</p>
                        </li>
                        <li>
                            <p>Empresa</p>
                            <p>{data.client.name}</p>
                        </li>
                        <li>
                            <p>Dirección</p>
                            <p>{data.client.address}</p>
                        </li>
                        <li>
                            <p>Teléfono</p>
                            <p>{data.client.phone}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <div className="card_info">
                    <h3>DATOS DEL VENDEDOR</h3>
                    <ul className="list_info">
                        <li>
                            <p>Vendedor</p>
                            <p>{data.seller.name}</p>
                        </li>
                        <li>
                            <p>Móvil corporativo</p>
                            <p>{data.seller.phone}</p>
                        </li>
                        <li>
                            <p>Correo</p>
                            <p>{data.seller.email}</p>
                        </li>
                    </ul>
                </div>
                <div className="card_info">
                    <h3>DATOS DEL PEDIDO</h3>
                    <ul className="list_info">
                        <li>
                            <p>Pedido</p>
                            <p>{data.order_number}</p>
                        </li>
                        {/* <li>
                            <p>N/Entrega</p>
                            <p>1064</p>
                        </li>
                        <li>
                            <p>Entregar en</p>
                            <p>Panamá</p>
                        </li> */}
                        <li>
                            <p>Condición de pago</p>
                            <p>{data.client.payment_method}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
