import moment from 'moment'
import { render } from 'redity'
import { Option } from '../../../../config/types/component'
import useStorage from '../../../../hooks/useStorage'
import { setCompanySelected } from '../../../../utilities/controlStorage'
import {
    formInputPart,
    productsControl,
    reqProducts,
    reqStowage,
    reqWarehouse,
    reqWorkingPeriod,
    storagePage
} from '../../storage'

export default function useCompany() {
    const { props } = formInputPart.useSelect('company')
    const { companiesFromSession } = useStorage()

    const companies = companiesFromSession
        .filter(company => company.is_enabled)
        .map(company => ({
            id: company.id,
            name: company.name,
            image: company.country.image_url
        }))

    function handleChange(option: Option) {
        if (option.value === '0') {
            formInputPart.setProps('warehouse', { disabled: true }, false)
        } else {
            setCompanySelected(
                companiesFromSession.find(c => c.id === parseInt(option.value))
            )
            formInputPart.setProps('warehouse', { disabled: false }, false)
            reqWarehouse.setQuery('company_id', option.value)
            reqWarehouse.load()
            reqStowage.setQuery('company_id', option.value)
            reqStowage.load()
            reqWorkingPeriod.setParam('company_id', option.value)
            reqWorkingPeriod.load()
        }
        formInputPart.setProps('sub_stowage', { disabled: true }, false)

        formInputPart.setProps('products', { disabled: true })
        formInputPart.setValue('warehouse', null)
        formInputPart.setValue('stowage', null)
        formInputPart.setValue('sub_stowage', null)

        productsControl.setList([])
        productsControl.renderMain()
        reqProducts.deleteQuery('not_logical_product_ids')

        storagePage.symbol = storagePage.companies.find(
            c => c.id === parseInt(option.value)
        ).local_currency.symbol

        storagePage.timezone = storagePage.companies.find(
            c => c.id === parseInt(option.value)
        ).timezone

        const date = moment().tz(storagePage.timezone).parseZone()
        formInputPart.setValue('request_date', date.toISOString(true))
        reqProducts.setQuery('operation_date', date.format('YYYY-MM-DD'))
        render('badge_label')
        props.onChange(option)
    }

    return {
        props,
        companies,
        handleChange
    }
}
