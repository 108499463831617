import { ExcelRendered } from '../../../components/ExcelField/types'
import { BodyProduct } from '../types'
import BuildDataCompany, { DataCompanyBuilded } from './BuildDataCompany'
import fixValue from './fixValue'
import labelToKeyName from './labelToKeyName'

export default function excelToBody(excelDataRendered: ExcelRendered) {
    const data: Array<BodyProduct> = []

    const [headData, ...bodyData] = excelDataRendered.rows

    for (const rows of bodyData) {
        const bodyProduct: BodyProduct = {}

        for (let i = 0; i < headData.length; i++) {
            const key = labelToKeyName(headData[i] as string)
            const value = rows[i]
            bodyProduct[key] = fixValue(key, value)
        }

        data.push(bodyProduct)
    }

    const dataWithPreCompanies = data.map(rows => {
        const restData: BodyProduct = {}
        const companias: Array<{ key: string; value: any }> = []

        for (const key of Object.keys(rows)) {
            if (key.search('comp_') > -1 || key.search('codigo_fiscal_') > -1) {
                companias.push({
                    key,
                    value: rows[key]
                })
            } else {
                restData[key] = rows[key]
            }
        }

        return {
            ...restData,
            companias
        }
    })

    const products: Array<BodyProduct> = dataWithPreCompanies.map(rows => {
        const companias = buildCompanias(rows.companias)

        return {
            ...rows,
            companias
        }
    })

    return {
        products
    }
}

function buildCompanias(
    companias: Array<{
        key: string
        value: any
    }>
) {
    const buildDataCompany = new BuildDataCompany()
    const fieldNameCompaniesWithValues: DataCompanyBuilded[] = []

    for (const company of companias) {
        buildDataCompany.setValue(company.key, company.value)
        fieldNameCompaniesWithValues.push(buildDataCompany.build(company.key))
    }

    const companiesBuilded: DataCompanyBuilded[] = []
    for (const v of fieldNameCompaniesWithValues) {
        if (!companiesBuilded.find(_v => _v.codigo === v.codigo)) {
            if (
                v.compra !== undefined &&
                v.venta !== undefined &&
                v.codigo_fiscal !== undefined
            ) {
                companiesBuilded.push(v)
            }
        }
    }

    return companiesBuilded
}
