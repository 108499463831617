import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import { LicenseData } from './types'
import { storagePage, formLicense } from './storage'
import { useParams } from 'react-router'
import build from './utilities/build'
import moment from 'moment'

export default function useInitial() {
    type Params = { license_id?: string; license_id_for_renovate?: string }
    const { license_id, license_id_for_renovate } = useParams<Params>()

    const [pageState, setState] = useState<StateCase>(StateCase.LOADING)

    let title = 'Nueva Licencia'
    if (license_id) {
        storagePage.mode = 'edition'
        title = 'Editar Licencia'
    } else if (license_id_for_renovate) {
        storagePage.mode = 'renovate'
        title = 'Renovar Licencia'
    }

    async function load() {
        if (storagePage.mode !== 'creation') {
            const response = await get<LicenseData>(
                `/v5.6/apis/licenses/${license_id || license_id_for_renovate}`
            )

            if (response.error) {
                setState(StateCase.ERROR)
                return
            } else {
                storagePage.license = response.data.result
                const { issue_date, due_date } = storagePage.license
                storagePage.license.issue_date = moment(issue_date)
                    .tz('UTC')
                    .toISOString()
                if (due_date) {
                    storagePage.license.due_date = moment(due_date)
                        .tz('UTC')
                        .toISOString()
                }
            }
        }
        build()
        setState(StateCase.SUCCESS)
    }

    useEffect(() => {
        load()
        return () => {
            storagePage.mode = 'creation'
            formLicense.init()
        }
    }, [])

    return {
        pageState,
        title
    }
}
