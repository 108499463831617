import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import Typography from '../../../components/Typography'
import Information from './Information'
import Products from './Products'
import Header from './Header'
import useInitial from '../useInitial'
import { useRender } from 'redity'

export default function RequisitionManagement() {
    const { pageState } = useInitial()
    useRender('requisition_data')
    return (
        <Initial pageState={pageState}>
            <Header />
            <Panel mode="form">
                <PanelBody>
                    <Typography type="header-3" className="mb-4">
                        Información de la requisición
                    </Typography>
                    <Information />
                    <Typography type="header-3" className="mb-4 mt-4">
                        Productos solicitados
                    </Typography>
                    <Products />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
