import {
    tHeadsClose,
    tHeadsConfirm,
    tHeadsPending,
    tHeadsSending
} from '../../constants'
import { cts, ocList } from '../../storage'
import TableClosed from './TableClosed'
import TableConfirm from './TableConfirm'
import TablePending from './TablePeding'
import TableSending from './TableSending'

cts.setSection('Pendientes', TablePending, 'assignment')
cts.setSection('Enviadas a proveedor', TableSending, 'send')
cts.setSection('Confirmadas', TableConfirm, 'local_shipping')
cts.setSection('Cerradas', TableClosed, 'check-cirle')

export default function Tables() {
    const value = cts.useStep(to => {
        if (to === 'step1') {
            ocList.filter.setParams('purchase_state_id', 1)
            ocList.thead = tHeadsPending
        }

        if (to === 'step2') {
            ocList.filter.setParams('purchase_state_id', 2)
            ocList.thead = tHeadsSending
        }

        if (to === 'step3') {
            ocList.filter.setParams('purchase_state_id', 3)
            ocList.thead = tHeadsConfirm
        }

        if (to === 'step4') {
            ocList.filter.setParams('purchase_state_id', 4)
            ocList.thead = tHeadsClose
        }

        ocList.filter.changeParams('page', 1)
        ocList.load().then(() => ocList.render())

        return true
    })

    return (
        <cts.SectionTabs
            value={value}
            type="tab"
            mode="simple"
            className="mb-4 mt-2"
        />
    )
}
