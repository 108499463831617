import moment from 'moment'
import { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Icon from '../../../../components/Icon'
import Typography from '../../../../components/Typography'
import { generateId } from '../../../../utilities/generateId'
import { TableRQ } from '../../types'

export default function Alerts({ rq }: { rq: TableRQ }) {
    const [id] = useState(() => generateId())

    const temperature_id = `temperature_${id}`
    const boat_id = `boat_${id}`
    const air_id = `air_${id}`
    const alert_id = `alert_${id}`

    return (
        <td>
            {rq.max_temperature !== null && (
                <>
                    <Icon
                        data-for={temperature_id}
                        data-tip={temperature_id}
                        name="thermostate"
                        className="text-blue-500 mr-2"
                    />
                    <ReactTooltip
                        id={temperature_id}
                        border
                        borderColor="#eee"
                        place="top"
                        getContent={() => (
                            <div>
                                <Typography type="paragraph-bold">
                                    Refrigeración
                                </Typography>
                                <p>
                                    Min: {rq.min_temperature} C° - Max:{' '}
                                    {rq.max_temperature} C°
                                </p>
                            </div>
                        )}
                        type="light"
                        effect="solid"
                        multiline={true}
                    />
                </>
            )}

            {!rq.marine_transport && (
                <>
                    <Icon
                        data-for={boat_id}
                        data-tip={boat_id}
                        name="boat"
                        className="text-error mr-2"
                    />
                    <ReactTooltip
                        id={boat_id}
                        border
                        borderColor="#eee"
                        place="top"
                        getContent={() => (
                            <div>
                                <Typography type="paragraph-bold">
                                    Restricción de transporte
                                </Typography>
                                <p>
                                    El producto no puede ser transportado vía
                                    marítima
                                </p>
                            </div>
                        )}
                        type="light"
                        effect="solid"
                        multiline={true}
                    />
                </>
            )}

            {!rq.air_transport && (
                <>
                    <Icon
                        data-for={air_id}
                        data-tip={air_id}
                        name="air-port"
                        className="text-error mr-2"
                    />
                    <ReactTooltip
                        id={air_id}
                        border
                        borderColor="#eee"
                        place="top"
                        getContent={() => (
                            <div>
                                <Typography type="paragraph-bold">
                                    Restricción de transporte
                                </Typography>
                                <p>
                                    El producto no puede ser transportado vía
                                    aerea
                                </p>
                            </div>
                        )}
                        type="light"
                        effect="solid"
                        multiline={true}
                    />
                </>
            )}

            {(rq.licenses.expire.length > 0 ||
                rq.licenses.expired.length > 0) && (
                <>
                    <Icon
                        data-for={alert_id}
                        data-tip={alert_id}
                        name="warning"
                        className="text-error"
                    />
                    <ReactTooltip
                        id={alert_id}
                        border
                        borderColor="#eee"
                        place="top"
                        getContent={() => (
                            <div>
                                <Typography type="paragraph-bold">
                                    Permiso Expirado
                                </Typography>

                                {rq.licenses.expire.map(license => (
                                    <p key={license.id}>
                                        {license.name}:{' '}
                                        {moment(license.due_date).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </p>
                                ))}
                                <Typography type="paragraph-bold">
                                    Permiso por expirar
                                </Typography>
                                {rq.licenses.expired.map(l => (
                                    <p key={l.id}>
                                        {l.name}:{' '}
                                        {moment(l.due_date).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </p>
                                ))}
                            </div>
                        )}
                        type="light"
                        effect="solid"
                        multiline={true}
                    />
                </>
            )}
        </td>
    )
}
