import { memo } from 'react'
import { FormControl } from '../../../../../../components/FormUtilities'
import NumeralField from '../../../../../../components/NumeralField'

function FieldCost({ value, onChange, symbol, helper }: FieldCostProps) {
    return (
        <FormControl label={`Costo (${symbol})`} required col="span-2">
            <NumeralField
                value={value}
                decimal={2}
                onChange={ev => onChange(ev.target.value)}
                negative
                helper={helper}
            />
        </FormControl>
    )
}

export default memo(FieldCost)

export interface FieldCostProps {
    value: string
    onChange: (value: string) => void
    symbol: string
    helper: string
}
