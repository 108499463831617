import ControlRequest from '../../utilities/ControlRequest'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { StoragePage, Form } from './types'

export const storagePage: StoragePage = {
    requisition: null,
    products: [],
    openDialog: false,
    initLoad: false,
    requisition_product_id: null
}

export const formManagementRQ = new FormEvents<Form>(Keys.FORM, {
    requisition: null
})

export const formDialog = new FormEvents<any>(Keys.FORM, {
    reason: ''
})

export const reqRequisition = new ControlRequest()
