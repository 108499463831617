import Alert from '../../../../../components/Alert'
import Panel, { PanelBody, PanelHeader } from '../../../../../components/Panel'
import useGet from '../../../../../hooks/useGet'
import { storagePage } from '../../../storage'
import { ResponseLDR } from '../../../types'
import { reqRC2 } from '../storage'
import Result from './Result'
import TableDistribution from './TableDistribution'

export default function Distribution() {
    const { logistical_declaration, version } = storagePage

    const { data } = useGet<ResponseLDR[]>(
        reqRC2.observer(
            `2>/v5.6/apis/logistical_declarations/${logistical_declaration.id}/receptions?is_enabled=true&version=${version}`,
            true
        ),
        []
    )

    return (
        <Panel mode="form">
            <PanelHeader title="Distribución de monto a costear" />
            <PanelBody>
                <Alert className="mb-2">
                    La diferencia de costeo lo asumirán los demás recepciones de
                    compra
                </Alert>

                <TableDistribution
                    logisticalDeclarationReception={data.result}
                />
                <Result logisticalDeclarationReception={data.result} />
            </PanelBody>
        </Panel>
    )
}
