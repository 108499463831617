import DropContent from '../../../components/DropContent'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectTouch from '../../../components/SelectTouch'
import useGet from '../../../hooks/useGet'
import StowageType from '../../../models/Stowage'
import ProviderType from '../../../models/Provider'
import { controlList, filterForm, reqStowage, reqProvider } from '../storage'

function Stowage() {
    const { props } = filterForm.useSelect<StowageType>('stowage_id')

    const {
        data,
        loading,
        props: propsSearch
    } = useGet<StowageType[]>(
        reqStowage.observer('/v5.6/apis/stowages', true),
        []
    )

    return (
        <FormControl label="Bodega destino">
            <SelectTouch
                {...props}
                {...propsSearch}
                loading={loading}
                placeholder="Seleccionar bodega destino"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Provider() {
    const { props } = filterForm.useSelect<ProviderType>('provider_id')

    const {
        data,
        loading,
        props: propsSearch
    } = useGet<ProviderType[]>(
        reqProvider.observer('/v5.6/apis/providers?limit=20', true),
        []
    )

    return (
        <FormControl label="Proveedor">
            <SelectTouch
                {...props}
                {...propsSearch}
                loading={loading}
                placeholder="Seleccionar proveedor"
            >
                <IterateOptions data={data.result} label="commercial_name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function FiltersForm() {
    const { props } = controlList.useForm(filterForm)

    async function handleApply() {
        controlList.filter.params.page = 1
        await props.onApply()
    }
    return (
        <DropContent
            {...props}
            onApply={handleApply}
            label="Filtros"
            className="ml-2"
        >
            <Stowage />
            <Provider />
        </DropContent>
    )
}
