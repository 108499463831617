import { tableAddress } from '../../storage'
import { Popup } from '../../../../utilities/popup'
import { TableAddress } from '../../types'
import BtnActionTable from '../../../../components/BtnActionTable'

export default function DeleteAddress({
    data,
    index
}: {
    data: TableAddress
    index: number
}) {
    async function deleteAddress() {
        if (data.id) {
            const result = await Popup.delete(
                'Eliminar registro',
                <>
                    Se removerá la dirección de la lista. ¿Estás seguro de
                    eliminar la dirección <b>{data.address}</b>?
                </>
            )
            if (!result) return
        }

        tableAddress.del(index)

        if (data.is_main) {
            let nextIndex = null
            for (let i = 0; i < tableAddress.list.length; i++) {
                const formAddress = tableAddress.list[i]
                if (formAddress.store().country_id === data.country_id) {
                    nextIndex = i
                    break
                }
            }

            if (nextIndex !== null) {
                tableAddress.put(nextIndex, { is_main: true })
            }
        }
    }

    return (
        <BtnActionTable
            title="Eliminar"
            iconName="bs-trash"
            onClick={deleteAddress}
        />
    )
}
