import moment from 'moment'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { StoragePage, Form } from './types'

export const storagePage: StoragePage = {
    license: null,
    mode: 'creation'
}

export const formLicense = new FormEvents<Form>(Keys.FORM, {
    company: null,
    type_licenses: [],
    code: '',
    name: '',
    issue_date: moment().toISOString(true),
    due_date_is_enabled: false,
    due_date: '',
    files: [],
    description: '',
    version: 'v1'
})

formLicense.store.on('issue_date', function (dateString) {
    const disabled = !formLicense.store().due_date_is_enabled
    formLicense.setProps(
        'due_date',
        {
            disabled,
            minDate: moment(dateString).add(1, 'day').toISOString(true)
        },
        false
    )
    formLicense.setValue('due_date', null)
})

formLicense.store.on('due_date_is_enabled', function (checked) {
    const minDate = moment(formLicense.store().issue_date)
        .add(1, 'day')
        .toISOString(true)
    if (!checked) {
        formLicense.setValue('due_date', null, false)
    }
    formLicense.setProps('due_date', { disabled: !checked, minDate })
})
