import { ReactNode, useContext } from 'react'
import LayoutContext from '../../contexts/layout-context'

export interface PropsPageHeader {
    title?: string
    subtitle?: string
    children: ReactNode
}

export default function PageHeader({
    title,
    subtitle,
    children
}: PropsPageHeader) {
    const { isSidebarOpen } = useContext(LayoutContext)

    return (
        <div
            className={`pl-${
                isSidebarOpen ? '72' : '8'
            } pr-10 py-4 bg-gray-50 border-t fixed w-full z-20 bottom-0 left-0 shadow-lg`}
            style={{
                transition: '.5s ease'
            }}
        >
            <div className="flex justify-between items-center">
                <div>
                    {title && (
                        <h2 className="text-lg font-univers-cn">{title}</h2>
                    )}
                    {subtitle && (
                        <h3 className="text-xs font-univers-light">
                            {subtitle}
                        </h3>
                    )}
                </div>
                <div>{children}</div>
            </div>
        </div>
    )
}
