import { controlList, formPaymentConcept } from './storage'
import Form from './View/Form'

export default function useSubmit(logisticalConceptId?: number) {
    const { submit, validation } = formPaymentConcept.useSubmit(
        logisticalConceptId
            ? `/v5.6/apis/logistical_concept/${logisticalConceptId}`
            : '/v5.6/apis/logistical_concept',
        {
            method: logisticalConceptId ? 'put' : 'post',
            done: controlList
        }
    )

    validation(v => {
        v.name.minLength(2, 'El número de caracteres válidos es 2 - 180')
        v.name.maxLength(180, 'El número de caracteres válidos es 2 - 180')
        v.name.isEmpty('Se requiere ingresar el nombre')
    })

    return formPaymentConcept.useModal(Form, {
        title: logisticalConceptId
            ? 'Editar concepto de pago'
            : 'Nuevo concepto de pago',
        onSubmit: submit,
        size: 'sm'
    })
}
