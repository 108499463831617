import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import MinimalStowage from '../../../../models/MinimalStowage'
import {
    formInputPart,
    productsControl,
    reqProducts,
    reqSubStowage,
    storagePage
} from '../../storage'

export default function useSubStowage() {
    const { data, props: propsSearch } = useGet<DestinyStowageValue[]>(
        reqSubStowage.observer(
            '/v5.6/apis/stowages/:stowage_id/substowages',
            storagePage.mode === 'edition'
        ),
        []
    )
    const { props } = formInputPart.useSelect('sub_stowage')

    function handleChange(option: Option) {
        props.onChange(option)
        formInputPart.setProps(
            'products',
            {
                disabled: option.value === '0'
            },
            false
        )
        reqProducts.deleteQuery('not_logical_product_ids')
        reqProducts.setQuery('origin_minimal_stowage_id', option.value)
        formInputPart.setValue('products', [])
        productsControl.setList([])

        reqProducts.load()
        productsControl.renderMain()
    }

    return {
        props,
        propsSearch,
        handleChange,
        data
    }
}
interface DestinyStowageValue extends MinimalStowage {
    substowage_name: string
}
