import { render, useRender } from 'redity'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContent, StatesModalSection } from './types'

const Key = 'MODAL_VIEW_2'

const Empty = () => <>👀</>

const states: StatesModalSection = {
    isOpen: false,
    loading: false,
    title: '',
    Component: Empty,
    onSubmit: () => null,
    size: 'sm',
    props: {}
}

export function ModalSection() {
    useRender(Key)
    function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        states.onSubmit(ev, {
            open: () => {
                states.isOpen = true
                states.loading = false
                render(Key)
            },
            close: closeModal
        })
    }

    return (
        <Modal
            open={states.isOpen}
            onSubmit={handleSubmit}
            size={states.size}
            title={states.title}
            onClose={closeModal}
        >
            <ModalBody>
                {states.isOpen && !states.loading && (
                    <states.Component {...states.props} />
                )}
                {states.loading && <Loading height="240px" />}
            </ModalBody>
            <ModalFooter>
                <Button color="ghost" onClick={closeModal}>
                    Cancelar
                </Button>
                {states.onSubmit !== null && (
                    <Button className="ml-2" type="submit">
                        Guardar
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    )
}

export function closeModal() {
    states.isOpen = false
    render(Key)
}

export function openModal() {
    states.isOpen = true
    render(Key)
}

export default async function modal(
    Component: (...props: any) => JSX.Element,
    content: ModalContent | (() => Promise<ModalContent | void | null>),
    size: 'sm' | 'md' | 'lg'
) {
    states.Component = Component
    states.loading = false
    states.isOpen = true
    states.size = size
    if (typeof content === 'function') {
        states.loading = true
        render(Key)
        const response = await content()
        if (!response) {
            states.isOpen = false
        } else {
            states.props = response.props || {}
            states.title = response.title
            states.onSubmit = response.onSubmit
        }
        states.loading = false
        render(Key)
    } else {
        states.props = states.props || {}
        states.title = content.title
        states.onSubmit = content.onSubmit
    }
    render(Key)
    return closeModal
}
