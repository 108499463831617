import BtnActionTable from '../../../components/BtnActionTable'
import Enable from '../../../components/Enable'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import Product from '../../../models/Product'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { GetProviderCatalog } from '../../../types/provider_catalog'
import buildCompanyName from '../../../utilities/buildCompanyName'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { providerList } from '../storage'
import { ProductPrividerCompanyGet } from '../types'
import useSubmitProductCatalog from '../useSubmitProductCatalog'
import ProductInfo from './ProductInfo'
import StateAction from './StateAction'

function Edition({
    productProviderCompany
}: {
    productProviderCompany: GetProviderCatalog
}) {
    const openModal = useSubmitProductCatalog(productProviderCompany)
    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={openModal}
            disabled={
                !productProviderCompany.provider_company.provider.is_enabled ||
                !productProviderCompany.product_company.company.memory
            }
        />
    )
}

function Code({
    product,
    productProviderCompanyId
}: {
    product: Product
    productProviderCompanyId: number
}) {
    const math = useRule('CU116')

    return (
        <div
            {...(math && {
                className: 'underline cursor-pointer',
                onClick: () =>
                    view<ProductPrividerCompanyGet>(
                        `/v5.6/apis/product_provider_companies/${productProviderCompanyId}`,
                        () => ({
                            title: product.name,
                            subtitle: product.code,
                            Component: ProductInfo
                        }),
                        'sm'
                    )
            })}
        >
            <b>{product.code}</b>
        </div>
    )
}

export default function Table() {
    return (
        <List control={providerList}>
            {(productProviderCompany, e) => (
                <tr key={productProviderCompany.id}>
                    <td>{e}</td>
                    <td>
                        <Code
                            productProviderCompanyId={productProviderCompany.id}
                            product={
                                productProviderCompany.product_company
                                    .product_country.product
                            }
                        />
                    </td>
                    <td className="max-w-lg" style={{ whiteSpace: 'normal' }}>
                        {
                            productProviderCompany.product_company
                                .product_country.product.description
                        }
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {productProviderCompany.provider_code}
                    </td>
                    <td
                        style={{ whiteSpace: 'normal' }}
                        className={
                            productProviderCompany.product_company.company
                                .memory
                                ? ''
                                : 'line-through'
                        }
                    >
                        <img
                            src={
                                productProviderCompany.product_company.company
                                    .country.image_url
                            }
                            className="w-8 inline mr-2"
                        />
                        {buildCompanyName(
                            productProviderCompany.product_company.company.name
                        )}
                    </td>
                    <td>
                        {
                            productProviderCompany.company_currency.currency
                                .symbol
                        }{' '}
                        {buildNumberFormat(
                            productProviderCompany.purchase_price
                        )}
                    </td>
                    <td>
                        <Enable enabled={productProviderCompany.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU112">
                                <Edition
                                    productProviderCompany={
                                        productProviderCompany
                                    }
                                />
                            </Rules>
                            <StateAction
                                productProviderCompanyId={
                                    productProviderCompany.id
                                }
                                isEnabled={productProviderCompany.is_enabled}
                                disabled={
                                    !productProviderCompany.provider_company
                                        .provider.is_enabled ||
                                    !productProviderCompany.product_company
                                        .company.memory
                                }
                                name={
                                    productProviderCompany.product_company
                                        .product_country.product.name
                                }
                            />
                            <Rules cu="CU115">
                                <Delete
                                    path={`/v5.6/apis/product_provider_company/${productProviderCompany.id}`}
                                    control={providerList}
                                    disabled={
                                        !productProviderCompany.provider_company
                                            .provider.is_enabled ||
                                        !productProviderCompany.product_company
                                            .company.memory
                                    }
                                >
                                    Se eliminará el producto del catálogo de
                                    proveedor para costos en compañía Adv. Lab
                                    Aguas. ¿Está seguro de remover el producto{' '}
                                    <b className="text-primary">
                                        {
                                            productProviderCompany
                                                .product_company.product_country
                                                .product.name
                                        }
                                    </b>{' '}
                                    del catálogo?
                                </Delete>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
