export default function ArrowLeft(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 7 13"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5.929 11.795l-4.513-5.12 4.513-5.12" strokeWidth={2} />
        </svg>
    )
}
