import NumeralField from '../../../../../components/NumeralField'
import Typography from '../../../../../components/Typography'
import {
    LOGISTICAL_CONCEPT__DUTY,
    LOGISTICAL_CONCEPT__EXTRA_CIF,
    LOGISTICAL_CONCEPT__FREIGHT,
    LOGISTICAL_CONCEPT__ISC,
    LOGISTICAL_CONCEPT__RATE_C_IVA,
    LOGISTICAL_CONCEPT__SAFE,
    LOGISTICAL_CONCEPT__SETTING_DUTY
} from '../../../../../config/constants'
import useGet from '../../../../../hooks/useGet'
import { TableList } from '../../../../../sections/ListForm'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import FormEvents from '../../../../../utilities/FormEvents'
import { storagePage } from '../../../storage'
import Cost from '../cost'
import { reqLDCostDistribution, tableIVAControl } from '../storageStep2'
import { IVASpecifications } from './Specifications'
import useIvaRow from './useIvaRow'

export default function TableIVA(props: Cost.ResponseLCIva) {
    const { logistical_declaration, version } = storagePage
    const { data } = useGet<Cost.ResponseLDProductsCostDistribution[]>(
        reqLDCostDistribution.observer(
            `/v5.6/apis/logistical_declaration/${logistical_declaration.id}/version/${version}/get_cost_distributions`,
            true
        ),
        []
    )

    const getDataLC = (
        lcs: Cost.ResponseLDProductsCostDistribution['logistical_costs']
    ) => {
        const freight_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__FREIGHT
        )
        const secure_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__SAFE
        )
        const rate_c_iva_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__RATE_C_IVA
        )
        const isc_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__ISC
        )
        const extra_cif_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__EXTRA_CIF
        )
        const duty_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__DUTY
        )
        const setting_duty_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__SETTING_DUTY
        )

        return {
            freight_cost,
            secure_cost,
            rate_c_iva_cost,
            isc_cost,
            extra_cif_cost,
            duty_cost,
            setting_duty_cost
        }
    }

    tableIVAControl.setList(
        data.result.map(d => {
            const {
                isc_cost,
                rate_c_iva_cost,
                freight_cost,
                secure_cost,
                duty_cost,
                extra_cif_cost,
                setting_duty_cost
            } = getDataLC(d.logistical_costs)
            const cif =
                d.purchase_dolar_cost_by_product +
                freight_cost.original_cost_by_product +
                secure_cost.original_cost_by_product +
                extra_cif_cost.original_cost_by_product
            const cif_local =
                d.purchase_local_cost_by_product +
                freight_cost.local_cost_by_product +
                secure_cost.local_cost_by_product +
                extra_cif_cost.local_cost_by_product

            const getPercent = (product_id: number) => {
                const percentage = props.logistical_iva_costs.find(
                    lic => lic.product_id === product_id
                )?.percentage

                if (percentage) return (percentage * 100).toString()
                return '0'
            }

            return {
                id: d.product.id,
                product_id: d.product.id,
                product_name: d.product.name,
                product_code: d.product.code,
                cif: cif,
                duty: duty_cost.original_cost_by_product,
                rate_c_iva: rate_c_iva_cost.original_cost_by_product,
                isc: isc_cost.original_cost_by_product,
                total:
                    cif +
                    duty_cost.original_cost_by_product +
                    rate_c_iva_cost.original_cost_by_product +
                    isc_cost.original_cost_by_product,
                local_total:
                    cif_local +
                    duty_cost.local_cost_by_product +
                    setting_duty_cost.local_cost_by_product +
                    rate_c_iva_cost.local_cost_by_product +
                    isc_cost.local_cost_by_product,
                iva_percent: getPercent(d.product_id),
                iva: 0
            }
        })
    )

    return (
        <>
            <label className="label">
                <span className="label-text text-gray-500 w-full">
                    IVA por producto
                </span>
            </label>
            <TableList
                control={tableIVAControl}
                scrollHeight={350}
                className="mb-4"
            >
                {(_, control, e) => <Row key={e} control={control} />}
            </TableList>
            <IVASpecifications />
        </>
    )
}

function Row({ control }: { control: FormEvents<Cost.ModalTableIva> }) {
    const { data, props, handleChange, tooltip } = useIvaRow(control)

    return (
        <tr className={tooltip && 'bg-red-100'}>
            <td>
                <Typography type="paragraph" className="font-bold">
                    {data.product_name}
                </Typography>
                <Typography type="small">{data.product_code}</Typography>
            </td>
            <td>{buildNumberFormat(data.cif)}</td>
            <td>{buildNumberFormat(data.duty)}</td>
            <td>{buildNumberFormat(data.rate_c_iva)}</td>
            <td>{buildNumberFormat(data.isc)}</td>
            <td>{buildNumberFormat(data.total)}</td>
            <td>
                <div className="w-24">
                    <NumeralField
                        {...props}
                        onChange={handleChange}
                        helper=""
                        max={100}
                        decimal={2}
                    />
                </div>
            </td>
            <td>
                {buildNumberFormat((data.total / 100) * Number(props.value))}
            </td>
        </tr>
    )
}
