/**
 * API Rest
 */
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || '/'
export const PUBLIC_URL_LOGIN = process.env.REACT_APP_PUBLIC_URL_LOGIN || '/'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const API_CORE_URL = process.env.REACT_APP_API_CORE_URL
export const API_FAKE = 'https://api-fake-test.herokuapp.com'
/**
 * Drive
 */
export const DRIVE_CREDENTIALS = {
    client_id: process.env.REACT_APP_DRIVE_CLIENT_ID,
    client_secret: process.env.REACT_APP_DRIVE_CLIENT_SECRET,
    refresh_token: process.env.REACT_APP_DRIVE_REFRESH_TOKEN,
    grant_type: process.env.REACT_APP_DRIVE_GRANT_TYPE
}

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

// ====================================== //
// Drive folder                           //
// ====================================== //
export const DRIVE_FOLDERS = {
    TEST: process.env.REACT_APP_DRIVE_FOLDERS_TEST,
    USER: process.env.REACT_APP_DRIVE_FOLDERS_USER
}

/**
 * @deprecated
 */
export const DRIVE_UPLOAD_LINK = 'https://drive.google.com/a/bitzone.lat/uc?id='
export const DRIVE_VIEW = 'https://drive.google.com/a/bitzone.lat/uc?id='

/**
 * @deprecated
 */
export const DRIVE_DOWNLOAD_LINK =
    'https://drive.google.com/uc?export=download&id='
export const DRIVE_DOWNLOAD = 'https://drive.google.com/uc?export=download&id='
