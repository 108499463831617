import { useHistory } from 'react-router'
import { Popup } from '../../utilities/popup'
import submit from '../../utilities/submit'
import getBody from './utilities/getBody'

export default function useSubmit(user_id: string) {
    const { push } = useHistory()

    async function handleSubmit() {
        const { success, error } = await submit(
            `/v5.6/apis/users/${user_id}/roles_by_company`,
            {
                title: 'Guardar cambios',
                body: getBody(),
                method: 'put',
                confirm:
                    '¿Está seguro de guardar los cambios de asignar roles al usuario?'
            }
        )

        if (error) {
            Popup.error(
                'Algo salió mal...',
                'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
            )
        } else if (success) {
            push('/administration/users')
        }
    }

    return {
        handleSubmit
    }
}
