import classNames from 'classnames'
import { PropsCalendarRows } from '../type'
import { getWeeks } from '../utilities'
import Day from './Day'

export default function CalendarRows({ viewDate }: PropsCalendarRows) {
    return (
        <>
            {getWeeks(viewDate).map((week, i) => (
                <div
                    key={i}
                    className={classNames(
                        `grid grid-cols-7 text-center text-gray-800 text-sm mt-2`
                    )}
                >
                    {week.map((day, y) => (
                        <Day key={y} viewDate={viewDate} day={day} />
                    ))}
                </div>
            ))}
        </>
    )
}
