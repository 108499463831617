import { useEffect, useState } from 'react'
import { API_CORE_URL } from '../../config/credentials'
import { StateCase } from '../../config/enums'
import useCancel from '../../hooks/useCancel'
import useStorage from '../../hooks/useStorage'
import { GetProduct } from '../../types/product'
import { get } from '../../utilities/http'

import {
    controlTabs,
    storagePage,
    formInformation,
    formNorms,
    tableCompaniesControl,
    tablePermitsControl,
    tableProductsControl,
    tableTaxesControl
} from './storage'
import { ChangeTax } from './types'
import prepareFormCreate from './utilities/prepareForCreate'
import prepareForEdition from './utilities/prepareForEdition'

export default function useInitial(product_id: string) {
    const [pageState, setPageState] = useState(StateCase.LOADING)
    const { companies, countriesAdvance, countries } = useStorage()
    const { onClick, init } = useCancel(
        [
            formInformation,
            formNorms,
            tableProductsControl,
            tableCompaniesControl,
            tableTaxesControl,
            tablePermitsControl
        ],
        '/configuration/products'
    )
    useEffect(() => {
        formNorms.setProps('min_temperature', { disabled: true }, false)
        formNorms.setProps('max_temperature', { disabled: true }, false)
        formInformation.setProps('type_communications', { disabled: true })
        formInformation.setParams('brand', { is_enabled: true })

        if (product_id) {
            storagePage.mode = 'edition'
            get<GetProduct>(`/v5.6/apis/products/${product_id}`).then(
                response => {
                    if (response.data) {
                        storagePage.product = response.data.result
                        prepareForEdition(response.data.result, countries)
                        setPageState(StateCase.SUCCESS)
                        init()
                    } else {
                        setPageState(StateCase.ERROR)
                    }
                }
            )
        } else {
            storagePage.mode = 'creation'
            fetch(`${API_CORE_URL}/apps/erp/change-tax`, {
                method: 'get',
                headers: {
                    Accept: 'application/json',
                    Authorization:
                        'Bearer ' + localStorage.getItem('core_token_erp')
                }
            })
                .then(async response => {
                    const changeTax: ChangeTax = await response.json()
                    prepareFormCreate(companies, countriesAdvance, changeTax)
                    setPageState(StateCase.SUCCESS)
                    init()
                })
                .catch(() => {
                    prepareFormCreate(companies, countriesAdvance, null)
                    setPageState(StateCase.SUCCESS)
                    init()
                })
        }

        return () => {
            formInformation.init()
            formNorms.init()
            tableProductsControl.setList([])
            controlTabs.value = 'step1'
            storagePage.product = null
            storagePage.products.clear()
            storagePage.step2Done = false
            storagePage.mode = 'creation'
        }
    }, [])

    return {
        cancel: onClick,
        pageState
    }
}
