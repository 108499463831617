import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectMultiple from '../../../components/SelectMultiple'
import Company from '../../../models/Company'
import Country from '../../../models/Country'
import buildCompanyName from '../../../utilities/buildCompanyName'
import { formWarehouse } from '../storage'

export default function Fields3() {
    const { props, getError, data } = formWarehouse.useSelects<
        Company & { country: Country }
    >(
        'companies',
        () =>
            `/v5.6/apis/companies/${
                formWarehouse.store().country?.value
            }/?is_enabled=true`
    )

    const helper = getError('ERROR-0000011', 'company_ids.0')

    const companies = data.map(company => ({
        id: company.id,
        name: buildCompanyName(company.name),
        image: company.country.image_url
    }))

    return (
        <FormControl label="Compañías" required col="span-6">
            <SelectMultiple
                {...props}
                label="Compañías"
                placeholder="Seleccionar compañías para relacionar con el almacén"
                helper={helper || props.helper}
                onSearch={null}
            >
                <IterateOptions data={companies} label="name" image="image" />
            </SelectMultiple>
        </FormControl>
    )
}
