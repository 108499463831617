import Selects from './Selects'
import Table from './Table'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'
import Fields4 from './Fields4'

export default function FormTariffsISCIVA() {
    return (
        <>
            <Selects />
            <Table />
            <Fields1 />
            <Fields2 />
            <Fields3 />
            <Fields4 />
        </>
    )
}
