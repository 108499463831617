import classNames from 'classnames'
import BtnActionTable from '../../../../components/BtnActionTable'
import RadioButton from '../../../../components/RadioButton'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { Popup } from '../../../../utilities/popup'
import { tableContacts } from '../../storage'
import { TableContact } from '../../types'
import useContact from './useContact'

function Edit({ data, index }: { data: TableContact; index: number }) {
    const open = useContact(index)
    function handleClick() {
        open({
            name: data.name,
            position: data.position,
            email: data.email,
            phone_number: data.phone_number.toString()
        })
    }

    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={handleClick}
        />
    )
}

function Row({
    control,
    index
}: {
    control: FormEvents<TableContact>
    index: number
}) {
    control.store.on('is_main', checked => {
        if (checked) {
            tableContacts.list.forEach((d, i) => {
                if (i !== index) {
                    d.setValue('is_main', false)
                }
            })
        }
    })
    const { props, getError } = control.useCheck('is_main')
    const data = control.store()
    const helper = getError()

    async function handleDelete() {
        if (data.id) {
            const result = await Popup.delete(
                'Eliminar registro',
                <>
                    Se removerá el contacto de la lista. ¿Estás seguro de
                    eliminar el contacto <b>{data.name}</b>?
                </>
            )
            if (result) {
                tableContacts.del(index)
            }
        } else {
            tableContacts.del(index)
        }
        if (data.is_main && tableContacts.getDataList().length > 0) {
            tableContacts.put(0, { is_main: true })
        }
    }

    return (
        <tr className={classNames({ 'bg-error': !!helper })}>
            <td>{data.name}</td>
            <td>
                <RadioButton {...props} />
            </td>
            <td>{data.position}</td>
            <td>{data.email}</td>
            <td>{data.phone_number}</td>
            <td>
                <div className="group-btn-options">
                    <Edit data={data} index={index} />
                    <BtnActionTable
                        title="Editar"
                        iconName="bs-trash"
                        onClick={handleDelete}
                    />
                </div>
            </td>
        </tr>
    )
}

export default function TableContacts() {
    return (
        <TableList control={tableContacts} scrollHeight={350}>
            {(_, control, e) => <Row key={e} control={control} index={e - 1} />}
        </TableList>
    )
}
