import Icon from '../../../../../components/Icon'
import { TableList } from '../../../../../sections/ListForm'
import useSubmitLogiticalCost from '../FormLogisticalCost/useSubmitLogiticalCost'
import useSubmitDutyISCIVA from '../FormDutyISCIVA/useSubmitDutyISCIVA'
import useTableNationalization from './useTableNationalization'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import BtnActionTable from '../../../../../components/BtnActionTable'
import {
    LOGISTICAL_CONCEPT__FREIGHT,
    LOGISTICAL_CONCEPT__IVA,
    LOGISTICAL_CONCEPT__RATE_C_IVA,
    LOGISTICAL_CONCEPT__RATE_S_IVA,
    LOGISTICAL_CONCEPT__SAFE,
    LOGISTICAL_CONCEPT__SETTING_DUTY,
    LOGISTICAL_CONCEPT__SETTING_IVA
} from '../../../../../config/constants'

export default function TableNationalization() {
    const {
        tableNationalizationControl,
        currentSymbol,
        cifDutyRateIscDolar,
        cifDutyRateIscLocal,
        iva_dolar,
        iva_local
    } = useTableNationalization()
    return (
        <>
            <TableList
                className="sticky"
                control={tableNationalizationControl}
                scrollHeight={350}
                filter={item =>
                    item.logistical_concept_id !== LOGISTICAL_CONCEPT__IVA &&
                    item.logistical_concept_id !==
                        LOGISTICAL_CONCEPT__SETTING_DUTY &&
                    item.logistical_concept_id !==
                        LOGISTICAL_CONCEPT__SETTING_IVA
                }
            >
                {(item, _, e) => (
                    <tr key={item.id}>
                        <td>{e}</td>
                        <td>
                            {item.has_files && (
                                <Icon
                                    name="description"
                                    className="text-info mr-2"
                                    size={20}
                                />
                            )}
                            {item.concept}
                        </td>
                        <td>
                            <div>{item.agent_name}</div>
                            <div>{item.document_number}</div>
                        </td>
                        <td>{item.type}</td>
                        <td>{item.cost_to_receipt}</td>
                        <td>
                            {item.symbol}{' '}
                            {buildNumberFormat(item.original_cost)}
                        </td>
                        <td>
                            {item.exchange_rate
                                ? item.exchange_rate.toFixed(4)
                                : '-'}
                        </td>
                        <td>
                            {currentSymbol} {buildNumberFormat(item.local_cost)}
                        </td>
                        <td>
                            <div className="group-btn-options">
                                <EditRateIVA
                                    logistical_cost_id={item.id}
                                    logistical_concept_id={
                                        item.logistical_concept_id
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
            <div className="flex justify-end mt-4 font-bold text-xs">
                <div className="bg-gray-200 py-2 px-4 col-span-2">
                    CIF + Arancel + Tasas + ISC
                </div>
                <div className="bg-gray-200 py-2 px-4">
                    $ {buildNumberFormat(cifDutyRateIscDolar)}
                </div>
                <div className="bg-gray-200 py-2 px-4">
                    {currentSymbol} {buildNumberFormat(cifDutyRateIscLocal)}
                </div>
            </div>
            <div className="flex justify-end mt-4 font-bold text-xs">
                <div className="bg-gray-200 py-2 px-4 col-span-3">
                    IVA (En base a CIF + Arancel + ISC + Tasa C/IVA){' '}
                </div>
                <div className="bg-gray-200 py-2 px-4">
                    $ {buildNumberFormat(iva_dolar)}
                </div>
                <div className="bg-gray-200 py-2 px-4">
                    {currentSymbol} {buildNumberFormat(iva_local)}
                    <EditIva
                        logistical_cost_id={
                            tableNationalizationControl
                                .getDataList()
                                .find(
                                    item =>
                                        item.logistical_concept_id ===
                                        LOGISTICAL_CONCEPT__IVA
                                )?.id
                        }
                    />
                </div>
            </div>
        </>
    )
}

function EditRateIVA({
    logistical_cost_id,
    logistical_concept_id
}: {
    logistical_cost_id: number
    logistical_concept_id: number
}) {
    const openLC = useSubmitLogiticalCost(
        logistical_cost_id,
        logistical_concept_id
    )

    const openT = useSubmitDutyISCIVA(logistical_cost_id, logistical_concept_id)

    function handleOpen() {
        if (
            logistical_concept_id === LOGISTICAL_CONCEPT__FREIGHT ||
            logistical_concept_id === LOGISTICAL_CONCEPT__SAFE ||
            logistical_concept_id === LOGISTICAL_CONCEPT__RATE_S_IVA ||
            logistical_concept_id === LOGISTICAL_CONCEPT__RATE_C_IVA
        ) {
            openLC()
        } else {
            openT()
        }
    }
    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={handleOpen}
        />
    )
}

function EditIva({ logistical_cost_id }: { logistical_cost_id: number }) {
    const open = useSubmitDutyISCIVA(logistical_cost_id, 1)
    return <Icon pointer name="bs-pencil" className="ml-4" onClick={open} />
}
