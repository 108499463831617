import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { storagePage } from '../../storage'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { BalanceHistory } from '../../types'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC',
        borderBottom: '1px solid #CCCCCC'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    table__td: {
        display: 'flex',
        flexDirection: 'row',
        padding: '6px',
        fontSize: fontSize,
        borderLeft: '1px solid #CCCCCC'
    },
    table__th: {
        backgroundColor: '#CCCCCC',
        padding: '4px',
        fontSize: fontSize,
        fontFamily: 'helvetica-bold'
    },
    textRight: {
        textAlign: 'right'
    },
    cellCount: {
        width: '30px'
    },
    cellProductCode: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100px'
    },
    cellProductDescription: {
        width: '160px'
    },
    cellCategory: {
        width: '80px'
    },
    cellQuantity: {
        width: '45px'
    },
    cellAverageCost: {
        width: '80px'
    },
    cellBalance: {
        width: '80px'
    }
})

export default function Body({ data }: { data: BalanceHistory[] }) {
    const sym = storagePage.currency.symbol

    return (
        <View style={styles.table}>
            <View style={styles.row}>
                <Text style={[styles.table__th, styles.cellCount]}>#</Text>
                <Text style={[styles.table__th, styles.cellProductCode]}>
                    Código
                </Text>
                <Text style={[styles.table__th, styles.cellProductDescription]}>
                    Descripción de producto
                </Text>
                <Text style={[styles.table__th, styles.cellCategory]}>
                    Categoría
                </Text>
                <Text style={[styles.table__th, styles.cellQuantity]}>
                    Cantidad
                </Text>
                <Text style={[styles.table__th, styles.cellAverageCost]}>
                    Costo promedio
                </Text>
                <Text style={[styles.table__th, styles.cellBalance]}>
                    Saldo
                </Text>
            </View>
            {data.map((d, i) => (
                <View key={i} style={styles.row}>
                    <Text style={[styles.table__td, styles.cellCount]}>
                        {i + 1}
                    </Text>
                    <View style={[styles.table__td, styles.cellProductCode]}>
                        <Text>{d.product_code.slice(0, 7)}</Text>
                        <Text>{d.product_code.slice(7, 14)}</Text>
                        <Text>{d.product_code.slice(14)}</Text>
                    </View>
                    <Text
                        style={[
                            styles.table__td,
                            styles.cellProductDescription
                        ]}
                    >
                        {d.product_description}
                    </Text>
                    <Text style={[styles.table__td, styles.cellCategory]}>
                        {d.category_name}
                    </Text>
                    <Text
                        style={[
                            styles.table__td,
                            styles.cellQuantity,
                            styles.textRight
                        ]}
                    >
                        {d.quantity || 0}
                    </Text>
                    <Text
                        style={[
                            styles.table__td,
                            styles.cellAverageCost,
                            styles.textRight
                        ]}
                    >
                        {sym}
                        {buildNumberFormat(d.average_unit_cost || 0)}
                    </Text>
                    <Text
                        style={[
                            styles.table__td,
                            styles.cellBalance,
                            styles.textRight
                        ]}
                    >
                        {sym}
                        {buildNumberFormat(
                            d.average_unit_cost * d.quantity || 0
                        )}
                    </Text>
                </View>
            ))}
        </View>
    )
}
