import DataTable, { TBody, THead } from '../../../../../components/DataTable'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../storage'
import useTableDistributionbyRC from './useTableDistributionbyRC'

export default function TableDistributionbyRC() {
    const { logical_declaration_receptions, currentSymbol } =
        useTableDistributionbyRC()
    const symbol = storagePage.currentCurrency.symbol

    return (
        <>
            <DataTable className="sticky" scrollHeight={350}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>RC</th>
                        <th>OC</th>
                        <th>Monto costeado actual</th>
                        <th>Monto a costear nuevo</th>
                        <th>Monto a costear nuevo con penalización</th>
                    </tr>
                </THead>
                <TBody>
                    {logical_declaration_receptions.map((item, i) => (
                        <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td>{item.reception.code}</td>
                            <td>
                                {item.reception.purchases
                                    .map(purchase => purchase.code)
                                    .join(', ')}
                            </td>
                            <td>
                                {symbol}{' '}
                                {buildNumberFormat(
                                    item.reception.logistical_cost
                                )}
                            </td>
                            <td>
                                {symbol}{' '}
                                {item.reception.costing_estimate
                                    ? buildNumberFormat(
                                          item.reception.costing_estimate
                                              .estimate_cost
                                      )
                                    : buildNumberFormat(
                                          item.logistical_cost -
                                              item.penalized_local_cost
                                      )}
                            </td>
                            <td>
                                {symbol}{' '}
                                {buildNumberFormat(
                                    item.reception.costing_estimate
                                        ? item.reception.logistical_cost
                                        : item.logistical_cost
                                )}
                            </td>
                        </tr>
                    ))}
                </TBody>
            </DataTable>
            <div className="grid grid-cols-6 mt-4 font-bold text-xs">
                <div className="col-end-6 bg-gray-200 py-2 px-4 col-span-2">
                    Monto Total a costear
                </div>
                <div className="col-end-7 bg-gray-200 py-2 px-4">
                    {currentSymbol}{' '}
                    {buildNumberFormat(
                        logical_declaration_receptions.reduce(function (a, b) {
                            return (
                                a +
                                (b.reception.costing_estimate
                                    ? b.reception.logistical_cost
                                    : b.logistical_cost)
                            )
                        }, 0)
                    )}
                </div>
            </div>
        </>
    )
}
