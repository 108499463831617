import { useEffect } from 'react'
import ControlTabs from '../../../../utilities/ControlTabs'
import { LicenseInfo } from '../../types'
import Info from './Info'
import Products from './Products'

const controlTabs = new ControlTabs()
controlTabs.setSection('Información general', Info)
controlTabs.setSection('Productos', Products)

export default function InfoLicense(props: { data: LicenseInfo }) {
    const value = controlTabs.useStep()
    useEffect(() => {
        return () => {
            controlTabs.value = 'step1'
        }
    }, [])
    return (
        <>
            <controlTabs.SectionTabs
                value={value}
                type="tab"
                mode="simple"
                className="mb-8"
                data={props.data}
            />
        </>
    )
}
