import { ItemRoute } from './types'
import Kardex from '../../pages/Kardex'
import InventoryReport from '../../pages/InventoryReport'
import InventoryTransitReport from '../../pages/InventoryTransitReport'

const Reports: ItemRoute[] = [
    {
        cu: 'CU164',
        label: 'Kardex',
        title: 'Kardex',
        path: '/kardex',
        Component: Kardex
    },
    {
        cu: 'CU106',
        title: 'Reporte de Inventario',
        label: 'Reporte de Inventario',
        path: '/inventory_report',
        Component: InventoryReport
    },
    {
        cu: 'CU106',
        title: 'Reporte de Inventario en tránsito',
        label: 'Reporte de Inventario en tránsito',
        path: '/inventory_report_transit',
        Component: InventoryTransitReport
    }
]

export default Reports
