import 'moment/locale/es'
import Logo from '../../../../assets/img/logo.png'
import { Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import { storagePage } from '../../storage'
import moment from 'moment'
import Company from '../../../../models/Company'
import { GetInputPart } from '../../types'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '14px'
    },
    companyWatemark: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '7px'
    },
    title: {
        fontSize: '16px',
        fontFamily: 'helvetica-bold',
        marginTop: '25px',
        marginBottom: '20px'
    },
    companyWatemark__logo: {
        height: '38px',
        width: '70px'
    },
    companyWatemark__info: {
        maxWidth: '30%'
    },
    fontBold: {
        fontFamily: 'helvetica-bold'
    },
    list_container: {
        display: 'flex',
        borderBottom: '2px dotted rgb(128, 126, 126)'
    },
    list: {
        display: 'flex',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingBottom: '10px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontSize: fontSize
    },
    listItemShort: {
        width: '25%'
    },
    listItemMedium: {
        width: '50%'
    },
    listItemLong: {
        width: '100%'
    },
    list__element__name: {
        fontFamily: 'helvetica-bold',
        fontSize: fontSize
    },
    list__element__value: {
        fontSize: fontSize
    }
})

export default function Header({
    company,
    data
}: {
    company: Company
    data: GetInputPart
}) {
    moment.locale('es')
    const { quantity_products, input_part_label } = data
    const { warehouse } = data.company_warehouse
    const { requesting_user, description, decision_date, is_annuled } =
        data.document
    const { stowage, order_sub_stowage } = data.minimal_stowage

    return (
        <>
            <View style={styles.header}>
                <View style={styles.companyWatemark}>
                    <Image style={styles.companyWatemark__logo} src={Logo} />
                    <View style={styles.companyWatemark__info}>
                        <Text style={styles.fontBold}>{company.name}</Text>
                        {company.fiscal_document === '' && (
                            <Text>
                                {company.type_fiscal_document}:{' '}
                                {company.fiscal_document}
                            </Text>
                        )}
                        <Text>{company.address}</Text>
                    </View>
                </View>
                <View style={styles.title}>
                    <Text>PARTE DE ENTRADA {data.document.code}</Text>
                </View>
                <View style={styles.list_container}>
                    <View style={styles.list}>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Usuario
                            </Text>
                            <Text style={styles.list__element__value}>
                                {requesting_user.name}{' '}
                                {requesting_user.lastname}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Almacén
                            </Text>
                            <Text style={styles.list__element__value}>
                                {warehouse.name}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Bodega
                            </Text>
                            <Text style={styles.list__element__value}>
                                {stowage.name}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Sub Bodega
                            </Text>
                            <Text style={styles.list__element__value}>
                                {order_sub_stowage
                                    ? order_sub_stowage.order.number
                                    : '-'}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.list}>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Cant. ingresada
                            </Text>
                            <Text style={styles.list__element__value}>
                                {quantity_products} {'unidades'}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Fecha de aprobación
                            </Text>
                            <Text style={styles.list__element__value}>
                                {moment(decision_date)
                                    .tz(storagePage.timezone)
                                    .format('DD/MM/YYYY')}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Estado
                            </Text>
                            <Text style={styles.list__element__value}>
                                {is_annuled ? 'Anulado' : 'Emitido'}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Etiqueta
                            </Text>
                            <Text style={styles.list__element__value}>
                                {input_part_label.name}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.list}>
                        <View style={styles.listItemLong}>
                            <Text style={styles.list__element__name}>
                                Motivo
                            </Text>
                            <Text style={styles.list__element__value}>
                                {description || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )
}
