import classNames from 'classnames'
import { PropsBadge } from './types'
import './badge.css'
import Icon from '../Icon'

export default function Badge({
    color = 'info',
    children,
    className = '',
    size = 'small',
    onClose = null,
    onClick = () => null
}: PropsBadge) {
    return (
        <div
            onClick={onClick}
            className={classNames(
                `badge-comp badge badge-${color} text-xs font-univers badge-comp--${size}`,
                className,
                {
                    'badge-close': !!onClose
                }
            )}
        >
            {children}
            {onClose && (
                <Icon
                    className="ml-2"
                    name="close"
                    size={9}
                    pointer
                    onClick={() => onClose()}
                />
            )}
        </div>
    )
}
