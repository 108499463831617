import { SVGProps } from 'react'

const Send = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.25 6.333.258.5 17.75 8 .258 15.5.25 9.667 12.75 8 .25 6.333Zm1.675-3.308 6.258 2.683-6.266-.833.008-1.85Zm6.25 7.267-6.258 2.683v-1.85l6.258-.833Z"
        />
    </svg>
)

export default Send
