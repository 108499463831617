import { ChangeEvent, useState } from 'react'

export default function useHelper<T extends Record<keyof T, string>>() {
    const [helper, setHelper] = useState<Partial<Record<keyof T, string>>>({})

    function onChange(ev: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const name = ev.target.name as keyof T
        if (helper[name]) {
            setHelper({
                ...helper,
                [name]: ''
            })
        }
    }

    function clear() {
        setHelper({})
    }

    return {
        helper,
        onChange,
        clear,
        setHelper
    }
}
