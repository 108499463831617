import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Progress from '../../../components/Progress'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList, storagePage } from '../storage'
import { formManagementRQ } from '../../RequisitionManagement/storage'
import {
    MinimalStowageItem,
    RequisitionItem,
    RequisitionView as RequisitionViewType
} from '../types'
import Pdf from './Pdf'
import { viewPrint } from '../../../sections/ModalPrint'
import buildName from '../../../utilities/buildName'
import BtnActionTable from '../../../components/BtnActionTable'
import ModalRequisition from '../../../modals/ModalRequisition'

export default function Table() {
    const math = useRule('CU220')
    return (
        <List control={controlList}>
            {(data, e) => (
                <tr key={data.id}>
                    <td>{e}</td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <b
                            {...(math && {
                                className: 'underline cursor-pointer',
                                onClick: () =>
                                    view(
                                        `/v5.6/crm/requisitions/${data.id}`,
                                        () => ({
                                            title: `DETALLE DE REQUISICIÓN`,
                                            Component: ModalRequisition
                                        }),
                                        'lg'
                                    )
                            })}
                        >
                            {data.code}
                        </b>
                    </td>
                    <td
                        style={{ whiteSpace: 'normal' }}
                        className={`${
                            !data.minimal_stowage.stowage.is_enabled &&
                            'line-through'
                        }`}
                    >
                        {' '}
                        {getSubStowages(data.minimal_stowage)}
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <div className="flex items-center">
                            <img
                                src={
                                    data.provider_company.provider.country
                                        .image_url
                                }
                                className="mr-2 w-8"
                            />
                            <p
                                className={`${
                                    !data.provider_company.provider
                                        .is_enabled && 'line-through'
                                }`}
                            >
                                {data.provider_company.provider.commercial_name}
                            </p>
                        </div>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <p
                            className={
                                !data.document.requesting_user.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {buildName(
                                data.document.requesting_user.name,
                                data.document.requesting_user.lastname
                            )}
                        </p>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <span
                            className={
                                data.client && !data.client.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {data.client ? data.client.name : '-'}
                        </span>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {data.document.decision_date
                            ? moment(data.document.decision_date)
                                  .tz(storagePage.timezone)
                                  .format('DD/MM/YYYY')
                            : '-'}
                    </td>
                    <td>
                        <Progress number={data.percentage}></Progress>
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU216">
                                <Management requisition={data} />
                            </Rules>
                            <Rules cu="CU221">
                                <LoadPDF requisition={data}></LoadPDF>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}

function getSubStowages(minimal_stowage: MinimalStowageItem) {
    const name = minimal_stowage.stowage.name

    if (minimal_stowage.order_sub_stowage) {
        return (
            <>
                <p
                    className={
                        !minimal_stowage.order_sub_stowage.is_enabled
                            ? 'line-through'
                            : ''
                    }
                >
                    {minimal_stowage.order_sub_stowage.order.number +
                        ' - ' +
                        minimal_stowage.order_sub_stowage.order.client?.name}
                </p>
            </>
        )
    } else if (minimal_stowage.gp_sub_stowage) {
        return (
            <>
                <p>{name}</p>
                <p
                    className={
                        !minimal_stowage.gp_sub_stowage.is_enabled
                            ? 'line-through'
                            : ''
                    }
                >
                    {minimal_stowage.gp_sub_stowage.purchase.code}
                </p>
            </>
        )
    } else {
        return <p>{name}</p>
    }
}

function ViewerPdf({ data }: { data: RequisitionViewType }) {
    return <Pdf data={data} />
}

function LoadPDF({ requisition }: { requisition: RequisitionItem }) {
    const math = useRule('CU221')

    function handleClick() {
        viewPrint(`/v5.6/crm/requisitions/${requisition.id}`, () => ({
            Component: ViewerPdf
        }))
    }

    if (math) {
        return (
            <BtnActionTable
                title="Imprimir"
                iconName="bs-printer"
                onClick={handleClick}
            />
        )
    }
}

function Management({ requisition }: { requisition: RequisitionItem }) {
    const { push } = useHistory()

    function handleClick() {
        formManagementRQ.setValue('requisition', {
            value: requisition.id.toString(),
            label: requisition.code
        })
        push(`/requisitions/management_requisition/management`)
    }

    return (
        <BtnActionTable
            title="Gestionar"
            iconName="bs-wrench"
            onClick={handleClick}
        />
    )
}
