import BtnActionTable from '../../../../../components/BtnActionTable'
import { ocList } from '../../../storage'
import OpenDialog from '../../../../../sections/OpenDialog'

export default function ClosePurchase({
    purchase_code,
    purchase_id,
    disabled
}: {
    purchase_code: string
    purchase_id: number
    disabled: boolean
}) {
    return (
        <>
            <OpenDialog
                title="Desconfirmar OC"
                icon="bs-hand-thumbs-down"
                color="error"
                control={ocList}
                element={click => (
                    <BtnActionTable
                        title="Desconfirmar"
                        iconName="bs-hand-thumbs-down"
                        onClick={click}
                        disabled={disabled}
                    />
                )}
                accept="Sí, desconfirmar"
                path="/v5.6/apis/purchases/disconfirm"
                body={{
                    purchase_id: purchase_id
                }}
                method="put"
            >
                La orden de compra <b>{purchase_code}</b> cambiará de estado a{' '}
                <b>“Pendiente de envío”</b>. ¿Está seguro de cambiar de estado?
            </OpenDialog>
        </>
    )
}
