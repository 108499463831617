import Typography from '../../../../../components/Typography'
import { ViewProductProps } from '../types'
import TableCompanies from './TableCompanies'
import TableFiscalCode from './TableFiscalCode'
import TableIva from './TableIva'
import TableLicenses from './TableLicenses'
import TablePermissions from './TablePermissions'

export default function Availability({ data }: ViewProductProps) {
    const { product, licenses } = data
    return (
        <>
            <Typography className="mb-2" type="header-3">
                Compañías
            </Typography>
            <TableCompanies productCountries={product.product_countries} />
            <Typography className="mb-2" type="header-3">
                Código fiscal
            </Typography>
            <TableFiscalCode productCountries={product.product_countries} />
            <Typography className="mb-2" type="header-3">
                Permisos requeridos por país
            </Typography>
            <TablePermissions productCountries={product.product_countries} />
            <Typography className="mb-2" type="header-3">
                Impuesto de valor agregado
            </Typography>
            <TableIva productCountries={product.product_countries} />
            <Typography className="mb-2" type="header-3">
                Licencias Adquiridas
            </Typography>
            <TableLicenses licenses={licenses} />
        </>
    )
}
