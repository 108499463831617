import { FormControl } from '../../../../../components/FormUtilities'
import Upload from '../../../../../components/Upload'
import { formCostGeneral } from '../storageStep3'

export default function Fields4() {
    const { props } = formCostGeneral.useUploads('files')
    return (
        <FormControl label="Archivos adjuntos " col="span-6" row="span-3">
            <Upload {...props} />
        </FormControl>
    )
}
