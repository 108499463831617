import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import SelectTouch from '../../../../components/SelectTouch'
import ProductCategory from '../../../../models/ProductCategory'
import Subcategory from '../../../../models/Subcategory'
import { formInformation } from '../../storage'

function Category() {
    const { props, data } = formInformation.useSelect<ProductCategory>(
        'category',
        '/v5.6/apis/categories'
    )
    return (
        <FormControl label="Categoría" required>
            <SelectTouch {...props} placeholder="Seleccionar una categoría">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function SubcategorySelect() {
    const { props, data } = formInformation.useSelect<Subcategory>(
        'subcategory',
        () => '/v5.6/apis/subcategories'
    )
    return (
        <FormControl label="Subcategoría" required>
            <SelectTouch {...props} placeholder="Seleccionar una subcategoría">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function CodeProduct() {
    const { props } = formInformation.useInput('code')
    return (
        <FormControl label="Código Producto" col="span-6">
            <InputField
                {...props}
                placeholder="CodigoSubcategoria-CódigoMarcaCodigoFabricante"
                readOnly
            />
        </FormControl>
    )
}

export default function Fields4() {
    return (
        <>
            <Category />
            <SubcategorySelect />
            <CodeProduct />
        </>
    )
}
