import { FormControl } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import { formBrand } from '../storage'

export default function Form() {
    const { getError, props } = formBrand.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe una marca con el mismo nombre'
    )
    return (
        <FormControl label="Nombre de marca" required>
            <InputField
                placeholder="Ingresar nombre"
                autoFocus
                {...props}
                helper={helper || props.helper}
            />
        </FormControl>
    )
}
