import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import { tableLicense } from './storage'

export default function useInitial() {
    const [pageState, setPageState] = useState(StateCase.LOADING)
    const { license_id } = useParams<{ license_id: string }>()

    useEffect(() => {
        tableLicense.filter.changeParams('sort', 'version')
        tableLicense.filter.changeParams('order', 'desc')

        tableLicense
            .done(`/v5.6/apis/licenses/${license_id}/license_versions`)
            .then(response => {
                if (!response) {
                    setPageState(StateCase.ERROR)
                    return
                }
                setPageState(StateCase.SUCCESS)
            })

        return () => {
            tableLicense.filter.restart()
        }
    }, [])

    return pageState
}
