import FileModel from '../../../../../models/FileModel'
import modal from '../../../../../sections/modal'
import { parseDriveFile, parseFile } from '../../../../../utilities/fileTools'
import { get } from '../../../../../utilities/http'
import {
    formLogisticalCost,
    reqLogisticalCost1,
    reqLogisticalCost2
} from '../storageStep2'
import Cost from '../cost'
import FormLogisticalCost from '.'

export default function useSubmitLogiticalCost(
    logistical_cost_id: number,
    logistical_concept_id: number
) {
    let currentFiles: FileModel[] = []

    const { submit, validation, observer } = formLogisticalCost.useSubmit(
        `/v5.6/apis/logistical_costs/general/${logistical_cost_id}`,
        {
            done: async () => {
                await reqLogisticalCost1.load()
                reqLogisticalCost2.load()
            },
            method: 'put'
        }
    )

    validation(v => {
        v.original_cost
            .isMinor(0.0, 'El valor mínimo es 0')
            .isEmpty('Se requiere ingresar el valor de monto')
        v.exchange_to_local.isEmpty(
            'Se requiere ingresar el valor del tipo de cambio'
        )
    })

    let currentCurrencyId = null

    observer<Cost.PutBodyModalLC>(f => ({
        agent_id: f.agent && parseInt(f.agent.value),
        document_number: f.document_number,
        original_cost: Number(f.original_cost),
        exchange_to_local: Number(f.exchange_to_local),
        currency_id: currentCurrencyId,
        reception_ids: f.rcs.map(option => parseInt(option.value)),
        new_files: f.files
            .filter(file => !file.id)
            .map(file => parseFile(file)),
        files_to_delete: currentFiles
            .filter(cf => !f.files.find(file => file.id === cf.id))
            .map(cf => cf.id),
        applicable_product_ids: f.productIds
    }))

    return function openModal() {
        modal(
            FormLogisticalCost,
            async () => {
                const { data } = await get<Cost.ResponseLCGeneral>(
                    `/v5.6/apis/logistical_costs/general/${logistical_cost_id}`
                )

                const {
                    logistical_cost_receptions,
                    files,
                    original_cost,
                    exchange_to_local,
                    currency_id,
                    document_number,
                    agent,
                    applicable_product_ids
                } = data.result

                currentFiles = files
                currentCurrencyId = currency_id

                formLogisticalCost.setValue(
                    'document_number',
                    document_number && document_number.toString()
                )
                formLogisticalCost.setValue(
                    'original_cost',
                    original_cost.toString()
                )
                formLogisticalCost.setValue(
                    'exchange_to_local',
                    exchange_to_local.toString()
                )
                formLogisticalCost.setValue(
                    'total_cost',
                    (original_cost * exchange_to_local).toFixed(2)
                )
                formLogisticalCost.setValue(
                    'rcs',
                    logistical_cost_receptions.map(lcr => ({
                        value: lcr.reception_id.toString(),
                        label: lcr.version.toString()
                    }))
                )
                formLogisticalCost.setValue(
                    'files',
                    files.map(file => parseDriveFile(file))
                )
                formLogisticalCost.setValue('agent', null)
                formLogisticalCost.setValue(
                    'productIds',
                    applicable_product_ids
                )
                if (agent) {
                    formLogisticalCost.setValue('agent', {
                        value: agent.id.toString(),
                        label: agent.name
                    })
                }

                return {
                    title: getTitle(logistical_concept_id),
                    onSubmit: submit
                }
            },
            'lg'
        )
    }
}

function getTitle(logistical_concept_id: number) {
    switch (logistical_concept_id) {
        case 2:
            return 'Flete (General)'
        case 4:
            return 'Seguro (General)'
        case 6:
            return 'Tasa S/IVA (General)'
        case 7:
            return 'Tasa C/IVA (General)'
        case 9:
            return 'Otros cargos CIF'
    }
}
