import BtnActionTable from '../../../../../../components/BtnActionTable'
import { TYPE_LOGISTICAL_COST_SOURCE__ADITIONAL_SETTING } from '../../../../../../config/constants'
import Delete from '../../../../../../sections/Delete'
import ModalGeneralCost from '../ModalGeneralCost'
import { TableActionsProps } from './types'

export default function TableActions({
    logisticalCost,
    onReload,
    rcs
}: TableActionsProps) {
    return (
        <td>
            <div className="group-btn-options">
                <ModalGeneralCost
                    element={click => (
                        <BtnActionTable
                            onClick={click}
                            title="Editar"
                            iconName="bs-pencil"
                            disabled={
                                logisticalCost.logistical_concept_id ===
                                TYPE_LOGISTICAL_COST_SOURCE__ADITIONAL_SETTING
                            }
                        />
                    )}
                    onDone={onReload}
                    edition={logisticalCost}
                    rcs={rcs}
                />
                <Delete
                    title="Remover costo de ajuste"
                    path={`/v5.6/apis/logistical_costs/${logisticalCost.id}`}
                    control={() => {
                        onReload()
                    }}
                    disabled={
                        logisticalCost.logistical_concept_id ===
                        TYPE_LOGISTICAL_COST_SOURCE__ADITIONAL_SETTING
                    }
                >
                    Al remover el costo, se recalculará el monto a costear.
                    ¿Está seguro de remover el costo{' '}
                    <b>{logisticalCost.logistical_concept.name}</b> del proceso
                    de costeo?
                </Delete>
            </div>
        </td>
    )
}
