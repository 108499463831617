import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import useSubmit from './../useSubmit'
import useCancel from '../../../hooks/useCancel'
import { formInputPart, storagePage } from './../storage'

export default function Header() {
    const submit = useSubmit(storagePage.inputPart?.id || undefined)
    const { onClick } = useCancel(formInputPart, '/movements/input_parts')
    return (
        <PageHeader subtitle="(*) Complete todos los campos para agregar">
            <Button color="ghost" onClick={onClick}>
                Cancelar
            </Button>
            <Button className="ml-2" onClick={() => submit()}>
                Guardar
            </Button>
        </PageHeader>
    )
}
