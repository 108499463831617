import 'moment/locale/es'
import Logo from '../../../../assets/img/logo.png'
import { Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import { storagePage } from '../../storage'
import moment from 'moment'
import Company from '../../../../models/Company'
import { GetReception } from '../../type'

import buildNumberFormat from '../../../../utilities/buildNumberFormat'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '14px'
    },
    companyWatemark: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '7px'
    },
    title: {
        fontSize: '16px',
        fontFamily: 'helvetica-bold',
        marginTop: '25px',
        marginBottom: '20px'
    },
    companyWatemark__logo: {
        height: '38px',
        width: '70px'
    },
    companyWatemark__info: {
        maxWidth: '30%'
    },
    fontBold: {
        fontFamily: 'helvetica-bold'
    },
    list_container: {
        display: 'flex',
        borderBottom: '2px dotted rgb(128, 126, 126)'
    },
    list: {
        display: 'flex',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingBottom: '10px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontSize: fontSize
    },
    listItemShort: {
        width: '25%'
    },
    listItemMedium: {
        width: '50%'
    },
    listItemLong: {
        width: '100%'
    },
    list__element__name: {
        fontFamily: 'helvetica-bold',
        fontSize: fontSize
    },
    list__element__value: {
        fontSize: fontSize
    }
})

export default function Header({
    company,
    data
}: {
    company: Company
    data: GetReception
}) {
    moment.locale('es')

    const { warehouse } = data.company_warehouse
    const {
        number_shipment,
        reception_date,
        type_reception,
        provider,
        number_invoice_shipping
    } = data
    const { user, description } = data.document
    const { purchase, exchange_rate } = data

    let amountFOB = 0
    for (let index = 0; index < data.reception_products.length; index++) {
        const reception_product = data.reception_products[index]
        amountFOB = amountFOB + reception_product.purchase_local_cost
    }

    return (
        <>
            <View style={styles.header}>
                <View style={styles.companyWatemark}>
                    <Image style={styles.companyWatemark__logo} src={Logo} />
                    <View style={styles.companyWatemark__info}>
                        <Text style={styles.fontBold}>{company.name}</Text>
                        {company.fiscal_document === '' && (
                            <Text>
                                {company.type_fiscal_document}:{' '}
                                {company.fiscal_document}
                            </Text>
                        )}
                        <Text>{company.address}</Text>
                    </View>
                </View>
                <View style={styles.title}>
                    <Text>RECEPCIÓN DE COMPRA {data.code}</Text>
                </View>
                <View style={styles.list_container}>
                    <View style={styles.list}>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Usuario
                            </Text>
                            <Text style={styles.list__element__value}>
                                {user.name} {user.lastname}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Moneda
                            </Text>
                            <Text style={styles.list__element__value}>
                                {purchase.currency.code}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Proveedor
                            </Text>
                            <Text style={styles.list__element__value}>
                                {provider.business_name}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Factura de Importación
                            </Text>
                            <Text style={styles.list__element__value}>
                                {number_invoice_shipping || '-'}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.list}>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Fecha de recepción
                            </Text>
                            <Text style={styles.list__element__value}>
                                {moment(reception_date)
                                    .tz(storagePage.timezone)
                                    .format('DD/MM/YYYY')}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Tipo de cambio
                            </Text>
                            <Text style={styles.list__element__value}>
                                {exchange_rate.toFixed(4)}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Embarque
                            </Text>
                            <Text style={styles.list__element__value}>
                                {number_shipment || '-'}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Tipo de RC
                            </Text>
                            <Text style={styles.list__element__value}>
                                {type_reception.name}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.list}>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Almacén
                            </Text>
                            <Text style={styles.list__element__value}>
                                {warehouse.name}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Monto FOB
                            </Text>
                            <Text style={styles.list__element__value}>
                                {buildNumberFormat(amountFOB / exchange_rate)}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Orden de compra
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.purchase.code}
                            </Text>
                        </View>
                        <View style={styles.listItemShort}>
                            <Text style={styles.list__element__name}>
                                Tipo de OC
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.purchase.type_purchase.name}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.list}>
                        <View style={styles.listItemLong}>
                            <Text style={styles.list__element__name}>
                                Comentario
                            </Text>
                            <Text style={styles.list__element__value}>
                                {description || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )
}
