import DataTable, { TBody, THead } from '../../../components/DataTable'
import Typography from '../../../components/Typography'
import Ul from '../../../components/Ul'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { SubcategoryInfo } from '../types'

export default function Info({ data }: { data: SubcategoryInfo }) {
    return (
        <>
            <Typography type="header-3" className="mb-2">
                Información general
            </Typography>
            <Ul>
                <li>
                    Subcategoría: <b>{data.name}</b>
                </li>
                <li>
                    Categoría: <b>{data.category.name}</b>
                </li>
            </Ul>
            <Typography type="header-3" className="mt-5 mb-2">
                Factores de precio y porcentaje de descuento
            </Typography>
            <DataTable>
                <THead>
                    <tr>
                        <th>Compañía</th>
                        <th>Factor subcategoría</th>
                        <th>Descuento %</th>
                    </tr>
                </THead>
                <TBody>
                    {data.subcategory_values.map(sv => (
                        <tr key={sv.id}>
                            <td>
                                <div className="flex items-center">
                                    <img
                                        src={sv.company.country.image_url}
                                        className="w-8 mr-2"
                                    />
                                    <p>{sv.company.name}</p>
                                </div>
                            </td>
                            <td>{buildNumberFormat(sv.factor)}</td>
                            <td>{buildNumberFormat(sv.discount)}</td>
                        </tr>
                    ))}
                </TBody>
            </DataTable>
        </>
    )
}
