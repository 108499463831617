import classnames from 'classnames'
import Icon from '../Icon'
import { PropsAlert } from './types'

export default function Alert({
    type = null,
    children,
    className
}: PropsAlert) {
    return (
        <div
            className={classnames(
                'alert',
                {
                    [`alert-${type}`]: !!type
                },
                className
            )}
        >
            <div className="flex items-center px-2">
                {(type === 'info' || type === null) && (
                    <Icon
                        name="comments"
                        size={24}
                        className="min-w-min mr-5 text-accent"
                    />
                )}
                {type === 'warning' && (
                    <Icon name="alert" size={24} className="min-w-min mr-5" />
                )}
                {type === 'error' && (
                    <Icon name="times" size={24} className="min-w-min mr-5" />
                )}
                <label className="text-xs font-univers-light">{children}</label>
            </div>
        </div>
    )
}
