export function getEnumeration(size: number, offset: number): number[] {
    const limit = 4
    const items: Array<number> = []
    let cursor = offset <= 2 ? 1 : offset - 1
    cursor = offset > size - limit ? size - limit : cursor
    cursor = size <= limit ? 1 : cursor

    if (offset >= 3) {
        items.push(1)
    }
    if (offset > 3) {
        items.push(-2)
    }

    for (let i = cursor; i < size + cursor; i++) {
        if (i >= limit + cursor) break
        items.push(i)
    }

    const lastValue = items[items.length - 1]
    if (size > limit && lastValue < size) {
        lastValue < size - 1 && items.push(-1)
        items.push(size)
    }

    return items
}
