import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import Datepicker from '../../../../components/Datepicker'
import { formGeneral } from './storageStep1'
import SelectTouch from '../../../../components/SelectTouch'
import moment from 'moment'
import useCompany from './useCompany'
import { storagePage } from '../../storage'

function Company() {
    const { props, companies, handleChange } = useCompany()

    return (
        <FormControl label="Compañía" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar compañía"
                clear={false}
            >
                <IterateOptions
                    data={companies.map(company => ({
                        ...company,
                        image_url: company.country.image_url
                    }))}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}

function DraftName() {
    const { props, getError } = formGeneral.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un costeo con el mismo nombre'
    )

    return (
        <FormControl label="Nombre de borrador" required>
            <InputField
                {...props}
                helper={helper || props.helper}
                placeholder="Ingresar nombre de borrador"
            />
        </FormControl>
    )
}

function DeclarationNumber() {
    const { props } = formGeneral.useInput('declaration_number')

    return (
        <FormControl label="N° de declaración">
            <InputField
                {...props}
                placeholder="Ingresar N° de declaración"
                disabled={storagePage.type === 'national'}
            />
        </FormControl>
    )
}

function DeclarationDate() {
    const { props } = formGeneral.useDate('declaration_date')

    return (
        <FormControl label="Fecha de declaración">
            <Datepicker
                maxDate={moment().toISOString(true)}
                {...props}
                placeholder="Seleccionar fecha"
            />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <Company />
            <DraftName />
            <DeclarationNumber />
            <DeclarationDate />
        </>
    )
}
