import { useContext } from 'react'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import StorageContext from '../../../contexts/storage-context'
import TypeProvider from '../../../models/TypeProvider'
import { controlList } from '../storage'

function StateCompany() {
    const { props } = controlList.useFilter<TypeProvider>('origin_company_id')
    const { companies } = useContext(StorageContext)
    const data = companies.map(company => ({
        id: company.id,
        name: company.name,
        image_url: company.country.image_url
    }))
    return (
        <Dropdown {...props} label="Compañía" showLabel={false}>
            <IterateOptions data={data} label="name" image="image_url" />
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Búsqueda de usuarios"
            size="auto"
            fullWidth={true}
        />
    )
}

export default function FilterUsers() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <StateCompany />
                </FlexLeft>
                <FlexRight></FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
