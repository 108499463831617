function Dollar(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 18"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M6.39 7.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V0h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1H.91c.12 2.19 1.76 3.42 3.68 3.83V18h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
        </svg>
    )
}

export default Dollar
