import { controlList, formCategory } from './storage'
import Form from './View/Form'

export default function useSubmit(categoryId?: number) {
    const { submit, validation } = formCategory.useSubmit(
        categoryId
            ? `/v5.6/apis/categories/${categoryId}`
            : '/v5.6/apis/categories',
        {
            method: categoryId ? 'put' : 'post',
            done: controlList
        }
    )

    validation(v => {
        v.name.minLength(2, 'El número de caracteres válidos es 2 - 180')
        v.name.isEmpty('Se requiere ingresar el nombre')
    })

    return formCategory.useModal(Form, {
        title: categoryId ? 'Editar Categoría' : 'Nueva Categoría',
        onSubmit: submit,
        size: 'sm',
        isCentered: true
    })
}
