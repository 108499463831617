import { useHistory } from 'react-router'
import Card from './Card'
import Rules from '../../../components/Rules'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { controlList } from '../storage'
import ActionActivateDeactivate from './ActionActivateDeactivate'
import BtnActionTable from '../../../components/BtnActionTable'

export default function Cards() {
    const { push } = useHistory()

    return (
        <List control={controlList} type="cards">
            {(warehouse, i) => (
                <Card
                    image={warehouse.image_url}
                    key={i}
                    title={warehouse.name}
                    code={warehouse.code}
                    image2={warehouse.country.image_url}
                    warehouse_id={warehouse.id}
                    is_enabled={warehouse.is_enabled}
                >
                    <div className="flex items-center flex-wrap justify-end mt-4 gap-1">
                        <Rules cu="CU096">
                            <BtnActionTable
                                title="Editar"
                                iconName="bs-pencil"
                                onClick={() =>
                                    push(
                                        `/configuration/warehouses/${warehouse.id}/edition`
                                    )
                                }
                            />
                        </Rules>
                        <Rules cu="CU123">
                            <Delete
                                path={`/v5.6/apis/warehouses/${warehouse.id}`}
                                control={controlList}
                            >
                                <p>Esta acción es irreversible</p>
                                <p>
                                    ¿Estás seguro de eliminar el almacén{' '}
                                    <b className="text-primary">
                                        {warehouse.name}
                                    </b>
                                    ?
                                </p>
                            </Delete>
                        </Rules>
                        <ActionActivateDeactivate warehouse={warehouse} />
                    </div>
                </Card>
            )}
        </List>
    )
}
