import { Option } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import Country from '../../../../../models/Country'
import { formDispatch, reqAgent, reqWarehouse } from '../../../storage'

export default function useCountry() {
    const { props } = formDispatch.useSelect('country')
    const { data, props: propsSearch } = useGet<Country[]>(
        '/v5.6/apis/countries?limit=10&sort=name',
        []
    )

    function handleChange(option: Option) {
        props.onChange(option)

        if (option.value === '0') {
            formDispatch.setProps('type_dispatch', { disabled: true }, false)
            formDispatch.setProps('place_dispatch', { disabled: true }, false)
            formDispatch.setProps('address_select', { disabled: true }, false)
        } else {
            formDispatch.setProps('type_dispatch', { disabled: false }, false)
            reqWarehouse.setParam('country_id', option.value)
            reqAgent.setQuery('country_address_id', option.value)
            if (formDispatch.store().type_dispatch?.value === '2') {
                reqWarehouse.load()
            }
        }
        formDispatch.setProps('place_dispatch_text', { disabled: true }, false)
        formDispatch.setValue('type_dispatch', null)
        formDispatch.setValue('place_dispatch', null)
        formDispatch.setValue('place_dispatch_text', '')
        formDispatch.setValue('address_select', null)
        formDispatch.setProps('address', { disabled: true }, false)
        formDispatch.setValue('address', '')
    }

    return {
        countries: data.result,
        props,
        propsSearch,
        handleChange
    }
}
