import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import useCancel from '../../hooks/useCancel'
import { get } from '../../utilities/http'
import { data } from './storage'
import { CompanyDeparmentUserData, UserData } from './types'
import getStores from './utilities/getStores'

async function load(user_id: string) {
    const [res1, res2] = await Promise.all([
        get<CompanyDeparmentUserData[]>(
            `/v5.6/apis/users/${user_id}/potential_roles_and_companies`
        ),
        get<UserData>(`/v5.6/apis/users/${user_id}/roles_by_company`)
    ])
    if (res1.error || res2.error) return StateCase.ERROR

    data.companyDepartmentUsers = res1.data.result
    data.user = res2.data.result

    return StateCase.SUCCESS
}

export default function useInitial(user_id: string) {
    const { onClick, init } = useCancel(getStores(), '/administration/users')
    const [state, setState] = useState(StateCase.LOADING)
    useEffect(() => {
        load(user_id).then(res => {
            setState(res)
            init()
        })

        return () => {
            data.companyDepartmentUsers = []
            data.controlLists = {}
            data.user = null
        }
    }, [])

    return {
        state,
        cancel: onClick
    }
}
