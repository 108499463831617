function AssignmentChecked(props) {
    return (
        <svg
            width={16}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.35 1.833h3.483c.917 0 1.667.75 1.667 1.667v11.667c0 .916-.75 1.666-1.667 1.666H2.167c-.117 0-.225-.008-.334-.025a1.673 1.673 0 0 1-1.2-.991 1.71 1.71 0 0 1-.133-.65V3.5c0-.233.05-.45.133-.642.084-.2.209-.383.359-.533.225-.225.516-.392.841-.458a1.46 1.46 0 0 1 .334-.034H5.65A2.509 2.509 0 0 1 8 .167c1.083 0 2 .7 2.35 1.666Zm1.475 3.817L13 6.833 6.333 13.5 3 10.167l1.175-1.175 2.158 2.15 5.492-5.492ZM8 1.625a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625A.63.63 0 0 1 8 1.625ZM2.167 15.167h11.666V3.5H2.167v11.667Z"
            />
        </svg>
    )
}

export default AssignmentChecked
