import {
    FormControl,
    FormControls,
    IterateOptions
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import SelectTouch from '../../../../../components/SelectTouch'
import Agent from '../../../../../models/Agent'
import { formLogisticalCost } from '../storageStep2'

export default function Fields3() {
    return (
        <FormControls className="mb-4">
            <AgentField />
            <DocumentNumberField />
        </FormControls>
    )
}

function DocumentNumberField() {
    const { props } = formLogisticalCost.useInput('document_number')

    return (
        <FormControl label="Nro. de documento">
            <InputField {...props} placeholder="Ingresar número de documento" />
        </FormControl>
    )
}

function AgentField() {
    const { props, data } = formLogisticalCost.useSelect<Agent>(
        'agent',
        '/v5.6/apis/agents?is_enabled=true'
    )
    return (
        <FormControl label="Agente">
            <SelectTouch {...props} placeholder="Seleccionar agente">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}
