import { Page, Document, StyleSheet } from '@react-pdf/renderer'
import { GetInputPart } from '../../types'
import moment from 'moment'
import Header from './Header'
import Body from './Body'

const styles = StyleSheet.create({
    section: {
        padding: '30px 30px'
    },
    page: {
        fontSize: '9px',
        fontFamily: 'Helvetica',
        marginBottom: '10px',
        color: '#2A2A5B'
    }
})

export default function Pdf({ data }: { data: GetInputPart }) {
    const { company } = data.company_warehouse

    return (
        <Document title={moment().format('DD-MM-YYYY')}>
            <Page size="A4" style={styles.section}>
                <Header company={company} data={data} />
                <Body data={data} />
            </Page>
        </Document>
    )
}
