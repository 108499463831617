import classNames from 'classnames'
import { PropsTooltip } from './types'
import './tooltip.css'

export default function Tooltip({
    className,
    color = 'primary',
    content,
    children,
    to = 'top'
}: PropsTooltip) {
    return (
        <div
            data-tip={content}
            className={classNames(
                'tooltip',
                {
                    [`tooltip-${color}`]: true,
                    [`tooltip-${to}`]: true
                },
                className
            )}
        >
            {children}
        </div>
    )
}
