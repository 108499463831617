import useInitial from '../useInitial'
import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import Cards from './Cards'
import Rules from '../../../components/Rules'
import Button from '../../../components/Button'
import { useHistory } from 'react-router'
import { controlList } from '../storage'
import useStorage from '../../../hooks/useStorage'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import Country from '../../../models/Country'

function CountryFilter() {
    const { props } = controlList.useFilter<Country>('country_id')
    const { countriesAdvance } = useStorage()

    return (
        <Dropdown {...props} label="País">
            <IterateOptions
                data={countriesAdvance}
                label="name"
                image="image_url"
            />
        </Dropdown>
    )
}

export default function Warehouses() {
    const state = useInitial()
    const { push } = useHistory()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <div className="flex flex-row gap-1 mb-4 justify-between">
                        <CountryFilter />
                        <Rules cu="CU095">
                            <Button
                                size="sm"
                                className="ml-2"
                                onClick={() =>
                                    push('/configuration/warehouses/creation')
                                }
                            >
                                Nuevo
                            </Button>
                        </Rules>
                    </div>
                    <Cards />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
