import { useState } from 'react'
import { Option } from '../../../../../../../config/types/component'
import Currency from '../../../../../../../models/Currency'
import { DriveFile } from '../../../../../../../utilities/Drive'
import { parseDriveFile } from '../../../../../../../utilities/fileTools'
import { ResponseLogisticalCost } from '../../../../../types'

export default function useFieldStates(
    logisticalCost?: ResponseLogisticalCost
) {
    const [concept, setConcept] = useState<Option | null>(() =>
        logisticalCost
            ? {
                  label: logisticalCost.logistical_concept.name,
                  value: logisticalCost.logistical_concept_id.toString()
              }
            : null
    )
    const [agent, setAgent] = useState<Option | null>(() =>
        logisticalCost
            ? {
                  label: logisticalCost.agent ? logisticalCost.agent.name : '',
                  value: logisticalCost.agent_id?.toString()
              }
            : null
    )
    const [currency, setCurrency] = useState<Currency | null>(() =>
        logisticalCost ? logisticalCost.currency : null
    )
    const [documentNumber, setDocumentNumber] = useState(() =>
        logisticalCost ? logisticalCost.document_number : ''
    )
    const [cost, setCost] = useState(() =>
        logisticalCost ? logisticalCost.original_cost.toFixed(2) : '0'
    )
    const [exchangeRate, setExchangeRate] = useState(() =>
        logisticalCost ? logisticalCost.exchange_to_local.toFixed(2) : '1'
    )
    const localCost = (Number(cost) * Number(exchangeRate)).toFixed(2)
    const [files, setFiles] = useState<DriveFile[]>(() => {
        if (!logisticalCost) return []
        return logisticalCost.files.map(file => parseDriveFile(file))
    })
    const [productIds, setProductIds] = useState<number[]>(() =>
        logisticalCost ? logisticalCost.applicable_product_ids : []
    )

    return {
        concept,
        setConcept,
        agent,
        setAgent,
        documentNumber,
        setDocumentNumber,
        currency,
        setCurrency,
        cost,
        setCost,
        exchangeRate,
        setExchangeRate,
        localCost,
        files,
        setFiles,
        setProductIds,
        productIds
    }
}
