import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import { formInputPart } from '../InputPart/storage'
import { controlList, reqStowage, reqWarehouse, storagePage } from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)

    useEffect(() => {
        controlList.filter.changeParams('sort', 'code')
        controlList.filter.changeParams('order', 'desc')
        const company = getCompanySelected()

        const option = {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url,
            span: company.local_currency.symbol
        }

        storagePage.company_enabled = company.is_enabled
        storagePage.timezone = company.timezone
        formInputPart.setValue('company', option)
        controlList.filter.setOption('company_id', option)

        reqWarehouse.setQuery('company_id', option.value)
        reqStowage.setQuery('company_id', option.value)

        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })

        return () => {
            storagePage.input_part = null
            storagePage.timezone = ''
            storagePage.company_enabled = false
            reqStowage.init()
            reqWarehouse.init()
        }
    }, [])

    return { state }
}
