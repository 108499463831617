import Checking from '../../../../../components/Checking'
import DataTable, { TBody, THead } from '../../../../../components/DataTable'
import buildCompanyName from '../../../../../utilities/buildCompanyName'
import { ProductView } from '../../../types'

export default function TableCompanies({
    productCountries
}: {
    productCountries: ProductView['product_countries']
}) {
    return (
        <DataTable className="mb-3" scrollHeight={250}>
            <THead>
                <tr>
                    <th>País</th>
                    <th>Compañía</th>
                    <th>Compra</th>
                    <th>Venta</th>
                </tr>
            </THead>
            <TBody>
                {productCountries.map(pc =>
                    pc.product_companies.map(pcom => (
                        <tr key={pcom.id}>
                            <td>
                                <img
                                    src={pcom.company.country.image_url}
                                    className="w-8"
                                />
                            </td>
                            <td
                                className={`${
                                    !pcom.company.memory && 'line-through'
                                }`}
                            >
                                {buildCompanyName(pcom.company.name)}
                            </td>
                            <td>
                                <Checking checked={pcom.can_buy} />
                            </td>
                            <td>
                                <Checking checked={pcom.can_sell} />
                            </td>
                        </tr>
                    ))
                )}
            </TBody>
        </DataTable>
    )
}
