import { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'
import './modal.css'
import { PropsModal } from './types'

export default function Modal({
    className = '',
    title = '',
    open = false,
    size = 'md',
    children,
    onSubmit = () => null,
    onClose = () => null,
    isCentered = false
}: PropsModal) {
    return createPortal(
        <div
            className={classnames(
                'modal',
                {
                    'modal-open': open,
                    'modal--sm': size === 'sm',
                    'modal--md': size === 'md',
                    'modal--lg': size === 'lg',
                    // 'overflow-y-auto items-start py-10': !isCentered,
                    'is-centered': isCentered
                },
                className
            )}
        >
            <div className="modal__window">
                <div className="modal__header">
                    <h3 className="title">{title}</h3>
                    <button className="modal-close" onClick={onClose}>
                        x
                    </button>
                </div>
                <form onSubmit={onSubmit}>{open && children}</form>
            </div>
        </div>,
        document.querySelector('#modal-contain')
    )
}

export function ModalBody({ children }: { children: ReactNode }) {
    return (
        <div className="modal__body">
            <div className="">{children}</div>
        </div>
    )
}

export function ModalFooter({ children }: { children: ReactNode }) {
    return <div className="modal__footer">{children}</div>
}
