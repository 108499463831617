import Icon from '../../../../components/Icon'
import Tooltip from '../../../../components/Tooltip'
import { tableContacts } from '../../storage'

export default function TooltipContacts() {
    const { message, findError } = tableContacts.useHelpers()
    const helper = findError(0)('ERROR-0000139')

    return (
        <Tooltip content={helper || message}>
            {(helper || message) && (
                <Icon name="warning" className="text-red-500" />
            )}
        </Tooltip>
    )
}
