import Rules from '../../../components/Rules'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { providerList } from '../storage'

interface PropsStateAction {
    isEnabled: boolean
    productProviderCompanyId: number
    name: string
    disabled: boolean
}

export default function StateAction({
    isEnabled,
    productProviderCompanyId,
    name,
    disabled
}: PropsStateAction) {
    return (
        <Rules cu={isEnabled ? 'CU113' : 'CU114'}>
            <ActivateDeactivate
                className="mr-2"
                isEnabled={isEnabled}
                control={providerList}
                disabled={disabled}
                body={{
                    product_provider_company_id: productProviderCompanyId
                }}
            >
                <Active path="/v5.6/apis/product_provider_companies/activate">
                    Se reactivará el producto del catálogo, el producto estará
                    disponible para operaciones de compra con el proveedor ¿Está
                    seguro de reactivar el producto <b>{name}</b>?
                </Active>
                <Deactive path="/v5.6/apis/product_provider_companies/deactivate">
                    Se desactivará el producto del catálogo, el producto ya no
                    estará disponible para operaciones de compra con el
                    proveedor. ¿Está seguro de desactivar el producto{' '}
                    <b>{name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
