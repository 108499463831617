import { PropsStep, PropsSteps } from './types'
import './steps.css'
import { StepsContext } from './contexts'
import { useContext } from 'react'
import classNames from 'classnames'

export default function Steps({
    children,
    value = null,
    onStep = () => null,
    className
}: PropsSteps) {
    function handleStep(v) {
        onStep(v)
    }

    return (
        <ul className={classNames('steps-comp relative', className)}>
            <StepsContext.Provider value={{ value, onStep: handleStep }}>
                {children}
            </StepsContext.Provider>
        </ul>
    )
}

export function Step({ children, value }: PropsStep) {
    const { value: v, onStep } = useContext(StepsContext)
    return (
        <li
            onClick={() => onStep(value)}
            className={classNames({ active: value === v }, 'relative')}
        >
            <p className="top-8 absolute w-36 text-center -left-14 text-xs">
                {children}
            </p>
        </li>
    )
}
