import Brand from '../../../models/Brand'
import Subcategory from '../../../models/Subcategory'
import { formInformation } from '../storage'
import { FormInformation } from '../types'

export function generateDescription(
    fieldName: keyof FormInformation,
    value: FormInformation[keyof FormInformation]
) {
    // nombre_marca + “ ” + descripcion_corta + “ ” +modelo + “ ”
    // + rango + “ ” + rango + “ ” + presentación + “ ” + cantidad
    const values = formInformation.store()
    values[fieldName as string] = value
    const { brand, name, model, rank, presentation, quantity } = values

    const description = `${brand?.label || ''}${!name ? '' : ' ' + name}${
        !model ? '' : ' ' + model
    }${!rank ? '' : ' ' + rank}${!presentation ? '' : ' ' + presentation}${
        !quantity ? '' : ' ' + quantity
    }`
    formInformation.setValue('description_generate', description.trim())
}

export function generateProductCode(
    fieldName: keyof FormInformation,
    value: FormInformation[keyof FormInformation]
) {
    const values = formInformation.store()
    values[fieldName as string] = value
    const brands = formInformation.getData<Brand>('brand')
    const subcategories = formInformation.getData<Subcategory>('subcategory')
    const { brand, subcategory, manufacturer_code } = values

    let brandCode = ''

    if (brand) {
        brandCode =
            brands.find(_brand => _brand.id === parseInt(brand.value))?.code ||
            ''
    }

    let subcategoryCode = ''

    if (subcategory) {
        subcategoryCode =
            subcategories.find(
                _subcategory => _subcategory.id === parseInt(subcategory.value)
            )?.code || ''
    }

    const code = `${subcategoryCode || ''}-${
        brandCode || ''
    }${manufacturer_code}`
    formInformation.setValue('code', code.trim())
}
