import classNames from 'classnames'
import moment from 'moment'
import { DRIVE_DOWNLOAD } from '../../config/app'
import { DriveFile } from '../../utilities/Drive'
import { getIconFile, parseFileSize } from '../../utilities/fileTools'
import Icon from '../Icon'
import { PropsFileView } from './types'

function isFile(file: File | DriveFile): file is File {
    return (file as File).name !== undefined
}

export default function FileView({
    file,
    onDelete = null,
    className = '',
    dontDownload = false
}: PropsFileView) {
    const name = isFile(file) ? file.name : file.filename
    const format = isFile(file)
        ? moment(file.lastModified).format('DD/MM/YYYY')
        : moment(file.format).format('DD/MM/YYYY')
    const href = isFile(file)
        ? window.URL.createObjectURL(file)
        : `${DRIVE_DOWNLOAD}${file.drive_id}`

    return (
        <div
            style={{ borderColor: '#a0aec0' }}
            className={classNames(
                'border rounded-md w-full flex justify-between items-center py-2 px-4',
                className
            )}
        >
            <div className="flex">
                <img src={getIconFile(name)} className="mr-2 w-8" />
                <div>
                    <p className="text-xs break-all">{name}</p>
                    <span className="text-gray-400 text-2xs block">
                        {parseFileSize(
                            file.size,
                            isFile(file) ? 'bytes' : 'kb'
                        )}{' '}
                        / Agregado el {format}
                    </span>
                </div>
            </div>
            <div>
                {onDelete && (
                    <Icon name="bs-trash" pointer onClick={onDelete} />
                )}
                {!dontDownload && (
                    <a href={href} download={name} className="ml-2">
                        <Icon name="download" />
                    </a>
                )}
            </div>
        </div>
    )
}
