import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { storagePage } from '../../../storage'
import { LogisticalDeclarationPDFData } from '../../../types'
import HelveticaBold from '../../../../../assets/fonts/HelveticaBold.otf'
import { generalStyles } from '../GeneralStyles'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const styles = StyleSheet.create({
    ...generalStyles,
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC',
        borderTop: '1px solid #CCCCCC'
    },
    container_summary: {
        marginTop: '14px'
    },
    cellLeft: {
        width: '70%',
        textAlign: 'left'
    },
    cellRight: {
        width: '30%',
        textAlign: 'right'
    }
})

export default function TableSummary({
    data
}: {
    data: LogisticalDeclarationPDFData
}) {
    const sym = storagePage.currency
    const { data_summary } = data

    return (
        <View style={styles.container_summary} break>
            <Text style={styles.container__title}>Resumen</Text>
            <View style={styles.table}>
                <View style={[styles.row, styles.textBold]}>
                    <Text style={[styles.table__td, styles.cellLeft]}>FOB</Text>
                    <Text style={[styles.table__td, styles.cellRight]}>
                        {sym} {buildNumberFormat(data_summary.fob_local)}
                    </Text>
                </View>
                {data.type_logistical_declaration_id === 1 && (
                    <>
                        <View style={styles.row}>
                            <Text style={[styles.table__td, styles.cellLeft]}>
                                Costos Internacionales
                            </Text>
                            <Text style={[styles.table__td, styles.cellRight]}>
                                {sym}{' '}
                                {buildNumberFormat(
                                    data_summary.international_local_cost
                                )}
                            </Text>
                        </View>
                        <View style={[styles.row, styles.textBold]}>
                            <Text style={[styles.table__td, styles.cellLeft]}>
                                CIF (FOB + Flete + Seguro)
                            </Text>
                            <Text style={[styles.table__td, styles.cellRight]}>
                                {sym}{' '}
                                {buildNumberFormat(data_summary.cif_local)}
                            </Text>
                        </View>

                        <View style={styles.row}>
                            <Text style={[styles.table__td, styles.cellLeft]}>
                                Costos de Nacionalización
                            </Text>
                            <Text style={[styles.table__td, styles.cellRight]}>
                                {sym}{' '}
                                {buildNumberFormat(
                                    data_summary.nationalization_local_cost
                                )}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.table__td, styles.cellLeft]}>
                                Costos Internacionales Adicionales
                            </Text>
                            <Text style={[styles.table__td, styles.cellRight]}>
                                {sym}{' '}
                                {buildNumberFormat(
                                    data_summary.extra_international_local_cost
                                )}
                            </Text>
                        </View>
                    </>
                )}
                <View style={styles.row}>
                    <Text style={[styles.table__td, styles.cellLeft]}>
                        Costos Nacionales Adicionales
                    </Text>
                    <Text style={[styles.table__td, styles.cellRight]}>
                        {sym}{' '}
                        {buildNumberFormat(
                            data_summary.extra_national_local_cost
                        )}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={[styles.table__td, styles.cellLeft]}>
                        Costos de Ajuste
                    </Text>
                    <Text style={[styles.table__td, styles.cellRight]}>
                        {sym}{' '}
                        {buildNumberFormat(data_summary.setting_local_cost)}
                    </Text>
                </View>
                <View style={[styles.row, styles.textBold]}>
                    <Text style={[styles.table__td, styles.cellLeft]}>
                        Costo TOTAL (FOB + Otros costos)
                    </Text>
                    <Text style={[styles.table__td, styles.cellRight]}>
                        {sym}{' '}
                        {buildNumberFormat(
                            data_summary.cif_plus_local +
                                data_summary.extra_international_local_cost +
                                data_summary.extra_national_local_cost +
                                data_summary.setting_local_cost
                        )}
                    </Text>
                </View>
                <View style={[styles.row, styles.textBold]}>
                    <Text style={[styles.table__td, styles.cellLeft]}>
                        MONTO A COSTEAR
                    </Text>
                    <Text style={[styles.table__td, styles.cellRight]}>
                        {sym}{' '}
                        {buildNumberFormat(
                            data_summary.international_local_cost +
                                data_summary.nationalization_local_cost +
                                data_summary.extra_international_local_cost +
                                data_summary.extra_national_local_cost +
                                data_summary.setting_local_cost
                        )}
                    </Text>
                </View>
            </View>
        </View>
    )
}
