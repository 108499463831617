import { render } from 'redity'
import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import {
    formTraspase,
    reqProducts,
    reqStowageDestiny,
    reqSubStowageDestiny,
    storagePage
} from '../../storage'
import { DestinationStowageForTraspaseResponse } from '../../types'

export default function useStowageDestiny() {
    const { props } = formTraspase.useSelect('stowage_destination')
    const {
        data,
        loading,
        props: propsSearch
    } = useGet<DestinationStowageForTraspaseResponse[]>(
        reqStowageDestiny.observer(
            '/v5.6/apis/stowages/destination_stowages_for_traspase?is_enabled=true&require_all_substowages=false'
        ),
        []
    )

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value === '0') {
            formTraspase.setProps(
                'sub_stowage_destination',
                { disabled: true },
                false
            )
            reqProducts.deleteQuery('destination_minimal_stowage_id')
            if (reqProducts.hasQuery('origin_minimal_stowage_id')) {
                reqProducts.load()
            }
        } else {
            const { type_stowage_id, minimal_stowage_principal }: any =
                data.result.find(
                    _stowage => _stowage.id === parseInt(option.value)
                )

            storagePage.current_type_stowage_id_destination = type_stowage_id
            // Pedidos (P) o Remisionada (R)
            const isPR = type_stowage_id === 2 || type_stowage_id === 11

            if (isPR) {
                formTraspase.setProps(
                    'sub_stowage_destination',
                    {
                        disabled: false
                    },
                    false
                )
                reqSubStowageDestiny.setQuery(
                    'destination_stowage_id',
                    option.value
                )
                reqSubStowageDestiny.load()
            } else {
                formTraspase.setProps(
                    'sub_stowage_destination',
                    {
                        disabled: true
                    },
                    false
                )
                reqProducts.setQuery(
                    'destination_minimal_stowage_id',
                    minimal_stowage_principal.id
                )
                storagePage.minimal_stowage_destiny_id =
                    minimal_stowage_principal.id
                reqProducts.deleteQuery('destination_minimal_stowage_id')
            }

            if (reqProducts.hasQuery('origin_minimal_stowage_id') && !isPR) {
                reqProducts.load()
            }
        }

        formTraspase.setValue('sub_stowage_destination', null)
        render(formTraspase.keyRender, 'sub_stowage_origin')
    }

    return { props, stowages: data.result, handleChange, loading, propsSearch }
}
