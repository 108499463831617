import { controlTabs, storagePage, formNorms, formInformation } from './storage'
import { BodyNorms } from './types'

export default function useSubmitNorms(productId: number) {
    const { submit, validation, observer } = formNorms.useSubmit<any>(
        `/v5.6/apis/products/${productId}/rules`,
        {
            message: '¿Está seguro de guardar cambios de Normas?',
            method: 'put',
            expectChange: storagePage.mode === 'creation',
            done: product => {
                storagePage.product = {
                    ...storagePage.product,
                    ...product,
                    id: storagePage.product.id
                }
                formNorms.init(formNorms.store())
                storagePage.step2Done = true
                controlTabs.next()
            }
        }
    )

    validation((v, form) => {
        if (parseInt(formInformation.store().type_product.value) !== 2) {
            v.lifetime.isEmpty('Se requiere ingresar la vida util')
        }
        if (form.refrigeration) {
            v.min_temperature.isEmpty(
                'Se requiere ingresar el mínimo de temperatura'
            )
            v.max_temperature
                .isEmpty('Se requiere ingresar el máximo de temperatura')
                .isMinor(
                    Number(form.min_temperature),
                    'La temperatura máxima debe ser mayor a la mínima'
                )
        }
    })

    observer<BodyNorms>(form => {
        function getStorableToDelete() {
            return storagePage.product.non_storable_products.filter(
                nsp =>
                    !form.storable_products.find(
                        sp => parseInt(sp.value) === nsp.id
                    )
            )
        }

        const new_non_storable_product_ids = () =>
            form.storable_products
                .filter(
                    product =>
                        !storagePage.product.non_storable_products.find(
                            s_product =>
                                s_product.id === parseInt(product.value)
                        )
                )
                .map(prod => parseInt(prod.value))

        return {
            lifetime: parseInt(form.lifetime),
            refrigeration: form.refrigeration,
            ...(form.refrigeration && {
                min_temperature: Number(form.min_temperature),
                max_temperature: Number(form.max_temperature)
            }),
            air_transport: form.air_transport,
            marine_transport: form.marine_transport,
            new_non_storable_product_ids: storagePage.product
                ?.non_storable_products
                ? new_non_storable_product_ids()
                : form.storable_products.map(s_prod => parseInt(s_prod.value)),
            non_storable_product_to_delete_ids:
                storagePage.mode === 'edition'
                    ? getStorableToDelete().map(nsp => nsp.id)
                    : []
        }
    })

    return submit
}
