import { FormEvent, useEffect, useState } from 'react'
import useHelper from '../../../../../hooks/useHelper'
import { post } from '../../../../../utilities/http'
import Notify from '../../../../../utilities/Notify'
import val from '../../../../../utilities/val'
import { providerList } from '../../../storage'
import { HelperData, RowError, SubmitValues, UploadState } from './types'

export default function useSubmit(
    values: SubmitValues,
    close: () => void,
    onDone: () => void
) {
    const { helper, setHelper } = useHelper<HelperData>()
    const [uploadStates, setUploadStates] = useState<UploadState[]>([])
    const [rowsError, setRowsError] = useState<RowError[]>([])

    useEffect(() => {
        setHelper({
            ...helper,
            company: ''
        })
    }, [values.optionCompany])
    useEffect(() => {
        setHelper({
            ...helper,
            money: ''
        })
    }, [values.optionMoney])
    useEffect(() => {
        setHelper({
            ...helper,
            importExcel: ''
        })
    }, [values.excelRendered])

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        setUploadStates([])
        setHelper({})
        setRowsError([])
        const v = val(values)
        v.optionCompany.isEmpty('Es requerido la compañía')
        v.optionMoney.isEmpty('Es requerido la moneda')
        v.excelRendered.isEmpty('Es requerido el excel')

        const result = v.runtest()
        if (result) {
            setHelper({
                company: result.optionCompany,
                money: result.optionMoney,
                importExcel: result.excelRendered
            })
            return
        }

        const rows = values.excelRendered.rows.filter(row => row.length > 0)

        if (rows.length === 0) {
            setUploadStates([
                {
                    status: 'error',
                    message: 'Formato excel invalidado'
                }
            ])
            return
        }

        const header = [
            'codigo_producto',
            'codigo_producto_proveedor',
            'moneda',
            'precio_compra',
            'descripcion_producto_proveedor'
        ]

        for (let i = 0; i < rows[0].length; i++) {
            const row = rows[0][i]
            if (row !== header[i]) {
                setUploadStates([
                    {
                        status: 'error',
                        message: 'Formato excel invalidado'
                    }
                ])
                return
            }
        }

        setUploadStates([
            {
                status: 'done',
                message: 'Formato excel validado'
            },
            {
                status: 'loading',
                message: 'Validando productos'
            }
        ])

        const rowsOnlyData = rows
            .filter((_, i) => i > 0)
            .map(row => {
                return {
                    product_code: row[0] ? row[0].toString() : null,
                    provider_code: row[1] ? row[1].toString() : null,
                    purchase_price: isNaN(row[3] as number)
                        ? row[3] || null
                        : Number(row[3]),
                    provider_description: row[4] ? row[4].toString() : null
                }
            })

        const providerId = providerList.filter.params.provider_id
        const { error, data } = await post<{ errors: RowError[] }>(
            '/v5.6/apis/product_provider_companies/validate',
            {
                company_id: parseInt(values.optionCompany.value),
                currency_id: parseInt(values.optionMoney.value),
                product_provider_companies: rowsOnlyData,
                provider_id: parseInt(providerId)
            }
        )

        if (error || (data.result && data.result.errors.length > 0)) {
            setUploadStates(prevStates =>
                prevStates.map((data, i) => {
                    if (i === 1) {
                        return {
                            status: 'error',
                            message: 'Validando productos'
                        }
                    }

                    return data
                })
            )
            setRowsError(data.result.errors)
            return
        } else {
            setUploadStates(prevStates => {
                const newData = prevStates.map((data, i) => {
                    if (i === 1) {
                        return {
                            status: 'done' as UploadState['status'],
                            message: 'Validando productos'
                        }
                    }

                    return data
                })
                return [
                    ...newData,
                    {
                        status: 'loading' as UploadState['status'],
                        message: 'Importando tus productos'
                    }
                ]
            })
        }

        const { error: err } = await post(
            '/v5.6/apis/product_provider_companies',
            {
                product_provider_companies: rowsOnlyData.map(row => ({
                    company_id: parseInt(values.optionCompany.value),
                    currency_id: parseInt(values.optionMoney.value),
                    product_code: row.product_code,
                    provider_code: row.provider_code,
                    purchase_price: row.purchase_price,
                    provider_description: row.provider_description
                })),
                provider_id: parseInt(providerId)
            }
        )

        if (err) {
            setUploadStates(prevStates =>
                prevStates.map((data, i) => {
                    if (i === 2) {
                        return {
                            status: 'error',
                            message: 'Importando tus productos'
                        }
                    }
                    return data
                })
            )
            Notify.error('Lo sentimos hubo un error')
        } else {
            Notify.success('La operación se ha completado exitosamente')
            setUploadStates([])
            close()
            onDone()
        }
    }

    return {
        submit,
        helper,
        setHelper,
        uploadStates,
        rowsError,
        setRowsError,
        setUploadStates
    }
}
