import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import useGet from '../../../../hooks/useGet'
import InputPartLabel from '../../../../models/InputPartLabel'
import { formInputPart } from '../../storage'
import useStowage from './useStowage'
import useSubStowage from './useSubStowage'

function Stowage() {
    const { props, stowages, handleChange, propsSearch } = useStowage()

    return (
        <FormControl label="Bodega" required>
            <SelectTouch
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar bodega"
                label="Bodega"
            >
                <IterateOptions data={stowages} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Tag() {
    const { props } = formInputPart.useSelect('tag')
    const { data } = useGet<InputPartLabel[]>(
        '/v5.6/apis/input_part_labels?is_enabled=true',
        []
    )

    return (
        <FormControl required label="Etiqueta">
            <SelectTouch
                {...props}
                placeholder="Seleccionar etiqueta"
                label="Etiqueta"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function SubStowage() {
    const { props, handleChange, propsSearch, data } = useSubStowage()

    return (
        <FormControl label="Sub bodega" required>
            <SelectTouch
                disabled
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar sub bodega"
                label="Sub bodega origen"
            >
                <IterateOptions data={data.result} label="substowage_name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function Fields2() {
    return (
        <>
            <Stowage />
            <Tag />
            <SubStowage />
        </>
    )
}
