import { ModalTools } from '../../../../sections/ModalSection/types'
import { formContact, tableContactsControl } from '../../storage'
import { emailExists, nameExists } from '../../utilities/expectTableContact'
import FormContact from './FormContact'

export default function useContact(index?: number) {
    function handleSubmit(ev, modalState: ModalTools) {
        ev.preventDefault()
        const result = formContact.test(v => {
            v.name
                .minLength(2, 'En número de carácteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el nombre')
                .condition(
                    value =>
                        nameExists(value as string, index) &&
                        'Ya existe un contacto con el mismo nombre'
                )
            v.position
                .minLength(2, 'En número de carácteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el cargo')
            v.email
                .minLength(2, 'En número de carácteres válidos es 2 - 180')
                .email('No es un email válido')
                .isEmpty('Se requiere ingresar el email')
                .condition(
                    value =>
                        emailExists(value as string, index) &&
                        'Ya existe un contacto con el mismo email'
                )
            v.phone_number
                .minLength(2, 'En número de carácteres válidos es 2 - 180')
                .isEmpty('Se requiere ingresar el teléfono')
        })

        if (result) {
            const { name, position, phone_number, email } = formContact.store()

            if (index === undefined) {
                const is_main = tableContactsControl
                    .getDataList()
                    .find(tableContact => tableContact.is_main)
                tableContactsControl.push({
                    name,
                    position,
                    phone_number,
                    email,
                    is_main: !is_main
                })
            } else {
                tableContactsControl.put(index, {
                    name,
                    position,
                    phone_number,
                    email
                })
            }
            modalState.close()
        }
    }

    return formContact.useModal(FormContact, {
        title: index !== undefined ? 'Editar Contacto' : 'Nuevo Contacto',
        size: 'sm',
        onSubmit: handleSubmit
    })
}
