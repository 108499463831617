import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import { TableImportProps } from './types'

export default function TableImport({ rowsError }: TableImportProps) {
    return (
        <DataTable>
            <THead>
                <tr>
                    <th>#</th>
                    <th>Fila</th>
                    <th>Error</th>
                </tr>
            </THead>
            <TBody>
                {rowsError.map((err, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{err.index}</td>
                        <td>{err.message}</td>
                    </tr>
                ))}
                {rowsError.length === 0 && <Caption colSpan={3}>---</Caption>}
            </TBody>
        </DataTable>
    )
}
