import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import SelectTouch from '../../../components/SelectTouch'
import { formPurchaseReceipt, reqWarehouse, reqWorkingPeriod } from '../storage'
import useCompany from './useCompany'
import useGet from '../../../hooks/useGet'
import Warehouse from '../../../models/Warehouse'
import TypeReception from '../../../models/TypeReception'
import WorkingPeriod from '../../../models/WorkingPeriod'
import moment from 'moment'
import useRule from '../../../hooks/useRule'
import DatePicker from '../../../components/Datepicker'
import NumeralField from '../../../components/NumeralField'
import useStorage from '../../../hooks/useStorage'

function SelectCompany() {
    const { props, companies, handleChange } = useCompany()

    return (
        <FormControl label="Compañía" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar compañía"
                label="Compañía"
                clear={false}
            >
                <IterateOptions data={companies} label="name" image="image" />
            </SelectTouch>
        </FormControl>
    )
}

function SelectWarehouse() {
    const { props } = formPurchaseReceipt.useSelect('warehouse')
    const { data, loading } = useGet<Warehouse[]>(
        reqWarehouse.observer('/v5.6/apis/warehouses/company', true),
        []
    )

    return (
        <FormControl label="Almacén" required>
            <SelectTouch
                {...props}
                loading={loading}
                placeholder="Seleccionar almacén"
                label="Almacén"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function SelectTypeRC() {
    const { props } = formPurchaseReceipt.useSelect('typeRC')
    const { data } = useGet<TypeReception[]>(
        '/v5.6/apis/type_receptions/all',
        []
    )

    return (
        <FormControl label="Tipo de RC" required>
            <SelectTouch
                {...props}
                placeholder="Seleccionar tipo de RC"
                label="Tipo de RC"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Shipment() {
    const { props } = formPurchaseReceipt.useInput('shipment')

    return (
        <FormControl label="Embarque">
            <InputField {...props} placeholder="Ingresar número de embarque" />
        </FormControl>
    )
}

function ExchangeRate() {
    const { props } = formPurchaseReceipt.useInput('exchangeRate')
    return (
        <FormControl required label="Tasa de cambio">
            <NumeralField
                {...props}
                decimal={2}
                placeholder="Ingresar tasa de cambio"
            />
        </FormControl>
    )
}

function ReceiptDate() {
    const { companiesFromSession } = useStorage()
    const company = companiesFromSession.find(
        c => c.id === parseInt(formPurchaseReceipt.store().company.value)
    )
    const { props, getError } = formPurchaseReceipt.useDate('receiptDate')
    const math = useRule('CU997')

    const helper = getError('ERROR-0000104')

    const { data } = useGet<WorkingPeriod>(
        reqWorkingPeriod.observer(
            '/v5.6/apis/working_periods/first_working_period/:company_id',
            true
        )
    )

    const { month, year, created_at } = data.result || {}
    const minDate =
        month && year
            ? moment(created_at, company.timezone).toISOString(true)
            : null

    return (
        <FormControl label="Fecha de recepción" required>
            <DatePicker
                {...props}
                disabled={!math && month && year}
                minDate={minDate}
                maxDate={moment().tz(company.timezone).toISOString(true)}
                helper={props.helper || helper}
                placeholder="Seleccionar fecha de recepción"
            />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <SelectCompany />
            <SelectWarehouse />
            <SelectTypeRC />
            <Shipment />
            <ExchangeRate />
            <ReceiptDate />
        </>
    )
}
