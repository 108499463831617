import { FormControl } from '../../../../components/FormUtilities'
import Textarea from '../../../../components/Textarea'
import Upload from '../../../../components/Upload'
import { formInformation } from '../../storage'

function Documents() {
    const { props } = formInformation.useUploads('files')

    return (
        <FormControl label="Documentos de respaldo" required col="span-6">
            <Upload
                {...props}
                extensions={[
                    'xls',
                    'xlsx',
                    'png',
                    'jpg',
                    'jpeg',
                    'pdf',
                    'doc',
                    'docx',
                    'PNG',
                    'JPG'
                ]}
            />
        </FormControl>
    )
}

function Comment() {
    const { props, getError } = formInformation.useInput('comment')
    const helper = getError()
    return (
        <FormControl label="Comentario" col="span-6">
            <Textarea
                {...props}
                placeholder="Ingresar comentario"
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

export default function Fields3() {
    return (
        <>
            <Documents />
            <Comment />
        </>
    )
}
