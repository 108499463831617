import { useEffect, useState } from 'react'
import { get } from '../../http'
import fter, { merge } from 'fter'

export default function useLoadSearch<D>(path: string) {
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<D[]>([])

    async function load(_search: string) {
        if (path === undefined) return
        const params = fter({ search: _search, limit: 10 })`
            search?
            limit
        `

        const response = await get<D[]>(merge(path, params))
        if (response.data) {
            setData(response.data.result)
            setLoading(false)
        }
    }

    const onSearch = async ev => {
        const value = ev.target.value
        setSearch(value)
        setLoading(true)
        load(value)
    }

    useEffect(() => {
        load('')
    }, [])

    return {
        search,
        loading,
        data,
        onSearch,
        setSearch
    }
}
