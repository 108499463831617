export default function User(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C5.79 0 4 1.79 4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm2 4c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm4 9c-.2-.71-3.3-2-6-2s-5.8 1.29-6 2.01V14h12v-1zM0 13c0-2.66 5.33-4 8-4s8 1.34 8 4v3H0v-3z"
            />
        </svg>
    )
}
