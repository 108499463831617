import { FormControl, FormControls } from '../../../../components/FormUtilities'
import NumeralField from '../../../../components/NumeralField'
import ReadOnly from '../../../../components/ReadOnly'
import { formFactor } from '../../storage'

function Factor() {
    const { props } = formFactor.useInput('factor')
    return (
        <FormControl label="Factor nuevo" required>
            <NumeralField
                {...props}
                placeholder="Ingresar nuevo factor"
                max={999}
                decimal={2}
            />
        </FormControl>
    )
}

function Discount() {
    const { props } = formFactor.useInput('discount')
    return (
        <FormControl label="Descuento nuevo (%)" required>
            <NumeralField
                {...props}
                placeholder="Ingresar nuevo descuento"
                max={100}
                decimal={2}
            />
        </FormControl>
    )
}

export default function Fields() {
    const {
        subcategory_name,
        category_name,
        current_factor,
        current_discount
    } = formFactor.store()

    return (
        <FormControls>
            <FormControl label="Subcategoría">
                <ReadOnly value={subcategory_name} />
            </FormControl>
            <FormControl label="Categoría">
                <ReadOnly value={category_name} />
            </FormControl>
            <FormControl label="Factor actual">
                <ReadOnly value={current_factor} />
            </FormControl>
            <Factor />
            <FormControl label="Descuento actual (%)">
                <ReadOnly value={current_discount} />
            </FormControl>
            <Discount />
        </FormControls>
    )
}
