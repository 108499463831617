export default function Directory(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="prefix__w-4 prefix__h-4 prefix__mr-2 prefix__stroke-current"
            width="1em"
            height="1em"
            {...props}
        >
            <path d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
        </svg>
    )
}
