import { InitialData } from '../../../config/load'
import { tableCompaniesControl } from '../storage'

export default function initialCreate(storage: InitialData) {
    tableCompaniesControl.setList(
        storage.companies
            .filter(company => company.is_enabled)
            .map(company => ({
                id: company.id,
                name: company.name,
                image: company.country.image_url,
                is_enabled: false
            }))
            .sort((a, b) => {
                return a.id - b.id
            })
    )
}
