import List from '../../../sections/List'
import { controlList } from '../storage'
import Period from './Period'

export default function Periods() {
    return (
        <List
            control={controlList}
            type="cards"
            scrollHeight={2}
            enumeration={false}
        >
            {(workingPeriods, i) => (
                <Period key={i} workingPeriod={workingPeriods}></Period>
            )}
        </List>
    )
}
