import moment from 'moment'
import { parseFile } from '../../../../utilities/fileTools'
import {
    controlTabsInternational,
    controlTabsNational,
    storagePage
} from '../../storage'
import { formGeneral } from './storageStep1'
import { reqRC } from '../SectionCosts/storageStep2'
import General from '../../types/general'

export default function useSubmit1() {
    const { mode } = storagePage

    const pathSubmit =
        mode === 'creation'
            ? `/v5.6/apis/logistical_declarations/general_information`
            : `/v5.6/apis/logistical_declarations/${storagePage.logistical_declaration.id}/general_information`

    const { observer, validation, submit } =
        formGeneral.useSubmit<General.Response>(pathSubmit, {
            message: '¿Está seguro de guardar cambios de Información General?',
            done: logistical_declaration => {
                const { id } = logistical_declaration
                storagePage.logistical_declaration = logistical_declaration

                if (mode === 'creation') {
                    storagePage.version = logistical_declaration.current_version
                }

                reqRC.setParam('logistical_declaration_id', id)
                storagePage.step1Done = true
                controlTabsInternational.next()
                controlTabsNational.next()
            },
            method: mode === 'edition' ? 'put' : 'post'
        })

    validation(v => {
        v.name.isEmpty('Se requiere ingresar el nombre de borrador')
        v.company.isEmpty('Se requiere seleccionar la compañía')
        v.working_period.isEmpty('Se requiere seleccionar el periodo fiscal')
        v.type_shipment.isEmpty('Se requiere seleccionar el tipo de embarque')
    })

    function getForm(form: General.Form) {
        if (mode === 'edition') {
            const { logistical_declaration } = storagePage
            return {
                name: form.name,
                number_declaration: form.declaration_number,
                date_declaration: form.declaration_date
                    ? moment(form.declaration_date).toISOString(true)
                    : null,
                agent_id: form.agent ? parseInt(form.agent.value) : null,
                type_shipment_id: form.type_shipment
                    ? parseInt(form.type_shipment.value)
                    : null,
                description: form.comment,
                new_files: form.files
                    .filter(file => !file.id)
                    .map(file => parseFile(file)),
                files_to_delete: logistical_declaration.files
                    .filter(cf => !form.files.find(file => file.id === cf.id))
                    .map(cf => cf.id)
            }
        }

        return {
            name: form.name,
            company_id: parseInt(form.company.value),
            date_declaration: form.declaration_date
                ? moment(form.declaration_date).toISOString(true)
                : null,
            working_period_id: form.working_period
                ? parseInt(form.working_period.value)
                : null,
            type_logistical_declaration_id:
                storagePage.type_logistical_declaration_id,
            agent_id: form.agent ? parseInt(form.agent.value) : null,
            type_shipment_id: form.type_shipment
                ? parseInt(form.type_shipment.value)
                : null,
            description: form.comment,
            files: form.files.map(file => parseFile(file)),
            number_declaration: form.declaration_number
        }
    }

    observer<General.BodyPost | General.BodyPut>(getForm)

    return submit
}
