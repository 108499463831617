import { ReactNode } from 'react'
import { render, useRender } from 'redity'
import { IconName } from '../components/Icon/types'
import SimpleTabs, { Tab } from '../components/SimpleTabs'
import Steps, { Step } from '../components/Steps'
import { generateId } from './generateId'

export type StepValue = 'step1' | 'step2' | 'step3' | 'step4' | 'step5'

interface PropsSectionTabs {
    type?: 'tab' | 'step'
    className?: string
    value: StepValue
    mode?: 'card' | 'simple'
    data?: any
}

export default class ControlTabs {
    readonly keyRender = generateId()
    private readonly keyIndex = 'Listen'
    value: StepValue = 'step1' as StepValue
    private values: StepValue[] = ['step1', 'step2', 'step3', 'step4', 'step5']
    private data: Record<
        string,
        {
            value: StepValue
            label: string
            Component: (props: { data: any }) => JSX.Element
            icon?: IconName
        }
    > = {}

    private onStep: (value: StepValue) => Promise<boolean> | boolean = () =>
        true

    useStep = (
        onStep: (to: StepValue) => Promise<boolean> | boolean = () => true
    ): StepValue => {
        useRender(this.keyRender)
        this.onStep = onStep
        return this.value
    }

    next = async () => {
        const data = Object.values(this.data)
        const index = data.findIndex(val => val.value === this.value)
        if (index >= data.length - 1) return false
        const value = data[index + 1].value
        if (await this.onStep(value)) {
            this.value = value
            render(this.keyRender)
            render(this.keyRender, this.keyIndex)
            return true
        }
        return false
    }

    prev = async () => {
        const data = Object.values(this.data)
        const index = data.findIndex(val => val.value === this.value)
        if (index <= 0) return false
        const value = data[index - 1].value
        if (await this.onStep(value)) {
            this.value = value
            render(this.keyRender)
            render(this.keyRender, this.keyIndex)
            return true
        }
        return false
    }

    async step(value: StepValue) {
        if (await this.onStep(value)) {
            this.value = value
            render(this.keyRender)
            render(this.keyRender, this.keyIndex)
            return true
        }
        return false
    }

    setSection(
        label: string,
        Component: (props: { data: any }) => JSX.Element,
        icon?: IconName
    ) {
        if (this.data[label]) return
        const i = Object.values(this.data).length
        this.data[label] = {
            value: this.values[i],
            label,
            Component,
            icon
        }
    }

    Listen = ({ children }: { children: (value: StepValue) => ReactNode }) => {
        useRender(this.keyRender, this.keyIndex)
        return <>{children(this.value)}</>
    }

    SectionTabs = ({
        className = '',
        type = 'step',
        mode = 'card',
        data: nextData = {}
    }: PropsSectionTabs) => {
        const data = Object.values(this.data)
        const Component =
            data.find(d => d.value === this.value)?.Component || (() => <>👀</>)

        const handleStep = async (value: StepValue) => {
            const result = await this.onStep(value)
            if (result) {
                this.value = value
                render(this.keyRender)
                render(this.keyRender, this.keyIndex)
            }
        }

        return (
            <>
                {type === 'step' && (
                    <Steps
                        value={this.value}
                        className={className}
                        onStep={handleStep}
                    >
                        {data.map(d => (
                            <Step key={d.value} value={d.value}>
                                {d.label}
                            </Step>
                        ))}
                    </Steps>
                )}
                {type === 'tab' && (
                    <SimpleTabs
                        value={this.value}
                        className={className}
                        onStep={handleStep}
                        type={mode}
                    >
                        {data.map(d => (
                            <Tab key={d.value} value={d.value} icon={d.icon}>
                                {d.label}
                            </Tab>
                        ))}
                    </SimpleTabs>
                )}
                <Component data={nextData} />
            </>
        )
    }
}
