import useStorage from '../../../hooks/useStorage'
import { TableList } from '../../../sections/ListForm'
import { Keys } from '../constants'
import { DataTable } from '../types'
import createList from '../utilities/createList'
import Companies from './Companies'

export default function TableDepartment({ data }: { data: DataTable }) {
    const { storage } = useStorage()
    const controlList = createList(
        `${Keys.TABLE_DEPARTMENT}-${data.department.id}`,
        data,
        storage.companies
    )
    return (
        <TableList control={controlList} className="datatable--form">
            {(d, control, i) => (
                <tr key={d.role_id}>
                    <td>{i + 1}</td>
                    <td>{d.name}</td>
                    <Companies control={control} />
                </tr>
            )}
        </TableList>
    )
}
