import BtnActionTable from '../../../../components/BtnActionTable'
import RadioButton from '../../../../components/RadioButton'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { Popup } from '../../../../utilities/popup'
import { tableContactsControl } from '../../storage'
import { Step1 } from '../../types'
import EditContact from './EditContact'

function Main({
    control,
    index
}: {
    control: FormEvents<Step1.TableProviderContact>
    index: number
}) {
    const { props } = control.useCheck('is_main')

    function handleChange(ev) {
        props.onChange(ev)
        if (!ev.target.checked) return
        tableContactsControl.list.forEach((data, i) => {
            if (i !== index) {
                data.setValue('is_main', false)
            }
        })
    }

    return <RadioButton {...props} onChange={handleChange} />
}

export default function TableContacts() {
    async function handleDel(index: number) {
        const { id, name, is_main } = tableContactsControl.get(index)
        if (id) {
            const result = await Popup.delete(
                'Eliminar registro',
                <>
                    Se removerá el contacto de la lista. ¿Estás seguro de
                    eliminar <b>{name}</b>?
                </>
            )
            if (!result) return
        }
        tableContactsControl.del(index)
        if (is_main && tableContactsControl.list.length > 0) {
            tableContactsControl.list[0].setValue('is_main', true)
        }
    }

    return (
        <TableList control={tableContactsControl} scrollHeight={250}>
            {(data, control, e) => (
                <tr key={e}>
                    <td>{data.name}</td>
                    <td>
                        <Main control={control} index={e - 1} />
                    </td>
                    <td>{data.position}</td>
                    <td>{data.phone_number}</td>
                    <td>{data.email}</td>
                    <td>
                        <div className="group-btn-options">
                            <EditContact contact={data} index={e - 1} />
                            <BtnActionTable
                                title="Elminar"
                                iconName="bs-trash"
                                onClick={() => handleDel(e - 1)}
                            />
                        </div>
                    </td>
                </tr>
            )}
        </TableList>
    )
}
