import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import useStorage from '../../hooks/useStorage'
import { get } from '../../utilities/http'
import { formAgent, storagePage, tableAddress, tableContacts } from './storage'
import { AgentGet } from './types'
import prepareEdition from './utilities/prepareEdition'

export default function useInitial() {
    const { agent_id } = useParams<{ agent_id?: string }>()
    const [pageState, setPageState] = useState(() =>
        agent_id ? StateCase.LOADING : StateCase.SUCCESS
    )
    const { storage } = useStorage()

    useEffect(() => {
        if (agent_id) {
            storagePage.mode = 'edition'
            get<AgentGet>(`/v5.6/apis/agents/${agent_id}`).then(response => {
                if (response.error) {
                    setPageState(StateCase.ERROR)
                    return
                }
                storagePage.agent = response.data.result
                prepareEdition(response.data.result, storage.countries)
                setPageState(StateCase.SUCCESS)
            })
        } else {
            storagePage.mode = 'creation'
        }

        return () => {
            formAgent.init()
            tableContacts.setList([])
            tableAddress.setList([])
            storagePage.agent = null
        }
    }, [])

    return {
        pageState
    }
}
