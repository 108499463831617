import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import { StoragePage, Kardex } from './types'
import ControlTabs from '../../utilities/ControlTabs'

export const storagePage: StoragePage = {
    header: {
        total_balance: 0,
        total_quantity: 0,
        records: 0
    },
    currency: null,
    timezone: ''
}

export const controlList = new ControlList<Kardex>(Keys.MAIN, {
    path: '/v5.6/apis/kardex',
    theads: [
        '#',
        { value: 'number', label: 'Número' },
        'Transacción',
        'Fecha',
        'Bodega',
        { value: 'type', label: 'Tipo' },
        'Entrada',
        'Salida',
        'Saldo',
        'Valor de ingreso unitario',
        'Costo ponderado unitario',
        'Debe',
        'Haber',
        'Saldo'
    ]
})

controlList.filter.changeParams('sort', null)
controlList.badgeRemove('company_id', false)
controlList.badgeRemove('product_id', false)
controlList.badgeRemove('start_date', false)
controlList.badgeRemove('end_date', false)

export const ctsModalReception = new ControlTabs()
