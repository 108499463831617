import BtnActionTable from '../../../../components/BtnActionTable'
import Rules from '../../../../components/Rules'
import OpenDialog from '../../../../sections/OpenDialog'
import { controlList } from '../../storage'
import { ConsumerVoucherData } from '../../types'

export default function CancelConsumerVoucher({
    consumer_voucher
}: {
    consumer_voucher: ConsumerVoucherData
}) {
    return (
        <Rules cu={'CU172'}>
            <OpenDialog
                element={click => (
                    <BtnActionTable
                        title="Anular"
                        iconName="bs-x"
                        onClick={click}
                        disabled={false}
                    />
                )}
                title="ANULAR VALE DE CONSUMO"
                path="/v5.6/apis/consumer_vouchers/cancel"
                icon="bs-x-circle"
                color="error"
                control={controlList}
                body={{
                    consumer_voucher_id: consumer_voucher.id
                }}
                method="put"
                accept="Sí, anular"
            >
                ¿Está seguro de anular el vale de consumo{' '}
                {consumer_voucher.document.code}?
            </OpenDialog>
        </Rules>
    )
}
