export default function Done(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M4.667 9.493l-3.5-3.762L0 6.985 4.667 12l10-10.746L13.5 0 4.667 9.493z" />
        </svg>
    )
}
