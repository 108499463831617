import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import { formTraspase } from '../Traspase/storage'
import {
    controlList,
    controlTabs,
    filterForm,
    reqStowageOrigin,
    reqWarehouse,
    storagePage
} from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)

    useEffect(() => {
        const company = getCompanySelected()
        controlList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })
        controlList.filter.params.status_id = 3
        formTraspase.setValue('company', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })
        reqWarehouse.setQuery('company_id', company.id)
        reqStowageOrigin.setQuery('company_id', company.id)

        controlList.filter.changeParams('sort', 'code')
        controlList.filter.changeParams('order', 'desc')
        storagePage.company_enabled = company.is_enabled
        storagePage.timezone = company.timezone
        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })

        return () => {
            filterForm.init()
            controlTabs.step('step1')
        }
    }, [])

    return { state }
}
