function LocationQuestion(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 15 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.149 8.092C.149 4.11 3.39.868 7.372.868c3.982 0 7.224 3.242 7.224 7.224 0 5.03-6.284 12.045-6.555 12.344l-.669.74-.668-.74C6.434 20.137.15 13.12.15 8.092zm1.806 0c0 3.197 3.449 8 5.417 10.357 1.58-1.905 5.418-6.899 5.418-10.357a5.422 5.422 0 00-5.418-5.418 5.422 5.422 0 00-5.417 5.418zm4.632 4.515h1.58v1.58h-1.58v-1.58zM4.212 7.64a3.164 3.164 0 013.16-3.16 3.164 3.164 0 013.16 3.16c0 1.015-.622 1.56-1.229 2.093-.575.504-1.136.996-1.136 1.862h-1.58c0-1.435.74-2.005 1.393-2.507.514-.395.973-.748.973-1.448 0-.867-.714-1.58-1.58-1.58-.867 0-1.58.713-1.58 1.58h-1.58z"
            />
        </svg>
    )
}

export default LocationQuestion
