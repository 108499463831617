import * as React from 'react'

function MessageClose(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg%22%3E"
            {...props}
        >
            <path d="M12 0H1.333C.6 0 .007.6.007 1.333l-.007 8c0 .734.6 1.334 1.333 1.334h5.334V9.333H1.333V2.667L6.667 6 12 2.667V6h1.333V1.333C13.333.6 12.733 0 12 0zM6.667 4.667L1.333 1.333H12L6.667 4.667zM10.227 12l-2.36-2.36.94-.94 1.413 1.413 2.827-2.826.953.94L10.227 12z" />
        </svg>
    )
}

export default MessageClose
