import { data } from '../storage'

export default function getCompanyDepartmentRoleUser(
    cduId: number,
    role_id: number
) {
    const cdu = data.user.company_department_users.find(
        _cdu => _cdu.id === cduId
    )

    return (
        cdu.company_department_role_users.find(
            _cdru => _cdru.role_id === role_id
        ) || null
    )
}
