import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowFob(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [
        { align: 'center', value: 'Valor FOB', fontWeight: 'bold' }
    ]
    data.forEach(d => {
        row.push({
            fontWeight: 'bold',
            value: d.data_summary.fob_dolar,
            format: '0.00'
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'center',
            fontWeight: 'bold',
            value: d.data_summary.fob_local,
            format: '0.00'
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
