import { ReactNode } from 'react'
import { render } from 'redity'
import { IconName } from '../../components/Icon/types'
import { Colors } from '../../config/types/component'
import { KEY_POPUP } from './constants'
import { statePopup, stateUtils } from './storage'
import { wait } from './utils'

export default function definePopup() {
    function open({
        children,
        ...props
    }: {
        icon: IconName
        title: string
        children: ReactNode
        accept: string
        color: Colors
    }) {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            ...props,
            open: true,
            cancel: 'No, cancelar'
        })
        return wait()
    }

    function save(children: ReactNode): Promise<boolean> {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: 'Guardar registro',
            icon: 'flag',
            accept: 'Sí, guardar registro',
            cancel: 'No, cancelar'
        })
        return wait()
    }

    const confirm = (
        title: string,
        children: ReactNode,
        accept = 'Sí, confirmar'
    ) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title,
            icon: 'check-cirle',
            accept: accept,
            cancel: 'No, cancelar'
        })
        return wait()
    }

    const check = async (title: string, children: ReactNode) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: title,
            icon: 'check-cirle',
            accept: null,
            cancel: null
        })
        await new Promise(resolve => {
            render(KEY_POPUP)
            setTimeout(() => {
                resolve(null)
            }, 2000)
        })
        statePopup.set('open', false)
        render(KEY_POPUP)
        return true
    }

    const del = async (title: string, children: ReactNode, accept?: string) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: title,
            icon: 'delete-sweep',
            accept: accept || 'Sí, eliminar registro',
            color: 'error'
        })
        return wait()
    }

    const error = async (title: string, children: ReactNode) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: title,
            icon: 'times',
            accept: 'Ok, intentar de nuevo',
            cancel: null,
            color: 'error'
        })
        return wait()
    }

    const mail = async (title: string, children: ReactNode) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: title,
            icon: 'message',
            accept: 'Sí, enviar orden de compra',
            cancel: 'No, cancelar',
            color: 'primary'
        })
        return wait()
    }

    const alert = async (
        children: ReactNode,
        accept = 'Sí, guardar cambios'
    ) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: '¡Espere!',
            icon: 'circle-exclamation',
            accept,
            cancel: 'No, cancelar',
            color: 'warning'
        })
        return wait()
    }

    const assign = async (title: string, children: ReactNode) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: title,
            icon: 'products',
            accept: 'Sí, asignar',
            cancel: 'No, cancelar',
            color: 'secondary'
        })
        return wait()
    }

    const deassign = async (title: string, children: ReactNode) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: title,
            icon: 'shop',
            accept: 'Sí, desasignar',
            cancel: 'No, cancelar',
            color: 'secondary'
        })
        return wait()
    }

    const ohno = async (children: ReactNode) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: '¡Oh no!',
            icon: 'circle-exclamation',
            accept: 'Sí, volver a intentar',
            color: 'secondary'
        })
        return wait()
    }

    const connection = async () => {
        statePopup.init()
        stateUtils.children =
            'Hemos sido notificados y estamos trabajando muy duro para resolver el problema. Por favor ten paciencia e intenta realizar de nuevo la operación.'
        statePopup({
            ...statePopup(),
            open: true,
            title: 'Algo salió mal...',
            icon: 'plus',
            accept: null,
            cancel: 'Ok, intentar de nuevo',
            color: 'secondary'
        })
        return wait()
    }

    const cancel = async (children: ReactNode) => {
        statePopup.init()
        stateUtils.children = children
        statePopup({
            ...statePopup(),
            open: true,
            title: '¡Espere!',
            icon: 'warning',
            accept: 'Sí, cancelar cambios',
            cancel: 'No, volver',
            color: 'warning'
        })
        return wait()
    }

    return {
        open,
        save,
        check,
        delete: del,
        error,
        alert,
        ohno,
        connection,
        assign,
        deassign,
        confirm,
        mail,
        cancel
    }
}
