import { SVGProps } from 'react'

const AirPort = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M19.5 14v-2l-8-5V1.5C11.5.67 10.83 0 10 0S8.5.67 8.5 1.5V7l-8 5v2l8-2.5V17l-2 1.5V20l3.5-1 3.5 1v-1.5l-2-1.5v-5.5l8 2.5Z" />
    </svg>
)

export default AirPort
