import 'moment/locale/es'
import Badge from '../../../../components/Badge'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import moment from 'moment'
import { GetTraspase } from '../../types'
import { storagePage } from '../../storage'

export default function TraspaseView({ data }: { data: GetTraspase }) {
    const color = {
        1: 'warning',
        2: 'error',
        3: 'success',
        4: 'warning'
    }
    return (
        <>
            <Typography type="header-3" className="mb-4">
                Información de la aprobación
            </Typography>
            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>Rol</th>
                        <th>Usuario</th>
                        <th>Estado</th>
                        <th>Fecha de aprobación</th>
                    </tr>
                </THead>
                <TBody>
                    {data.document.document_approver_users.map((item, i) => (
                        <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td
                                className={
                                    item.user.role && !item.user.role.is_enabled
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                {(item.user.role && item.user.role.name) || '-'}
                            </td>
                            <td
                                className={
                                    !item.user.is_enabled ? 'line-through' : ''
                                }
                            >{`${item.user.name} ${item.user.lastname}`}</td>
                            <td>
                                <Badge
                                    color={
                                        color[
                                            item.type_user_document_approval_id
                                        ]
                                    }
                                >
                                    {item.type_user_document_approval.name}
                                </Badge>
                            </td>
                            <td>
                                {moment(item.created_at)
                                    .tz(storagePage.timezone)
                                    .format('DD/MM/YYYY')}
                            </td>
                        </tr>
                    ))}
                    {data.document.document_approver_users.length === 0 && (
                        <Caption colSpan={6}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
