import { ReactNode, useState } from 'react'
import Dialog from '../../components/Dialog'
import { PropsDialog } from '../../components/Dialog/types'
import { Progress } from '../../utilities/progress'
import { post, put } from '../../utilities/http'
import Notify from '../../utilities/Notify'
import { Exception } from '../../utilities/http/type'
import { Popup } from '../../utilities/popup'
import Control from '../../utilities/Control/Control'

function expectError(error: Exception) {
    if (error.type === 'dependency' || error.type === 'development') {
        const errors = Object.values(error.errors)
        const message =
            errors.length > 0
                ? errors[0].description
                : 'Ni idea del porque el error xD'
        Popup.error('Lo sentimos', message)
    } else {
        Popup.ohno(
            'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
        )
    }
}

export default function OpenDialog({
    element,
    children,
    path,
    body,
    control,
    msgSuccess,
    msgLoading,
    method = 'post',
    ...props
}: PropsOpenDialog) {
    const [isOpen, setOpen] = useState(false)

    async function handleResult(result: boolean) {
        setOpen(false)
        if (!result) {
            return
        }
        Progress.open(msgLoading || 'Cargando...')
        const send = method === 'post' ? post : put
        const response = await send<any>(path, body)

        if (response.data) {
            if (control instanceof Control) {
                await control.done()
            } else {
                control()
            }
            Notify.success(
                msgSuccess || 'La operación se ha completado exitosamente'
            )
            Progress.close()
            if (control instanceof Control) {
                control.renderMain()
            }
        } else {
            Progress.close()
            expectError(response.error)
        }
    }

    return (
        <>
            {element(() => setOpen(true))}
            <Dialog {...props} open={isOpen} onResult={handleResult}>
                {children}
            </Dialog>
        </>
    )
}

interface PropsOpenDialog extends Omit<PropsDialog, 'open' | 'onResult'> {
    element: (onClick: () => void) => ReactNode
    children: ReactNode
    path: string
    body: Record<string, any>
    control: Control | (() => void)
    msgLoading?: string
    msgSuccess?: string
    method?: 'post' | 'put'
}
