import { useEffect, useState } from 'react'
import ControlTabs from '../../../../utilities/ControlTabs'
import { get } from '../../../../utilities/http'
import {
    LicenseData,
    PresentationData,
    ProductIncompatible,
    ProductView
} from '../../types'
import Availability from './Availability'
import Info from './Info'
import Norms from './Norms'

const controlTabs = new ControlTabs()
controlTabs.setSection('Información General', Info)
controlTabs.setSection('Normas', Norms)
controlTabs.setSection('Disponibilidad', Availability)

export default function Product({ data }: { data: ProductView }) {
    const value = controlTabs.useStep()
    const [results, setResponses] = useState<{
        licenses: LicenseData[]
        presentations: PresentationData[]
        incompatibles: ProductIncompatible[]
    }>({
        licenses: [],
        presentations: [],
        incompatibles: []
    })
    useEffect(() => {
        Promise.all([
            get<LicenseData[]>(`/v5.6/apis/products/${data.id}/licenses`),
            get<PresentationData[]>(
                `/v5.6/apis/products/${data.id}/presentation_products`
            ),
            get<ProductIncompatible[]>(
                `/v5.6/apis/products/${data.id}/incompatible_products?limit=200`
            )
        ]).then(responses => {
            if (responses[0].error || responses[1].error || responses[2].error)
                return
            setResponses({
                licenses: responses[0].data.result,
                presentations: responses[1].data.result,
                incompatibles: responses[2].data.result
            })
        })
        return () => {
            controlTabs.value = 'step1'
        }
    }, [])

    return (
        <controlTabs.SectionTabs
            value={value}
            type="tab"
            mode="simple"
            data={{
                product: data,
                licenses: results.licenses,
                presentations: results.presentations,
                incompatibles: results.incompatibles
            }}
            className="mb-5"
        />
    )
}
