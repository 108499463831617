import { ReactNode } from 'react'
import { render } from 'redity'
import { KEY_PROGRESS } from './constants'
import { state_progress } from './storage'

export default function defineProgress() {
    function open(children: ReactNode) {
        state_progress.open = true
        state_progress.children = children
        render(KEY_PROGRESS)
    }

    function close() {
        state_progress.open = false
        render(KEY_PROGRESS)
    }

    return {
        open,
        close
    }
}
