import { InitialData } from '../../../config/load'
import {
    formInformation,
    tableCompaniesControl,
    tableFiscalCodeControl,
    tablePermitsControl,
    tableTaxesControl
} from '../storage'
import { ChangeTax } from '../types'

export default function prepareFormCreate(
    companies: InitialData['companies'],
    countriesAdvance: InitialData['countriesAdvance'],
    changeTax: ChangeTax
) {
    formInformation.setProps('brand', { disabled: false })

    tableCompaniesControl.setList(
        companies.map(company => ({
            company_id: company.id,
            can_sell: false,
            can_buy: false,
            image: company.country.image_url,
            company_name: company.name,
            is_enabled: company.is_enabled
        }))
    )

    tableFiscalCodeControl.setList(
        companies.map(company => ({
            company_id: company.id,
            country_image: company.country.image_url,
            company_name: company.name,
            is_enabled: company.is_enabled,
            fiscal_code: ''
        }))
    )

    const taxCountries = changeTax ? changeTax.countries : []

    tableTaxesControl.setList(
        countriesAdvance.map(country => ({
            country_id: country.id,
            tax:
                taxCountries
                    .find(_country => _country.country_id === country.core_id)
                    ?.tax?.toString() || '',
            image: country.image_url,
            country_name: country.name
        }))
    )

    tablePermitsControl.setList(
        countriesAdvance.map(country => ({
            country_id: country.id,
            nationalization_permission: false,
            shipment_permission: false,
            ministry_health_permission: false,
            new_files: [],
            image: country.image_url,
            country_name: country.name
        }))
    )
}
