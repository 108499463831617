import {
    FormControl,
    FormControls
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import NumeralField from '../../../../../components/NumeralField'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../storage'
import { formLogisticalCost } from '../storageStep2'

function PurchaseCost1() {
    const { props } = formLogisticalCost.useInput('original_cost')

    function handleChange(ev) {
        props.onChange(ev)
        runOperation()
    }

    return (
        <FormControl label="Monto ($)" required col="span-2">
            <NumeralField
                {...props}
                decimal={2}
                onChange={handleChange}
                autoFocus
            />
        </FormControl>
    )
}

function ExchangeRate() {
    const { props } = formLogisticalCost.useInput('exchange_to_local')

    function handleChange(ev) {
        props.onChange(ev)
        runOperation()
    }

    // const { symbol } = storagePage.currentCurrency

    return (
        <FormControl
            label={`Tipo de cambio ($ a ${storagePage.currentCurrency.symbol})`}
            required
            col="span-2"
        >
            <NumeralField {...props} onChange={handleChange} decimal={4} />
        </FormControl>
    )
}

function PurchaseCost2() {
    const { props } = formLogisticalCost.useInput('total_cost')
    const { symbol } = storagePage.currentCurrency
    return (
        <FormControl label={`Monto (${symbol})`} required col="span-2">
            <InputField {...props} disabled />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <FormControls className="mb-4">
            <PurchaseCost1 />
            <ExchangeRate />
            <PurchaseCost2 />
        </FormControls>
    )
}

function runOperation() {
    const { exchange_to_local, original_cost } = formLogisticalCost.store()
    const exchange_to_local_int = Number(exchange_to_local)
    const original_cost_int = Number(original_cost)
    const purchase_cost = exchange_to_local_int * original_cost_int
    formLogisticalCost.setValue('total_cost', buildNumberFormat(purchase_cost))
}
