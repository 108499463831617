import FormEvents from '../../utilities/FormEvents'
import ListFormControl from '../../sections/ListForm/ListFormControl'
import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
import {
    Storage,
    FormPresentation,
    GroupPresentationData,
    TablePresentationData
} from './types'
import Product from '../../models/Product'
import { dataMemory } from '../../utilities/dataTools'
// import setParamsPresentations from './utilities/setParamsPresentations'

const storage: Storage = {
    currentGroupPresentation: null,
    products: dataMemory()
}
export default storage

export const controlList = new ControlList<GroupPresentationData>(Keys.MAIN, {
    path: '/v5.6/apis/group_presentations',
    theads: [
        '#',
        { value: 'name', label: 'Grupo de presentación' },
        'N° de presentaciones',
        'Acciones'
    ]
})

export const formPresentation = new FormEvents<FormPresentation>(
    Keys.MODAL_FORM,
    {
        name: '',
        presentationProducts: []
    }
)

formPresentation.setParams('presentationProducts', {
    sort: 'name'
})

formPresentation.store.on('presentationProducts', function (options) {
    const groupPresentations = formPresentation.getData<Product>(
        'presentationProducts'
    )

    const productsBackup = storage.products.sets(
        groupPresentations.map(product => ({
            description: product.description,
            code: product.code,
            amount: storage.products.get(product.id)?.amount || '',
            id: product.id
        }))
    )

    tablePresentations.setList(
        productsBackup.filter(
            product =>
                !!options.find(option => parseInt(option.value) === product.id)
        )
    )
    tablePresentations.renderMain()
    // setParamsPresentations(false)
})

export const tablePresentations = new ListFormControl<TablePresentationData>(
    Keys.LIST_FORM,
    ['Producto', 'Presentación', 'Acciones']
)
