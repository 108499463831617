import { useContext } from 'react'
import { InitialData } from '../config/load'
import StorageContext from '../contexts/storage-context'

export interface InitialStorage extends InitialData {
    /**
     * @deprecated
     */
    storage: InitialData
}

export default function useStorage(): InitialStorage {
    const storage = useContext(StorageContext)
    return {
        ...storage,
        storage
    }
}
