import SearchField from '../../../components/SearchField'
import Button from '../../../components/Button'
import { Link } from 'react-router-dom'
import { controlList, storagePage } from '../storage'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import useStorage from '../../../hooks/useStorage'
import Rules from '../../../components/Rules'
import { Option } from '../../../config/types/component'
import { formInformation } from '../../CostEstimate/storage'
import { render, useRender } from 'redity'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { setCompanySelected } from '../../../utilities/controlStorage'

function Company() {
    const { companiesFromSession } = useStorage()
    const { props } = controlList.useFilter('company_id')

    function handleChange(option: Option) {
        const company = companiesFromSession.find(
            _company => _company.id === parseInt(option.value)
        )
        if (company.is_enabled) {
            formInformation.setValue('company', option)
            storagePage.company_enabled = true
        } else {
            formInformation.setValue('company', null)
            storagePage.company_enabled = false
        }
        setCompanySelected(company)
        props.onChange(option)
        render('button_new')
    }

    return (
        <Dropdown
            {...props}
            clear={false}
            onChange={handleChange}
            label="Compañía"
            showLabel={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    name: company.name,
                    image_url: company.country.image_url,
                    id: company.id,
                    disabled: !company.is_enabled
                }))}
                label="name"
                image="image_url"
                strikeOut="disabled"
            />
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por código de recepción"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

function ButtonNew() {
    useRender('button_new')
    return (
        <Link
            to="/costs/cost_estimates/create"
            className={
                !storagePage.company_enabled ? 'pointer-events-none' : ''
            }
        >
            <Button
                disabled={!storagePage.company_enabled}
                size="sm"
                className=""
            >
                Nuevo
            </Button>
        </Link>
    )
}

export default function Filters() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Company />
                </FlexLeft>
                <FlexRight>
                    <Rules cu="CU288">
                        <ButtonNew />
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
