import { useEffect } from 'react'
import buildCompanyName from '../../../utilities/buildCompanyName'
import useStorage from '../../../hooks/useStorage'
import { ctsView } from '../storage'
import { GetPurchase } from '../type'
import GeneralInformation from './GeneralInformation'
import ProductsToRequest from './ProductsToRequest'

ctsView.setSection('Información General', GeneralInformation)
ctsView.setSection('Productos a solicitar', ProductsToRequest)

export default function ModalPurchase({ data }: { data: GetPurchase }) {
    const value = ctsView.useStep()
    useEffect(() => {
        return () => {
            ctsView.value = 'step1'
        }
    })

    const { countries } = useStorage()

    const getCountry = (country_id: number) =>
        countries.find(country => country.id === country_id)

    return (
        <>
            <div className="body__head">
                <div className="title">
                    <h1>ORDEN DE COMPRA {data.code}</h1>
                </div>
                <div>
                    <div className="company_badge">
                        <img
                            src={
                                getCountry(data.destination_company.country_id)
                                    .image_url
                            }
                        />
                        <b>{buildCompanyName(data.destination_company.name)}</b>
                    </div>
                </div>
            </div>
            <ctsView.SectionTabs
                value={value}
                type="tab"
                mode="simple"
                className="mb-4"
                data={data}
            />
        </>
    )
}
