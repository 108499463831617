import Button from '../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import TableAdresses from './TableAdresses'
import TooltipAddress from './TooltipAddress'
import useAddress from './useAddress'

export default function Address() {
    const open = useAddress()

    return (
        <Panel mode="form">
            <PanelHeader
                title="Direcciones de retiro"
                helper={<TooltipAddress />}
            >
                <Button
                    outline
                    size="sm"
                    color="primary"
                    onClick={() => open()}
                >
                    + Agregar
                </Button>
            </PanelHeader>
            <PanelBody>
                <TableAdresses />
            </PanelBody>
        </Panel>
    )
}
