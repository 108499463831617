import { ReactNode } from 'react'
import { render, useRender } from 'redity'
import { IconName } from '../components/Icon/types'
import { Exception } from '../utilities/http/type'
import { Popup } from '../utilities/popup'

export const states: Omit<PropsDialog, 'element'> & PropsStatesDialog = {
    icon: 'check-cirle',
    yes: 'Sí, guardar cambios',
    no: 'No, cancelar',
    loadingMsg: 'Cargando...',
    children: null,
    title: '',
    isOpen: false,
    on: () => null
}

export const KEY = 'DIALOG_MAIN'
export const KEY_THIS = 'DIALOG_MAIN'

export default function useDialog(done: () => Promise<void | string>) {
    useRender(KEY_THIS)

    function close() {
        states.isOpen = false
        render(KEY)
    }

    function Dialog({ element, ...props }: PropsDialog) {
        function open() {
            states.title = props.title
            states.icon = props.icon
            states.children = props.children
            states.no = props.no || 'No, cancelar'
            states.yes = props.yes || 'Sí, guardar cambios'
            states.loadingMsg = props.loadingMsg || 'Cargando...'
            states.on = done
            states.isOpen = false
            render(KEY)
        }

        return <>{element(open)}</>
    }

    function expectError(error: Exception) {
        if (error.type === 'dependency') {
            const errors = Object.values(error.errors)
            const message =
                errors.length > 0
                    ? errors[0].description
                    : 'Ni idea del porque el error xD'
            Popup.error('Lo sentimos', message)
        } else {
            Popup.ohno(
                'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
            )
        }
    }

    return {
        close,
        Dialog,
        expectError,
        isOpen: states.isOpen
    }
}

interface PropsDialog {
    icon: IconName
    yes?: string
    no?: string
    loadingMsg?: string
    children: ReactNode
    title: string
    element: (onClick: () => void) => ReactNode
}

interface PropsStatesDialog {
    isOpen: boolean
    on: () => Promise<string | void>
}
