import { Option } from '../../config/types/component'
import { SelectListProvider } from '../../contexts/select-context'
import useOut from '../../hooks/useOut'
import Icon from '../Icon'
import Loading from '../Loading'
import InputField from '../InputField'
import './selectTouch.css'
import { PropsSelectTouch } from './types'
// import { createPortal } from 'react-dom'
import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'

export default function SelectTouch({
    label = 'Seleccionar',
    option = null,
    onChange = () => null,
    children,
    onSearch = null,
    search = '',
    loading = false,
    placeholderSearch,
    className = '',
    clear = true,
    ...props
}: PropsSelectTouch) {
    const { ref, isOpen, setOpen } = useOut([])
    const selectBodyRef = useRef<HTMLDivElement>()
    function setPosition() {
        if (!selectBodyRef.current) return
        const y = ref.current?.getBoundingClientRect().y
        const x = ref.current?.getBoundingClientRect().x
        const scrollY = window.pageYOffset
        const widthSelect = ref.current?.clientWidth

        selectBodyRef.current.style.width = `${widthSelect + 3}px`
        selectBodyRef.current.style.top = `${y + scrollY}px`
        selectBodyRef.current.style.left = `${x}px`
    }
    useEffect(() => {
        const timer = setTimeout(() => setPosition(), 200)
        window.addEventListener('scroll', setPosition)
        window.addEventListener('resize', setPosition)
        return () => {
            clearTimeout(timer)
            window.removeEventListener('scroll', setPosition)
            window.removeEventListener('resize', setPosition)
        }
    }, [])

    function handleOption(opt: Option) {
        onChange(opt)
        setOpen(false)
    }

    function handleClick() {
        setOpen(true)
    }

    function handleFocus() {
        setOpen(true)
    }

    return (
        <div ref={ref} className={classNames('select-touch w-full', className)}>
            <a href="" onFocus={handleFocus}></a>
            <InputField
                {...props}
                readOnly={!props.disabled}
                onClick={handleClick}
                value={option?.label || ''}
                className={`w-full ${option?.image && 'pl-12'} pr-7`}
            />
            {option?.image && (
                <img
                    src={option.image}
                    className="select-none w-7 absolute left-4 top-12 rounded"
                />
            )}
            <Icon
                name="arrow-bottom"
                className="absolute top-12 right-3 text-gray-400 pointer-events-none"
                size={16}
                onClick={handleClick}
            />
            {createPortal(
                <SelectListProvider
                    isOpen={isOpen}
                    onOption={handleOption}
                    placeholderSearch={placeholderSearch}
                    label={label}
                    onSearch={onSearch}
                    search={search}
                    className={classNames('w-full top-auto z-select-options', {
                        // sticky: selectTouchInherit
                    })}
                    clear={clear}
                    ref={selectBodyRef}
                >
                    {loading ? <Loading scale={0.7} /> : children}
                </SelectListProvider>,
                document.querySelector('#select-contain')
            )}
        </div>
    )
}
