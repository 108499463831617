import FileModel from '../../../../../models/FileModel'
import modal from '../../../../../sections/modal'
import { parseFile } from '../../../../../utilities/fileTools'
import { get } from '../../../../../utilities/http'
import {
    formDutyISCIVA,
    reqLDCostDistribution,
    reqLogisticalCost1,
    reqLogisticalCost2,
    tableIVAControl
} from '../storageStep2'
import Cost from '../cost'
import { loadForm } from './utilities'
import { FormIVA } from '.'

export default function useSubmitIva(logistical_cost_id: number) {
    const { submit, validation, observer } = formDutyISCIVA.useSubmit(
        `/v5.6/apis/logistical_costs/iva/${logistical_cost_id}`,
        {
            done: () => {
                reqLogisticalCost1.load()
                reqLogisticalCost2.load()
            },
            method: 'put'
        }
    )

    let currentFiles: FileModel[] = []

    validation(() => {
        return tableIVAControl.test(f => {
            f.iva_percent.isEmpty('Se requiere ingresar el iva')
        })
    })

    observer<Cost.PutBodyModalIva>(f => ({
        agent_id: f.agent && parseInt(f.agent.value),
        setting_local_cost: Number(f.setting_local_cost),
        document_number: f.document_number,
        reception_ids: f.rcs.map(option => parseInt(option.value)),
        original_cost_products: tableIVAControl.getDataList().map(ocp => ({
            product_id: ocp.product_id,
            percentage: Number(ocp?.iva_percent) / 100
        })),
        new_files: f.files
            .filter(file => !file.id)
            .map(file => parseFile(file)),
        files_to_delete: currentFiles
            .filter(cf => !f.files.find(file => file.id === cf.id))
            .map(cf => cf.id)
    }))

    return function handleModal() {
        modal(
            FormIVA,
            async () => {
                const res = await get<Cost.ResponseLCIva>(
                    `/v5.6/apis/logistical_costs/iva/${logistical_cost_id}`
                )

                currentFiles = res.data.result.files

                loadForm(res.data.result)

                reqLDCostDistribution.setParam(
                    'logistical_declaration_id',
                    res.data.result.logistical_declaration_id
                )

                return {
                    title: 'IVA (Por producto)',
                    onSubmit: submit,
                    props: res.data.result
                }
            },
            'md'
        )
    }
}
