import FilterBrands from './FilterBrands'
import useInitial from '../useInitial'
import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import TableBrands from './TableBrands'

export default function Brands() {
    const state = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterBrands />
                    <TableBrands />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
