import classNames from 'classnames'
import { useRender } from 'redity'
import { THead, TSort } from '../../components/DataTable'
import { Order } from '../../config/types/component'
import ControlList from '../../utilities/Control/ControlList'

interface PropsHead {
    control: ControlList<any>
    theadWhiteSpace:
        | 'normal'
        | 'pre'
        | 'nowrap'
        | 'pre-wrap'
        | 'pre-line'
        | 'break-spaces'
}

export default function Head({ control, theadWhiteSpace }: PropsHead) {
    useRender(control.keyRender, control.indexs.HEAD)

    async function handleSort(sort: string, order: Order) {
        control.loading = true
        control.filter.params.sort = sort
        control.filter.params.order = order
        control.renderMain()
        const result = await control.done()
        if (result) {
            control.renderMain()
        }
    }

    return (
        <THead
            value={control.filter.params.sort}
            onSort={handleSort}
            order={control.filter.params.order}
        >
            <tr>
                {control.thead.map((val, i) => (
                    <th
                        className={classNames('', {
                            'cell-actions':
                                val.toString().toLowerCase() === 'acciones'
                        })}
                        key={i}
                        style={{ whiteSpace: theadWhiteSpace }}
                    >
                        {typeof val === 'string' ? (
                            val
                        ) : (
                            <TSort value={val.value}>{val.label}</TSort>
                        )}
                    </th>
                ))}
            </tr>
        </THead>
    )
}
