import { render, useRender } from 'redity'
import { ExcelRendered } from '../../../components/ExcelField/types'
import { Keys } from '../constant'
import { storagePage } from '../storage'

export default function useImportSection() {
    const lRender = useRender(Keys.IMPORT)

    function handleChange(currentFile: File, dataRendered: ExcelRendered) {
        storagePage.reports = []
        storagePage.messagesResult = []
        render(Keys.TABLE_RESULT)
        render(Keys.REPORT_STATE)

        if (!dataRendered) {
            storagePage.importState = 'nothing'
            render(Keys.REPORT_STATE)
            storagePage.file = null
            lRender()
            render(Keys.BUTTONS)
            render(Keys.BUTTON_BACK)
            return
        }
        dataRendered.rows = dataRendered.rows.filter(row => row.length !== 0)
        storagePage.excelDataRendered = dataRendered

        storagePage.file = currentFile
        lRender()
        render(Keys.BUTTONS)
    }

    function handleLoad(isLoading: boolean) {
        if (isLoading) {
            storagePage.importState = 'importing'
            render(Keys.REPORT_STATE)
        } else {
            storagePage.importState = 'waiting'
            render(Keys.REPORT_STATE)
        }
        render(Keys.BUTTON_BACK)
    }

    return {
        handleChange,
        file: storagePage.file,
        handleLoad
    }
}
