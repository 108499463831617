import { useState } from 'react'
import Dialog from '../../../../components/Dialog'
import { Progress } from '../../../../utilities/progress'
import Textarea from '../../../../components/Textarea'
import { formDialog, storagePage } from '../../storage'
import { render } from 'redity'
import BtnActionTable from '../../../../components/BtnActionTable'

export default function CloseRequisition({
    requisition_product_id,
    disabled
}: {
    requisition_product_id: number
    disabled: boolean
}) {
    const [isOpen, setOpen] = useState(false)
    const { props, getError } = formDialog.useInput('reason')
    const helper = getError()
    const submit = useSubmitReason(setOpen, requisition_product_id)

    async function handleResult(res) {
        if (!res) {
            formDialog.init()
            setOpen(false)
            return
        }
        await submit()
    }
    return (
        <>
            <BtnActionTable
                title="Cerrar RQ"
                iconName="bs-bag-x"
                onClick={() => setOpen(true)}
                disabled={disabled}
            />
            <Dialog
                title="Cerrar Requisición"
                icon="check-cirle"
                open={isOpen}
                color="success"
                onResult={ev => handleResult(ev)}
                accept="Sí, cerrar"
                cancel="No, cancelar"
            >
                Para cerrar la requisición del producto, ingrese el motivo.
                <Textarea
                    {...props}
                    maxLength={3000}
                    helper={helper || props.helper}
                    placeholder="Ingresa el motivo"
                    style={{ width: '100%' }}
                />
            </Dialog>
        </>
    )
}

export function useSubmitReason(setOpen, requisitionProductId) {
    const { submit, validation, observer } = formDialog.useSubmit<any>(
        '/v5.6/apis/requisitions/manage/close',
        {
            method: 'put',
            onError: () => {
                setOpen(true)
                Progress.close()
            },
            done: data => {
                storagePage.products = storagePage.products.map(product => {
                    if (product.id === requisitionProductId) {
                        return {
                            ...product,
                            is_closed: data.is_closed,
                            reason: data.reason
                        }
                    }
                    return product
                })
                render('requisition_data')
                setOpen(false)
                formDialog.init()
                Progress.close()
            }
        }
    )

    validation(v => {
        v.reason.isEmpty('Se requiere ingresar una razón')
    })

    observer<any>(form => {
        return {
            reason: form.reason.trim(),
            requisition_product_id: requisitionProductId
        }
    })

    return submit
}
