export default function ArrowRight(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 8 13"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1.572 1.205l4.513 5.12-4.513 5.12" strokeWidth={2} />
        </svg>
    )
}
