function Flag(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.5.5h9l.4 2h5.6v10h-7l-.4-2H2.5v7h-2V.5zm7.44 2.39l-.08-.39H2.5v6h7.24l.32 1.61.08.39h3.36v-6H8.26l-.32-1.61z"
            />
        </svg>
    )
}

export default Flag
