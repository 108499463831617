import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import { controlTabs, storagePage } from '../storage'
import Step1 from './Step1'
import Step2 from './Step2'
import useInitial from './../useInitial'
import useStep1Submit from './Step1/useSubmit'
import useStep2Submit from './Step2/useSubmit'
import { StepValue } from '../../../utilities/ControlTabs'

controlTabs.setSection('Información General', Step1)
controlTabs.setSection('Productos a Solicitar', Step2)

export default function Requisition() {
    const { pageState, cancel } = useInitial()

    const value = controlTabs.useStep(to => {
        if (to === 'step2' && !storagePage.step1Done) return false
        return true
    })

    const cancelText = controlTabs.value === 'step1' ? 'Cancelar' : 'Atrás'
    const nextText = controlTabs.value === 'step2' ? 'Guardar' : 'Siguiente'

    const submitStep1 = useStep1Submit()
    const submitStep2 = useStep2Submit()

    const submits: Partial<Record<StepValue, (...props: any) => any>> = {
        step1: submitStep1,
        step2: submitStep2
    }

    const handleCancel = () => {
        controlTabs.value === 'step1' && cancel()
        controlTabs.prev()
    }

    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <controlTabs.SectionTabs
                    value={value}
                    className="max-w-screen-2xl"
                />
            </div>
            <PageHeader subtitle="(*) Complete todos los campos para la requisición">
                <Button color="ghost" onClick={handleCancel}>
                    {cancelText}
                </Button>
                <Button
                    className="ml-2"
                    onClick={() => submits[controlTabs.value]()}
                >
                    {nextText}
                </Button>
            </PageHeader>
        </Initial>
    )
}
