import moment from 'moment'
import ControlRequest from '../../../../utilities/ControlRequest'
import FormEvents from '../../../../utilities/FormEvents'
import General from '../../types/general'
import { Keys } from '../../constants'

export const formGeneral = new FormEvents<General.Form>(Keys.GENERAL, {
    company: null,
    name: '',
    declaration_number: '',
    declaration_date: moment().toISOString(),
    working_period: null,
    agent: null,
    type_shipment: null,
    files: [],
    comment: ''
})

export const reqWorkingPeriod = new ControlRequest()
