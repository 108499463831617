import {
    LOGISTICAL_CONCEPT__DUTY,
    LOGISTICAL_CONCEPT__IVA,
    LOGISTICAL_CONCEPT__SETTING_DUTY,
    LOGISTICAL_CONCEPT__SETTING_IVA,
    TYPE_LOGISTICAL_COST_SOURCE__NATIONALIZATION
} from '../../../../../config/constants'
import LogisticalConcept from '../../../../../models/LogisticalConcept'
import {
    GroupLogisticalConcept,
    LogisticalDeclarationExcelData
} from '../../../types'
import { getRowCost } from './getRowCost'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR, textBold } from './utils'

export function getRowsNacionalizationCosts(
    data: LogisticalDeclarationExcelData[],
    groupConcepts: GroupLogisticalConcept[]
) {
    const rows: Row[][] = []
    getNationalizationConcepts(groupConcepts).forEach(concept => {
        rows.push(getRowCost(concept, data, ['nationalization_costs']))
    })
    const rowSummary: Row[] = [
        {
            value: 'Costos de nacionalización',
            backgroundColor: COLOR.yellow
        }
    ]
    data.forEach(d => {
        const duty_cost = d.nationalization_costs.find(
            c => c.logistical_concept_id === LOGISTICAL_CONCEPT__DUTY
        )
        const duty_original_cost = duty_cost ? duty_cost.original_cost : 0
        const duty_local_cost = duty_cost ? duty_cost.local_cost : 0

        rowSummary.push({
            value:
                d.data_summary.nationalization_original_cost -
                duty_original_cost,
            format: '0.00',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: d.data_summary.nationalization_local_cost - duty_local_cost,
            format: '0.00',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    rows.push(addRowStyles(rowSummary, [borderThin, textBold]))
    return rows
}

function getNationalizationConcepts(groupConcepts: GroupLogisticalConcept[]) {
    const nationalizationConcepts: LogisticalConcept[] = []
    groupConcepts
        .filter(
            groupConcept =>
                groupConcept.id === TYPE_LOGISTICAL_COST_SOURCE__NATIONALIZATION
        )
        .forEach(groupConcept => {
            groupConcept.logistical_concepts
                .filter(
                    concept =>
                        concept.id !== LOGISTICAL_CONCEPT__IVA &&
                        concept.id !== LOGISTICAL_CONCEPT__DUTY &&
                        concept.id !== LOGISTICAL_CONCEPT__SETTING_DUTY &&
                        concept.id !== LOGISTICAL_CONCEPT__SETTING_IVA
                )
                .forEach(concept => {
                    nationalizationConcepts.push(concept)
                })
        })
    return nationalizationConcepts
}
