export default function Boat(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5.833h5v2.5H14c.918 0 1.668.75 1.668 1.667v3.85l1.066.35a.83.83 0 01.5.417c.1.2.117.433.05.65l-1.575 5.566h-.041c-1.334 0-2.517-.733-3.334-1.666-.816.933-2 1.666-3.333 1.666-1.334 0-2.517-.733-3.334-1.666-.816.933-2 1.666-3.333 1.666h-.042L.71 10.267A.839.839 0 011.26 9.2l1.075-.35V5c0-.917.75-1.667 1.667-1.667h2.5v-2.5zM9.835 2.5v.833H8.167V2.5h1.667zm-.833 5.925L13.484 9.9l1.992.65-.934 3.308a3.673 3.673 0 01-.95-.783l-1.258-1.442-1.258 1.434c-.284.333-1.067 1.1-2.075 1.1-1.009 0-1.792-.767-2.075-1.1l-1.259-1.434-1.258 1.434c-.167.191-.5.525-.95.775l-.942-3.3 2-.659 4.484-1.458zm-5-.117V5h10v3.308L9 6.667 4 8.308zm5 9.159a5.821 5.821 0 003.333-1.067c1.017.708 2.175 1.1 3.334 1.1h1.666v1.667h-1.666a7.251 7.251 0 01-3.334-.825 7.258 7.258 0 01-3.333.808 7.363 7.363 0 01-3.334-.808c-1.05.533-2.183.825-3.333.825H.667V17.5h1.667c1.158 0 2.317-.392 3.333-1.1a5.821 5.821 0 003.334 1.067z"
            />
        </svg>
    )
}
