import 'moment/locale/es'
import Typography from '../../../../components/Typography'
import { TableList } from '../../../../sections/ListForm'
import {
    formConsumptionVoucher,
    productsControl,
    reqProducts,
    storagePage
} from '../../storage'
import NumeralField from '../../../../components/NumeralField'
import FormEvents from '../../../../utilities/FormEvents'
import { LogicalProductForVoucher } from '../../types'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import BtnActionTable from '../../../../components/BtnActionTable'

export default function Table() {
    function handleDelete(lProduct: LogicalProductForVoucher, index) {
        productsControl.del(index)
        const { products } = formConsumptionVoucher.store()

        const options = products.filter(
            tp => parseInt(tp.value) !== lProduct.logical_product_id
        )
        formConsumptionVoucher.setValue('products', options)
        if (options.length === 0) {
            reqProducts.deleteQuery('not_logical_product_ids')
        } else {
            reqProducts.setQuery(
                'not_logical_product_ids',
                options.map(option => option.value)
            )
        }
        reqProducts.load()
    }

    return (
        <>
            <TableList control={productsControl} scrollHeight={500}>
                {(lProduct, control, e) => (
                    <tr key={lProduct.logical_product_id}>
                        <td>{e}</td>
                        <td>
                            <Typography
                                type="paragraph"
                                className={'font-bold'}
                            >
                                {lProduct.name}
                            </Typography>
                            <Typography type="small" color="gray">
                                {lProduct.code}
                            </Typography>
                        </td>
                        <td>{lProduct.presentation || '-'}</td>
                        <td>
                            <Quantity control={control} />
                        </td>
                        <td>{lProduct.stock}</td>
                        <td>
                            {storagePage.symbol}{' '}
                            {buildNumberFormat(lProduct.average)}
                        </td>
                        <Total control={control} />
                        <td>
                            <div className="group-btn-options">
                                <BtnActionTable
                                    title="Eliminar"
                                    iconName="bs-trash"
                                    onClick={() =>
                                        handleDelete(lProduct, e - 1)
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}

function Quantity({
    control
}: {
    control: FormEvents<LogicalProductForVoucher>
}) {
    const { props } = control.useInput('quantity')
    const { average } = control.store()

    function handleChange(ev) {
        props.onChange(ev)
        const value = ev.target.value
        control.setValue('total', average * parseInt(value || 0))
    }

    return (
        <NumeralField
            {...props}
            onChange={handleChange}
            placeholder="0"
            max={control.store().stock}
        />
    )
}

function Total({ control }: { control: FormEvents<LogicalProductForVoucher> }) {
    control.useInput('total')
    const { total } = control.store()
    return (
        <td>
            {storagePage.symbol} {buildNumberFormat(total)}
        </td>
    )
}
