import Checkbox from '../../../../components/Checkbox'
import { TableList } from '../../../../sections/ListForm'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import FormEvents from '../../../../utilities/FormEvents'
import { tableCompaniesControl } from '../../storage'
import { Step2 } from '../../types'

function Shop({ control }: { control: FormEvents<Step2.TableCompany> }) {
    const { props } = control.useCheck('is_enabled')

    return <Checkbox {...props} />
}

export default function TableCompanies() {
    return (
        <TableList control={tableCompaniesControl}>
            {(company, control) => (
                <tr key={company.id}>
                    <td>
                        <img src={company.image} className="w-8" />
                    </td>
                    <td>{buildCompanyName(company.name)}</td>
                    <td>
                        <Shop control={control} />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
