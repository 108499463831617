import { Row } from './types'

export const COLOR = {
    blue: '#CEE1F2',
    gray: '#cccccc',
    melon: '#fbe4d5',
    yellow: '#fef2cb'
}

export function addRowStyles(row: Row[], configs: any[]) {
    configs.forEach(config => {
        row = config(row)
    })
    return row
}

export function borderThin(row: Row[]) {
    return row.map(cell => {
        return <Row>{
            ...cell,
            borderStyle: 'thin'
        }
    })
}

export function backgroundBlue(row: Row[]) {
    return row.map(cell => {
        return <Row>{
            ...cell,
            backgroundColor: '#9EC3E6'
        }
    })
}

export function textBold(row: Row[]) {
    return row.map(cell => {
        return <Row>{
            ...cell,
            fontWeight: 'bold'
        }
    })
}
