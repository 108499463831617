import { CSSProperties } from 'react'
import { LdsEllipsis } from './Loading.style'

export default function Spinner({ style = {} }: { style: CSSProperties }) {
    return (
        <LdsEllipsis style={style}>
            <div />
            <div />
            <div />
            <div />
        </LdsEllipsis>
    )
}
