import Distribution from './Distribution'
import Settings from './Settings'

export default function SectionSettings() {
    return (
        <>
            <Settings />
            <Distribution />
        </>
    )
}
