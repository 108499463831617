import { Option } from '../../../../config/types/component'
import { ModalTools } from '../../../../sections/ModalSection/types'
import { formDispatch } from '../../storage'
import { Step3 } from '../../types'
import createEditionDispatch from '../../utilities/createEditionDispatch'
import eventsStep3 from '../../utilities/eventsStep3'
import {
    addressExist,
    dispatchExist,
    textAddressExist
} from '../../utilities/expectTableDispatch'
import FormDispatch from './FormDispatch'

export default function useDispatch(index?: number) {
    function handleSubmit(ev, modalState: ModalTools) {
        ev.preventDefault()
        const result = formDispatch.test((v, f) => {
            v.country.isEmpty('Se requiere seleccionar el país')
            v.type_dispatch.isEmpty(
                'Se requiere seleccionar el tipo de destino'
            )

            if (f.type_dispatch?.value === '4') {
                v.place_dispatch_text
                    .minLength(2, 'El número de caracteres válidos es 2 - 180')
                    .isEmpty('Se requiere ingresar el lugar de despacho')
                v.address
                    .minLength(2, 'El número de caracteres válidos es 2 - 3000')
                    .isEmpty('Se requiere ingresar la dirección')
                    .condition(
                        value =>
                            textAddressExist(value as string, f, index) &&
                            'Ya existe un registro con la misma dirección'
                    )
            } else {
                v.place_dispatch.isEmpty(
                    'Se requiere seleccionar el lugar de despacho'
                )
                v.place_dispatch.condition(value => {
                    if (value === null) return ''
                    const dispatch = dispatchExist(value as Option, index)

                    if (dispatch) {
                        if (f.type_dispatch.label !== 'Agente') {
                            return dispatch.address === f.address
                                ? 'Ya existe un registro con el mismo lugar de despacho'
                                : ''
                        }
                    }
                    return ''
                })
                v.place_dispatch.to('address_select').condition(value => {
                    if (value === null) return ''
                    const dispatch = dispatchExist(value as Option, index)

                    if (dispatch) {
                        if (f.type_dispatch.label === 'Agente') {
                            return dispatch.address === f.address_select.label
                                ? 'Ya existe un registro con la misma dirección'
                                : ''
                        }
                    }
                    return ''
                })
            }

            if (f.type_dispatch?.value === '1') {
                v.address_select.isEmpty('Se requiere seleccionar la dirección')
                v.address_select.condition(
                    value =>
                        addressExist(value as Option, index) &&
                        'Ya existe un registro con la misma dirección'
                )
            }
        })

        if (result) {
            createEditionDispatch(index)
            modalState.close()
        }
    }

    const openModal = formDispatch.useModal(FormDispatch, {
        title:
            index === undefined
                ? 'Nueva dirección de despacho'
                : 'Editar dirección de despacho',
        size: 'sm',
        onSubmit: handleSubmit
    })

    function handleOpen(data?: Step3.FormDispatch) {
        eventsStep3(index === undefined ? 'creation' : 'edition')
        openModal(data)
    }

    return handleOpen
}
