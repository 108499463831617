import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import useCancel from '../../hooks/useCancel'
import useStorage from '../../hooks/useStorage'
import { GetProvider } from '../../types/provider'
import { get } from '../../utilities/http'
import { formContact } from '../Agent/storage'
import {
    controlTabs,
    formConfig,
    formDispatch,
    formInfo,
    formWithdrawal,
    storagePage,
    tableCompaniesControl,
    tableContactsControl,
    tableDispatch,
    tableWithdrawal
} from './storage'
import initialCreate from './utilities/initialCreate'
import initialEdition from './utilities/initialEdition'

export default function useInitial() {
    const { provider_id } = useParams<{ provider_id?: string }>()
    const [pageState, setPageState] = useState<StateCase>(StateCase.LOADING)
    const { storage } = useStorage()
    const { onClick, init } = useCancel(
        [
            formInfo,
            tableContactsControl,
            formContact,
            tableCompaniesControl,
            formConfig,
            tableDispatch,
            formDispatch,
            tableWithdrawal,
            formWithdrawal
        ],
        '/configuration/providers'
    )

    useEffect(() => {
        if (provider_id) {
            storagePage.mode = 'edition'
            storagePage.providerId = parseInt(provider_id)
            get<GetProvider>(`/v5.6/apis/providers/${provider_id}`).then(
                result => {
                    if (result.error) {
                        setPageState(StateCase.ERROR)
                    } else {
                        storagePage.provider = result.data.result
                        initialEdition(storage)
                        init()
                        setPageState(StateCase.SUCCESS)
                    }
                }
            )
        } else {
            storagePage.mode = 'creation'
            initialCreate(storage)
            setPageState(StateCase.SUCCESS)
            init()
        }

        return () => {
            storagePage.provider = null
            storagePage.providerId = null
            storagePage.mode = 'creation'
            storagePage.step1Done = false
            storagePage.step2Done = false

            controlTabs.value = 'step1'

            formInfo.init()
            tableContactsControl.setList([])
            formContact.init()

            tableCompaniesControl.setList([])
            formConfig.init()

            tableDispatch.setList([])
            formDispatch.init()
            tableWithdrawal.setList([])
            formWithdrawal.init()
        }
    }, [])

    return {
        pageState,
        cancel: onClick
    }
}
