import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowNumberDeclaration(
    data: LogisticalDeclarationExcelData[]
) {
    const row: Row[] = [{ value: 'Nro. DUA/GUIA/MIA -   Declara' }]
    data.forEach(d => {
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'center',
            value: d.number_declaration
        })
        return row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
