import {
    Empty,
    FormControl,
    IterateOptions
} from '../../../../../components/FormUtilities'
import Option from '../../../../../components/Option'
import SelectTouch from '../../../../../components/SelectTouch'
import { formOC, storagePage } from '../../../storage'
import usePlaceDispatchPickUp from './usePlaceDispatchPickUp'
import useShippingWay from './useShippingWay'

export default function Fields2() {
    return (
        <>
            <ShippingWay />
            <PlaceDispatchPickUp />
            <Address />
        </>
    )
}

function ShippingWay() {
    const { props, shippingMethodPurchases, handleChange } = useShippingWay()

    return (
        <FormControl label="Forma de envío" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Forma de envío"
            >
                <IterateOptions data={shippingMethodPurchases} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function PlaceDispatchPickUp() {
    const { props, places, handleChange } = usePlaceDispatchPickUp()

    return (
        <FormControl label="Lugar de despacho/retiro" required>
            <SelectTouch
                disabled
                {...props}
                onChange={handleChange}
                placeholder="Lugar de despacho/retiro"
            >
                {places.map((place, i) => (
                    <Option key={i} label={place.locate} value={place.locate} />
                ))}
                <Empty data={places} />
            </SelectTouch>
        </FormControl>
    )
}

function Address() {
    const { props } = formOC.useSelect('address')

    return (
        <FormControl label="Dirección" col="span-6" required>
            <SelectTouch
                disabled
                {...props}
                placeholder="Seleccionar dirección"
            >
                <IterateOptions data={storagePage.addresses} label="address" />
            </SelectTouch>
        </FormControl>
    )
}
