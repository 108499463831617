import Rules from '../../../components/Rules'
import Brand from '../../../models/Brand'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { controlList } from '../storage'

interface PropsBrandADe {
    brand: Brand
}

export default function BrandActivateDeactivate({ brand }: PropsBrandADe) {
    return (
        <Rules cu={brand.is_enabled ? 'CU038' : 'CU039'}>
            <ActivateDeactivate
                isEnabled={brand.is_enabled}
                control={controlList}
                body={{
                    brand_id: brand.id
                }}
            >
                <Active
                    path={`/v5.6/apis/brands/activate`}
                    label="Activar marca"
                >
                    ¿Estás seguro de activar la marca{' '}
                    <b className="text-primary">{brand.name}</b>?
                </Active>
                <Deactive
                    path={`/v5.6/apis/brands/deactivate`}
                    label="Desactivar marca"
                >
                    ¿Estás seguro de desactivar la marca{' '}
                    <b className="text-primary">{brand.name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
