import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { RequisitionView } from '../../types'

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    rowHeader: {
        backgroundColor: '#F6F6F6',
        flexDirection: 'row',
        alignItems: 'center'
    },
    header1: {
        width: '30%',
        fontSize: '9px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header2: {
        width: '6%',
        fontSize: '8px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header3: {
        width: '14%',
        fontSize: '9px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header4: {
        width: '20%',
        fontSize: '9px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    item1: {
        width: '31%',
        fontSize: '9px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    item2: {
        width: '6%',
        fontSize: '9px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    item3: {
        width: '14%',
        fontSize: '9px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    item4: {
        width: '18%',
        fontSize: '9px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    caption: {
        width: '100%',
        fontSize: '9px',
        padding: '5px',
        textAlign: 'center'
    }
})

export default function Table2({ data }: { data: RequisitionView }) {
    return (
        <>
            <View style={styles.rowHeader}>
                <Text style={styles.header2}>#</Text>
                <Text style={styles.header4}>
                    {'Código de \n producto (ERP)'}
                </Text>
                <Text style={styles.header1}>
                    Descripción de producto (ERP)
                </Text>
                <Text style={styles.header1}>
                    {'Descripción de producto \n (Proveedor)'}
                </Text>
                <Text style={styles.header3}>{'Cantidad \n solicitada'}</Text>
            </View>
            {data.classic_requisition_products
                .filter(
                    crp =>
                        crp.requisition_product.is_enabled &&
                        !crp.requisition_product.deleted
                )
                .map((item, i) => (
                    <View style={styles.row} key={i}>
                        <Text style={styles.item2}>{i + 1}</Text>
                        <Text style={styles.item4}>
                            {item.requisition_product.product.code}
                        </Text>
                        <Text style={styles.item1}>
                            {item.requisition_product.product.description}
                        </Text>
                        <Text style={styles.item1}>
                            {`${item.requisition_product.product_provider_company.provider_description} ${item.requisition_product.product_provider_company.provider_code}`}
                        </Text>
                        <Text style={styles.item3}>
                            {item.requisition_product.quantity}
                        </Text>
                    </View>
                ))}
            {data.classic_requisition_products.length === 0 && (
                <View style={styles.row}>
                    <Text style={styles.caption}>
                        No se encontraron resultados
                    </Text>
                </View>
            )}
        </>
    )
}
