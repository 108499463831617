import { useHistory } from 'react-router-dom'
import { put } from '../../../../utilities/http'
import { Popup } from '../../../../utilities/popup'
import submit from '../../../../utilities/submit'
import { storagePage } from '../../storage'

export default function useSubmit4() {
    const { push } = useHistory()

    async function handleSubmit() {
        const { logistical_declaration, mode, version } = storagePage

        const { error, success } = await submit(
            `/v5.6/apis/logistical_declarations/${logistical_declaration.id}/save_logistical_costs`,
            {
                body: {
                    version
                },
                method: 'put',
                confirm: '¿Está seguro de guardar cambios?'
            }
        )

        if (error) {
            Popup.error(
                'Algo salió mal...',
                'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
            )
        }

        if (success) {
            push('/costs/costing')
            if (mode === 'edition' && logistical_declaration.document_id) {
                await put('/v5.6/apis/logistical_declarations/run_costs', {
                    logistical_declaration_id: logistical_declaration.id
                })
            }
        }
    }

    return handleSubmit
}
