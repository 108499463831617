import Checkbox from '../../../../components/Checkbox'
import Typography from '../../../../components/Typography'
import UploadField from '../../../../components/UploadField'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { tablePermitsControl } from '../../storage'
import { TableFormPermit } from '../../types'

interface PropsField {
    control: FormEvents<TableFormPermit>
}

function Nationalization({ control }: PropsField) {
    const { props } = control.useCheck('nationalization_permission')
    return <Checkbox {...props} />
}

function Shipment({ control }: PropsField) {
    const { props } = control.useCheck('shipment_permission')
    return <Checkbox {...props} />
}

function Ministry({ control }: PropsField) {
    const { props } = control.useCheck('ministry_health_permission')
    const { props: propsUF } = control.useUploads('new_files')
    return (
        <div className="flex items-center">
            <Checkbox {...props} className="mr-2" />
            <UploadField
                {...propsUF}
                disabled={!props.checked}
                maxSizeFile={10000000}
            />
        </div>
    )
}

export default function TablePermits() {
    return (
        <TableList control={tablePermitsControl}>
            {(data, control, e) => (
                <tr key={e}>
                    <td>
                        <div className="flex items-center">
                            <img src={data.image} className="mr-2 w-7" />
                            <Typography>{data.country_name}</Typography>
                        </div>
                    </td>
                    <td>
                        <Nationalization control={control} />
                    </td>
                    <td>
                        <Shipment control={control} />
                    </td>
                    <td>
                        <Ministry control={control} />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
