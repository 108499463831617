import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowEmpty(
    name: string,
    data: LogisticalDeclarationExcelData[],
    border = true
) {
    let row: Row[] = [{ value: name }]

    data.forEach(() => {
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    if (border) {
        row = addRowStyles(row, [borderThin])
    }
    return row
}
