import { ItemRoute } from './types'
import PurchaseOrderForm from '../../pages/PurchaseOrder'
import PurchaseOrders from '../../pages/PurchaseOrders'

const PurchaseOrder: ItemRoute[] = [
    {
        title: 'Órdenes de compra',
        Component: PurchaseOrders,
        path: '/',
        cu: 'CU243',
        routes: [
            {
                title: 'Nueva orden de compra',
                cu: 'CU244',
                path: '/creation',
                Component: PurchaseOrderForm
            },
            {
                title: 'Editar Orden de Compra',
                cu: 'CU245',
                path: '/:purchase_id/edition',
                Component: PurchaseOrderForm
            }
        ]
    }
]

export default PurchaseOrder
