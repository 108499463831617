import Badge from '../../../components/Badge'
import { PropsEnable } from '../../../components/Enable/types'

export default function IsActive({ enabled, className = '' }: PropsEnable) {
    return (
        <Badge color={enabled ? 'success' : 'error'} className={className}>
            {enabled ? 'Emitido' : 'Anulado'}
        </Badge>
    )
}
