import { memo, useState } from 'react'
import { Empty, FormControl } from '../../../../../../components/FormUtilities'
import Option from '../../../../../../components/Option'
import SelectTouch from '../../../../../../components/SelectTouch'
import { Option as OptionType } from '../../../../../../config/types/component'
import useGet from '../../../../../../hooks/useGet'
import LogisticalConcept from '../../../../../../models/LogisticalConcept'

function FieldConcept({
    option,
    onChange,
    helper,
    isDisabled
}: FieldConceptProps) {
    const { data } = useGet<LogisticalConcept[]>(
        '/v5.6/apis/search_logistical_concepts',
        []
    )
    const [search, setSearch] = useState('')

    const logistical_concept_ids = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    const logistical_concepts = data.result
        .filter(
            lc =>
                !logistical_concept_ids.find(id => lc.id === id) &&
                lc.is_enabled
        )
        .filter(
            lc => lc.name.toLowerCase().search(search.trim().toLowerCase()) > -1
        )

    return (
        <FormControl label="Concepto" required col="span-3">
            <SelectTouch
                placeholder="Seleccionar concepto"
                onChange={onChange}
                option={option}
                helper={helper}
                disabled={isDisabled}
                search={search}
                onSearch={ev => setSearch(ev.target.value)}
            >
                {logistical_concepts.map(lc => (
                    <Option
                        key={lc.id}
                        label={lc.name}
                        value={lc.id.toString()}
                    />
                ))}
                <Empty data={logistical_concepts} />
            </SelectTouch>
        </FormControl>
    )
}

export default memo(FieldConcept)

export interface FieldConceptProps {
    option: OptionType
    onChange: (option: OptionType) => void
    helper: string
    isDisabled: boolean
}
