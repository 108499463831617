import { useEffect } from 'react'
import { GetProvider } from '../../../../types/provider'
import ControlTabs from '../../../../utilities/ControlTabs'
import Address from './Address'
import Config from './Config'
import Info from './Info'

const adminSection = new ControlTabs()

adminSection.setSection('Información General', Info)
adminSection.setSection('Configuraciones', Config)
adminSection.setSection('Direcciones Despacho/Retiro', Address)

export default function InfoProvider({ data }: { data: GetProvider }) {
    const value = adminSection.useStep()

    useEffect(() => {
        adminSection.value = 'step1'
    })

    return (
        <adminSection.SectionTabs
            value={value}
            type="tab"
            mode="simple"
            className="mb-4"
            data={data}
        />
    )
}
