import Icon from '../../../../components/Icon'
import Tooltip from '../../../../components/Tooltip'
import { tableAddress } from '../../storage'

export default function TooltipAddress() {
    const { message, findError } = tableAddress.useHelpers()
    const helper = findError(0)(
        'ERROR-0000139',
        null,
        'agent_withdrawal_address'
    )
    return (
        <Tooltip content={message || helper}>
            {(message || helper) && (
                <Icon name="warning" className="text-red-500" />
            )}
        </Tooltip>
    )
}
