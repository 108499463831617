import { memo } from 'react'
import { FormControl } from '../../../../../../components/FormUtilities'
import NumeralField from '../../../../../../components/NumeralField'
import { storagePage } from '../../../../storage'

function FieldExchangeRate({
    value,
    onChange,
    symbol,
    helper
}: FieldExchangeRateProps) {
    return (
        <FormControl
            label={`Tipo de cambio (${symbol} a ${storagePage.currentCurrency.symbol})`}
            required
            col="span-2"
        >
            <NumeralField
                value={value}
                decimal={4}
                onChange={ev => onChange(ev.target.value)}
                helper={helper}
            />
        </FormControl>
    )
}

export default memo(FieldExchangeRate)

export interface FieldExchangeRateProps {
    value: string
    onChange: (value: string) => void
    symbol: string
    helper: string
}
