import ProductCategory from '../../models/ProductCategory'
import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'

export const controlList = new ControlList<ProductCategory>(Keys.MAIN, {
    path: '/v5.6/apis/categories',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'name', label: 'Categoría' },
        'Acciones'
    ]
})

export const formCategory = new FormEvents(Keys.MODAL_FORM, {
    name: ''
})
