import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import { FormControls } from '../../../components/FormUtilities'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'
import GoogleMap from './GoogleMap'
import useInitial from '../useInitial'
import useSubmit from './../useSubmit'

export default function Warehouse() {
    const submit = useSubmit()
    const { pageState, cancel } = useInitial()

    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title="Información de almacén" />
                    <PanelBody>
                        <FormControls>
                            <Fields1 />
                            <Fields2 />
                            <GoogleMap />
                        </FormControls>
                    </PanelBody>
                </Panel>
                <Panel mode="form">
                    <PanelHeader title="Relación con compañias" />
                    <PanelBody>
                        <FormControls>
                            <Fields3 />
                        </FormControls>
                    </PanelBody>
                </Panel>
            </div>
            <PageHeader subtitle="(*) Complete todos los campos para el almacén">
                <Button color="ghost" onClick={cancel}>
                    Cancelar
                </Button>
                <Button className="ml-2" onClick={() => submit()}>
                    Guardar
                </Button>
            </PageHeader>
        </Initial>
    )
}
