import Dropdown from '../../../../components/Dropdown'
import Option from '../../../../components/Option'
import { Option as OptionType } from '../../../../config/types/component'
import useStorage from '../../../../hooks/useStorage'
import Company from '../../../../models/Company'
import { setCompanySelected } from '../../../../utilities/controlStorage'
import { productPriceList } from '../../storage'

export function CompanyFilter() {
    const { props } = productPriceList.useFilter<Company>('company_id')
    const { companiesFromSession } = useStorage()

    function handleChange(option: OptionType) {
        props.onChange(option)
        const company = companiesFromSession.find(
            c => c.id.toString() === option.value
        )
        setCompanySelected(company)
    }

    return (
        <Dropdown
            {...props}
            label="Compañía"
            showLabel={false}
            onChange={handleChange}
        >
            {companiesFromSession.map(company => (
                <Option
                    key={company.id}
                    value={company.id.toString()}
                    label={company.name}
                    image={company.country.image_url}
                    strikeOut={!company.is_enabled}
                />
            ))}
        </Dropdown>
    )
}
