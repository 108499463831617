import { ItemRoute } from './types'
import Categories from '../../pages/Categories'
import Subcategories from '../../pages/Subcategories'
import Brands from '../../pages/Brands'
import Licenses from '../../pages/Licenses'
import Products from '../../pages/Products'
import Presentations from '../../pages/Presentations'
import Agents from '../../pages/Agents'
import Providers from '../../pages/Providers'
import Warehouses from '../../pages/Warehouses'
import Stowages from '../../pages/Stowages'
import PaymentConcept from '../../pages/PaymentConcept'
import DiscountFactor from '../../pages/DiscountFactor'
import License from '../../pages/License'
import ProductLicense from '../../pages/ProductLicense'
import LicenseHistory from '../../pages/LicenseHistory'
import ImportProducts from '../../pages/ImportProducts'
import Warehouse from '../../pages/Warehouse'
import Agent from '../../pages/Agent'
import Provider from '../../pages/Provider'
import FinancialInformation from '../../pages/FinancialInformation'
import Product from '../../pages/Product'
import ProviderCatalog from '../../pages/ProviderCatalog'

const Configuration: ItemRoute[] = [
    {
        cu: 'CU035',
        label: 'Marcas',
        title: 'Marcas',
        path: '/brands',
        Component: Brands
    },
    {
        cu: 'CU041',
        label: 'Categorías',
        title: 'Categorías',
        path: '/categories',
        Component: Categories
    },
    {
        cu: 'CU045',
        label: 'Subcategorías',
        title: 'Subcategorías',
        path: '/subcategories',
        Component: Subcategories,
        routes: [
            {
                cu: 'CU050',
                title: 'Factor Descuento',
                path: '/:subcategory_id/factor',
                Component: DiscountFactor
            }
        ]
    },
    {
        label: 'Licencias',
        title: 'Licencias',
        cu: 'CU052',
        path: '/licenses',
        Component: Licenses,
        routes: [
            {
                cu: 'CU053',
                title: 'Nueva Licencia',
                path: '/create',
                Component: License
            },
            {
                cu: 'CU054',
                title: 'Editar Licencia',
                path: '/:license_id/edition',
                Component: License
            },
            {
                cu: 'CU059',
                title: 'Renovar Licencia',
                path: '/:license_id_for_renovate/renovate',
                Component: License
            },
            {
                cu: 'CU058',
                title: 'Licencia',
                path: '/:license_id/products',
                Component: ProductLicense
            },
            {
                cu: 'CU072',
                title: 'Licencia',
                path: '/:license_id/history',
                Component: LicenseHistory
            }
        ]
    },
    {
        label: 'Productos',
        title: 'Productos',
        cu: 'CU063',
        path: '/products',
        Component: Products,
        routes: [
            {
                title: 'Nuevo Producto',
                cu: 'CU064',
                path: '/creation',
                Component: Product
            },
            {
                title: 'Editar Producto',
                cu: 'CU065',
                path: '/:product_id/edition',
                Component: Product
            },
            {
                title: 'Importar productos',
                path: '/imports',
                cu: 'CU291',
                Component: ImportProducts
            }
        ]
    },
    {
        cu: 'CU067',
        label: 'Presentaciones',
        title: 'Presentaciones',
        path: '/presentations',
        Component: Presentations
    },
    {
        label: 'Agentes',
        title: 'Agentes',
        cu: 'CU074',
        path: '/agents',
        Component: Agents,
        routes: [
            {
                cu: 'CU075',
                title: 'Nuevo Agente',
                path: '/creation',
                Component: Agent
            },
            {
                cu: 'CU076',
                title: 'Editar Agente',
                path: '/:agent_id/edition',
                Component: Agent
            }
        ]
    },
    {
        label: 'Proveedores',
        title: 'Proveedores',
        cu: 'CU082',
        path: '/providers',
        Component: Providers,
        routes: [
            {
                cu: 'CU083',
                title: 'Nuevo Proveedor',
                path: '/creation',
                Component: Provider
            },
            {
                cu: 'CU084',
                title: 'Editar Proveedor',
                path: '/:provider_id/edition',
                Component: Provider
            },
            {
                cu: 'CU081',
                title: 'Información Financiera',
                path: '/:provider_id/financial',
                Component: FinancialInformation
            },
            {
                cu: 'CU109',
                title: 'Catálogo Proveedor',
                path: '/:provider_id/catalog',
                Component: ProviderCatalog
            }
        ]
    },
    {
        label: 'Almacenes',
        title: 'Almacenes',
        cu: 'CU094',
        path: '/warehouses',
        Component: Warehouses,
        routes: [
            {
                cu: 'CU095',
                title: 'Nuevo Almacén',
                path: '/creation',
                Component: Warehouse
            },
            {
                cu: 'CU096',
                title: 'Editar Almacén',
                path: '/:warehouse_id/edition',
                Component: Warehouse
            }
        ]
    },
    {
        label: 'Bodegas',
        title: 'Bodegas',
        cu: 'CU099',
        path: '/stowages',
        Component: Stowages
    },
    {
        cu: 'CU237',
        label: 'Conceptos de pago',
        title: 'Conceptos de pago',
        path: '/payment_concepts',
        Component: PaymentConcept
    }
]

export default Configuration
