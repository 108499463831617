import { FormControl, FormControls } from '../../../../components/FormUtilities'
import NumeralField from '../../../../components/NumeralField'
import { formSaleDiscounts, storagePage } from './../../storage'

function CostProvider() {
    const { props } = formSaleDiscounts.useInput('cost_provider')
    const prefix = formSaleDiscounts.store().symbol
    return (
        <FormControl label="Costo proveedor" col="span-3">
            <NumeralField prefix={prefix} {...props} readOnly readNumber />
        </FormControl>
    )
}

function InventoryBarrier() {
    const { props } = formSaleDiscounts.useInput('inventory_barrier')
    const { modeStep2 } = storagePage

    return (
        <FormControl label="Barrera de inventario" col="span-3" required>
            <NumeralField
                {...props}
                disabled={modeStep2 === 'view'}
                placeholder="Ingresar barrera de inventario"
            />
        </FormControl>
    )
}

export default function Fields() {
    return (
        <FormControls className="mb-4 mt-2">
            <CostProvider />
            <InventoryBarrier />
        </FormControls>
    )
}
