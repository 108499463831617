import moment from 'moment'
import { ListFormControl } from '../../sections/ListForm'
import { GetLocateAddress } from '../../types/locate'
import ControlRequest from '../../utilities/ControlRequest'
import ControlTabs from '../../utilities/ControlTabs'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { FormOC, StoragePage, TableRQ } from './types'

export const storagePage: StoragePage = {
    addresses: [],
    step1Done: false,
    purchase: null,
    mode: 'creation',
    req_products_actived: {},
    timezone: ''
}

export const formOC = new FormEvents<FormOC>(Keys.FORM, {
    billing_company: null,
    destination_company: null,
    provider: null,
    type_oc: '',
    priority: null,
    date: moment().toISOString(true),
    payment_condition: '',
    comment: '',
    shipping_type: null,
    country_dispatch_withdrawal: null,
    shipping_way: null,
    place_dispatch_pick_up: null,
    address: null
})

export const ct = new ControlTabs()

export const tableRequisition = new ListFormControl<TableRQ>(
    Keys.TABLE_REQUISITION,
    [
        '#',
        'Requisición',
        'Bodega de destino',
        'Descripción de producto (Proveedor)',
        'Días abiertos',
        'Alertas',
        'Cant. pendiente de RQ',
        'Cant. a comprar',
        'Precio proveedor',
        'Acciones'
    ]
)

export const reqRequisition = new ControlRequest()
export const reqProviders = new ControlRequest()

export const formQuantity = new FormEvents('s', {
    quantity: ''
})

export const formPrice = new FormEvents('s', {
    price: ''
})

export const reqDeliveryCountry = new ControlRequest()
export const reqLocate = new ControlRequest<GetLocateAddress[]>()
