import Button from '../../../components/Button'
import useGet from '../../../hooks/useGet'
import xls from '../../../assets/img/xls.svg'
import { ProductHeaders } from '../types'
import writeXlsxFile, { Schema } from 'write-excel-file'
import { storagePage } from '../storage'
import Rules from '../../../components/Rules'

export default function DownloadTemplate() {
    const { data, error } = useGet<ProductHeaders>(
        '/v5.6/apis/products/headers'
    )

    if (!error && data.result) {
        storagePage.fields = data.result.fields
    }

    async function download() {
        const schema: Schema<any> = data.result.fields.map(value => ({
            column: value,
            type: String,
            width: 20,
            value: d => {
                return d.name // ?
            }
        }))

        await writeXlsxFile([], {
            fileName: 'plantilla.xlsx',
            schema
        })
    }

    return (
        <Rules cu="CU292">
            <Button
                color="success"
                className="w-full mt-4 border-success text-success hover:bg-success hover:border-success"
                outline
                onClick={() => download()}
            >
                <img src={xls} width="24" height="24" className="mr-2" />
                Descargar plantilla
            </Button>
        </Rules>
    )
}
