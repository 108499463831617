const headers = new Headers()
headers.append('Content-Type', 'application/json')
headers.append('Accept', 'application/json')
headers.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
headers.append('Access-Control-Allow-Origin', '*')
const token = localStorage.getItem('token')
if (token) {
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
}

export default headers
