import classNames from 'classnames'
import { PropsCol } from './types'

export default function Col({
    children,
    className = '',
    cols = '2'
}: PropsCol) {
    return (
        <div className={classNames(`grid grid-cols-${cols} gap-4`, className)}>
            {children}
        </div>
    )
}
