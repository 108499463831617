import BtnActionTable from '../../../../components/BtnActionTable'
import Toggle from '../../../../components/Toggle'
import { TableList } from '../../../../sections/ListForm'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import FormEvents from '../../../../utilities/FormEvents'
import { Popup } from '../../../../utilities/popup'
import { tableDispatch } from '../../storage'
import { Step3 } from '../../types'
import EditDispatch from './EditDispatch'

function Main({
    control,
    index
}: {
    control: FormEvents<Step3.TableDispatch>
    index: number
}) {
    const { props } = control.useCheck('is_main')
    const { country, enabled } = control.store()
    function handleChange(ev: any) {
        props.onChange(ev)
        const checked = ev.target.checked as boolean
        if (checked) {
            tableDispatch.list.forEach((data, i) => {
                if (data.store().country.id === country.id && index !== i) {
                    data.setValue('is_main', false)
                }
            })
        } else {
            const dispatchFinded = tableDispatch.list.find(
                (data, i) =>
                    data.store().country.id === country.id && index !== i
            )

            if (dispatchFinded) {
                dispatchFinded.setValue('is_main', true)
            }
        }
    }

    return <Toggle {...props} onChange={handleChange} disabled={!enabled} />
}

export default function TableDispatch() {
    async function handleDel(index) {
        const dispatch = tableDispatch.get(index)
        if (dispatch.id) {
            const result = await Popup.delete(
                'Eliminar registro',
                <>
                    Se removerá la dirección de despacho de la lista ¿Está
                    seguro de eliminar la dirección destino{' '}
                    <b>{dispatch.place_dispatch}</b>?
                </>
            )
            if (!result) return
        }

        const dispatchDeleted = tableDispatch.del(index)
        if (dispatchDeleted.is_main) {
            const dispatchIndex = tableDispatch
                .getDataList()
                .findIndex(
                    data => data.country.id === dispatchDeleted.country.id
                )
            dispatchIndex > -1 &&
                tableDispatch.put(dispatchIndex, { is_main: true })
        }
    }

    return (
        <TableList control={tableDispatch} scrollHeight={250}>
            {(dispatch, control, e) => (
                <tr key={e}>
                    <td>
                        <img
                            src={dispatch.country.image_url}
                            className="w-8 max-w-min"
                        />
                    </td>
                    <td>
                        {dispatch.type_dispatch.name === 'Compañía'
                            ? buildCompanyName(dispatch.place_dispatch)
                            : dispatch.place_dispatch}
                    </td>
                    <td>{dispatch.type_dispatch.name}</td>
                    <td>
                        <Main control={control} index={e - 1} />
                    </td>
                    <td>{dispatch.address}</td>
                    <td>
                        <div className="group-btn-options">
                            <EditDispatch dispatch={dispatch} index={e - 1} />
                            <BtnActionTable
                                title="Eliminar"
                                iconName="bs-trash"
                                disabled={!dispatch.enabled}
                                onClick={() => handleDel(e - 1)}
                            />
                        </div>
                    </td>
                </tr>
            )}
        </TableList>
    )
}
