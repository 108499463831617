import { Option } from '../../../../../config/types/component'
import useStorage from '../../../../../hooks/useStorage'
import { formOC, reqDeliveryCountry, reqLocate } from '../../../storage'

export default function useShippingType() {
    const { props } = formOC.useSelect('shipping_type')
    const { shippingTypePurchases } = useStorage()

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value === '0') {
            formOC.setProps(
                'country_dispatch_withdrawal',
                {
                    disabled: true
                },
                false
            )
            reqDeliveryCountry.deleteQuery('shipping_type_purchase_id')
            reqLocate.deleteQuery('mode_address')
        } else {
            reqDeliveryCountry.setQuery(
                'shipping_type_purchase_id',
                option.value
            )

            if (reqDeliveryCountry.hasParam('provider_id')) {
                reqDeliveryCountry.load()
                formOC.setProps(
                    'country_dispatch_withdrawal',
                    {
                        disabled: false
                    },
                    false
                )
            }

            if (option.value === '1') {
                reqLocate.setParam('mode_address', 'dispatch_addresses')
            } else {
                reqLocate.setParam('mode_address', 'withdrawal_addresses')
            }
        }

        formOC.setValue('country_dispatch_withdrawal', null)

        formOC.setProps(
            'place_dispatch_pick_up',
            {
                disabled: true
            },
            false
        )
        formOC.setValue('place_dispatch_pick_up', null)
        formOC.setValue('address', null)
    }

    return {
        handleChange,
        props,
        shippingTypePurchases
    }
}
