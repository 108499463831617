import 'moment/locale/es'
import Typography from '../../../../components/Typography'
import { TableList } from '../../../../sections/ListForm'
import { formTraspase, productsControl, reqProducts } from '../../storage'
import { TableProductTraspase } from '../../types'
import FormEvents from '../../../../utilities/FormEvents'
import NumeralField from '../../../../components/NumeralField'
import BtnActionTable from '../../../../components/BtnActionTable'

export default function TraspaseView() {
    function handleDelete(lProduct: TableProductTraspase, index) {
        productsControl.del(index)
        const { traspase_products } = formTraspase.store()
        const options = traspase_products.filter(
            tp => parseInt(tp.value) !== lProduct.logical_product_id
        )
        formTraspase.setValue('traspase_products', options)
        // if (options.length === 0) {
        // reqProducts.deleteQuery('not_logical_product_ids')
        // } else {
        // reqProducts.setQuery(
        //     'not_logical_product_ids',
        //     options.map(op => op.value)
        // )
        // }
        reqProducts.load()
    }

    return (
        <div style={{ minHeight: '250px' }}>
            <TableList scrollHeight={250} control={productsControl}>
                {(lProduct, control, e) => (
                    <tr key={lProduct.id}>
                        <td>{e}</td>
                        <td>
                            <Typography
                                type="paragraph"
                                className={'font-bold'}
                            >
                                {lProduct.name}
                            </Typography>
                            <Typography type="small" color="gray">
                                {lProduct.code}
                            </Typography>
                        </td>
                        <td>
                            <Amount control={control} />
                        </td>
                        <td>{lProduct.quantity_available}</td>
                        <td>
                            <div className="group-btn-options">
                                <BtnActionTable
                                    title="Eliminar"
                                    iconName="bs-trash"
                                    onClick={() =>
                                        handleDelete(lProduct, e - 1)
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
        </div>
    )
}

function Amount({ control }: { control: FormEvents<TableProductTraspase> }) {
    const { props } = control.useInput('amount_to_transfer')

    return (
        <NumeralField
            {...props}
            placeholder="0"
            max={control.store().quantity_available}
        />
    )
}
