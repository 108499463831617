import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import Fields from './Fields'
import TableProducts from './TableProducts'
import useInitial from '../useInitial'
import useSubmit from '../useSubmit'

export default function ProductLicense() {
    const { pageState, cancel } = useInitial()
    const handleSubmit = useSubmit()

    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title="Asignar productos válidos para licencia" />
                    <PanelBody>
                        <Fields />
                        <TableProducts />
                    </PanelBody>
                </Panel>
            </div>
            <PageHeader subtitle="(*) Complete todos los campos para la licencia">
                <Button color="ghost" onClick={cancel}>
                    Cancelar
                </Button>
                <Button className="ml-2" onClick={handleSubmit}>
                    Guardar
                </Button>
            </PageHeader>
        </Initial>
    )
}
