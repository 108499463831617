import moment from 'moment'
import { render } from 'redity'
import { Option } from '../../../../config/types/component'
import useStorage from '../../../../hooks/useStorage'
import { setCompanySelected } from '../../../../utilities/controlStorage'
import {
    formTraspase,
    productsControl,
    reqProducts,
    reqStowageOrigin,
    reqWarehouse,
    storagePage
} from '../../storage'

export default function useCompany() {
    const { props } = formTraspase.useSelect('company')
    const { companiesFromSession } = useStorage()

    const data = companiesFromSession
        .filter(company => company.memory)
        .map(company => ({
            id: company.id,
            name: company.name,
            image: company.country.image_url
        }))

    function handleChange(option: Option) {
        if (option.value === '0') {
            formTraspase.setProps('warehouse', { disabled: true }, false)
            formTraspase.setProps('stowage_origin', { disabled: true }, false)
            formTraspase.setProps(
                'stowage_destination',
                { disabled: true },
                false
            )
            formTraspase.setProps(
                'sub_stowage_origin',
                { disabled: true },
                false
            )
            formTraspase.setProps(
                'sub_stowage_destination',
                { disabled: true },
                false
            )
            formTraspase.setProps('traspase_products', {
                disabled: true
            })
        } else {
            const company = companiesFromSession.find(
                _cp => _cp.id === parseInt(option.value)
            )
            setCompanySelected(company)
            reqWarehouse.setQuery('company_id', option.value)
            reqWarehouse.load()
            reqStowageOrigin.setQuery('company_id', option.value)
            reqStowageOrigin.load().then(response => {
                if (response.data) {
                    // storagePage.stowages_origin = response.data.result
                    render(formTraspase.keyRender, 'stowage_origin')
                }
            })
            formTraspase.setProps('warehouse', { disabled: false }, false)
            formTraspase.setProps('stowage_origin', { disabled: false }, false)
        }
        storagePage.timezone = companiesFromSession.find(
            c => c.id === parseInt(option.value)
        ).timezone
        formTraspase.setValue('warehouse', null)
        formTraspase.setValue('stowage_origin', null)
        formTraspase.setValue('stowage_destination', null)
        formTraspase.setValue('sub_stowage_origin', null)
        formTraspase.setValue('sub_stowage_destination', null)
        formTraspase.setValue('traspase_products', [])
        productsControl.setList([])
        reqProducts.deleteQuery('not_logical_product_ids')

        const date = moment().tz(storagePage.timezone).parseZone()
        formTraspase.setValue('date_request', date.toISOString(true))
        render('badge_label')

        productsControl.renderMain()
        props.onChange(option)
    }

    return { props, handleChange, companies: data }
}
