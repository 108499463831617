import { Link } from 'react-router-dom'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import Rules from '../../../components/Rules'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import useStorage from '../../../hooks/useStorage'
import { controlList } from '../storage'
import { Option as OptionType } from '../../../config/types/component'
import { setCompanySelected } from '../../../utilities/controlStorage'

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por nombre de la licencia"
            size="auto"
            fullWidth={true}
        />
    )
}

function Company() {
    const { props } = controlList.useFilter('company_id')
    const { companiesFromSession } = useStorage()

    function handleChange(option: OptionType) {
        props.onChange(option)
        const company = companiesFromSession.find(
            comp => comp.id === parseInt(option.value)
        )
        setCompanySelected(company)
    }

    return (
        <Dropdown
            {...props}
            label="Compañía"
            showLabel={false}
            onChange={handleChange}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    ...company,
                    image_url: company.country.image_url,
                    strikeOut: !company.memory
                }))}
                label="name"
                image="image_url"
                strikeOut="strikeOut"
            />
        </Dropdown>
    )
}

export default function FilterLicenses() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Company />
                </FlexLeft>
                <FlexRight>
                    <Rules cu="CU053">
                        <Link to="/configuration/licenses/create">
                            <Button size="sm">Nuevo</Button>
                        </Link>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
