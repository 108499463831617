import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import FilterProducts from './FilterProducts'
import TableProducts from './TableProducts'

export default function Products() {
    const state = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterProducts />
                    <TableProducts />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
