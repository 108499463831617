import buildCompanyName from '../../../utilities/buildCompanyName'
import { data } from '../storage'
import { DataTable } from '../types'

let role_company_ids = []
let department_with_companies = []

function assignRoles(
    roles: DataTable['roles'],
    department_id: number,
    cduOptions: any
) {
    const cdus = data.companyDepartmentUsers.filter(
        cdu => cdu.company_department.department.id === department_id
    )
    if (cdus) {
        const data_roles = cdus[0].company_department.department.roles
        for (const data_role of data_roles) {
            const role = { ...data_role }
            const _cduOptions = []

            for (const cdu of cdus) {
                // Load cdu ID
                let cdu_option = {
                    ...cduOptions.find(
                        option =>
                            option.company_id ===
                            cdu.company_department.company.id
                    ),
                    id: cdu.id
                }
                // Load is_enabled and cdru_id
                const role_company_id = role_company_ids.find(
                    x =>
                        x.company_id === cdu_option.company_id &&
                        x.role_id === role.id
                )
                if (role_company_id) {
                    cdu_option = {
                        ...cdu_option,
                        company_department_role_user_id:
                            role_company_id.cdru_id,
                        is_enabled: role_company_id.is_enabled
                    }
                }
                _cduOptions.push(cdu_option)
            }
            roles.push({
                ...role,
                cduOptions: _cduOptions
            })
        }
    }
}

function load_department_with_companies() {
    department_with_companies = []
    let department_ids = data.companyDepartmentUsers.map(cdu => {
        return cdu.company_department.department.id
    })
    department_ids = department_ids.filter((value, index) => {
        return department_ids.indexOf(value) === index
    })
    for (const department_id of department_ids) {
        department_with_companies.push({
            department_id: department_id,
            companies: data.companyDepartmentUsers
                .filter(
                    cdu =>
                        cdu.company_department.department.id === department_id
                )
                .map(cdu => {
                    return cdu.company_department.company
                })
        })
    }
}

function load_role_company_ids() {
    role_company_ids = []
    if (data.user) {
        for (const cdu of data.user.company_department_users) {
            const company_id = cdu.company_department.company_id
            for (const cdru of cdu.company_department_role_users) {
                role_company_ids.push({
                    company_id: company_id,
                    role_id: cdru.role.id,
                    cdru_id: cdru.id,
                    is_enabled: cdru.is_enabled
                })
            }
        }
    }
}

export default function getListTables() {
    load_department_with_companies()
    load_role_company_ids()
    const listTables: Array<DataTable> = []
    for (const cdu of data.companyDepartmentUsers) {
        if (
            !listTables.find(
                lt => lt.department.id === cdu.company_department.department.id
            )
        ) {
            listTables.push({
                company_department_user_id: cdu.id,
                department: cdu.company_department.department,
                roles: []
            })
        }
    }

    for (const dataTable of listTables) {
        const cduOptions = department_with_companies
            .find(x => x.department_id === dataTable.department.id)
            .companies.map(company => {
                return {
                    id: null,
                    name: buildCompanyName(company.name),
                    company_id: company.id,
                    company_department_role_user_id: null,
                    is_enabled: false
                }
            })
        assignRoles(dataTable.roles, dataTable.department.id, cduOptions)
    }

    return listTables
}
