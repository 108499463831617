function CircleWarning(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9 5h2v2H9V5zM9 9h2v6H9V9z" fillOpacity={0.54} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 10C0 4.48 4.48 0 10 0s10 4.48 10 10-4.48 10-10 10S0 15.52 0 10zm2 0c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8-8 3.59-8 8z"
            />
        </svg>
    )
}

export default CircleWarning
