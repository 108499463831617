import { ItemRoute } from './types'
import ProductPrices from '../../pages/ProductPrices'
import MainProvider from '../../pages/MainProvider'
import ProductPrice from '../../pages/ProductPrice'

const Prices: ItemRoute[] = [
    {
        label: 'Precios de venta',
        title: 'Precios de venta',
        cu: 'CU090',
        path: '/prices',
        Component: ProductPrices,
        routes: [
            {
                cu: 'CU091',
                title: 'Editar Precio',
                path: '/products/:product_price_id/edition',
                Component: ProductPrice
            }
        ]
    },
    {
        label: 'Precios de compra',
        title: 'Precios de compra',
        cu: 'CU118',
        path: '/main_providers',
        Component: MainProvider
    }
]

export default Prices
