import { ModalTools } from '../../../../sections/ModalSection/types'
import { formPaymentMethod, tablePaymentMethods } from '../../storage'
import { TablePaymentMethodData } from '../../types'
import FormPaymentMethod from './FormPaymentMethod'

export default function usePaymentMethod(index?: number) {
    function onSubmit(ev, modalTools: ModalTools) {
        ev.preventDefault()
        const result = formPaymentMethod.test(v => {
            v.description.isEmpty('Se requiere ingresar la descripción')
            v.payment_method.isEmpty(
                'Se requiere seleccionar la método de pago'
            )
            v.company.isEmpty('Se requiere seleccionar la compañía')
        })

        if (!result) return

        const { payment_method, description, company } =
            formPaymentMethod.store()

        const data: TablePaymentMethodData = {
            id: 1,
            company: {
                name: company.label,
                id: parseInt(company.value),
                image: company.image
            },
            description,
            payment_method: {
                id: parseInt(payment_method.value),
                name: payment_method.label
            }
        }

        if (index === undefined) {
            tablePaymentMethods.push(data)
        } else {
            tablePaymentMethods.put(index, data)
        }
        modalTools.close()
    }

    return formPaymentMethod.useModal(FormPaymentMethod, {
        size: 'sm',
        onSubmit,
        title:
            index === undefined
                ? 'Agregar Medios de Pago'
                : 'Editar Medios de Pago'
    })
}
