import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import ReadOnly from '../../../../components/ReadOnly'
import SelectTouch from '../../../../components/SelectTouch'
import Textarea from '../../../../components/Textarea'
import Country from '../../../../models/Country'
import RegexTypes from '../../../../utilities/regexTypes'
import { formInfo, storagePage } from '../../storage'

function SelectCountry() {
    const { props, data } = formInfo.useSelect<Country>(
        'country',
        '/v5.6/apis/countries'
    )
    const { mode } = storagePage
    return (
        <FormControl label="País de procedencia" required>
            {mode === 'creation' && (
                <SelectTouch
                    {...props}
                    label="País de procedencia"
                    placeholder="Seleccionar país de procedencia"
                >
                    <IterateOptions
                        data={data}
                        label="name"
                        image="image_url"
                    />
                </SelectTouch>
            )}
            {mode === 'edition' && (
                <ReadOnly
                    value={props.option?.label}
                    image={props.option?.image}
                />
            )}
        </FormControl>
    )
}

function Web() {
    const { props } = formInfo.useInput('link_web_page')
    return (
        <FormControl label="Página web">
            <InputField
                {...props}
                placeholder="Ingresar link de página web"
                regex={RegexTypes.url}
            />
        </FormControl>
    )
}

function FiscalAddress() {
    const { props } = formInfo.useInput('fiscal_address')
    return (
        <FormControl label="Dirección Fiscal" required col="span-6">
            <InputField
                {...props}
                placeholder="Ingresar dirección Fiscal"
                maxLength={300}
            />
        </FormControl>
    )
}

function Description() {
    const { props } = formInfo.useInput('description')
    return (
        <FormControl label="Descripción Adicional" col="span-6">
            <Textarea {...props} placeholder="Ingresar descripción adicional" />
        </FormControl>
    )
}

export default function Fields2() {
    return (
        <>
            <SelectCountry />
            <Web />
            <FiscalAddress />
            <Description />
        </>
    )
}
