import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import useStorage from '../../../../hooks/useStorage'
import { GetProvider } from '../../../../types/provider'
import getDispatches from '../../utilities/getDispatches'

export default function Address({ data: provider }: { data: GetProvider }) {
    const { countries } = useStorage()
    const getCountry = (country_id: number) =>
        countries.find(country => country.id === country_id)

    const addressesDispatch = getDispatches(provider)

    return (
        <>
            <Typography type="header-3" className="mb-2">
                Direcciones de Despacho
            </Typography>
            <DataTable className="mb-4" scrollHeight={250}>
                <THead>
                    <tr>
                        <th>País</th>
                        <th>Lugar de despacho</th>
                        <th>Tipo destino</th>
                        <th>Pricipal</th>
                        <th>Dirección</th>
                    </tr>
                </THead>
                <TBody>
                    {addressesDispatch.map((addressDispatch, i) => (
                        <tr key={i}>
                            <td>
                                <img
                                    src={addressDispatch.image}
                                    className="w-8"
                                />
                            </td>
                            <td>{addressDispatch.place_dispatch}</td>
                            <td>{addressDispatch.type_dispatch}</td>
                            <td>{addressDispatch.main ? 'Si' : 'No'}</td>
                            <td>{addressDispatch.address}</td>
                        </tr>
                    ))}
                    {addressesDispatch.length === 0 && (
                        <Caption colSpan={5}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
            <Typography type="header-3" className="mb-2">
                Dirección de retiro
            </Typography>
            <DataTable scrollHeight={250}>
                <THead>
                    <tr>
                        <th>País</th>
                        <th>Lugar de retiro</th>
                        <th>Pricipal</th>
                        <th>Dirección</th>
                    </tr>
                </THead>
                <TBody>
                    {provider.provider_withdrawal_addresses
                        .filter(
                            providerWithdrawalAddress =>
                                providerWithdrawalAddress.is_enabled
                        )
                        .map(providerWithdrawalAddress => (
                            <tr key={providerWithdrawalAddress.id}>
                                <td>
                                    <img
                                        src={
                                            getCountry(
                                                providerWithdrawalAddress
                                                    .address.country_id
                                            ).image_url
                                        }
                                        className="w-8"
                                    />
                                </td>
                                <td>
                                    {providerWithdrawalAddress.address.locate}
                                </td>
                                <td>
                                    {providerWithdrawalAddress.is_main
                                        ? 'Si'
                                        : 'No'}
                                </td>
                                <td>
                                    {providerWithdrawalAddress.address.address}
                                </td>
                            </tr>
                        ))}
                    {provider.provider_withdrawal_addresses.length === 0 && (
                        <Caption colSpan={4}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
