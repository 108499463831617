import { SimpleOption } from '../../../../../components/Option'
import { controlList } from '../../../storage'
import writeXlsxFile, { Schema } from 'write-excel-file'
import { get } from '../../../../../utilities/http'
import { buildPath } from '../../../../../sections/ModalPrint/utils'

export default function OptionExcel() {
    async function downloadExcel() {
        const company_id = controlList.filter.params.company_id
        const { data } = await get(
            buildPath(
                `/v5.6/apis/inventory_transit/excel?company_id=${company_id}`,
                []
            )
        )
        const dataAPI: any = data.result

        const objects: any = [
            [
                {
                    value: '#',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'OC',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Estado de OC',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Código de producto',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Descripción del producto',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Proveedor',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Fecha de confirmación de OC',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Requisición',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Bodega destino',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Cliente',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Cantidad en tránsito',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Precio unitario',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                },
                {
                    value: 'Precio total',
                    fontWeight: 'bold',
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true,
                    backgroundColor: '#e3e3e3'
                }
            ]
        ]

        for (const [index, purchase_product] of dataAPI.entries()) {
            objects.push([
                {
                    value: '' + (index + 1),
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.purchase_code,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.purchase_status_name,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.product_code,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.product_description,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.provider_business_name,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.purchase_confirmation_date,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.requisition_code,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.destination_stowage_name,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.client_name,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.quantity,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.unit_price,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: purchase_product.total_price,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                }
            ])
            // if (oc_product.is_enabled && !oc_product.deleted) {
            //     total += oc_product.quantity * oc_product.unit_purchase_price
            // }
        }

        await writeXlsxFile(objects, {
            fileName: `inventario_transito.xlsx`,
            columns: [10, 15, 15, 15, 35, 20, 20, 20, 20, 20, 20, 15, 15].map(
                width => ({ width, wrap: true })
            ),
            fontSize: 10
        })
    }

    return (
        <SimpleOption onClick={downloadExcel}>Exportar en excel</SimpleOption>
    )
}
