import {
    FormControl,
    FormControls
} from '../../../../../components/FormUtilities'
import NumeralField from '../../../../../components/NumeralField'
import InputField from '../../../../../components/InputField'
import { formCostGeneral } from '../storageStep3'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../storage'
import useStorage from '../../../../../hooks/useStorage'

function PurchaseCost1() {
    const { props } = formCostGeneral.useInput('original_cost')
    const symbol = useGetCurrentSymbol()

    function handleChange(ev) {
        props.onChange(ev)
        runOperation()
    }

    return (
        <FormControl
            label={`Monto de concepto (${symbol})`}
            required
            col="span-2"
        >
            <NumeralField {...props} onChange={handleChange} decimal={2} />
        </FormControl>
    )
}

function ExchangeRate() {
    const { props } = formCostGeneral.useInput('exchange_to_local')
    const symbol = useGetCurrentSymbol()

    function handleChange(ev) {
        props.onChange(ev)
        runOperation()
    }

    return (
        <FormControl
            label={`Tipo de cambio (${symbol} a ${storagePage.currentCurrency.symbol})`}
            required
            col="span-2"
        >
            <NumeralField {...props} onChange={handleChange} decimal={4} />
        </FormControl>
    )
}

function PurchaseCost2() {
    const { props } = formCostGeneral.useInput('total_cost')

    return (
        <FormControl
            label={`Monto de concepto (${storagePage.currentCurrency.symbol})`}
            col="span-2"
        >
            <InputField {...props} disabled />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <FormControls className="mb-4">
            <PurchaseCost1 />
            <ExchangeRate />
            <PurchaseCost2 />
        </FormControls>
    )
}

function runOperation() {
    const { original_cost, exchange_to_local } = formCostGeneral.store()
    const result = Number(original_cost) * Number(exchange_to_local)
    formCostGeneral.setValue('total_cost', buildNumberFormat(result))
}

function useGetCurrentSymbol() {
    const { currency } = formCostGeneral.store()
    const { currencies } = useStorage()

    const currentCurrency = currencies.find(
        c => c.id.toString() === currency?.value
    )

    return currentCurrency ? currentCurrency.symbol : '?'
}
