import {
    LOGISTICAL_CONCEPT__DUTY,
    LOGISTICAL_CONCEPT__ISC,
    LOGISTICAL_CONCEPT__IVA
} from '../../../../../config/constants'
import useSubmitDuty from './useSubmitDuty'
import useSubmitISC from './useSubmitISC'
import useSubmitIva from './useSubmitIva'

export default function useSubmitDutyISCIVA(
    logistical_cost_id: number,
    logistical_concept_id: number
) {
    const iva = useSubmitIva(logistical_cost_id)
    const duty = useSubmitDuty(logistical_cost_id)
    const isc = useSubmitISC(logistical_cost_id)

    if (logistical_concept_id === LOGISTICAL_CONCEPT__IVA) {
        return iva
    } else if (logistical_concept_id === LOGISTICAL_CONCEPT__DUTY) {
        return duty
    } else if (logistical_concept_id === LOGISTICAL_CONCEPT__ISC) {
        return isc
    }
}
