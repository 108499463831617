import classNames from 'classnames'
import { useHistory } from 'react-router'
import BtnActionTable from '../../../components/BtnActionTable'
import Enable from '../../../components/Enable'
import Option from '../../../components/Option'
import Popover from '../../../components/Popover'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { GetProvider } from '../../../types/provider'
import { controlList } from '../storage'
import ActiveDeactivate from './ActiveDeactivate'
import InfoProvider from './InfoProvider'

export default function Table() {
    const math = useRule('CU088')
    const { push } = useHistory()

    function handleView(provider_id: number) {
        view<GetProvider>(`/v5.6/apis/providers/${provider_id}`, provider => ({
            title: provider.business_name,
            Component: InfoProvider
        }))
    }

    return (
        <List control={controlList}>
            {(provider, i) => (
                <tr key={provider.id}>
                    <td>{i}</td>
                    <td>
                        {math ? (
                            <b
                                onClick={() => handleView(provider.id)}
                                className={classNames({
                                    'cursor-pointer underline':
                                        provider.is_enabled,
                                    'line-through': !provider.is_enabled
                                })}
                            >
                                {provider.commercial_name}
                            </b>
                        ) : (
                            provider.commercial_name
                        )}
                    </td>
                    <td>
                        <div className="flex items-center">
                            <img
                                src={provider.country.image_url}
                                className="mr-2 w-8"
                            />
                            <p>{provider.country.name}</p>
                        </div>
                    </td>
                    <td>{provider.business_name}</td>
                    <td>
                        <Enable enabled={provider.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU084">
                                <BtnActionTable
                                    title="Editar"
                                    iconName="bs-pencil"
                                    onClick={() =>
                                        push(
                                            `/configuration/providers/${provider.id}/edition`
                                        )
                                    }
                                />
                            </Rules>
                            <Rules cu="CU085">
                                <Delete
                                    path={`/v5.6/apis/providers/${provider.id}`}
                                    loadingMessage="Eliminando Licencia"
                                    control={controlList}
                                >
                                    ¿Está seguro de eliminar el proveedor{' '}
                                    <b className="text-primary">
                                        {provider.business_name}
                                    </b>
                                    ?
                                </Delete>
                            </Rules>
                            <Rules
                                cu={[
                                    'CU081',
                                    'CU109',
                                    provider.is_enabled ? 'CU085' : 'CU086'
                                ]}
                            >
                                <Popover
                                    component={
                                        <BtnActionTable iconName="bs-three-dots" />
                                    }
                                    position="bottom-right-left"
                                    element=".panel--form-datatable"
                                >
                                    <Rules cu="CU109">
                                        <Option
                                            icon="dashboard"
                                            label="Catálogo de productos"
                                            value="1"
                                            onClick={() =>
                                                push(
                                                    `/configuration/providers/${provider.id}/catalog`
                                                )
                                            }
                                        />
                                    </Rules>
                                    <ActiveDeactivate provider={provider} />
                                </Popover>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
