import NumeralField from '../../../../../components/NumeralField'
import Typography from '../../../../../components/Typography'
import useGet from '../../../../../hooks/useGet'
import { TableList } from '../../../../../sections/ListForm'
import FormEvents from '../../../../../utilities/FormEvents'
import { storagePage } from '../../../storage'
import { reqLDCostDistribution, tableDutyControl } from '../storageStep2'
import Cost from '../cost'
import useDutyRow from './useDutyRow'
import { DutySpecifications } from './Specifications'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import {
    LOGISTICAL_CONCEPT__EXTRA_CIF,
    LOGISTICAL_CONCEPT__FREIGHT,
    LOGISTICAL_CONCEPT__SAFE
} from '../../../../../config/constants'

export default function TableDuty(props: Cost.ResponseLCDuty) {
    const { version, logistical_declaration } = storagePage
    const { data } = useGet<Cost.ResponseLDProductsCostDistribution[]>(
        reqLDCostDistribution.observer(
            `/v5.6/apis/logistical_declaration/${logistical_declaration.id}/version/${version}/get_cost_distributions`,
            true
        ),
        []
    )

    const getDataLC = (
        lcs: Cost.ResponseLDProductsCostDistribution['logistical_costs']
    ) => {
        const freight_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__FREIGHT
        )
        const secure_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__SAFE
        )
        const extra_cif_cost = lcs.find(
            lc => lc.logistical_concept_id === LOGISTICAL_CONCEPT__EXTRA_CIF
        )

        return {
            freight_cost,
            secure_cost,
            extra_cif_cost
        }
    }

    const getPercentage = (product_id: number) => {
        const percentage = props.logistical_duty_costs.find(
            lic => lic.product_id === product_id
        )?.percentage

        if (percentage) return (percentage * 100).toString()
        return '0'
    }

    tableDutyControl.setList(
        data.result.map(d => {
            const { freight_cost, secure_cost, extra_cif_cost } = getDataLC(
                d.logistical_costs
            )

            return {
                id: d.product_id,
                product_id: d.product.id,
                product_name: d.product.name,
                product_code: d.product.code,
                fob: d.purchase_dolar_cost_by_product,
                freight: freight_cost.original_cost_by_product,
                secure: secure_cost.original_cost_by_product,
                cif_local:
                    d.purchase_local_cost_by_product +
                    freight_cost.local_cost_by_product +
                    secure_cost.local_cost_by_product +
                    extra_cif_cost.local_cost_by_product,
                cif:
                    d.purchase_dolar_cost_by_product +
                    freight_cost.original_cost_by_product +
                    secure_cost.original_cost_by_product +
                    extra_cif_cost.original_cost_by_product,
                percentage: getPercentage(d.product_id),
                duty: 0
            }
        })
    )

    return (
        <>
            <label className="label">
                <span className="label-text text-gray-500 w-full">
                    Aranceles por producto
                </span>
            </label>
            <TableList
                control={tableDutyControl}
                scrollHeight={350}
                className="mb-4"
                tbody={(_, control, e) => <Row key={e} control={control} />}
            />
            <DutySpecifications />
        </>
    )
}

function Row({ control }: { control: FormEvents<Cost.ModalTableDuty> }) {
    const { tooltip, data, props, handleChange } = useDutyRow(control)

    return (
        <tr className={tooltip && 'bg-red-100'}>
            <td>
                <Typography type="paragraph" className="font-bold">
                    {data.product_name}
                </Typography>
                <Typography type="small">{data.product_code}</Typography>
            </td>
            <td>{data.fob}</td>
            <td>{buildNumberFormat(data.freight)}</td>
            <td>{buildNumberFormat(data.secure)}</td>
            <td>{buildNumberFormat(data.cif)}</td>
            <td>
                <NumeralField
                    {...props}
                    onChange={handleChange}
                    decimal={2}
                    helper=""
                    max={9999999999}
                />
            </td>
            <td>{buildNumberFormat((data.cif / 100) * Number(props.value))}</td>
        </tr>
    )
}
