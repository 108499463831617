import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'

export default function Header({ onCancel }) {
    const handleCancel = () => {
        onCancel()
    }

    return (
        <PageHeader subtitle="Revisa la información de los precios del producto">
            <Button color="ghost" onClick={handleCancel}>
                Cancelar
            </Button>
        </PageHeader>
    )
}
