export default function Trash(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.083.5H3.916l-.833.833H.167V3h11.666V1.333H8.916L8.083.5zm1.25 5v8.333H2.666V5.5h6.667zM1 3.833h10v10c0 .917-.75 1.667-1.667 1.667H2.666C1.75 15.5 1 14.75 1 13.833v-10z"
            />
        </svg>
    )
}
