import 'moment/locale/es'
import Typography from '../../../../components/Typography'
import NumeralField from '../../../../components/NumeralField'
import { LogicalProductForInput } from '../../types'
import FormEvents from '../../../../utilities/FormEvents'
import { TableList } from '../../../../sections/ListForm'
import {
    formInputPart,
    productsControl,
    reqProducts,
    storagePage
} from '../../storage'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import BtnActionTable from '../../../../components/BtnActionTable'

export default function TraspaseView() {
    function handleDelete(lProduct: LogicalProductForInput, index) {
        productsControl.del(index)
        const { products } = formInputPart.store()
        const options = products.filter(
            tp => parseInt(tp.value) === lProduct.logical_product_id
        )
        formInputPart.setValue('products', options)
        if (options.length === 0) {
            reqProducts.deleteQuery('not_logical_product_ids')
        } else {
            reqProducts.setQuery(
                'not_logical_product_ids',
                options.map(option => option.value)
            )
        }
        reqProducts.load()
    }

    return (
        <>
            <TableList control={productsControl} scrollHeight={500}>
                {(lProduct, control, e) => (
                    <tr key={lProduct.logical_product_id}>
                        <td>{e}</td>
                        <td>
                            <Typography
                                type="paragraph"
                                className={'font-bold'}
                            >
                                {lProduct.name}
                            </Typography>
                            <Typography type="small" color="gray">
                                {lProduct.code}
                            </Typography>
                        </td>
                        <td>{lProduct.presentation || '-'}</td>
                        <td>
                            <Quantity control={control} />
                        </td>
                        <td>
                            <div className="flex items-center">
                                <Average
                                    prefix={storagePage.symbol}
                                    control={control}
                                />
                            </div>
                        </td>
                        <td>
                            {storagePage.symbol}{' '}
                            {buildNumberFormat(lProduct.total)}
                        </td>
                        <td>
                            <div className="group-btn-options">
                                <BtnActionTable
                                    title="Eliminar"
                                    iconName="bs-trash"
                                    onClick={() =>
                                        handleDelete(lProduct, e - 1)
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}

function Quantity({
    control
}: {
    control: FormEvents<LogicalProductForInput>
}) {
    const { props } = control.useInput('quantity')

    function handleChange(ev) {
        props.onChange(ev)
        const newTotal =
            parseInt(control.store().average || '0') * ev.target.value
        control.setValue('total', newTotal)
        productsControl.renderMain()
    }

    return <NumeralField {...props} onChange={handleChange} placeholder="0" />
}

function Average({
    control,
    prefix
}: {
    control: FormEvents<LogicalProductForInput>
    prefix: string
}) {
    const { props } = control.useInput('average')
    function handleChange(ev) {
        props.onChange(ev)
        const newTotal =
            parseInt(control.store().quantity || '0') * ev.target.value
        control.setValue('total', newTotal)
        productsControl.renderMain()
    }
    return (
        <NumeralField
            prefix={prefix}
            decimal={2}
            {...props}
            onChange={handleChange}
            placeholder="0"
        />
    )
}
