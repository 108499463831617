import Rules from '../../../components/Rules'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { controlList } from '../storage'
import { ProviderData } from '../types'

interface PropsActiveDeactive {
    provider: ProviderData
}

export default function ActiveDeactivate({ provider }: PropsActiveDeactive) {
    return (
        <Rules cu={provider.is_enabled ? 'CU085' : 'CU086'}>
            <ActivateDeactivate
                isEnabled={provider.is_enabled}
                control={controlList}
                body={{
                    provider_id: provider.id
                }}
            >
                <Active
                    path="/v5.6/apis/providers/activate"
                    label="Reactivar proveedor"
                >
                    Al reactivar el proveedor, estará disponible para registrar
                    requisiciones y órdenes de compra. ¿Está seguro de reactivar
                    el proveedor
                    <b className="text-primary"> {provider.commercial_name}</b>?
                </Active>
                <Deactive
                    path="/v5.6/apis/providers/deactivate"
                    label="Desactivar proveedor"
                >
                    Al desactivar el proveedor, aun se podrá realizar
                    operaciones de mantenimiento como registro de costos,
                    relación con productos y modificación de datos. Sin embargo,
                    el proveedor dejará de estar disponible para registrar
                    requisiciones y órdenes de compra. ¿Está seguro de
                    desactivar el proveedor
                    <b className="text-primary"> {provider.commercial_name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
