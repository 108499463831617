import { GOOGLE_API_KEY } from '../../config/credentials'
export async function geocode(address) {
    const geocoder = new window.google.maps.Geocoder()
    return new Promise((resolve, reject) => {
        geocoder.geocode({ address: address }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                resolve(results)
            } else {
                reject(new Error('Address not found'))
            }
        })
    })
}
export const getGeocodeByLatLng = async (lat, lng) => {
    const latLngString = `latlng=${lat},${lng}`
    return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?${latLngString}&key=${GOOGLE_API_KEY}`
    )
}
