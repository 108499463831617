import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import {
    controlList,
    controlTabs,
    filterForm,
    reqProvider,
    reqStowage,
    storagePage
} from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)

    useEffect(() => {
        const company = getCompanySelected()
        controlList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })
        controlList.filter.params.status_id = 1
        reqStowage.setQuery('company_id', company.id)
        storagePage.company_enabled = company.is_enabled
        storagePage.timezone = company.timezone
        controlList.filter.changeParams('sort', 'requisition_code')
        controlList.filter.changeParams('order', 'desc')
        controlList.filter.params.status = 'Abiertas'

        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })
        return () => {
            filterForm.init()

            reqStowage.init()
            reqProvider.init()

            controlTabs.value = 'step1'
        }
    }, [])

    return { state }
}
