import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import FilterUsers from './FilterUsers'
import TableUsers from './TableUsers'

export default function Users() {
    const state = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterUsers />
                    <TableUsers />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
