import classnames from 'classnames'
import { PropsFormControl } from './types'

function Normal({ children, label, required, labelComponent = null }) {
    return (
        <>
            <label className="label">
                <span className="label-text text-gray-500 flex justify-between w-full">
                    <div>
                        {label}
                        {required && <b className="ml-2 text-red-500">*</b>}
                    </div>
                    {labelComponent}
                </span>
            </label>
            {children}
        </>
    )
}

function Inline({ label, required, children }) {
    return (
        <label className="cursor-pointer label items-start">
            <span className="label-text text-gray-500 mr-2">
                {label}
                {required && <b className="ml-2 text-red-500">*</b>}
            </span>
            {children}
        </label>
    )
}

export default function FormControl({
    label,
    children,
    className,
    row = 'span-1',
    col = 'span-3',
    mode = 'normal',
    required = false,
    labelComponent = null
}: PropsFormControl) {
    return (
        <div
            className={classnames(
                'form-control relative',
                {
                    [`row-${row}`]: true,
                    [`col-${col}`]: true
                },
                className
            )}
        >
            {mode === 'normal' && (
                <Normal
                    label={label}
                    required={required}
                    labelComponent={labelComponent}
                >
                    {children}
                </Normal>
            )}
            {mode === 'inline' && (
                <Inline label={label} required={required}>
                    {children}
                </Inline>
            )}
        </div>
    )
}
