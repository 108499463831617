import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import useStorage from '../../hooks/useStorage'
import { get } from '../../utilities/http'
import {
    formPurchaseReceipt,
    productsControl,
    reqPurchase,
    reqWarehouse,
    reqWorkingPeriod,
    storagePage
} from './storage'
import { GetReception } from './types'
import useInitialEdition from './useInitialEdition'
import { render } from 'redity'
import { Keys } from './constants'

export default function useInitial() {
    type Params = { purchase_receipt_id?: string }
    const { purchase_receipt_id } = useParams<Params>()
    const [pageState, setState] = useState<StateCase>(StateCase.LOADING)
    const { companiesFromSession } = useStorage()
    const loadEdition = useInitialEdition()

    let title = 'Nueva recepción de compra'
    if (purchase_receipt_id) {
        storagePage.mode = 'edition'
        title = 'Editar recepción de compra'
    }

    async function load() {
        if (purchase_receipt_id) {
            const response = await get<GetReception>(
                `/v5.6/apis/receptions/${purchase_receipt_id}`
            )

            if (response.error) {
                setState(StateCase.ERROR)
                return
            }
            storagePage.purchaseReceipt = response.data.result
            const company = companiesFromSession.find(
                c => c.id === response.data.result.company_warehouse.company_id
            )
            storagePage.timezone = company.timezone
            storagePage.localCurrency = company.local_currency
            reqPurchase.setQuery('only_confirmed', false)
            loadEdition(response.data.result)
        } else {
            if (formPurchaseReceipt.store().company) {
                const company = companiesFromSession.find(
                    c =>
                        c.id ===
                        parseInt(formPurchaseReceipt.store().company.value)
                )
                storagePage.timezone = company.timezone
                storagePage.localCurrency = company.local_currency

                reqWorkingPeriod.setParam(
                    'company_id',
                    formPurchaseReceipt.store().company.value
                )
                reqWarehouse.setQuery(
                    'company_id',
                    formPurchaseReceipt.store().company.value
                )
                reqPurchase.setParam(
                    'company_id',
                    formPurchaseReceipt.store().company.value
                )
            } else {
                const company = companiesFromSession[0]
                formPurchaseReceipt.setValue('company', {
                    label: company.name,
                    value: company.id.toString(),
                    image: company.country.image_url
                })
                reqWorkingPeriod.setParam('company_id', company.id)
                reqWarehouse.setQuery('company_id', company.id)
                reqPurchase.setParam('company_id', company.id)
                storagePage.timezone = company.timezone
            }
            reqWarehouse.setQuery('is_enabled', true)
            reqPurchase.setQuery('only_confirmed', true)
            storagePage.mode = 'creation'
            const date = moment().tz(storagePage.timezone).parseZone()
            formPurchaseReceipt.setValue('receiptDate', date.toISOString(true))
        }
        setState(StateCase.SUCCESS)
    }

    useEffect(() => {
        load()
        return () => {
            storagePage.purchaseReceipt = null
            storagePage.mode = 'creation'
            storagePage.receipt_products = []
            render(Keys.MOUNT)
            productsControl.setList([])
            formPurchaseReceipt.init()
            formPurchaseReceipt.clearProps()
            reqWorkingPeriod.init()
            reqWarehouse.init()
            reqPurchase.init()
        }
    }, [])

    return {
        pageState,
        title
    }
}
