import { render } from 'redity'
import FormEvents from '../../../../../utilities/FormEvents'
import { Keys } from '../../../constants'
import Cost from '../cost'
import { formDutyISCIVA, tableISCControl } from '../storageStep2'

export default function useIscRow(control: FormEvents<Cost.ModalTableISC>) {
    const { props } = control.useInput('original_cost')
    const data = control.store()

    const tooltip = props.helper

    function handleChange(ev) {
        props.onChange(ev)
        const value = tableISCControl.getDataList().reduce(function (a, b) {
            return a + Number(b.original_cost)
        }, 0)
        const { exchange_to_local } = formDutyISCIVA.store()
        const total = (value * Number(exchange_to_local)).toFixed(2)
        formDutyISCIVA.setValue('original_cost', value.toFixed(2))
        formDutyISCIVA.setValue('total_cost', total)
        render(Keys.TABLE_STEP_2_ISC_SPECIFICATIONS)
    }

    return {
        props,
        tooltip,
        handleChange,
        data
    }
}
