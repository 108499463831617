import { ListFormControl } from '../../../../sections/ListForm'
import ControlRequest from '../../../../utilities/ControlRequest'
import FormEvents from '../../../../utilities/FormEvents'
import { Keys } from '../../constants'
import Aditional from './aditional'

export const tableAditionalInternationalCostControl =
    new ListFormControl<Aditional.TableICA>(
        Keys.TABLE_ADITIONAL_INTERNATIONAL_COSTS,
        [
            '#',
            'Concepto',
            'Agente',
            'Tipo',
            'Costeo a RCs',
            'Monto',
            'Tipo de cambio',
            'Monto',
            'Acciones'
        ]
    )

export const tableAditionalNationalCostControl =
    new ListFormControl<Aditional.TableNCA>(
        Keys.TABLE_ADITIONAL_INTERNATIONAL_COSTS2,
        [
            '#',
            'Concepto',
            'Agente',
            'Tipo',
            'Costeo a RCs',
            'Monto',
            'Tipo de cambio',
            'Monto',
            'Acciones'
        ]
    )

export const reqLogisticalCost3 = new ControlRequest()
export const reqLogisticalCost4 = new ControlRequest()
export const reqLDCostDistribution2 = new ControlRequest()

export const formCostGeneral = new FormEvents<Aditional.FormGeneralCost>(
    Keys.FORM_COST_GENERAL,
    {
        logistical_cost_id: null,
        logistical_concept: null,
        currency: null,
        original_cost: '0.00',
        exchange_to_local: '1.00',
        total_cost: '0.00',
        document_number: '',
        agent: null,
        rcs: [],
        files: [],
        productIds: []
    }
)

export const formCostByProduct = new FormEvents<Aditional.FormLCSpecific>(
    Keys.FORM_COST_BY_PRODUCT,
    {
        logistical_cost_id: null,
        logistical_concept: null,
        currency: null,
        cost: '',
        exchange_to_local: '',
        total_cost: '',
        document_number: '',
        agent: null,
        rcs: [],
        files: [],
        productIds: []
    }
)

export const tableCostByProductControl =
    new ListFormControl<Aditional.ModalTableCostProduct>(
        Keys.TABLE_COST_BY_PRODUCT,
        ['Producto', 'Costo', 'Costo']
    )
