import BtnActionTable from '../../../components/BtnActionTable'
import Rules from '../../../components/Rules'
import Category from '../../../models/Category'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { controlList } from '../storage'
import useSubmit from '../useSubmit'

function Edit({ category }: { category: Category }) {
    const open = useSubmit(category.id)
    return (
        <Rules cu="CU043">
            <BtnActionTable
                title="Editar"
                iconName="bs-pencil"
                onClick={() => open(category)}
            />
        </Rules>
    )
}

export default function TableCategories() {
    return (
        <List control={controlList}>
            {(data, i) => (
                <tr key={data.id}>
                    <td>{i}</td>
                    <td>{data.code}</td>
                    <td>{data.name}</td>
                    <td>
                        <div className="group-btn-options">
                            <Edit category={data} />
                            <Rules cu="CU044">
                                <Delete
                                    path={`/v5.6/apis/categories/${data.id}`}
                                    control={controlList}
                                >
                                    ¿Está seguro de eliminar la categoría{' '}
                                    <b className="text-primary">{data.name}</b>?
                                </Delete>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
