import { FormControl } from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import RegexTypes from '../../../../utilities/regexTypes'
import { formContact } from '../../storage'

function Name() {
    const { props } = formContact.useInput('name')
    return (
        <FormControl className="mb-2" required label="Nombre">
            <InputField
                {...props}
                placeholder="Ingresar nombre de contacto"
                autoFocus
            />
        </FormControl>
    )
}

function Position() {
    const { props } = formContact.useInput('position')
    return (
        <FormControl className="mb-2" required label="Cargo">
            <InputField {...props} placeholder="Ingresar cargo del contacto" />
        </FormControl>
    )
}

function Email() {
    const { props } = formContact.useInput('email')
    return (
        <FormControl className="mb-2" required label="Email">
            <InputField
                {...props}
                placeholder="Ingresar email del contacto"
                regex={RegexTypes.email}
            />
        </FormControl>
    )
}

function Phone() {
    const { props } = formContact.useInput('phone_number')
    return (
        <FormControl className="mb-2" required label="Teléfono">
            <InputField
                {...props}
                placeholder="Ingresar teléfono del contacto"
            />
        </FormControl>
    )
}

export default function FormContact() {
    return (
        <>
            <Name />
            <Position />
            <Email />
            <Phone />
        </>
    )
}
