import {
    FormControl,
    FormControls,
    IterateOptions
} from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import NumeralField from '../../../components/NumeralField'
import SelectTouch from '../../../components/SelectTouch'
import Textarea from '../../../components/Textarea'
import Company from '../../../models/Company'
import Country from '../../../models/Country'
import Currency from '../../../models/Currency'
import Product from '../../../models/Product'
import buildCompanyName from '../../../utilities/buildCompanyName'
import { formCatalog, providerList } from '../storage'

function CompanySelect() {
    const { props, data, getError } = formCatalog.useSelect<
        Company & { country: Country }
    >(
        'company',
        `/v5.6/apis/companies/provider_companies_from_session?provider_id=${providerList.filter.params.provider_id}&is_enabled=true`
    )

    const helper = getError(
        'ERROR-0000110',
        null,
        'product_provider_companies.0.company_id'
    )

    const companies = data.map(company => ({
        ...company,
        name: buildCompanyName(company.name),
        image_url: company.country.image_url,
        strikeOut: !company.memory
    }))

    return (
        <FormControl label="Compañía compradora" required col="span-6">
            <SelectTouch
                {...props}
                placeholder="Seleccionar compañía"
                onSearch={null}
                helper={props.helper || helper}
            >
                <IterateOptions
                    data={companies}
                    label="name"
                    image="image_url"
                    strikeOut="strikeOut"
                />
            </SelectTouch>
        </FormControl>
    )
}

function ProductField() {
    const { props, data } = formCatalog.useSelect<Product>(
        'product',
        () => '/v5.6/apis/products'
    )

    const product = data.map(_product => ({
        ..._product,
        label: _product.code + ' - ' + _product.description
    }))

    return (
        <FormControl label="Producto" required col="span-6">
            <SelectTouch
                {...props}
                placeholder="Seleccionar producto"
                placeholderSearch="Buscar por código o descripción de producto"
            >
                <IterateOptions data={product} label="label" />
            </SelectTouch>
        </FormControl>
    )
}

function Money() {
    const { props, data: currencies } = formCatalog.useSelect<Currency>(
        'currency',
        () => '/v5.6/core/currencies'
    )

    return (
        <FormControl label="Moneda" required>
            <SelectTouch {...props} placeholder="Seleccionar moneda">
                <IterateOptions
                    data={currencies.map(currency => ({
                        ...currency,
                        name: `(${currency.symbol}) ${currency.name}`
                    }))}
                    label="name"
                />
            </SelectTouch>
        </FormControl>
    )
}

function Price() {
    const { props } = formCatalog.useInput('purchase_price')
    return (
        <FormControl label="Precio de compra" required>
            <NumeralField
                {...props}
                max={10000000000000000000}
                decimal={2}
                placeholder="Ingrese el precio de compra del producto"
            />
        </FormControl>
    )
}

function ProviderCode() {
    const { props } = formCatalog.useInput('provider_code')
    return (
        <FormControl
            label="Código de producto (Proveedor)"
            required
            col="span-6"
        >
            <InputField
                {...props}
                placeholder="Ingrese el código de proveedor"
            />
        </FormControl>
    )
}

function Description() {
    const { props } = formCatalog.useInput('provider_description')
    return (
        <FormControl
            required
            label="Descripción de producto (Proveedor)"
            col="span-6"
        >
            <Textarea
                {...props}
                placeholder="Ingrese la descripción del producto"
                regex={null}
            />
        </FormControl>
    )
}

export default function ProductForm() {
    return (
        <FormControls>
            <CompanySelect />
            <ProductField />
            <Money />
            <Price />
            <ProviderCode />
            <Description />
        </FormControls>
    )
}
