import { useState } from 'react'
import classNames from 'classnames'
import { PropsCalendar } from '../type'
import { DAYS } from '../utilities'
import CalendarRows from './CalendarRows'
import SelectorMonthYear from './SelectorMonthYear'

function Days() {
    return (
        <div
            className={classNames(
                `w-full grid grid-cols-7 grid-rows-1 text-gray-800 leading-4 text-center`
            )}
        >
            {DAYS.map((DAY, i) => (
                <div
                    className={classNames(
                        `text-sm leading-6 h-6 w-full rounded-full m-auto outline-none font-bold`
                    )}
                    key={i}
                >
                    {DAY}
                </div>
            ))}
        </div>
    )
}

export default function Calendar({ viewDate }: PropsCalendar) {
    const [render, setRender] = useState(false)
    function handleBack(left) {
        if (left) {
            viewDate.subtract(1, 'month')
        } else {
            viewDate.add(1, 'month')
        }
        setRender(!render)
    }
    function handleChange(type, option) {
        if (type === 'month') {
            viewDate.month(option - 1)
        } else if (type === 'year') {
            viewDate.year(parseInt(option))
        }
        setRender(!render)
    }
    return (
        <div className={classNames(`min-w-min w-full py-2 px-3`)}>
            <SelectorMonthYear
                viewDate={viewDate}
                onBack={handleBack}
                onChange={handleChange}
            />
            <Days />
            <CalendarRows viewDate={viewDate} />
        </div>
    )
}
