import { createContext } from 'react'
import { ItemRoute, BlockRoute } from '../config/routes/types'

export interface RoutingTool {
    epicsModules: BlockRoute[]
    pages: ItemRoute[]
}

const RoutingContext = createContext<any>({
    epicsModules: [],
    pages: []
})

export default RoutingContext
