import { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { generateId } from '../../utilities/generateId'
import { PropsReactPopover } from './Type'

export default function ReactPopover({
    children,
    component,
    className,
    type = 'light',
    position = 'top',
    ...props
}: PropsReactPopover) {
    const [tooltipId] = useState(() => generateId())
    return (
        <>
            <span data-tip data-for={tooltipId} className={className}>
                {component}
            </span>
            <ReactTooltip
                id={tooltipId}
                effect="solid"
                type={type}
                place={position}
                className={`max-w-xs whitespace-normal shadow-md ${className}`}
                {...props}
            >
                <span>{children}</span>
            </ReactTooltip>
        </>
    )
}
