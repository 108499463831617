import CU from '../../config/types/cu'
import { getModules, getPages } from '../routing'
import { isAdmin } from './tools'

export default function getRouting(id: number, CUs: CU[]) {
    let epicsModules = getModules().filter(mod => {
        if (isAdmin(id)) return true
        if (!mod.cu) return true
        return CUs.find(cu => cu === mod.cu)
    })
    epicsModules = epicsModules.map(mod => {
        if (mod.routes) {
            mod.routes = mod.routes
                .filter(route => {
                    if (isAdmin(id)) return true
                    if (!route.cu) return true
                    return CUs.find(cu => cu === route.cu)
                })
                .map(route => {
                    if (route.routes) {
                        route.routes = route.routes.filter(subroute => {
                            if (isAdmin(id)) return true
                            if (!subroute.cu) return true
                            return CUs.find(cu => cu === subroute.cu)
                        })
                    }
                    return route
                })
                .filter(route => {
                    if (route.cu) return true
                    if (!route.cu && route.routes.length > 0) return true
                    return false
                })
        }
        return mod
    })
    const pages = getPages().filter(page => {
        if (isAdmin(id)) return true
        if (!page.cu) return true
        return CUs.find(cu => cu === page.cu)
    })
    return {
        epicsModules,
        pages
    }
}
