import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import useStorage from '../../../hooks/useStorage'
import Option from '../../../components/Option'
import { controlList, reqYears } from '../storage'
import useGet from '../../../hooks/useGet'
import { Option as OptionType } from '../../../config/types/component'
import { setCompanySelected } from '../../../utilities/controlStorage'

function StateCompany() {
    const { companiesFromSession } = useStorage()
    const { props } = controlList.useFilter('company_id')
    const data = companiesFromSession.map(company => ({
        id: company.id,
        name: company.name,
        image_url: company.country.image_url,
        strikeOut: !company.is_enabled
    }))

    function handleChange(option: OptionType) {
        const company = companiesFromSession.find(
            _cp => _cp.id === parseInt(option.value)
        )
        setCompanySelected(company)
        props.onChange(option)
        reqYears.setParam('company_id', option.value)
        reqYears.load()
    }

    return (
        <Dropdown
            {...props}
            onChange={handleChange}
            label="Compañía"
            clear={false}
            showLabel={false}
        >
            <IterateOptions
                data={data}
                label="name"
                image="image_url"
                strikeOut="strikeOut"
            />
        </Dropdown>
    )
}

function FilterYears() {
    const { props } = controlList.useFilter('year')
    const { data } = useGet<number[]>(
        reqYears.observer(
            `/v5.6/apis/working_periods/get_years_by_company/:company_id`,
            true
        ),
        []
    )

    return (
        <Dropdown {...props} label="Año" className="ml-2" clear={false}>
            {data.result.map(year => (
                <Option
                    key={year}
                    label={year.toString()}
                    value={year.toString()}
                />
            ))}
        </Dropdown>
    )
}

export default function FiltersHeaders() {
    return (
        <div className="flex flex-row gap-1 mb-4">
            <StateCompany />
            <FilterYears />
        </div>
    )
}
