import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { GetTraspase } from '../../types'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC',
        borderBottom: '1px solid #CCCCCC'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    table__td: {
        width: '13%',
        padding: '6px',
        fontSize: fontSize,
        borderLeft: '1px solid #CCCCCC'
    },
    table__th: {
        width: '13%',
        backgroundColor: '#CCCCCC',
        padding: '4px',
        fontSize: fontSize,
        fontFamily: 'helvetica-bold'
    },
    textRight: {
        textAlign: 'right'
    },
    cellCount: {
        width: '6%'
    },
    cellProductCode: {
        width: '20%'
    },
    cellProductDescription: {
        width: '40%'
    },
    cellCategory: {
        width: '22%'
    },
    cellQuantity: {
        width: '12%'
    }
})

export default function Body({ data }: { data: GetTraspase }) {
    return (
        <View style={styles.table}>
            <View style={styles.row}>
                <Text style={[styles.table__th, styles.cellCount]}>#</Text>
                <Text style={[styles.table__th, styles.cellProductCode]}>
                    Código de producto
                </Text>
                <Text style={[styles.table__th, styles.cellProductDescription]}>
                    Descripción de producto
                </Text>
                <Text style={[styles.table__th, styles.cellCategory]}>
                    Categoría
                </Text>
                <Text style={[styles.table__th, styles.cellQuantity]}>
                    Cantidad
                </Text>
            </View>
            {data.classic_traspase_products.map((d, i) => (
                <View key={i} style={styles.row}>
                    <Text style={[styles.table__td, styles.cellCount]}>
                        {i + 1}
                    </Text>
                    <Text style={[styles.table__td, styles.cellProductCode]}>
                        {d.traspase_product.product.code}
                    </Text>
                    <Text
                        style={[
                            styles.table__td,
                            styles.cellProductDescription
                        ]}
                    >
                        {d.traspase_product.product.description}
                    </Text>
                    <Text style={[styles.table__td, styles.cellCategory]}>
                        {d.traspase_product.product.subcategory.category.name}
                    </Text>
                    <Text
                        style={[
                            styles.table__td,
                            styles.cellQuantity,
                            styles.textRight
                        ]}
                    >
                        {d.traspase_product.quantity}
                    </Text>
                </View>
            ))}
        </View>
    )
}
