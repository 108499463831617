import BtnActionTable from '../../../../components/BtnActionTable'
import { reqAgent, reqWarehouse } from '../../storage'
import { Step3 } from '../../types'
import useDispatch from './useDispatch'

export default function EditDispatch({
    dispatch,
    index
}: {
    dispatch: Step3.TableDispatch
    index: number
}) {
    const open = useDispatch(index)

    const isOtherType = dispatch.type_dispatch.id === 4
    const isAgentType = dispatch.type_dispatch.id === 1

    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            disabled={!dispatch.enabled}
            onClick={() => {
                reqAgent.setQuery('country_address_id', dispatch.country.id)
                reqWarehouse.setQuery('country_id', dispatch.country.id)

                open({
                    country: {
                        label: dispatch.country.name,
                        image: dispatch.country.image_url,
                        value: dispatch.country.id.toString()
                    },
                    type_dispatch: {
                        label: dispatch.type_dispatch.name,
                        value: dispatch.type_dispatch.id.toString()
                    },
                    place_dispatch: isOtherType
                        ? null
                        : {
                              label: dispatch.place_dispatch,
                              value: dispatch.place_dispatch_id.toString()
                          },
                    place_dispatch_text: dispatch.place_dispatch,
                    address: dispatch.address,
                    address_select: isAgentType
                        ? {
                              label: dispatch.address,
                              value: dispatch.address_id.toString()
                          }
                        : null
                })
            }}
        />
    )
}
