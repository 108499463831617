import { useParams } from 'react-router'
import { formAgent, tableAddress, tableContacts } from './storage'
import { BodyAgent, TableAddress } from './types'
import parseBody from './utilities/parseBody'

export default function useSubmit() {
    const { agent_id } = useParams<{ agent_id?: string }>()
    const { submit, validation, observer } = formAgent.useSubmit(
        agent_id ? `/v5.6/apis/agents/${agent_id}` : '/v5.6/apis/agents',
        {
            message: '¿Está seguro de guardar cambios de Agente?',
            method: agent_id ? 'put' : 'post',
            expectChange: false,
            done: '/configuration/agents',
            onError: error => {
                if (error.type === 'development') {
                    formAgent.errorControl.setException(error)
                }
                tableContacts.setError(error)
                tableAddress.setError(error)
            }
        }
    )

    validation(v => {
        v.name
            .minLength(2, 'El número de caracteres válidos es 2 - 300')
            .isEmpty('Se requiere ingresar el nombre')
        v.country.isEmpty('Se requiere seleccionar un país')
        v.type_agents.isEmpty('Se requiere seleccionar el tipo de agente')
        v.fiscal_address
            .minLength(2, 'El número de caracteres válidos es 2 - 300')
            .isEmpty('Se requiere ingresar la dirección fiscal')

        let pass = true
        if (tableAddress.list.length === 0) {
            pass = false
            tableAddress.setMessage(
                'Se requiere registrar al menos una dirección de retiro'
            )
        }

        const dataAddress: TableAddress[] = []
        tableAddress.getDataList().forEach(data => {
            if (!dataAddress.find(d => d.country_id === data.country_id)) {
                dataAddress.push(data)
            }
        })

        for (const d of dataAddress) {
            const finded = tableAddress
                .getDataList()
                .find(
                    dataTable =>
                        dataTable.country_id === d.country_id &&
                        dataTable.is_main
                )
            if (!finded) {
                pass = false
                tableAddress.setMessage(
                    'Se requiere de al menos una dirección principal por país'
                )
                break
            }
        }

        return pass
    })

    observer<BodyAgent>(parseBody)

    return submit
}
