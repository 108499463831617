import { parse } from 'fter'
import { ChangeEvent, useEffect, useState } from 'react'
import Checkbox from '../../../../../../components/Checkbox'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../../../components/DataTable'
import Loading from '../../../../../../components/Loading'
import Product from '../../../../../../models/Product'
import { get } from '../../../../../../utilities/http'
import { storagePage } from '../../../../storage'
import { TableProductsProps } from './types'

export default function TableProducts({
    rcIds,
    productIds,
    setProductIds
}: TableProductsProps) {
    const company_id = storagePage.logistical_declaration.company_id
    const queries = parse(
        {
            reception_ids: rcIds.map(rcId => rcId)
        },
        `/v5.6/apis/companies/${company_id}/receptions/products`
    )

    const [isLoading, setLoading] = useState(false)
    const [products, setProducts] = useState<Product[]>([])

    useEffect(() => {
        setProducts([])
        if (rcIds.length === 0) return
        setLoading(true)
        get<Product[]>(queries).then(({ data, error }) => {
            if (!error) {
                setProducts(data.result)
            }
            setLoading(false)
        })
    }, [rcIds.length])

    const isChecked = (productId: number) =>
        !!productIds.find(id => id === productId)

    function checkHandler(ev: ChangeEvent<HTMLInputElement>) {
        const productId = Number(ev.target.value)
        if (isChecked(productId)) {
            setProductIds(productIds.filter(id => id !== productId))
        } else {
            setProductIds([...productIds, productId])
        }
    }

    return (
        <DataTable>
            <THead>
                <tr>
                    <th>Productos a costear</th>
                    <th />
                </tr>
            </THead>
            <TBody>
                {products.map(product => (
                    <tr key={product.id}>
                        <td>{product.description}</td>
                        <td>
                            <Checkbox
                                value={product.id}
                                checked={isChecked(product.id)}
                                onChange={checkHandler}
                            />
                        </td>
                    </tr>
                ))}
                {products.length === 0 && !isLoading && (
                    <Caption colSpan={2}>No hay productos</Caption>
                )}
                {isLoading && (
                    <Caption colSpan={2}>
                        <Loading height="auto" scale={0.8} />
                    </Caption>
                )}
            </TBody>
        </DataTable>
    )
}
