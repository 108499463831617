import { FormControl } from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import { formInformation } from '../../storage'

function SortDescription() {
    const { props, getError } = formInformation.useInput('name')
    const helper = getError(
        'ERROR-0000107',
        'Ya existe un producto con el mismo nombre'
    )

    return (
        <FormControl label="Descripción corta" required>
            <InputField
                {...props}
                placeholder="Ingresar descripción corta"
                autoFocus
                helper={helper || props.helper}
                regex={null}
            />
        </FormControl>
    )
}

function ManufacturerCode() {
    const { props, getError } = formInformation.useInput('manufacturer_code')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un código de fabricante con el mismo nombre'
    )
    return (
        <FormControl label="Código de fabricante" required>
            <InputField
                {...props}
                placeholder="Ingresar código de fabricante"
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

function Presentation() {
    const { props } = formInformation.useInput('presentation')
    return (
        <FormControl label="Presentación/Envase">
            <InputField
                {...props}
                placeholder="Ingresar presentación de producto"
                maxLength={255}
            />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <SortDescription />
            <ManufacturerCode />
            <Presentation />
        </>
    )
}
