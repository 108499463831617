import Company from '../../models/Company'
import Country from '../../models/Country'
import User from '../../models/User'
import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
interface CompanyData extends Company {
    country: Country
}
export interface UserData extends User {
    principal_company: CompanyData
}
export const controlList = new ControlList<UserData>(Keys.MAIN, {
    path: '/v5.6/apis/users',
    theads: [
        '#',
        { value: 'name', label: 'Nombre' },
        'Compañía origen',
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})
