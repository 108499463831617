import ControlTabs from '../../utilities/ControlTabs'
import { StoragePage } from './types'

export const controlTabsNational = new ControlTabs()
export const controlTabsInternational = new ControlTabs()

export const storagePage: StoragePage = {
    logistical_declaration: null,
    mode: 'creation',
    type: 'national',
    step1Done: false,
    type_logistical_declaration_id: 1, // Internacional (1), Nacional (2)
    version: '',
    currentCurrency: null,
    currentFobDolar: 0,
    currentFobLocal: 0,
    currentCifDolar: 0,
    currentCifLocal: 0,
    currentReception: null,
    total_cost: 0,
    currentSymbolReception: '',
    timezone: ''
}
