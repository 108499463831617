import { StoragePage } from './types'

export const storagePage: StoragePage = {
    reports: [],
    fields: [],
    importState: 'nothing',
    messagesResult: [],
    file: null,
    excelDataRendered: null
}
