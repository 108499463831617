import Option from '../../../../components/Option'
import writeXlsxFile from 'write-excel-file'
import { get } from '../../../../utilities/http'
import { buildPath } from '../../../../sections/ModalPrint/utils'
import {
    GroupLogisticalConcept,
    LogisticalDeclarationExcelData
} from '../../types'
import { controlList } from '../../storage'
import { getRowShipment } from './rows/getRowShipment'
import { getRowMonth } from './rows/getRowMonth'
import { getRowReceptions } from './rows/getRowReceptions'
import { getRowPurchases } from './rows/getRowPurchases'
import { getRowFob } from './rows/getRowFob'
import { getRowEmpty } from './rows/getRowEmpty'
import { getRowProvider } from './rows/getRowProvider'
import { getRowNumberDeclaration } from './rows/getRowNumberDeclaration'
import { getRowAgent } from './rows/getRowAgent'
import { getRowCif } from './rows/getRowCif'
import { getColumnSize } from './rows/getColumnSize'
import { getRowDuty } from './rows/getRowDuty'
import { getRowIva } from './rows/getRowIva'
import { getRowDutyPlusIva } from './rows/getRowDutyPlusIva'
import { getRowHeaderCosts } from './rows/getRowHeaderCosts'
import { getRowsNacionalizationCosts } from './rows/getRowsNacionalizationCosts'
import { getRowsOriginCosts } from './rows/getRowsOriginCosts'
import { getRowsDestinationCosts } from './rows/getRowsDestinationCosts'
import { getRowFinalCost } from './rows/getRowFinalCost'
import { getRowsSettingCosts } from './rows/getRowsSettingCosts'
import { getRowNumberInvoiceFinal } from './rows/getRowNumberInvoiceFinal'
import { getRowNumberInvoiceShipping } from './rows/getRowNumberInvoiceShipping'
import { getRowProviderLocalAmount } from './rows/getRowProviderLocalAmount'

export default function OptionExport() {
    async function download() {
        const [r1, r2] = await Promise.all([
            get<LogisticalDeclarationExcelData[]>(
                buildPath('/v5.6/apis/logistical_declarations/excel', [
                    ['sort', controlList.filter.getParam('sort')],
                    ['search', controlList.filter.getParam('search')],
                    ['company_id', controlList.filter.getParam('company_id')],
                    [
                        'has_document',
                        controlList.filter.getParam('has_document').toString()
                    ],
                    [
                        'working_period_id',
                        controlList.filter.getParam('working_period_id')
                    ]
                ])
            ),
            get<GroupLogisticalConcept[]>(
                '/v5.6/apis/type_logistical_cost_sources/group_logistical_concepts'
            )
        ])
        const data = r1.data.result
        const groupConcepts = r2.data.result

        const dataExcel = [
            getRowShipment(data),
            getRowEmpty('', data),
            getRowMonth(data),
            getRowReceptions(data),
            getRowPurchases(data),
            getRowEmpty('', data),
            getRowNumberInvoiceShipping(data),
            getRowNumberInvoiceFinal(data),
            getRowProvider(data),
            getRowEmpty('', data),
            getRowFob(data),
            getRowEmpty('JDBROKER Salida', data),
            getRowNumberDeclaration(data),
            getRowAgent(data),
            getRowEmpty('', data),
            getRowCif(data),
            getRowProviderLocalAmount(data),
            getRowEmpty('Paletas', data),
            getRowEmpty('Bultos', data),
            getRowEmpty('Peso', data),
            getRowEmpty('', data),
            getRowDuty(data),
            getRowIva(data),
            getRowDutyPlusIva(data),
            getRowEmpty('', data),
            getRowEmpty('', data),
            getRowHeaderCosts(data),
            ...getRowsNacionalizationCosts(data, groupConcepts),
            ...getRowsOriginCosts(data, groupConcepts),
            ...getRowsDestinationCosts(data, groupConcepts),
            ...getRowsSettingCosts(data, groupConcepts),
            getRowEmpty('', data),
            getRowFinalCost(data)
        ]
        await writeXlsxFile(dataExcel, {
            columns: getColumnSize(data),
            fileName: 'Reporte de costos.xlsx'
        })
    }

    return (
        <Option
            label="Exportar en Excel"
            value="3"
            icon="download"
            onClick={download}
        />
    )
}
