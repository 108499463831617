import { render } from 'redity'
import { FormControl } from '../../../../components/FormUtilities'
import NumeralField from '../../../../components/NumeralField'
import { ModalTools } from '../../../../sections/modal/types'
import FormEvents from '../../../../utilities/FormEvents'
import { Keys } from '../../constants'
import { formPrice, storagePage, tableRequisition } from '../../storage'
import { TableRQ } from '../../types'

export default function useProviderPrice(
    control: FormEvents<TableRQ>,
    index: number
) {
    const { symbol, id, quantity_buy, checkbox } = control.store()
    const { props } = control.useValue('provider_price')

    function handleSubmit(ev, modal: ModalTools) {
        ev.preventDefault()
        const result = formPrice.test(f => {
            f.price
                .isEmpty('Se requiere ingresar el precio')
                .isMinor(1, 'El valor mínimo es 1')
        })

        if (result) {
            modal.close()
            tableRequisition.put(index, {
                provider_price: parseFloat(formPrice.store().price)
            })
            storagePage.req_products_actived[id] = {
                provider_price: parseFloat(formPrice.store().price),
                quantity_buy
            }
            render(Keys.MOUNT)
        }
    }

    const open = formPrice.useModal(Form, {
        title: 'Editar precio',
        onSubmit: handleSubmit,
        size: 'sm'
    })

    return {
        symbol,
        provider_price: props.value.toFixed(2),
        open,
        quantity_buy,
        checkbox
    }
}

function Form() {
    const { props, getError } = formPrice.useInput('price')
    const helper = getError(null, null, 'unit_purchase_price')

    return (
        <FormControl label="Precio($)" required>
            <NumeralField
                {...props}
                autoFocus
                helper={props.helper || helper}
                max={999999999.99}
                decimal={2}
            />
        </FormControl>
    )
}
