import { IterateOptions } from '../../../components/FormUtilities'
import SelectMultiple from '../../../components/SelectMultiple'
import useStorage from '../../../hooks/useStorage'
import buildCompanyName from '../../../utilities/buildCompanyName'
import FormEvents from '../../../utilities/FormEvents'
import { DataList } from '../types'

export default function Companies({
    control
}: {
    control: FormEvents<DataList>
}) {
    const { storage } = useStorage()
    const { props } = control.useSelects('cduSelecteds')
    const data = control.store().cduOptions.map(cdu => {
        const company = storage.companies.find(
            _company => _company.id === cdu.company_id
        )
        return {
            name: buildCompanyName(cdu.name),
            id: cdu.id,
            ...(company && { image_url: company.country.image_url })
        }
    })

    return (
        <td>
            <SelectMultiple
                {...props}
                placeholder="Seleccionar una compañía"
                label="Compañías"
                selectTouchInherit={true}
            >
                <IterateOptions data={data} label="name" image="image_url" />
            </SelectMultiple>
        </td>
    )
}
