import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import ControlTabs from '../../utilities/ControlTabs'
import ControlRequest from '../../utilities/ControlRequest'
import { FilterForm, StoragePage, TraspaseItem } from './types'

export const controlTabs = new ControlTabs()

export const controlList = new ControlList<TraspaseItem>(Keys.MAIN, {
    path: '/v5.6/apis/traspases',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'requesting_user_name', label: 'Solicitante' },
        { value: 'warehouse_name', label: 'Almacén' },
        'Bodega origen',
        'Bodega destino',
        { value: 'decision_date', label: 'Fecha de aprobación' },
        'Acciones'
    ]
})
controlList.badgeRemove('company_id', false)

export const filterForm = new FormEvents<FilterForm>(Keys.FILTER_FORM, {
    warehouse_id: null,
    stowage_id: null,
    minimal_stowage_id: null,
    stowage_destination_id: null,
    minimal_stowage_destination_id: null
})

export const reqWarehouse = new ControlRequest()
export const reqStowageOrigin = new ControlRequest()
export const reqSubStowageOrigin = new ControlRequest()
export const reqStowageDestination = new ControlRequest()
export const reqSubStowageDestination = new ControlRequest()

export const storagePage: StoragePage = {
    company_enabled: false,
    timezone: ''
}

function resetSubStowages() {
    filterForm.setProps(
        'stowage_destination_id',
        {
            disabled: true
        },
        false
    )
    filterForm.setProps(
        'minimal_stowage_destination_id',
        {
            disabled: true
        },
        false
    )
    controlList.filter.deleteFilter('stowage_destination_id')
    filterForm.setValue('stowage_destination_id', null)
    controlList.filter.deleteFilter('minimal_stowage_destination_id')
    filterForm.setValue('minimal_stowage_destination_id', null)
}

controlList.onDeleteBadge = key => {
    if (key === 'stowage_id') {
        controlList.filter.deleteFilter('minimal_stowage_id')
        filterForm.setProps('minimal_stowage_id', { disabled: true }, false)
        filterForm.setValue('minimal_stowage_id', null)
        resetSubStowages()
    }

    if (key === 'minimal_stowage_id') {
        resetSubStowages()
    }
}
