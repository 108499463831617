import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useRender } from 'redity'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import { storagePage } from './storage'
import { SubcategoryValuesData } from './types'
import { Keys } from './constants'

export default function useInitial() {
    const { subcategory_id } = useParams<{ subcategory_id: string }>()
    const [pageState, setPageState] = useState(StateCase.LOADING)
    useRender(Keys.MAIN)

    useEffect(() => {
        get<SubcategoryValuesData[]>(
            `/v5.6/apis/subcategory/${subcategory_id}/subcategory_values`
        ).then(result => {
            if (result.error) {
                setPageState(StateCase.ERROR)
                return
            }

            storagePage.subcategoryValues = result.data.result
            setPageState(StateCase.SUCCESS)
        })
    }, [])

    return pageState
}
