import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowPurchases(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [{ value: 'OC', fontWeight: 'bold' }]
    data.forEach(d => {
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'center',
            fontWeight: 'bold',
            value: d.logistical_declaration_receptions
                .map(ldr => ldr.purchase.code)
                .join('/')
        })
        return row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
