import modal from '../../sections/modal'
import storage, {
    controlList,
    formPresentation,
    tablePresentations
} from './storage'
import { PresentationBody } from './types'
import Form from './View/Form'
import loadGroupPresentation from './utilities/loadGroupPresentation'
import setParamsPresentations from './utilities/setParamsPresentations'

export default function useSubmit(groupPresentationId?: number) {
    const { submit, validation, observer } = formPresentation.useSubmit(
        groupPresentationId
            ? `/v5.6/apis/group_presentations/${groupPresentationId}`
            : '/v5.6/apis/group_presentations',
        {
            method: groupPresentationId ? 'put' : 'post',
            done: controlList,
            expectChange: false
        }
    )

    validation(v => {
        v.name.minLength(2, 'El número de caracteres válidos es 2 - 180')
        v.name.isEmpty('Se requiere ingresar el nombre')

        return tablePresentations.test((_v, d) => {
            _v.amount.condition(value => {
                if (
                    tablePresentations
                        .getDataList()
                        .find(data => data.amount === value && data.id !== d.id)
                ) {
                    return 'No pueden existir valores repetidos'
                }
            })
            _v.amount.isMinor(1, 'El valor mínimo es 1')
            _v.amount.isEmpty('Se requiere ingresar la presentación')
        })
    })

    observer<PresentationBody>(form => ({
        name: form.name.trim(),
        presentation_products: tablePresentations.getDataList().map(data => ({
            amount: parseInt(data.amount),
            product_id: data.id
        }))
    }))

    return function openModal() {
        storage.currentGroupPresentation = null
        storage.products.sets(
            storage.products.getList().map(product => ({
                ...product,
                amount: ''
            }))
        )
        if (!groupPresentationId) {
            formPresentation.init()
            tablePresentations.setList([])
            modal(
                Form,
                {
                    title: 'Nuevo grupo de presentación',
                    onSubmit: submit
                },
                'md'
            )
            setParamsPresentations()
        } else {
            modal(
                Form,
                async () => {
                    const result = await loadGroupPresentation(
                        groupPresentationId
                    )
                    if (!result) return null
                    setParamsPresentations()

                    return {
                        title: 'Editar grupo de presentación',
                        onSubmit: submit
                    }
                },
                'md'
            )
        }
    }
}
