import moment from 'moment'
import FileView from '../../../components/FileView'
import Ul from '../../../components/Ul'
import useStorage from '../../../hooks/useStorage'
import { parseDriveFile } from '../../../utilities/fileTools'
import { LicenseData } from '../types'

export default function InfoLicense({ data }: { data: LicenseData }) {
    const { storage } = useStorage()
    const {
        company_id,
        code,
        issue_date,
        name,
        due_date,
        description,
        license_type_licenses,
        files
    } = data.formated_value
    const company = storage.companies.find(comp => comp.id === company_id)
    return (
        <div className="grid grid-cols-2 gap-x-10 gap-y-4">
            <Ul>
                <li className="flex">
                    Compañía:{' '}
                    <div className="flex">
                        <img
                            src={company.country.image_url}
                            alt=""
                            className="w-5 mr-1 ml-1"
                        />
                        <b className={`${!company.memory && 'line-through'}`}>
                            {company.name}
                        </b>
                    </div>
                </li>
                <li>
                    Código: <b>{code}</b>
                </li>
                <li>
                    Fecha de emisión:{' '}
                    <b>{moment(issue_date).format('DD/MM/YYYY')}</b>
                </li>
                <li>
                    Tipo de licencia:{' '}
                    <b>
                        {license_type_licenses &&
                            license_type_licenses
                                .filter(ltl => ltl.memory)
                                .map(ltl => ltl.type_license.name)
                                .join(' - ')}
                    </b>
                </li>
            </Ul>
            <Ul>
                <li>
                    Licencia: <b>{name}</b>
                </li>
                <li>
                    Versión: <b>v{data.version}</b>
                </li>
                <li>
                    Fecha de vencimiento:{' '}
                    <b>
                        {due_date
                            ? moment(due_date).format('DD/MM/YYYY')
                            : ' - '}
                    </b>
                </li>
            </Ul>
            <Ul className="col-span-2 mb-4">
                <li>
                    Descripción: <b>{description}</b>
                </li>
            </Ul>
            {files &&
                files.map(file => (
                    <FileView key={file.id} file={parseDriveFile(file)} />
                ))}
        </div>
    )
}
