import { ExcelRendered } from '../../../components/ExcelField/types'
import { storagePage } from '../storage'

export default function validateFormatExcel(excelDataRendered: ExcelRendered) {
    const rows = excelDataRendered.rows.filter(row => row.length > 0)
    const rowsHead = rows[0] || []

    for (const fieldName of storagePage.fields) {
        if (
            !rowsHead.find(
                label => clearText(label as string) === clearText(fieldName)
            )
        ) {
            return false
        }
    }

    return true
}

function clearText(text: string) {
    const ofThis = 'ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç'
    const to = 'AAAAAEEEEIIIIOOOOUUUUNCaaaaaeeeeiiiioooouuuunc'
    const regex = new RegExp('[' + ofThis + ']', 'ug')

    return text
        .trim()
        .toLowerCase()
        .replace(regex, match => to.charAt(ofThis.indexOf(match)))
}
