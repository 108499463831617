import BtnActionTable from '../../../components/BtnActionTable'
import Enable from '../../../components/Enable'
import Popover from '../../../components/Popover'
import Rules from '../../../components/Rules'
import Brand from '../../../models/Brand'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { controlList } from '../storage'
import useSubmit from '../useSubmit'
import BrandActivateDeactivate from './BrandActivateDeactivate'

function Edit({ brand }: { brand: Brand }) {
    const open = useSubmit(brand.id)
    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={() => open(brand)}
        />
    )
}

export default function TableBrands() {
    return (
        <List control={controlList}>
            {(brand, i) => (
                <tr key={brand.id}>
                    <td>{i}</td>
                    <td>{brand.code}</td>
                    <td>
                        <p className={!brand.is_enabled ? 'line-through' : ''}>
                            {brand.name}
                        </p>
                    </td>
                    <td>
                        <Enable enabled={brand.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU037">
                                <Edit brand={brand} />
                            </Rules>
                            <Rules cu="CU040">
                                <Delete
                                    path={`/v5.6/brands/${brand.id}`}
                                    control={controlList}
                                >
                                    ¿Está seguro eliminar la marca{' '}
                                    <b className="text-primary">{brand.name}</b>
                                    ?
                                </Delete>
                            </Rules>
                            <Rules cu={brand.is_enabled ? 'CU038' : 'CU039'}>
                                <Popover
                                    component={
                                        <BtnActionTable iconName="bs-three-dots" />
                                    }
                                    position="bottom-right-left"
                                    element=".panel--form-datatable"
                                >
                                    <BrandActivateDeactivate brand={brand} />
                                </Popover>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
