import { mergeExpect } from '../../../utilities/dataTools'
import { storagePage, tableAddress, tableContacts } from '../storage'
import { BodyAgent, FormAgent } from '../types'

export default function parseBody(form: FormAgent): BodyAgent {
    const forContactsCreation = () =>
        tableContacts.getDataList().map(contact => ({
            is_main: contact.is_main,
            name: contact.name,
            position: contact.position,
            phone_number: contact.phone_number,
            email: contact.email
        }))

    const forContactsEdition = () =>
        mergeExpect(
            tableContacts.getDataList(),
            storagePage.agent.agent_contacts
        )
            .mapping(data => ({
                ...(data.id && { id: data.id, deleted: false }),
                is_main: data.is_main,
                name: data.name,
                position: data.position,
                phone_number: data.phone_number,
                email: data.email
            }))
            .deleted(agent_contact => ({
                id: agent_contact.id,
                deleted: true
            }))

    const forAddressCreation = () =>
        tableAddress.getDataList().map(dataAddress => ({
            is_main: dataAddress.is_main,
            country_id: dataAddress.country_id,
            address: dataAddress.address,
            locate: dataAddress.locate
        }))

    const forAddressEdition = () =>
        mergeExpect(
            tableAddress.getDataList(),
            storagePage.agent.agent_withdrawal_addresses
        )
            .mapping(dataAddress => ({
                is_main: dataAddress.is_main,
                country_id: dataAddress.country_id,
                address: dataAddress.address,
                locate: dataAddress.locate,
                ...(dataAddress.id && {
                    id: dataAddress.id,
                    deleted: false
                })
            }))
            .deleted(agent_withdrawal_address => ({
                id: agent_withdrawal_address.id,
                deleted: true
            }))

    return {
        country_id: parseInt(form.country.value),
        type_agent_ids: form.type_agents.map(type_agent =>
            parseInt(type_agent.value)
        ),
        name: form.name,
        fiscal_address: form.fiscal_address,
        agent_contacts:
            storagePage.mode === 'creation'
                ? forContactsCreation()
                : forContactsEdition(),
        agent_withdrawal_address:
            storagePage.mode === 'creation'
                ? forAddressCreation()
                : forAddressEdition()
    }
}
