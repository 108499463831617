import * as React from 'react'

function Download(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.706 4.333h-2.8v-4h-4.2v4H.908L5.807 9l4.899-4.667zm-5.6 1.334v-4h1.4v4h.82l-1.52 1.446-1.518-1.446h.819zm5.6 6v-1.334H.907v1.334h9.799z"
            />
        </svg>
    )
}

export default Download
