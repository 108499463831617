import { render } from 'redity'
import { Option } from '../../../config/types/component'
import useGet from '../../../hooks/useGet'
import Stowage from '../../../models/Stowage'
import { MinimalStowageResponse } from '../../InputParts/types'
import {
    filterForm,
    reqSubStowageDestination,
    reqSubStowageOrigin
} from '../storage'

export default function useSubStowageOrigin() {
    const { props } = filterForm.useSelect<Stowage>('minimal_stowage_id')
    const { data, props: propsSearch } = useGet<MinimalStowageResponse[]>(
        reqSubStowageOrigin.observer(
            `/v5.6/apis/stowages/:stowage_id/substowages`,
            reqSubStowageOrigin.hasParam('stowage_id')
        ),
        []
    )

    function handleChange(option: Option) {
        if (option.value === '0') {
            filterForm.setValue('minimal_stowage_id', null)
            filterForm.setValue('minimal_stowage_destination_id', null)
        } else {
            props.onChange(option)
            if (reqSubStowageDestination.hasQuery('destination_stowage_id')) {
                filterForm.setProps('minimal_stowage_destination_id', {
                    disabled: false
                })
                reqSubStowageDestination.load()
            }
        }
        render(filterForm.keyRender, 'minimal_stowage_destination_id')
    }

    function disable(value: string) {
        const { minimal_stowage_destination_id } = filterForm.store()

        if (minimal_stowage_destination_id?.value === value) return true
        return false
    }

    return {
        props,
        propsSearch,
        data,
        handleChange,
        disable
    }
}
