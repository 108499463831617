import { data } from '../storage'

interface CompanyDepartmentRoleUserBody {
    company_department_role_users: CompanyDepartmentRoleUserData[]
}

interface CompanyDepartmentRoleUserData {
    id?: number
    role_id?: number
    is_enabled: boolean
    company_department_user_id?: number
}

export default function getBody(): CompanyDepartmentRoleUserBody {
    const body: CompanyDepartmentRoleUserBody = {
        company_department_role_users: []
    }

    Object.values(data.controlLists).forEach(control => {
        for (const dataOfList of control.list) {
            const { role_id, cduSelecteds, cduOptions } = dataOfList.store()
            cduOptions.forEach(op => {
                const cdu = cduSelecteds.find(
                    _cdu => parseInt(_cdu.value) === op.id
                )
                if (op.company_department_role_user_id) {
                    body.company_department_role_users.push({
                        id: op.company_department_role_user_id,
                        is_enabled: !!cdu
                    })
                } else {
                    if (!cdu) return
                    body.company_department_role_users.push({
                        role_id,
                        is_enabled: true,
                        company_department_user_id: op.id
                    })
                }
            })
        }
    })

    return body
}
