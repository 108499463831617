import parseError from './parseError'
import { expectIsAPIFake, getPathWithoutParams, parsePath } from './tools'
import { HttpResponse, Method } from './type'

function simulateResponseError(data) {
    return {
        message: '',
        response: {
            data
        }
    }
}

export default function build(
    headers: Headers,
    signals: Map<string, AbortController>
) {
    const httpInit: RequestInit = {
        headers
    }

    async function http<T>(
        path: string,
        method: Method,
        body?: Record<string, any>
    ): Promise<HttpResponse<T>> {
        const controller = new AbortController()

        signals.set(getPathWithoutParams(path), controller)

        const parsedPath = parsePath(path)
        const expect = expectIsAPIFake(parsedPath)
        const init = {
            ...httpInit,
            method,
            signal: controller.signal,
            ...(body && { body: JSON.stringify(body) })
        }

        const base = expect.isFake ? http.baseFake : http.baseUrl
        try {
            const response = await fetch(`${base}${expect.path}`, init)
            const data = await response.json()
            const errors = data?.errors
            if (
                (errors && Array.isArray(errors) && errors) ||
                response.status === 400 ||
                response.status === 405 ||
                response.status === 500
            ) {
                throw simulateResponseError(data)
            }
            return { error: null, data }
        } catch (error) {
            return {
                error: parseError(error),
                data: null
            }
        }
    }

    http.baseUrl = ''
    http.baseFake = ''

    return http
}
