import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import { StateCase } from '../../../config/enums'
import ImportSection from './ImportSection'
import ImportResult from './ImportResult'
import useImport from './useImport'
import useInitial from '../useInitial'
import { useHistory } from 'react-router-dom'

export default function ImportProducts() {
    useInitial()

    return (
        <Initial pageState={StateCase.SUCCESS}>
            <Header />
            <div className="flex">
                <div
                    style={{
                        width: '360px',
                        minWidth: '360px'
                    }}
                    className="mr-8"
                >
                    <Panel mode="form">
                        <PanelHeader title="Carga de importación" />
                        <PanelBody>
                            <ImportSection />
                        </PanelBody>
                    </Panel>
                </div>
                <Panel mode="form">
                    <PanelHeader title="Resultados de importación" />
                    <PanelBody>
                        <ImportResult />
                    </PanelBody>
                </Panel>
            </div>
        </Initial>
    )
}

function Header() {
    const { handleClick, isLoading, isDisabled, canItBeCancelled } = useImport()
    const { push } = useHistory()

    return (
        <PageHeader subtitle="Importación de productos">
            <Button
                color="ghost"
                disabled={canItBeCancelled}
                onClick={() => push('/configuration/products')}
            >
                Cancelar
            </Button>
            <Button
                loading={isLoading}
                className="ml-2"
                onClick={() => handleClick()}
                disabled={isDisabled}
            >
                Importar
            </Button>
        </PageHeader>
    )
}
