import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import FileView from '../../../../components/FileView'
import Typography from '../../../../components/Typography'
import Ul from '../../../../components/Ul'
import { parseDriveFile } from '../../../../utilities/fileTools'
import { ViewProductProps } from './types'

export default function Info({ data }: ViewProductProps) {
    const { product, presentations } = data

    return (
        <>
            <Typography className="mb-1" type="header-3">
                Información de producto
            </Typography>
            <div className="grid grid-cols-2 gap-4 mb-6">
                <Ul>
                    <li>
                        Descripción corta: <b>{product.name}</b>
                    </li>
                    <li>
                        Presentación/Envase:{' '}
                        <b>{product.presentation || '-'}</b>
                    </li>
                    <li>
                        Marca: <b>{product.brand.name}</b>
                    </li>
                    <li>
                        Tipo de producto: <b>{product.type_product.name}</b>
                    </li>
                    <li>
                        Producto precursor:{' '}
                        <b>{product.is_precursor ? 'Si' : 'No'}</b>
                    </li>
                    <li>
                        Modelo: <b>{product.model || '-'}</b>
                    </li>
                </Ul>
                <Ul>
                    <li>
                        Código de fabricante: <b>{product.manufacturer_code}</b>
                    </li>
                    <li>
                        Rango: <b>{product.rank || '-'}</b>
                    </li>
                    <li>
                        Cantidad: <b>{product.quantity}</b>
                    </li>
                    <li>
                        Tipo de comunicación:{' '}
                        <b>
                            {product.product_type_communications
                                .filter(ptc => ptc.memory)
                                .map(ptc => ptc.type_communication.name)
                                .join(', ')}
                        </b>
                        {product.product_type_communications.length === 0 && (
                            <b>-</b>
                        )}
                    </li>
                    <li>
                        Producto hazardous:{' '}
                        <b>{product.is_hazardous ? 'Si' : 'No'}</b>
                    </li>
                </Ul>
                {product.files.map(f => (
                    <FileView key={f.id} file={parseDriveFile(f)} />
                ))}
            </div>
            <Typography type="header-3" className="mb-1">
                Codificación de producto
            </Typography>
            <div className="grid grid-cols-2 gap-4 mb-6">
                <Ul>
                    <li>
                        Categoría: <b>{product.subcategory.category.name}</b>
                    </li>
                    <li>
                        Subcategoría: <b>{product.subcategory.name}</b>
                    </li>
                </Ul>
                <Ul>
                    <li>
                        Código del producto: <b>{product.code}</b>
                    </li>
                </Ul>
            </div>
            <Typography type="header-3" className="mb-1">
                Presentaciones relacionadas
            </Typography>
            <DataTable>
                <THead>
                    <tr>
                        <th>Producto</th>
                        <th>Presentación</th>
                    </tr>
                </THead>
                <TBody>
                    {presentations.map(presentation => (
                        <tr key={presentation.id}>
                            <td>
                                <p>{presentation.product.description}</p>
                                <span className="text-xs">
                                    {presentation.product.code}
                                </span>
                            </td>
                            <td>{presentation.amount}</td>
                        </tr>
                    ))}
                    {presentations.length === 0 && (
                        <Caption colSpan={2}>No existen registros</Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
