import { render } from 'redity'
import { Option } from '../../../../config/types/component'
import useStorage from '../../../../hooks/useStorage'
import { setCompanySelected } from '../../../../utilities/controlStorage'
import { formInformation, productsControl, reqReception } from '../../storage'

export default function useCompany() {
    const { props } = formInformation.useSelect('company')
    const { companiesFromSession } = useStorage()

    const companies = companiesFromSession.map(company => ({
        id: company.id,
        name: company.name,
        image: company.country.image_url
    }))

    function handleChange(option: Option) {
        formInformation.setValue('purchaseReceipt', null)
        formInformation.setValue('purchaseCost', '')
        productsControl.setList([])
        reqReception.setQuery('company_id', option.value)
        setCompanySelected(
            companiesFromSession.find(
                company => parseInt(option.value) === company.id
            )
        )
        reqReception.load()
        props.onChange(option)
        render(formInformation.keyRender, 'estimatedAmount')
        render('ContainerTable')
    }

    return {
        props,
        companies,
        handleChange
    }
}
