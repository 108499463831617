import Icon from '../../../../components/Icon'
import RadioButton from '../../../../components/RadioButton'
import Tooltip from '../../../../components/Tooltip'
import Typography from '../../../../components/Typography'
import Ul from '../../../../components/Ul'
import { TableList } from '../../../../sections/ListForm'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import FormEvents from '../../../../utilities/FormEvents'
import { listProductProviderCompany, productCompanyData } from '../../storage'
import { TableProductProviderCompanyData } from '../../types'

function Main({
    control
}: {
    control: FormEvents<TableProductProviderCompanyData>
}) {
    const { props } = control.useCheck('is_main')
    function handleChange(ev: any) {
        props.onChange(ev)
        const checked = ev.target.checked
        if (checked) {
            const { id } = control.store()
            listProductProviderCompany.list.forEach(c => {
                if (c.store().id !== id) {
                    c.setValue('is_main', false)
                }
            })
        }
    }

    return <RadioButton {...props} onChange={handleChange} />
}

export default function AssignProvider() {
    return (
        <>
            <Ul>
                <li>
                    Compañía compradora:{' '}
                    <b>
                        {productCompanyData?.productCompany
                            ? buildCompanyName(
                                  productCompanyData?.productCompany?.company
                                      .name
                              )
                            : ''}
                    </b>
                </li>
                <li>
                    Producto:{' '}
                    <b>
                        {
                            productCompanyData?.productCompany?.product_country
                                ?.product.name
                        }
                    </b>
                </li>
            </Ul>
            <Typography className="mb-2 mt-4" type="header-3">
                Lista de proveedores <ErrorHelperTable />
            </Typography>

            <TableList control={listProductProviderCompany} scrollHeight={250}>
                {(productProviderCompany, control) => (
                    <tr key={productProviderCompany.id}>
                        <td>
                            <img
                                src={productProviderCompany.country_image}
                                className="w-8 mr-2 inline"
                            />
                            {productProviderCompany.name}
                        </td>
                        <td>
                            {productProviderCompany.currency}{' '}
                            {productProviderCompany?.purchase_price
                                ? buildNumberFormat(
                                      productProviderCompany?.purchase_price
                                  )
                                : ''}
                        </td>
                        <td>
                            <Main control={control} />
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}

function ErrorHelperTable() {
    const { message } = listProductProviderCompany.useHelpers()

    if (!message) return null

    return (
        <Tooltip content={message}>
            <Icon name="warning" className="text-error" />
        </Tooltip>
    )
}
