import { Link } from 'react-router-dom'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import Rules from '../../../components/Rules'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import Country from '../../../models/Country'
import { controlList } from '../storage'

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por razón social o nombre comercial"
            size="auto"
            fullWidth={true}
        />
    )
}

function CountrySelect() {
    const { props, data } = controlList.useFilter<Country>(
        'country_id',
        '/v5.6/apis/countries?sort=name'
    )

    return (
        <Dropdown {...props} label="País">
            <IterateOptions data={data} label="name" image="image_url" />
        </Dropdown>
    )
}

export default function FilterProviders() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <CountrySelect />
                </FlexLeft>
                <FlexRight>
                    <Rules cu="CU083">
                        <Link to="/configuration/providers/creation">
                            <Button size="sm">Nuevo</Button>
                        </Link>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
