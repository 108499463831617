import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import moment from 'moment'
import { Head, Kardex } from '../../types'
import { controlList, storagePage } from './../../storage'
import euro from '../../../../assets/img/euro.jpg'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header1: {
        width: '10%',
        fontSize: '9px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header2: {
        width: '8%',
        fontSize: '9px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header3: {
        width: '8%',
        fontSize: '9px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header4: {
        width: '6%',
        fontSize: '9px',
        fontWeight: 'extrabold',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    item1: {
        width: '10%',
        fontSize: '8px',
        padding: '5px',
        height: '100%',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textAlign: 'center'
    },
    item2: {
        width: '8%',
        fontSize: '8px',
        padding: '5px',
        height: '100%',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textAlign: 'center'
    },
    item3: {
        width: '8%',
        fontSize: '8px',
        padding: '5px',
        height: '100%',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textAlign: 'center'
    },
    item4: {
        width: '6%',
        fontSize: '8px',
        padding: '5px',
        height: '100%',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textAlign: 'center'
    },
    caption: {
        width: '100%',
        fontSize: '10px',
        padding: '5px',
        textAlign: 'center'
    }
})

export default function Table2({ data }: { data: Kardex[] }) {
    const head: Head = controlList.response?.head
    const currency = storagePage.currency

    return (
        <>
            <View style={styles.row} fixed>
                <Text style={styles.header2}>Número</Text>
                <Text style={styles.header2}>Transacción</Text>
                <Text style={styles.header2}>Fecha</Text>
                <Text style={styles.header1}>Bodega</Text>
                <Text style={styles.header1}>Tipo</Text>
                <Text style={styles.header4}>Entrada</Text>
                <Text style={styles.header4}>Salida</Text>
                <Text style={styles.header4}>Saldo</Text>
                <Text style={styles.header3}>
                    {currency.symbol === '₡' ? (
                        <Image src={euro} />
                    ) : (
                        `(${currency.symbol})`
                    )}{' '}
                    V.I.U.
                </Text>
                <Text style={styles.header3}>
                    {currency.symbol === '₡' ? (
                        <Image src={euro} />
                    ) : (
                        `(${currency.symbol})`
                    )}{' '}
                    V.P.U.
                </Text>
                <Text style={styles.header3}>
                    {currency.symbol === '₡' ? (
                        <Image src={euro} />
                    ) : (
                        `(${currency.symbol})`
                    )}{' '}
                    Debe
                </Text>
                <Text style={styles.header3}>
                    {currency.symbol === '₡' ? (
                        <Image src={euro} />
                    ) : (
                        `(${currency.symbol})`
                    )}{' '}
                    Haber
                </Text>
                <Text style={styles.header3}>
                    {currency.symbol === '₡' ? (
                        <Image src={euro} />
                    ) : (
                        `(${currency.symbol})`
                    )}{' '}
                    Saldo
                </Text>
            </View>
            {head && data.length > 0 && (
                <View style={styles.row}>
                    <Text style={styles.item2}></Text>
                    <Text style={styles.item2}>
                        {head ? head.transaction : ''}
                    </Text>
                    <Text style={styles.item2}></Text>
                    <Text style={styles.item1}></Text>
                    <Text style={styles.item1}></Text>
                    <Text style={styles.item4}>{head ? head.input : '-'}</Text>
                    <Text style={styles.item4}>{head ? head.output : ''}</Text>
                    <Text style={styles.item4}>
                        {head ? head.total_inventory : '-'}
                    </Text>
                    <Text style={styles.item3}>
                        {head ? buildNumberFormat(head.income_unit_cost) : '-'}
                    </Text>
                    <Text style={styles.item3}>
                        {head ? buildNumberFormat(head.average_unit_cost) : '-'}
                    </Text>
                    <Text style={styles.item3}>
                        {head ? buildNumberFormat(head.has_to) : '-'}
                    </Text>
                    <Text style={styles.item3}>
                        {head ? buildNumberFormat(head.to_have) : '-'}
                    </Text>
                    <Text style={styles.item3}>
                        {head ? buildNumberFormat(head.cost_balance) : '-'}
                    </Text>
                </View>
            )}

            {Array.isArray(data) && data.length > 0 ? (
                data.map((d, i) => (
                    <View style={styles.row} key={i}>
                        <Text style={styles.item2}>{d.document.code}</Text>
                        <Text style={styles.item2}>
                            {d.type_operation.name}
                        </Text>
                        <Text style={styles.item2}>
                            {moment(d.operation_date).format('DD/MM/YYYY')}
                        </Text>
                        <Text style={styles.item1}>
                            {d.minimal_stowage_name}
                        </Text>
                        <Text style={styles.item1}>
                            {d.document.type_document.name}
                        </Text>
                        <Text style={styles.item4}>{d.input}</Text>
                        <Text style={styles.item4}>{d.output}</Text>
                        <Text style={styles.item4}>{d.total_inventory}</Text>
                        <Text style={styles.item3}>
                            {buildNumberFormat(d.income_unit_cost)}
                        </Text>
                        <Text style={styles.item3}>
                            {buildNumberFormat(d.average_unit_cost)}
                        </Text>
                        <Text style={styles.item3}>
                            {buildNumberFormat(d.hast_to)}
                        </Text>
                        <Text style={styles.item3}>
                            {buildNumberFormat(d.to_have)}
                        </Text>
                        <Text style={styles.item3}>
                            {buildNumberFormat(d.cost_balance)}
                        </Text>
                    </View>
                ))
            ) : (
                <View style={styles.row}>
                    <Text style={styles.caption}>
                        No se encontraron resultados
                    </Text>
                </View>
            )}
        </>
    )
}
