import { GetCreditNote } from '../type'

export default function GeneralInformation({ data }: { data: GetCreditNote }) {
    return (
        <div className="body__cards">
            <div className="column">
                <div className="card_info">
                    <h3>INFORMACIÓN GENERAL</h3>
                    <ul className="list_info">
                        {/* <li>
                            <p>Serie</p>
                            <p>A</p>
                        </li> */}
                        <li>
                            <p>Nota de crédito</p>
                            <p>{data.credit_note_number}</p>
                        </li>
                        {/* <li>
                            <p>Lugar</p>
                            <p>Caracas</p>
                        </li> */}
                        <li>
                            <p>Fecha</p>
                            <p>{data.date}</p>
                        </li>
                        <li>
                            <p>Referencia</p>
                            <p>Sobre Factura {data.invoice_number}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <div className="card_info">
                    <h3>DATOS DEL CLIENTE</h3>
                    <ul className="list_info">
                        <li>
                            <p>Documento fiscal</p>
                            <p>{data.client.document}</p>
                        </li>
                        <li>
                            <p>Empresa</p>
                            <p>{data.client.name}</p>
                        </li>
                        <li>
                            <p>Dirección</p>
                            <p>{data.client.address}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
