import { formRC } from '../View/SectionCosts/storageStep2'

export default function runOperationModalReception() {
    const {
        purchase_dolar_cost,
        exchange_rc_to_dolar,
        exchange_dolar_to_local
    } = formRC.store()
    const dolar_cost_total =
        Number(purchase_dolar_cost || '0') * Number(exchange_rc_to_dolar)
    formRC.setValue('purchase_dolar_cost_total', dolar_cost_total.toFixed(2))
    formRC.setValue('purchase_local_cost', dolar_cost_total.toFixed(2))
    const local_cost_total =
        dolar_cost_total * Number(exchange_dolar_to_local || '0')
    formRC.setValue('purchase_local_cost_total', local_cost_total.toFixed(2))
}
