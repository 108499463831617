import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from './useInitial'
import Initial from '../../../components/Initial'
import FilterCategories from './FilterCategories'
import TableCategories from './TableCategories'

export default function Categories() {
    const state = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterCategories />
                    <TableCategories />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
