import Search from './Search'
import Fields from './Fields'

export default function FormRC() {
    return (
        <>
            <Search />
            <Fields />
        </>
    )
}
