import Logo from '../../../../assets/img/logo.png'
import {
    Page,
    Text,
    Document,
    StyleSheet,
    Image,
    View,
    Font
} from '@react-pdf/renderer'
import Table1 from './Table1'
import Table2 from './Table2'
import Univers from '../../../../assets/fonts/UniversLTStd.otf'
import UniversLTStdBold from '../../../../assets/fonts/UniversLTStdBold.otf'
import { controlList } from './../../storage'
import { getCompanySelected } from '../../../../utilities/controlStorage'
import moment from 'moment'
import { Kardex } from '../../types'

Font.register({ family: 'univers', src: Univers })
Font.register({ family: 'univers-bold', src: UniversLTStdBold })

const styles = StyleSheet.create({
    section: {
        padding: '40px 30px'
    },
    title: {
        fontSize: '16px',
        textAlign: 'center',
        color: '#2A2A5B',
        fontWeight: 'bold',
        fontFamily: 'univers-bold'
    },
    logo: {
        width: '100px',
        marginTop: '10px',
        marginBottom: '10px'
    },
    companyName: {
        fontFamily: 'univers-bold',
        fontSize: '10px',
        marginBottom: '5px',
        color: '#2A2A5B',
        width: '50%'
    },
    companyFiscalDocument: {
        fontFamily: 'univers-bold',
        fontSize: '9px',
        marginBottom: '5px',
        color: '#2A2A5B'
    },
    page: {
        fontFamily: 'univers-bold',
        fontSize: '9px',
        marginBottom: '10px',
        color: '#2A2A5B',
        textAlign: 'right',
        width: '50%'
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        border: '1px solid #E2E2E2',
        marginBottom: '12px'
    },
    flex: {
        flexDirection: 'row',
        alignItems: 'center'
    }
})

export default function Pdf({ data }: { data: Kardex[] }) {
    const { start_date, end_date } = controlList.filter.params

    const company = getCompanySelected()
    return (
        <Document
            title={`${moment(start_date).format('DDMMYYYY')}-${moment(
                end_date
            ).format('DDMMYYYY')}.pdf`}
        >
            <Page size="A4" style={styles.section} orientation="landscape">
                <Text style={styles.title}>KARDEX</Text>
                <Image src={Logo} style={styles.logo} />
                <View style={styles.flex}>
                    <View>
                        <Text style={styles.companyName}>{company.name}</Text>
                        {company.fiscal_document === '' && (
                            <Text style={styles.companyFiscalDocument}>
                                {company.type_fiscal_document}:{' '}
                                {company.fiscal_document}
                            </Text>
                        )}
                    </View>
                    <Text
                        style={styles.page}
                        render={({ pageNumber, totalPages }) =>
                            `Páginas: Pág ${pageNumber} de ${totalPages}`
                        }
                        fixed
                    />
                </View>
                <View style={styles.tableContainer}>
                    <Table1 />
                </View>
                <View style={styles.tableContainer}>
                    <Table2 data={data} />
                </View>
            </Page>
        </Document>
    )
}
