import { render } from 'redity'
import FormEvents from '../../../../../utilities/FormEvents'
import { Keys } from '../../../constants'
import Cost from '../cost'
import { formDutyISCIVA, tableDutyControl } from '../storageStep2'

export default function useDutyRow(control: FormEvents<Cost.ModalTableDuty>) {
    const { props } = control.useInput('percentage')
    const data = control.store()

    const tooltip = props.helper

    function handleChange(ev) {
        props.onChange(ev)
        const list = tableDutyControl.getDataList()
        const original_cost = list
            .reduce(function (a, b) {
                return a + (b.cif / 100) * Number(b.percentage)
            }, 0)
            .toFixed(2)
        formDutyISCIVA.setValue('original_cost', original_cost)

        const local_cost = list.reduce(function (a, b) {
            return a + (b.cif_local / 100) * Number(b.percentage)
        }, 0)
        const total = local_cost.toFixed(2)
        formDutyISCIVA.setValue('total_cost', total)
        const { setting_local_cost } = formDutyISCIVA.store()
        formDutyISCIVA.setValue(
            'final_local_cost',
            (local_cost + Number(setting_local_cost)).toFixed(2)
        )
        render(Keys.TABLE_STEP_2_DUTY_SPECIFICATIONS)
    }

    return {
        props,
        handleChange,
        tooltip,
        data
    }
}
