import Col from '../../../../components/Col'
import DataTable, { TBody, THead } from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import Ul from '../../../../components/Ul'
import { GetProvider } from '../../../../types/provider'

export default function Info({ data: provider }: { data: GetProvider }) {
    return (
        <>
            <Typography className="mb-2" type="header-3">
                Información detallada
            </Typography>
            <Col className="mb-4">
                <Ul>
                    <li>
                        Razón Social: <b>{provider.business_name}</b>
                    </li>
                    <li>
                        Nombre comercial: <b>{provider.commercial_name}</b>
                    </li>
                    <li>
                        Documento de identificación Fiscal:{' '}
                        <b>{provider.tax_identification_number || '-'}</b>
                    </li>
                    <li>
                        País de procedencia:{' '}
                        <img
                            src={provider.country.image_url}
                            className="w-5 inline mr-1"
                        />{' '}
                        <b>{provider.country.name}</b>
                    </li>
                </Ul>
                <Ul>
                    <li>
                        Dirección Fiscal:{' '}
                        <b>{provider.fiscal_address.address}</b>
                    </li>
                    <li>
                        Página:{' '}
                        {provider.link_web_page ? (
                            <a
                                className="text-accent underline"
                                href={provider.link_web_page}
                            >
                                {provider.link_web_page}
                            </a>
                        ) : (
                            '-'
                        )}
                    </li>
                    <li>
                        Descripción adicional:{' '}
                        <b>{provider.description || '-'}</b>
                    </li>
                </Ul>
            </Col>
            <Typography className="mb-2" type="header-3">
                Personal de contacto
            </Typography>
            <DataTable scrollHeight={250}>
                <THead>
                    <tr>
                        <th>Nombres</th>
                        <th>Principal</th>
                        <th>Cargo</th>
                        <th>Teléfono</th>
                        <th>Email</th>
                    </tr>
                </THead>
                <TBody>
                    {provider.provider_contacts.map(providerContact => (
                        <tr key={providerContact.id}>
                            <td>{providerContact.contact.name}</td>
                            <td>{providerContact.is_main ? 'Si' : 'No'}</td>
                            <td>{providerContact.contact.position}</td>
                            <td>{providerContact.contact.phone_number}</td>
                            <td>{providerContact.contact.email}</td>
                        </tr>
                    ))}
                </TBody>
            </DataTable>
        </>
    )
}
