import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
import { LicenseData } from './types'

export const controlList = new ControlList<LicenseData>(Keys.MAIN, {
    path: '/v5.6/apis/licenses',
    theads: [
        '#',
        { value: 'name', label: 'Licencia' },
        { value: 'company_name', label: 'Compañía' },
        'Versión',
        'Tipo de licencia',
        { value: 'due_date', label: 'Fecha de vcto.' },
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})
