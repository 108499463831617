import { useRender } from 'redity'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { Keys } from '../../../constants'
import { storagePage } from '../../../storage'
import { TotalCostProps } from './types'

export default function TotalCost({ logisticalDRs }: TotalCostProps) {
    useRender(Keys.TOTAL_COST)
    const currentSymbol = storagePage.currentCurrency.symbol

    const amoutPay = logisticalDRs.reduce(
        (a, b) => a + b.logistical_cost - b.penalized_local_cost,
        0
    )
    const total =
        amoutPay + logisticalDRs.reduce((a, b) => a + b.purchase_local_cost, 0)

    return (
        <>
            <div className="flex justify-end mt-4 font-bold text-xs">
                <div className="bg-gray-200 py-2 px-10 col-span-2 w-56">
                    Costo TOTAL (Compra + Otros costos)
                </div>
                <div className="bg-gray-200 py-2 px-4 w-28">
                    {currentSymbol} {buildNumberFormat(total)}
                </div>
            </div>
            <div className="flex justify-end mt-4 font-bold text-xs">
                <div className="bg-gray-200 py-2 px-10 col-span-3 w-56">
                    Monto a Costear
                </div>
                <div className="bg-gray-200 py-2 px-4 w-28">
                    {currentSymbol} {buildNumberFormat(amoutPay)}
                </div>
            </div>
        </>
    )
}
