import { render } from 'redity'
import FormEvents from '../../../../utilities/FormEvents'
import { Keys } from '../../constants'
import { storagePage } from '../../storage'
import { TableRQ } from '../../types'

export default function useCheckbox(control: FormEvents<TableRQ>) {
    const { props } = control.useCheck('checkbox')

    function handleChange(ev) {
        props.onChange(ev)
        const checked: boolean = ev.target.checked
        const { provider_price, id, pending_amount } = control.store()
        if (checked) {
            control.setValue('quantity_buy', pending_amount)
            storagePage.req_products_actived[id] = {
                quantity_buy: pending_amount,
                provider_price
            }
        } else {
            control.setValue('quantity_buy', 0)
            delete storagePage.req_products_actived[id]
        }
        render(control.keyRender, 'provider_price')
        render(Keys.MOUNT)
    }

    return { props, handleChange }
}
