import fter, { merge } from 'fter'
import { HttpResponse } from './http/type'

export default class ControlRequest<T> {
    private currentObserver: () => Promise<HttpResponse<T>>
    private pathBase = '/'
    private params: Record<string, string | number | boolean> = {}
    private queries: QueryValues = {}

    constructor(pathBase = '') {
        this.pathBase = pathBase
    }

    observer(path: string, initiateLoad = false): ObserverTools<T> {
        const getFullPath = () => {
            const queries = fter.parse(this.queries)
            const pathBase = this.pathBase === '/' ? '' : this.pathBase
            let fullPath = merge(pathBase + path, queries)

            if (fullPath.search('/:') > -1) {
                Object.entries(this.params).forEach(([key, value]) => {
                    const keyValue = `:${key}`
                    if (fullPath.search(keyValue) > -1) {
                        fullPath = fullPath.replace(keyValue, value.toString())
                    }
                })
            }

            return fullPath
        }

        const setObserver = (observer: () => Promise<HttpResponse<T>>) => {
            this.currentObserver = observer
        }

        const deleteObserver = () => {
            this.currentObserver = null
        }

        return {
            getFullPath,
            setObserver,
            deleteObserver,
            initiateLoad
        }
    }

    async load(): Promise<HttpResponse<T>> {
        if (this.currentObserver) {
            return this.currentObserver()
        }
        return {
            data: null,
            error: null
        }
    }

    setQuery(name: string, value: QueryValue) {
        this.queries[name] = value
    }

    getQuery(name: string) {
        return this.queries[name]
    }

    deleteQuery(name: string) {
        delete this.queries[name]
    }

    setParam(name: string, value: string | number | boolean) {
        this.params[name] = value
    }

    getParam(name: string) {
        return this.params[name]
    }

    deleteParam(name: string) {
        delete this.params[name]
    }

    hasQuery(name: string): boolean {
        return !!this.queries[name]
    }

    hasParam(name: string): boolean {
        return !!this.params[name]
    }

    init() {
        this.queries = {}
        this.params = {}
    }
}

export interface ObserverTools<T> {
    getFullPath: () => string
    setObserver: (observer: () => Promise<HttpResponse<T>>) => void
    deleteObserver: () => void
    initiateLoad: boolean
}
export type QueryValues = Record<string, QueryValue>
export type QueryValue = string | number | string[] | number[] | boolean
