import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import { controlTabs, storagePage } from '../storage'
import useSubmitInfo from '../useSubmitInfo'
import useSubmitNorms from '../useSubmitNorms'
import useSubmitAvailability from '../useSubmitAvailability'
import { StepValue } from '../../../utilities/ControlTabs'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import { useParams } from 'react-router'

controlTabs.setSection('Información General', Step1)
controlTabs.setSection('Normas', Step2)
controlTabs.setSection('Disponibilidad', Step3)

function Buttons({ value, cancel }: { value: StepValue; cancel: () => void }) {
    const submitInfo = useSubmitInfo(storagePage.product?.id || null)
    const submitNorms = useSubmitNorms(storagePage.product?.id)
    const submitAvailability = useSubmitAvailability()
    const submits: Partial<Record<StepValue, any>> = {
        step1: submitInfo,
        step2: submitNorms,
        step3: submitAvailability
    }

    return (
        <>
            {storagePage.mode === 'creation' && (
                <Button
                    color="ghost"
                    onClick={() =>
                        value === 'step1' || storagePage.mode === 'edition'
                            ? cancel()
                            : controlTabs.prev()
                    }
                >
                    {value === 'step1' ? 'Cancelar' : 'Volver'}
                </Button>
            )}
            {storagePage.mode === 'edition' && (
                <Button color="accent" onClick={() => cancel()}>
                    Cancelar
                </Button>
            )}
            {storagePage.mode === 'creation' && (
                <Button className="ml-2" onClick={submits[value]}>
                    {value === 'step3' ? 'Guardar' : 'Siguiente'}
                </Button>
            )}
        </>
    )
}

export default function Product() {
    const { product_id } = useParams<{ product_id?: string }>()
    const { pageState, cancel } = useInitial(product_id)
    const value = controlTabs.useStep(to => {
        if (product_id) return true
        if (to === 'step2' && !storagePage.product) return false
        if (to === 'step3' && !storagePage.step2Done) return false
        return true
    })

    return (
        <Initial pageState={pageState}>
            <controlTabs.SectionTabs
                className="max-w-screen-2xl"
                value={value}
                type={product_id ? 'tab' : 'step'}
            />
            <PageHeader subtitle="(*) Complete todos los campos para el producto">
                <Buttons value={value} cancel={cancel} />
            </PageHeader>
        </Initial>
    )
}
