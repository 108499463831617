import { useState } from 'react'
import Dialog from '../../../../../components/Dialog'
import { Progress } from '../../../../../utilities/progress'
import Textarea from '../../../../../components/Textarea'
import { formDialogClosePurchase } from '../../../storage'
import { render } from 'redity'
import BtnActionTable from '../../../../../components/BtnActionTable'
import { Keys } from '../../../constants'

export default function ClosePurchase({
    purchase_code,
    percentage,
    purchase_id,
    disabled
}: {
    purchase_code: string
    purchase_id: number
    percentage: number
    disabled: boolean
}) {
    const [isOpen, setOpen] = useState(false)
    const { props, getError } =
        formDialogClosePurchase.useInput('closingReason')
    const helper = getError()
    const submit = useSubmitReason(setOpen, purchase_id)

    async function handleResult(res) {
        if (!res) {
            formDialogClosePurchase.init()
            setOpen(false)
            return
        }
        await submit()
    }
    return (
        <>
            <BtnActionTable
                title="Cerrar OC"
                iconName="bs-cart-x"
                onClick={() => setOpen(true)}
                disabled={disabled}
            />
            <Dialog
                title="Cerrar OC"
                icon="bs-cart-x"
                open={isOpen}
                color="warning"
                onResult={ev => handleResult(ev)}
                accept="Sí, cerrar"
                cancel="No, cancelar"
            >
                <div>
                    La orden de compra {purchase_code} se cerrará de forma
                    manual. Pasará a tener estado {'Cerrado'} con el{' '}
                    {percentage.toFixed(0)}% de la mercancía ya recepcionada. La
                    mercancía restante ya no podrá ser recepcionada. ¿Está
                    seguro de cerrar la OC?
                </div>
                <Textarea
                    {...props}
                    maxLength={3000}
                    required
                    helper={helper || props.helper}
                    placeholder="Ingresar motivo de cierre"
                    style={{ width: '100%' }}
                    className="mt-2"
                />
            </Dialog>
        </>
    )
}

export function useSubmitReason(setOpen, purchase_id) {
    const { submit, validation, observer } =
        formDialogClosePurchase.useSubmit<any>('/v5.6/apis/purchases/close', {
            method: 'put',
            onError: () => {
                setOpen(true)
                Progress.close()
            },
            done: () => {
                render(Keys.PANEL_HEADER)
                setOpen(false)
                formDialogClosePurchase.init()
                Progress.close()
            }
        })

    validation(v => {
        v.closingReason.isEmpty('Se requiere ingresar una razón')
    })

    observer<any>(form => {
        return {
            closing_reason: form.closingReason.trim(),
            purchase_id: purchase_id
        }
    })

    return submit
}
