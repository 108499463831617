import { useHistory } from 'react-router'
import { mergeExpect } from '../../../../utilities/dataTools'
import { Popup } from '../../../../utilities/popup'
import submit from '../../../../utilities/submit'
import { storagePage, tableDispatch, tableWithdrawal } from '../../storage'
import { Step3 } from '../../types'
import valStep3 from '../../utilities/valStep3'

async function sendSubmit(body: Step3.BodyAddresses) {
    const { providerId, mode } = storagePage
    const { success, error } = await submit(
        `/v5.6/apis/providers/${providerId}/addresses`,
        {
            body,
            message:
                '¿Está seguro de guardar cambios en Direcciones Despacho/Retiro?',
            method: mode === 'edition' ? 'put' : 'post'
        }
    )

    if (error) {
        Popup.error(
            'Algo salió mal...',
            'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
        )
    }

    return success
}

export default function useStep3Submit() {
    const { push } = useHistory()

    async function handleSubmit() {
        if (!valStep3()) return

        tableDispatch.setMessage('')
        tableWithdrawal.setMessage('')

        const { mode } = storagePage

        /**
         * dispatch
         */

        const mapDispatch = (data: Step3.TableDispatch) => {
            // esto es raro
            const place_dispatch_id =
                data.type_dispatch.id === 1
                    ? data.address_id
                    : data.place_dispatch_id

            return {
                id: data.id,
                is_main: data.is_main,
                country_id: data.country.id,
                type_dispatch_id: data.type_dispatch.id,
                ...(data.type_dispatch.id === 4
                    ? { place_dispatch: data.place_dispatch }
                    : { place_dispatch_id }),
                address: data.address,
                // address_id: data.address_id || undefined,
                ...(data.id && { deleted: false })
            }
        }

        const dispatch_addresses: Step3.BodyAddresses['dispatch_addresses'] =
            tableDispatch.getDataList().map(mapDispatch)

        const getDispatchAddresses = () =>
            mergeExpect(tableDispatch.getDataList(), [
                ...storagePage.provider.warehouse_dispatch_addresses,
                ...storagePage.provider.agent_dispatch_addresses,
                ...storagePage.provider.company_dispatch_addresses,
                ...storagePage.provider.other_dispatch_addresses
            ])
                .mapping<Step3.BodyAddresses['dispatch_addresses'][number]>(
                    mapDispatch
                )
                .deleted(data => ({
                    id: data.id,
                    is_main: data.is_main,
                    country_id: data.country.id,
                    type_dispatch_id: data.type_dispatch.id,
                    place_dispatch: 'eliminando',
                    address:
                        'cualquier cosa, de igual manera se va eliminar xD',
                    address_id: null,
                    deleted: true
                }))

        /**
         * withdrawal
         */

        const mapWithdrawal = (data: Step3.TableWithdrawal) => ({
            id: data.id,
            is_main: data.is_main,
            country_id: data.country.id,
            ...(mode === 'creation'
                ? { address: data.address }
                : { withdrawal_address: data.address }),
            locate: data.place_retreat,
            ...(data.id && { deleted: false })
        })

        const withdrawal_addresses: Step3.BodyAddresses['withdrawal_addresses'] =
            tableWithdrawal.getDataList().map(mapWithdrawal)

        const getWithDrawalAddresses = () =>
            mergeExpect(
                tableWithdrawal.getDataList(),
                storagePage.provider.provider_withdrawal_addresses
            )
                .mapping<Step3.BodyAddresses['withdrawal_addresses'][number]>(
                    mapWithdrawal
                )
                .deleted(data => ({
                    id: data.id,
                    is_main: data.is_main,
                    country_id: data.country_id,
                    address: data.address.address,
                    locate: data.address.locate,
                    deleted: true
                }))

        /**
         * submit
         */

        const result = await sendSubmit({
            dispatch_addresses:
                mode === 'creation'
                    ? dispatch_addresses
                    : getDispatchAddresses(),
            withdrawal_addresses:
                mode === 'creation'
                    ? withdrawal_addresses
                    : getWithDrawalAddresses()
        })

        if (result) {
            push('/configuration/providers')
        }
    }

    return handleSubmit
}
