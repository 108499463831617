import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import SearchProductPrices from './filters/SearchProductPrices'
import { CompanyFilter } from './filters/CompanyFilter'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import TableProductPrices from './TableProductPrices'

export default function ProviderPrices() {
    const pageState = useInitial()

    return (
        <Initial pageState={pageState}>
            <Panel>
                <PanelBody>
                    <SectionFiltersGrid>
                        <SearchProductPrices />
                        <FlexBox>
                            <FlexLeft>
                                <CompanyFilter />
                            </FlexLeft>
                            <FlexRight></FlexRight>
                        </FlexBox>
                    </SectionFiltersGrid>
                    <TableProductPrices />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
