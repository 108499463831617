import Button from '../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import TablePaymentMethods from './TablePaymentMethods'
import usePaymentMethod from './usePaymentMethod'

export default function PaymentMethods() {
    const open = usePaymentMethod()
    return (
        <Panel mode="form">
            <PanelHeader title="Medios de pago">
                <Button outline size="sm" onClick={() => open()}>
                    + Agregar
                </Button>
            </PanelHeader>
            <PanelBody>
                <TablePaymentMethods />
            </PanelBody>
        </Panel>
    )
}
