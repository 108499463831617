import moment from 'moment'
import DatePicker from '../../../../../components/Datepicker'
import {
    FormControl,
    IterateOptions
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import SelectTouch from '../../../../../components/SelectTouch'
import Textarea from '../../../../../components/Textarea'
import useGet from '../../../../../hooks/useGet'
import useStorage from '../../../../../hooks/useStorage'
import PriorityPurchase from '../../../../../models/PriorityPurchase'
import { formOC } from '../../../storage'

export default function Fields2() {
    return (
        <>
            <Priority />
            <DateRequired />
            <PaymentConditionField />
            <Comment />
        </>
    )
}

function Priority() {
    const { data } = useGet<PriorityPurchase[]>(
        '/v5.6/apis/priority_purchases',
        []
    )
    const { props } = formOC.useSelect('priority')

    return (
        <FormControl label="Prioridad" required>
            <SelectTouch {...props} placeholder="Seleccionar prioridad">
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function DateRequired() {
    const { companiesFromSession } = useStorage()
    const company = companiesFromSession.find(
        c => c.id === parseInt(formOC.store().billing_company.value)
    )
    const { props, getError } = formOC.useDate('date')
    const helper = getError(null, null, 'request_date')

    return (
        <FormControl label="Fecha requerida" required>
            <DatePicker
                {...props}
                helper={props.helper || helper}
                minDate={moment()
                    .tz(company.timezone)
                    .parseZone()
                    .toISOString(true)}
            />
        </FormControl>
    )
}

function PaymentConditionField() {
    const { props } = formOC.useInput('payment_condition')

    return (
        <FormControl label="Condición de pago" required col="span-6">
            <InputField {...props} placeholder="ingresar condición de pago" />
        </FormControl>
    )
}

function Comment() {
    const { props } = formOC.useInput('comment')

    return (
        <FormControl label="Comentario" col="span-6">
            <Textarea {...props} placeholder="Ingresar comentario" />
        </FormControl>
    )
}
