import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import moment from 'moment'
import { controlList, storagePage } from './../../storage'
import euro from '../../../../assets/img/euro.jpg'

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header: {
        width: '100%',
        fontSize: '9px',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        fontFamily: 'univers-bold',
        padding: '5px'
    },
    header2: {
        width: '50%',
        fontSize: '9px',
        backgroundColor: '#F6F6F6',
        fontFamily: 'univers-bold',
        textAlign: 'center',
        padding: '5px'
    },
    item: {
        width: '100%',
        fontSize: '9px',
        padding: '5px',
        border: '1px solid #E2E2E2',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    item2: {
        width: '50%',
        fontSize: '9px',
        border: '1px solid #E2E2E2',
        padding: '5px',
        textAlign: 'center',
        textTransform: 'uppercase'
    }
})

export default function Table1() {
    const product = controlList.filter.getOption('product_id')
    const start_date = moment(controlList.filter.getParam('start_date')).format(
        'DD/MM/YYYY'
    )
    const end_date = moment(controlList.filter.getParam('end_date')).format(
        'DD/MM/YYYY'
    )
    const currency = storagePage.currency

    return (
        <>
            <View style={styles.row}>
                <Text style={styles.header}>PRODUCTO</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.item}>
                    {`${product.label2} - ${product.label3}`}
                </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.header2}>RANGO DE FECHA</Text>
                <Text style={styles.header2}>MONEDA</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.item2}>
                    {`${start_date} - ${end_date}`}
                </Text>
                <Text style={styles.item2}>
                    {currency.symbol === '₡' ? (
                        <Image src={euro} />
                    ) : (
                        currency.symbol
                    )}{' '}
                    {currency.name}
                </Text>
            </View>
        </>
    )
}
