import Icon from '../../../components/Icon'
import NumeralField from '../../../components/NumeralField'
import ReactPopover from '../../../components/ReactPopover'
import Typography from '../../../components/Typography'
import { TableList } from '../../../sections/ListForm'
import FormEvents from '../../../utilities/FormEvents'
import { productsControl, storagePage } from '../storage'
import {
    MinimalStowageItem,
    ProductsForInput,
    RequisitionView as RequisitionViewType
} from '../types'
import ModalProducts from './ModalProducts'
import { view } from '../../../sections/View'
import useRule from '../../../hooks/useRule'
import RequisitionView from './RequisitionView'
import Requisition from '../../../models/Requisition'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { useRender, render } from 'redity'
import { Keys } from '../constants'

function Code({ requisition }: { requisition: Requisition }) {
    const math = useRule('CU220')

    return (
        <div
            {...(math && {
                className: 'font-univers-bold mb-1 underline cursor-pointer',
                onClick: () => {
                    view<RequisitionViewType>(
                        `/v5.6/crm/requisitions/${requisition.id}`,
                        () => ({
                            title: `Requisición  ${requisition.code}`,
                            Component: RequisitionView
                        }),
                        'md'
                    )
                }
            })}
        >
            <Typography type="paragraph" className={`font-bold`}>
                {requisition.code}
            </Typography>
        </div>
    )
}

export default function TableProducts() {
    function handleView(product) {
        view(
            `/v5.6/apis/products/${product.requisition_product.product_id}/incompatible_products`,
            () => ({
                title: `Norma de almacenaje`,
                Component: ModalProducts,
                size: 'sm'
            })
        )
    }

    function IncompatibleProducts({ product }: { product: ProductsForInput }) {
        if (
            product.requisition_product.product.non_storable_products.length >=
            1
        ) {
            return (
                <ReactPopover
                    component={
                        <Icon
                            pointer
                            name="unarchive"
                            className="ml-2 text-warning"
                            onClick={() => handleView(product)}
                        />
                    }
                >
                    <b>Norma de almacenamiento</b> <br />
                    Producto con norma de almacenaje
                </ReactPopover>
            )
        } else {
            return <div></div>
        }
    }

    return (
        <TableList
            theadWhiteSpace="normal"
            control={productsControl}
            scrollHeight={350}
            className="mb-4"
        >
            {(product, control, e) => {
                return (
                    <tr key={e}>
                        <td>{e}</td>
                        <td style={{ whiteSpace: 'normal' }}>
                            <Code
                                requisition={
                                    product.requisition_product.requisition
                                }
                            />
                        </td>
                        <td style={{ whiteSpace: 'normal' }}>
                            {getStowageName(
                                product.requisition_product.requisition
                                    .minimal_stowage
                            )}
                        </td>
                        <td style={{ whiteSpace: 'normal' }}>
                            <div className="flex">
                                <div>
                                    <Typography
                                        type="paragraph"
                                        className={'font-bold'}
                                    >
                                        {
                                            product.requisition_product.product
                                                .description
                                        }
                                    </Typography>
                                    <Typography type="small" color="gray">
                                        {
                                            product.requisition_product.product
                                                .code
                                        }
                                    </Typography>
                                </div>
                                <div className="flex items-center ml-2">
                                    {product.requisition_product.product
                                        .min_temperature && (
                                        <ReactPopover
                                            component={
                                                <Icon
                                                    className={`text-info`}
                                                    name="thermostate"
                                                ></Icon>
                                            }
                                        >
                                            <b>Refrigeración</b> Min:{' '}
                                            {
                                                product.requisition_product
                                                    .product.min_temperature
                                            }{' '}
                                            Cº - Max:{' '}
                                            {
                                                product.requisition_product
                                                    .product.max_temperature
                                            }{' '}
                                            Cº
                                        </ReactPopover>
                                    )}
                                    <IncompatibleProducts product={product} />
                                </div>
                            </div>
                        </td>
                        <td>
                            {product.purchase.currency.symbol}{' '}
                            {buildNumberFormat(product.unit_purchase_price)}
                        </td>
                        <td>{product.quantity}</td>
                        <td>{product.quantity_received_other_rcs}</td>
                        <td>
                            {product.quantity -
                                product.quantity_received_other_rcs}
                        </td>
                        <td>
                            {product.confirmed ? (
                                product.quantity_requested
                            ) : (
                                <Quantity
                                    control={control}
                                    max={
                                        product.quantity -
                                        product.quantity_received_other_rcs
                                    }
                                    product={product}
                                />
                            )}
                        </td>
                        <td>
                            <Icon
                                pointer
                                className={
                                    product.confirmed
                                        ? `text-success`
                                        : `text-primary`
                                }
                                name="check-cirle"
                                onClick={() => {
                                    handleConfirm(control, product)
                                }}
                            ></Icon>
                        </td>
                    </tr>
                )
            }}
        </TableList>
    )
}

function Quantity({
    control,
    max,
    product
}: {
    control: FormEvents<any>
    max: number
    product: ProductsForInput
}) {
    const { props } = control.useInput('quantity_requested')
    function handleChange(ev) {
        control.setValue('helper', '')
        props.onChange(ev)
        productsControl.renderMain()
    }

    return (
        <NumeralField
            {...props}
            onChange={handleChange}
            max={max}
            helper={product.helper}
        />
    )
}

function handleConfirm(
    control: FormEvents<ProductsForInput>,
    product: ProductsForInput
) {
    const { quantity_requested, id, unit_purchase_price } = control.store()
    if (storagePage.mode === 'creation') {
        if (parseInt(product.quantity_requested) > 0) {
            productsControl.setMessage('')
            control.setValue('helper', '')
            control.setValue('confirmed', !control.store().confirmed)
            storagePage.receipt_products[id] = {
                quantity_requested: parseInt(quantity_requested, 10),
                unit_purchase_price
            }
            storagePage.symbolCurrency = product.purchase.currency.symbol
        } else {
            control.setValue('helper', 'Se debe ingresar una cantidad')
            delete storagePage.receipt_products[id]
        }
        if (control.store().confirmed === false) {
            delete storagePage.receipt_products[id]
        }
    }
    if (storagePage.mode === 'edition') {
        if (control.store().confirmed) {
            delete storagePage.receipt_products[id]
        }
        if (parseInt(product.quantity_requested) >= 0) {
            productsControl.setMessage('')
            control.setValue('helper', '')
            control.setValue('confirmed', !control.store().confirmed)
            storagePage.receipt_products[id] = {
                quantity_requested: parseInt(quantity_requested, 10),
                unit_purchase_price
            }
            storagePage.symbolCurrency = product.purchase.currency.symbol
        } else {
            control.setValue('helper', 'Se debe ingresar una cantidad')
            delete storagePage.receipt_products[id]
        }
    }
    render(Keys.MOUNT)
    productsControl.renderMain()
}

function getStowageName(minimal_stowage: MinimalStowageItem) {
    const stowageName = minimal_stowage.stowage.name

    if (minimal_stowage.order_sub_stowage) {
        return (
            stowageName + ' - ' + minimal_stowage.order_sub_stowage.order.number
        )
    } else if (minimal_stowage.gp_sub_stowage) {
        return (
            stowageName + ' - ' + minimal_stowage.gp_sub_stowage.purchase.code
        )
    } else {
        return stowageName
    }
}
