import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import useCancel from '../../hooks/useCancel'
import { get, post } from '../../utilities/http'
import {
    controlTabsNational,
    controlTabsInternational,
    storagePage
} from './storage'
import { ResponseLD } from './types'
import {
    formGeneral,
    reqWorkingPeriod
} from './View/SectionGeneralInformation/storageStep1'
import {
    tableRCsControl,
    tableInternationalCostsControl,
    tableNationalizationControl
} from './View/SectionCosts/storageStep2'
import {
    tableAditionalInternationalCostControl,
    tableAditionalNationalCostControl
} from './View/SectionAdditional/storageStep3'
import fillForm from './utilities/fillForm'
import useStorage from '../../hooks/useStorage'
import moment from 'moment'

export default function useInitial(type_logistical_declaration_id: number) {
    type Params = { logistical_declaration_id?: string }
    const { logistical_declaration_id } = useParams<Params>()
    const { companiesFromSession } = useStorage()
    const [pageState, setPageState] = useState<StateCase>(StateCase.LOADING)
    const { onClick, init } = useCancel(
        [
            formGeneral,
            tableRCsControl,
            tableInternationalCostsControl,
            tableNationalizationControl,
            tableAditionalInternationalCostControl,
            tableAditionalNationalCostControl
        ],
        '/costs/costing'
    )

    storagePage.type_logistical_declaration_id = type_logistical_declaration_id
    storagePage.type =
        type_logistical_declaration_id === 1 ? 'international' : 'national'

    useEffect(() => {
        if (logistical_declaration_id) {
            storagePage.mode = 'edition'

            loadEdition(parseInt(logistical_declaration_id)).then(result => {
                if (result) {
                    fillForm(result, companiesFromSession)
                    storagePage.timezone = companiesFromSession.find(
                        _company => _company.id === result.company_id
                    ).timezone
                    storagePage.step1Done = true
                    setPageState(StateCase.SUCCESS)
                    init()
                } else {
                    setPageState(StateCase.ERROR)
                }
            })
        } else {
            storagePage.mode = 'creation'
            if (formGeneral.store().company) {
                const company = companiesFromSession.find(
                    _company =>
                        _company.id ===
                        parseInt(formGeneral.store().company.value)
                )
                reqWorkingPeriod.setQuery('company_id', company.id)
                storagePage.currentCurrency = company.local_currency
                storagePage.timezone = company.timezone
            } else {
                const companies = companiesFromSession.filter(
                    _company => _company.is_enabled
                )
                const company = companies[0]
                formGeneral.setValue('company', {
                    value: company.id.toString(),
                    label: company.name,
                    image: company.country.image_url
                })
                const date = moment().tz(company.timezone).toISOString(true)
                formGeneral.setValue('declaration_date', date)
                formGeneral.setProps('declaration_date', {
                    maxDate: date
                })
                reqWorkingPeriod.setQuery('company_id', company.id)
                storagePage.currentCurrency = company.local_currency
                storagePage.timezone = company.timezone
            }

            formGeneral.init(formGeneral.store())
            setPageState(StateCase.SUCCESS)
            init()
        }

        return () => {
            storagePage.logistical_declaration = null
            storagePage.mode = 'creation'
            storagePage.step1Done = false
            controlTabsInternational.value = 'step1'
            controlTabsNational.value = 'step1'

            formGeneral.init()
            formGeneral.clearProps()
            tableAditionalNationalCostControl.setList([])
            tableAditionalInternationalCostControl.setList([])
            tableInternationalCostsControl.setList([])
            tableNationalizationControl.setList([])
            tableRCsControl.setList([])
        }
    }, [])
    return {
        pageState,
        cancel: onClick
    }
}

async function loadEdition(logistical_declaration_id: number) {
    const [res1, res2] = await Promise.all([
        get<ResponseLD>(
            `/v5.6/apis/logistical_declarations/${logistical_declaration_id}`
        ),
        post<{ version: string }>(
            '/v5.6/apis/logistical_declarations/create_demo_version',
            {
                logistical_declaration_id
            }
        )
    ])
    if (res1.error || res2.error) return null

    storagePage.logistical_declaration = res1.data.result
    storagePage.version = res2.data.result.version
    return res1.data.result
}
