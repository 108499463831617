import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import ContentModule from './ContentModule'
import useInitial from '../useInitial'
import { data } from '../storage'
import { useHistory, useParams } from 'react-router-dom'
import getBody from '../utilities/getBody'
import submit from '../../../utilities/submit'
import { Popup } from '../../../utilities/popup'
import useCancel from '../../../hooks/useCancel'

function Header() {
    const { push } = useHistory()
    const { role_id } = useParams<{ role_id: string }>()
    const { onClick } = useCancel(
        [...data.modules.map(mod => mod.permissions)].flat(),
        '/administration/roles'
    )

    async function handleSave() {
        const { success, error } = await submit(
            `/v5.6/apis/roles/${role_id}/permissions`,
            {
                body: getBody(data.modules),
                method: 'put',
                confirm: (
                    <>
                        ¿Está seguro de guardar cambios del rol{' '}
                        <b>{data.rolePermission.name}</b>?
                    </>
                )
            }
        )

        if (error) {
            if (error.errors.role_id) {
                Popup.error('Rol Desactivado', error.errors.role_id.description)
            } else {
                Popup.error(
                    'Algo salió mal...',
                    'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
                )
            }
        } else if (success) {
            push('/administration/roles')
        }
    }

    return (
        <PageHeader subtitle="Selecciona los permisos para rol.">
            <Button color="ghost" onClick={onClick}>
                Cancelar
            </Button>
            <Button className="ml-2" onClick={handleSave}>
                Guardar
            </Button>
        </PageHeader>
    )
}

export default function AsignPermisions() {
    const pageState = useInitial()

    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title="Configurar Permisos de Auditor" />
                    <PanelBody>
                        {data.modules.map((mod, i) => (
                            <ContentModule key={i} control={mod} />
                        ))}
                    </PanelBody>
                </Panel>
            </div>
            <Header />
        </Initial>
    )
}
