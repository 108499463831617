import { formDispatch } from '../storage'

type Mode = 'creation' | 'edition'

export default function eventsStep3(mode: Mode) {
    formDispatch.setProps('type_dispatch', {
        disabled: true
    })

    if (mode === 'edition') {
        formDispatch.setProps('country', {
            disabled: true
        })
        formDispatch.setProps('address_select', { disabled: false })
        formDispatch.setProps('address', { disabled: false })
    } else {
        formDispatch.setProps('country', {
            disabled: false
        })
        formDispatch.setProps('place_dispatch_text', {
            disabled: true
        })
        formDispatch.setProps('address', { disabled: true })
        formDispatch.setProps('address_select', { disabled: true })
    }
}
