import { FormControl } from '../../../components/FormUtilities'
import Textarea from '../../../components/Textarea'
import { formPurchaseReceipt } from '../storage'

export default function Fields3() {
    const { props } = formPurchaseReceipt.useInput('comment')

    return (
        <FormControl label="Comentario">
            <Textarea {...props} placeholder="Ingresar comentario" />
        </FormControl>
    )
}
