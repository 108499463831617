import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowProviderLocalAmount(
    data: LogisticalDeclarationExcelData[]
) {
    const row: Row[] = [{ value: 'Monto Proveedor', fontWeight: 'bold' }]
    data.forEach(d => {
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'right',
            fontWeight: 'bold',
            value: d.logistical_declaration_receptions.reduce(function (a, b) {
                return a + Number(b.reception.provider_local_amount)
            }, 0),
            format: '0.00'
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
