import { tableContactsControl } from '../storage'

export function emailExists(email: string, index?: number) {
    const tableDataContacts = tableContactsControl.getDataList()

    if (index !== undefined) {
        return !!tableDataContacts.find(
            (data, i) => data.email === email && i !== index
        )
    }

    return !!tableDataContacts.find(data => data.email === email)
}

export function nameExists(name: string, index?: number) {
    const tableDataContacts = tableContactsControl.getDataList()

    if (index !== undefined) {
        return !!tableDataContacts.find(
            (data, i) => data.name === name && i !== index
        )
    }

    return !!tableDataContacts.find(data => data.name === name)
}
