import { useContext } from 'react'
import { Order } from '../../config/types/component'
import { Context } from './contexts'
import { PropsSort, PropsTSort } from './types'
import classnames from 'classnames'

export function TSort({ value, children }: PropsTSort) {
    const { value: c_value, order: c_order, onSort } = useContext(Context)
    function handleClick() {
        const order: Order = c_order === 'asc' ? 'desc' : 'asc'
        onSort(value, order)
    }

    return (
        <div className="tsort block" onClick={handleClick}>
            <span
                className={classnames('cursor-pointer fill-current', {
                    active: c_value === value
                })}
            >
                {children}
                <SortSVG order={c_order} active={c_value === value} />
            </span>
        </div>
    )
}

function SortSVG({ active, order }: PropsSort) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 511.627 511.627"
            className={classnames('svg-sort ml-1', {
                desc: order === 'desc' && active
            })}
            fontSize={10}
        >
            <path d="M333.584 438.536h-73.087c-2.666 0-4.853.855-6.567 2.573-1.709 1.711-2.568 3.901-2.568 6.564v54.815c0 2.673.855 4.853 2.568 6.571 1.715 1.711 3.901 2.566 6.567 2.566h73.087c2.666 0 4.856-.855 6.563-2.566 1.718-1.719 2.563-3.898 2.563-6.571v-54.815c0-2.663-.846-4.854-2.563-6.564-1.707-1.717-3.897-2.573-6.563-2.573zM196.54 401.991h-54.817V9.136c0-2.663-.854-4.856-2.568-6.567C137.441.859 135.254 0 132.587 0H77.769c-2.663 0-4.856.855-6.567 2.568-1.709 1.715-2.568 3.905-2.568 6.567V401.99H13.816c-4.184 0-7.04 1.902-8.564 5.708-1.525 3.621-.855 6.95 1.997 9.996l91.361 91.365c2.094 1.707 4.281 2.562 6.567 2.562 2.474 0 4.665-.855 6.567-2.562l91.076-91.078c1.906-2.279 2.856-4.571 2.856-6.844 0-2.676-.859-4.859-2.568-6.584-1.713-1.706-3.9-2.562-6.568-2.562zM388.4 292.362H260.494c-2.666 0-4.853.855-6.567 2.566-1.71 1.711-2.568 3.901-2.568 6.563v54.823c0 2.662.855 4.853 2.568 6.563 1.714 1.711 3.901 2.566 6.567 2.566H388.4c2.666 0 4.855-.855 6.563-2.566 1.715-1.711 2.573-3.901 2.573-6.563v-54.823c0-2.662-.858-4.853-2.573-6.563-1.707-1.71-3.897-2.566-6.563-2.566zM504.604 2.568C502.889.859 500.702 0 498.036 0H260.497c-2.666 0-4.853.855-6.567 2.568-1.709 1.715-2.568 3.905-2.568 6.567v54.818c0 2.666.855 4.853 2.568 6.567 1.715 1.709 3.901 2.568 6.567 2.568h237.539c2.666 0 4.853-.855 6.567-2.568 1.711-1.714 2.566-3.901 2.566-6.567V9.136c.004-2.663-.855-4.857-2.565-6.568zM443.22 146.181H260.494c-2.666 0-4.853.855-6.567 2.57-1.71 1.713-2.568 3.9-2.568 6.567v54.816c0 2.667.855 4.854 2.568 6.567 1.714 1.711 3.901 2.57 6.567 2.57H443.22c2.663 0 4.853-.855 6.57-2.57 1.708-1.713 2.563-3.9 2.563-6.567v-54.816c0-2.667-.855-4.858-2.563-6.567-1.721-1.711-3.911-2.57-6.57-2.57z" />
        </svg>
    )
}
