import DropContent from '../../../components/DropContent'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectTouch from '../../../components/SelectTouch'
import useGet from '../../../hooks/useGet'
import Category from '../../../models/Category'
import Warehouse from '../../../models/Warehouse'
import ProviderType from '../../../models/Provider'
import { Option } from '../../../config/types/component'
import {
    controlList,
    filterForm,
    reqWarehouse,
    reqSummaryData
} from '../storage'
import moment from 'moment'
import DatePicker from '../../../components/Datepicker'

function WarehouseOption() {
    const { props } = filterForm.useSelect<Warehouse>('warehouse_id')

    const { data, loading } = useGet<Warehouse[]>(
        reqWarehouse.observer('/v5.6/apis/warehouses/company/', true),
        []
    )

    return (
        <FormControl label="Almacén">
            <SelectTouch
                {...props}
                loading={loading}
                placeholder="Seleccionar almacén"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Provider() {
    const { props, data } = filterForm.useSelect<ProviderType>(
        'provider_id',
        () => {
            return '/v5.6/apis/providers'
        }
    )

    return (
        <FormControl label="Proveedor">
            <SelectTouch {...props} placeholder="Seleccionar proveedor">
                <IterateOptions data={data} label="business_name" />
            </SelectTouch>
        </FormControl>
    )
}

function StartDateField() {
    const { props } = filterForm.useDate('start_date')

    function handleChange(option: Option) {
        props.onChange(option)
        const start_date = moment(filterForm.store().start_date).format(
            'YYYY-MM-DD'
        )
        reqSummaryData.setQuery('start_date', start_date)
    }

    return (
        <FormControl label="Fecha Inicio" className="mt-1">
            <DatePicker
                {...props}
                infoZ=""
                placeholder="Fecha Inicio"
                onChange={handleChange}
            />
        </FormControl>
    )
}

function EndDateField() {
    const { props } = filterForm.useDate('end_date')

    function handleChange(option: Option) {
        props.onChange(option)
        const end_date = moment(filterForm.store().end_date).format(
            'YYYY-MM-DD'
        )
        reqSummaryData.setQuery('end_date', end_date)
    }

    return (
        <FormControl label="Fecha Fin" className="mt-1">
            <DatePicker
                {...props}
                infoZ=""
                placeholder="Fecha Fin"
                onChange={handleChange}
            />
        </FormControl>
    )
}

function TypeReception() {
    const { props, data } = filterForm.useSelect<Category>(
        'type_reception_id',
        '/v5.6/apis/type_receptions/all'
    )

    return (
        <FormControl label="Tipo de recepción">
            <SelectTouch {...props} placeholder="Seleccionar tipo de recepción">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function TypeOC() {
    const { props, data } = filterForm.useSelect<Category>(
        'type_purchase_id',
        '/v5.6/apis/type_purchases/all'
    )

    return (
        <FormControl label="Tipo de orden de compra">
            <SelectTouch {...props} placeholder="Seleccionar tipo de OC">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function FiltersForm() {
    const { props } = controlList.useForm(
        filterForm,
        form => ({
            start_date: form.start_date
                ? moment(form.start_date).format('DD/MM/YYYY')
                : null,
            end_date: form.end_date
                ? moment(form.end_date).format('DD/MM/YYYY')
                : null
        }),
        form => ({
            start_date: form.start_date
                ? moment(form.start_date).format('YYYY-MM-DD')
                : null,
            end_date: form.end_date
                ? moment(form.end_date).format('YYYY-MM-DD')
                : null
        })
    )
    return (
        <DropContent {...props} label="Filtros" className="ml-2">
            <WarehouseOption />
            <StartDateField />
            <EndDateField />
            <Provider />
            <TypeReception />
            <TypeOC />
        </DropContent>
    )
}
