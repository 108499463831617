import useRule from '../../../hooks/useRule'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList } from '../storage'
import { SubcategoryData } from '../types'
import Actions from './Actions'
import Info from './Info'

function Code({ subcategory }: { subcategory: SubcategoryData }) {
    function handleClick() {
        view(`/v5.6/apis/subcategories/${subcategory.id}`, () => ({
            title: 'Información de la Subcategoría',
            Component: Info
        }))
    }
    const math = useRule('CU049')
    if (!math) return <>{subcategory.code}</>
    return (
        <b onClick={handleClick} className="underline cursor-pointer">
            {subcategory.code}
        </b>
    )
}

export default function TableSubcategories() {
    return (
        <List control={controlList}>
            {(subcategory, i) => (
                <tr key={subcategory.id}>
                    <td>{i}</td>
                    <td>
                        <Code subcategory={subcategory} />
                    </td>
                    <td>{subcategory.name}</td>
                    <td>{subcategory.category.name}</td>
                    <td>
                        <div className="group-btn-options">
                            <Actions subcategory={subcategory} />
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
