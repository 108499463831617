import moment from 'moment'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../../components/DataTable'
import { LicenseData } from '../../../types'

export default function TableLicenses({
    licenses
}: {
    licenses: LicenseData[]
}) {
    return (
        <DataTable scrollHeight={250}>
            <THead>
                <tr>
                    <th>Compañía</th>
                    <th>Licencia</th>
                    <th>Versión</th>
                    <th>Vencimiento</th>
                </tr>
            </THead>
            <TBody>
                {licenses.map(license => (
                    <tr key={license.id}>
                        <td>
                            <div className="flex">
                                <img
                                    src={license.company.country.image_url}
                                    className="w-8 mr-2"
                                />
                                <p>{license.company.name}</p>
                            </div>
                        </td>
                        <td>{license.name}</td>
                        <td>V{license.version}</td>
                        <td>
                            {license.due_date
                                ? moment(license.due_date).format('DD/MM/YYYY')
                                : '-'}
                        </td>
                    </tr>
                ))}
                {licenses.length === 0 && (
                    <Caption colSpan={4}>No existen registros</Caption>
                )}
            </TBody>
        </DataTable>
    )
}
