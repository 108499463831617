import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import { data } from './storage'
import { PermissionData, RolePermissionData } from './types'
import assignPermissionRoleId from './utilities/assignPermissionRoleId'
import permissionCollections from './utilities/permissionCollections'

async function load(role_id: number) {
    const [r1, r2] = await Promise.all([
        get<PermissionData[]>('/v5.6/apis/permissions'),
        get<RolePermissionData>(`/v5.6/apis/roles/${role_id}/permissions`)
    ])
    if (r1.error) return StateCase.ERROR
    data.modules = permissionCollections(r1.data.result)
    assignPermissionRoleId(data.modules, r2.data.result)
    return StateCase.SUCCESS
}

export default function useInitial() {
    const { role_id } = useParams<{ role_id: string }>()
    const [pageState, setPageState] = useState(StateCase.LOADING)
    useEffect(() => {
        load(parseInt(role_id)).then(state => setPageState(state))
    }, [])

    return pageState
}
