import FormEvents from '../../../utilities/FormEvents'
import { Keys } from '../constants'
import { DataGroupPermision, DataPermision, ModuleForm } from '../storage'
import { PermissionData } from '../types'

function createFormGroup(data: PermissionData) {
    return new FormEvents<DataGroupPermision>(`${Keys.GROUP}-${data.id}`, {
        id: data.group_permission_id,
        name: data.group_permission.name,
        active: false
    })
}

function createFormPermission(data: PermissionData) {
    return new FormEvents<DataPermision>(`${Keys.CHECKS}-${data.id}`, {
        id: data.id,
        name: data.name,
        active: false,
        permission_role_id: null
    })
}

export default function permissionCollections(
    permissionsData: PermissionData[]
) {
    const permissions = permissionsData.filter(
        permission => permission.group_permission_id !== 4
    )
    const mods: ModuleForm[] = []
    for (const permission of permissions) {
        const module = mods.find(
            mod =>
                mod.groupPermision.store().id === permission.group_permission_id
        )
        if (!module) {
            mods.push({
                groupPermision: createFormGroup(permission),
                permissions: []
            })
        }
    }

    for (const permission of permissions) {
        const module = mods.find(
            mod =>
                mod.groupPermision.store().id === permission.group_permission_id
        )
        module.permissions.push(createFormPermission(permission))
    }

    return mods
}
