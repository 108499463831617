import { PropsControlDivider } from './types'

export default function ControlDivider({
    children,
    divider = '2'
}: PropsControlDivider) {
    return (
        <div className={`grid grid-cols-${divider} gap-x-4 gap-y-2`}>
            {children}
        </div>
    )
}
