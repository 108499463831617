import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowNumberInvoiceFinal(
    data: LogisticalDeclarationExcelData[]
) {
    const row: Row[] = [{ value: 'Nro. Factura final' }]
    data.forEach(d => {
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'center',
            value: d.logistical_declaration_receptions
                .map(ldr => ldr.reception.number_invoice_final || '-')
                .join('/')
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
