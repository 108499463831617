function Comments(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0H1C.45 0 0 .45 0 1v14l4-4h10c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-1 2v7H3.17L2 10.17V2h11zm4 2h2c.55 0 1 .45 1 1v15l-4-4H5c-.55 0-1-.45-1-1v-2h13V4z"
            />
        </svg>
    )
}

export default Comments
