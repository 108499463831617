import { FormControl, FormControls } from '../../../../components/FormUtilities'
import Toggle from '../../../../components/Toggle'
import { formConfig } from '../../storage'

function Tracking() {
    const { props } = formConfig.useCheck('specific_tracking_number')
    return (
        <FormControl label="¿Proveedor con tracking number específico?">
            <Toggle {...props} />
        </FormControl>
    )
}

function Packing() {
    const { props } = formConfig.useCheck('specific_packing_list')
    return (
        <FormControl label="¿Proveedor suministra packing list específico?">
            <Toggle {...props} />
        </FormControl>
    )
}

export default function Toggles() {
    return (
        <FormControls>
            <Tracking />
            <Packing />
        </FormControls>
    )
}
