import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import {
    ViewProductPrice,
    GetPricesPerCompany
} from '../../types/product_price'
import useCancel from '../../hooks/useCancel'
import { get } from '../../utilities/http'
import {
    storagePage,
    tablePricesControl,
    tableSaleDiscountsControl,
    formFactor,
    formSaleDiscounts,
    controlTabs
} from './storage'
import buildNumberFormat from '../../utilities/buildNumberFormat'

export default function useInitial() {
    const [pageState, setPageState] = useState<StateCase>(StateCase.LOADING)
    const { onClick, init } = useCancel(
        [
            tablePricesControl,
            tableSaleDiscountsControl,
            formFactor,
            formSaleDiscounts
        ],
        '/prices'
    )
    const { product_price_id } = useParams<{ product_price_id?: string }>()

    useEffect(() => {
        storagePage.productPriceId = parseInt(product_price_id)

        get<ViewProductPrice>(
            `/v5.6/apis/product_companies/${product_price_id}/sale_prices_and_factors`
        ).then(resultProductPrice => {
            if (resultProductPrice.error) {
                setPageState(StateCase.ERROR)
            } else {
                storagePage.productPrice = resultProductPrice.data.result
                get<GetPricesPerCompany[]>(
                    `/v5.6/apis/product/${storagePage.productPrice.product_country.product.id}/sale_price_per_company/all`
                ).then(resultPrices => {
                    if (resultPrices.error) {
                        setPageState(StateCase.ERROR)
                    } else {
                        initialEditionPrices(resultPrices.data.result)
                        setPageState(StateCase.SUCCESS)
                    }
                })
                init()
            }
        })

        return () => {
            storagePage.productPrice = null
            storagePage.productPriceId = null
            storagePage.productId = null
            storagePage.loadingSaleDiscounts = false

            formFactor.init()
            tableSaleDiscountsControl.setList([])
            formSaleDiscounts.init()
            tablePricesControl.setList([])

            controlTabs.value = 'step1'
        }
    }, [])

    return {
        pageState,
        cancel: onClick
    }
}

export function initialEditionPrices(pricesPerCompany: GetPricesPerCompany[]) {
    tablePricesControl.setList(
        pricesPerCompany.map(price_per_company => ({
            id: price_per_company.id,
            company: price_per_company.company,
            factor: price_per_company.subcategory_value
                ? price_per_company.subcategory_value.factor
                : '-',
            special_factor: price_per_company.special_factor
                ? price_per_company.special_factor.toString()
                : '',
            discount: price_per_company.subcategory_value
                ? `${price_per_company.subcategory_value.discount} %`
                : '-',
            unit_cost: price_per_company.product_provider_company
                ? `${
                      price_per_company.product_provider_company
                          ?.company_currency?.currency?.symbol
                  } ${buildNumberFormat(
                      price_per_company.product_provider_company?.purchase_price
                  )}`
                : null, // El costo que muestra es el de su proveedor principal 🤔
            sale_price: price_per_company.product_provider_company
                ? `${
                      price_per_company.product_provider_company
                          ?.company_currency?.currency?.symbol
                  } ${buildNumberFormat(
                      price_per_company.product_provider_company?.sale_price
                  )}`
                : '-',
            discount_price: price_per_company.product_provider_company
                ? `${
                      price_per_company.product_provider_company
                          ?.company_currency?.currency?.symbol
                  } ${buildNumberFormat(
                      price_per_company.product_provider_company?.discount_price
                  )}`
                : '-'
        }))
    )
}
