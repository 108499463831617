import { Option } from '../../config/types/component'
import { GetPurchases } from '../../types/purchase'
import ControlList from '../../utilities/Control/ControlList'
import ControlTabs from '../../utilities/ControlTabs'

import FormEvents from '../../utilities/FormEvents'
import { Keys, tHeadsPending } from './constants'
import ControlRequest from '../../utilities/ControlRequest'

export const ocList = new ControlList<GetPurchases[number]>(Keys.TABLE, {
    theads: tHeadsPending,
    path: '/v5.6/apis/purchases'
})

ocList.badgeRemove('company_id', false)
ocList.filter.changeParams('sort', 'id')
ocList.filter.setParams('purchase_state_id', 1)

export const cts = new ControlTabs()

export const formFilter = new FormEvents<FormFilter>(Keys.FORM_FILTER, {
    type_purchase_id: null,
    provider_id: null
})

export const formDialogClosePurchase = new FormEvents<any>(
    Keys.FORM_DIALOG_CLOSE_PURCHASE,
    {
        closingReason: ''
    }
)

interface FormFilter {
    type_purchase_id: Option
    provider_id: Option
}

export const ctsView = new ControlTabs()

export const storagePage: StoragePage = {
    timezone: ''
}

export const reqProvider = new ControlRequest()

interface StoragePage {
    timezone: string
}
