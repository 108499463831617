import FileModel from '../../../../../models/FileModel'
import { parseDriveFile, parseFile } from '../../../../../utilities/fileTools'
import { storagePage } from '../../../storage'
import Aditional from '../aditional'
import { formCostGeneral } from '../storageStep3'

export function loadForm(response: Aditional.ResponseLCGeneral) {
    const {
        id,
        logistical_concept,
        currency,
        original_cost,
        exchange_to_local,
        files,
        logistical_cost_receptions,
        document_number,
        agent
    } = response

    formCostGeneral.init({
        logistical_cost_id: id,
        logistical_concept: {
            value: logistical_concept.id.toString(),
            label: logistical_concept.name
        },
        currency: {
            value: currency.id.toString(),
            label: `${currency.name} (${currency.symbol})`
        },
        document_number: document_number && document_number.toString(),
        agent: null,
        original_cost: original_cost.toString(),
        exchange_to_local: exchange_to_local.toString(),
        total_cost: (original_cost * exchange_to_local).toString(),
        rcs: logistical_cost_receptions.map(lcr => ({
            value: lcr.reception_id.toString(),
            label: 'no es necesario para el checking'
        })),
        files: files.map(file => parseDriveFile(file)),
        productIds: response.applicable_product_ids
    })
    if (agent) {
        formCostGeneral.setValue('agent', {
            value: agent.id.toString(),
            label: agent.name
        })
    }
}

export function parseBodyEdition(
    f: Aditional.FormGeneralCost,
    currentFiles: FileModel[]
) {
    return {
        agent_id: f.agent && parseInt(f.agent.value),
        document_number: f.document_number,
        currency_id: parseInt(f.currency.value),
        original_cost: Number(f.original_cost),
        exchange_to_local: Number(f.exchange_to_local),
        reception_ids: f.rcs.map(rc => parseInt(rc.value)),
        new_files: f.files
            .filter(file => !file.id)
            .map(file => parseFile(file)),
        files_to_delete: currentFiles
            .filter(file => !f.files.find(_file => _file.id === file.id))
            .map(file => file.id),
        applicable_product_ids: f.productIds
    }
}

export function parseBodyCreation(
    f: Aditional.FormGeneralCost,
    type: 'national' | 'international'
) {
    return {
        agent_id: f.agent && parseInt(f.agent.value),
        document_number: f.document_number,
        type_logistical_cost_source_id: type === 'national' ? 4 : 3,
        currency_id: parseInt(f.currency.value),
        logistical_concept_id: parseInt(f.logistical_concept.value),
        logistical_declaration_id: storagePage.logistical_declaration.id,
        version: storagePage.version,
        original_cost: Number(f.original_cost),
        exchange_to_local: Number(f.exchange_to_local),
        reception_ids: f.rcs.map(rc => parseInt(rc.value)),
        files: f.files.map(file => parseFile(file)),
        applicable_product_ids: f.productIds
    }
}
