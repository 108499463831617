import { useHistory } from 'react-router'
import List from '../../../sections/List'
import { controlList, storagePage } from '../storage'
import moment from 'moment'
import Rules from '../../../components/Rules'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import BtnActionTable from '../../../components/BtnActionTable'
import RunAccountingContainer, { RunAccounting } from '../RunAccounting'

export default function Table() {
    const { push } = useHistory()

    return (
        <List control={controlList} theadWhiteSpace="normal">
            {(cost_estimate, e) => (
                <tr key={cost_estimate.id}>
                    <td>{e}</td>
                    <td>{cost_estimate.reception.code}</td>
                    <td>
                        {cost_estimate.reception.purchases.map(
                            (purchase, i) => (
                                <p key={i}>{purchase.code}</p>
                            )
                        )}
                    </td>
                    <td>
                        {getMonth(
                            cost_estimate.reception.document.working_period
                                .month
                        )}
                    </td>
                    <td>
                        {cost_estimate.reception.number_shipment ? 'Si' : 'No'}
                    </td>
                    <td>
                        {cost_estimate.reception.currency.symbol}{' '}
                        {buildNumberFormat(
                            cost_estimate.reception.purchase_local_cost
                        )}
                    </td>
                    <td>
                        {cost_estimate.reception.currency.symbol}{' '}
                        {buildNumberFormat(cost_estimate.estimate_cost)}
                    </td>
                    <td>
                        {getPercent(
                            cost_estimate.estimate_cost,
                            cost_estimate.reception.purchase_local_cost
                        )}
                    </td>
                    <td>
                        {cost_estimate.reception.has_logistical_declaration
                            ? cost_estimate.reception.currency.symbol +
                              ' ' +
                              buildNumberFormat(
                                  cost_estimate.reception.logistical_cost
                              )
                            : '-'}
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU289">
                                <BtnActionTable
                                    title="Editar"
                                    iconName="bs-pencil"
                                    disabled={
                                        !storagePage.company_enabled ||
                                        cost_estimate.reception.document
                                            .has_accounting
                                    }
                                    onClick={() =>
                                        push(
                                            `/costs/cost_estimates/${cost_estimate.id}/edition`
                                        )
                                    }
                                />
                            </Rules>
                            <Rules cu="CU312">
                                <RunAccountingContainer
                                    control={controlList}
                                    body={{
                                        costing_estimate_id: cost_estimate.id
                                    }}
                                    disabled={
                                        !storagePage.company_enabled ||
                                        cost_estimate.reception.document
                                            .has_accounting
                                    }
                                >
                                    <RunAccounting
                                        path={`/v5.6/apis/costing_estimates/run_accounting`}
                                    >
                                        Se generarán los asientos contables para
                                        la recepción de compra y dejará de ser
                                        editable. ¿Está seguro de generar
                                        asientos contables para la recepción de
                                        compra{' '}
                                        <b>
                                            {
                                                cost_estimate.reception.document
                                                    .code
                                            }
                                        </b>
                                        ?
                                    </RunAccounting>
                                </RunAccountingContainer>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}

function getMonth(index) {
    const month = moment.months(index - 1)
    return month.charAt(0).toUpperCase() + month.slice(1)
}

function getPercent(a, b) {
    const result = a / b
    const percent = result * 100
    return '% ' + buildNumberFormat(percent)
}
