import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowIva(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [{ value: 'Iva' }]
    data.forEach(d => {
        row.push({
            value: d.data_summary.iva_original,
            format: '0.00'
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: d.data_summary.iva_local,
            format: '0.00'
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
