import { createContext } from 'react'
import { ContextData } from './types'

const Context = createContext<ContextData>({
    onClick: () => null,
    className: '',
    isEnabled: false,
    type: 'option',
    disabled: false,
    iconHide: false
})

export default Context
