import classnames from 'classnames'
import Icon from '../Icon'
import { PropsBtnActionTable } from './types'

export default function BtnActionTable({
    iconName,
    title = '',
    onClick = () => null,
    className = '',
    disabled = false,
    size = 15
}: PropsBtnActionTable) {
    const classNamesBtn = classnames(`btn-option`, className)
    return (
        <button
            type="button"
            title={title}
            className={classNamesBtn}
            disabled={disabled}
            onClick={onClick}
        >
            <Icon size={size} name={iconName} />
        </button>
    )
}
