import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import { controlTabs, controlList } from '../storage'
import Filters from './Filters'
import Table from './Table'

controlTabs.setSection('Abiertos', Table, 'assignment')
controlTabs.setSection('Cerrados', Table, 'assignment-checked')

export default function RequisitionsManagement() {
    const { state } = useInitial()
    const value = controlTabs.useStep(to => {
        controlList.filter.params.page = 1
        switch (to) {
            case 'step1':
                controlList.filter.params.status = 'Abiertas'
                controlList.done().then(() => {
                    controlList.renderMain()
                })
                break
            case 'step2':
                controlList.filter.params.status = 'Cerradas'
                controlList.done().then(() => {
                    controlList.renderMain()
                })
                break
        }

        return true
    })

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <Filters />
                    <controlTabs.SectionTabs
                        value={value}
                        type="tab"
                        mode="simple"
                        className="mb-4 mt-2"
                    />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
