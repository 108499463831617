import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import ReactPopover from '../../../../components/ReactPopover'
import { tableProductsControl } from '../../storage'
import Table from './Table'
import useProduct from './useProduct'

export default function Step2() {
    const open = useProduct()

    return (
        <Panel mode="form">
            <PanelHeader
                title="Información general"
                helper={<TooltipProducts />}
            >
                <Button
                    size="sm"
                    outline
                    color="primary"
                    onClick={() => open()}
                >
                    Agregar +
                </Button>
            </PanelHeader>
            <PanelBody>
                <Table />
            </PanelBody>
        </Panel>
    )
}

function TooltipProducts() {
    const { message } = tableProductsControl.useHelpers()
    return (
        <>
            {message && (
                <ReactPopover
                    component={<Icon name="warning" className="text-red-500" />}
                >
                    <p>{message}</p>
                </ReactPopover>
            )}
        </>
    )
}
