import { ValueOptions } from './type'

export function expectValue(value: string, options: ValueOptions) {
    const v = value === null ? '' : value
    const regex = options.negative ? /^[0-9\-.]+$/g : /^[0-9.]+$/g
    let result = regex.test(v) ? v : v.replace(/[^\d]/g, '')
    result = negation(result)
    result = point(result)
    result = decimal(result, options.decimal)
    result = maximum(result, options.max)
    return fixZeros(result)
}

function negation(value: string): string {
    const negative = value.search('-') === 0 ? '-' : ''
    const result = value.split('').filter(chart => chart !== '-')
    return `${negative}${result.join('')}`
}

function point(value: string): string {
    const [n1] = value.split('')
    if (n1 === '.') return ''
    const count = (value.match(/\./g) || []).length
    if (count > 1) {
        const newChars = []
        let pushedPoint = false
        for (const char of value.split('.')) {
            newChars.push(char)
            if (!pushedPoint) {
                newChars.push('.')
                pushedPoint = true
            }
        }
        return newChars.join('')
    }
    return value
}

function decimal(value: string, count: number): string {
    const [i, d = ''] = value.split('.')
    if (count === 0 && value.search('.') > -1) return i
    if (d.length === 0) return value
    if (d.length > count) {
        return `${i}.${d.slice(0, -(d.length - count))}`
    }
    return `${i}.${d}`
}

function maximum(value: string, max: number): string {
    if (max === null) return value
    const parsed = parseInt(value)
    if (parsed > max) {
        const rem = parsed - max
        return (parsed - rem).toString()
    }
    return value
}

function fixZeros(value: string): string {
    if (value === '') return value
    const [z1, z2] = value.split('')
    if (z2 === '.') return value
    if ((z1 === '0' && z2 === '0') || (z1 === '0' && z2 !== '0')) {
        return parseInt(value).toString()
    }
    return value
}
