import { useParams } from 'react-router'
import modal from '../../sections/modal'
import { GetProviderCatalog } from '../../types/provider_catalog'
import Notify from '../../utilities/Notify'
import { Popup } from '../../utilities/popup'
import { formCatalog, providerList } from './storage'
import { FormProductCatalog, PostBodyCatalog, PutBodyCatalog } from './types'
import initCreation from './utilities/initCreation'
import initEdition from './utilities/initEdition'
import ProductForm from './View/ProductForm'

export default function useSubmitProductCatalog(
    productProviderCompany?: GetProviderCatalog
) {
    const { provider_id } = useParams<{ provider_id: string }>()
    const id = productProviderCompany ? `/${productProviderCompany.id}` : ''
    const message = productProviderCompany
        ? `¿Está seguro de guardar el catálogo del producto ${productProviderCompany.product_company.product_country.product.name}?`
        : '¿Está seguro de guardar el catálogo del producto?'
    const { submit, observer, validation } = formCatalog.useSubmit(
        `/v5.6/apis/product_provider_companies${id}`,
        {
            done: providerList,
            method: productProviderCompany ? 'put' : 'post',
            message,
            onError: handleError
        }
    )

    validation(v => {
        v.company.isEmpty('Se requiere seleccionar la compañía')
        v.product.isEmpty('Se requiere seleccionar el producto')
        v.currency.isEmpty('Se requiere seleccionar la moneda')
        v.purchase_price.isEmpty('Se requiere ingresar el precio de compra')
        v.provider_code
            .minLength(2, 'El número de caracteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el código de proveedor')
        v.provider_description
            .minLength(2, 'El número de caracteres válidos es 2 - 3000')
            .isEmpty('Se requiere ingresar la descripción del proveedor')
    })

    function obsForCreation(f: FormProductCatalog): PostBodyCatalog {
        return {
            provider_id: parseInt(provider_id),
            product_provider_companies: [
                {
                    company_id: parseInt(f.company.value),
                    product_id: parseInt(f.product.value),
                    currency_id: parseInt(f.currency.value),
                    purchase_price: Number(f.purchase_price),
                    provider_code: f.provider_code,
                    provider_description: f.provider_description || null
                }
            ]
        }
    }

    function obsForEdition(f: FormProductCatalog): PutBodyCatalog {
        return {
            currency_id: parseInt(f.currency.value),
            purchase_price: Number(f.purchase_price),
            provider_code: f.provider_code,
            provider_description: f.provider_description
        }
    }

    observer<PostBodyCatalog | PutBodyCatalog>(
        productProviderCompany ? obsForEdition : obsForCreation
    )

    function handleModal() {
        formCatalog.init()
        if (productProviderCompany) {
            modal(
                ProductForm,
                async () => {
                    const result = await initEdition(productProviderCompany.id)

                    if (!result) {
                        Popup.error(
                            'Algo salió mal...',
                            'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
                        )
                        return
                    }

                    return {
                        title: 'Editar producto',
                        onSubmit: submit
                    }
                },
                'md'
            )

            return
        }

        initCreation()
        modal(
            ProductForm,
            {
                title: 'Nuevo producto',
                onSubmit: submit
            },
            'md'
        )
    }

    return handleModal
}

function handleError() {
    Notify.error(
        'Ya existe un registro con la misma compañía, producto y moneda'
    )
}
