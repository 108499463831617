import { FormControls } from '../../../../components/FormUtilities'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'

export default function SectionGeneralInformation() {
    return (
        <Panel mode="form">
            <PanelHeader title="Información general" />
            <PanelBody>
                <FormControls>
                    <Fields1 />
                    <Fields2 />
                    <Fields3 />
                </FormControls>
            </PanelBody>
        </Panel>
    )
}
