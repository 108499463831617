import 'moment/locale/es'
import Logo from '../../../../assets/img/logo.png'
import { Text, View, Font, Image } from '@react-pdf/renderer'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import moment from 'moment'
import Company from '../../../../models/Company'
import { LogisticalDeclarationPDFData } from '../../types'
import { generalStyles as styles } from './GeneralStyles'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

export default function Header({
    company,
    data
}: {
    company: Company
    data: LogisticalDeclarationPDFData
}) {
    moment.locale('es')

    return (
        <>
            <View style={styles.header} fixed>
                <View style={styles.companyWatemark}>
                    <Image style={styles.companyWatemark__logo} src={Logo} />
                    <View style={styles.companyWatemark__info}>
                        <Text style={styles.fontBold}>{company.name}</Text>
                        {company.fiscal_document === '' && (
                            <Text>
                                {company.type_fiscal_document}:{' '}
                                {company.fiscal_document}
                            </Text>
                        )}
                        <Text>{company.address}</Text>
                    </View>
                </View>
                <View style={styles.title}>
                    <Text>
                        {data.document_id
                            ? 'COSTEO - DETALLE DE COSTOS'
                            : 'BORRADOR - DETALLE DE COSTOS'}
                    </Text>
                </View>
                <View style={styles.list}>
                    <View>
                        <Text style={styles.list__element__name}>
                            Nº declaración
                        </Text>
                        <Text style={styles.list__element__value}>
                            {data.number_declaration
                                ? data.number_declaration
                                : '-'}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.list__element__name}>
                            Agente aduanal
                        </Text>
                        <Text style={styles.list__element__value}>
                            {data.agent ? data.agent.name : '-'}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.list__element__name}>
                            Tipo de embarque
                        </Text>
                        <Text style={styles.list__element__value}>
                            {data.type_shipment.name}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.list__element__name}>
                            Fecha de declaración
                        </Text>
                        <Text style={styles.list__element__value}>
                            {data.date_declaration}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.list__element__name}>Página</Text>
                        <Text
                            style={styles.list__element__value}
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                        />
                    </View>
                </View>
            </View>
        </>
    )
}
