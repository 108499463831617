function Clipboard(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 .5h18c1.1 0 2 .9 2 2v13c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2v-13c0-1.1.9-2 2-2zm0 2v13h8v-13H2zm18 13h-8v-13h8v13zM19 6h-6v1.5h6V6zm-6 2.5h6V10h-6V8.5zm6 2.5h-6v1.5h6V11z"
            />
        </svg>
    )
}

export default Clipboard
