import { LogisticalDeclarationExcelData } from '../../../types'
import { Column } from './types'

export function getColumnSize(data: LogisticalDeclarationExcelData[]) {
    const column: Column[] = [{ width: 30 }]
    data.forEach(() => {
        column.push({})
        column.push({})
        column.push({})
        column.push({ width: 25 })
        column.push({ width: 2 })
    })
    return column
}
