import moment from 'moment'
import useStorage from '../../../hooks/useStorage'
import { GetPurchase } from '../type'
import buildCompanyName from '../../../utilities/buildCompanyName'

export default function GeneralInformation({ data }: { data: GetPurchase }) {
    const { countries } = useStorage()

    const getCountry = (country_id: number) =>
        countries.find(country => country.id === country_id)

    function getLocate(
        relation_address: GetPurchase['relation_address'],
        shipping_type_purchase_id: number
    ) {
        if (shipping_type_purchase_id === 1) {
            if (relation_address.company) {
                return relation_address.company.name
            } else if (relation_address.warehouse) {
                return relation_address.warehouse.name
            } else if (relation_address.agent) {
                return relation_address.agent.name
            } else {
                return relation_address.locate
            }
        } else {
            return relation_address.locate
        }
    }

    return (
        <div className="body__cards">
            <div className="column">
                <div className="card_info">
                    <h3>INFORMACIÓN GENERAL</h3>
                    <ul className="list_info">
                        <li>
                            <p>Proveedor</p>
                            <div className="flex">
                                <img
                                    src={
                                        getCountry(
                                            data.provider_company.provider
                                                .country_id
                                        ).image_url
                                    }
                                    className="w-6 ml-1 h-4 self-center"
                                />
                                <p
                                    className={
                                        !data.provider_company.provider
                                            .is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {
                                        data.provider_company.provider
                                            .commercial_name
                                    }
                                </p>
                            </div>
                        </li>
                        <li>
                            <p>Compañía de facturación</p>
                            <div className="flex">
                                <img
                                    src={
                                        getCountry(
                                            data.invoicing_company.country_id
                                        ).image_url
                                    }
                                    className="w-6 ml-1 h-4 self-center"
                                />
                                <p
                                    className={
                                        data.invoicing_company.is_enabled
                                            ? ''
                                            : 'line-through'
                                    }
                                >
                                    {buildCompanyName(
                                        data.invoicing_company.name
                                    )}
                                </p>
                            </div>
                        </li>
                        <li>
                            <p>Tipo de orden de compra</p>
                            <p>{data.type_purchase.name}</p>
                        </li>
                        <li>
                            <p>Prioridad</p>
                            <p>{data.priority_purchase.name}</p>
                        </li>
                        <li>
                            <p>Fecha requerida</p>
                            <p>
                                {moment(data.required_date)
                                    .tz(data.invoicing_company.timezone)
                                    .format('DD/MM/YYYY')}
                            </p>
                        </li>
                        <li>
                            <p>Fecha confirmada</p>
                            <p>
                                {data.provider_confirmation_date
                                    ? moment(data.provider_confirmation_date)
                                          .tz(data.invoicing_company.timezone)
                                          .format('DD/MM/YYYY')
                                    : '-'}
                            </p>
                        </li>
                        <li>
                            <p>Condición de pago</p>
                            <p>{data.payment_condition_description || '-'}</p>
                        </li>
                        <li>
                            <p>Comentario</p>
                            <p>{data.document.description || '-'}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <div className="card_info">
                    <h3>INSTRUCCIONES DE DESPACHO/RETIRO</h3>
                    <ul className="list_info">
                        <li>
                            <p>Tipo de envío</p>
                            <p>{data.shipping_type_purchase.name}</p>
                        </li>
                        <li>
                            <p>País de despacho/retiro</p>
                            <div className="flex">
                                <img
                                    src={
                                        getCountry(
                                            data.relation_address.country_id
                                        ).image_url
                                    }
                                    className="w-6 ml-1 h-4 self-center"
                                />
                                <p>
                                    {
                                        getCountry(
                                            data.relation_address.country_id
                                        ).name
                                    }
                                </p>
                            </div>
                        </li>
                        <li>
                            <p>Forma de envío</p>
                            <p>{data.shipping_method_purchase.name}</p>
                        </li>
                        <li>
                            <p>Lugar de despacho/retiro</p>
                            <p>
                                {getLocate(
                                    data.relation_address,
                                    data.shipping_method_purchase.id
                                )}
                            </p>
                        </li>
                        <li>
                            <p>Dirección</p>
                            <p>{data.relation_address.address}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
