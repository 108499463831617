import { CodeError, Exception } from '../http/type'
import { Popup } from '../popup'

export default class ErrorControl<T> {
    private exception: Exception = null

    setException(exception: Exception, alert = true) {
        this.exception = exception
        if (exception && alert && exception.type !== 'customer') {
            const error = exception
            if (error.type === 'dependency' || error.type === 'development') {
                const errors = Object.values(error.errors)
                const message =
                    errors.length > 0
                        ? errors[0].description
                        : 'Ni idea del porque el error xD'
                Popup.error('Lo sentimos', message)
            } else {
                Popup.error(
                    'Algo salió mal...',
                    'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
                )
            }
        }
    }

    getError = (
        codeError?: CodeError,
        parseMessage?: string,
        fieldName?: string | keyof T
    ) => {
        if (!this.exception) return ''

        const error = fieldName
            ? this.exception.errors[fieldName]
            : Object.values(this.exception.errors)[0]

        if (!error) return ''

        if (codeError) {
            if (error.code_error === codeError) {
                return parseMessage || error.description
            } else {
                return ''
            }
        } else {
            return parseMessage || error.description
        }
    }

    private hasError(fieldName: keyof T | string) {
        if (this.exception && this.exception.errors[fieldName]) {
            return true
        }
        return false
    }

    private errorNameActives: Record<string, keyof T> = {}

    getErrorByField = (fieldName: keyof T) => {
        let currentFieldName = fieldName as string

        const getError = (
            codeError?: CodeError,
            parseMessage?: string,
            fieldNameOrAlias?: keyof T | string
        ) => {
            const name = (fieldNameOrAlias || fieldName) as string
            currentFieldName = name
            this.errorNameActives[name] = fieldName
            return this.getError(codeError, parseMessage, name)
        }

        return {
            error: this.hasError(fieldName)
                ? this.exception.errors[fieldName]
                : null,
            getError,
            clear: () => {
                if (!this.hasError(currentFieldName)) return
                delete this.exception.errors[currentFieldName]
            }
        }
    }

    clear() {
        this.exception = null
    }

    clearError(fieldName: keyof T) {
        if (!this.exception) return
        const alias = Object.values(this.errorNameActives).filter(
            name => name === fieldName
        )
        alias.forEach(alia => {
            delete this.exception.errors[alia]
        })
    }
}
