import { createContext } from 'react'

interface StepsContextData {
    value: string
    onStep: (value: string) => void
}

export const StepsContext = createContext<StepsContextData>({
    value: '',
    onStep: () => null
})
