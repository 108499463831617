import FormEvents from '../../utilities/FormEvents'
import { RolePermissionData } from './types'

export interface DataGroupPermision {
    id: number
    name: string
    active: boolean
}

export interface DataPermision {
    id: number
    name: string
    active: boolean
    permission_role_id?: number
}

export interface ModuleForm {
    groupPermision: FormEvents<DataGroupPermision>
    permissions: FormEvents<DataPermision>[]
}

export interface Data {
    modules: ModuleForm[]
    rolePermission: RolePermissionData
}

export const data: Data = {
    modules: [],
    rolePermission: null
}
