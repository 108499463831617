import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('PROVIDER', [
    'FORM1',
    'TABLE_CONTACTS',
    'FORM_CONTACT',
    'TABLE_COMPANIES',
    'FORM_CONFIG',
    'TABLE_DISPATCH',
    'TABLE_RETREAT',
    'FORM_DISPATCH',
    'FORM_RETREAT',
    'PLACE_DISPATCH_FIELDS'
])
