import { FormControls } from '../../../../components/FormUtilities'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import UploadFiles from './UploadFiles'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'
import Fields4 from './Fields4'
import Toggles from './Toggles'
import Button from '../../../../components/Button'
import useSubmitInfo from '../../useSubmitInfo'
import { storagePage } from '../../storage'

export default function Step1() {
    const submit = useSubmitInfo(storagePage.product?.id || null)
    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Información de producto">
                    {storagePage.mode === 'edition' && (
                        <Button
                            size="sm"
                            color="accent"
                            onClick={() => submit()}
                        >
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <Information />
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Codificación Producto">
                    {storagePage.mode === 'edition' && (
                        <Button
                            size="sm"
                            color="accent"
                            onClick={() => submit()}
                        >
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <Codification />
            </Panel>
        </>
    )
}

function Information() {
    return (
        <PanelBody>
            <FormControls>
                <Fields1 />
                <Fields2 />
                <Fields3 />
                <UploadFiles />
                <Toggles />
            </FormControls>
        </PanelBody>
    )
}

function Codification() {
    return (
        <PanelBody>
            <FormControls>
                <Fields4 />
            </FormControls>
        </PanelBody>
    )
}
