import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import useCancel from '../../hooks/useCancel'
import { get } from '../../utilities/http'
import { LicenseData } from './types'
import { storagePage, formProductLicense, tableProducts } from './storage'
import build from './utilities/build'

export default function useInitial() {
    const [pageState, setPageState] = useState(StateCase.LOADING)
    const { license_id } = useParams<{ license_id: string }>()
    const { onClick, init } = useCancel(
        [formProductLicense, tableProducts],
        '/configuration/licenses'
    )
    useEffect(() => {
        get<LicenseData>(`/v5.6/apis/licenses/${license_id}`).then(result => {
            if (result.error) {
                setPageState(StateCase.ERROR)
            } else {
                storagePage.license = result.data.result
                build()
                setPageState(StateCase.SUCCESS)
                init()
            }
        })

        return () => {
            formProductLicense.init()
            tableProducts.setList([])
            storagePage.products.clear()
        }
    }, [])

    return { pageState, cancel: onClick }
}
