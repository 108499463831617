import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import { CostEstimateData, StoragePage } from './types'

export const controlList = new ControlList<CostEstimateData>(Keys.MAIN, {
    path: '/v5.6/apis/costing_estimates',
    theads: [
        '#',
        { value: 'reception_code', label: 'Recepción de compra anticipada' },
        { value: 'purchase_code', label: 'OC' },
        'Periodo laboral',
        'Embarcado',
        { value: 'purchase_cost', label: 'Costo de compra' },
        { value: 'estimate_cost', label: 'Costo estimado' },
        'Costo estimado / Costo de compra',
        'Costo real',
        'Acciones'
    ]
})

export const storagePage: StoragePage = {
    company_enabled: false
}
