import writeXlsxFile, { Schema } from 'write-excel-file'
import BtnActionTable from '../../../components/BtnActionTable'
import { get } from '../../../utilities/http'
import { ConsumerVoucherExcel, ConsumerVoucherProductExcel } from '../types'

export default function ExportExcel({
    consumer_voucher_id
}: {
    consumer_voucher_id: number
}) {
    async function download() {
        const { data } = await get<ConsumerVoucherExcel>(
            `/v5.6/apis/consumer_voucher/${consumer_voucher_id}/excel`
        )
        const schema: Schema<ConsumerVoucherProductExcel> = [
            {
                column: 'Código de producto',
                width: 20,
                value: d => {
                    return d.product.code
                }
            },
            {
                column: 'Descripción de producto',
                width: 40,
                value: d => {
                    return d.product.description
                }
            },
            {
                column: 'Marca',
                width: 20,
                value: d => {
                    return d.product.brand.name
                }
            },
            {
                column: 'Cantidad',
                width: 20,
                type: Number,
                format: '0',
                value: d => {
                    return d.quantity
                }
            },
            {
                column: 'Costo Promedio Unitario',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return d.operation?.average_unit_cost || 0.0
                }
            },
            {
                column: 'Subtotal',
                width: 20,
                type: Number,
                format: '0.00',
                value: d => {
                    return (d.operation?.average_unit_cost || 0.0) * d.quantity
                }
            }
        ]
        await writeXlsxFile(data.result.consumer_voucher_products, {
            fileName: `${data.result.document.code}.xlsx`,
            schema
        })
    }

    return (
        <BtnActionTable
            title="Excel"
            iconName="bs-file-earmark-excel"
            onClick={download}
        />
    )
}
