import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import MinimalStowage from '../../../../models/MinimalStowage'
import {
    formConsumptionVoucher,
    productsControl,
    reqProducts,
    reqSubStowage,
    storagePage
} from '../../storage'

export default function useSubStowage() {
    const { data, props: propsSearch } = useGet<DestinyStowageValue[]>(
        reqSubStowage.observer(
            '/v5.6/apis/stowages/:stowage_id/substowages',
            storagePage.mode === 'edition' &&
                !!reqSubStowage.getParam('stowage_id')
        ),
        []
    )
    const { props } = formConsumptionVoucher.useSelect('sub_stowage')

    function handleChange(option: Option) {
        props.onChange(option)
        formConsumptionVoucher.setProps(
            'products',
            {
                disabled: option.value === '0'
            },
            false
        )
        reqProducts.setQuery('origin_minimal_stowage_id', option.value)
        reqProducts.deleteQuery('not_logical_product_ids')
        reqProducts.load()
        formConsumptionVoucher.setValue('products', [])
        productsControl.setList([])
        productsControl.renderMain()
    }

    return {
        props,
        propsSearch,
        handleChange,
        minimalSubStowages: data.result
    }
}
interface DestinyStowageValue extends MinimalStowage {
    substowage_name: string
}
