import Checkbox from '../../../../components/Checkbox'
import { TableList } from '../../../../sections/ListForm'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import FormEvents from '../../../../utilities/FormEvents'
import { tableCompaniesControl } from '../../storage'
import { TableFormCompany } from '../../types'

interface PropsField {
    control: FormEvents<TableFormCompany>
    disabled: boolean
}

function Buy({ control, disabled }: PropsField) {
    const { props } = control.useCheck('can_buy')
    return <Checkbox {...props} disabled={disabled} />
}

function Sell({ control, disabled }: PropsField) {
    const { props } = control.useCheck('can_sell')
    return <Checkbox {...props} disabled={disabled} />
}

export default function TableCompanies() {
    return (
        <TableList control={tableCompaniesControl}>
            {(data, control, e) => (
                <tr key={e}>
                    <td>
                        <img src={data.image} className="w-7" />
                    </td>
                    <td className={`${!data.is_enabled && 'line-through'}`}>
                        {buildCompanyName(data.company_name)}
                    </td>
                    <td>
                        <Buy disabled={!data.is_enabled} control={control} />
                    </td>
                    <td>
                        <Sell disabled={!data.is_enabled} control={control} />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
