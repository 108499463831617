import { PDFViewer } from '@react-pdf/renderer'
import { render, useRender } from 'redity'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Modal, { ModalBody, ModalFooter } from '../../components/ModalPrint'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import { HttpResponse } from '../../utilities/http/type'
import { State, ModalPrintOpenData } from './types'

const Null = () => <></>

const initialState = (): State<any> => ({
    isOpen: false,
    Component: Null,
    data: null,
    mode: StateCase.LOADING,
    cb: () => null,
    path: '',
    size: 'md'
})

const state = initialState()

export default function ModalPrint() {
    const r = useRender('MODAL_PRINT')
    function handleClose() {
        const values = initialState()
        Object.keys(values).forEach(key => {
            state[key] = values[key]
        })
        r()
    }

    return (
        <Modal open={state.isOpen} size={state.size}>
            <ModalBody>
                {state.mode === StateCase.SUCCESS && (
                    <PDFViewer width="100%" style={{ minHeight: '80vh' }}>
                        <state.Component {...state.props} data={state.data} />
                    </PDFViewer>
                )}
                {state.mode === StateCase.LOADING && <Loading height="240px" />}
                {state.mode === StateCase.ERROR && (
                    <div className="flex h-60 flex-col items-center justify-center">
                        <p>Hubo problemas al cargar los datos</p>
                        <Button
                            className="mt-2"
                            color="warning"
                            onClick={() => viewPrint(state.path, state.cb)}
                        >
                            Recargar
                        </Button>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClose} color="ghost">
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export async function viewPrint<T>(
    path: string,
    cb: (data: T) => ModalPrintOpenData<T>,
    size?: 'sm' | 'md' | 'lg'
) {
    state.isOpen = true
    state.size = size || 'lg'
    state.cb = cb
    state.path = path
    state.mode = StateCase.LOADING
    render('MODAL_PRINT')
    if (path !== null) {
        const response: HttpResponse<T> = await get<T>(path)
        if (response.error) {
            state.mode = StateCase.ERROR
            render('MODAL_PRINT')
            return
        }
        const result = cb(response.data.result)
        state.props = result.props || {}
        state.data = response.data.result
        state.Component = result.Component
    } else {
        state.Component = cb(null).Component
    }
    state.mode = StateCase.SUCCESS
    render('MODAL_PRINT')
}
