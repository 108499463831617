import { createContext } from 'react'

export interface LayoutData {
    isSidebarOpen: boolean
    toogle: () => void
    open: () => void
    close: () => void
}

const LayoutContext = createContext<LayoutData>({
    isSidebarOpen: false,
    toogle: () => null,
    open: () => null,
    close: () => null
})

export default LayoutContext
