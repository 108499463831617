import classNames from 'classnames'
import { useEffect } from 'react'
import FileView from '../FileView'
import Helper from '../Helper'
import Icon from '../Icon'
import './excelField.css'
import { PropsExcelField } from './types'
import useExcelField from './useExcelField'

export default function ExcelField({
    helper,
    file,
    onChange: change,
    className = '',
    maxSizeFile = 10000000,
    placeholder = 'Haz clic o arrastra un documento para añadirle',
    onLoad = () => null,
    dontDownload = false
}: PropsExcelField) {
    const { ref, onChange, onDelete } = useExcelField(
        change,
        maxSizeFile,
        onLoad
    )

    useEffect(() => {
        if (ref.current && file === null) {
            ref.current.value = ''
            ref.current.files = null
        }
    }, [file])

    return (
        <div className={className}>
            {!file && (
                <div
                    className={classNames('excel-field', {
                        helper: !!helper
                    })}
                >
                    <Icon
                        name="cloud-upload"
                        className="text-accent mb-2"
                        size={34}
                    />
                    <p>{placeholder}</p>
                    <input type="file" ref={ref} onChange={onChange} />
                </div>
            )}
            {file && (
                <FileView
                    file={file}
                    onDelete={onDelete}
                    className={helper && 'border-error'}
                    dontDownload={dontDownload}
                />
            )}
            {!file && (
                <span className="text-gray-400 text-2xs mt-2">
                    Archivos permitidos: XLSX
                </span>
            )}
            <Helper>{helper}</Helper>
        </div>
    )
}
