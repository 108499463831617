import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import useSubmit from '../useSubmit'
import useCancel from '../../../hooks/useCancel'
import { formInformation } from '../storage'

export default function Header() {
    const submit = useSubmit()
    const { onClick } = useCancel(formInformation, '/costs/cost_estimates')
    return (
        <PageHeader subtitle="(*) (*) Complete todos los campos para la estimación ">
            <Button color="ghost" onClick={onClick}>
                Cancelar
            </Button>
            <Button className="ml-2" onClick={() => submit()}>
                Guardar
            </Button>
        </PageHeader>
    )
}
