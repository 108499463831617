import classNames from 'classnames'
import { MouseEvent, useContext } from 'react'
import SelectContext from '../../contexts/select-context'
import Icon from '../Icon'
import { PropsOption, PropsSimpleOption } from './types'

export default function Option({
    label,
    value,
    icon = null,
    onClick = () => null,
    disabled = false,
    image = null,
    label2 = null,
    strikeOut = false
}: PropsOption) {
    const { onClick: click, optionsChecks } = useContext(SelectContext)
    function handleClick(
        ev: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
    ) {
        click({ label, value, image })
        onClick(ev)
    }

    const checked = !!optionsChecks.find(check => check === value)

    return (
        <a
            className={classNames(
                'py-3 px-4 transition hover:bg-base-200 focus:bg-base-200 rounded-xl flex items-center justify-between',
                {
                    'opacity-50 cursor-auto pointer-events-none': disabled,
                    'cursor-pointer': !disabled,
                    'line-through': strikeOut
                }
            )}
            onClick={handleClick}
        >
            <div className="flex items-center text-gray-500">
                {icon === null && !image && (
                    <div
                        className="rounded bg-accent inline-block mr-2"
                        style={{
                            width: '8px',
                            minWidth: '8px',
                            height: '8px'
                        }}
                    />
                )}
                {icon && !image && (
                    <Icon name={icon} className="mr-2 text-secondary" />
                )}
                {image && (
                    <img
                        src={image}
                        style={{ minWidth: '24px', width: '24px' }}
                        className="mr-2 rounded"
                    />
                )}
                <div
                    className={`${checked && 'text-primary font-univers-bold'}`}
                >
                    <div
                        className="text-xs"
                        style={{ ...(label2 && { minWidth: '120px' }) }}
                    >
                        {label}
                    </div>
                    {label2 && (
                        <div className="text-2xs text-gray-400">{label2}</div>
                    )}
                </div>
            </div>
            {checked && <Icon name="done" size={15} className="text-accent" />}
        </a>
    )
}

export function SimpleOption({
    children,
    onClick = () => null
}: PropsSimpleOption) {
    return (
        <div
            onClick={onClick}
            className={
                'py-3 px-4 transition hover:bg-base-200 focus:bg-base-200 rounded-xl flex items-center'
            }
        >
            <div
                className="rounded bg-accent inline-block mr-2"
                style={{
                    width: '8px',
                    minWidth: '8px',
                    height: '8px'
                }}
            />
            <div className="text-xs">{children}</div>
        </div>
    )
}
