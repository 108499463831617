import DropContent from '../../../components/DropContent'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectTouch from '../../../components/SelectTouch'
import { Option } from '../../../config/types/component'
import useGet from '../../../hooks/useGet'
import MinimalStowage from '../../../models/MinimalStowage'
import Stowage from '../../../models/Stowage'
import Warehouse from '../../../models/Warehouse'
import {
    controlList,
    filterForm,
    reqStowage,
    reqSubStowage,
    reqWarehouse,
    reqSummaryData
} from '../storage'
import { MinimalStowageResponse } from '../types'
import moment from 'moment'
import DatePicker from '../../../components/Datepicker'

function WarehouseOption() {
    const { props } = filterForm.useSelect<Warehouse>('warehouse_id')

    const { data, loading } = useGet<Warehouse[]>(
        reqWarehouse.observer('/v5.6/apis/warehouses/company/', true),
        []
    )

    return (
        <FormControl label="Almacén">
            <SelectTouch
                {...props}
                loading={loading}
                placeholder="Seleccionar almacén"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function StowageOption() {
    const { props } = filterForm.useSelect<Stowage>('stowage_id')

    const { data, props: propsSearch } = useGet<Stowage[]>(
        reqStowage.observer('/v5.6/apis/stowages/', true),
        []
    )

    function handleChange(option: Option) {
        reqSubStowage.setParam('stowage_id', option.value)
        reqSubStowage.load()
        props.onChange(option)
    }

    return (
        <FormControl label="Bodega">
            <SelectTouch
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar bodega"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function StartDateField() {
    const { props } = filterForm.useDate('start_date')

    function handleChange(option: Option) {
        props.onChange(option)
        const start_date = moment(filterForm.store().start_date).format(
            'YYYY-MM-DD'
        )
        reqSummaryData.setQuery('start_date', start_date)
    }

    return (
        <FormControl label="Fecha Inicio" className="mt-1">
            <DatePicker
                {...props}
                infoZ=""
                placeholder="Fecha Inicio"
                onChange={handleChange}
            />
        </FormControl>
    )
}

function EndDateField() {
    const { props } = filterForm.useDate('end_date')

    function handleChange(option: Option) {
        props.onChange(option)
        const end_date = moment(filterForm.store().end_date).format(
            'YYYY-MM-DD'
        )
        reqSummaryData.setQuery('end_date', end_date)
    }

    return (
        <FormControl label="Fecha Fin" className="mt-1">
            <DatePicker
                {...props}
                infoZ=""
                placeholder="Fecha Fin"
                onChange={handleChange}
            />
        </FormControl>
    )
}

function SubStowage() {
    const { props } = filterForm.useSelect<MinimalStowage>('minimal_stowage_id')
    const { data, props: propsSearch } = useGet<MinimalStowageResponse[]>(
        reqSubStowage.observer(
            `/v5.6/apis/stowages/:stowage_id/substowages`,
            true
        ),
        []
    )

    let disabled = true
    if (filterForm.store().stowage_id) {
        const arrayStowage = [
            'Bodega Pedidos',
            'Bodega Remisionada',
            'Bodega Garantía Proveedor'
        ]

        disabled = !arrayStowage.includes(filterForm.store().stowage_id.label)
    }

    return (
        <FormControl label="Sub bodega">
            <SelectTouch
                {...props}
                {...propsSearch}
                disabled={disabled}
                placeholder="Seleccionar sub bodega"
            >
                <IterateOptions data={data.result} label="substowage_name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function FiltersForm() {
    const { props } = controlList.useForm(
        filterForm,
        form => ({
            start_date: form.start_date
                ? moment(form.start_date).format('DD/MM/YYYY')
                : null,
            end_date: form.end_date
                ? moment(form.end_date).format('DD/MM/YYYY')
                : null
        }),
        form => ({
            start_date: form.start_date
                ? moment(form.start_date).format('YYYY-MM-DD')
                : null,
            end_date: form.end_date
                ? moment(form.end_date).format('YYYY-MM-DD')
                : null
        })
    )

    async function handleApply() {
        await props.onApply()
    }

    return (
        <DropContent
            {...props}
            onApply={handleApply}
            label="Filtros"
            className="ml-2"
        >
            <WarehouseOption />
            <StartDateField />
            <EndDateField />
            <StowageOption />
            <SubStowage />
        </DropContent>
    )
}
