export default function ArrowBottom(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M6.678.839L4 3.51 1.322.839.5 1.66l3.5 3.5 3.5-3.5L6.678.84z" />
        </svg>
    )
}
