import { useRender } from 'redity'
import ProgressDialog from '../../components/ProgressDialog'
import { KEY_PROGRESS } from './constants'
import { state_progress } from './storage'

export default function ProgressComponent() {
    useRender(KEY_PROGRESS)
    const { open, children } = state_progress

    return <ProgressDialog open={open}>{children}</ProgressDialog>
}
