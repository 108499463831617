import { ReactNode } from 'react'
import { useRender } from 'redity'
import { PropsDialog } from '../../components/Dialog/types'
import { KEY_POPUP } from './constants'
import { statePopup, stateUtils } from './storage'

export default function usePopup(): PropsDialog & { children: ReactNode } {
    useRender(KEY_POPUP)

    function handleResult(result: boolean) {
        stateUtils && stateUtils.resolve(result)
        stateUtils.resolve = null
    }

    return {
        ...statePopup(),
        onResult: handleResult,
        children: stateUtils.children
    }
}
