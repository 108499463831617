import { useRender } from 'redity'
import Pagination from '../../components/Pagination'
import ControlList from '../../utilities/Control/ControlList'

interface PropsEnumeration {
    control: ControlList<any>
}

export default function Enumeration({ control }: PropsEnumeration) {
    useRender(control.keyRender, control.indexs.PAGINATION)
    async function handlePage(page: number) {
        control.filter.params.page = page
        control.loading = true
        control.renderMain()
        await control.done()
        control.renderMain()
    }

    async function handleLimit(limit: number) {
        control.filter.params.limit = limit
        control.filter.params.page = 1
        control.loading = true
        control.renderMain()
        await control.done()
        control.renderMain()
    }

    return (
        <Pagination
            onChange={handlePage}
            pages={control.filter.pagination.pages}
            page={control.filter.params.page}
            onLimit={handleLimit}
            limit={control.filter.params.limit}
        />
    )
}
