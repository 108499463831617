import moment from 'moment'
import 'moment/locale/es'
import { useRender } from 'redity'
import Badge from '../../../components/Badge'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { Keys } from '../constants'
import { storagePage, controlList } from '../storage'

export default function Header() {
    useRender(Keys.HEADER)

    const { total_balance, total_quantity, records } = storagePage.header
    const { start_date } = controlList.filter.params
    const symbol = storagePage.currency ? storagePage.currency.symbol : ''

    return (
        <div className="w-full stats mb-4 border-2">
            <div className="stat place-items-center">
                <div className="stat-title">Saldo total</div>
                <div className="stat-value">
                    {symbol} {buildNumberFormat(total_balance)}
                </div>
            </div>
            <div className="stat place-items-center place-content-center">
                <div className="stat-title">Cant. Total</div>
                <div className="stat-value">{total_quantity}</div>
            </div>
            <div className="stat place-items-center place-content-center">
                <div className="stat-title">Registros</div>
                <div className="stat-value">{records}</div>
            </div>
            <div className="stat place-items-center place-content-center">
                <div className="stat-title">Rango de fecha</div>
                <div className="stat-desc">
                    <Badge color="info">
                        {moment(start_date).format('DD/MM/YYYY')}
                    </Badge>
                </div>
            </div>
        </div>
    )
}
