import 'moment/locale/es'
import { useRender } from 'redity'
import Typography from '../../../../components/Typography'
import { TableList } from '../../../../sections/ListForm'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { productsControl, responseSimulate } from '../../storage'
import { getCurrency } from '../../Utils/getCurrency'

export default function TraspaseView() {
    useRender('ContainerTable')
    const currency = getCurrency()
    return (
        <>
            <TableList control={productsControl} scrollHeight={500}>
                {(IProduct, control, e) => (
                    <tr key={e}>
                        <td>{e}</td>
                        <td>
                            <Typography
                                type="paragraph"
                                className={'font-bold'}
                            >
                                {IProduct.product_description}
                            </Typography>
                            <Typography type="small" color="gray">
                                {IProduct.product_code}
                            </Typography>
                        </td>
                        <td>{IProduct.quantity}</td>
                        <td>
                            {currency}{' '}
                            {buildNumberFormat(
                                IProduct.purchase_cost / IProduct.quantity
                            )}
                        </td>
                        <td>
                            {currency}{' '}
                            {buildNumberFormat(
                                IProduct.estimate_cost / IProduct.quantity
                            )}
                        </td>
                        <td>
                            {currency}{' '}
                            {buildNumberFormat(IProduct.purchase_cost)}
                        </td>
                        <td>
                            {currency}{' '}
                            {buildNumberFormat(IProduct.estimate_cost)}
                        </td>
                    </tr>
                )}
            </TableList>
            <div className="grid grid-cols-6 mt-4 font-bold text-xs">
                <div className="col-end-5 bg-gray-200 py-2 px-4 ">TOTAL</div>
                <div className="col-end-6 bg-gray-200 py-2 px-4">
                    {currency}{' '}
                    {buildNumberFormat(responseSimulate.total_purchase_cost)}
                </div>
                <div className="col-end-7 bg-gray-200 py-2 px-4">
                    {currency}{' '}
                    {buildNumberFormat(responseSimulate.total_estimate_cost)}
                </div>
            </div>
        </>
    )
}
