import AsignPermisions from '../../pages/AsignPermisions'
import Roles from '../../pages/Roles'
import RolesUser from '../../pages/RolesUser'
import Users from '../../pages/Users'
import WorkPeriods from '../../pages/WorkPeriods'
import { ItemRoute } from './types'

const Administration: ItemRoute[] = [
    {
        label: 'Roles',
        title: 'Roles',
        cu: 'CU001',
        path: '/roles',
        Component: Roles,
        routes: [
            {
                cu: 'CU004',
                title: 'Configurar permisos',
                path: '/:role_id/permissions',
                Component: AsignPermisions
            }
        ]
    },
    {
        label: 'Usuarios',
        title: 'Usuarios',
        cu: 'CU008',
        path: '/users',
        Component: Users,
        routes: [
            {
                cu: 'CU009',
                title: 'Usuarios',
                path: '/:user_id',
                Component: RolesUser
            }
        ]
    },
    {
        label: 'Periodos de Trabajo',
        title: 'Periodos de Trabajo',
        cu: 'CU131',
        path: '/work_periods',
        Component: WorkPeriods
    }
]

export default Administration
