import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR, textBold } from './utils'

export function getRowFinalCost(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [
        { value: 'Monto a Costear', backgroundColor: COLOR.melon }
    ]

    data.forEach(d => {
        row.push({
            value:
                d.data_summary.international_original_cost +
                d.data_summary.nationalization_original_cost +
                d.data_summary.extra_national_original_cost +
                d.data_summary.extra_international_original_cost +
                d.data_summary.setting_original_cost,
            format: '0.00',
            backgroundColor: COLOR.melon
        })
        row.push({
            value: '',
            backgroundColor: COLOR.melon
        })
        row.push({
            value: '',
            backgroundColor: COLOR.melon
        })
        row.push({
            value:
                d.data_summary.international_local_cost +
                d.data_summary.nationalization_local_cost +
                d.data_summary.extra_national_local_cost +
                d.data_summary.extra_international_local_cost +
                d.data_summary.setting_local_cost,
            format: '0.00',
            backgroundColor: COLOR.melon
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin, textBold])
}
