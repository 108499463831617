function StickyNote(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0H2C.9 0 0 .9 0 2v16c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6zm2 10H4v2h8v-2zm0 4H4v2h8v-2zM2 18h12V7H9V2H2v16z"
            />
        </svg>
    )
}

export default StickyNote
