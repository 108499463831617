import moment from 'moment'
import Icon from '../../../components/Icon'
import ReactPopover from '../../../components/ReactPopover'

export default function DueDate({ due_date }: { due_date: string }) {
    if (due_date === null) return null
    const current_date = moment()
    const dueDateMoment = moment(due_date)
    const dateSubtractMoment = moment(due_date).subtract(60, 'day')

    if (current_date.isAfter(dueDateMoment)) {
        return (
            <ReactPopover
                component={<Icon name="warning" className="text-red-500" />}
            >
                <p>Licencia expirada</p>
            </ReactPopover>
        )
    }

    if (current_date.isAfter(dateSubtractMoment)) {
        return (
            <ReactPopover
                component={<Icon name="warning" className="text-warning" />}
            >
                <p>Licencia por expirar</p>
            </ReactPopover>
        )
    }

    return null
}
