import Typography from '../../../components/Typography'
import useRule from '../../../hooks/useRule'
import { view } from '../../../sections/View'
import { GetWarehouseView } from '../../../types/warehouses'
import WarehouseView from './WarehouseView'

export default function Card({
    image,
    image2,
    title,
    code,
    children,
    warehouse_id,
    is_enabled
}: any) {
    return (
        <div className="card bordered rounded-lg p-4">
            <figure>
                <img
                    src={image}
                    alt={title}
                    className="rounded-lg h-40 object-cover"
                />
            </figure>
            <div className="card-body px-0 pb-0 pt-4">
                <div className="flex items-start justify-between mb-2 flex-1">
                    <div className="mr-4">
                        <Name
                            title={title}
                            is_enabled={is_enabled}
                            warehouse_id={warehouse_id}
                        />
                        <Typography
                            type="paragraph"
                            color="gray"
                            className="font-univers-light"
                        >
                            {code}
                        </Typography>
                    </div>
                    <img src={image2} className="w-8" />
                </div>
                {children}
            </div>
        </div>
    )
}

function Name({
    title,
    is_enabled,
    warehouse_id
}: {
    title: string
    is_enabled: boolean
    warehouse_id: number
}) {
    const math = useRule('CU098')

    return (
        <h2
            {...(math && {
                className: `card-title capitalize font-univers-bold mb-1 text-xl ${
                    is_enabled
                        ? 'underline cursor-pointer'
                        : 'line-through cursor-pointer'
                }`,
                onClick: () => {
                    view<GetWarehouseView>(
                        `/v5.6/apis/warehouses/${warehouse_id}/general_information`,
                        warehouse => ({
                            title: warehouse.name,
                            Component: WarehouseView
                        }),
                        'sm'
                    )
                }
            })}
        >
            {title}
        </h2>
    )
}
