import { parseFile } from '../../../utilities/fileTools'
import {
    storagePage,
    tableCompaniesControl,
    tableFiscalCodeControl,
    tablePermitsControl,
    tableTaxesControl
} from '../storage'
import { BodyAvailability } from '../types'

export default function getBodyAvailability(): BodyAvailability {
    const product_companies = tableCompaniesControl
        .getDataList()
        .filter(company => company.is_enabled)
        .map(company => {
            const fiscalCode = tableFiscalCodeControl
                .getDataList()
                .find(
                    data => data.company_id === company.company_id
                ).fiscal_code

            return {
                company_id: company.company_id,
                can_sell: company.can_sell,
                can_buy: company.can_buy,
                fiscal_code: fiscalCode ? fiscalCode.trim() : null
            }
        })

    const product_countries: BodyAvailability['product_countries'] =
        tablePermitsControl.list.map(permits => {
            const {
                country_id,
                nationalization_permission,
                shipment_permission,
                ministry_health_permission,
                new_files
            } = permits.store()
            const dataTaxs = tableTaxesControl.list.find(
                tax => tax.store().country_id === country_id
            )

            const getProductCountriesFiles = () =>
                storagePage.product.product_countries
                    .filter(pc => pc.country_id === country_id)
                    .map(pc => pc.files)
                    .flat()

            return {
                country_id,
                tax: parseInt(dataTaxs.store().tax) || 0,
                nationalization_permission,
                shipment_permission,
                ministry_health_permission,
                ...(storagePage.mode === 'edition'
                    ? {
                          new_files: new_files
                              .filter(f => !f.id)
                              .map(f => parseFile(f)),
                          files_to_delete: getProductCountriesFiles()
                              .filter(
                                  f => !new_files.find(nf => nf.id === f.id)
                              )
                              .map(f => f.id)
                      }
                    : {
                          new_files: new_files.map(f => parseFile(f))
                      })
            }
        })
    return {
        product_companies,
        product_countries
    }
}
