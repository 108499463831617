import { FormControls } from '../../../../../components/FormUtilities'
import Panel, { PanelBody, PanelHeader } from '../../../../../components/Panel'
import Fields1 from './Fields1'
import Fields2 from './Fields2'

export default function Instruction() {
    return (
        <Panel mode="form">
            <PanelHeader title="Instrucciones de Despacho/Retiro" />
            <PanelBody>
                <FormControls>
                    <Fields1 />
                    <Fields2 />
                </FormControls>
            </PanelBody>
        </Panel>
    )
}
