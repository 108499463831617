import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import { StepValue } from '../../../utilities/ControlTabs'
import { controlTabs, storagePage } from '../storage'
import useStep1Submit from './Step1/useStep1Submit'
import useStep2Submit from './Step2/useStep2Submit'
import useStep3Submit from './Step3/useStep3Submit'

export default function Header({ onCancel }) {
    const cancelText = controlTabs.value === 'step1' ? 'Cancelar' : 'Volver'
    const nextText = controlTabs.value === 'step3' ? 'Guardar' : 'Siguiente'
    const { mode } = storagePage

    const submitStep1 = useStep1Submit()
    const submitStep2 = useStep2Submit()
    const submitStep3 = useStep3Submit()

    const submits: Partial<Record<StepValue, (...props: any) => any>> = {
        step1: submitStep1,
        step2: submitStep2,
        step3: submitStep3
    }

    const handleCancel = () => {
        if (mode === 'edition') {
            onCancel()
            return
        }

        controlTabs.value === 'step1' && onCancel()
        controlTabs.prev()
    }

    return (
        <PageHeader subtitle="(*) Complete todos los campos para el proveedor">
            <Button color="ghost" onClick={handleCancel}>
                {mode === 'creation' ? cancelText : 'Cancelar'}
            </Button>
            {mode === 'creation' && (
                <Button
                    className="ml-2"
                    onClick={() => submits[controlTabs.value]()}
                >
                    {nextText}
                </Button>
            )}
        </PageHeader>
    )
}
