import {
    formInformation,
    reqProviders,
    reqStowageDestiny,
    reqSubStowageDestiny,
    storagePage
} from '../../storage'
import useGet from '../../../../hooks/useGet'
import { Option } from '../../../../config/types/component'
import { DestinationSubStowage } from '../../types'

export default function useStowageDestination() {
    const { props } = formInformation.useSelect('stowage_destination')
    const { data, props: propsSearch } = useGet<DestinationSubStowage[]>(
        reqStowageDestiny.observer(
            `/v5.6/apis/requisitions/stowages?only_first_minimal_stowage=true`,
            true
        ),
        []
    )

    function handleChange(option: Option) {
        if (option.value === '0') {
            formInformation.setProps('sub_stowage_destination', {
                disabled: true
            })
        } else {
            storagePage.stowages_destination = data.result
            formInformation.setProps(
                'sub_stowage_destination',
                { disabled: false },
                false
            )

            const { type_stowage_id, minimal_stowages, type_stowage, id } =
                data.result.find(
                    stowage => stowage.id === parseInt(option.value)
                )
            storagePage.current_type_stowage_id_destination = type_stowage_id
            storagePage.is_inventory = type_stowage.is_inventory

            const isPGR =
                type_stowage_id === 2 || // Pedidos (P)
                type_stowage_id === 11 // Remisionada (R)

            formInformation.setProps(
                'sub_stowage_destination',
                { disabled: !isPGR },
                false
            )

            if (isPGR) {
                reqSubStowageDestiny.setParam('stowage_id', option.value)
                reqSubStowageDestiny.load()
            } else {
                formInformation.setProps(
                    'sub_stowage_destination',
                    {
                        disabled: true
                    },
                    false
                )
                if (type_stowage_id === 6) {
                    storagePage.minimal_stowage_destiny_id = id
                } else {
                    storagePage.minimal_stowage_destiny_id =
                        minimal_stowages[0].id
                }
            }
        }
        reqProviders.deleteQuery('order_number')
        reqProviders.load()
        props.onChange(option)
        formInformation.setValue('sub_stowage_destination', null)
        formInformation.setValue('provider', null)
    }

    return {
        props,
        stowages: data.result.filter(stowage => stowage.is_enabled),
        handleChange,
        propsSearch
    }
}
