import { THeadValue } from './types'
import { render, renders, useRender } from 'redity'
import FormEvents from '../../utilities/FormEvents'
import Control from '../../utilities/Control/Control'
import { Val } from '../../utilities/val/type'
import { CodeError, Exception } from '../../utilities/http/type'

export default class ListFormControl<T> extends Control {
    done(): Promise<boolean> {
        throw new Error('Method not implemented.')
    }

    thead: Array<THeadValue> = []
    indexs = {
        BODY: 'BODY',
        HEAD: 'HEAD',
        SEARCH: 'SEARCH',
        MESSAGE: 'MESSAGE'
    }

    list: FormEvents<T>[] = []
    private message = ''

    constructor(keyRender: string, thead: THeadValue[]) {
        super(keyRender)
        this.thead = thead
    }

    renderMain(): boolean {
        render(this.keyRender, this.indexs.BODY)
        return true
    }

    setList(list: T[]) {
        this.list = []
        let i = 0
        for (const data of list) {
            this.list.push(new FormEvents<T>(`${this.keyRender}-${i}`, data))
            i++
        }
        this.setMessage('')
    }

    getDataList(): T[] {
        const list: T[] = []
        this.list.forEach(d => list.push(d.store()))
        return list
    }

    get(index: number): T {
        return this.list.find((_, i) => index === i)?.store() || null
    }

    push(data: T, toRender = true) {
        this.setList([...this.getDataList(), data])
        if (toRender) {
            this.renderMain()
        }
        this.setMessage('')
    }

    put(index: number, data: Partial<T>) {
        let finded = false
        for (let i = 0; i < this.list.length; i++) {
            const d = this.list[i]
            if (i === index) {
                d.store({ ...d.store(), ...data })
                finded = true
                break
            }
        }
        this.renderMain()
        this.setMessage('')
        return finded
    }

    del(index: number): T {
        const data = this.get(index)
        this.list = this.list.filter((_, i) => i !== index)
        this.setError(null)
        this.renderMain()
        return data || null
    }

    test(
        validFun: (v: Val<T>, form: T, index: number) => void,
        generalMessage?: string
    ): boolean {
        render(this.keyRender, this.indexs.MESSAGE)
        let pass = true
        let i = 0
        for (const data of this.list) {
            const result = data.test((v, f) => validFun(v, f, i))
            if (!result) {
                pass = false
            }
            i++
        }
        if (!pass && generalMessage) {
            this.message = generalMessage
        } else {
            this.message = ''
        }
        render(this.keyRender, this.indexs.MESSAGE)

        return pass
    }

    setMessage(message: string) {
        this.message = message
        render(this.keyRender, this.indexs.MESSAGE)
    }

    useHelpers = () => {
        useRender(this.keyRender, this.indexs.MESSAGE)
        const helpers = this.list.map(d => d.store.helpers())
        const findError = (index: number) => {
            const errors: Record<
                number,
                (
                    codeError?: CodeError,
                    parseMessage?: string,
                    fieldName?: string | keyof T
                ) => string
            > = {}
            this.list.forEach((data, i) => {
                errors[i] = data.errorControl.getError
            })

            return errors[index] || (() => '')
        }

        return {
            message: this.message,
            helpers,
            findError
        }
    }

    setError = (error: Exception) => {
        this.list.forEach(data => {
            data.errorControl.setException(error, false)
            renders(data.keyRender)
        })
        render(this.keyRender, this.indexs.MESSAGE)
    }

    mapping(cbfun: (data: T) => T | null | undefined) {
        this.list.forEach((d, index) => {
            const result = cbfun(d.store())
            if (result) {
                d.store(result)
            } else {
                this.list = this.list.filter((_, i) => i !== index)
            }
        })
    }
}
