import classNames from 'classnames'
import { useContext } from 'react'
import Icon from '../Icon'
import SimpleStepsContext from './contexts'
import { PropsSimpleTabs, PropsTab } from './types'
import './simpleTabs.css'

export default function SimpleTabs({
    children,
    value,
    onStep = () => null,
    className,
    type = 'simple'
}: PropsSimpleTabs) {
    return (
        <SimpleStepsContext.Provider value={{ value, onStep, type }}>
            <div
                className={classNames(
                    {
                        'flex w-full bg-white px-10 pb-5 pt-4 rounded-xl mx-auto border-2 mb-4':
                            type === 'card'
                    },
                    {
                        'flex w-full mt-4': type === 'simple'
                    },
                    className
                )}
            >
                {children}
            </div>
        </SimpleStepsContext.Provider>
    )
}

export function Tab({ children, value, icon }: PropsTab) {
    const { value: currentValue, onStep } = useContext(SimpleStepsContext)
    return (
        <a
            className={classNames(`simple-tab text-sm`, {
                'simple-tab--selected': value === currentValue,
                '': value !== currentValue
            })}
            onClick={() => onStep(value)}
        >
            {icon && <Icon size={18} name={icon} className="mr-1" />} {children}
        </a>
    )
}
