import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import Fields from './Fields'
import Header from './Header'
import Address from './Address'
import Contact from './Contact'
import useInitial from '../useInitial'

export default function Agent() {
    const { pageState } = useInitial()
    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title="Información de agente" />
                    <PanelBody>
                        <Fields />
                    </PanelBody>
                </Panel>
                <Contact />
                <Address />
            </div>
            <Header />
        </Initial>
    )
}
