import Button from '../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import { storagePage } from '../../storage'
import TableCompanies from './TableCompanies'
import Toggles from './Toggles'
import useStep2Submit from './useStep2Submit'

export default function Step2() {
    const submit = useStep2Submit()
    const { mode } = storagePage
    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Compañias habilitadas para compras">
                    {mode === 'edition' && (
                        <Button size="sm" onClick={() => submit()}>
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TableCompanies />
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Configuraciones de proveedor">
                    {mode === 'edition' && (
                        <Button size="sm" onClick={() => submit()}>
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <Toggles />
                </PanelBody>
            </Panel>
        </>
    )
}
