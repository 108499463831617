import { THeadValue } from '../../utilities/Control/ControlList'
import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('WAREHOUSES', [
    'VIEW',
    'INFO',
    'HEADER',
    'FILTER_FORM'
])

export const colorTypeDocumentApproval = {
    1: {
        bullet: 'text-yellow-500',
        badge: 'warning'
    },
    2: {
        bullet: 'text-red-500',
        badge: 'error'
    },
    3: {
        bullet: 'text-green-500',
        badge: 'success'
    },
    4: {
        bullet: 'text-yellow-500',
        badge: 'error'
    }
}

// const estado = ['Estado']
function getTHeads(
    dateLabel: string,
    sortBy: string
    // state = false
): THeadValue[] {
    return [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'requesting_user_name', label: 'Solicitante' },
        { value: 'warehouse_name', label: 'Almacén' },
        'Bodega origen',
        'Bodega destino',
        { value: sortBy, label: dateLabel },
        // ...(state ? estado : []),
        'Acciones'
    ]
}

// export const tHeadsPending: THeadValue[] = getTHeads(
//     'Fecha de solicitud',
//     'request_date'
// )
export const tHeadsApproved: THeadValue[] = getTHeads(
    'Fecha de aprobación',
    'decision_date'
    // true
)
export const tHeadsRejected: THeadValue[] = getTHeads(
    'Fecha de rechazo',
    'decision_date'
)
export const tHeadsCanceled: THeadValue[] = getTHeads(
    'Fecha de cancelación',
    'decision_date'
)
