import { ViewProductPrice } from '../../../types/product_price'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import {
    formSaleDiscounts,
    storagePage,
    tableSaleDiscountsControl
} from '../storage'

export function initialEditionSaleDiscount(productPrice: ViewProductPrice) {
    storagePage.productPrice = productPrice
    formSaleDiscounts.init({
        company: formSaleDiscounts.store().company,
        inventory_barrier: productPrice.inventory_barrier.toString(),
        cost_provider: productPrice.product_provider_company
            ? buildNumberFormat(
                  productPrice.product_provider_company.purchase_price
              )
            : '-',
        symbol: productPrice.product_provider_company
            ? productPrice.product_provider_company.company_currency.currency
                  .symbol
            : ''
    })
    tableSaleDiscountsControl.setList(
        productPrice.sale_discounts.map(sale_discount => ({
            id: sale_discount.id,
            min_quantity: sale_discount.min_quantity.toString(),
            max_quantity: sale_discount.max_quantity.toString(),
            discount: sale_discount.discount.toString()
        }))
    )
}
