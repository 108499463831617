import { useHistory } from 'react-router-dom'
import { Popup } from '../../../../utilities/popup'
import submit from '../../../../utilities/submit'
import { storagePage } from '../../storage'

export default function useSubmit() {
    const { push } = useHistory()
    async function handleSubmit() {
        const { req_products_actived, purchase } = storagePage
        const requisition_products = Object.keys(req_products_actived).map(
            requisition_product_id => ({
                requisition_product_id: parseInt(requisition_product_id),
                quantity_to_buy:
                    req_products_actived[parseInt(requisition_product_id)]
                        .quantity_buy,
                unit_purchase_price:
                    req_products_actived[parseInt(requisition_product_id)]
                        .provider_price
            })
        )
        if (requisition_products.length >= 1) {
            const { error, success } = await submit(
                `/v5.6/purchases/${purchase.id}/purchase_products`,
                {
                    body: {
                        requisition_products
                    },
                    confirm:
                        '¿Está seguro de guardar cambios de productos a comprar?',
                    method: 'put'
                }
            )
            if (error) {
                Popup.error(
                    'Algo salió mal...',
                    'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
                )
            }

            if (success) {
                push('/purchase_orders')
            }
        } else {
            Popup.error(
                'Orden incompleta',
                'Debe seleccionar por lo menos un producto'
            )
        }
    }

    return handleSubmit
}
