import { FormControls } from '../../../components/FormUtilities'
import Toggle from '../../../components/Toggle'
import Typography from '../../../components/Typography'
import { ModuleForm } from '../storage'
import Check from './Check'
interface PropsContentModule {
    control: ModuleForm
}

export default function ContentModule({ control }: PropsContentModule) {
    const { props } = control.groupPermision.useCheck('active')
    function handleChange(ev) {
        props.onChange(ev)
        if (!control.groupPermision.store().active) {
            control.permissions.forEach(per => {
                per.store.set('active', false)
            })
        }
    }

    return (
        <div className="mb-4">
            <div className="flex items-center mb-4">
                <Typography type="header-3" className="mr-3">
                    {control.groupPermision.store().name}
                </Typography>
                <Toggle {...props} onChange={handleChange} />
            </div>
            <FormControls>
                {control.permissions.map((permision, i) => (
                    <Check
                        key={i}
                        control={permision}
                        disabled={!control.groupPermision.store().active}
                    />
                ))}
            </FormControls>
        </div>
    )
}
