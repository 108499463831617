export default function IconError(props) {
    return (
        <svg
            width={150}
            height={150}
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#prefix__clip0)">
                <path
                    d="M72.703 83.215h-58.64a4.688 4.688 0 00-4.688 4.687v46.852h67.922V87.903a4.682 4.682 0 00-4.594-4.688z"
                    fill="#fff"
                />
                <path
                    d="M2.344 134.754H84.328v2.344a4.7 4.7 0 01-4.687 4.687H7.03a4.7 4.7 0 01-4.687-4.687v-2.344zM49.219 106.629H18.75v-7.031h23.438l7.03 7.031z"
                    fill="#C6C6C6"
                />
                <path
                    d="M67.969 99.598v7.031h-18.75l-7.032-7.031H67.97zM103.125 97.277c6.492 0 11.719 5.25 11.719 11.719 0 6.469-5.227 11.719-11.719 11.719-6.469 0-11.719-5.25-11.719-11.719v-.023c.024-6.469 5.25-11.696 11.719-11.696zM63.281 44.52c0 8.414 6.844 15.234 15.235 15.234h50.39c10.36 0 18.75-8.39 18.75-18.727v9.375c0 10.336-8.39 18.75-18.75 18.75H78.515c-8.39 0-15.234-6.82-15.234-15.234V44.52z"
                    fill="#C6C6C6"
                />
                <path
                    d="M128.906 22.277c10.36 0 18.75 8.391 18.75 18.75 0 10.336-8.39 18.727-18.75 18.727h-50.39c-8.391 0-15.235-6.82-15.235-15.234 0-8.391 6.844-15.211 15.235-15.211h1.593c2.204-12 12.73-21.094 25.36-21.094 10.031 0 18.703 5.719 22.969 14.062h.468z"
                    fill="#fff"
                />
                <path
                    d="M128.909 62.117h-50.39a2.342 2.342 0 01-2.344-2.344 2.342 2.342 0 012.344-2.343h50.39a2.342 2.342 0 012.344 2.343 2.342 2.342 0 01-2.344 2.344zM131.253 36.345A2.342 2.342 0 01128.909 34c0-12.923-10.514-23.438-23.437-23.438-12.924 0-23.438 10.514-23.438 23.438a2.342 2.342 0 01-2.344 2.344A2.342 2.342 0 0177.347 34c0-15.509 12.616-28.125 28.125-28.125 15.508 0 28.125 12.616 28.125 28.125a2.342 2.342 0 01-2.344 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M128.909 62.117a2.342 2.342 0 01-2.344-2.344 2.342 2.342 0 012.344-2.344c9.045 0 16.406-7.357 16.406-16.4 0-9.044-7.361-16.403-16.406-16.403a2.342 2.342 0 01-2.344-2.344 2.342 2.342 0 012.344-2.343c11.632 0 21.094 9.461 21.094 21.09 0 11.628-9.462 21.088-21.094 21.088zM78.519 62.117c-9.694 0-17.579-7.885-17.579-17.576 0-9.69 7.885-17.571 17.579-17.571a2.342 2.342 0 012.343 2.344 2.342 2.342 0 01-2.343 2.343c-7.107 0-12.891 5.78-12.891 12.884 0 7.106 5.784 12.888 12.89 12.888a2.342 2.342 0 012.344 2.344 2.342 2.342 0 01-2.343 2.344zM128.909 71.501h-50.39a2.342 2.342 0 01-2.344-2.344 2.342 2.342 0 012.344-2.343h50.39a2.342 2.342 0 012.344 2.343 2.342 2.342 0 01-2.344 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M128.909 71.501a2.342 2.342 0 01-2.344-2.344 2.342 2.342 0 012.344-2.343c9.045 0 16.406-7.36 16.406-16.402a2.342 2.342 0 012.344-2.344 2.342 2.342 0 012.344 2.344c0 11.63-9.462 21.09-21.094 21.09zM78.519 71.502c-9.694 0-17.579-7.885-17.579-17.576a2.342 2.342 0 012.344-2.344 2.342 2.342 0 012.344 2.344c0 7.106 5.784 12.888 12.89 12.888a2.342 2.342 0 012.344 2.344 2.342 2.342 0 01-2.343 2.344zM80.117 31.657h-1.598a2.342 2.342 0 01-2.344-2.343 2.342 2.342 0 012.344-2.344h1.598a2.343 2.343 0 110 4.688zM128.909 24.626h-.467a2.341 2.341 0 01-2.343-2.344 2.341 2.341 0 012.343-2.343h.467a2.342 2.342 0 012.344 2.343 2.342 2.342 0 01-2.344 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M63.284 56.27a2.342 2.342 0 01-2.344-2.345v-9.384a2.342 2.342 0 012.344-2.344 2.342 2.342 0 012.344 2.344v9.384a2.342 2.342 0 01-2.344 2.344zM147.659 52.755a2.342 2.342 0 01-2.344-2.344V41.03a2.342 2.342 0 012.344-2.344 2.342 2.342 0 012.344 2.344v9.382a2.342 2.342 0 01-2.344 2.344zM7.034 144.134c-3.876 0-7.031-3.155-7.031-7.031a2.341 2.341 0 012.344-2.344 2.341 2.341 0 012.343 2.344 2.344 2.344 0 002.344 2.343 2.342 2.342 0 012.344 2.344 2.342 2.342 0 01-2.344 2.344zM9.373 90.258a2.342 2.342 0 01-2.344-2.344c0-3.877 3.155-7.031 7.031-7.031a2.342 2.342 0 012.344 2.344 2.342 2.342 0 01-2.344 2.343 2.345 2.345 0 00-2.343 2.344 2.342 2.342 0 01-2.344 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M2.347 139.446a2.342 2.342 0 01-2.344-2.344v-2.336a2.342 2.342 0 012.344-2.344 2.342 2.342 0 012.343 2.344v2.336a2.342 2.342 0 01-2.343 2.344zM79.627 144.134a2.342 2.342 0 01-2.344-2.344 2.342 2.342 0 012.344-2.344 2.344 2.344 0 002.344-2.343 2.341 2.341 0 012.343-2.344 2.341 2.341 0 012.344 2.344c0 3.876-3.154 7.031-7.031 7.031z"
                    fill="#151A6A"
                />
                <path
                    d="M84.314 139.446a2.342 2.342 0 01-2.343-2.344v-2.336a2.342 2.342 0 012.343-2.344 2.342 2.342 0 012.344 2.344v2.336a2.343 2.343 0 01-2.344 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M84.315 137.109H2.347a2.341 2.341 0 01-2.344-2.343 2.342 2.342 0 012.344-2.344h81.968a2.343 2.343 0 110 4.687zM79.627 144.134H7.034a2.342 2.342 0 01-2.344-2.344 2.342 2.342 0 012.344-2.344h72.593a2.343 2.343 0 110 4.688z"
                    fill="#151A6A"
                />
                <path
                    d="M9.373 137.1a2.342 2.342 0 01-2.344-2.344V87.914a2.342 2.342 0 012.344-2.344 2.342 2.342 0 012.344 2.344v46.842a2.342 2.342 0 01-2.344 2.344zM77.288 90.258a2.342 2.342 0 01-2.344-2.344 2.345 2.345 0 00-2.343-2.344 2.342 2.342 0 01-2.344-2.343 2.342 2.342 0 012.344-2.344c3.876 0 7.03 3.154 7.03 7.031a2.343 2.343 0 01-2.343 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M77.288 137.1a2.342 2.342 0 01-2.344-2.344V87.914a2.342 2.342 0 012.344-2.344 2.342 2.342 0 012.344 2.344v46.842a2.343 2.343 0 01-2.344 2.344zM72.697 85.57H14.06a2.342 2.342 0 01-2.343-2.343 2.342 2.342 0 012.343-2.344h58.636a2.343 2.343 0 110 4.688zM67.967 108.989H18.748a2.341 2.341 0 01-2.344-2.343v-7.032a2.342 2.342 0 012.344-2.344h49.219a2.342 2.342 0 012.344 2.344v7.032a2.341 2.341 0 01-2.344 2.343zm-46.875-4.687h44.531v-2.344H21.092v2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M49.216 108.989c-.6 0-1.2-.23-1.657-.687l-7.03-7.031a2.341 2.341 0 010-3.314 2.341 2.341 0 013.313 0l7.031 7.031a2.341 2.341 0 010 3.314 2.338 2.338 0 01-1.657.687zM60.93 118.367H25.786a2.341 2.341 0 01-2.344-2.344 2.341 2.341 0 012.344-2.343H60.93a2.341 2.341 0 012.344 2.343 2.341 2.341 0 01-2.344 2.344zM51.56 125.398H35.155a2.341 2.341 0 01-2.344-2.343 2.342 2.342 0 012.344-2.344h16.407a2.342 2.342 0 012.343 2.344 2.341 2.341 0 01-2.343 2.343zM103.123 99.603a2.342 2.342 0 01-2.344-2.344v-32.79a2.342 2.342 0 012.344-2.343 2.342 2.342 0 012.344 2.344v32.789a2.342 2.342 0 01-2.344 2.344zM91.393 111.33H77.286a2.342 2.342 0 01-2.344-2.344 2.341 2.341 0 012.344-2.343h14.107a2.341 2.341 0 012.344 2.343 2.342 2.342 0 01-2.344 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M103.128 123.066c-7.755 0-14.063-6.307-14.063-14.062 0-7.756 6.308-14.063 14.063-14.063 7.755 0 14.062 6.307 14.062 14.063 0 7.755-6.307 14.062-14.062 14.062zm0-23.437c-5.17 0-9.375 4.205-9.375 9.375s4.205 9.375 9.375 9.375 9.375-4.205 9.375-9.375-4.205-9.375-9.375-9.375z"
                    fill="#151A6A"
                />
                <path
                    d="M103.128 111.347a2.342 2.342 0 01-2.344-2.344v-4.69a2.341 2.341 0 012.344-2.343 2.341 2.341 0 012.344 2.343v4.69a2.342 2.342 0 01-2.344 2.344z"
                    fill="#151A6A"
                />
                <path
                    d="M103.198 116.032a2.35 2.35 0 01-2.355-2.344 2.33 2.33 0 012.329-2.343h.026a2.343 2.343 0 110 4.687z"
                    fill="#151A6A"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" d="M0 0h150v150H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
