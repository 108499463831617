import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './daisyui.css'
import './App.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter as Router } from 'react-router-dom'
import { PUBLIC_URL } from './config/credentials'

ReactDOM.render(
    <React.StrictMode>
        <Router basename={PUBLIC_URL}>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
