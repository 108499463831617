import { FormControl } from '../../../components/FormUtilities'
import Textarea from '../../../components/Textarea'
import Upload from '../../../components/Upload'
import { formLicense } from '../storage'

function Files() {
    const { props, getError } = formLicense.useUploads('files')
    const helper = getError(
        'ERROR-XXXXXXX',
        'Este error es desconocido 👀',
        'files.0.size'
    )

    return (
        <FormControl label="Archivos" col="span-6" required>
            <Upload {...props} helper={helper || props.helper} />
        </FormControl>
    )
}

function Description() {
    const { props, getError } = formLicense.useInput('description')
    const helper = getError('ERROR-XXXXXXX')
    return (
        <FormControl label="Descripción" col="span-6" required>
            <Textarea
                {...props}
                helper={helper || props.helper}
                placeholder="Descripción de la licencia"
            />
        </FormControl>
    )
}

export default function Fields3() {
    return (
        <>
            <Files />
            <Description />
        </>
    )
}
