import { GetProviderCatalog } from '../../types/provider_catalog'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { FormImportCatalog, FormProductCatalog } from './types'

export const providerList = new ControlList<GetProviderCatalog>(Keys.MAIN, {
    path: '/v5.6/apis/product_provider_companies',
    theads: [
        '#',
        {
            label: 'Código de producto',
            value: 'product_code'
        },
        {
            label: 'Descripción de producto',
            value: 'product_name'
        },
        {
            label: 'Código (proveedor)',
            value: 'provider_code'
        },
        {
            label: 'Compañía compradora',
            value: 'company_name'
        },
        'Precio de compra',
        {
            label: 'Estado',
            value: 'is_enabled'
        },
        'Acciones'
    ]
})

export const formCatalog = new FormEvents<FormProductCatalog>(
    Keys.FORM_CATALOG,
    {
        company: null,
        product: null,
        currency: null,
        purchase_price: null,
        provider_code: '',
        provider_description: ''
    }
)

formCatalog.setParams('product', {
    limit: 10
})

export const formImport = new FormEvents<FormImportCatalog>(Keys.FORM_IMPORT, {
    company: null,
    currency: null,
    excelFile: null,
    dataExcel: []
})
