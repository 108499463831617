import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { GetPurchase } from '../../../../types/purchase'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header1: {
        width: '5%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header2: {
        width: '15%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header3: {
        width: '35%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header4: {
        width: '20%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    item1: {
        width: '5%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    item2: {
        width: '15%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    item3: {
        width: '35%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    item4: {
        width: '20%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    caption: {
        width: '100%',
        fontSize: '10px',
        padding: '5px',
        textAlign: 'center'
    }
})

export default function Table2({ data }: { data: GetPurchase }) {
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.header1}>R</Text>
                <Text style={styles.header2}>Item/Cod.</Text>
                <Text style={styles.header2}>Cant./Qty</Text>
                <Text style={styles.header3}>Descripción/Description</Text>
                <Text style={styles.header4}>Costo/Cost</Text>
                <Text style={styles.header2}>Total</Text>
            </View>
            {data.purchase_products
                .filter(x => x.is_enabled)
                .map((item, i) => (
                    <View style={styles.row} key={i}>
                        <Text style={styles.item1}>{i + 1}</Text>
                        <Text style={styles.item2}>
                            {
                                item.requisition_product
                                    .product_provider_company.provider_code
                            }
                        </Text>
                        <Text style={styles.item2}>{item.quantity}</Text>
                        <Text style={styles.item3}>
                            {
                                item.requisition_product
                                    .product_provider_company
                                    .provider_description
                            }
                        </Text>
                        <Text style={styles.item4}>
                            {buildNumberFormat(item.unit_purchase_price)}
                        </Text>
                        <Text style={styles.item2}>
                            {buildNumberFormat(
                                item.unit_purchase_price * item.quantity
                            )}
                        </Text>
                    </View>
                ))}
            {data.purchase_products.length === 0 && (
                <View style={styles.row}>
                    <Text style={styles.caption}>
                        No se encontraron resultados
                    </Text>
                </View>
            )}
        </>
    )
}
