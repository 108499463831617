import { render } from 'redity'
import { post } from '../../../utilities/http'
import { formInformation, productsControl, responseSimulate } from '../storage'
import { GETEstimateCostProducts } from '../types'

export async function loadProducts() {
    const reception_id = formInformation.store().purchaseReceipt?.value
    const estimate_cost = parseInt(formInformation.store().estimatedAmount) || 0
    const response = await post<GETEstimateCostProducts>(
        `/v5.6/apis/receptions/${reception_id}/products/simulate_estimate_costs`,
        {
            estimate_cost: estimate_cost
        }
    )
    if (!response.error) {
        responseSimulate.product_estimate_response =
            response.data.result.product_estimate_response
        responseSimulate.total_estimate_cost =
            response.data.result.total_estimate_cost
        responseSimulate.total_purchase_cost =
            response.data.result.total_purchase_cost

        productsControl.setList(response.data.result.product_estimate_response)
        productsControl.renderMain()
        render('ContainerTable')
    }
}
