import { useEffect, ReactNode } from 'react'
import DataTable from '../../components/DataTable'
import ControlList from '../../utilities/Control/ControlList'
import Badges from './Badges'
import Body from './Body'
import Enumeration from './Enumeration'
import Head from './Head'

export interface PropsList<T> {
    control: ControlList<T>
    /**
     * @deprecated use prop tbody
     */
    children?: (data: T, enumeration: number) => ReactNode
    enumeration?: boolean
    scrollHeight?: number
    type?: 'table' | 'cards'
    head?: (response: any) => ReactNode
    tbody?: (data: T, enumeration: number) => ReactNode
    dontResetFilterWhenExit?: boolean
    theadWhiteSpace?:
        | 'normal'
        | 'pre'
        | 'nowrap'
        | 'pre-wrap'
        | 'pre-line'
        | 'break-spaces'
}

export default function List<T>({
    control,
    children,
    enumeration = true,
    scrollHeight = null,
    type = 'table',
    head = () => <></>,
    tbody,
    dontResetFilterWhenExit = false,
    theadWhiteSpace = 'nowrap'
}: PropsList<T>) {
    if (!enumeration) {
        control.filter.params.limit = 1000
    }
    useEffect(() => {
        return () => {
            if (!dontResetFilterWhenExit) {
                control.filter.restart()
            }
        }
    }, [])
    return (
        <>
            {type === 'table' && (
                <>
                    <Badges control={control} />
                    <DataTable scrollHeight={scrollHeight}>
                        <Head
                            control={control}
                            theadWhiteSpace={theadWhiteSpace}
                        />
                        <Body head={head} control={control} type="table">
                            {(data, i) =>
                                tbody ? tbody(data, i) : children(data, i)
                            }
                        </Body>
                    </DataTable>
                </>
            )}
            {type === 'cards' && (
                <Body control={control} type="cards">
                    {(data, i) => children(data, i)}
                </Body>
            )}
            {enumeration && <Enumeration control={control} />}
        </>
    )
}
