import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowAgent(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [{ value: 'Agente Aduanal' }]
    data.forEach(d => {
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'center',
            value: d.agent ? d.agent.name : ''
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
