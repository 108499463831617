import BtnActionTable from '../../../../components/BtnActionTable'
import NumeralField from '../../../../components/NumeralField'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import storage, { formPresentation, tablePresentations } from '../../storage'
import { TablePresentationData } from '../../types'
import setParamsPresentations from '../../utilities/setParamsPresentations'

function Amount({ control }: { control: FormEvents<TablePresentationData> }) {
    const { props } = control.useInput('amount')
    function handleChange(ev: any) {
        props.onChange(ev)
        const data = control.store()
        storage.products.update(data.id, data)
    }

    return (
        <NumeralField
            {...props}
            fullWidth={false}
            className="w-20"
            max={999999}
            size="sm"
            onChange={handleChange}
        />
    )
}

export default function TableProducts() {
    function handleRemove(product: TablePresentationData) {
        formPresentation.setValue(
            'presentationProducts',
            formPresentation
                .store()
                .presentationProducts.filter(
                    pp => parseInt(pp.value) !== product.id
                )
        )
        setParamsPresentations()
    }

    return (
        <div style={{ height: '250px' }}>
            <TableList
                control={tablePresentations}
                className="mt-6"
                scrollHeight={250}
            >
                {(data, control, i) => (
                    <tr key={i}>
                        <td>
                            <p className="-mb-1">{data.description}</p>
                            <span className="text-2xs">{data.code}</span>
                        </td>
                        <td>
                            <Amount control={control} />
                        </td>
                        <td>
                            <div className="group-btn-options">
                                <BtnActionTable
                                    title="Eliminar"
                                    iconName="bs-trash"
                                    onClick={() => handleRemove(data)}
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </TableList>
        </div>
    )
}
