import List from '../../../sections/List'
import { productPriceList } from '../storage'
import { view } from '../../../sections/View'
import { ViewProductPrice } from '../../../types/product_price'
import useRule from '../../../hooks/useRule'
import ModalInfo from './ModalInfo'
import { GetProductPrices } from '../../../types/prices'
import { useHistory } from 'react-router'
import Rules from '../../../components/Rules'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import buildCompanyName from '../../../utilities/buildCompanyName'
import BtnActionTable from '../../../components/BtnActionTable'

function Code({ productPrice }: { productPrice: GetProductPrices }) {
    const math = useRule('CU093')

    function handleClick() {
        if (math) {
            view<ViewProductPrice>(
                `/v5.6/apis/product_companies/${productPrice.id}/sale_prices_and_factors`,
                () => ({
                    title: 'DETALLE DE PRECIO',
                    Component: ModalInfo
                })
            )
        }
    }

    return (
        <div onClick={handleClick}>
            <b className={math && 'cursor-pointer underline'}>
                {productPrice.product_country.product.code}
            </b>
        </div>
    )
}

export default function TableProductPrices() {
    const { push } = useHistory()

    return (
        <List control={productPriceList}>
            {(productPrice, e) => (
                <tr key={productPrice.id}>
                    <td>{e}</td>
                    <td className="max-w-md">
                        <Code productPrice={productPrice} />
                    </td>
                    <td className="max-w-md" style={{ whiteSpace: 'normal' }}>
                        {productPrice.product_country.product.description}
                    </td>
                    <td>
                        <div className="flex items-center">
                            <img
                                src={productPrice.company.country.image_url}
                                className="mr-2 w-8"
                            />
                            <p
                                className={`${
                                    !productPrice.company.is_enabled &&
                                    'line-through'
                                }`}
                            >
                                {buildCompanyName(productPrice.company.name)}
                            </p>
                        </div>
                    </td>
                    <td>
                        {productPrice.product_provider_company?.sale_price
                            ? `${
                                  productPrice.product_provider_company
                                      .company_currency.currency.symbol
                              } ${buildNumberFormat(
                                  productPrice.product_provider_company
                                      .sale_price
                              )}`
                            : '-'}
                    </td>
                    <td>{productPrice.can_sell ? 'Sí' : 'No'}</td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU092">
                                <BtnActionTable
                                    title="Editar"
                                    iconName="bs-pencil"
                                    onClick={() =>
                                        push(
                                            `/prices/products/${productPrice.id}/edition`
                                        )
                                    }
                                />
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
