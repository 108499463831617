import NumeralField from '../../../../components/NumeralField'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { tableTaxesControl } from '../../storage'
import { TableFormTax } from '../../types'

function Taxes({ control }: { control: FormEvents<TableFormTax> }) {
    const { props } = control.useInput('tax')
    return (
        <div className="flex items-center">
            <NumeralField
                {...props}
                size="sm"
                fullWidth={false}
                className="w-14"
                placeholder="-"
                max={100}
            />
            <b className="ml-2 text-accent">%</b>
        </div>
    )
}

export default function TableTaxes() {
    return (
        <TableList control={tableTaxesControl}>
            {(data, control, e) => (
                <tr key={e}>
                    <td>
                        <div className="flex items-center">
                            <img src={data.image} className="w-7 mr-2" />
                            {data.country_name}
                        </div>
                    </td>
                    <td>
                        <Taxes control={control} />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
