import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { render, useRender } from 'redity'
import { AlertType } from '../Alert/types'
import BlockAlert from '../BlockAlert'

interface ControlBlockAlertState {
    open: boolean
    message: string
    type: AlertType
}

const KEY = 'CONTROL_BLOCK_ALERT'

const states: ControlBlockAlertState = {
    open: false,
    message: '',
    type: 'info'
}

export function showBlockAlert(message: string, type?: AlertType) {
    states.open = true
    states.type = type
    states.message = message
    render(KEY)
}

function ControlBlockAlert() {
    const { pathname } = useLocation()
    const r = useRender(KEY)

    useEffect(() => {
        if (states.open) {
            states.open = false
            r()
        }
    }, [pathname])

    function handleClose() {
        states.open = false
        r()
    }

    return (
        <BlockAlert type={states.type} onClose={handleClose} open={states.open}>
            {states.message}
        </BlockAlert>
    )
}

export default memo(ControlBlockAlert)
