import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowReceptions(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [
        { value: 'RC', fontWeight: 'bold', backgroundColor: COLOR.blue }
    ]
    data.forEach(d => {
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'center',
            fontWeight: 'bold',
            value: d.logistical_declaration_receptions
                .map(ldr => ldr.reception.code)
                .join('/'),
            backgroundColor: COLOR.blue
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
