import Ul from '../../../components/Ul'
import { GetStowageView } from '../../../types/stowages'
import buildNumberFormat from '../../../utilities/buildNumberFormat'

export default function WarehouseView({ data }: { data: GetStowageView }) {
    return (
        <>
            <Ul className="mb-4">
                <li className="list-outside	">
                    Bodega: <b className="ml-2">{data.name}</b>
                </li>
                <li className="list-outside	">
                    Tipo de bodega: <b>{data.type_stowage.name}</b>
                </li>
                <li className="list-outside	">
                    Tipo Inventario:{' '}
                    <b>{data.type_stowage.is_inventory ? 'Sí' : 'No'}</b>
                </li>
                {data.type_stowage.id === 7 && (
                    <li className="list-outside	">
                        Cliente: <b>{data.client ? data.client.name : ' -'}</b>
                    </li>
                )}
                <li className="list-outside	">
                    Valor de bodega:{' '}
                    <b>
                        {data.currency.symbol}{' '}
                        {buildNumberFormat(data.stowage_value)}
                    </b>
                </li>
                <li className="list-outside	">
                    Monto de inventario máximo:{' '}
                    <b>{buildNumberFormat(data.max_amount || 0) || '-'}</b>
                </li>
                {[7, 9, 10].includes(data.type_stowage.id) && (
                    <li className="list-outside	">
                        Estado:{' '}
                        <b>{data.is_enabled ? 'Activo' : 'Desactivado'}</b>
                    </li>
                )}
            </Ul>
        </>
    )
}
