import { FormControl } from '../../../../components/FormUtilities'
import Textarea from '../../../../components/Textarea'
import { formInformation } from '../../storage'

export default function Fields2() {
    const { props } = formInformation.useInput('comment')
    return (
        <FormControl label="Comentario" col="span-6">
            <Textarea {...props} placeholder="Ingresar comentario " />
        </FormControl>
    )
}
