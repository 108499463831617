import { useEffect } from 'react'
import { render } from 'redity'
import { get } from '../http'
import Base from './Base'
import { merge, parse } from 'fter'

interface StatesFields {
    loading: boolean
    search: string
    runSearch: () => void
}

interface StorageFields<T, D> {
    props: Map<keyof T, any>
    params: Map<keyof T, any>
    searcher: Map<keyof T, boolean>
    alias: Map<keyof T, string[]>
    states: Map<keyof T, StatesFields>
    data: Map<keyof T, D>
}

export default class ManageFields<T> {
    base: Base<T>

    protected initializeManage(base: Base<T>) {
        this.base = base
    }

    private storage: StorageFields<T, any[]> = {
        props: new Map(),
        params: new Map(),
        alias: new Map(),
        states: new Map(),
        searcher: new Map(),
        data: new Map()
    }

    protected propsFields(fieldName: keyof T) {
        return this.storage.props.get(fieldName) || {}
    }

    clearProps() {
        this.storage.props.clear()
    }

    setProps(
        fieldName: keyof T,
        props:
            | Record<string, any>
            | ((currentProps: Record<string, any>) => Record<string, any>),
        toRender = true
    ) {
        const currentProps = this.storage.props.get(fieldName) || {}
        const nextProps =
            typeof props === 'function' ? props(currentProps) : props
        this.storage.props.set(fieldName, nextProps)
        this.base.store.helper(fieldName, '') // 👀
        this.base.errorControl.clearError(fieldName)
        if (toRender) {
            render(this.base.keyRender, fieldName as string)
        }
    }

    getProps(fieldName: keyof T): Record<string, any> {
        return this.storage.props.get(fieldName) || {}
    }

    protected useSearcher<D>(
        fieldName: keyof T,
        pathRequest: (() => string) | string
    ) {
        if (!this.storage.states.has(fieldName)) {
            this.storage.states.set(fieldName, {
                loading: false,
                search: '',
                runSearch: () => null
            })
        }

        const states = this.storage.states.get(fieldName)
        const searcher = this.storage.searcher.get(fieldName) || false
        const data: D[] = this.storage.data.get(fieldName) || []

        const getData = async (search = '') => {
            this.storage.states.set(fieldName, {
                ...states,
                loading: true,
                search
            })
            render(this.base.keyRender, fieldName as string)
            const params = parse({
                ...(search && { search }),
                ...(this.storage.params.get(fieldName) || {})
            })
            const path =
                typeof pathRequest === 'function' ? pathRequest() : pathRequest
            const resp = await get<Array<D>>(merge(path, params))
            if (resp.error) return
            this.storage.states.set(fieldName, {
                ...this.storage.states.get(fieldName),
                loading: false
            })
            this.storage.data.set(fieldName, resp.data.result)
            render(this.base.keyRender, fieldName as string)
        }

        this.storage.states.set(fieldName, {
            ...states,
            runSearch: getData
        })

        useEffect(() => {
            if (
                (typeof pathRequest === 'function' && searcher) ||
                (typeof pathRequest === 'string' && pathRequest)
            )
                getData()

            return () => {
                this.storage.states.delete(fieldName)
            }
        }, [])

        const onSearch = (ev: any) => {
            const val = ev.target.value
            getData(val)
        }

        return {
            loading: states.loading,
            search: states.search,
            onSearch,
            data
        }
    }

    /**
     * @deprecated use ControlRequest con useGet
     */
    setParams(
        fieldName: keyof T,
        params:
            | Record<string, any>
            | ((currentProps: Record<string, any>) => Record<string, any>),
        runSearch = true
    ) {
        const currentParams = this.storage.params.get(fieldName) || {}
        const nextParams =
            typeof params === 'function' ? params(currentParams) : params
        this.storage.params.set(fieldName, nextParams)
        this.storage.searcher.set(fieldName, runSearch)
        if (runSearch) {
            return this.runSearch(fieldName)
        }
        return false
    }

    /**
     * @deprecated use ControlRequest con useGet
     */
    runSearch = (fieldName: keyof T): boolean => {
        if (this.storage.states.has(fieldName)) {
            const states = this.storage.states.get(fieldName)
            states.runSearch()
            return true
        }
        return false
    }

    /**
     * @deprecated
     */
    getData<D>(fieldName: keyof T): Array<D> {
        return this.storage.data.get(fieldName) || []
    }
}
