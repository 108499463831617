import Button from '../../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../../components/Panel'
import useGet from '../../../../../hooks/useGet'
import { storagePage } from '../../../storage'
import { ResponseLDR, ResponseLogisticalCost } from '../../../types'
import ModalGeneralCost from './ModalGeneralCost'
import TableSettings from './TableSettings'
import TotalCost from './TotalCost'

export default function Settings() {
    const { logistical_declaration, version } = storagePage
    const { data: dataRLDR, reload: reloadDataRLDR } = useGet<ResponseLDR[]>(
        `/v5.6/apis/logistical_declarations/${storagePage.logistical_declaration.id}/receptions?version=${storagePage.version}`,
        []
    )

    const { data, reload: reloadDataLC } = useGet<ResponseLogisticalCost[]>(
        `5>/v5.6/apis/logistical_declarations/${logistical_declaration.id}/logistical_costs?type_logistical_cost_source_id=5&version=${version}&sort=logistical_concept_id`,
        []
    )

    function reloadHandler() {
        reloadDataRLDR()
        reloadDataLC()
    }

    return (
        <Panel mode="form">
            <PanelHeader title="Costos de ajustes">
                <ModalGeneralCost
                    element={click => (
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            onClick={click}
                        >
                            + Costo General
                        </Button>
                    )}
                    onDone={reloadHandler}
                    rcs={dataRLDR.result}
                />
            </PanelHeader>
            <PanelBody>
                <TableSettings
                    logisticalCost={data.result}
                    onReload={reloadHandler}
                    rcs={dataRLDR.result}
                />
                <TotalCost logisticalDRs={dataRLDR.result} />
            </PanelBody>
        </Panel>
    )
}
