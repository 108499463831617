import InputField from '../../../../components/InputField'
import { TableList } from '../../../../sections/ListForm'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import FormEvents from '../../../../utilities/FormEvents'
import { tableFiscalCodeControl } from '../../storage'
import { TableFormFiscalCode } from '../../types'

function FiscalCode({
    control,
    disabled
}: {
    control: FormEvents<TableFormFiscalCode>
    disabled: boolean
}) {
    const { props } = control.useInput('fiscal_code')
    return (
        <InputField
            {...props}
            size="sm w-full"
            maxLength={180}
            disabled={disabled}
        />
    )
}

export default function TableTaxes() {
    return (
        <TableList control={tableFiscalCodeControl}>
            {(data, control, e) => (
                <tr key={e}>
                    <td>
                        <img src={data.country_image} className="w-7" />
                    </td>
                    <td className={`${!data.is_enabled && 'line-through'}`}>
                        {buildCompanyName(data.company_name)}
                    </td>
                    <td>
                        <FiscalCode
                            disabled={!data.is_enabled}
                            control={control}
                        />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
