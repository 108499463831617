import Icon from '../Icon'
import './uploadField.css'
import classNames from 'classnames'
import { UploadFieldProps } from './types'
import Item from './Item'
import useFiles from '../../hooks/useFiles'

export default function UploadField({
    files = [],
    onChange = () => null,
    maxSizeFile = 5000000,
    extensions = [
        'doc',
        'docx',
        'jpeg',
        'jpg',
        'pdf',
        'png',
        'PNG',
        'JPG',
        'xlsx',
        'XLSX'
    ],
    disabled = false,
    className = '',
    multiple = true,
    placeholder = 'Arrastra o elige tu archivo'
}: UploadFieldProps) {
    const { refInput, handleChange, handleDelete, filesData } = useFiles({
        files,
        onChange,
        maxSizeFile,
        extensions
    })

    return (
        <div
            className={classNames(
                'upload-field relative',
                {
                    'has-files': filesData.length > 0,
                    disabled
                },
                className
            )}
        >
            {filesData.length === 0 && (
                <Empty onChange={handleChange} disabled={disabled}>
                    {placeholder}
                </Empty>
            )}
            {filesData.length > 0 && (
                <>
                    {filesData.map((file, i) => (
                        <Item
                            key={i}
                            name={file.name}
                            driveId={file.drive_id}
                            onDelete={() => handleDelete(i)}
                            disabled={disabled}
                        />
                    ))}
                    {!disabled && (
                        <div className="border-2 border-dashed rounded-full flex items-center justify-center h-9 w-9 relative">
                            <Icon
                                name="more"
                                size={16}
                                className="text-gray-300"
                            />
                            <input
                                type="file"
                                multiple={multiple}
                                ref={refInput}
                                onChange={handleChange}
                                className="absolute top-0 left-0 w-full h-full opacity-0"
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

function Empty({ onChange, disabled = false, children }) {
    return (
        <>
            <Icon
                name="cloud-upload"
                className={`text-accent mr-2 transition ${
                    disabled && 'opacity-40'
                }`}
                size={24}
            />
            <p
                className={`font-univers-light text-xs ml-2 transition ${
                    disabled ? 'text-gray-300' : 'text-gray-400'
                }`}
            >
                {children}
            </p>
            <input
                type="file"
                multiple
                onChange={onChange}
                className={`absolute top-0 left-0 w-full h-full opacity-0 ${
                    disabled && 'pointer-events-none'
                }`}
            />
        </>
    )
}
