import { LOGISTICAL_CONCEPT__DUTY } from '../../../../../config/constants'
import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowDutyPlusIva(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [{ value: '' }]
    const backgroundColor = COLOR.melon
    data.forEach(d => {
        const duty_cost = d.nationalization_costs.find(
            c => c.logistical_concept_id === LOGISTICAL_CONCEPT__DUTY
        )
        row.push({
            fontWeight: 'bold',
            value:
                d.data_summary.iva_original +
                (duty_cost ? duty_cost.original_cost : 0),
            format: '0.00',
            backgroundColor: backgroundColor
        })
        row.push({
            value: '',
            backgroundColor: backgroundColor
        })
        row.push({
            value: '',
            backgroundColor: backgroundColor
        })
        row.push({
            fontWeight: 'bold',
            value:
                d.data_summary.iva_local +
                (duty_cost ? duty_cost.local_cost : 0),
            format: '0.00',
            backgroundColor: backgroundColor
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
