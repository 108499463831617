import ListFormControl from '../../sections/ListForm/ListFormControl'
import ControlTabs from '../../utilities/ControlTabs'
import { StoragePage, Step1, Step2 } from './types'
import { Keys } from './constants'
import FormEvents from '../../utilities/FormEvents'

export const storagePage: StoragePage = {
    productPrice: null,
    productPriceId: null,
    modeStep2: 'view',
    productId: null,
    loadingSaleDiscounts: false,
    enabledEditionDiscount: false
}

export const controlTabs = new ControlTabs()

export const tablePricesControl = new ListFormControl<Step1.TablePrices>(
    Keys.TABLE_PRICES,
    [
        'Compañía',
        'Factor de subcategoría',
        'Factor especial',
        'Descuento de subcategoría',
        'Costo de proveedor',
        'Precio de venta',
        'Precio con descuento'
    ]
)

export const tableSaleDiscountsControl =
    new ListFormControl<Step2.TableSaleDiscounts>(Keys.TABLE_SALE_DISCOUNTS, [
        '#',
        'Cantidad mínima',
        'Cantidad máxima',
        'Descuento (%)',
        'Acciones'
    ])

export const formFactor = new FormEvents<Step1.FormFactor>(Keys.FORM_FACTOR, {
    special_factor: null
})

export const formSaleDiscounts = new FormEvents<Step2.FormProductCompany>(
    Keys.FORM_SALE_DISCOUNTS,
    {
        company: null,
        cost_provider: null,
        inventory_barrier: null,
        symbol: null
    }
)
