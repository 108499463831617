import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'
import Fields4 from './Fields4'

export default function FormLogisticalCost() {
    return (
        <div className="flex self-stretch">
            <div className="w-4/6 pr-4">
                <Fields1 />
                <Fields3 />
                <Fields4 />
            </div>
            <div className="w-1/3 border-l border-dashed -mt-4 -mb-4 pt-4 pl-4">
                <Fields2 />
            </div>
        </div>
    )
}
