import { useHistory } from 'react-router'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList, storagePage } from '../storage'
import { GetInputPart, InputPartData } from '../types'
import IsActive from './BadgeActive'
import moment from 'moment'
import Pdf from './Pdf'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import buildName from '../../../utilities/buildName'
import { viewPrint } from '../../../sections/ModalPrint'
import BtnActionTable from '../../../components/BtnActionTable'
import RunAccountingContainer, { RunAccounting } from '../RunAccounting'
import ModalInputPart from '../../../modals/ModalInputPart'
import CancelInputPart from './Options/CancelInputPart'
import ExportExcel from './Options/ExportExcel'

export default function Table() {
    const { push } = useHistory()

    function ViewerPdf({ data }: { data: GetInputPart }) {
        return <Pdf data={data} />
    }

    function LoadPDF({ input_part }: { input_part: InputPartData }) {
        function handleClick() {
            viewPrint(`/v5.6/apis/input_parts/${input_part.id}`, () => ({
                Component: ViewerPdf
            }))
        }

        return (
            <BtnActionTable
                title="Imprimir"
                iconName="bs-printer"
                onClick={handleClick}
            />
        )
    }

    const math = useRule('CU180')

    return (
        <List control={controlList}>
            {(input_part, e) => (
                <tr key={input_part.id}>
                    <td>{e}</td>
                    <td>
                        <b
                            {...(math && {
                                className: 'underline cursor-pointer',
                                onClick: () =>
                                    view(
                                        `/v5.6/apis/input_parts/${input_part.id}`,
                                        () => ({
                                            title: `DETALLE DE PARTE DE ENTRADA`,
                                            Component: ModalInputPart
                                        }),
                                        'lg'
                                    )
                            })}
                        >
                            {input_part.document.code}
                        </b>
                    </td>
                    <td>
                        <p
                            className={
                                !input_part.document.requesting_user.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {buildName(
                                input_part.document.requesting_user.name,
                                input_part.document.requesting_user.lastname
                            )}
                        </p>
                    </td>
                    <td>
                        <p
                            className={
                                !input_part.company_warehouse.warehouse
                                    .is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {input_part.company_warehouse.warehouse.name}
                        </p>
                    </td>
                    <td>
                        <span
                            className={
                                !input_part.minimal_stowage.stowage.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {input_part.minimal_stowage.order_sub_stowage ? (
                                <p>
                                    {input_part.minimal_stowage
                                        .order_sub_stowage.order.number +
                                        ' - ' +
                                        input_part.minimal_stowage
                                            .order_sub_stowage.order.client
                                            .name}
                                </p>
                            ) : (
                                <p>{input_part.minimal_stowage.stowage.name}</p>
                            )}
                        </span>
                    </td>
                    <td>
                        <p
                            className={
                                !input_part.input_part_label.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {input_part.input_part_label.name}
                        </p>
                    </td>
                    <td>
                        {moment(input_part.document.decision_date)
                            .tz(storagePage.timezone)
                            .format('DD/MM/YYYY')}
                    </td>
                    <td>
                        <IsActive enabled={!input_part.document.is_annuled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            {!input_part.document.is_annuled && (
                                <Rules cu="CU178">
                                    <BtnActionTable
                                        title="Editar"
                                        iconName="bs-pencil"
                                        disabled={
                                            !storagePage.company_enabled ||
                                            input_part.document.has_accounting
                                        }
                                        onClick={() =>
                                            push(
                                                `/movements/input_parts/${input_part.id}/edition`
                                            )
                                        }
                                    />
                                </Rules>
                            )}
                            <Rules cu="CU179">
                                <LoadPDF input_part={input_part} />
                            </Rules>
                            <Rules cu="CU314">
                                <ExportExcel input_part_id={input_part.id} />
                            </Rules>
                            <CancelInputPart input_part={input_part} />
                            <Rules cu="CU310">
                                <RunAccountingContainer
                                    control={controlList}
                                    body={{
                                        input_part_id: input_part.id
                                    }}
                                    disabled={
                                        !storagePage.company_enabled ||
                                        input_part.document.has_accounting
                                    }
                                >
                                    <RunAccounting
                                        path={`/v5.6/apis/input_parts/run_accounting`}
                                    >
                                        Se generarán los asientos contables para
                                        la parte de entrada. Luego de esta
                                        acción la parte de entrada no podrá ser
                                        modificada, esta acción es irreversible.
                                        ¿Está seguro de generar asientos
                                        contables para la parte de entrada{' '}
                                        <b>{input_part.document.code}</b>?
                                    </RunAccounting>
                                </RunAccountingContainer>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
