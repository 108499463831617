import moment from 'moment'
import Progress from '../../../../components/Progress'
import useRule from '../../../../hooks/useRule'
import List from '../../../../sections/List'
import { ocList } from '../../storage'
import useIsCompanyDisabled from '../../useIsCompanyDisabled'
import CommonCols from './CommonCols'
import LoadPDF from './LoadPdf'
import ClosePurchase from './Options/ClosePurchase'
import DisconfirmPurchase from './Options/DisconfirmPurchase'
import Icon from '../../../../components/Icon'
import writeXlsxFile, { Schema } from 'write-excel-file'
import { buildPath } from '../../../../sections/ModalPrint/utils'
import { get } from '../../../../utilities/http'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import useStorage from '../../../../hooks/useStorage'
import classnames from 'classnames'

async function downloadExcel(item: any, getCountry: any) {
    const { data } = await get(buildPath(`/v5.6/apis/purchases/${item.id}`, []))
    const dataAPI: any = data.result
    let total = 0
    let nOcs = 0

    const objects: any = [
        [],
        [
            {
                value: 'NRO DOC.',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: dataAPI.code,
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true
            }
        ],
        [
            {
                value: 'PARA/TO',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value:
                    dataAPI.provider_company.provider.business_name +
                    ', ' +
                    getCountry(dataAPI.provider_company.provider.country_id)
                        .name,
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true
            }
        ],
        [
            {
                value: 'DE/FROM',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value:
                    dataAPI.invoicing_company.name +
                    ', ' +
                    dataAPI.invoicing_company.address,
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true
            }
        ],
        [
            {
                value: 'FECHA OC/P.O DATE',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: moment(dataAPI.document.decision_date)
                    .tz(dataAPI.invoicing_company.timezone)
                    .format('DD/MM/YYYY'),
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true
            }
        ],
        [
            {
                value: 'CONDICIÓN DE PAGO',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: dataAPI.payment_condition_description
                    ? dataAPI.payment_condition_description
                    : '-',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true
            }
        ],
        [
            {
                value: 'MEDIO DE ENVIO/VÍA',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: dataAPI.shipping_method_purchase.name,
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true
            }
        ],
        [
            {
                value: 'DIRECCIÓN DE ENTREGA/DELIVERY ADDRESS',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: [
                    dataAPI.relation_address.agent?.name,
                    dataAPI.relation_address.company?.name,
                    dataAPI.relation_address.warehouse?.name,
                    dataAPI.relation_address.address
                ]
                    .filter(function (elemento) {
                        return (
                            elemento !== null &&
                            elemento !== '' &&
                            elemento !== undefined
                        )
                    })
                    .join(', '),
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true
            }
        ],
        [],
        [
            {
                value: 'R',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: 'Item/Cod.',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: 'Cant./Qty',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: 'Descripción/Description',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: 'Costo/Cost',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            },
            {
                value: 'Total',
                fontWeight: 'bold',
                alignVertical: 'center',
                align: 'center',
                borderColor: '#000000',
                wrap: true,
                backgroundColor: '#e3e3e3'
            }
        ]
    ]

    for (const [index, oc_product] of dataAPI.purchase_products.entries()) {
        if (oc_product.is_enabled && !oc_product.deleted) {
            nOcs += 1
            objects.push([
                {
                    value: '' + nOcs,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: oc_product.requisition_product
                        .product_provider_company.provider_code,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: '' + oc_product.quantity,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value: oc_product.requisition_product
                        .product_provider_company.provider_description,
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value:
                        '' + buildNumberFormat(oc_product.unit_purchase_price),
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                },
                {
                    value:
                        '' +
                        buildNumberFormat(
                            oc_product.unit_purchase_price * oc_product.quantity
                        ),
                    alignVertical: 'center',
                    align: 'center',
                    borderColor: '#000000',
                    wrap: true
                }
            ])
            total += oc_product.quantity * oc_product.unit_purchase_price
        }
    }

    objects.push([
        {},
        {},
        {},
        {},
        {
            value: 'Total Neto ' + dataAPI.currency.code,
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        },
        {
            value: buildNumberFormat(Math.round(total * 100) / 100),
            fontWeight: 'bold',
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        }
    ])

    objects.push([
        {
            value: 'Comentarios',
            rowSpan: 3,
            fontWeight: 'bold',
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true,
            backgroundColor: '#e3e3e3'
        },
        {
            value: 'Estos precios no incluyen I.V.A./Taxes are not included',
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        },
        {},
        {},
        {
            value: 'Descuento/Discount',
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        },
        {
            value: '0,00',
            fontWeight: 'bold',
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        }
    ])

    objects.push([
        {
            borderColor: '#000000'
        },
        {
            value:
                'Emitir facturas a nombre de ' +
                dataAPI.destination_company.name,
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        },
        {},
        {},
        {
            value: 'Recargo/Charge',
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        },
        {
            value: '0,00',
            fontWeight: 'bold',
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        }
    ])

    objects.push([
        {
            borderColor: '#000000'
        },
        {
            value:
                'Please send your invoice to ' +
                dataAPI.destination_company.name,
            alignVertical: 'center',
            align: 'center',
            borderColor: '#000000',
            wrap: true
        },
        {},
        {},
        {},
        {}
    ])

    await writeXlsxFile(objects, {
        fileName: `${dataAPI.code}.xlsx`,
        columns: [35, 35, 11, 35, 13, 13].map(width => ({ width, wrap: true })),
        fontSize: 10
    })
}

export default function TableConfirm() {
    const { countries } = useStorage()
    const getCountry = (country_id: number) =>
        countries.find(country => country.id === country_id)
    const classNamesBtn = classnames(`btn-option`)
    const ruleClose = useRule('CU299')
    const ruleDisconfirm = useRule('CU248')
    const rulePrintPdf = useRule('CU250')
    const isCompanyDisabled = useIsCompanyDisabled()

    return (
        <List
            control={ocList}
            dontResetFilterWhenExit
            tbody={(item, e) => (
                <tr key={e}>
                    <td>{e}</td>
                    <CommonCols item={item} />
                    <td>
                        {moment(item.provider_confirmation_date)
                            .tz(item.invoicing_company.timezone)
                            .format('DD/MM/YYYY')}
                    </td>
                    <td>
                        <Progress number={item.percentage} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            {ruleDisconfirm && (
                                <DisconfirmPurchase
                                    purchase_code={item.code}
                                    purchase_id={item.id}
                                    disabled={isCompanyDisabled}
                                />
                            )}
                            {ruleClose && (
                                <ClosePurchase
                                    purchase_code={item.code}
                                    percentage={item.percentage}
                                    purchase_id={item.id}
                                    disabled={isCompanyDisabled}
                                />
                            )}
                            {rulePrintPdf && <LoadPDF order={item} />}
                            <button
                                type="button"
                                title="Exportar en Excel"
                                className={classNamesBtn}
                                disabled={false}
                                onClick={async () => {
                                    await downloadExcel(item, getCountry)
                                }}
                            >
                                <Icon size={15} name="bs-file-earmark-excel" />
                            </button>
                        </div>
                    </td>
                </tr>
            )}
        />
    )
}
