import { Option } from '../../../config/types/component'
import useGet from '../../../hooks/useGet'
import Stowage from '../../../models/Stowage'
import {
    filterForm,
    reqStowageDestination,
    reqStowageOrigin,
    reqSubStowageDestination,
    reqSubStowageOrigin
} from '../storage'
import { GetStowagesOrigin } from '../types'

export default function useStowageOrigin() {
    const { props } = filterForm.useSelect<Stowage>('stowage_id')

    const { data, props: propsSearch } = useGet<GetStowagesOrigin[]>(
        reqStowageOrigin.observer('/v5.6/apis/stowages/', true),
        []
    )

    function handleChange(option: Option) {
        if (option.value === '0') {
            filterForm.setProps('stowage_destination_id', { disabled: true })
            reqSubStowageDestination.deleteQuery('origin_stowage_id')
            filterForm.setValue('stowage_destination_id', null)
        } else {
            filterForm.setValue('stowage_destination_id', null)
            filterForm.setValue('minimal_stowage_destination_id', null)
            filterForm.setProps('minimal_stowage_destination_id', {
                disabled: true
            })
            filterForm.setProps('stowage_destination_id', { disabled: false })
            reqStowageDestination.setQuery('origin_stowage_id', option.value)
            reqStowageDestination.load()
            props.onChange(option)

            const { type_stowage_id } = data.result.find(
                _stowage => _stowage.id === parseInt(option.value)
            )

            // Pedidos (P) o Remisionada (R) o Garantia Proveedor
            const isPR =
                type_stowage_id === 2 ||
                type_stowage_id === 11 ||
                type_stowage_id === 6

            if (isPR) {
                reqSubStowageOrigin.setParam('stowage_id', option.value)
                filterForm.setProps('minimal_stowage_id', {
                    disabled: false
                })
                reqSubStowageOrigin.load()
            } else {
                filterForm.setProps('minimal_stowage_id', {
                    disabled: true
                })
                filterForm.setValue('minimal_stowage_id', null)
            }
            props.onChange(option)
        }
    }

    return {
        props,
        propsSearch,
        handleChange,
        data
    }
}
