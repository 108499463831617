import Routes from '../../config/routes'
import { ItemRoute, RoutingPage } from '../../config/routes/types'

function iterateRoutes(
    epic: RoutingPage,
    route: ItemRoute,
    epicModule: RoutingPage
) {
    const subs = route.routes || []
    const fixPath = (_p: string) => (_p === '/' ? '' : _p)

    const pathRoute =
        route.path === epic.path
            ? epic.path
            : `${fixPath(epic.path)}${fixPath(route.path)}`
    epicModule.routes.push({
        ...route,
        path: pathRoute,
        ...(subs.length > 0 && {
            routes: subs.map(subroute => ({
                ...subroute,
                path: `${pathRoute}${fixPath(subroute.path)}`
            }))
        })
    })
}

export default function getModules(): any[] {
    const data: Array<any> = []
    Routes().forEach(epic => {
        const epicModule: any = {
            ...epic
        }
        if (epic.routes) {
            epicModule.routes = []
            for (const route of epic.routes) {
                iterateRoutes(epic, route, epicModule)
            }
        }
        data.push(epicModule)
    })

    return data
}
