import classNames from 'classnames'
import { PropsTypografy } from './types'

export default function Typography({
    children,
    type = 'paragraph',
    color = 'primary',
    className,
    lineThrough = false
}: PropsTypografy) {
    const textColor = color === 'gray' ? `text-${color}-400` : `text-${color}`
    const font = 'font-univers'

    if (type === 'title' || type === 'header-1')
        // 30px
        return (
            <h1
                className={classNames(
                    `${font}-bold-cn text-3xl uppercase ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </h1>
        )
    if (type === 'header-2')
        return (
            <h2
                className={classNames(
                    `${font}-bold-cn text-2xl ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </h2>
        )

    if (type === 'header-3')
        return (
            <h3
                className={classNames(
                    `${font}-bold-cn text-xl text-accent ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </h3>
        )

    if (type === 'header-4')
        return (
            <h4
                className={classNames(
                    `${font}-cn text-lg ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </h4>
        )

    if (type === 'subtitle')
        return (
            <h5
                className={classNames(
                    `${font}-cn text-md ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </h5>
        )

    if (type === 'paragraph')
        return (
            <p
                className={classNames(
                    `${font} text-sm ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </p>
        )

    if (type === 'paragraph-bold')
        return (
            <p
                className={classNames(
                    `${font}-bold text-sm ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </p>
        )

    if (type === 'small')
        return (
            <small
                className={classNames(
                    `${font} text-xs ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </small>
        )

    if (type === 'button')
        return (
            <span
                className={classNames(
                    `${font} text-sm ${textColor}`,
                    className,
                    {
                        'line-through': lineThrough
                    }
                )}
            >
                {children}
            </span>
        )

    // link
    return (
        <span
            className={classNames(
                `${font}-bold text-sm ${textColor}`,
                className,
                {
                    'line-through': lineThrough
                }
            )}
        >
            {children}
        </span>
    )
}
