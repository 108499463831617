import {
    FormControl,
    IterateOptions
} from '../../../../../components/FormUtilities'
import SelectTouch from '../../../../../components/SelectTouch'
import AddressFields from './AdressFields'
import PlaceDispatchFields from './PlaceDispatchFields'
import useCountry from './useCountry'
import useTypeDispatch from './useTypeDispatch'

function CountryField() {
    const { countries, props, propsSearch, handleChange } = useCountry()

    return (
        <FormControl label="País" required className="mb-2">
            <SelectTouch
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar país"
                label="País"
            >
                <IterateOptions
                    data={countries}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}

function TypeDispatch() {
    const { props, typeDispatches, handleChange } = useTypeDispatch()

    return (
        <>
            <FormControl label="Tipo de destino" required className="mb-2">
                <SelectTouch
                    disabled
                    {...props}
                    onChange={handleChange}
                    placeholder="Seleccionar un tipo de destino"
                    label="Tipo de despacho"
                >
                    <IterateOptions data={typeDispatches} label="name" />
                </SelectTouch>
            </FormControl>
        </>
    )
}

export default function FormDispatch() {
    return (
        <>
            <CountryField />
            <TypeDispatch />
            <PlaceDispatchFields />
            <AddressFields />
        </>
    )
}
