import { ReactNode } from 'react'
import { useRender } from 'redity'
import { Caption, TBody } from '../../components/DataTable'
import ControlList from '../../utilities/Control/ControlList'

interface PropsBody<T> {
    control: ControlList<T>
    children: (data: T, enumeration: number) => ReactNode
    type: 'table' | 'cards'
    head?: (response: any) => ReactNode
}

export default function Body<T>({
    control,
    children,
    type,
    head
}: PropsBody<T>) {
    useRender(control.keyRender, control.indexs.BODY)
    const list = control.list
    return (
        <>
            {type === 'table' && (
                <TBody>
                    {list.length === 0 && (
                        <Caption colSpan={control.thead.length}>
                            No se encontraron resultados
                        </Caption>
                    )}
                    {head &&
                        control?.response?.head &&
                        list.length > 0 &&
                        head(control.response.head)}
                    {list.map((data, i) =>
                        children(data, control.filter.enumeration(i))
                    )}
                </TBody>
            )}
            {type === 'cards' && (
                <div className="grid md:grid-cols-3 xl:grid-cols-4 gap-7">
                    {list.length === 0 && (
                        <div className="h-16 col-span-3">
                            No se encontraron resultados
                        </div>
                    )}
                    {list.map((data, i) =>
                        children(data, control.filter.enumeration(i))
                    )}
                </div>
            )}
        </>
    )
}
