import { ReactNode } from 'react'
import { post, put } from './http'
import { Exception } from './http/type'
import Notify from './Notify'
import { Popup } from './popup'
import { Progress } from './progress'

interface SubmitData {
    body: Record<string, any>
    method?: 'post' | 'put'
    title?: string
    /**
     * @deprecated usa message
     */
    confirm?: ReactNode
    message?: ReactNode
    progress?: string
    notify?: string
}

interface SubmitResponse<T> {
    error: Exception
    success: boolean
    data: T
}

export default async function submit<T>(
    path: string,
    {
        body,
        method = 'post',
        title = 'Guardar cambios',
        confirm = null,
        message = null,
        progress = 'Cargando',
        notify = 'La operación se ha completado exitosamente'
    }: SubmitData
): Promise<SubmitResponse<T>> {
    const send = method === 'post' ? post : put

    if (confirm || message) {
        const result = await Popup.confirm(title, confirm || message)

        if (!result)
            return {
                error: null,
                success: false,
                data: null
            }
    }
    Progress.open(progress)
    const response = await send<T>(path, body)
    Progress.close()

    if (response.data) {
        Notify.success(notify)
    }

    return {
        error: response.error,
        success: !response.error,
        data: response.data?.result || null
    }
}
