export default function Save(props) {
    return (
        <svg
            width="1em"
            height="1em"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m.5 7 .01-7L21.5 9 .51 18 .5 11l15-2-15-2Zm2.01-3.97 7.51 3.22-7.52-1 .01-2.22Zm7.5 8.72L2.5 14.97v-2.22l7.51-1Z"
            />
        </svg>
    )
}
