import { ItemRoute } from './types'
import PurchaseReceipts from '../../pages/PurchaseReceipts'
import InputParts from '../../pages/InputParts'
import ConsumptionVouchers from '../../pages/ConsumptionVouchers'
import Traspases from '../../pages/Traspases'
import PurchaseReceipt from '../../pages/PurchaseReceipt'
import Traspase from '../../pages/Traspase'
import InputPart from '../../pages/InputPart'
import ConsumptionVoucher from '../../pages/ConsumptionVoucher'

const Movements: ItemRoute[] = [
    {
        label: 'Recepciones de compra',
        title: 'Recepciones de compra',
        path: '/purchase_receipts',
        cu: 'CU225',
        Component: PurchaseReceipts,
        routes: [
            {
                title: 'Nueva recepción de compra',
                cu: 'CU226',
                path: '/create',
                Component: PurchaseReceipt
            },
            {
                title: 'Editar recepción de compra',
                cu: 'CU227',
                path: '/:purchase_receipt_id/edition',
                Component: PurchaseReceipt
            }
        ]
    },
    {
        cu: 'CU143',
        title: 'Traspasos',
        label: 'Traspasos',
        path: '/traspases',
        Component: Traspases,
        routes: [
            {
                cu: 'CU317',
                title: 'Nuevo traspaso',
                path: '/creation',
                Component: Traspase
            },
            {
                cu: 'CU145',
                title: 'Editar traspaso',
                path: '/:traspase_id/edition',
                Component: Traspase
            }
        ]
    },
    {
        cu: 'CU176',
        title: 'Partes de entrada',
        label: 'Partes de entrada',
        path: '/input_parts',
        Component: InputParts,
        routes: [
            {
                cu: 'CU177',
                title: 'Nueva parte de entrada',
                path: '/creation',
                Component: InputPart
            },
            {
                cu: 'CU178',
                title: 'Editar parte de entrada',
                path: '/:input_part_id/edition',
                Component: InputPart
            }
        ]
    },
    {
        cu: 'CU167',
        title: 'Vales de consumo',
        label: 'Vales de consumo',
        path: '/consumption_vouchers',
        Component: ConsumptionVouchers,
        routes: [
            {
                cu: 'CU168',
                title: 'Nuevo vale de consumo',
                path: '/creation',
                Component: ConsumptionVoucher
            },
            {
                cu: 'CU169',
                title: 'Editar vale de consumo',
                path: '/:consumer_voucher_id/edition',
                Component: ConsumptionVoucher
            }
        ]
    }
]

export default Movements
