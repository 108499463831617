import classNames from 'classnames'
import React, { ReactNode, createContext } from 'react'
import Button from '../components/Button'

import SearchField from '../components/SearchField'
import { Option } from '../config/types/component'

export interface SelectContextData {
    onClick: (option: Option) => void
    optionsChecks: string[]
}

export interface PropsSelectListProvider {
    isOpen: boolean
    label: string
    onOption: (option: Option) => void
    children: ReactNode
    className?: string
    placeholderSearch?: string
    onAccept?: () => void | null
    onSearch?: (ev: any) => void | null
    search?: string
    optionsChecks?: string[]
    scroll?: boolean
    ref?: React.MutableRefObject<HTMLDivElement>
    clear?: boolean
    fullWidth?: boolean
}

const SelectContext = createContext<SelectContextData>({
    onClick: () => null,
    optionsChecks: []
})

export default SelectContext

export const SelectListProvider = React.forwardRef<
    HTMLDivElement,
    PropsSelectListProvider
>(function SelectList(
    {
        isOpen,
        label,
        onOption,
        children,
        className = '',
        placeholderSearch = 'Búsqueda',
        search = '',
        onSearch = null,
        onAccept = null,
        optionsChecks = [],
        scroll = true,
        clear = true,
        fullWidth = false
    }: PropsSelectListProvider,
    ref
) {
    return (
        <div
            className={classNames(
                'select-touch absolute rounded shadow-lg bg-white border py-2 z-30 zoom-in right-0',
                {
                    hidden: !isOpen
                },
                {
                    'w-full': fullWidth
                },
                className
            )}
            ref={ref}
        >
            <div className="px-4">
                <div className="flex justify-between pt-1">
                    <label
                        htmlFor=""
                        className="text-accent font-univers text-xs"
                    >
                        {label}
                    </label>
                    {clear && (
                        <span
                            className="text-gray-500 underline text-xs cursor-pointer"
                            onClick={() => onOption({ label: '', value: '0' })}
                        >
                            Limpiar
                        </span>
                    )}
                </div>
                {isOpen && onSearch && (
                    <SearchField
                        className="select-touch--search w-full mt-2"
                        placeholder={placeholderSearch}
                        fullWidth
                        autoFocus
                        value={search}
                        onChange={onSearch}
                        onClear={() => onSearch({ target: { value: '' } })}
                        size="auto"
                    />
                )}
            </div>
            <SelectContext.Provider
                value={{ onClick: onOption, optionsChecks }}
            >
                <div
                    className={`mt-2 px-4 ${
                        scroll ? 'overflow-y-auto max-h-52' : ''
                    }`}
                >
                    {children}
                </div>
            </SelectContext.Provider>
            {onAccept && (
                <div className="px-4 pt-4">
                    <Button size="sm" onClick={onAccept} block>
                        OK
                    </Button>
                </div>
            )}
        </div>
    )
})
