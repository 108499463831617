import { GetRequisition } from '../type'

export default function RequisitionProducts({
    data
}: {
    data: GetRequisition
}) {
    return (
        <div className="body__tables">
            <div className="column">
                <div className="card_info">
                    <h3>REQUISICIONES DE COMPRA</h3>
                    <table className="table_info">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Código Producto (ERP)</th>
                                <th>Descripción Producto (ERP)</th>
                                <th>Código Producto (Proveedor)</th>
                                <th>Descripción Producto (Proveedor)</th>
                                <th>Cantidad solicitante</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.classic_requisition_products
                                .filter(x => !x.deleted)
                                .filter(x => x.requisition_product.is_enabled)
                                .map((item, i) => (
                                    <tr key={item.id}>
                                        <td>{i + 1}</td>
                                        <td>
                                            {
                                                item.requisition_product.product
                                                    .code
                                            }
                                        </td>
                                        <td>
                                            {
                                                item.requisition_product.product
                                                    .description
                                            }
                                        </td>
                                        <td>
                                            {
                                                item.requisition_product
                                                    .product_provider_company
                                                    .provider_code
                                            }
                                        </td>
                                        <td>
                                            <p>
                                                {
                                                    item.requisition_product
                                                        .product_provider_company
                                                        .provider_description
                                                }
                                            </p>
                                        </td>
                                        <td>
                                            {item.requisition_product.quantity}{' '}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
