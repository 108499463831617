import { View, Font } from '@react-pdf/renderer'
import { LogisticalDeclarationPDFData } from '../../types'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import TableLogisticalReceptions from './tables/TableLogisticalReceptions'
import TableInternationalCosts from './tables/TableInternationalCosts'
import TableNationalizationCosts from './tables/TableNationalizationCosts'
import TableExtraInternationalCosts from './tables/TableExtraInternationalCosts'
import TableExtraNationalCosts from './tables/TableExtraNationalCosts'
import TableSettingCosts from './tables/TableSettingCosts'
import TableSummary from './tables/TableSummary'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

export default function Body({ data }: { data: LogisticalDeclarationPDFData }) {
    return (
        <View>
            <TableLogisticalReceptions data={data} />
            {data.type_logistical_declaration_id === 1 && (
                <>
                    <TableInternationalCosts data={data} />
                    <TableNationalizationCosts data={data} />
                    <TableExtraInternationalCosts data={data} />
                </>
            )}
            <TableExtraNationalCosts data={data} />
            <TableSettingCosts data={data} />
            <TableSummary data={data} />
        </View>
    )
}
