import Initial from '../../../components/Initial'
import Header from './Header'
import { useParams } from 'react-router'
import SectionGeneralInformation from './SectionGeneralInformation'
import SectionCosts from './SectionCosts'
import SectionAdditional from './SectionAdditional'
import SectionCostDistribution from './SectionCostDistribution'
import { controlTabsInternational, storagePage } from '../storage'
import useInitial from '../useInitial'
import { Popup } from '../../../utilities/popup'
import { tableRCsControl } from './SectionCosts/storageStep2'
import SectionSettings from './SectionSettings'

controlTabsInternational.setSection(
    'Información General',
    SectionGeneralInformation
)
controlTabsInternational.setSection('Costos', SectionCosts)
controlTabsInternational.setSection('Adicionales', SectionAdditional)
controlTabsInternational.setSection('Ajustes', SectionSettings)
controlTabsInternational.setSection('Distribución', SectionCostDistribution)

export default function CostInternational() {
    const { pageState, cancel } = useInitial(1)
    type Params = { logistical_declaration_id?: string }
    const { logistical_declaration_id } = useParams<Params>()
    const value = controlTabsInternational.useStep(to => {
        if (
            (to === 'step3' || to === 'step4') &&
            tableRCsControl.getDataList().length === 0 &&
            controlTabsInternational.value === 'step2'
        ) {
            Popup.error(
                'Costeo incompleto',
                'Debe de seleccionar al menos una recepción en el costeo.'
            )
            return false
        }
        if (storagePage.mode === 'edition') return true
        if (to !== 'step1' && !storagePage.step1Done) {
            return false
        }
        return true
    })

    return (
        <Initial pageState={pageState}>
            <Header cancel={cancel} />
            <controlTabsInternational.SectionTabs
                value={value}
                type={logistical_declaration_id ? 'tab' : 'step'}
                className="max-w-screen-2xl"
            />
        </Initial>
    )
}
