import DatePicker from '../../../components/Datepicker'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectMultiple from '../../../components/SelectMultiple'
import Toggle from '../../../components/Toggle'
import useStorage from '../../../hooks/useStorage'
import { formLicense } from '../storage'

function TypeLicense() {
    const { props } = formLicense.useSelects('type_licenses')
    const { storage } = useStorage()

    return (
        <FormControl label="Tipo de licencia" col="span-6" required>
            <SelectMultiple
                {...props}
                placeholder="Seleccionar tipo de licencia"
                label="Seleccionar"
                selectTouchInherit={true}
            >
                <IterateOptions data={storage.typeLicenses} label="name" />
            </SelectMultiple>
        </FormControl>
    )
}

function IssueDate() {
    const { props } = formLicense.useDate('issue_date')
    return (
        <FormControl label="Fecha de emisión" required>
            <DatePicker {...props} placeholder="Seleccionar fecha" />
        </FormControl>
    )
}

function DueDate() {
    const { props } = formLicense.useCheck('due_date_is_enabled')
    const { props: propsDate, getError } = formLicense.useDate('due_date')
    const helper = getError('ERROR-0000015')
    return (
        <FormControl
            label="Fecha de vencimiento"
            labelComponent={<Toggle {...props} />}
        >
            <DatePicker
                {...propsDate}
                placeholder="Seleccionar fecha"
                helper={propsDate.helper || helper}
            />
        </FormControl>
    )
}

export default function Fields2() {
    return (
        <>
            <TypeLicense />
            <IssueDate />
            <DueDate />
        </>
    )
}
