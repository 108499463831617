import { render } from 'redity'
import { get } from '../../../utilities/http'
import { Keys } from '../constants'
import { storagePage, controlList } from '../storage'
import { GeneralInformation } from '../types'

export default async function loadHead() {
    const { company_id, product_id, start_date, end_date } =
        controlList.filter.params
    const { data, error } = await get<GeneralInformation>(
        `/v5.6/apis/kardex/general_information?to_print=${true}&company_id=${company_id}&start_date=${start_date}&end_date=${end_date}&product_id=${product_id}`
    )
    if (error) return false
    storagePage.header = {
        total_balance: data.result.total_balance,
        total_quantity: data.result.total_quantity,
        records: data.result.records
    }

    render(Keys.HEADER)
    return error
}
