import SearchField from '../../../../components/SearchField'
import { productPriceList } from '../../storage'

export default function SearchProductPrices() {
    const { props } = productPriceList.useSearch()

    return (
        <SearchField
            {...props}
            size="auto"
            fullWidth={true}
            placeholder="Buscar por código o nombre de producto"
            className="ml-0"
        />
    )
}
