import Brand from '../../models/Brand'
import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'

export const controlList = new ControlList<Brand>(Keys.MAIN, {
    path: '/v5.6/brands',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'name', label: 'Marca' },
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})

export const formBrand = new FormEvents(Keys.FORM, {
    name: ''
})
