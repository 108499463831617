import classNames from 'classnames'
import { Option } from '../../config/types/component'
import { SelectListProvider } from '../../contexts/select-context'
import useOut from '../../hooks/useOut'
import Icon from '../Icon'
import Loading from '../Loading'
import { PropsDropdown } from './types'

export default function Dropdown({
    className,
    children,
    label,
    showLabel = true,
    onChange = () => null,
    option = null,
    onSearch = null,
    search = '',
    loading = false,
    clear = true,
    fullWidth = false
}: PropsDropdown) {
    const { ref, isOpen, setOpen } = useOut([])
    function handleOption(opt: Option) {
        onChange(opt)
        setOpen(false)
    }

    function handleClick() {
        setOpen(true)
    }
    return (
        <div ref={ref} className="relative">
            <button
                placeholder="Test"
                onClick={handleClick}
                className={classNames(
                    `dropdown--button btn btn-sm font-thin font-univers-light btn-accent gap-2 ${
                        option ? '' : 'btn-outline'
                    }`,
                    {
                        'w-full': fullWidth
                    },
                    className
                )}
            >
                {/* {option || label} */}
                {!option && label}
                {option && showLabel && label + ': '}
                {option && (
                    <>
                        {option && option.image && (
                            <img src={option.image} className="w-6" />
                        )}{' '}
                        <div>{(option as any).label3 || option.label}</div>
                    </>
                )}
                <Icon name="arrow-bottom" className="" size={11} />
            </button>
            <SelectListProvider
                isOpen={isOpen}
                onOption={handleOption}
                label={label}
                className="w-60 top-9"
                onSearch={onSearch}
                search={search}
                clear={clear}
                fullWidth={fullWidth}
            >
                {loading ? <Loading scale={0.7} /> : children}
            </SelectListProvider>
        </div>
    )
}
