import LogisticalConcept from '../../../../../models/LogisticalConcept'
import {
    GroupLogisticalConcept,
    LogisticalDeclarationExcelData
} from '../../../types'
import { getRowCost } from './getRowCost'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR, textBold } from './utils'

export function getRowsOriginCosts(
    data: LogisticalDeclarationExcelData[],
    groupConcepts: GroupLogisticalConcept[]
) {
    const rows: Row[][] = []
    getOriginConcepts(groupConcepts).forEach(concept => {
        rows.push(
            getRowCost(concept, data, [
                'international_costs',
                'extra_international_costs'
            ])
        )
    })
    const rowSummary: Row[] = [
        {
            value: 'Costos en origen',
            backgroundColor: COLOR.yellow
        }
    ]
    data.forEach(d => {
        rowSummary.push({
            value:
                d.data_summary.international_original_cost +
                d.data_summary.extra_international_original_cost,
            format: '0.00',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value:
                d.data_summary.international_local_cost +
                d.data_summary.extra_international_local_cost,
            format: '0.00',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    rows.push(addRowStyles(rowSummary, [borderThin, textBold]))
    return rows
}

function getOriginConcepts(groupConcepts: GroupLogisticalConcept[]) {
    const originConcepts: LogisticalConcept[] = []
    groupConcepts
        .filter(groupConcept => groupConcept.id === 1 || groupConcept.id === 3)
        .forEach(groupConcept => {
            groupConcept.logistical_concepts.forEach(concept => {
                originConcepts.push(concept)
            })
        })
    return originConcepts
}
