import { controlList, formSubcategory } from './storage'
import Form from './View/Form'

export default function useSubmit(subCategoryId?: number) {
    const { submit, validation, observer } = formSubcategory.useSubmit(
        subCategoryId
            ? `/v5.6/apis/subcategories/${subCategoryId}`
            : '/v5.6/apis/subcategories',
        {
            method: subCategoryId ? 'put' : 'post',
            done: controlList
        }
    )

    validation(v => {
        v.name.minLength(2, 'El número de caracteres válidos es 2 - 180')
        v.name.maxLength(180, 'El número de caracteres válidos es 2 - 180')
        v.name.isEmpty('Se requiere ingresar el nombre')
        v.category_id.isEmpty('Se requiere seleccionar una categoría')
    })

    observer(data => ({
        name: data.name,
        category_id: parseInt(data.category_id.value)
    }))

    return formSubcategory.useModal(Form, {
        title: subCategoryId ? 'Editar Subcategoría' : 'Nueva Subcategoría',
        onSubmit: submit,
        size: 'sm',
        isCentered: true
    })
}
