export default function AllInbox(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.833.5H2.167C1.25.5.5 1.25.5 2.167V8c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V2.167C15.5 1.25 14.75.5 13.833.5zM2.167 6.333h2.608c.175.65.558 1.225 1.058 1.667H2.167V6.333zm8 1.667h3.666V6.333h-2.608A3.438 3.438 0 0110.167 8zm3.666-3.333H9.667V5.5c0 .892-.775 1.667-1.667 1.667S6.333 6.392 6.333 5.5v-.833H2.167v-2.5h11.666v2.5zM9.667 10.5H15.5v3.333c0 .917-.75 1.667-1.667 1.667H2.167C1.25 15.5.5 14.75.5 13.833V10.5h5.833v.833c0 .392.159.75.4 1.042C7.042 12.75 7.5 13 8 13c.5 0 .958-.25 1.267-.625.241-.292.4-.65.4-1.042V10.5zm-7.5 1.667h2.608c.012.053.032.101.051.15.009.019.017.038.024.058.2.567.542 1.067.983 1.458H2.167v-1.666zm8 1.666h3.666v-1.666h-2.608c-.012.053-.032.101-.051.15-.009.019-.017.038-.024.058a3.363 3.363 0 01-.983 1.458z"
            />
        </svg>
    )
}
