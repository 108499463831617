import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import Provider from '../../../../models/Provider'
import {
    formInformation,
    reqCatalogueCompany,
    reqProviders
} from '../../storage'

export default function useProvider() {
    const { props } = formInformation.useSelect('provider')
    const { data, props: propsSearch } = useGet<Provider[]>(
        reqProviders.observer(`/v5.6/apis/providers?limit=20`, true),
        []
    )

    function handleChange(option: Option) {
        if (option.value === '0') {
            formInformation.setProps('catalogue_company', {
                disabled: true
            })
        } else {
            reqCatalogueCompany.setQuery('provider_id', option.value)
            reqCatalogueCompany.load()
            formInformation.setProps('catalogue_company', { disabled: false })
        }
        formInformation.setValue('catalogue_company', null)
        props.onChange(option)
    }

    // return {
    //     props,
    //     handleChange,
    //     providers: data.filter(provider => provider.is_enabled)
    // }
    return {
        props,
        handleChange,
        providers: data.result.filter(provider => provider.is_enabled),
        propsSearch
    }
}
