import Typography from '../../../components/Typography'
import { GetPurchase } from '../type'
import buildNumberFormat from '../../../utilities/buildNumberFormat'

export default function ProductsToRequest({ data }: { data: GetPurchase }) {
    function getCodeSubStowages(
        minimal_stowage: GetPurchase['purchase_products'][number]['requisition_product']['classic_requisition_products'][number]['requisition']['minimal_stowage']
    ) {
        let code = ''

        if (minimal_stowage.client_sub_stowages.length > 0) {
            code = minimal_stowage.client_sub_stowages[0].client.name
        }

        if (minimal_stowage.order_sub_stowages.length > 0) {
            code =
                minimal_stowage.order_sub_stowages[0].order.number +
                ' - ' +
                minimal_stowage.order_sub_stowages[0].order.client?.name
        }

        if (minimal_stowage.gp_sub_stowages.length > 0) {
            code = minimal_stowage.gp_sub_stowages[0].purchase.code
        }

        return code
    }

    const mount = data.purchase_products
        .filter(pp => pp.is_enabled && !pp.deleted)
        .map(pp => pp.quantity * pp.unit_purchase_price)
        .reduce((a, b) => a + b, 0)

    return (
        <div className="body__tables">
            <div className="column">
                <div className="card_info">
                    <h3>REQUISICIONES DE COMPRA</h3>
                    <table className="table_info">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Requisición</th>
                                <th>Bodega destino</th>
                                <th>Descripción de producto (Proveedor)</th>
                                <th>Cant. solicitada en RQ</th>
                                <th>Cant. a comprar</th>
                                <th>Precio proveedor</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.purchase_products
                                .filter(x => x.is_enabled)
                                .map((pp, i) => (
                                    <tr key={pp.id}>
                                        <td>{i + 1}</td>
                                        <td>
                                            {pp.requisition_product.classic_requisition_products
                                                .map(
                                                    crp => crp.requisition.code
                                                )
                                                .join(', ')}
                                        </td>
                                        <td
                                            className={
                                                pp.requisition_product
                                                    .classic_requisition_products[0]
                                                    ?.requisition
                                                    .minimal_stowage?.stowage
                                                    ?.is_enabled === null
                                                    ? 'line-through'
                                                    : ''
                                            }
                                        >
                                            <p>
                                                {pp.requisition_product.classic_requisition_products
                                                    .map(
                                                        crp =>
                                                            crp.requisition
                                                                .minimal_stowage
                                                                .stowage.name
                                                    )
                                                    .join(', ')}
                                            </p>
                                            <Typography
                                                type="small"
                                                color="gray"
                                            >
                                                {pp.requisition_product.classic_requisition_products
                                                    .map(crp =>
                                                        getCodeSubStowages(
                                                            crp.requisition
                                                                .minimal_stowage
                                                        )
                                                    )
                                                    .join(', ')}
                                            </Typography>
                                        </td>
                                        <td>
                                            <p>
                                                {
                                                    pp.requisition_product
                                                        .product_provider_company
                                                        .provider_description
                                                }
                                            </p>
                                            <Typography
                                                type="small"
                                                color="gray"
                                            >
                                                {
                                                    pp.requisition_product
                                                        .product_provider_company
                                                        .provider_code
                                                }
                                            </Typography>
                                        </td>
                                        <td>
                                            {pp.requisition_product.quantity}
                                        </td>
                                        <td>{pp.quantity}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            {data.currency.symbol}{' '}
                                            {buildNumberFormat(
                                                pp.unit_purchase_price
                                            )}
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            {data.currency.symbol}{' '}
                                            {buildNumberFormat(
                                                pp.unit_purchase_price *
                                                    pp.quantity
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                        <tfoot className="tfoot">
                            <tr>
                                <td
                                    colSpan={7}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Total en OC
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {data.currency.symbol}{' '}
                                    {buildNumberFormat(mount)}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}
