import ControlList from '../../utilities/Control/ControlList'
import ControlTabs from '../../utilities/ControlTabs'
import { Keys, tHeadsDrafts } from './constants'
import { LogisticalDeclaratioData, StoragePage } from './types'

export const controlTabs = new ControlTabs()

export const controlList = new ControlList<LogisticalDeclaratioData>(
    Keys.MAIN,
    {
        path: '/v5.6/apis/logistical_declarations',
        theads: tHeadsDrafts
    }
)

export const storagePage: StoragePage = {
    company_enabled: false,
    currency: '',
    originalCompanies: [],
    workingPeriods: []
}
