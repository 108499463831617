import BtnActionTable from '../../components/BtnActionTable'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import Option from '../../components/Option'
import { del } from '../../utilities/http'
import { Exception } from '../../utilities/http/type'
import Notify from '../../utilities/Notify'
import { Popup } from '../../utilities/popup'
import { Progress } from '../../utilities/progress'
import { PropsDelete } from './types'

function expectError(error: Exception) {
    if (error.type === 'dependency' || error.type === 'development') {
        const errors = Object.values(error.errors)
        const message =
            errors.length > 0
                ? errors[0].description
                : 'Ni idea del porque el error xD'
        Popup.error('Lo sentimos', message)
    } else {
        Popup.ohno(
            'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
        )
    }
}

export default function Delete({
    control,
    path,
    title = 'Eliminar Registro',
    children,
    loadingMessage = 'Eliminando registro',
    disabled = false,
    label,
    accept,
    type = 'option'
}: PropsDelete) {
    async function handleDelete() {
        const result = await Popup.delete(title, children, accept)
        if (result) {
            Progress.open(loadingMessage)
            const response = await del(path)
            if (response.data) {
                if (typeof control === 'function') {
                    await control()
                } else {
                    await control.done()
                    control.renderMain()
                }
                Notify.success('La operación se ha completado exitosamente')
                Progress.close()
            } else {
                Progress.close()
                expectError(response.error)
            }
        }
    }
    return (
        <>
            {!label && (
                <BtnActionTable
                    title="Eliminar"
                    iconName="bs-trash"
                    onClick={() => handleDelete()}
                    disabled={disabled}
                />
            )}
            {label && (
                <>
                    {type === 'option' && (
                        <Option
                            disabled={disabled}
                            value="0"
                            label={label}
                            icon="bs-trash"
                            onClick={() => handleDelete()}
                        />
                    )}
                    {type === 'button' && (
                        <Button
                            onClick={() => handleDelete()}
                            color="error"
                            size="sm"
                            disabled={disabled}
                        >
                            <Icon size={14} name="bs-trash" className="mr-1" />{' '}
                            {label}
                        </Button>
                    )}
                </>
            )}
        </>
    )
}
