import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import ControlRequest from '../../utilities/ControlRequest'
import { ReceptionData, StoragePage } from './type'
import ControlTabs from '../../utilities/ControlTabs'
import moment from 'moment'

export const controlList = new ControlList<ReceptionData>(Keys.MAIN, {
    path: '/v5.6/apis/receptions',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'type_reception', label: 'Tipo de RC' },
        { value: 'user_name', label: 'Usuario' },
        { value: 'warehouse_name', label: 'Almacén' },
        { value: 'provider_name', label: 'Proveedor' },
        { value: 'oc', label: 'OC' },
        { value: 'type_oc', label: 'Tipo de OC' },
        { value: 'reception_date', label: 'Fecha de recepción' },
        // 'Estado',
        'Acciones'
    ]
})
export const reqSummaryData = new ControlRequest()
controlList.badgeRemove('start_date', false)
controlList.badgeRemove('end_date', false)
export const filterForm = new FormEvents<any>(Keys.FILTER_FORM, {
    warehouse_id: null,
    type_reception_id: null,
    type_purchase_id: null,
    provider_id: null,
    start_date: null,
    end_date: null
})

export const reqWarehouse = new ControlRequest()

export const storagePage: StoragePage = {
    reception: null,
    company_enabled: false,
    timezone: ''
}

export const ctsView = new ControlTabs()

controlList.badgeRemove('company_id', false)
