import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import useSubStowageDestiny from './useSubStowageDestiny'
import useProvider from './useProvider'
import useCatalogueCompany from './useCatalogueCompany'

function SubStowage() {
    const { props, data, handleChange, propsSearch } = useSubStowageDestiny()

    return (
        <FormControl label="Sub bodega destino" required>
            <SelectTouch
                {...props}
                {...propsSearch}
                placeholder="Seleccionar sub bodega"
                onChange={handleChange}
            >
                <IterateOptions data={data.result} label="substowage_name" />
            </SelectTouch>
        </FormControl>
    )
}

function ProviderField() {
    const { props, providers, handleChange, propsSearch } = useProvider()

    return (
        <FormControl label="Proveedor" required>
            <SelectTouch
                {...props}
                {...propsSearch}
                placeholder="Seleccionar proveedor"
                onChange={handleChange}
            >
                <IterateOptions data={providers} label="commercial_name" />
            </SelectTouch>
        </FormControl>
    )
}

function CatalogueCompany() {
    const { props, catalogueCompanies } = useCatalogueCompany()

    return (
        <FormControl label="Catálogo de proveedor" required>
            <SelectTouch
                {...props}
                placeholder="Seleccionar catálogo de proveedor"
            >
                <IterateOptions
                    data={catalogueCompanies}
                    label="name"
                    image="image"
                />
            </SelectTouch>
        </FormControl>
    )
}
export default function Fields2() {
    return (
        <>
            <SubStowage />
            <ProviderField />
            <CatalogueCompany />
        </>
    )
}
