import Button from '../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import { storagePage } from '../../storage'
import useSubmitAvailability from '../../useSubmitAvailability'
import TableCompanies from './TableCompanies'
import TablePermits from './TablePermits'
import TableTaxes from './TableTaxes'
import TableFiscalCode from './TableFiscalCode'

export default function Step3() {
    const submit = useSubmitAvailability()
    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Compañía">
                    {storagePage.mode === 'edition' && (
                        <Button size="sm" color="accent" onClick={submit}>
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TableCompanies />
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Código fiscal">
                    {storagePage.mode === 'edition' && (
                        <Button size="sm" color="accent" onClick={submit}>
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TableFiscalCode />
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Impuesto de valor agregado">
                    {storagePage.mode === 'edition' && (
                        <Button size="sm" color="accent" onClick={submit}>
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TableTaxes />
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Permisos requeridos por país">
                    {storagePage.mode === 'edition' && (
                        <Button size="sm" color="accent" onClick={submit}>
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TablePermits />
                </PanelBody>
            </Panel>
        </>
    )
}
