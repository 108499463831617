import ContainerPage from '../../../components/ContainerPage'
import { Page500Container, Title, P } from './Page500.style'
import Button from '../../../components/Button'
import IconNotFound from './IconNotFound'
import Panel, { PanelBody } from '../../../components/Panel'
import { useHistory, Redirect, useLocation } from 'react-router-dom'

export default function Page404() {
    const { push } = useHistory()
    const { pathname } = useLocation()
    return (
        <ContainerPage>
            {pathname === '/' && <Redirect to={'/prices'} />}
            <Panel>
                <PanelBody>
                    <div className="flex items-center justify-center py-12">
                        <Page500Container>
                            <IconNotFound />
                            <Title>No logramos encontrar lo que buscas</Title>
                            <P>
                                Algo sucedio y no logramos encontrar la página
                                que estás buscando. <br />
                                Por favor intenta volviendo al inicio
                            </P>
                            <Button
                                color="primary"
                                className="mt-8"
                                onClick={() => push('/')}
                            >
                                Ir al inicio
                            </Button>
                        </Page500Container>
                    </div>
                </PanelBody>
            </Panel>
        </ContainerPage>
    )
}
