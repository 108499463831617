import { Option } from '../../../../../config/types/component'
import useStorage from '../../../../../hooks/useStorage'
import { formOC } from '../../../storage'

export default function useShippingWay() {
    const { props } = formOC.useSelect('shipping_way')
    const { shippingMethodPurchases } = useStorage()

    function handleChange(option: Option) {
        props.onChange(option)
    }

    return {
        props,
        shippingMethodPurchases,
        handleChange
    }
}
