import { render } from 'redity'
import {
    Empty,
    FormControl,
    FormControls,
    IterateOptions
} from '../../../../../components/FormUtilities'
import Option from '../../../../../components/Option'
import SelectTouch from '../../../../../components/SelectTouch'
import { Option as OptionType } from '../../../../../config/types/component'
import useStorage from '../../../../../hooks/useStorage'
import LogisticalConcept from '../../../../../models/LogisticalConcept'
import { formCostGeneral } from '../storageStep3'

function Concept() {
    const { props, data, getError } =
        formCostGeneral.useSelect<LogisticalConcept>(
            'logistical_concept',
            '/v5.6/apis/search_logistical_concepts?is_enabled=true&non_logistical_concept_ids=1,2,3,4,5,6,7,8,9,10,11'
        )
    const helper2 = getError(
        'ERROR-0000102',
        'El concepto de pago ya está siendo usado en el formulario',
        'logistical_concept_id'
    )
    return (
        <FormControl label="Concepto" required col="span-3">
            <SelectTouch
                {...props}
                helper={props.helper || helper2}
                placeholder="Seleccionar concepto"
                disabled={formCostGeneral.store().logistical_cost_id !== null}
            >
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Currency() {
    const { props } = formCostGeneral.useSelect('currency')
    const { currencies } = useStorage()

    function handleChange(option: OptionType) {
        props.onChange(option)
        render(formCostGeneral.keyRender, 'original_cost')
        render(formCostGeneral.keyRender, 'exchange_to_local')
    }

    return (
        <FormControl label="Moneda" required col="span-3">
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar moneda"
            >
                {currencies.map(currency => (
                    <Option
                        key={currency.id}
                        label={`${currency.name} (${currency.symbol})`}
                        value={currency.id.toString()}
                    />
                ))}
                <Empty data={currencies} />
            </SelectTouch>
        </FormControl>
    )
}

export default function Selects() {
    return (
        <FormControls className="mb-4">
            <Concept />
            <Currency />
        </FormControls>
    )
}
