export default function DeleteSweep(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 42 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5.836h8.333l2.084 1.96h6.25v3.917h-25V2.795h6.25L8.5.836zM6.417 32.181c-2.292 0-4.167-1.763-4.167-3.918V8.673h20.833v19.59c0 2.155-1.875 3.918-4.166 3.918h-12.5zM41.833 8.673H27.25v3.918h14.583V8.673zm-6.25 15.672H27.25v3.918h8.333v-3.918zm-8.333-7.836h12.5v3.918h-12.5v-3.918zM6.417 12.59h12.5v15.672h-12.5V12.591z"
            />
        </svg>
    )
}
