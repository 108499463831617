import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { FormRole, RoleData } from './types'

export const controlList = new ControlList<RoleData>(Keys.MAIN, {
    path: '/v5.6/apis/roles',
    theads: [
        '#',
        { value: 'name', label: 'Rol' },
        { value: 'department_name', label: 'Departamento' },
        'N° Permisos',
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})

export const formRoles = new FormEvents<FormRole>('asf', {
    name: '',
    department_id: null
})
