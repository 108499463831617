import { render, renders } from 'redity'
import {
    FormControl,
    FormControls
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import NumeralField from '../../../../../components/NumeralField'
import useStorage from '../../../../../hooks/useStorage'
import { Keys } from '../../../constants'
import { storagePage } from '../../../storage'
import { formCostByProduct, tableCostByProductControl } from '../storageStep3'
import { runOperationFields } from './utilities'

function PurchaseCost1() {
    const { props } = formCostByProduct.useInput('cost')
    const { currency } = formCostByProduct.store()
    const { currencies } = useStorage()

    function getSymbol() {
        return currencies.find(c => c.id.toString() === currency.value).symbol
    }

    return (
        <FormControl
            label={`Monto de concepto (${currency ? getSymbol() : '?'})`}
            col="span-2"
        >
            <InputField {...props} disabled />
        </FormControl>
    )
}

function ExchangeRate() {
    const { props } = formCostByProduct.useInput('exchange_to_local')
    const { symbol } = storagePage.currentCurrency
    const currentSymbol = useGetCurrentSymbol()

    function handleChange(ev) {
        props.onChange(ev)
        runOperationFields()
        renders(tableCostByProductControl.keyRender)
        render(Keys.TABLE_DISTRIBUTION_BY_PRODUCT_SPECIFICATIONS)
    }

    return (
        <FormControl
            label={`Tipo de cambio (${currentSymbol} a ${symbol})`}
            required
            col="span-2"
        >
            <NumeralField {...props} onChange={handleChange} decimal={4} />
        </FormControl>
    )
}

function PurchaseCost2() {
    const { props } = formCostByProduct.useInput('total_cost')

    return (
        <FormControl
            label={`Monto de concepto (${storagePage.currentCurrency.symbol})`}
            col="span-2"
        >
            <InputField {...props} disabled />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <FormControls className="mb-4">
            <PurchaseCost1 />
            <ExchangeRate />
            <PurchaseCost2 />
        </FormControls>
    )
}

function useGetCurrentSymbol() {
    const { currency } = formCostByProduct.store()
    const { currencies } = useStorage()

    const currentCurrency = currencies.find(
        c => c.id.toString() === currency?.value
    )

    return currentCurrency ? currentCurrency.symbol : '?'
}
