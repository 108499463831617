const RegexTypes = {
    onlyNumber: /[^0-9]/,
    alphaNumber: /[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_ .,/-]*$/g,
    code: /[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_ -.,]*$/g,
    url: /[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_ .,:/=?&]*$/g,
    search: /[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_ .,-]*$/g,
    address: /([^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ.,\s])*$/g,
    phone: /[^0-9+\-() ]/g,
    email: /[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_ .,@]*$/g
}

export default RegexTypes
