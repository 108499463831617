import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import FilterCosts from './FilterCosts'
import { controlList, controlTabs } from '../storage'
import { tHeadsDrafts, tHeadsCosting } from '../constants'
import TableDrafts from './TableDrafts'

controlTabs.setSection('Borradores', TableDrafts)
controlTabs.setSection('Costeos', TableDrafts)

export default function Costs() {
    const state = useInitial()
    const value = controlTabs.useStep(to => {
        if (to === 'step1') {
            controlList.thead = tHeadsDrafts
            controlList.filter.setParams('has_document', false)
            controlList.done().then(() => {
                controlList.renderMain()
            })
        }
        if (to === 'step2') {
            controlList.thead = tHeadsCosting
            controlList.filter.setParams('has_document', true)
            controlList.done().then(() => {
                controlList.renderMain()
            })
        }

        return true
    })

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterCosts />
                    <controlTabs.SectionTabs
                        value={value}
                        type="tab"
                        mode="simple"
                        className="mb-4 mt-2"
                    />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
