import classNames from 'classnames'
import { PropsFormControls } from './types'

export default function FormControls({
    children,
    className = ''
}: PropsFormControls) {
    return (
        <div
            className={classNames(
                'grid grid-cols-6 gap-x-16 gap-y-3',
                className
            )}
        >
            {children}
        </div>
    )
}
