import { get } from '../../../utilities/http'
import { listProductProviderCompany } from '../storage'
import { GetProductCompany } from '../types'

export default async function loadProductProviderCompany(productCompanyId) {
    const { data, error } = await get<GetProductCompany>(
        `/v5.6/apis/product_companies/${productCompanyId}/principal_provider_information`
    )
    if (error) return null
    const { product_provider_companies } = data.result

    listProductProviderCompany.setList(
        product_provider_companies
            .filter(
                item =>
                    item.provider_company.is_enabled &&
                    item.provider_company.provider.is_enabled
            )
            .map(ppc => ({
                id: ppc.provider_company.provider_id,
                name: ppc.provider_company.provider.commercial_name,
                currency: ppc.company_currency.currency.symbol,
                purchase_price: ppc.purchase_price,
                is_main: ppc.is_main,
                country_image: ppc.provider_company.provider.country.image_url
            }))
    )
    return data.result
}
