import Col from '../../../../../components/Col'
import DataTable, { TBody, THead } from '../../../../../components/DataTable'
import Typography from '../../../../../components/Typography'
import Ul from '../../../../../components/Ul'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { formProduct, storagePage } from '../../../storage'

export default function TableProducts() {
    const { product, inventory } = formProduct.store()
    const items = []
    if (inventory.length !== 0) {
        inventory.forEach(item =>
            item.logical_products.forEach(logical_product => {
                if (
                    logical_product.minimal_stowage.stowage.type_stowage
                        .is_inventory === storagePage.is_inventory
                ) {
                    const name = logical_product.minimal_stowage.stowage.name
                    let subStowage = ''
                    if (logical_product.minimal_stowage.client_sub_stowage) {
                        subStowage =
                            logical_product.minimal_stowage.client_sub_stowage
                                .client.name
                    }

                    if (logical_product.minimal_stowage.order_sub_stowage) {
                        subStowage =
                            logical_product.minimal_stowage.order_sub_stowage
                                .order.number
                    }

                    items.push({
                        presentation: item.presentation_product
                            ? item.presentation_product.amount
                            : '-',
                        quantity: logical_product.quantity_available,
                        name,
                        subStowage
                    })
                }
            })
        )
    }
    return (
        <>
            <Typography className="mb-4">
                Información del producto por proveedor
            </Typography>
            <div className="grid gap-4">
                <Ul>
                    <li>
                        Descripción de proveedor:{' '}
                        <b>
                            {product !== null
                                ? product.provider_description
                                : '-'}
                        </b>
                    </li>
                </Ul>
                <Ul>
                    <Col>
                        <li>
                            Costo proveedor:
                            <b>
                                {' '}
                                {product !== null
                                    ? `${
                                          product.company_currency.currency
                                              .symbol
                                      } ${buildNumberFormat(
                                          product.purchase_price
                                      )}`
                                    : '-'}
                            </b>
                        </li>
                        <li>
                            Código proveedor:{' '}
                            <b>
                                {' '}
                                {product !== null ? product.provider_code : '-'}
                            </b>
                        </li>
                    </Col>
                </Ul>
            </div>

            <Typography className="mb-4">
                Inventario actual del producto
            </Typography>
            <DataTable scrollHeight={250}>
                <THead>
                    <tr>
                        <th>Información de inventario</th>
                        <th>Presentación</th>
                        <th>Cantidad</th>
                    </tr>
                </THead>
                <TBody>
                    {items.length === 0 ? (
                        <tr>
                            <td colSpan={3} className="text-center">
                                No se encontraron resultados
                            </td>
                        </tr>
                    ) : (
                        items.map((item, id) => (
                            <tr key={id}>
                                <td>
                                    <p>{item.name}</p>
                                    {item.subStowage && (
                                        <p>{item.subStowage}</p>
                                    )}
                                </td>
                                <td>{item.presentation}</td>
                                <td>{item.quantity}</td>
                            </tr>
                        ))
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
