import DropImage from '../../../components/DropImage'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import SelectTouch from '../../../components/SelectTouch'
import { Option as OptionType } from '../../../config/types/component'
import useStorage from '../../../hooks/useStorage'
import RegexTypes from '../../../utilities/regexTypes'
import { formWarehouse } from '../storage'

function SelectCountry() {
    const { countriesAdvanceActiveCompany } = useStorage()

    const { props, getError } = formWarehouse.useSelect('country')
    const helper = getError()

    function handleChange(option: OptionType) {
        props.onChange(option)

        if (
            formWarehouse.store().country &&
            option.value !== formWarehouse.store().country.value
        ) {
            formWarehouse.setValue('companies', [])
        }
        if (option.value === '0') {
            formWarehouse.setProps('companies', { disabled: true })
        }
        formWarehouse.setValue('companies', [], false)
        formWarehouse.setProps('companies', { disabled: false }, false)
        formWarehouse.runSearch('companies')
    }

    return (
        <FormControl label="País" required>
            <SelectTouch
                {...props}
                placeholder="Seleccionar país"
                onChange={handleChange}
                helper={helper || props.helper}
            >
                <IterateOptions
                    data={countriesAdvanceActiveCompany}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}

function Image() {
    const { props, getError } = formWarehouse.useImage('image_url')
    const helper = getError()

    return (
        <FormControl label="Foto" row="span-3">
            <DropImage {...props} helper={helper || props.helper} />
        </FormControl>
    )
}

function Code() {
    const { props, getError } = formWarehouse.useInput('code')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un almacén con el mismo código'
    )
    return (
        <FormControl required label="Código de almacén">
            <InputField
                {...props}
                regex={RegexTypes.code}
                placeholder="Ingresar código de almacén"
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <SelectCountry />
            <Image />
            <Code />
        </>
    )
}
