import moment from 'moment'
import { InitialStorage } from '../../../hooks/useStorage'
import { parseDriveFile } from '../../../utilities/fileTools'
import { storagePage } from '../storage'
import { ResponseLD } from '../types'
import {
    formGeneral,
    reqWorkingPeriod
} from '../View/SectionGeneralInformation/storageStep1'

export default function fillForm(
    logistical_declaration: ResponseLD,
    companiesFromSession: InitialStorage['companiesFromSession']
) {
    const company = companiesFromSession.find(
        _company => _company.id === logistical_declaration.company_id
    )
    formGeneral.init({
        company: {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        },
        name: logistical_declaration.name,
        declaration_number: logistical_declaration.number_declaration,
        declaration_date: moment(
            logistical_declaration.date_declaration,
            'YYYY-MM-DD'
        ).toISOString(true),
        working_period: {
            value: logistical_declaration.working_period_id.toString(),
            label: moment(
                `01/${logistical_declaration.working_period.month}/${logistical_declaration.working_period.year}`,
                'DD/MM/YYYY'
            ).format('MMMM YYYY')
        },
        agent: {
            value: logistical_declaration?.agent?.id.toString() || null,
            label: logistical_declaration?.agent?.name || ''
        },
        type_shipment: {
            value: logistical_declaration?.type_shipment_id.toString() || null,
            label: logistical_declaration?.type_shipment.name || ''
        },
        files: logistical_declaration.files.map(file => parseDriveFile(file)),
        comment: logistical_declaration.description
    })

    formGeneral.setProps('declaration_date', {
        maxDate: moment().tz(company.timezone).toISOString(true)
    })

    storagePage.currentCurrency = company.local_currency
    reqWorkingPeriod.setQuery('company_id', company.id)
}
