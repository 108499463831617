import fter, { parse } from 'fter'
import { Option } from '../../../config/types/component'
import { Pagination } from '../../../config/types/entities'

export interface Params {
    page: number
    search: string
    order: 'asc' | 'desc'
    sort: string
    limit: number
}

export type ParamsExtends = Params & Record<string, any>

export interface FilterTools {
    params: ParamsExtends
    setOption: (key: string, option: Option) => void
    getBadges: () => Array<Option & { key: string; remove: boolean }>
    getOption: (key: string) => Option
    deleteFilter: (key: string) => void
    pagination: Pagination
    setPagination: (Pagination) => void
    fullPath: () => string
    restart: () => void
    enumeration: (index: number) => number
    setPath: (path: string) => void
    changeParams: (paramName: keyof Params, value: Params[keyof Params]) => void
    setParams: (paramName: string, value: string | number | boolean) => void
    getParam: (paramName: string) => any
    badgeRemove: (paramName: string, active: boolean) => void
}

export default function filterTools(path: string): FilterTools {
    let currentPath = path
    const params: ParamsExtends = {
        page: 1,
        sort: 'name',
        order: 'asc',
        search: '',
        limit: 10
    }

    const pagination: Pagination = {
        page: 1,
        pages: 1,
        total: 0
    }

    const configBadge = {}

    const options: Record<string, Option> = {}

    function setOption(key: string, option: Option) {
        params[key] = option.value
        options[key] = option
    }

    function changeParams<P extends keyof Params>(
        paramName: P,
        value: Params[P]
    ) {
        params[paramName] = value
    }

    function setPath(pathString: string) {
        currentPath = pathString
    }

    function getOption(key): Option {
        return options[key] || null
    }

    function getBadges() {
        return Object.keys(options).map(key => ({
            ...options[key],
            key,
            remove: configBadge[key] === undefined ? true : configBadge[key]
        }))
    }

    function deleteFilter(key: string) {
        delete params[key]
        delete options[key]
    }

    function fullPath(): string {
        let attrs = fter({ ...params, search: params.search.trim() })`
            page
            search?
            order?asc
            sort?id
            limit
        `

        const nextParams = { ...params }
        delete nextParams.limit
        delete nextParams.order
        delete nextParams.page
        delete nextParams.search
        delete nextParams.sort
        if (attrs === '') {
            attrs = parse(nextParams)
        } else {
            const filter = parse(nextParams)
            const [, moreAttr] = filter.split('?')
            const nextAttrs = moreAttr ? `&${moreAttr}` : ''
            attrs = `${attrs}${nextAttrs}`
        }

        return `${currentPath}${attrs}`
    }

    function restart() {
        params.page = 1
        params.sort = 'name'
        params.order = 'asc'
        params.search = ''
        params.limit = 10

        for (const key in params) {
            if (
                !(
                    key === 'page' ||
                    key === 'sort' ||
                    key === 'order' ||
                    key === 'search' ||
                    key === 'limit'
                )
            ) {
                delete params[key]
            }
        }

        for (const key in options) {
            delete options[key]
        }
    }

    function enumeration(index: number) {
        return index + 1 + params.limit * (pagination.page - 1)
    }

    function setPagination(responsePagination: Pagination) {
        if (responsePagination) {
            pagination.page = responsePagination.page
            pagination.pages = responsePagination.pages
            pagination.total = responsePagination.total
        }
    }

    function setParams(paramName: string, value: string | number) {
        params[paramName] = value
    }

    function getParam(paramName: string) {
        return params[paramName]
    }

    function badgeRemove(paramName: string, active: boolean) {
        configBadge[paramName] = active
    }

    return {
        params,
        setOption,
        getOption,
        getBadges,
        deleteFilter,
        pagination,
        fullPath,
        restart,
        enumeration,
        setPagination,
        changeParams,
        setPath,
        setParams,
        getParam,
        badgeRemove
    }
}
