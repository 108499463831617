import BtnActionTable from '../../../components/BtnActionTable'
import DataTable, { TBody, THead } from '../../../components/DataTable'
import buildCompanyName from '../../../utilities/buildCompanyName'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { SubcategoryValuesData } from '../types'
import useSubmitFactor from '../useSubmitFactor'

function Edition({
    subcategoryValueId,
    disabled
}: {
    subcategoryValueId: number
    disabled: boolean
}) {
    const openModal = useSubmitFactor(subcategoryValueId)

    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={() => openModal()}
            disabled={disabled}
        />
    )
}

export default function TableDiscountFactor({
    subcategoryValue
}: {
    subcategoryValue: SubcategoryValuesData
}) {
    return (
        <DataTable className="mb-8">
            <THead>
                <tr>
                    <th>Compañía</th>
                    <th>Factor subcategoría</th>
                    <th>Descuento %</th>
                    <th className="cell-actions">Acciones</th>
                </tr>
            </THead>
            <TBody>
                <tr>
                    <td>
                        <div className="flex items-center">
                            <img
                                src={subcategoryValue.company.country.image_url}
                                className="w-8 mr-2"
                            />
                            <p
                                className={`${
                                    !subcategoryValue.company.is_enabled
                                        ? 'line-through'
                                        : ''
                                }`}
                            >
                                {buildCompanyName(
                                    subcategoryValue.company.name
                                )}
                            </p>
                        </div>
                    </td>
                    <td>{buildNumberFormat(subcategoryValue.factor)}</td>
                    <td>{buildNumberFormat(subcategoryValue.discount)}</td>
                    <td>
                        <div className="group-btn-options">
                            <Edition
                                subcategoryValueId={subcategoryValue.id}
                                disabled={!subcategoryValue.company.is_enabled}
                            />
                        </div>
                    </td>
                </tr>
            </TBody>
        </DataTable>
    )
}
