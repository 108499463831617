import { render } from 'redity'
import FormEvents from '../../../../../utilities/FormEvents'
import { Keys } from '../../../constants'
import Cost from '../cost'
import { formDutyISCIVA, tableIVAControl } from '../storageStep2'

export default function useIvaRow(control: FormEvents<Cost.ModalTableIva>) {
    const { props } = control.useInput('iva_percent')
    const data = control.store()

    const tooltip = props.helper

    function handleChange(ev) {
        props.onChange(ev)
        const original_cost = tableIVAControl
            .getDataList()
            .reduce(function (a, b) {
                return a + (b.total / 100) * Number(b.iva_percent)
            }, 0)
        formDutyISCIVA.setValue('original_cost', original_cost.toFixed(2))
        const local_cost = tableIVAControl
            .getDataList()
            .reduce(function (a, b) {
                return a + (b.local_total / 100) * Number(b.iva_percent)
            }, 0)
        formDutyISCIVA.setValue('total_cost', local_cost.toFixed(2))
        const { setting_local_cost } = formDutyISCIVA.store()
        formDutyISCIVA.setValue(
            'final_local_cost',
            (local_cost + Number(setting_local_cost)).toFixed(2)
        )
        render(Keys.TABLE_STEP_2_IVA_SPECIFICATIONS)
    }

    return {
        data,
        props,
        handleChange,
        tooltip
    }
}
