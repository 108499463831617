import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR, textBold } from './utils'

export function getRowHeaderCosts(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [{ value: '' }]
    data.forEach(() => {
        row.push({
            value: 'Costo original'
        })
        row.push({
            value: 'Tipo de cambio'
        })
        row.push({
            value: 'Agente - Nro doc.'
        })
        row.push({
            value: 'Costo local'
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin, textBold])
}
