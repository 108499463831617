import classNames from 'classnames'
import { createPortal } from 'react-dom'
import './popover.css'
import { PropsPopover } from './types'
import usePopover from './usePopover'

export default function Popover({
    component: Icon,
    title,
    children,
    color = 'info',
    position = 'bottom',
    className,
    element = '.datatable'
}: PropsPopover) {
    const {
        iconRef,
        handleMouseEnterTooltip,
        handleMouseLeaveIcon,
        handleMouseOverIcon,
        tooltipRef,
        active,
        handleMouseLeaveTooltip
    } = usePopover(position, element)

    return (
        <div className={`relative w-min inline-block ${className}`}>
            <div
                ref={iconRef}
                className={`w-min`}
                onMouseOver={handleMouseOverIcon}
                onMouseLeave={handleMouseLeaveIcon}
            >
                {Icon}
            </div>
            {createPortal(
                <div
                    ref={tooltipRef}
                    className={classNames(
                        `text-2xs bg-white rounded-lg shadow-xl z-50 transition-all delay-100 ease-in-out absolute top-0 left-0 triangle triangle-${position} color-${
                            title ? color : 'white'
                        } `,
                        {
                            'opacity-1 transform transform-y-0': active,
                            'opacity-0 transform translate-y-2 invisible':
                                !active
                        }
                    )}
                    onMouseEnter={handleMouseEnterTooltip}
                    onMouseLeave={handleMouseLeaveTooltip}
                >
                    {title && (
                        <div
                            className={classNames(
                                `bg-${color} text-white px-3 py-2 flex items-center justify-center rounded-t-lg`
                            )}
                        >
                            <p>{title}</p>
                        </div>
                    )}
                    <div
                        className={classNames(
                            ` px-4 py-2 border border-gray-300 rounded-b-lg`,
                            {
                                'rounded-t-lg': !title
                            }
                        )}
                    >
                        {children}
                    </div>
                </div>,
                document.querySelector('#tooltip-contain')
            )}
        </div>
    )
}
