import { SVGProps } from 'react'

const LocalShipping = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.667 4.167h-2.5V.833H2.5C1.583.833.833 1.583.833 2.5v9.167H2.5c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5h5c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5h1.667V7.5l-2.5-3.333Zm-.417 1.25L17.883 7.5h-3.716V5.417h2.083Zm-12.083 6.25c0 .458.375.833.833.833a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.833.836.836 0 0 0-.833.833ZM6.85 10A2.475 2.475 0 0 0 5 9.167c-.742 0-1.392.325-1.85.833H2.5V2.5h10V10H6.85Zm7.317 1.667c0 .458.375.833.833.833a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.833.836.836 0 0 0-.833.833Z"
        />
    </svg>
)

export default LocalShipping
