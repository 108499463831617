import classNames from 'classnames'
import { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { DRIVE_DOWNLOAD } from '../../config/app'
import { getIconFile } from '../../utilities/fileTools'
import { generateId } from '../../utilities/generateId'
import Icon from '../Icon'
import { PropsItem } from './types'

export default function Item({ name, driveId, onDelete, disabled }: PropsItem) {
    const src = getIconFile(name)
    const href = driveId ? `${DRIVE_DOWNLOAD}${driveId}` : '#'
    const [id] = useState(() => generateId())
    return (
        <>
            <div className="relative flex" data-for={id} data-tip={name}>
                <a
                    href={href}
                    download={name}
                    className="mr-4 rounded-full border-2 w-9 h-9 flex items-center justify-center"
                >
                    <img src={src} className="w-5" />
                </a>
                {!disabled && (
                    <div
                        className={classNames(
                            'rounded-full w-4 h-4  right-2 absolute flex items-center justify-center',
                            {
                                'text-white bg-error cursor-pointer': driveId,
                                'bg-gray-200': !driveId,
                                'bottom-0': !driveId,
                                'top-0': driveId
                            }
                        )}
                    >
                        <Icon
                            name={driveId ? 'close' : 'loading'}
                            pointer
                            onClick={onDelete}
                            className={`${!driveId && 'animate-spin absolute'}`}
                            size={7}
                        />
                    </div>
                )}
            </div>
            <ReactTooltip
                id={id}
                place="top"
                type="dark"
                effect="solid"
                multiline={true}
            />
        </>
    )
}
