import moment from 'moment'
import { useParams } from 'react-router-dom'
import Purchase from '../../../../models/Purchase'
import { ct, formOC, reqRequisition, storagePage } from '../../storage'
import { BodyCreate } from '../../types'

export default function useSubmit() {
    const { purchase_id } = useParams<{ purchase_id?: string }>()
    const path = purchase_id
        ? `/v5.6/apis/purchases/${purchase_id}/general_information`
        : '/v5.6/apis/purchases/general_information'

    const { submit, validation, observer } = formOC.useSubmit<Purchase>(path, {
        done: purchase => {
            storagePage.purchase = purchase
            storagePage.step1Done = true
            const { billing_company, destination_company } = formOC.store()
            if (storagePage.mode === 'edition') {
                reqRequisition.setQuery('purchase_id', purchase.id)
            }
            if (storagePage.mode === 'creation') {
                reqRequisition.deleteQuery('purchase_id')
            }
            reqRequisition.setQuery(
                'destination_company_id',
                destination_company.value
            )
            reqRequisition.setQuery(
                'invoicing_company_id',
                billing_company.value
            )
            ct.next()
        },
        method: purchase_id ? 'put' : 'post'
    })

    validation(f => {
        f.billing_company.isEmpty(
            'Se requiere seleccionar la compañía de facturación'
        )
        f.destination_company.isEmpty(
            'Se requiere seleccionar la compañía de destino'
        )
        f.provider.isEmpty('Se requiere seleccionar proveedor')
        f.priority.isEmpty('Se requiere seleccionar la prioridad')
        f.date.isEmpty('Se requiere seleccionar la fecha')
        f.payment_condition.isEmpty('Se requiere ingresar la condición de pago')
        f.shipping_type.isEmpty('Se requiere seleccionar el tipo de envío')
        f.shipping_way.isEmpty('Se requiere seleccionar la forma de envío')
        f.country_dispatch_withdrawal.isEmpty(
            'Se requiere seleccionar el país de despacho/retiro'
        )
        f.place_dispatch_pick_up.isEmpty(
            'Se requiere seleccionar el lugar de despacho/retiro'
        )
        f.address.isEmpty('Se requiere seleccionar la dirección')
    })

    observer<BodyCreate>(f => {
        const date = moment(f.date).tz(storagePage.timezone).parseZone()
        const newDate = moment
            .tz(date.format('YYYY-MM-DD'), storagePage.timezone)
            .add(1, 'minute')
        return {
            address_id: parseInt(f.address.value),
            description: f.comment || null,
            destination_company_id: parseInt(f.destination_company.value),
            invoicing_company_id: parseInt(f.billing_company.value),
            payment_condition_description: f.payment_condition,
            priority_purchase_id: parseInt(f.priority.value),
            provider_id: parseInt(f.provider.value),
            request_date: newDate.toISOString(true),
            shipping_method_purchase_id: parseInt(f.shipping_way.value),
            shipping_type_purchase_id: parseInt(f.shipping_type.value)
        }
    })

    return submit
}
