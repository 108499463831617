import { controlList, formBrand } from './storage'
import Form from './View/Form'

export default function useSubmit(brandId?: number) {
    const { submit, validation } = formBrand.useSubmit(
        brandId ? `/v5.6/apis/brands/${brandId}` : '/v5.6/apis/brands',
        {
            method: brandId ? 'put' : 'post',
            done: controlList
        }
    )

    validation(v => {
        v.name.minLength(2, 'El número de caracteres válidos es 2 - 180')
        v.name.maxLength(180, 'El número de caracteres válidos es 2 - 180')
        v.name.isEmpty('Se requiere ingresar el nombre')
    })

    return formBrand.useModal(Form, {
        title: brandId ? 'Editar Marca' : 'Nueva Marca',
        onSubmit: submit,
        size: 'sm',
        isCentered: true
    })
}
