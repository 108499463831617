import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import { InputPartData, StoragePage } from './types'
import ControlRequest from '../../utilities/ControlRequest'
import moment from 'moment'

export const controlList = new ControlList<InputPartData>(Keys.MAIN, {
    path: '/v5.6/apis/input_parts',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'applicant', label: 'Usuario' },
        { value: 'warehouse', label: 'Almacén' },
        'Bodega',
        'Etiqueta',
        { value: 'date', label: 'Fecha de emisión' },
        'Estado',
        'Acciones'
    ]
})
export const reqSummaryData = new ControlRequest()
controlList.badgeRemove('start_date', false)
controlList.badgeRemove('end_date', false)
export const filterForm = new FormEvents<any>(Keys.FILTER_FORM, {
    warehouse_id: null,
    stowage_id: null,
    minimal_stowage_id: null,
    start_date: null,
    end_date: null
})

export const reqStowage = new ControlRequest()
export const reqWarehouse = new ControlRequest()
export const reqSubStowage = new ControlRequest()

controlList.onDeleteBadge = key => {
    if (key === 'stowage_id') {
        controlList.filter.deleteFilter('minimal_stowage_id')
        filterForm.setValue('minimal_stowage_id', null)
    }
}

controlList.badgeRemove('company_id', false)

export const storagePage: StoragePage = {
    input_part: null,
    company_enabled: false,
    timezone: ''
}
