import classNames from 'classnames'
import moment from 'moment'
import Badge from '../../../components/Badge'
import Icon from '../../../components/Icon'
import Panel from '../../../components/Panel'
import Tooltip from '../../../components/Tooltip'
import useStorage from '../../../hooks/useStorage'
import WorkingPeriod from '../../../models/WorkingPeriod'
import { controlList } from '../storage'
import ActiveDeactivate from './ActiveDeactivate'
import ClosePeriod from './ClosePeriod'

function Warning() {
    return (
        <Tooltip content="Hay recepciones sin costear">
            <Icon
                name="bs-exclamation-triangle"
                size={26}
                className="text-warning"
            />
        </Tooltip>
    )
}

export default function Period({
    workingPeriod
}: {
    workingPeriod: WorkingPeriod
}) {
    const { companiesFromSession } = useStorage()

    const company_id = controlList.filter.getParam('company_id')
    const company = companiesFromSession.find(
        com => com.id.toString() === company_id
    )

    return (
        <Panel
            className={classNames('ml-1', {
                'opacity-50': workingPeriod.deleted,
                'pointer-events-none': workingPeriod.deleted
            })}
            mode="form"
        >
            <div className="p-6">
                <div className="relative flex justify-between items-center mb-5">
                    <div style={{ width: '28px' }}>
                        {workingPeriod.n_reception_without_cost > 0 && (
                            <Warning />
                        )}
                    </div>
                    {workingPeriod.is_enabled && !workingPeriod.end_date && (
                        <Badge
                            color="accent"
                            className="mx-auto block relative"
                        >
                            Periodo actual
                        </Badge>
                    )}
                    <div>
                        <Icon
                            name="point"
                            size={28}
                            className={classNames(
                                workingPeriod.is_enabled
                                    ? 'text-success'
                                    : 'text-gray-200',
                                ''
                            )}
                        />
                    </div>
                </div>
                <div className="text-3xl font-univers-bold-cn uppercase text-center items-center mt-1">
                    {moment.months(workingPeriod.month - 1)}
                </div>
                <div className="text-center text-xs items-center mt-1">
                    ({workingPeriod.number_operations} operaciones)
                </div>
                <div className="text-sm text-center items-center mt-5">
                    Fecha de inicio:{' '}
                    {workingPeriod.created_at
                        ? moment(workingPeriod.created_at)
                              .tz(company.timezone)
                              .format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                </div>
                <div className="text-sm text-center items-center mt-1">
                    Fecha de fin:{' '}
                    {workingPeriod.end_date
                        ? moment(workingPeriod.end_date)
                              .tz(company.timezone)
                              .format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                </div>
                <div className="mt-6 text-center">
                    {!(workingPeriod.is_enabled && !workingPeriod.end_date) && (
                        <ActiveDeactivate workingPeriods={workingPeriod} />
                    )}
                    {workingPeriod.is_enabled && !workingPeriod.end_date && (
                        <ClosePeriod workingPeriod={workingPeriod} />
                    )}
                </div>
            </div>
        </Panel>
    )
}
