import { ItemRoute } from './types'
import Requisitions from '../../pages/Requisitions'
import RequisitionsManagement from '../../pages/RequisitionsManagement'
import RequisitionForm from '../../pages/Requisition'
import RequisitionManagement from '../../pages/RequisitionManagement'

const Requisition: ItemRoute[] = [
    {
        label: 'Documentos',
        title: 'Requisiciones',
        cu: 'CU197',
        path: '/',
        Component: Requisitions,
        routes: [
            {
                cu: 'CU198',
                title: 'Nueva requisición',
                path: '/creation',
                Component: RequisitionForm
            }
        ]
    },
    {
        label: 'Gestión de requisición',
        title: 'Gestión de requisición',
        cu: 'CU215',
        path: '/management_requisition',
        Component: RequisitionsManagement,
        routes: [
            {
                cu: 'CU216',
                title: 'Gestión de requisición',
                path: '/management',
                Component: RequisitionManagement
            }
        ]
    }
]

export default Requisition
