import useGet from '../../../../hooks/useGet'
import { reqPCD } from './storageStep4'
import Distribution from './distribution'
import { storagePage } from '../../storage'

export default function useTableDistributionByProduct() {
    const { logistical_declaration, version } = storagePage
    const { data } = useGet<
        Distribution.ResponseLDReceptionProductDistribution[]
    >(
        reqPCD.observer(
            `/v5.6/apis/logistical_declarations/${logistical_declaration.id}/version/${version}/reception_product_distributions`,
            true
        ),
        []
    )
    return {
        product_cost_distributions: data.result
    }
}
