import { Step1 } from './../../types'
import { formFactor, tablePricesControl } from '../../storage'
import FormPrice from './FormPrice'
import { RespondeUpdateFactor } from '../../../../types/product_price'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

export default function usePrice(product_company_id?: number) {
    const { submit, validation, observer } =
        formFactor.useSubmit<RespondeUpdateFactor>(
            `/v5.6/apis/product_companies/${product_company_id}/sale_price_special_factor`,
            {
                method: 'put',
                done: product_company => {
                    let index = 0
                    tablePricesControl.list.forEach((price, i) => {
                        if (price.store().id === product_company_id) {
                            index = i
                        }
                    })
                    tablePricesControl.put(index, {
                        special_factor:
                            product_company.special_factor.toString(),
                        sale_price: product_company.product_provider_company
                            ? `${
                                  product_company.product_provider_company
                                      ?.company_currency?.currency?.symbol
                              } ${buildNumberFormat(
                                  product_company.product_provider_company
                                      ?.sale_price
                              )}`
                            : '-',
                        discount_price: product_company.product_provider_company
                            ? `${
                                  product_company.product_provider_company
                                      ?.company_currency?.currency?.symbol
                              } ${buildNumberFormat(
                                  product_company.product_provider_company
                                      ?.discount_price
                              )}`
                            : '-'
                    })
                    formFactor.store.init(formFactor.store)
                }
            }
        )

    validation(v => {
        v.special_factor.isMinor(1, 'El número debe ser mayor o igual a 1')
        v.special_factor.isEmpty('Se debe ingresar el factor especial')
        v.special_factor.isGreater(
            999,
            'El número debe ser menor o igual a 999'
        )
    })

    observer<Step1.BodyFactor>(form => {
        return {
            special_factor:
                form.special_factor !== ''
                    ? parseFloat(form.special_factor)
                    : null
        }
    })

    return formFactor.useModal(FormPrice, {
        title: 'Editar Factor Especial',
        size: 'sm',
        onSubmit: submit,
        isCentered: true
    })
}
