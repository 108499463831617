import SearchField from '../../../components/SearchField'
import Button from '../../../components/Button'
import { controlList, filterForm, reqWarehouse, storagePage } from '../storage'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import useStorage from '../../../hooks/useStorage'
import FiltersForm from './FiltersForm'
import { Option } from '../../../config/types/component'
import { Link } from 'react-router-dom'
import Rules from '../../../components/Rules'
import { formPurchaseReceipt } from '../../PurchaseReceipt/storage'
import { render, useRender } from 'redity'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { setCompanySelected } from '../../../utilities/controlStorage'
import OptionExcel from './Export/OptionExcel'

function Company() {
    const { companiesFromSession } = useStorage()
    const { props } = controlList.useFilter('company_id')

    async function handleChange(option: Option) {
        filterForm.setValue('warehouse_id', null)
        controlList.filter.deleteFilter('warehouse_id')
        reqWarehouse.setQuery('company_id', option.value)
        reqWarehouse.load()
        controlList.renderForm()
        const company = companiesFromSession.find(
            _company => _company.id === parseInt(option.value)
        )
        if (company.is_enabled) {
            formPurchaseReceipt.setValue('company', option)
            storagePage.company_enabled = true
        } else {
            formPurchaseReceipt.setValue('company', null)
            storagePage.company_enabled = false
        }
        storagePage.timezone = company.timezone
        setCompanySelected(company)
        render('button_new')
        await props.onChange(option)
    }

    return (
        <Dropdown
            {...props}
            label="Compañía"
            clear={false}
            onChange={handleChange}
            showLabel={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    name: company.name,
                    image_url: company.country.image_url,
                    id: company.id,
                    disabled: !company.is_enabled
                }))}
                label="name"
                image="image_url"
                strikeOut="disabled"
            />
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por código de RC, OC o producto"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

function ButtonNew() {
    useRender('button_new')
    return (
        <Link
            to="/movements/purchase_receipts/create"
            className={
                !storagePage.company_enabled ? 'pointer-events-none' : ''
            }
        >
            <Button
                disabled={!storagePage.company_enabled}
                size="sm"
                className="ml-2"
            >
                Nuevo
            </Button>
        </Link>
    )
}

export default function Filters() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Company />
                    <FiltersForm />
                </FlexLeft>
                <FlexRight>
                    <Dropdown label="Exportar" icon="download" clear={false}>
                        <OptionExcel />
                    </Dropdown>
                    <Rules cu="CU226">
                        <ButtonNew />
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
