import { FormControl } from '../../../../components/FormUtilities'
import Textarea from '../../../../components/Textarea'
import Upload from '../../../../components/Upload'
import { formGeneral } from './storageStep1'

function Files() {
    const { props } = formGeneral.useUploads('files')
    return (
        <FormControl label="Documentos" col="span-6" row="span-3">
            <Upload {...props} />
        </FormControl>
    )
}

function Comment() {
    const { props } = formGeneral.useInput('comment')
    return (
        <FormControl label="Comentario" col="span-6">
            <Textarea {...props} placeholder="Ingresar comentario " />
        </FormControl>
    )
}

export default function Fields3() {
    return (
        <>
            <Files />
            <Comment />
        </>
    )
}
