import { ItemRoute } from '../../config/routes/types'
import getModules from './getModules'

export default function getPages(): Array<ItemRoute> {
    const pages: Array<any> = []
    for (const mod of getModules()) {
        if (mod.routes) {
            for (const route of mod.routes) {
                pages.push({
                    Component: route.Component,
                    path: route.path,
                    title: route.title,
                    cu: route.cu
                })
                if (route.routes) {
                    for (const sub of route.routes) {
                        pages.push({
                            Component: sub.Component,
                            path: sub.path,
                            title: sub.title,
                            cu: sub.cu
                        })
                    }
                }
            }
        } else {
            pages.push(mod)
        }
    }

    return pages
}
