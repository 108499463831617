import Rules from '../../../components/Rules'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { GetStowages } from '../../../types/stowages'
import { controlList } from '../storage'

interface PropsActiveDeactive {
    stowage: GetStowages
}

export default function ActiveDeactivate({ stowage }: PropsActiveDeactive) {
    return (
        <Rules cu={stowage.is_enabled ? 'CU103' : 'CU104'}>
            <ActivateDeactivate
                isEnabled={stowage.is_enabled}
                disabled={!stowage.company.is_enabled}
                control={controlList}
                body={{
                    stowage_id: stowage.id
                }}
                className="mr-2"
            >
                <Active path="/v5.6/apis/stowages/activate">
                    Al reactivar la bodega, estará disponible para operaciones
                    de compra y venta. ¿Estás seguro de reactivar la bodega{' '}
                    <b className="text-primary">{stowage.name}</b>?
                </Active>
                <Deactive path="/v5.6/apis/stowages/deactivate">
                    Al desactivar la bodega, dejará de estar disponible para
                    operaciones de compra y venta. ¿Estás seguro de desactivar
                    la bodega <b className="text-primary">{stowage.name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
