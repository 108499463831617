import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
import { ProviderData } from './types'

export const controlList = new ControlList<ProviderData>(Keys.MAIN, {
    path: '/v5.6/apis/providers',
    theads: [
        '#',
        { value: 'commercial_name', label: 'Nombre comercial' },
        { value: 'country_name', label: 'País de procedencia' },
        { value: 'business_name', label: 'Razón social' },
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})
