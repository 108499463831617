export default function mathPath(rountingPath: string, path: string) {
    const splitPathname = rountingPath.split('/')
    const splitPath = path.split('/')
    if (splitPathname.length === splitPath.length) {
        let finded = true
        for (let i = 0; i < splitPathname.length; i++) {
            if (!finded) break
            if (splitPath[i].search(':') > -1) continue
            finded = splitPathname[i] === splitPath[i]
        }
        return finded
    } else {
        return rountingPath === path
    }
}
