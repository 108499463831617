import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { GetPurchase } from '../../../../types/purchase'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    item: {
        width: '20%',
        fontSize: '10px',
        padding: '5px',
        height: '100%'
    },
    item2: {
        width: '57%',
        fontSize: '10px',
        padding: '5px',
        height: '100%'
    },
    item3: {
        width: '43%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        padding: '5px',
        height: '100%',
        textAlign: 'center'
    },
    itemTable: {
        width: '15%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderBottom: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2'
    },
    info: {
        width: '65%'
    },
    info2: {
        width: '35%'
    },
    infoLine: {
        width: '100%',
        fontSize: '10px',
        textAlign: 'left'
    },
    signatures: {
        marginTop: '100px',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    itemSignature: {
        width: '30%'
    },
    container: {
        width: '100%',
        borderTop: '1px solid #000',
        padding: '5px'
    },
    line: {
        width: '100%',
        fontSize: '8px',
        textAlign: 'center'
    }
})

export default function Table3({ data }: { data: GetPurchase }) {
    let total = 0

    data.purchase_products
        .filter(pp => pp.is_enabled && !pp.deleted)
        .forEach(pp => {
            total += pp.quantity * pp.unit_purchase_price
        })

    return (
        <>
            <View style={styles.row}>
                <View style={styles.info}></View>
                <Text style={styles.item}>Total Neto {data.currency.code}</Text>
                <Text style={styles.itemTable}>{buildNumberFormat(total)}</Text>
            </View>
            <View style={styles.row}>
                <View style={styles.info}>
                    <Text style={styles.infoLine}>
                        Estos precios no incluyen I.V.A./Taxes are not included
                    </Text>
                    <Text style={styles.infoLine}>
                        Emitir facturas a nombre de{' '}
                        {data.destination_company.name}
                    </Text>
                    <Text style={styles.infoLine}>
                        Please send your invoice to{' '}
                        {data.destination_company.name}
                    </Text>
                </View>
                <View style={styles.info2}>
                    <View style={styles.row}>
                        <Text style={styles.item2}>Descuento/Discount</Text>
                        <Text style={styles.item3}>0,00</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.item2}>Recargo/Charge</Text>
                        <Text style={styles.item3}>0,00</Text>
                    </View>
                </View>
            </View>
            <View style={styles.signatures} wrap={false}>
                <View style={styles.itemSignature}>
                    <View style={styles.container}>
                        <Text style={styles.line}>Elaborado por/Made by</Text>
                        <Text style={styles.line}>
                            Firma y sello/Signature and Stamp
                        </Text>
                    </View>
                </View>
                <View style={styles.itemSignature}>
                    <View style={styles.container}>
                        <Text style={styles.line}>
                            Autorizado por/Autorized by
                        </Text>
                        <Text style={styles.line}>
                            Firma y sello/Signature and Stamp
                        </Text>
                    </View>
                </View>
                <View style={styles.itemSignature}>
                    <View style={styles.container}>
                        <Text style={styles.line}>
                            Autorizado por/Autorized by
                        </Text>
                        <Text style={styles.line}>
                            Firma y sello/Signature and Stamp
                        </Text>
                    </View>
                </View>
            </View>
        </>
    )
}
