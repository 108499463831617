import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import SelectTouch from '../../../../components/SelectTouch'
import Brand from '../../../../models/Brand'
import { formInformation } from '../../storage'

function Rank() {
    const { props } = formInformation.useInput('rank')
    return (
        <FormControl label="Rango">
            <InputField
                {...props}
                placeholder="Ingresar rango"
                maxLength={255}
            />
        </FormControl>
    )
}

function BrandSelect() {
    const { props, data } = formInformation.useSelect<Brand>(
        'brand',
        '/v5.6/brands?sort=name'
    )
    return (
        <FormControl label="Marca" required>
            <SelectTouch {...props} placeholder="Selecciona Marca">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Model() {
    const { props } = formInformation.useInput('model')

    return (
        <FormControl label="Modelo">
            <InputField
                {...props}
                placeholder="Ingresar modelo de producto"
                maxLength={255}
            />
        </FormControl>
    )
}

function Quantity() {
    const { props } = formInformation.useInput('quantity')
    return (
        <FormControl label="Cantidad">
            <InputField {...props} placeholder="" maxLength={255} />
        </FormControl>
    )
}

export default function Fields2() {
    return (
        <>
            <Rank />
            <BrandSelect />
            <Model />
            <Quantity />
        </>
    )
}
