import {
    FormControl,
    IterateOptions
} from '../../../../../components/FormUtilities'
import SelectTouch from '../../../../../components/SelectTouch'
import useCountryDispatchWithdrawal from './useCountryDispatchWithdrawal'
import useShippingType from './useShippingType'

export default function Fields1() {
    return (
        <>
            <ShippingType />
            <CountryDispatchWithdrawal />
        </>
    )
}

function ShippingType() {
    const { props, shippingTypePurchases, handleChange } = useShippingType()

    return (
        <FormControl label="Tipo de envío" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar tipo de envío"
            >
                <IterateOptions data={shippingTypePurchases} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function CountryDispatchWithdrawal() {
    const { props, countries, handleChange } = useCountryDispatchWithdrawal()

    return (
        <FormControl label="País de despacho/retiro" required>
            <SelectTouch
                disabled
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar país de despacho o retiro"
            >
                <IterateOptions
                    data={countries}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}
