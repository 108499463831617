import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import SelectTouch from '../../../../components/SelectTouch'
import Textarea from '../../../../components/Textarea'
import Country from '../../../../models/Country'
import { formAddress } from '../../storage'

function Locate() {
    const { props } = formAddress.useInput('locate')
    return (
        <FormControl label="Lugar de retiro" required className="mb-2">
            <InputField
                {...props}
                placeholder="Ingresar lugar de retiro"
                autoFocus
            />
        </FormControl>
    )
}

function CountrySelect() {
    const { props, data } = formAddress.useSelect<Country>(
        'country',
        '/v5.6/apis/countries'
    )
    return (
        <FormControl label="País" required className="mb-2">
            <SelectTouch
                {...props}
                placeholder="Seleccionar país de dirección"
                label="Seleccionar"
            >
                <IterateOptions data={data} label="name" image="image_url" />
            </SelectTouch>
        </FormControl>
    )
}

function Address() {
    const { props } = formAddress.useInput('address')
    return (
        <FormControl label="Dirección" required>
            <Textarea {...props} placeholder="Ingresar dirección" />
        </FormControl>
    )
}

export default function FormAddress() {
    return (
        <>
            <Locate />
            <CountrySelect />
            <Address />
        </>
    )
}
