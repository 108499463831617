import BtnActionTable from '../../../../components/BtnActionTable'
import Rules from '../../../../components/Rules'
import OpenDialog from '../../../../sections/OpenDialog'
import { controlList } from '../../storage'

export default function CancelTraspase({ traspase }: { traspase: any }) {
    return (
        <>
            <Rules cu={'CU155'}>
                <OpenDialog
                    element={click => (
                        <BtnActionTable
                            title="Anular"
                            iconName="bs-x"
                            onClick={click}
                            disabled={false}
                        />
                    )}
                    title="ANULAR TRASPASO"
                    path="/v5.6/apis/traspases/cancel"
                    icon="bs-x-circle"
                    color="error"
                    control={controlList}
                    body={{
                        traspase_id: traspase.id
                    }}
                    method="put"
                    accept="Sí, anular"
                >
                    ¿Está seguro de anular el traspaso {traspase.document.code}?
                </OpenDialog>
            </Rules>
        </>
    )
}
