import { StyleSheet, Font } from '@react-pdf/renderer'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

export const generalStyles = StyleSheet.create({
    section: {
        padding: '30px 30px'
    },
    page: {
        fontSize: '9px',
        fontFamily: 'Helvetica',
        marginBottom: '10px',
        color: '#2A2A5B'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '14px'
    },
    companyWatemark: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '7px'
    },
    title: {
        fontSize: '16px',
        fontFamily: 'helvetica-bold',
        marginTop: '25px',
        marginBottom: '20px'
    },
    companyWatemark__logo: {
        height: '38px',
        width: '70px'
    },
    companyWatemark__info: {
        maxWidth: '30%'
    },
    fontBold: {
        fontFamily: 'helvetica-bold'
    },
    list: {
        display: 'flex',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingBottom: '10px',
        justifyContent: 'space-between',
        borderBottom: '2px dotted rgb(128, 126, 126)',
        flexDirection: 'row',
        fontSize: fontSize
    },
    list__element__name: {
        fontFamily: 'helvetica-bold',
        fontSize: fontSize
    },
    list__element__value: {
        fontSize: fontSize
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    table__footer__td: {
        padding: '2px',
        fontSize: fontSize,
        backgroundColor: 'rgb(229,231,235)'
    },
    table__td: {
        padding: '6px',
        fontSize: fontSize,
        borderLeft: '1px solid #CCCCCC',
        borderBottom: '1px solid #CCCCCC'
    },
    container__title: {
        fontSize: '12px',
        marginBottom: '8px',
        fontFamily: 'helvetica-bold'
    },
    table__th: {
        width: '13%',
        backgroundColor: '#CCCCCC',
        padding: '4px',
        fontSize: fontSize,
        fontFamily: 'helvetica-bold'
    },
    textRight: {
        textAlign: 'right'
    },
    textBold: {
        fontFamily: 'helvetica-bold'
    }
})
