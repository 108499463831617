import styled from 'styled-components'

export const SectionFiltersGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-gap: 10px;

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 300px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;
    }
    // X-Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
`

export const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
`

export const FlexLeft = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
`

export const FlexRight = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
`
