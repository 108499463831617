import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import { GetCompanies, ViewProductPrice } from '../../../../types/product_price'
import { Option as OptionType } from '../../../../config/types/component'
import { formSaleDiscounts, storagePage } from '../../storage'
import { render } from 'redity'
import { get } from '../../../../utilities/http'
import { Popup } from '../../../../utilities/popup'
import { initialEditionSaleDiscount } from '../../utilities/initialEditionSaleDiscount'
import { Keys } from '../../constants'
import buildCompanyName from '../../../../utilities/buildCompanyName'

export default function SelectCompany() {
    const { props, data } = formSaleDiscounts.useSelect<GetCompanies>(
        'company',
        `/v5.6/apis/companies/${storagePage.productPrice.product_country.product.id}/enabled_for_sale`
    )

    const companies = data.map(item => ({
        ...item.company,
        id: item.id,
        name: buildCompanyName(item.company.name),
        image_url: item.company.country.image_url,
        strikeOut: !item.company.memory
    }))

    async function handleChange(option: OptionType) {
        if (option && option.value !== '0') {
            storagePage.loadingSaleDiscounts = true
            render('SaleDiscounts')
            get<ViewProductPrice>(
                `/v5.6/apis/product_companies/${option.value}/sale_prices_and_factors`
            ).then(response => {
                if (response.error && response.error.type !== 'cancel') {
                    Popup.error('Lo sentimos', response.error.message)
                    storagePage.loadingSaleDiscounts = false
                } else {
                    initialEditionSaleDiscount(response.data.result)
                    storagePage.loadingSaleDiscounts = false
                    render('SaleDiscounts')
                }
            })
        } else {
            storagePage.loadingSaleDiscounts = false
        }

        const com = data.find(d => d.id === parseInt(option.value))?.company
            .memory
        storagePage.enabledEditionDiscount = com

        props.onChange(option)
        render('SaleDiscounts')
        render(Keys.DISCOUNT_BUTTON_EDITION)
    }

    return (
        <FormControl label="Compañía" col="span-6">
            <SelectTouch
                {...props}
                placeholder="Seleccionar una compañía"
                onChange={handleChange}
                selectTouchInherit={true}
            >
                <IterateOptions
                    data={companies}
                    label="name"
                    image="image_url"
                    strikeOut="strikeOut"
                />
            </SelectTouch>
        </FormControl>
    )
}
