import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import ReadOnly from '../../../components/ReadOnly'
import SelectTouch from '../../../components/SelectTouch'
import { formStowage, storagePage } from '../storage'
import useStorage from '../../../hooks/useStorage'
import ClientType from '../../../models/Client'
import FractionField from '../../../components/FractionField'

function Company() {
    const { company } = formStowage.store()
    return (
        <FormControl label="Compañía" required>
            <ReadOnly image={company.image} value={company.label} />
        </FormControl>
    )
}

function Name() {
    const { props, getError } = formStowage.useInput('name')
    const helper = getError(
        'ERROR-0000110',
        'Ya existe una bodega con el mismo nombre'
    )

    return (
        <FormControl label="Nombre de bodega" required className="mt-2">
            <InputField
                placeholder="Ingresar nombre"
                autoFocus
                {...props}
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

function TypeStowage() {
    const { props } = formStowage.useSelect('type_stowage')
    const { typeStowages } = useStorage()
    return (
        <FormControl label="Tipo de bodega" required className="mt-2">
            <SelectTouch
                {...props}
                placeholder="Seleccionar un tipo"
                disabled={storagePage.modeModal === 'edition'}
            >
                <IterateOptions data={typeStowages} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Client() {
    const { props, data } = formStowage.useSelect<ClientType>(
        'client',
        `/v5.6/apis/clients/${storagePage.company.value}`
    )

    return (
        <FormControl label="Cliente" required className="mt-2">
            <SelectTouch
                {...props}
                placeholder={
                    storagePage.modeModal === 'creation'
                        ? 'Seleccionar un cliente'
                        : '-'
                }
            >
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Mount() {
    const { props } = formStowage.useInput('max_amount')
    const { currency_symbol } = formStowage.store()
    return (
        <FormControl
            label="Monto de Inventario máximo"
            {...props}
            className="mt-2"
        >
            <FractionField
                {...props}
                decimal={2}
                max={9999999999.99}
                prefix={currency_symbol}
            />
        </FormControl>
    )
}

export default function Form() {
    return (
        <>
            <Company />
            <Name />
            <TypeStowage />
            <Client />
            <Mount />
        </>
    )
}
