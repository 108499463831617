import BtnActionTable from '../../../../../components/BtnActionTable'
import Option from '../../../../../components/Option'
import Popover from '../../../../../components/Popover'
import Rules from '../../../../../components/Rules'
import ModalImport from '../../ModalImport'
import OptionExport from './OptionExport'
import { providerList } from '../../../storage'

export default function PopoverMore() {
    return (
        <Popover
            component={<BtnActionTable iconName="bs-three-dots" />}
            position="bottom-right-left"
            element=".control"
        >
            <Rules cu="CU111">
                <ModalImport
                    element={click => (
                        <Option
                            label="Importar Catálogo"
                            value="1"
                            icon="cloud-upload"
                            onClick={click}
                        />
                    )}
                    onDone={async () => {
                        await providerList.load()
                        providerList.render()
                    }}
                />
            </Rules>
            {/* <Option label="Exportar en PDF" value="2" icon="download" /> */}
            <Rules cu="CU293">
                <OptionExport />
            </Rules>
        </Popover>
    )
}
