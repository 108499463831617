import {
    FormControl,
    FormControls
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import NumeralField from '../../../../../components/NumeralField'
import { storagePage } from '../../../storage'
import { formRC } from '../storageStep2'
import runOperationModalReception from '../../../utilities/runOperationModalReception'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'

function PurchaseCost1() {
    const { props } = formRC.useInput('purchase_dolar_cost')
    const { currentReception, currentSymbolReception } = storagePage
    const symbol =
        currentReception?.purchase?.currency?.symbol || currentSymbolReception

    const value = buildNumberFormat(parseFloat(props.value) || 0)
    return (
        <FormControl label={`Costo de compra (${symbol})`} col="span-2">
            <InputField {...props} value={value} disabled decimal={5} />
        </FormControl>
    )
}

function ExchangeRate() {
    const { props } = formRC.useInput('exchange_rc_to_dolar')
    const { currentReception, currentSymbolReception } = storagePage
    const symbol =
        currentReception?.purchase?.currency?.symbol || currentSymbolReception

    function handleChange(ev) {
        props.onChange(ev)
        runOperationModalReception()
    }

    return (
        <FormControl
            label={`Tipo de cambio (${symbol} a $)`}
            required
            col="span-2"
        >
            <NumeralField {...props} onChange={handleChange} decimal={4} />
        </FormControl>
    )
}

function PurchaseCost2() {
    const { props } = formRC.useInput('purchase_dolar_cost_total')
    const value = buildNumberFormat(parseFloat(props.value) || 0)
    return (
        <FormControl label="Costo de compra ($)" col="span-2">
            <InputField {...props} value={value} disabled decimal={2} />
        </FormControl>
    )
}

function PurchaseCost3() {
    const { props } = formRC.useInput('purchase_local_cost')
    const value = buildNumberFormat(parseFloat(props.value) || 0)
    return (
        <FormControl label="Costo de compra ($)" col="span-2">
            <InputField {...props} value={value} disabled decimal={2} />
        </FormControl>
    )
}

function ExchangeRate2() {
    const { props } = formRC.useInput('exchange_dolar_to_local')

    function handleChange(ev) {
        props.onChange(ev)
        runOperationModalReception()
    }

    return (
        <FormControl
            label={`Tipo de cambio ($ a ${storagePage.currentCurrency.symbol})`}
            required
            col="span-2"
        >
            <NumeralField {...props} onChange={handleChange} decimal={4} />
        </FormControl>
    )
}

function PurchaseCost4() {
    const { props } = formRC.useInput('purchase_local_cost_total')
    const value = buildNumberFormat(parseFloat(props.value) || 0)
    return (
        <FormControl
            label={`Costo de compra (${storagePage.currentCurrency.symbol})`}
            col="span-2"
        >
            <InputField {...props} value={value} disabled decimal={2} />
        </FormControl>
    )
}

export default function Fields() {
    return (
        <FormControls>
            <PurchaseCost1 />
            <ExchangeRate />
            <PurchaseCost2 />
            <PurchaseCost3 />
            <ExchangeRate2 />
            <PurchaseCost4 />
        </FormControls>
    )
}
