import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import useCancel from '../../../hooks/useCancel'
import { formPurchaseReceipt, storagePage } from '../storage'
import useSubmit from '../useSubmit'

export default function Header() {
    const submit = useSubmit(storagePage.purchaseReceipt?.id || undefined)
    const { onClick } = useCancel(
        formPurchaseReceipt,
        '/movements/purchase_receipts'
    )
    return (
        <PageHeader subtitle="(*) Complete todos los campos para agregar">
            <Button color="ghost" onClick={onClick}>
                Cancelar
            </Button>
            <Button className="ml-2" onClick={() => submit()}>
                Guardar
            </Button>
        </PageHeader>
    )
}
