import { controlList } from '../../../storage'
import Pdf from './../../Pdf'
import { useRender } from 'redity'
import { Keys } from '../../../constants'
import { viewPrint } from '../../../../../sections/ModalPrint'
import { buildPath } from '../../../../../sections/ModalPrint/utils'
import { SimpleOption } from '../../../../../components/Option'

export default function OptionPDF() {
    useRender(Keys.EXPORT)

    function handleClick() {
        const company_id = controlList.filter.params.company_id
        const path = buildPath(
            `/v5.6/apis/inventory_transit/excel?company_id=${company_id}`,
            []
        )

        viewPrint(path, () => ({
            Component: Pdf
        }))
    }
    return <SimpleOption onClick={handleClick}>Exportar en PDF</SimpleOption>
}
