import LogisticalConcept from '../../../../../models/LogisticalConcept'
import {
    GroupLogisticalConcept,
    LogisticalDeclarationExcelData
} from '../../../types'
import { getRowCost } from './getRowCost'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR, textBold } from './utils'

export function getRowsSettingCosts(
    data: LogisticalDeclarationExcelData[],
    groupConcepts: GroupLogisticalConcept[]
) {
    const rows: Row[][] = []
    getSettingConcepts(groupConcepts).forEach(concept => {
        rows.push(getRowCost(concept, data, ['setting_costs']))
    })
    const rowSummary: Row[] = [
        {
            value: 'Costos de ajuste',
            backgroundColor: COLOR.yellow
        }
    ]
    data.forEach(d => {
        rowSummary.push({
            value: d.data_summary.setting_original_cost,
            format: '0.00',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: d.data_summary.setting_local_cost,
            format: '0.00',
            backgroundColor: COLOR.yellow
        })
        rowSummary.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    rows.push(addRowStyles(rowSummary, [borderThin, textBold]))
    return rows
}

function getSettingConcepts(groupConcepts: GroupLogisticalConcept[]) {
    const settingConcepts: LogisticalConcept[] = []
    groupConcepts
        .filter(groupConcept => groupConcept.id === 5)
        .forEach(groupConcept => {
            groupConcept.logistical_concepts.forEach(concept => {
                settingConcepts.push(concept)
            })
        })
    return settingConcepts
}
