import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { StateCase } from '../../config/enums'
import useCancel from '../../hooks/useCancel'
import { GetWarehouse } from '../../types/warehouse'
import { get } from '../../utilities/http'
import { formWarehouse, storagePage } from './storage'
import initialEdition from './utilities/initialEdition'

export default function useInitial() {
    const { warehouse_id } = useParams<{ warehouse_id?: string }>()
    const [pageState, setPageState] = useState<StateCase>(StateCase.LOADING)
    const { onClick } = useCancel(formWarehouse, '/configuration/warehouses')

    useEffect(() => {
        if (warehouse_id) {
            storagePage.mode = 'edition'
            storagePage.warehouseId = parseInt(warehouse_id)
            get<GetWarehouse>(
                `/v5.6/apis/warehouses/${warehouse_id}/general_information`
            ).then(result => {
                if (result.error) {
                    setPageState(StateCase.ERROR)
                } else {
                    storagePage.warehouse = result.data.result
                    initialEdition(storagePage.warehouse)
                    formWarehouse.setProps('country', {
                        disabled: true
                    })
                    setPageState(StateCase.SUCCESS)
                }
            })
        } else {
            storagePage.mode = 'creation'
            formWarehouse.setProps('country', {
                disabled: false
            })
            formWarehouse.setProps('companies', {
                disabled: true
            })
            setPageState(StateCase.SUCCESS)
        }

        return () => {
            storagePage.warehouse = null
            storagePage.warehouseId = null
            storagePage.mode = 'creation'

            formWarehouse.init()
        }
    }, [])

    return {
        pageState,
        cancel: onClick
    }
}
