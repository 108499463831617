import useGet from '../../../../hooks/useGet'
import { reqRequisition, storagePage, tableRequisition } from '../../storage'
import { RequisitionProductToPurchase } from '../../types'

export default function useTableRequisitions() {
    const { data, loading } = useGet<RequisitionProductToPurchase[]>(
        reqRequisition.observer(
            '/v5.6/apis/requisition_products/to_purchase',
            true
        ),
        []
    )

    function getCodeSubStowages(
        minimal_stowage: RequisitionProductToPurchase['minimal_stowage']
    ) {
        let code = ''

        if (minimal_stowage.client_sub_stowage) {
            code = minimal_stowage.client_sub_stowage.client.name
        }

        if (minimal_stowage.order_sub_stowage) {
            code = minimal_stowage.order_sub_stowage.order.number
        }

        if (minimal_stowage.gp_sub_stowage) {
            code = minimal_stowage.gp_sub_stowage.purchase.code
        }

        return code
    }

    if (!loading) {
        tableRequisition.setList(
            data.result.map(d => {
                const backup =
                    storagePage.req_products_actived[d.requisition_product_id]

                return {
                    id: d.requisition_product_id,
                    rq: d.requisition.code,
                    stowage_name: d.minimal_stowage.stowage.name,
                    stawage_code: getCodeSubStowages(d.minimal_stowage),
                    product_description:
                        d.product_provider_company.provider_description,
                    product_code: d.product_provider_company.provider_code,
                    open_days: d.days_opened,
                    pending_amount: d.quantity_pending,
                    quantity_buy: backup
                        ? backup.quantity_buy
                        : d.quantity_to_purchase,
                    provider_price: backup
                        ? backup.provider_price
                        : d.product_provider_company.purchase_price,
                    symbol: d.product_provider_company.company_currency.currency
                        .symbol,
                    checkbox: !!backup || d.quantity_to_purchase > 0,
                    min_temperature: d.product.min_temperature,
                    max_temperature: d.product.max_temperature,
                    marine_transport: d.product.marine_transport,
                    air_transport: d.product.air_transport,
                    licenses: {
                        expire: d.product.expired_licenses,
                        expired: d.product.licenses_to_expired
                    }
                }
            })
        )
    }

    return {
        tableRequisition
    }
}
