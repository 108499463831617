import { controlList } from '../../../storage'
import Pdf from './../../Pdf'
import { useRender } from 'redity'
import { Keys } from '../../../constants'
import { viewPrint } from '../../../../../sections/ModalPrint'
import { buildPath } from '../../../../../sections/ModalPrint/utils'
import { SimpleOption } from '../../../../../components/Option'

export default function OptionPDF() {
    useRender(Keys.EXPORT)

    function handleClick() {
        const path = buildPath('/v5.6/apis/balance_histories', [
            ['search', controlList.filter.getParam('search')],
            ['company_id', controlList.filter.getParam('company_id')],
            ['date', controlList.filter.getParam('date')],
            ['with_stock', controlList.filter.getParam('with_stock')],
            ['category_id', controlList.filter.getParam('category_id')],
            ['stowage_id', controlList.filter.getParam('stowage_id')],
            [
                'minimal_stowage_id',
                controlList.filter.getParam('minimal_stowage_id')
            ],
            ['cost_alert', controlList.filter.getParam('cost_alert')]
        ])

        viewPrint(path, () => ({
            Component: Pdf
        }))
    }
    return <SimpleOption onClick={handleClick}>Exportar en PDF</SimpleOption>
}
