import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import GeneralInformation from './GeneralInformation'
import { ct, storagePage } from '../storage'
import ProductsToBuy from './ProductsToBuy'
import useInitial from '../useInitial'
import useSubmitStep1 from './GeneralInformation/useSubmit'
import useSubmitStep2 from './ProductsToBuy/useSubmit'

ct.setSection('Información General', GeneralInformation)
ct.setSection('Productos a comprar', ProductsToBuy)

export default function PurchaseOrder() {
    const { pageState, onCancel } = useInitial()
    const submit1 = useSubmitStep1()
    const submit2 = useSubmitStep2()
    const value = ct.useStep(to => {
        if (storagePage.mode === 'edition') return true
        if (to === 'step2' && !storagePage.step1Done) {
            return false
        }
        return true
    })

    function handleCancel() {
        if (value === 'step1') {
            onCancel()
        } else {
            ct.prev()
        }
    }

    return (
        <Initial pageState={pageState}>
            <PageHeader>
                <Button color="ghost" onClick={handleCancel}>
                    {value === 'step1' ? 'Cancelar' : 'Atrás'}
                </Button>
                <Button
                    className="ml-2"
                    onClick={() => (value === 'step1' ? submit1() : submit2())}
                >
                    {value === 'step1' && storagePage.mode === 'creation'
                        ? 'Siguiente'
                        : 'Guardar'}
                </Button>
            </PageHeader>
            <ct.SectionTabs
                value={value}
                type={storagePage.mode === 'creation' ? 'step' : 'tab'}
                className="max-w-screen-2xl"
            />
        </Initial>
    )
}
