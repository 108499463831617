import { FormControl } from '../../../components/FormUtilities'
import Upload from '../../../components/Upload'
import { formPurchaseReceipt } from '../storage'

export default function UploadFiles() {
    const { props } = formPurchaseReceipt.useUploads('files')
    return (
        <FormControl label="Documentos" col="span-6" row="span-3" required>
            <Upload
                {...props}
                extensions={[
                    'png',
                    'jpg',
                    'jpeg',
                    'pdf',
                    'doc',
                    'docx',
                    'PNG',
                    'JPG',
                    'xlsx',
                    'XLSX'
                ]}
            />
        </FormControl>
    )
}
