import { ModalTools } from '../../../../sections/ModalSection/types'
import { formPaymentCondition, tablePaymentConditions } from '../../storage'
import { TablePaymentConditionData } from '../../types'
import FormPaymentCondition from './FormPaymentCondition'

export default function usePaymentCondition(index?: number) {
    function onSubmit(ev, modalTools: ModalTools) {
        ev.preventDefault()
        const result = formPaymentCondition.test(v => {
            v.description.isEmpty('Se requiere ingresar la descripción')
            v.payment_condition.isEmpty(
                'Se requiere seleccionar la condición de pago'
            )
            v.company.isEmpty('Se requiere seleccionar la compañía')
        })

        if (!result) return

        const { payment_condition, description, company } =
            formPaymentCondition.store()

        const data: TablePaymentConditionData = {
            id: 1,
            company: {
                name: company.label,
                id: parseInt(company.value),
                image: company.image
            },
            description,
            payment_condition: {
                id: parseInt(payment_condition.value),
                name: payment_condition.label
            }
        }

        if (index === undefined) {
            tablePaymentConditions.push(data)
        } else {
            tablePaymentConditions.put(index, data)
        }
        modalTools.close()
    }

    return formPaymentCondition.useModal(FormPaymentCondition, {
        size: 'sm',
        onSubmit,
        title:
            index === undefined
                ? 'Agregar Condición de Pago'
                : 'Editar Condición de Pago'
    })
}
