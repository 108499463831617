import { NAVBAR_HEIGHT } from '../../config/app'
import classnames from 'classnames'
import { PropsContainerPage } from './type'
import Breadcrumb from './Breadcrumb'
import ControlBlockAlert, { showBlockAlert } from './ControlBlockAlert'

export default function ContainerPage({
    children,
    className = '',
    fluid = false
}: PropsContainerPage) {
    return (
        <div
            className={classnames('w-full', className)}
            style={{ minHeight: `calc(100% - ${NAVBAR_HEIGHT})` }}
        >
            <ControlBlockAlert />
            <div className="text-xs breadcrumbs w-full bg-white px-4 py-2">
                <Breadcrumb />
            </div>
            <div
                className={fluid ? '' : 'p-4'}
                style={{ paddingBottom: '240px' }}
            >
                {children}
            </div>
        </div>
    )
}

export { showBlockAlert }
