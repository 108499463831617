import { parse } from 'fter'
import { ChangeEvent } from 'react'
import Checkbox from '../../../../../../components/Checkbox'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../../../components/DataTable'
import Loading from '../../../../../../components/Loading'
import useGet from '../../../../../../hooks/useGet'
import Product from '../../../../../../models/Product'
import { storagePage } from '../../../../storage'
import { TableProductsProps } from './types'

export default function TableProducts({
    rcIds,
    productIds,
    setProductIds
}: TableProductsProps) {
    const company_id = storagePage.logistical_declaration.company_id
    const queries = parse(
        {
            reception_ids: rcIds.map(rcId => rcId)
        },
        `/v5.6/apis/companies/${company_id}/receptions/products`
    )
    const { data, loading } = useGet<Product[]>(queries, [], [rcIds.length])

    const isChecked = (productId: number) =>
        !!productIds.find(id => id === productId)

    function checkHandler(ev: ChangeEvent<HTMLInputElement>) {
        const productId = Number(ev.target.value)
        if (isChecked(productId)) {
            setProductIds(productIds.filter(id => id !== productId))
        } else {
            setProductIds([...productIds, productId])
        }
    }

    return (
        <DataTable>
            <THead>
                <tr>
                    <th>Productos a costear</th>
                    <th />
                </tr>
            </THead>
            <TBody>
                {data.result.map(product => (
                    <tr key={product.id}>
                        <td>{product.description}</td>
                        <td>
                            <Checkbox
                                value={product.id}
                                checked={isChecked(product.id)}
                                onChange={checkHandler}
                                disabled
                            />
                        </td>
                    </tr>
                ))}
                {data.result.length === 0 && !loading && (
                    <Caption colSpan={2}>No hay productos</Caption>
                )}
                {loading && (
                    <Caption colSpan={2}>
                        <Loading height="auto" scale={0.8} />
                    </Caption>
                )}
            </TBody>
        </DataTable>
    )
}
