import { useHistory } from 'react-router'
import { render, renders } from 'redity'
import sttore, { Sttore } from 'sttore'
import { ModalTools } from '../../sections/modal/types'
import { useModal } from '../../sections/ModalSection'
import { DataModalSection } from '../../sections/ModalSection/types'
import Control from '../Control/Control'
import submit from '../submit'
import val from '../val'
import { Val } from '../val/type'
import ErrorControl from './ErrorControl'
import ManageFields from './ManageFields'
import { SubmitData } from './types'

export default class Base<T> extends ManageFields<T> {
    store: Sttore<T>
    keyRender: string = null
    protected valid: (val: Val<T>, form: T) => void = () => null
    protected requestData: any = null

    constructor(keyRender: string, form: T) {
        super()
        this.keyRender = keyRender
        this.store = sttore<T>(form)
        this.initializeManage(this)
    }

    protected getValues<K extends keyof T>(fieldName: K) {
        const value: T[K] = this.store()[fieldName]
        const helper = this.store.helpers()[fieldName]
        const nextProps = this.propsFields(fieldName)

        return {
            ...nextProps,
            value,
            helper
        }
    }

    readonly errorControl = new ErrorControl<T>()

    private async doneSubmit<S>(
        done: SubmitData<S>['done'],
        push: (path: string) => void,
        data: S
    ) {
        if (done instanceof Control) {
            await done.done()
            done.renderMain()
        } else if (typeof done === 'string') push(done)
        else if (typeof done === 'function') done(data)
    }

    private preSubmit(valid: (val: Val<T>, form: T) => void | boolean) {
        const v = val(this.store())
        const indicator = valid(v, this.store())
        const result = v.runtest()
        if (result) {
            this.store.helpers({ ...this.store.helpers(), ...result })
            renders(this.keyRender)
            return false
        }

        if (indicator === false) return false

        return true
    }

    useSubmit<S>(path: string, props?: SubmitData<S>) {
        const {
            message,
            done,
            expectChange = false,
            onError = () => null,
            ...data
        } = props || {}
        const { push } = useHistory()
        type FormBody = Partial<Record<keyof T, any>> & Record<string, any>

        interface Tools {
            valid: (val: Val<T>, form: T) => void | boolean
            observer: (form: T) => FormBody
        }

        const tools: Tools = {
            observer: null,
            valid: () => null
        }
        const handleSubmit = async (
            ev?: React.FormEvent<HTMLFormElement>,
            statesModal?: ModalTools
        ) => {
            if (ev) ev.preventDefault()
            if (!this.preSubmit(tools.valid)) return
            if (statesModal) statesModal.close()
            if (expectChange && !this.store.change()) {
                this.doneSubmit<S>(done, push, this.requestData)
                return
            }

            const {
                success,
                error,
                data: dataResponse
            } = await submit<S>(path, {
                ...data,
                confirm: message || null,
                body: tools.observer
                    ? tools.observer(this.store())
                    : this.store()
            })

            if (success) {
                this.requestData = dataResponse
                this.doneSubmit<S>(done, push, dataResponse)
                return
            }

            if (!error) return
            this.errorControl.setException(error)

            onError(error)

            if (error.type === 'customer' || error.type === 'development') {
                if (statesModal && error.type === 'customer') statesModal.open()
                renders(this.keyRender)
            }
        }

        const validation = (
            validFn: (val: Val<T>, form: T) => void | boolean
        ) => {
            tools.valid = validFn
        }

        function setObserver<B = FormBody>(cb: (form: T) => B) {
            tools.observer = cb
        }

        return {
            submit: handleSubmit,
            validation,
            observer: setObserver
        }
    }

    useModal = <L = T>(
        Form: () => JSX.Element,
        {
            observer = data => data as any,
            ...dataModal
        }: DataModalSection & { observer?: (data: L) => T }
    ) => {
        const { open, onOpen } = useModal<L>(Form, dataModal)
        onOpen(data => {
            this.init(observer(data))
        })
        return open
    }

    setValue<K extends keyof T>(fieldName: K, value: T[K], toRender = true) {
        this.store.set(fieldName, value)
        this.store.helper(fieldName, '')
        if (toRender) {
            render(this.keyRender, fieldName as string)
        }
    }

    init = (data?: T) => {
        this.errorControl.clear()
        this.store.init()
        if (data) {
            this.store(data)
            this.store.init(this.store)
        }
    }

    test(validFn: (val: Val<T>, form: T) => void) {
        const v = val(this.store())
        validFn(v, this.store())
        const result = v.runtest()
        if (result) {
            this.store.helpers({ ...this.store.helpers(), ...result })
            renders(this.keyRender)
            return false
        }
        return true
    }
}
