import { useRender } from 'redity'
import Loading from '../../../../../components/Loading'
import { storagePage } from '../../../storage'
import SearchProducts from './SearchProducts'
import TableProducts from './TableProducts'

export default function ProductForm() {
    useRender('ProductData')

    return (
        <>
            <SearchProducts />
            <div style={{ minHeight: '400px' }}>
                {storagePage.loadingProductForm && <Loading height="300px" />}
                {!storagePage.loadingProductForm && <TableProducts />}
            </div>
        </>
    )
}
