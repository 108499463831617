import { createContext } from 'react'

interface SimpleTabsContextData {
    value: string
    onStep: (value: string) => void
    type: 'simple' | 'card'
}

const SimpleStepsContext = createContext<SimpleTabsContextData>({
    value: null,
    onStep: () => null,
    type: 'simple'
})

export default SimpleStepsContext
