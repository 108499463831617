import { SIDEBAR_WIDTH } from '../../config/app'
import Menu from './Menu'

export default function Sidebar({ isOpen }: { isOpen: boolean }) {
    return (
        <>
            <div
                className="bg-white relative z-30"
                style={{
                    width: isOpen ? SIDEBAR_WIDTH : '0',
                    minWidth: isOpen ? SIDEBAR_WIDTH : '0',
                    transition: '.5s ease'
                }}
            />
            <aside
                className="fixed h-screen overflow-y-auto z-30"
                style={{
                    width: SIDEBAR_WIDTH,
                    minWidth: SIDEBAR_WIDTH,
                    left: isOpen ? 0 : `-${SIDEBAR_WIDTH}`,
                    transition: '.5s ease'
                }}
            >
                <div className="px-3 pt-4">
                    <img
                        className="m-auto"
                        width="120px"
                        src="https://cutt.ly/PTuebwH"
                    />
                </div>
                <div className="px-3 pt-2">
                    <hr style={{ borderColor: '#edeff5' }} />
                </div>
                <div className="px-3 pt-4">
                    <Menu />
                </div>
            </aside>
            {/* <aside
                className="fixed h-screen shadow overflow-y-auto pt-24 z-20"
                style={{
                    width: SIDEBAR_WIDTH,
                    minWidth: SIDEBAR_WIDTH,
                    left: isOpen ? 0 : `-${SIDEBAR_WIDTH}`,
                    transition: '.5s ease'
                }}
            >
                <div
                    className="pt-12 pl-2 pb-10 fixed bg-white top-0 z-20"
                    style={{
                        width: `calc(${SIDEBAR_WIDTH} - 10px)`
                    }}
                >
                    <img className="m-auto" width="120px" src={Logo} />
                </div>
                <Menu />
            </aside> */}
        </>
    )
}
