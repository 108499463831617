import Typography from '../../../../components/Typography'
import Fields from './Fields'
import TableProducts from './TableProducts'

export default function EditionDiscountFactor() {
    return (
        <>
            <Typography type="header-3">Información General</Typography>
            <Fields />
            <Typography type="header-3" className="mt-5">
                Productos afectados
            </Typography>
            <TableProducts />
        </>
    )
}
