import Sidebar from './Sidebar'
import Navbar from './Navbar'
import LayoutContext from '../../contexts/layout-context'
import { memo, ReactNode, useState, useEffect } from 'react'
import { SIDEBAR_WIDTH } from '../../config/app'

interface PropsLayout {
    children: React.ReactNode
}

export default function Layout({ children }: PropsLayout) {
    const [values, setState] = useState({
        isSidebarOpen: true
    })

    function open() {
        setState({ isSidebarOpen: true })
    }
    function close() {
        setState({ isSidebarOpen: false })
    }

    function toogle() {
        setState({ isSidebarOpen: !values.isSidebarOpen })
    }

    useEffect(() => {
        window
            .matchMedia('(min-width: 992px)')
            .addEventListener('change', () =>
                setState({ isSidebarOpen: false })
            )
    }, [])

    return (
        <main className="flex bg-background min-h-screen relative">
            <LayoutContext.Provider value={{ ...values, toogle, open, close }}>
                <Sidebar isOpen={values.isSidebarOpen} />
                <div
                    style={{
                        width: values.isSidebarOpen
                            ? `calc(100% - ${SIDEBAR_WIDTH})`
                            : '100%',
                        transition: '.5s ease'
                    }}
                >
                    <Navbar onClickMenu={() => toogle()} />
                    <WrappedChidlren>{children}</WrappedChidlren>
                </div>
            </LayoutContext.Provider>
        </main>
    )
}

const WrappedChidlren = memo(function Children({
    children
}: {
    children: ReactNode
}) {
    return <>{children}</>
})
