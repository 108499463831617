import TableDuty from './TableDuty'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'
import Fields4 from './Fields4'
import TableISC from './TableISC'
import TableIVA from './TableIVA'
import Fields0 from './Fields0'

export function FormDuty(props) {
    return (
        <>
            <TableDuty {...props} />
            <Fields1 isExchangeHidden />
            <Fields2 />
            <Fields3 />
            <Fields4 />
        </>
    )
}

export function FormISC(props) {
    return (
        <>
            <Fields0 />
            <TableISC {...props} />
            <Fields1 />
            <Fields2 />
            <Fields3 />
        </>
    )
}

export function FormIVA(props) {
    return (
        <>
            <TableIVA {...props} />
            <Fields1 isExchangeHidden />
            <Fields2 />
            <Fields3 />
        </>
    )
}
