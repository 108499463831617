export default function Eye(props) {
    return (
        <svg
            width={15}
            height={11}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.625 5.5A7.392 7.392 0 0 1 7.5.812 7.392 7.392 0 0 1 14.375 5.5 7.392 7.392 0 0 1 7.5 10.188 7.392 7.392 0 0 1 .625 5.5Zm12.387 0A6.106 6.106 0 0 0 7.5 2.062 6.106 6.106 0 0 0 1.988 5.5 6.106 6.106 0 0 0 7.5 8.938 6.106 6.106 0 0 0 13.012 5.5ZM7.5 3.937a1.563 1.563 0 1 1-.001 3.127A1.563 1.563 0 0 1 7.5 3.937ZM4.687 5.5A2.817 2.817 0 0 1 7.5 2.687 2.817 2.817 0 0 1 10.313 5.5 2.817 2.817 0 0 1 7.5 8.313 2.817 2.817 0 0 1 4.687 5.5Z"
            />
        </svg>
    )
}
