import LogisticalConcept from '../../models/LogisticalConcept'
import ControlList from '../../utilities/Control/ControlList'
import ControlTabs from '../../utilities/ControlTabs'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'

export const controlTabs = new ControlTabs()

export const controlList = new ControlList<LogisticalConcept>(Keys.MAIN, {
    path: '/v5.6/apis/search_logistical_concepts',
    theads: [
        '#',
        { value: 'name', label: 'Concepto' },
        'N° de documentos relacionados',
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})

export const formPaymentConcept = new FormEvents(Keys.FORM, {
    name: ''
})
