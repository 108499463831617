import BtnActionTable from '../../../../components/BtnActionTable'
import { Step3 } from '../../types'
import useRetreat from './useRetreat'

export default function EditWithdrawal({
    retreat,
    index
}: {
    retreat: Step3.TableWithdrawal
    index: number
}) {
    const open = useRetreat(index)

    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            disabled={!retreat.enabled}
            onClick={() =>
                open({
                    country: {
                        label: retreat.country.name,
                        image: retreat.country.image_url,
                        value: retreat.country.id.toString()
                    },
                    place_retreat: retreat.place_retreat,
                    address: retreat.address
                })
            }
        />
    )
}
