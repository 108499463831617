import Icon from '../../../../components/Icon'
import { TableList } from '../../../../sections/ListForm'
import { RespondeUpdateFactor } from '../../../../types/product_price'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { put } from '../../../../utilities/http'
import Notify from '../../../../utilities/Notify'
import { Popup } from '../../../../utilities/popup'
import { Progress } from '../../../../utilities/progress'
import { tablePricesControl } from '../../storage'
import EditPrice from './EditPrice'

export default function TablePrices() {
    async function handleDel(index: number) {
        const { id } = tablePricesControl.get(index)

        const result = await Popup.delete(
            'Eliminar registro',
            <>
                Al eliminar el factor especial se tomará el factor de
                subcategoría para calcular los precios ¿Estás seguro de eliminar
                el <b>factor especial</b>?
            </>
        )
        if (!result) return

        Progress.open('Cargando')

        put<RespondeUpdateFactor>(
            `/v5.6/apis/product_companies/${id}/sale_price_special_factor`,
            { special_factor: null }
        ).then(response => {
            if (response.error) {
                Progress.close()
                Popup.error('Lo sentimos', response.error)
            } else {
                const { product_provider_company, special_factor } =
                    response.data.result

                const sale_price = product_provider_company?.sale_price
                    ? `${
                          product_provider_company.company_currency.currency
                              .symbol
                      } ${buildNumberFormat(
                          product_provider_company.sale_price
                      )}`
                    : '-'

                tablePricesControl.put(index, {
                    sale_price: sale_price,
                    special_factor: special_factor
                        ? special_factor.toString()
                        : '',
                    discount_price: product_provider_company?.discount_price
                        ? `${
                              product_provider_company.company_currency.currency
                                  .symbol
                          } ${buildNumberFormat(
                              product_provider_company.discount_price
                          )}`
                        : '-'
                })
                Progress.close()
                Notify.success('La operación se ha completado exitosamente')
            }
        })
    }

    return (
        <TableList control={tablePricesControl}>
            {(data, _, e) => (
                <tr key={e}>
                    <td>
                        <div className="flex items-center">
                            <img
                                src={data.company.country.image_url}
                                className="mr-2 w-8"
                            />
                            <p
                                className={`${
                                    !data.company.is_enabled && 'line-through'
                                }`}
                            >
                                {buildCompanyName(data.company.name)}
                            </p>
                        </div>
                    </td>
                    <td>{data.factor}</td>
                    <td>
                        <div className="w-full">
                            <div className="flex justify-between w-full">
                                <p>{data.special_factor || '-'}</p>
                                <div>
                                    <EditPrice data={data} />
                                    {data.special_factor !== '' && (
                                        <Icon
                                            name="bs-trash"
                                            pointer
                                            disabled={!data.company.is_enabled}
                                            onClick={() => {
                                                handleDel(e - 1)
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>{data.discount}</td>
                    <td>{data.unit_cost || '-'}</td>
                    <td>{data.sale_price}</td>
                    <td>{data.discount_price}</td>
                </tr>
            )}
        </TableList>
    )
}
