import { useState } from 'react'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import SearchField from '../../../../components/SearchField'
import Typography from '../../../../components/Typography'
import Ul from '../../../../components/Ul'
import { ViewProductProps } from './types'

export default function Norms({ data }: ViewProductProps) {
    const { product, incompatibles } = data
    const [search, setSearch] = useState('')

    const products = incompatibles.filter(
        prod =>
            prod.name.toLowerCase().search(search.toLowerCase()) > -1 ||
            prod.code.toLowerCase().search(search.toLowerCase()) > -1
    )

    return (
        <>
            <Typography className="mb-2" type="header-3">
                Norma
            </Typography>
            <div className="grid grid-cols-2 gap-4 mb-6">
                <Ul>
                    <li>
                        Vida útil del producto: <b>{product.lifetime}</b>
                    </li>
                    <li>
                        Medio de transporte:{' '}
                        <b>
                            {product.marine_transport && 'Marítimo, '}
                            {product.air_transport && 'Aéreo, '} Terrestre
                        </b>
                    </li>
                </Ul>
                <Ul>
                    <li>
                        Refrigeración:{' '}
                        <b>
                            {product.min_temperature} -{' '}
                            {product.max_temperature}°C
                        </b>
                    </li>
                </Ul>
            </div>
            <Typography className="mb-2" type="header-3">
                Reglas de Almacenaje
            </Typography>
            <SearchField
                fullWidth
                placeholder="Buscar por código o nombre de producto"
                className="mb-2"
                onChange={ev => setSearch(ev.target.value)}
                value={search}
                onClear={() => setSearch('')}
            />
            <DataTable>
                <THead>
                    <tr>
                        <th>Producto</th>
                        <th>Categoría</th>
                        <th>Subcategoría</th>
                    </tr>
                </THead>
                <TBody>
                    {products.map(prod => (
                        <tr key={prod.id}>
                            <td>
                                <p>{prod.name}</p>
                                <span>{prod.code}</span>
                            </td>
                            <td>{prod.subcategory.category.name}</td>
                            <td>{prod.subcategory.name}</td>
                        </tr>
                    ))}
                    {products.length === 0 && (
                        <Caption colSpan={3}>No existen registros</Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
