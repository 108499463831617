import { render } from 'redity'
import modal from '../../sections/modal'
import { get } from '../../utilities/http'
import { Popup } from '../../utilities/popup'
import { formFactor, storagePage, tableProductsControl } from './storage'
import { SubcategoryValuesData } from './types'
import EditionDiscountFactor from './View/EditionDiscountFactor'
import { Keys } from './constants'

export default function useSubmitFactor(subcategoryValueId: number) {
    const { submit, validation, observer } = formFactor.useSubmit(
        `/v5.6/apis/subcategory_value/${subcategoryValueId}`,
        {
            title: 'asfafs',
            method: 'put',
            done: () => {
                for (const subcategoryValue of storagePage.subcategoryValues) {
                    if (subcategoryValueId === subcategoryValue.id) {
                        const { factor, discount } = formFactor.store()
                        subcategoryValue.factor = Number(factor)
                        subcategoryValue.discount = Number(discount)
                        render(Keys.MAIN)
                        break
                    }
                }
            }
        }
    )

    validation(v => {
        v.discount.isEmpty('Se requiere ingresar el descuento')
        v.factor.isMinor(1.25, 'El valor mímino es 1.25')
        v.factor.isEmpty('Se requiere ingresar el factor')
    })

    observer(f => ({
        factor: Number(f.factor),
        discount: Number(f.discount)
    }))

    function handleOpen() {
        modal(
            EditionDiscountFactor,
            async () => {
                const [res1] = await Promise.all([
                    get<SubcategoryValuesData>(
                        `/v5.6/apis/subcategory_value/${subcategoryValueId}`
                    )
                ])

                if (res1.error) {
                    Popup.error(
                        'Algo salió mal...',
                        'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
                    )
                    return null
                }

                const { subcategory, factor, discount } = res1.data.result

                await tableProductsControl.load(
                    `/v5.6/apis/simulate_subcategory_values/${subcategoryValueId}`,
                    {
                        fac: Number(factor),
                        desc: Number(discount)
                    }
                )

                formFactor.init({
                    factor: factor.toString(),
                    discount: discount.toString(),
                    subcategory_name: subcategory.name,
                    category_name: subcategory.category.name,
                    current_factor: factor.toString(),
                    current_discount: discount.toString()
                })

                return {
                    title: 'Editar factor y descuento de subcategoría',
                    onSubmit: submit
                }
            },
            'md'
        )
    }

    return handleOpen
}
