import buildCompanyName from '../../utilities/buildCompanyName'
import { formInformation, productsControl, responseSimulate } from './storage'
import { GetCostingEstimate, ProductEstimateResponse } from './types'

export default function useInitialEdition() {
    return function load(costing_estimate: GetCostingEstimate) {
        const { reception, comment, estimate_cost, costing_estimate_products } =
            costing_estimate

        const company = reception.document.company
        formInformation.setValue('company', {
            label: buildCompanyName(company.name),
            value: company.id.toString(),
            image: company.country.image_url
        })

        formInformation.setValue('purchaseReceipt', {
            label: reception.code,
            value: reception.id.toString()
        })
        formInformation.setValue(
            'purchaseCost',
            reception.purchase_local_cost.toString()
        )
        formInformation.setValue('comment', comment)

        formInformation.setValue('estimatedAmount', estimate_cost.toFixed(2))

        const products_simulate: ProductEstimateResponse[] =
            costing_estimate_products.map(cep => ({
                product_code:
                    cep.reception_product.purchase_product.requisition_product
                        .product.code,
                product_description:
                    cep.reception_product.purchase_product.requisition_product
                        .product.description,
                quantity: cep.reception_product.quantity,
                purchase_cost:
                    cep.reception_product.purchase_product.unit_purchase_price *
                    cep.reception_product.quantity *
                    reception.exchange_rate,
                estimate_cost: cep.cost
            }))
        responseSimulate.total_estimate_cost = estimate_cost
        responseSimulate.total_purchase_cost = reception.purchase_local_cost
        productsControl.setList(products_simulate)
    }
}
