import ListFormControl from '../sections/ListForm/ListFormControl'

/**
 * @deprecated use del of ListFormControl
 */
export default function useRemove<T extends { id?: number }>(
    listFormControl: ListFormControl<T>,
    onRemove: (data: T) => boolean | void = () => true
) {
    async function del(index: number) {
        const list: T[] = []
        let dataRemoved: T = null
        let i = 0
        for (const form of listFormControl.getDataList()) {
            if (i === index) {
                dataRemoved = form
            } else {
                list.push(form)
            }
            i++
        }

        listFormControl.setList(list)
        listFormControl.renderMain()
        dataRemoved && onRemove(dataRemoved)
    }

    return del
}
