import { formDispatch, tableDispatch } from '../storage'

export default function createEditionDispatch(index?: number) {
    const {
        country,
        type_dispatch,
        place_dispatch,
        place_dispatch_text,
        address,
        address_select
    } = formDispatch.store()

    const placeDispatchName =
        type_dispatch.value === '4' ? place_dispatch_text : place_dispatch.label

    const placeDispatchId =
        type_dispatch.value === '4' ? null : parseInt(place_dispatch.value)

    const addressData =
        type_dispatch.value === '1' ? address_select.label : address

    const address_id =
        type_dispatch.value === '1' ? parseInt(address_select.value) : null

    if (index === undefined) {
        const dispatchesData = tableDispatch.getDataList()

        const hasMain = !!dispatchesData.find(
            d => d.is_main && d.country.id === parseInt(country.value)
        )

        const data = {
            country: {
                id: parseInt(country.value),
                image_url: country.image,
                name: country.label
            },
            type_dispatch: {
                id: parseInt(type_dispatch.value),
                name: type_dispatch.label
            },
            place_dispatch_id: placeDispatchId,
            place_dispatch: placeDispatchName,
            address: addressData,
            address_id,
            is_main: !hasMain,
            enabled: true
        }

        tableDispatch.push(data)
    } else {
        tableDispatch.put(index, {
            country: {
                id: parseInt(country.value),
                name: country.label,
                image_url: country.image
            },
            type_dispatch: {
                id: parseInt(type_dispatch.value),
                name: type_dispatch.label
            },
            place_dispatch_id: placeDispatchId,
            place_dispatch: placeDispatchName,
            address: addressData,
            address_id
        })
    }
}
