import { ChangeEvent, DependencyList, useEffect, useState } from 'react'
import { get } from '../utilities/http'
import { HttpResponse } from '../utilities/http/type'
import { ObserverTools } from '../utilities/ControlRequest'
import { parse, merge } from 'fter'
import Notify from '../utilities/Notify'

const pagination = {
    total: 0,
    page: 1,
    pages: 1
}

export default function useGet<T>(
    path: string | ObserverTools<T>,
    defaultValue?: T,
    deps?: DependencyList
): UseGetDataResult<T> {
    const [result, setResult] = useState<
        HttpResponse<T> & { loading: boolean; search: string }
    >(() => ({
        data: {
            result: defaultValue || null,
            pagination
        },
        error: null,
        loading: typeof path === 'string' ? true : path.initiateLoad,
        search: ''
    }))

    const getPath = () => (typeof path === 'string' ? path : path.getFullPath())

    function send(res: HttpResponse<T>) {
        const resultData = res.data ? res.data.result : defaultValue
        const currentPagination = res.data ? res.data.pagination : pagination
        const loading = res.error?.type === 'cancel'
        setResult({
            data: {
                result: resultData,
                pagination: currentPagination
            },
            error: res.error,
            loading,
            search: ''
        })
        return {
            data: {
                result: resultData,
                pagination: currentPagination
            },
            error: res.error
        }
    }
    useEffect(() => {
        if (typeof path === 'string') {
            get<T>(path).then(send)
        } else if (path.initiateLoad) {
            get<T>(getPath()).then(send)
        }
        return () => {
            if (typeof path !== 'string') {
                path.deleteObserver()
            }
        }
    }, deps || [])

    const reload = async () => {
        setResult({
            ...result,
            loading: true
        })
        return send(await get<T>(getPath()))
    }

    if (typeof path !== 'string') {
        path.setObserver(reload)
    }

    const onSearch = async (ev: ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value

        let fullPath = null
        if (value === '') {
            fullPath = getPath()
        } else {
            fullPath = merge(getPath(), parse({ search: value }))
        }

        setResult({
            ...result,
            loading: true,
            search: value
        })
        const currentResult = await get<T>(fullPath)
        if (currentResult.error && currentResult.error.type === 'cancel') {
            return
        }

        if (currentResult.data === null) {
            Notify.error(
                'Hubo problemas en la conexión, por favor vuelva reintentar'
            )
            return
        }

        setResult(prev => ({ ...prev, ...currentResult, loading: false }))
    }

    return {
        data: result.data,
        error: result.error,
        reload,
        props: {
            onSearch,
            loading: result.loading,
            search: result.search
        },
        loading: result.loading
    }
}

export interface UseGetDataResult<T> extends HttpResponse<T> {
    reload: () => Promise<HttpResponse<T>>
    props: {
        loading: boolean
        onSearch: (ev: ChangeEvent<HTMLInputElement>) => void
        search: string
    }
    loading: boolean
}
