import FileModel from '../../../../../models/FileModel'
import modal from '../../../../../sections/modal'
import { parseFile } from '../../../../../utilities/fileTools'
import { get } from '../../../../../utilities/http'
import {
    formDutyISCIVA,
    reqLDCostDistribution,
    reqLogisticalCost1,
    reqLogisticalCost2,
    tableISCControl
} from '../storageStep2'
import Cost from '../cost'
import { loadForm } from './utilities'
import { FormISC } from '.'

export default function useSubmitISC(logistical_cost_id: number) {
    const { submit, validation, observer } = formDutyISCIVA.useSubmit(
        `/v5.6/apis/logistical_costs/specific/${logistical_cost_id}`,
        {
            done: () => {
                reqLogisticalCost1.load()
                reqLogisticalCost2.load()
            },
            method: 'put'
        }
    )

    let currentFiles: FileModel[] = []

    validation(() => {
        return tableISCControl.test(f => {
            f.original_cost.isEmpty('Se requiere ingresar el costo')
        })
    })

    observer<Cost.PutBodyModalSpecific>(f => {
        return {
            agent_id: f.agent && parseInt(f.agent.value),
            setting_local_cost: Number(f.setting_local_cost),
            document_number: f.document_number,
            reception_ids: f.rcs.map(option => parseInt(option.value)),
            currency_id: parseInt(f.currency.value),
            exchange_to_local: Number(f.exchange_to_local),
            original_cost_products: tableISCControl.getDataList().map(isc => ({
                product_id: isc.product_id,
                original_cost: Number(isc.original_cost)
            })),
            new_files: f.files
                .filter(file => !file.id)
                .map(file => parseFile(file)),
            files_to_delete: currentFiles
                .filter(cf => !f.files.find(file => file.id === cf.id))
                .map(cf => cf.id)
        }
    })

    return function handleModal() {
        modal(
            FormISC,
            async () => {
                const res = await get<Cost.ResponseLCSpecific>(
                    `/v5.6/apis/logistical_costs/specific/${logistical_cost_id}`
                )

                currentFiles = res.data.result.files

                loadForm(res.data.result)

                reqLDCostDistribution.setParam(
                    'logistical_declaration_id',
                    res.data.result.logistical_declaration_id
                )

                return {
                    title: 'ISC (Por producto)',
                    onSubmit: submit,
                    props: res.data.result
                }
            },
            'md'
        )
    }
}
