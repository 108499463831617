import {
    FormControl,
    FormControls,
    IterateOptions
} from '../../../../../components/FormUtilities'
import Option from '../../../../../components/Option'
import SelectTouch from '../../../../../components/SelectTouch'
import useGet from '../../../../../hooks/useGet'
import useStorage from '../../../../../hooks/useStorage'
import LogisticalConcept from '../../../../../models/LogisticalConcept'
import { formDutyISCIVA } from '../storageStep2'

export default function Fields0() {
    return (
        <FormControls className="mb-4">
            <ConceptField />
            <CurrencyField />
        </FormControls>
    )
}

function ConceptField() {
    const { props } = formDutyISCIVA.useSelect('logistical_concept')
    const { data } = useGet<LogisticalConcept[]>(
        '/v5.6/apis/search_logistical_concepts',
        []
    )

    return (
        <FormControl label="Concepto">
            <SelectTouch {...props} placeholder="Seleccionar concepto" disabled>
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function CurrencyField() {
    const { currencies } = useStorage()
    const { props } = formDutyISCIVA.useSelect('currency')

    return (
        <FormControl label="Moneda">
            <SelectTouch {...props} placeholder="Seleccionar moneda" disabled>
                {currencies.map(currency => (
                    <Option
                        key={currency.id}
                        label={`${currency.name} (${currency.symbol})`}
                        value={currency.id.toString()}
                    />
                ))}
            </SelectTouch>
        </FormControl>
    )
}
