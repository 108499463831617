import { ItemRoute } from './types'
import CostEstimates from '../../pages/CostEstimates'
import Costs from '../../pages/Costs'
import CostNational from '../../pages/Cost/National'
import CostInternational from '../../pages/Cost/International'
import CostEstimate from '../../pages/CostEstimate'

const Costing: ItemRoute[] = [
    {
        label: 'Costeo',
        title: 'Costeo',
        cu: 'CU261',
        path: '/costing',
        Component: Costs,
        routes: [
            {
                cu: 'CU262',
                title: 'Nuevo costeo nacional',
                path: '/creation/national',
                Component: CostNational
            },
            {
                cu: 'CU281',
                title: 'Editar costeo nacional',
                path: '/edition/:logistical_declaration_id/national',
                Component: CostNational
            },
            {
                cu: 'CU263',
                title: 'Nuevo costeo de importación',
                path: '/creation/international',
                Component: CostInternational
            },
            {
                cu: 'CU281',
                title: 'Editar costeo de importación',
                path: '/edition/:logistical_declaration_id/international',
                Component: CostInternational
            }
        ]
    },
    {
        cu: 'CU287',
        label: 'Costeo anticipado',
        title: 'Costeo anticipado',
        path: '/cost_estimates',
        Component: CostEstimates,
        routes: [
            {
                cu: 'CU288',
                title: 'Nueva estimación de costeo',
                path: '/create',
                Component: CostEstimate
            },
            {
                cu: 'CU289',
                title: 'Editar estimación de costeo',
                path: '/:cost_estimate_id/edition',
                Component: CostEstimate
            }
        ]
    }
]

export default Costing
