import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { storagePage } from '../../../storage'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { LogisticalDeclarationPDFData } from '../../../types'
import HelveticaBold from '../../../../../assets/fonts/HelveticaBold.otf'
import { generalStyles } from '../GeneralStyles'
import {
    LOGISTICAL_CONCEPT__SETTING_DUTY,
    LOGISTICAL_CONCEPT__SETTING_IVA
} from '../../../../../config/constants'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const styles = StyleSheet.create({
    ...generalStyles,
    container_nationalization_costs: {
        marginTop: '14px'
    },
    cellCount: {
        width: '6%'
    },
    cellConcept: {
        width: '25%'
    },
    cellAgent: {
        display: 'flex',
        flexDirection: 'column',
        width: '21%'
    },
    cellAmountDolar: {
        width: '18%'
    },
    cellExchange: {
        width: '12%'
    },
    cellAmountLocal: {
        width: '18%'
    },
    cellFooterName: {
        width: '58%',
        fontFamily: 'helvetica-bold'
    }
})

export default function TableNationalizationCosts({
    data
}: {
    data: LogisticalDeclarationPDFData
}) {
    const sym = storagePage.currency

    return (
        <View style={styles.container_nationalization_costs}>
            <Text style={styles.container__title}>
                Costos de Nacionalización
            </Text>
            <View style={styles.table}>
                <View style={styles.row}>
                    <Text style={[styles.table__th, styles.cellCount]}>#</Text>
                    <Text style={[styles.table__th, styles.cellConcept]}>
                        Concepto
                    </Text>
                    <Text style={[styles.table__th, styles.cellAgent]}>
                        Agente
                    </Text>
                    <Text style={[styles.table__th, styles.cellAmountDolar]}>
                        Monto
                    </Text>
                    <Text style={[styles.table__th, styles.cellExchange]}>
                        TC
                    </Text>
                    <Text style={[styles.table__th, styles.cellAmountLocal]}>
                        Monto
                    </Text>
                </View>
                {data.nationalization_costs
                    .filter(
                        d =>
                            d.logistical_concept_id !==
                                LOGISTICAL_CONCEPT__SETTING_DUTY &&
                            d.logistical_concept_id !==
                                LOGISTICAL_CONCEPT__SETTING_IVA
                    )
                    .map((d, i) => (
                        <View key={i} style={styles.row}>
                            <Text style={[styles.table__td, styles.cellCount]}>
                                {i + 1}
                            </Text>
                            <Text
                                style={[styles.table__td, styles.cellConcept]}
                            >
                                {d.logistical_concept.name}
                            </Text>
                            <View style={[styles.table__td, styles.cellAgent]}>
                                {d.agent && <Text>{d.agent.name}</Text>}
                                {d.agent && <Text>{d.document_number}</Text>}
                                {!d.agent && <Text>-</Text>}
                            </View>
                            <Text
                                style={[
                                    styles.table__td,
                                    styles.cellAmountDolar,
                                    styles.textRight
                                ]}
                            >
                                $ {buildNumberFormat(d.original_cost)}
                            </Text>
                            <Text
                                style={[
                                    styles.table__td,
                                    styles.cellExchange,
                                    styles.textRight
                                ]}
                            >
                                {d.exchange_to_local
                                    ? buildNumberFormat(d.exchange_to_local)
                                    : '-'}
                            </Text>
                            <Text
                                style={[
                                    styles.table__td,
                                    styles.cellAmountLocal,
                                    styles.textRight
                                ]}
                            >
                                {sym} {''}
                                {buildNumberFormat(
                                    d.local_cost + d.setting_local_cost
                                )}
                            </Text>
                        </View>
                    ))}
                <View style={styles.row}>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellFooterName,
                            styles.textRight
                        ]}
                    >
                        CIF + Arancel + Tasas + ISC
                    </Text>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellExchange,
                            styles.textRight
                        ]}
                    >
                        ${' '}
                        {buildNumberFormat(data.data_summary.cif_plus_original)}
                    </Text>
                    <Text
                        style={[styles.table__footer__td, styles.cellExchange]}
                    ></Text>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellAmountLocal,
                            styles.textRight
                        ]}
                    >
                        {sym} {''}
                        {buildNumberFormat(data.data_summary.cif_plus_local)}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellFooterName,
                            styles.textRight
                        ]}
                    >
                        IVA (En base a CIF + Arancel + ISC + Tasa C/IVA)
                    </Text>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellExchange,
                            styles.textRight
                        ]}
                    >
                        $ {buildNumberFormat(data.data_summary.iva_original)}
                    </Text>
                    <Text
                        style={[styles.table__footer__td, styles.cellExchange]}
                    ></Text>
                    <Text
                        style={[
                            styles.table__footer__td,
                            styles.cellAmountLocal,
                            styles.textRight
                        ]}
                    >
                        {sym} {''}
                        {buildNumberFormat(data.data_summary.iva_local)}
                    </Text>
                </View>
            </View>
        </View>
    )
}
