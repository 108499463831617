export default function Star(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19 7.13L10 .5l2.81 6.63 7.19.61-5.46 4.73 1.64 7.03L10 15.77 3.82 19.5l1.64-7.03L0 7.74l7.19-.61zm3.78.79L10 5.63 9.03 7.9l-.47 1.11-1.2.1-2.47.21 1.88 1.63.91.79-.27 1.18-.56 2.41 2.12-1.28 1.03-.62 1.03.64 2.12 1.28-.56-2.41-.27-1.18.91-.79 1.88-1.63-2.47-.21-1.2-.1-.47-1.11z"
            />
        </svg>
    )
}
