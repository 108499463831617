import Button from '../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import TableCreditLine from './TableCreditLine'
import useCreditLine from './useCreditLine'

export default function CreditLine() {
    const open = useCreditLine()
    return (
        <Panel mode="form">
            <PanelHeader title="Línea de crédito">
                <Button outline size="sm" onClick={() => open()}>
                    + Agregar
                </Button>
            </PanelHeader>
            <PanelBody>
                <TableCreditLine />
            </PanelBody>
        </Panel>
    )
}
