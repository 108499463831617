function FindPage(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0H2C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6zM2 2h7l5 5v8.58l-1.84-1.84a4.992 4.992 0 00-.64-6.28A4.96 4.96 0 008 6a5 5 0 00-3.53 1.46 4.98 4.98 0 000 7.05 4.982 4.982 0 006.28.63L13.6 18H2V2zm6 11.98c.8 0 1.55-.32 2.11-.88.57-.56.88-1.31.88-2.11 0-.8-.32-1.55-.88-2.11C9.55 8.31 8.8 8 8 8c-.8 0-1.55.31-2.11.88-.57.56-.88 1.31-.88 2.11 0 .8.32 1.55.88 2.11.56.57 1.31.88 2.11.88z"
            />
        </svg>
    )
}

export default FindPage
