import { tableSaleDiscountsControl, formSaleDiscounts } from '../storage'

export default function valStep2() {
    let pass = true

    formSaleDiscounts.test(v => {
        v.company.isEmpty('Se requiere seleccionar la compañía')
    })

    // if (tableSaleDiscountsControl.getDataList().length === 0) {
    //     tableSaleDiscountsControl.setMessage(
    //         'Se requiere registrar al menos un descuento por volumen'
    //     )
    //     pass = false
    // } else {
    //     tableSaleDiscountsControl.setMessage('')
    // }

    if (
        !formSaleDiscounts.test(v => {
            v.inventory_barrier.isMinor(
                1,
                'Se requiere ingresar un valor mayor a 0'
            )
        })
    ) {
        pass = false
    }

    if (!pass) return false

    return tableSaleDiscountsControl.test((v, f, index) => {
        v.min_quantity.isMinor(1, 'La cantidad mínima no puede ser 0')
        v.min_quantity.isEmpty(
            'Se deben registrar cantidades mínima y máxima para guardar un descuento por volumen'
        )
        v.max_quantity.isEmpty(
            'Se deben registrar cantidades mínima y máxima para guardar un descuento por volumen'
        )
        v.discount.isEmpty(
            'Se deben registrar cantidades mínima y máxima para guardar un descuento por volumen'
        )

        v.min_quantity.condition(value => {
            if (value >= parseInt(f.max_quantity)) {
                return 'El valor de la cantidad mínima debe ser menor que la cantidad máxima'
            }

            for (
                let i = 0;
                i < tableSaleDiscountsControl.getDataList().length;
                i++
            ) {
                const { min_quantity, max_quantity } =
                    tableSaleDiscountsControl.getDataList()[i]

                if (i !== index) {
                    if (
                        value >= parseInt(min_quantity) &&
                        value <= parseInt(max_quantity)
                    ) {
                        return 'El rango no debe contener a otro'
                    }
                }
            }
        })

        v.discount.condition(value => {
            if (value < 0 || value > 100) {
                return 'El valor del descuento debe encontrarse entre 0 y 100'
            }
        })
    })
}
