import moment from 'moment'
import Rules from '../../../components/Rules'
import useStorage from '../../../hooks/useStorage'
import WorkingPeriod from '../../../models/WorkingPeriod'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { controlList } from '../storage'

export default function ActiveDeactivate({
    workingPeriods
}: PropsActiveDeactive) {
    const { companiesFromSession } = useStorage()

    const company_id = controlList.filter.getParam('company_id')
    const company = companiesFromSession.find(
        com => com.id.toString() === company_id
    )

    return (
        <Rules cu={workingPeriods.is_enabled ? 'CU134' : 'CU133'}>
            <ActivateDeactivate
                isEnabled={workingPeriods.is_enabled}
                control={controlList}
                disabled={!company?.is_enabled}
                iconHide
                type="button"
                body={{
                    working_period_id: workingPeriods.id
                }}
                className="mr-2"
            >
                <Active
                    label="Activar periodo"
                    path="/v5.6/apis/working_periods/activate"
                >
                    Al activar el periodo de trabajo, se podran registrar
                    operaciones de inventario desde el periodo{' '}
                    {moment.months(workingPeriods.month - 1)} hasta el periodo
                    Actual de compra y venta. ¿Estás seguro de activar el
                    periodo de trabajo{' '}
                    <b className="text-primary">
                        {moment.months(workingPeriods.month - 1)}
                    </b>
                    ?
                </Active>
                <Deactive
                    label="Desactivar periodo"
                    path="/v5.6/apis/working_periods/deactivate"
                >
                    Al desactivar este periodo de trabajo, ya no se podrán
                    realizar operaciones de inventario desde{' '}
                    <b className="text-primary">
                        {moment.months(workingPeriods.month - 1)}
                    </b>{' '}
                    - Actual. ¿Está seguro de desactivar el periodo de trabajo
                    de{' '}
                    <b className="text-primary">
                        {moment.months(workingPeriods.month - 1)}
                    </b>
                    ?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}

interface PropsActiveDeactive {
    workingPeriods: WorkingPeriod
}
