import useGet from '../../../../../hooks/useGet'
import { storagePage } from '../../../storage'
import {
    reqLogisticalCost1,
    tableInternationalCostsControl
} from '../storageStep2'
import { ResponseLogisticalCost } from '../../../types'

export default function useTableInternationalCost() {
    const { data, loading } = useGet<ResponseLogisticalCost[]>(
        reqLogisticalCost1.observer(
            `1>/v5.6/apis/logistical_declarations/${storagePage.logistical_declaration.id}/logistical_costs?type_logistical_cost_source_id=1&version=${storagePage.version}&sort=logistical_concept_id`,
            true
        ),
        []
    )

    function getReception(
        lcrs: ResponseLogisticalCost['logistical_cost_receptions']
    ) {
        return lcrs.map(lcr => lcr.reception.code).join(', ')
    }

    if (!loading) {
        tableInternationalCostsControl.setList(
            data.result.map(lc => ({
                id: lc.id,
                document_number: lc.document_number,
                agent_name: lc.agent ? lc.agent.name : '',
                concept: lc.logistical_concept.name,
                type: lc.type_logistical_cost.name,
                logistical_concept_id: lc.logistical_concept.id,
                cost_to_receipt: lc.in_all_receptions
                    ? 'Todos'
                    : getReception(lc.logistical_cost_receptions),
                original_cost: lc.original_cost,
                exchange_rate: lc.exchange_to_local,
                local_cost: lc.local_cost + lc.setting_local_cost,
                setting_local_cost: lc.setting_local_cost,
                symbol: lc.currency.symbol,
                has_files: lc.files.length > 0
            }))
        )
    }

    /**
     * Flete y seguro
     */
    const originalCost = data.result.reduce(function (a, b) {
        return a + b.original_cost
    }, 0)

    const localCost = data.result.reduce(function (a, b) {
        return a + b.local_cost
    }, 0)

    storagePage.currentCifDolar = storagePage.currentFobDolar + originalCost
    storagePage.currentCifLocal = storagePage.currentFobLocal + localCost

    return {
        tableInternationalCostsControl,
        cifDolar: storagePage.currentCifDolar,
        cifLocal: storagePage.currentCifLocal,
        currentSymbol: storagePage.currentCurrency.symbol
    }
}
