import moment from 'moment'
import { render } from 'redity'
import useRule from '../../hooks/useRule'
import { Exception } from '../../utilities/http/type'
import { Popup } from '../../utilities/popup'
import { formConsumptionVoucher, productsControl, storagePage } from './storage'
import { BodyPost, BodyPut, Form } from './types'

export default function useSubmit(consumer_voucher_id?: number) {
    const { submit, validation, observer } = formConsumptionVoucher.useSubmit(
        consumer_voucher_id
            ? `/v5.6/apis/consumer_voucher/${consumer_voucher_id}`
            : '/v5.6/apis/consumer_voucher',
        {
            message: '¿Está seguro de guardar el vale de consumo?',
            done: '/movements/consumption_vouchers',
            method: consumer_voucher_id ? 'put' : 'post',
            onError: error => {
                handleError(error)
            }
        }
    )
    const math = useRule('CU168')

    validation(v => {
        v.company.isEmpty('Se requiere seleccionar una compañía')
        v.warehouse.isEmpty('Se requiere seleccionar un almacén')
        v.stowage.isEmpty('Se requiere seleccionar una bodega')
        const { current_type_stowage_id_origin } = storagePage
        if (
            current_type_stowage_id_origin === 2 ||
            current_type_stowage_id_origin === 6 ||
            current_type_stowage_id_origin === 11
        ) {
            v.sub_stowage.isEmpty('Se requiere seleccionar una sub bodega')
        }
        v.tag.isEmpty('Se requiere seleccionar una etiqueta')
        if (math) {
            v.request_date.isEmpty(
                'Se requiere seleccionar una fecha de emisión'
            )
        }
        v.description
            .minLength(2, 'El número de caracteres válidos es 2 - 3000')
            .isEmpty('Se requiere ingresar la descripción')

        if (productsControl.list.length === 0) {
            formConsumptionVoucher.store.helper(
                'products',
                'Se requiere como mínimo un producto'
            )
            render(formConsumptionVoucher.keyRender, 'products')
            return false
        }

        return productsControl.test(_v => {
            _v.quantity
                .isMinor(1, 'El valor mínimo es 1')
                .isEmpty('Se requiere ingresar la cantidad a solicitar')
        })
    })

    observer<BodyPost | BodyPut>(f => getBody(f, consumer_voucher_id))

    return submit
}

function getBody(f: Form, consumer_voucher_id?: number): BodyPost | BodyPut {
    const isDelete = (logicalProductId: number, isRegistered: boolean) => {
        if (!isRegistered) {
            return {}
        }

        return {
            deleted:
                !storagePage.consumptionVoucher.consumer_voucher_products.find(
                    cvp => cvp.operation.logical_product_id === logicalProductId
                )
        }
    }

    if (consumer_voucher_id) {
        return {
            consumer_voucher_label_id: parseInt(f.tag.value),
            description: f.description,
            products: [
                ...productsControl.getDataList().map(lp => ({
                    id: lp.id,
                    logical_product_id: lp.logical_product_id,
                    quantity: parseInt(lp.quantity),
                    ...isDelete(lp.logical_product_id, !!lp.id)
                })),
                ...storagePage.current_consumer_voucher_products
                    .filter(
                        cvp =>
                            !productsControl
                                .getDataList()
                                .map(lp => lp.logical_product_id)
                                .includes(cvp.operation.logical_product_id)
                    )
                    .map(cvp => ({
                        id: cvp.id,
                        quantity: 0,
                        logical_product_id: cvp.operation.logical_product_id,
                        deleted: true
                    }))
            ]
        }
    }

    const date = moment(f.request_date).tz(storagePage.timezone).parseZone()
    const newDate = moment
        .tz(date.format('YYYY-MM-DD'), storagePage.timezone)
        .add(1, 'minute')

    return {
        company_id: parseInt(f.company.value),
        warehouse_id: parseInt(f.warehouse.value),
        stowage_id: parseInt(f.stowage.value),
        minimal_stowage_id: f.sub_stowage
            ? parseInt(f.sub_stowage.value)
            : storagePage.current_stowage_origin_id,
        consumer_voucher_label_id: parseInt(f.tag.value),
        request_date: newDate.toISOString(true),
        description: f.description,
        products: productsControl.getDataList().map(lp => ({
            logical_product_id: lp.logical_product_id,
            quantity: parseInt(lp.quantity)
        }))
    }
}

function handleError(error: Exception) {
    if (error.errors?.logical_product_id) {
        Popup.error(
            'Inventario negativo',
            error.errors.logical_product_id.description
        )
    }
}
