import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import Header from './Header'
import Filters from './Filters'
import TableKardex from './TableKardex'

export default function Kardex() {
    const { state } = useInitial()
    return (
        <Initial pageState={state}>
            <Header />
            <Panel>
                <PanelBody>
                    <Filters />
                    <TableKardex />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
