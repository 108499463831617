import storage, { formPresentation, tablePresentations } from '../storage'

export default function setParamsPresentations(execute = true) {
    const product_ids = tablePresentations.getDataList().map(pp => pp.id)

    // let not_product_ids = product_ids
    const add_product_ids = []

    if (storage.currentGroupPresentation) {
        for (const presentation_product of storage.currentGroupPresentation
            .presentation_products) {
            if (
                product_ids.find(
                    product_id => product_id === presentation_product.product_id
                )
            ) {
                // not_product_ids.push(presentation_product.product_id)
            } else {
                add_product_ids.push(presentation_product.product_id)
            }
        }
    }

    // not_product_ids = [...Array.from(new Set<number>(not_product_ids))]

    formPresentation.setParams(
        'presentationProducts',
        {
            sort: 'name',
            // ...(not_product_ids.length !== 0 && { not_product_ids }),
            ...(add_product_ids.length !== 0 && { add_product_ids }),
            limit: 10
        },
        execute
    )
}
