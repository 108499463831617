import DataTable, { TBody, THead } from '../../../../../../components/DataTable'
import { LOGISTICAL_CONCEPT__PENALIZATION } from '../../../../../../config/constants'
import buildNumberFormat from '../../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../../storage'
import { ResponseLogisticalCost } from '../../../../types'
import Actions from './TableActions'
import { TableSettingsProps } from './types'

export default function TableSettings({
    logisticalCost,
    onReload,
    rcs
}: TableSettingsProps) {
    const { symbol } = storagePage.currentCurrency

    function getReception(
        lcrs: ResponseLogisticalCost['logistical_cost_receptions']
    ) {
        return lcrs.map(lcr => lcr.reception.code).join(', ')
    }

    return (
        <DataTable scrollHeight={350}>
            <THead>
                <tr>
                    <th>#</th>
                    <th>Concepto de costo</th>
                    <th>Tipo</th>
                    <th>Costea a RCs</th>
                    <th>Monto (?)</th>
                    <th>TC</th>
                    <th>Monto ({symbol})</th>
                    <th className="cell-actions">Acciones</th>
                </tr>
            </THead>
            <TBody>
                {logisticalCost
                    .filter(
                        lc =>
                            lc.logistical_concept_id !==
                            LOGISTICAL_CONCEPT__PENALIZATION
                    )
                    .map((rlc, i) => (
                        <tr key={rlc.id}>
                            <td>{i + 1}</td>
                            <td>{rlc.logistical_concept.name}</td>
                            <td>{rlc.type_logistical_cost.name}</td>
                            <td>
                                {rlc.in_all_receptions
                                    ? 'Todos'
                                    : getReception(
                                          rlc.logistical_cost_receptions
                                      ) || '-'}
                            </td>
                            <td>
                                {rlc.currency.symbol}{' '}
                                {buildNumberFormat(rlc.original_cost)}
                            </td>
                            <td>{buildNumberFormat(rlc.exchange_to_local)}</td>
                            <td>
                                {symbol} {buildNumberFormat(rlc.local_cost)}
                            </td>
                            <Actions
                                logisticalCost={rlc}
                                onReload={onReload}
                                rcs={rcs}
                            />
                        </tr>
                    ))}
            </TBody>
        </DataTable>
    )
}
