import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { GetConsumerVoucher } from '../../types'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC',
        borderBottom: '1px solid #CCCCCC'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    table__td: {
        width: '13%',
        padding: '6px',
        fontSize: fontSize,
        borderLeft: '1px solid #CCCCCC'
    },
    table__th: {
        width: '13%',
        backgroundColor: '#CCCCCC',
        padding: '4px',
        fontSize: fontSize,
        fontFamily: 'helvetica-bold'
    },
    textRight: {
        textAlign: 'right'
    },
    cellCount: {
        width: '6%'
    },
    cellProductCode: {
        width: '23%'
    },
    cellProductDescription: {
        width: '36%'
    },
    cellQuantity: {
        width: '6%'
    },
    cellUnitValue: {
        width: '14%'
    },
    cellTotal: {
        width: '15%'
    },
    table__footer__td: {
        padding: '2px',
        fontSize: fontSize,
        backgroundColor: 'rgb(229,231,235)'
    },
    cellFooterName: {
        width: '85%',
        fontFamily: 'helvetica-bold'
    },
    cellTotalRC: {
        width: '15%'
    }
})

export default function Body({ data }: { data: GetConsumerVoucher }) {
    const { consumer_voucher_products } = data
    const currencySymbol = data.company_warehouse.company.currency.symbol
    const totalValue = consumer_voucher_products.reduce(function (a, b) {
        return a + b.quantity * b.operation.average_unit_cost
    }, 0)

    return (
        <View style={styles.table}>
            <View style={styles.row}>
                <Text style={[styles.table__th, styles.cellCount]}>#</Text>
                <Text style={[styles.table__th, styles.cellProductCode]}>
                    Código de producto
                </Text>
                <Text style={[styles.table__th, styles.cellProductDescription]}>
                    Descripción de producto
                </Text>
                <Text style={[styles.table__th, styles.cellQuantity]}>
                    Cant.
                </Text>
                <Text style={[styles.table__th, styles.cellUnitValue]}>
                    Costo Prom. Unit.
                </Text>
                <Text style={[styles.table__th, styles.cellTotal]}>Total</Text>
            </View>
            {consumer_voucher_products
                .filter(item => item.is_enabled)
                .filter(item => !item.deleted)
                .map((item, i) => (
                    <View key={i} style={styles.row}>
                        <Text style={[styles.table__td, styles.cellCount]}>
                            {i + 1}
                        </Text>
                        <Text
                            style={[styles.table__td, styles.cellProductCode]}
                        >
                            {item.product.code}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellProductDescription
                            ]}
                        >
                            {item.product.description}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellQuantity,
                                styles.textRight
                            ]}
                        >
                            {item.quantity}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellUnitValue,
                                styles.textRight
                            ]}
                        >
                            {currencySymbol}{' '}
                            {buildNumberFormat(
                                item.operation.average_unit_cost
                            )}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellTotal,
                                styles.textRight
                            ]}
                        >
                            {currencySymbol}{' '}
                            {buildNumberFormat(
                                item.quantity * item.operation.average_unit_cost
                            )}
                        </Text>
                    </View>
                ))}
            <View style={styles.row}>
                <Text
                    style={[
                        styles.table__footer__td,
                        styles.cellFooterName,
                        styles.textRight
                    ]}
                >
                    TOTAL
                </Text>
                <Text
                    style={[
                        styles.table__footer__td,
                        styles.cellTotalRC,
                        styles.textRight
                    ]}
                >
                    {currencySymbol} {buildNumberFormat(totalValue)}
                </Text>
            </View>
        </View>
    )
}
