import BtnActionTable from '../../../components/BtnActionTable'
import Enable from '../../../components/Enable'
import Icon from '../../../components/Icon'
import ReactPopover from '../../../components/ReactPopover'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import buildCompanyName from '../../../utilities/buildCompanyName'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { controlList } from '../storage'
import { GetProductCompany, ProductCompanyData } from '../types'
import useSubmit from '../useSubmit'
import Info from './Info'

function Code({
    code,
    productCompanyId
}: {
    code: string
    productCompanyId: number
}) {
    const math = useRule('CU120')

    return (
        <div
            {...(math && {
                className: 'underline cursor-pointer',
                onClick: () =>
                    view<GetProductCompany>(
                        `/v5.6/apis/product_companies/${productCompanyId}/principal_provider_information`,
                        product_company => ({
                            title: product_company.product_country?.product
                                ?.name,
                            subtitle: `(${product_company.product_country?.product.code})`,
                            Component: Info
                        })
                    )
            })}
        >
            <b>{code}</b>
        </div>
    )
}

function Assign({ product_company }: { product_company: ProductCompanyData }) {
    const openModal = useSubmit(product_company.id)

    return (
        <BtnActionTable
            title="Cambiar proveedor principal"
            iconName="bs-arrow-repeat"
            onClick={openModal}
        />
    )
}

export default function TableProviders() {
    return (
        <List control={controlList}>
            {(product_company, i) => (
                <tr key={product_company.id}>
                    <td>{i}</td>
                    <td className="max-w-md">
                        <Code
                            code={product_company.product_country.product.code}
                            productCompanyId={product_company.id}
                        />
                    </td>
                    <td className="max-w-md" style={{ whiteSpace: 'normal' }}>
                        {product_company.product_country.product.description}
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {product_company?.product_provider_company
                            ?.provider_company?.provider?.country
                            ?.image_url && (
                            <img
                                className="w-8 inline mr-2"
                                src={
                                    product_company?.product_provider_company
                                        ?.provider_company?.provider?.country
                                        ?.image_url
                                }
                            />
                        )}
                        <p
                            className={`inline ${
                                !product_company?.product_provider_company
                                    ?.memory && 'line-through'
                            }`}
                        >
                            {product_company?.product_provider_company
                                ?.provider_company.provider.commercial_name ||
                                '-'}
                        </p>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <div className="flex">
                            <img
                                src={product_company.company.country.image_url}
                                className="w-8 mr-2"
                            />
                            <p
                                className={`${
                                    !product_company.company.memory &&
                                    'line-through'
                                }`}
                            >
                                {product_company.company
                                    ? buildCompanyName(
                                          product_company.company.name
                                      )
                                    : ''}
                            </p>
                        </div>
                    </td>
                    <td>
                        {
                            product_company?.product_provider_company
                                ?.company_currency.currency.symbol
                        }{' '}
                        {product_company?.product_provider_company
                            ? buildNumberFormat(
                                  product_company?.product_provider_company
                                      ?.purchase_price
                              )
                            : '-'}
                    </td>
                    <td>
                        <Enable
                            enabled={
                                product_company.product_provider_company
                                    ?.is_enabled
                            }
                        />
                        {!product_company.product_provider_company
                            ?.is_enabled && (
                            <ReactPopover
                                component={
                                    <Icon
                                        className="text-red-600"
                                        name="warning"
                                    />
                                }
                                className="ml-3"
                            >
                                <div className="text-center max-w-sm text-gray-400">
                                    <p>
                                        El registro no está activo para compras.
                                    </p>
                                    <p>Cambie de proveedor principal o</p>
                                    <p>habilite la configuración de compras.</p>
                                </div>
                            </ReactPopover>
                        )}
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU119">
                                <Assign product_company={product_company} />
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
