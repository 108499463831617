import Checkbox from '../../../../../components/Checkbox'
import { controlList } from '../../../storage'

export default function CheckboxStock() {
    const { value, setParam } = controlList.useParam('with_stock')
    function onChange() {
        const temp = value === 'true'
        setParam((!temp).toString())
    }

    return (
        <div className="mr-2">
            <label className="flex items-center text-neutral text-xs underline">
                <Checkbox checked={value === 'true'} onChange={onChange} />{' '}
                <div className="ml-1">Con Stock</div>
            </label>
        </div>
    )
}
