import { render } from 'redity'
import { Option } from '../../../config/types/component'
import useGet from '../../../hooks/useGet'
import Stowage from '../../../models/Stowage'
import { filterForm, reqSubStowageDestination } from '../storage'

export default function useSubStowageDestination() {
    const { props } = filterForm.useSelect<Stowage>(
        'minimal_stowage_destination_id'
    )
    const { data, props: propsSearch } = useGet<any>(
        reqSubStowageDestination.observer(
            `/v5.6/apis/stowages/:destination_stowage_id/substowages`,
            reqSubStowageDestination.hasQuery('destination_stowage_id') &&
                reqSubStowageDestination.hasQuery('origin_minimal_stowage_id')
        ),
        []
    )
    function disable(value: string) {
        const { minimal_stowage_id } = filterForm.store()

        if (minimal_stowage_id?.value === value) return true
        return false
    }

    function handleChange(option: Option) {
        props.onChange(option)
        render(filterForm.keyRender, 'minimal_stowage_id')
    }

    return { data, props, propsSearch, disable, handleChange }
}
