import Rules from '../../../components/Rules'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { GetAgents } from '../../../types/agent'
import { controlList } from '../storage'

interface PropsActiveDeactive {
    agent: GetAgents[number]
}

export default function ActiveDeactivate({ agent }: PropsActiveDeactive) {
    return (
        <Rules cu={agent.is_enabled ? 'CU077' : 'CU078'}>
            <ActivateDeactivate
                isEnabled={agent.is_enabled}
                control={controlList}
                body={{
                    agent_id: agent.id
                }}
                className="ml-2"
            >
                <Active path="/v5.6/apis/agents/activate">
                    Al reactivar el agente, volverá a estar disponible para
                    operaciones de compra ¿Estás seguro de activar el agente{' '}
                    <b className="text-primary"> {agent.name}</b>?
                </Active>
                <Deactive path="/v5.6/apis/agents/deactivate">
                    Al desactivar el agente dejará de estar disponible para
                    operaciones de compra ¿Estás seguro de desactivar el agente
                    <b className="text-primary"> {agent.name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
