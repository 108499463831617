import Icon from '../../../../components/Icon'
import useRemove from '../../../../hooks/useRemove'
import { TableList } from '../../../../sections/ListForm'
import { tablePaymentMethods } from '../../storage'
import { TablePaymentMethodData } from '../../types'
import usePaymentMethod from './usePaymentMethod'

function Edit({
    index,
    data
}: {
    index: number
    data: TablePaymentMethodData
}) {
    const open = usePaymentMethod(index)
    return (
        <Icon
            name="bs-pencil"
            className="mr-2"
            pointer
            onClick={() =>
                open({
                    payment_method: {
                        value: data.payment_method.id.toString(),
                        label: data.payment_method.name
                    },
                    company: {
                        value: data.company.id.toString(),
                        label: data.company.name
                    },
                    description: data.description
                })
            }
        />
    )
}

export default function TablePaymentMethods() {
    const del = useRemove(tablePaymentMethods)

    return (
        <TableList control={tablePaymentMethods} scrollHeight={250}>
            {(data, _, e) => (
                <tr key={e}>
                    <td>{data.payment_method.id}</td>
                    <td>
                        <div className="flex">
                            <img
                                src={data.company.image}
                                className="w-8 mr-2"
                            />
                            <p>{data.company.name}</p>
                        </div>
                    </td>
                    <td>{data.description}</td>
                    <td>
                        <Edit index={e - 1} data={data} />
                        <Icon
                            name="bs-trash"
                            pointer
                            onClick={() => del(e - 1)}
                        />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
