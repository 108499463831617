import Initial from '../../../components/Initial'
import Header from './Header'
import Step1 from './Step1'
import Step2 from './Step2'
import useInitial from '../useInitial'
import { controlTabs } from './../storage'

controlTabs.setSection('Precios', Step1)
controlTabs.setSection('Descuentos por volumen', Step2)

export default function ProductPrice() {
    const { pageState, cancel } = useInitial()
    const value = controlTabs.useStep(() => {
        return true
    })
    return (
        <Initial pageState={pageState}>
            <Header onCancel={cancel} />
            <controlTabs.SectionTabs
                value={value}
                mode="card"
                type="tab"
                className="max-w-screen-2xl"
            />
        </Initial>
    )
}
