import 'moment/locale/es'
import Logo from '../../../../assets/img/logo.png'
import { Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import { storagePage, filterForm } from '../../storage'
import moment from 'moment'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import Company from '../../../../models/Company'
import { BalanceHistory } from '../../types'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '14px'
    },
    companyWatemark: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '7px'
    },
    title: {
        fontSize: '16px',
        fontFamily: 'helvetica-bold',
        marginTop: '25px',
        marginBottom: '20px'
    },
    companyWatemark__logo: {
        height: '38px',
        width: '70px'
    },
    companyWatemark__info: {
        maxWidth: '30%'
    },
    fontBold: {
        fontFamily: 'helvetica-bold'
    },
    list: {
        display: 'flex',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingBottom: '10px',
        justifyContent: 'space-between',
        borderBottom: '2px dotted rgb(128, 126, 126)',
        flexDirection: 'row',
        fontSize: fontSize
    },
    list__element__name: {
        fontFamily: 'helvetica-bold',
        fontSize: fontSize
    },
    list__element__value: {
        fontSize: fontSize
    }
})

export default function Header({
    company,
    data
}: {
    company: Company
    data: BalanceHistory[]
}) {
    const total_balance = data.reduce(
        (a, b) => a + (b.average_unit_cost * b.quantity || 0),
        0
    )
    moment.locale('es')
    const date = moment().format('LL')
    const { currency } = storagePage
    const stowageSelected = filterForm.store().stowage_id
    const subStowageSelected = filterForm.store().minimal_stowage_id

    return (
        <>
            <View style={styles.header} fixed>
                <View style={styles.companyWatemark}>
                    <Image style={styles.companyWatemark__logo} src={Logo} />
                    <View style={styles.companyWatemark__info}>
                        <Text style={styles.fontBold}>{company.name}</Text>
                        {company.fiscal_document === '' && (
                            <Text>
                                {company.type_fiscal_document}:{' '}
                                {company.fiscal_document}
                            </Text>
                        )}
                        <Text>{company.address}</Text>
                    </View>
                </View>
                <View style={styles.title}>
                    <Text>REPORTE DE INVENTARIO EN TRÁNSITO</Text>
                </View>
            </View>
        </>
    )
}
