import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import {
    storagePage,
    formInformation,
    reqReception,
    productsControl
} from './storage'
import { useParams } from 'react-router'
import { GetCostingEstimate } from './types'
import useStorage from '../../hooks/useStorage'
import useInitialEdition from './useInitialEdition'

export default function useInitial() {
    const { cost_estimate_id } = useParams<{ cost_estimate_id?: string }>()
    const { companiesFromSession } = useStorage()
    const [pageState, setState] = useState<StateCase>(StateCase.LOADING)
    const loadEdition = useInitialEdition()

    let title = 'Nueva estimación de costeo'
    if (cost_estimate_id) {
        storagePage.mode = 'edition'
        storagePage.cost_estimate_id = cost_estimate_id
        title = 'Editar estimación de costeo'
    }

    async function load() {
        if (storagePage.mode !== 'creation') {
            const response = await get<GetCostingEstimate>(
                `/v5.6/apis/costing_estimates/${cost_estimate_id}`
            )
            if (response.error) {
                setState(StateCase.ERROR)
                return
            } else {
                storagePage.costing_estimate = response.data.result
                loadEdition(storagePage.costing_estimate)
            }
        } else {
            if (formInformation.store().company) {
                reqReception.setQuery(
                    'company_id',
                    formInformation.store().company.value
                )
            } else {
                const company = companiesFromSession[0]
                formInformation.setValue('company', {
                    label: company.name,
                    value: company.id.toString(),
                    image: company.country.image_url
                })
                reqReception.setQuery('company_id', company.id)
            }

            reqReception.setQuery('type_reception_id', 2)
            reqReception.setQuery('includes_logistical_costs', false)
        }
        setState(StateCase.SUCCESS)
    }

    useEffect(() => {
        load()
        return () => {
            storagePage.mode = 'creation'
            storagePage.cost_estimate_id = null
            formInformation.init()
            formInformation.clearProps()
            productsControl.setList([])
        }
    }, [])

    return {
        pageState,
        title
    }
}
