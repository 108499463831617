import Panel, { PanelBody } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import FiltersHeaders from './FiltersHeaders'
import Periods from './Periods'

export default function PageTest() {
    const pageState = useInitial()

    return (
        <Initial pageState={pageState}>
            <Panel>
                <PanelBody>
                    <FiltersHeaders />
                    <Periods />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
