import { render } from 'redity'
import Dropdown from '../../../../../components/Dropdown'
import { IterateOptions } from '../../../../../components/FormUtilities'
import useStorage from '../../../../../hooks/useStorage'
import {
    controlList,
    filterForm,
    reqSummaryData,
    storagePage
} from '../../../storage'
import { Keys } from '../../../constants'
import { Option as OptionType } from '../../../../../config/types/component'
import { setCompanySelected } from '../../../../../utilities/controlStorage'

export default function CompanyFilter() {
    const { props } = controlList.useFilter('company_id')
    const { companiesFromSession } = useStorage()
    async function handleChange(option: OptionType) {
        filterForm.runSearch('stowage_id')
        controlList.filter.deleteFilter('stowage_id')
        controlList.filter.deleteFilter('minimal_stowage_id')
        filterForm.setValue('stowage_id', null)
        filterForm.setValue('minimal_stowage_id', null)
        controlList.renderForm()

        const company = companiesFromSession.find(
            item => item.id === parseInt(option.value)
        )
        setCompanySelected(company)
        storagePage.currency = company.local_currency

        reqSummaryData.setQuery('company_id', company.id)
        await reqSummaryData.load()
        render(Keys.EXPORT)
        render(Keys.HEADER)

        await props.onChange(option)
    }

    return (
        <Dropdown
            {...props}
            onChange={handleChange}
            label="Compañía"
            clear={false}
            showLabel={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    ...company,
                    image_url: company.country.image_url,
                    strikeOut: !company.is_enabled
                }))}
                label="name"
                image="image_url"
                strikeOut="strikeOut"
            />
        </Dropdown>
    )
}
