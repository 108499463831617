import Button from '../../../components/Button'
import Rules from '../../../components/Rules'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { controlList } from '../storage'
import useSubmit from '../useSubmit'

export default function FilterPresentations() {
    const { props } = controlList.useSearch()
    const openModal = useSubmit()

    return (
        <SectionFiltersGrid>
            <SearchField
                {...props}
                placeholder="Buscar por grupo de presentaciones"
                size="auto"
                fullWidth={true}
            />
            <FlexBox>
                <FlexLeft></FlexLeft>
                <FlexRight>
                    <Rules cu="CU068">
                        <Button size="sm" onClick={openModal}>
                            Nuevo
                        </Button>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
