import { useState } from 'react'
import { ExcelRendered } from '../../../../../components/ExcelField/types'
import { Option } from '../../../../../config/types/component'

export default function useValues() {
    const [optionCompany, setOptionCompany] = useState<Option>(null)
    const [optionMoney, setOptionMoney] = useState<Option>(null)
    const [file, setFile] = useState<File>(null)
    const [excelRendered, setExcelRendered] = useState<ExcelRendered>(null)

    return {
        optionCompany,
        setOptionCompany,
        optionMoney,
        setOptionMoney,
        file,
        setFile,
        excelRendered,
        setExcelRendered
    }
}
