export default function Limit({ handleLimit, limit }) {
    return (
        <div className="pagination-info">
            <span className="mr-2">Mostrando</span>
            <select
                onChange={ev => handleLimit(parseInt(ev.target.value))}
                value={limit.toString()}
                className="select select-bordered select-xs w-14"
            >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>
        </div>
    )
}
