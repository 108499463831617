import { parseDriveFile } from '../../../../../utilities/fileTools'
import { formDutyISCIVA } from '../storageStep2'
import Cost from '../cost'

export function loadForm(
    result: Cost.ResponseLCDuty | Cost.ResponseLCSpecific | Cost.ResponseLCIva
) {
    const {
        logistical_cost_receptions,
        files,
        original_cost,
        setting_local_cost,
        exchange_to_local,
        logistical_concept,
        currency,
        local_cost,
        document_number,
        agent
    } = result

    formDutyISCIVA.setValue(
        'document_number',
        document_number && document_number.toString()
    )
    formDutyISCIVA.setValue('logistical_concept', null)
    formDutyISCIVA.setValue('currency', {
        label: `${currency.name} (${currency.symbol})`,
        value: currency.id.toString()
    })
    formDutyISCIVA.setValue('original_cost', original_cost.toFixed(2))
    formDutyISCIVA.setValue('setting_local_cost', setting_local_cost.toFixed(2))
    formDutyISCIVA.setValue(
        'exchange_to_local',
        (exchange_to_local || 0).toFixed(2)
    )
    formDutyISCIVA.setValue(
        'total_cost',
        exchange_to_local !== null
            ? (original_cost * exchange_to_local).toFixed(2)
            : local_cost.toFixed(2)
    )
    formDutyISCIVA.setValue(
        'final_local_cost',
        exchange_to_local !== null
            ? (original_cost * exchange_to_local).toFixed(2) // ISC
            : (local_cost + setting_local_cost).toFixed(2) // Arancel - IVA
    )
    formDutyISCIVA.setValue(
        'rcs',
        logistical_cost_receptions.map(lcr => ({
            value: lcr.reception_id.toString(),
            label: lcr.version.toString()
        }))
    )
    formDutyISCIVA.setValue(
        'files',
        files.map(file => parseDriveFile(file))
    )
    formDutyISCIVA.setValue('logistical_concept', {
        value: logistical_concept.id.toString(),
        label: logistical_concept.name
    })
    formDutyISCIVA.setValue('agent', null)
    if (agent) {
        formDutyISCIVA.setValue('agent', {
            value: agent.id.toString(),
            label: agent.name
        })
    }
}
