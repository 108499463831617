import { useContext } from 'react'
import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import SelectMultiple from '../../../../components/SelectMultiple'
import SelectTouch from '../../../../components/SelectTouch'
import StorageContext from '../../../../contexts/storage-context'
import { formInformation } from '../../storage'

function TypeProduct() {
    const { props } = formInformation.useSelect('type_product')
    const { typeProducts } = useContext(StorageContext)
    return (
        <FormControl label="Tipo de Producto" required>
            <SelectTouch {...props} placeholder="Seleccionar tipo de producto">
                <IterateOptions data={typeProducts} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function TypesComunication() {
    const { props } = formInformation.useSelects('type_communications')
    const { typeComunications } = useContext(StorageContext)
    return (
        <FormControl
            label="Tipo de Comunicación"
            col="span-6"
            required={!props.disabled}
        >
            <SelectMultiple
                {...props}
                placeholder="Seleccionar tipo(s) de comunicación"
                selectTouchInherit={true}
            >
                <IterateOptions data={typeComunications} label="name" />
            </SelectMultiple>
        </FormControl>
    )
}

function DescriptionGenerate() {
    const { props } = formInformation.useInput('description_generate')
    return (
        <FormControl label="Descripción Generada" col="span-6">
            <InputField
                {...props}
                readOnly
                placeholder="Marca + descripción corta + modelo + rango + presentación + cantidad"
            />
        </FormControl>
    )
}

export default function Fields3() {
    return (
        <>
            <TypeProduct />
            <TypesComunication />
            <DescriptionGenerate />
        </>
    )
}
