import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import MapSearch from './MapSearch'
import MarkerPoint from './MarkerPoint'
import { geocode, getGeocodeByLatLng } from './utils'
import { GOOGLE_API_KEY } from '../../config/credentials'
import { Libraries, PropsMapView } from './types'
import Loading from '../Loading'
import classNames from 'classnames'
import './mapView.css'

export { MapSearch, geocode, getGeocodeByLatLng, MarkerPoint }
const libraries: Libraries[] = ['places']

export default function MapView({
    className,
    center = {
        lat: 38.2850563,
        lng: -100.3448311
    },
    zoom = 5,
    onLoad = () => null,
    search = false,
    onSelect = () => null,
    children = null,
    ...props
}: PropsMapView) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_API_KEY,
        id: 'loader-map',
        version: '3',
        language: 'es',
        region: 'US',
        libraries
    })

    if (loadError) {
        return <div>Lo sentimos, el mapa no ha podido ser cargado :{'('}</div>
    }

    return (
        <div className={classNames('mapview', className)}>
            {isLoaded && (
                <GoogleMap
                    id="google-map"
                    center={center}
                    options={{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        clickableIcons: false
                    }}
                    zoom={zoom}
                    {...props}
                    onLoad={onLoad}
                >
                    {children}
                    {search && <MapSearch onPlaceChanged={onSelect} />}
                </GoogleMap>
            )}
            {!isLoaded && (
                <div className="flex flex-col justify-center items-center h-full">
                    <div>
                        <Loading />
                        <p className="text-gray-400 text-xs text-center">
                            Cargando mapa
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}
