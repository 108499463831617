import { useState } from 'react'
import { API_BASE_URL, PUBLIC_URL } from '../../../config/credentials'
import './loginStyles.css'

export default function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    async function onClickLogin() {
        try {
            const res = await fetch(`${API_BASE_URL}/login/`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            })
            if (res.ok) {
                const json_res = await res.json()
                window.location.replace(
                    `${PUBLIC_URL}#/prices?token=${json_res.result.access_token}`
                )
            } else {
                alert('Nombre o contraseña de usuario incorrecto')
            }
        } catch (error) {
            alert('Servicio ERP no disponible')
        }
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onClickLogin()
        }
    }

    return (
        <div className="login-page">
            <div className="container">
                <div className="container__body">
                    <div className="form">
                        <div className="form_header">
                            <img
                                className="logo"
                                width="120px"
                                src="https://cutt.ly/PTuebwH"
                            />
                            <h1 className="title">INICIAR SESIÓN</h1>
                        </div>
                        <div className="inputs">
                            <label>Nombre de usuario</label>
                            <input
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                onKeyDown={handleKeyDown}
                                type="text"
                                name="username"
                                className="username"
                                placeholder="Ingresar nombre de usuario"
                            />
                        </div>
                        <div className="inputs">
                            <label>Contraseña</label>
                            <input
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                onKeyDown={handleKeyDown}
                                type="password"
                                name="password"
                                className="password"
                                placeholder="Ingresar contraseña"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={onClickLogin}
                            className="btn"
                        >
                            Iniciar sesión
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
