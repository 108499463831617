import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import useStorage from '../../hooks/useStorage'
import WorkingPeriod from '../../models/WorkingPeriod'
import { getCompanySelected } from '../../utilities/controlStorage'
import { get } from '../../utilities/http'
import { controlList, controlTabs, storagePage } from './storage'

export async function loadWorkingPeriods(company_id: number) {
    const response = await get<WorkingPeriod[]>(
        `/v5.6/apis/working_periods?company_id=${company_id}`
    )
    storagePage.workingPeriods = response.data.result
}

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)
    const { originalCompanies } = useStorage()

    useEffect(() => {
        const company = getCompanySelected()

        const option = {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url,
            span: company.local_currency.symbol
        }

        storagePage.originalCompanies = originalCompanies
        storagePage.company_enabled = company.is_enabled
        storagePage.currency = company.local_currency.symbol
        controlList.filter.setOption('company_id', option)
        controlList.filter.setParams('has_document', false)
        controlList.filter.setParams('sort', 'working_period')

        controlList.done().then(result => {
            if (result) {
                loadWorkingPeriods(company.id).then(() => {
                    setState(StateCase.SUCCESS)
                })
            } else {
                setState(StateCase.ERROR)
            }
        })

        return () => {
            controlTabs.step('step1')
        }
    }, [])

    return state
}
