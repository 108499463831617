import useStorage from '../../hooks/useStorage'
import { ocList } from './storage'

export default function useIsCompanyDisabled() {
    const { companiesFromSession } = useStorage()
    const option = ocList.filter.getOption('company_id')

    const currentCompany = companiesFromSession.find(
        company => company.id.toString() === option.value
    )

    return !currentCompany.is_enabled
}
