import moment from 'moment'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { render, useRender } from 'redity'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import Option from '../../../components/Option'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { Option as OptionType } from '../../../config/types/component'
import LayoutContext from '../../../contexts/layout-context'
import useRule from '../../../hooks/useRule'
import useStorage from '../../../hooks/useStorage'
import { setCompanySelected } from '../../../utilities/controlStorage'
import { formGeneral } from '../../Cost/View/SectionGeneralInformation/storageStep1'
import { controlList, storagePage } from '../storage'
import { loadWorkingPeriods } from '../useInitial'
import PopoverMore from './PopoverMore'

function CompanyField() {
    const { companiesFromSession } = useStorage()
    const { props } = controlList.useFilter('company_id')

    async function handleChange(option: OptionType) {
        const company = companiesFromSession.find(
            _company => _company.id === parseInt(option.value)
        )
        if (company.is_enabled) {
            formGeneral.setValue('company', option)
        } else {
            formGeneral.setValue('company', null)
        }
        setCompanySelected(company)
        storagePage.company_enabled = company.is_enabled
        storagePage.currency = company.local_currency.symbol
        controlList.filter.deleteFilter('working_period_id')
        await loadWorkingPeriods(company.id)
        render('button_new')
        await props.onChange(option)
    }

    return (
        <Dropdown
            {...props}
            clear={false}
            onChange={handleChange}
            label="Compañía"
            showLabel={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    ...company,
                    image_url: company.country.image_url,
                    disabled: !company.is_enabled
                }))}
                strikeOut="disabled"
                label="name"
                image="image_url"
            />
        </Dropdown>
    )
}

function WorkingPeriodField() {
    const { props } = controlList.useFilter('working_period_id')
    const { workingPeriods } = storagePage
    controlList.filter.getParam('company_id')

    async function handleChange(option: OptionType) {
        await props.onChange(option)
    }

    function capitalize(word: string) {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }

    return (
        <Dropdown
            {...props}
            clear={false}
            onChange={handleChange}
            label="Periodo de Trabajo"
            showLabel={true}
        >
            <IterateOptions
                data={workingPeriods.map(workingPeriod => ({
                    ...workingPeriod,
                    label: capitalize(
                        moment(
                            `01/${workingPeriod.month}/${workingPeriod.year}`,
                            'DD/MM/YYYY'
                        ).format('MMMM YYYY')
                    )
                }))}
                label="label"
            />
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()

    return (
        <SearchField
            {...props}
            placeholder="Buscar por código o N° de declaración"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

function NewButton() {
    const { push } = useHistory()
    useRender('button_new')
    const rule1 = useRule('CU263')
    const rule2 = useRule('CU262')
    const { close } = useContext(LayoutContext)

    function handleChange(option: OptionType) {
        close()
        if (option.value === '1') {
            push('/costs/costing/creation/international')
        } else {
            push('/costs/costing/creation/national')
        }
    }

    return (
        <Dropdown
            label="Nuevo"
            className=""
            clear={false}
            onChange={handleChange}
        >
            <Option
                disabled={!rule1 || !storagePage.company_enabled}
                label="Crear costeo de importación"
                value="1"
            ></Option>
            <Option
                disabled={!rule2 || !storagePage.company_enabled}
                label="Crear costeo nacional"
                value="2"
            ></Option>
        </Dropdown>
    )
}

export default function FilterCosts() {
    return (
        <SectionFiltersGrid className="control">
            <Search />
            <FlexBox>
                <FlexLeft>
                    <CompanyField />
                    <WorkingPeriodField />
                </FlexLeft>
                <FlexRight>
                    <PopoverMore />
                    <NewButton />
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
