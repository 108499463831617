import classNames from 'classnames'
import InputField from '../InputField'
import { PropsReadOnly } from './types'

export default function ReadOnly({ value, image, className }: PropsReadOnly) {
    return (
        <>
            <InputField
                value={value}
                readOnly
                className={classNames(className, {
                    'pl-12': !!image
                })}
                tabIndex="-1"
            />
            {image && (
                <img
                    src={image}
                    className="absolute top-12 left-4 pointer-events-none"
                    style={{
                        maxWidth: '24px'
                    }}
                />
            )}
        </>
    )
}
