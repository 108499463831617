import moment from 'moment'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import BtnActionTable from '../../../components/BtnActionTable'
import Rules from '../../../components/Rules'
import LayoutContext from '../../../contexts/layout-context'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { viewPrint } from '../../../sections/ModalPrint'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import RunCostingContainer, { RunCosting } from '../RunCosting'
import { controlList, controlTabs, storagePage } from '../storage'
import {
    LogisticalDeclaratioData,
    LogisticalDeclarationPDFData
} from '../types'
import Pdf from './Pdf'

export default function TableDrafts() {
    const tab = controlTabs.value
    const { push } = useHistory()
    const { close } = useContext(LayoutContext)

    function handleClick(
        logistical_declaration_id: number,
        type_logistical_declaration_id: number
    ) {
        close()
        if (type_logistical_declaration_id === 2) {
            push(`/costs/costing/edition/${logistical_declaration_id}/national`)
        } else {
            push(
                `/costs/costing/edition/${logistical_declaration_id}/international`
            )
        }
    }

    return (
        <List control={controlList}>
            {(logistical_declaration, e) => (
                <tr key={logistical_declaration.id}>
                    <td>{e}</td>
                    <td>
                        {tab === 'step1'
                            ? logistical_declaration.name
                            : logistical_declaration?.document?.code}
                    </td>
                    <td>
                        {tab === 'step1'
                            ? logistical_declaration.type_logistical_declaration
                                  .name
                            : logistical_declaration?.number_declaration}
                    </td>
                    <td>
                        {getMonth(logistical_declaration.working_period.month)}
                    </td>
                    <td>
                        {storagePage.currency}{' '}
                        {buildNumberFormat(
                            logistical_declaration.purchase_local_cost
                        )}
                    </td>
                    <td>
                        {storagePage.currency}{' '}
                        {buildNumberFormat(
                            logistical_declaration.logistical_cost
                        )}
                    </td>
                    <td>
                        {getPercent(
                            logistical_declaration.logistical_cost,
                            logistical_declaration.purchase_local_cost
                        )}
                    </td>
                    <td>
                        <div className="group-btn-options">
                            {tab === 'step1' && (
                                <NextActions
                                    logistical_declaration={
                                        logistical_declaration
                                    }
                                />
                            )}
                            {tab !== 'step1' && (
                                <Rules cu={'CU281'}>
                                    <BtnActionTable
                                        title="Editar"
                                        iconName="bs-pencil"
                                        disabled={!storagePage.company_enabled}
                                        onClick={() =>
                                            handleClick(
                                                logistical_declaration.id,
                                                logistical_declaration.type_logistical_declaration_id
                                            )
                                        }
                                    />
                                </Rules>
                            )}
                            <Rules cu="CU295">
                                <LoadPDF
                                    logistical_declaration={
                                        logistical_declaration
                                    }
                                />
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}

function ViewerPdf({ data }: { data: LogisticalDeclarationPDFData }) {
    return <Pdf data={data} />
}

function LoadPDF({
    logistical_declaration
}: {
    logistical_declaration: LogisticalDeclaratioData
}) {
    function handleClick() {
        viewPrint<LogisticalDeclarationPDFData>(
            `/v5.6/apis/logistical_declarations/${logistical_declaration.id}/pdf`,
            () => ({
                Component: ViewerPdf
            })
        )
    }

    return (
        <BtnActionTable
            title="Imprimir"
            iconName="bs-printer"
            onClick={handleClick}
        />
    )
}

function getPercent(a, b) {
    const result = a / b
    if (Number.isNaN(result)) return 0 + '%'
    const percent = result * 100
    return buildNumberFormat(percent) + '%'
}

function getMonth(index) {
    const month = moment.months(index - 1)
    return month.charAt(0).toUpperCase() + month.slice(1)
}

function NextActions({
    logistical_declaration
}: {
    logistical_declaration: LogisticalDeclaratioData
}) {
    const { push } = useHistory()
    const { close } = useContext(LayoutContext)

    function handleClick() {
        close()
        if (logistical_declaration.type_logistical_declaration_id === 2) {
            push(`/costs/costing/edition/${logistical_declaration.id}/national`)
        } else {
            push(
                `/costs/costing/edition/${logistical_declaration.id}/international`
            )
        }
    }

    return (
        <>
            <Rules cu="CU280">
                <RunCostingContainer
                    control={controlList}
                    body={{
                        logistical_declaration_id: logistical_declaration.id
                    }}
                    disabled={!storagePage.company_enabled}
                >
                    <RunCosting path="/v5.6/apis/logistical_declarations/run_costs">
                        Al correr el costeo, el sistema calculará el nuevo valor
                        de ingreso unitario de los productos y los actualizará
                        para las recepciones de compra. Será visible el cambio
                        en kardex y en los reportes de inventario. Además,
                        generarán asientos contables en todos los productos de
                        las recepciones que se costean.¿Está seguro de correr el
                        costeo para el borrador{' '}
                        <b>{logistical_declaration.name}</b>?
                    </RunCosting>
                </RunCostingContainer>
            </Rules>
            <Rules cu="CU281">
                <BtnActionTable
                    title="Editar"
                    iconName="bs-pencil"
                    disabled={!storagePage.company_enabled}
                    onClick={handleClick}
                />
            </Rules>
            <Rules cu="CU276">
                <Delete
                    path={`/v5.6/apis/logistical_declarations/${logistical_declaration.id}`}
                    control={controlList}
                    title="Remover costeo"
                    accept="Sí, remover costeo"
                    disabled={!storagePage.company_enabled}
                >
                    Al remover el costo, se recalculará el monto a costear.
                    ¿Está seguro de remover el costo{' '}
                    <b>{logistical_declaration.name}</b> del proceso de costeo?
                </Delete>
            </Rules>
        </>
    )
}
