import List from '../../../sections/List'
import { controlList } from '../storage'
import moment from 'moment'
import DueDate from './DueDate'
import Actions from './Actions'
import { view } from '../../../sections/View'
import InfoLicense from './InfoLicense'
import { LicenseData } from '../types'
import useRule from '../../../hooks/useRule'
import Enable from '../../../components/Enable'
import buildCompanyName from '../../../utilities/buildCompanyName'

function Code({ license }: { license: LicenseData }) {
    const math = useRule('CU060')
    function handleView() {
        view(`/v5.6/apis/licenses/${license.id}`, () => {
            return {
                Component: InfoLicense,
                title: license.name
            }
        })
    }

    if (!math)
        return (
            <p className={!license.is_enabled && 'line-through'}>
                {license.name}
            </p>
        )

    const mode = license.is_enabled ? 'underline' : 'line-through'

    return (
        <b className={`cursor-pointer ` + mode} onClick={handleView}>
            {license.name}
        </b>
    )
}

export default function Table() {
    return (
        <List control={controlList}>
            {(license, i) => (
                <tr key={license.id}>
                    <td>{i}</td>
                    <td>
                        <Code license={license} />
                    </td>
                    <td>
                        <div className="flex items-center">
                            <img
                                src={license.company.country.image_url}
                                className="mr-2 w-8"
                            />
                            <p
                                className={`${
                                    !license.company.memory && 'line-through'
                                }`}
                            >
                                {buildCompanyName(license.company.name)}
                            </p>
                        </div>
                    </td>
                    <td>{license.version}</td>
                    <td>
                        {license.license_type_licenses
                            .filter(ltl => ltl.memory)
                            .map(tl => tl.type_license.name)
                            .join(' - ')}
                    </td>
                    <td>
                        {license.due_date ? (
                            <>
                                <p className="mr-2 inline-block">
                                    {moment(license.due_date).format(
                                        'DD/MM/YYYY'
                                    )}
                                </p>
                                <DueDate due_date={license.due_date} />
                            </>
                        ) : (
                            '-'
                        )}
                    </td>
                    <td>
                        <Enable enabled={license.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Actions license={license} />
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
