import { put } from '../../../utilities/http'
import { Exception } from '../../../utilities/http/type'
import Notify from '../../../utilities/Notify'
import { Popup } from '../../../utilities/popup'
import { Progress } from '../../../utilities/progress'
import Context from './context'
import { ActionData, PropsRunCosting } from './types'
import RunCosting from './RunCosting'

function expectError(error: Exception) {
    if (error.type === 'dependency' || error.type === 'development') {
        const errors = Object.values(error.errors)
        const message =
            errors.length > 0
                ? errors[0].description
                : 'Ni idea del porque el error xD'
        Popup.error('Lo sentimos', message)
    } else {
        Popup.ohno(
            'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
        )
    }
}

export default function RunCostingContainer({
    control,
    className = '',
    children,
    body,
    type = 'option',
    disabled
}: PropsRunCosting) {
    async function onClick(data: ActionData) {
        const result = await Popup.open({
            title: data.title,
            children: data.message,
            accept: data.accept,
            icon: 'money',
            color: 'info'
        })
        if (result) {
            Progress.open('Procesando')
            const response = await put<any>(data.path, body)
            if (response.data) {
                await control.done()
                Notify.success(data.notify)
                Progress.close()
                control.renderMain()
            } else {
                Progress.close()
                expectError(response.error)
            }
        }
    }

    return (
        <Context.Provider value={{ className, onClick, type, disabled }}>
            {children}
        </Context.Provider>
    )
}

export { RunCosting }
