import { useRef } from 'react'
import { ExcelRenderer } from 'react-excel-renderer'
import { expectSize, parseFileSize, support } from '../../utilities/fileTools'
import { PropsExcelField } from './types'

export default function useExcelField(
    onChange: PropsExcelField['onChange'],
    maxSizeFile: PropsExcelField['maxSizeFile'],
    onLoad: (isLoading: boolean) => void
) {
    const ref = useRef<HTMLInputElement>()
    const extensions = ['XLSX', 'xlsx', 'xls', 'XLS']

    function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const currentFile: File = ev.target.files[0]

        if (!currentFile) return

        if (!support([currentFile], extensions)) {
            alert('archivo no soportado')
            return
        }

        if (!expectSize([currentFile], maxSizeFile)) {
            alert(`Soporte máximo de ${parseFileSize(maxSizeFile)}`)
            return
        }

        onLoad(true)
        ExcelRenderer(currentFile, (err, resp) => {
            onLoad(false)
            if (err) {
                alert('Archivo válido o corrupto')
                return
            }

            onChange(currentFile, resp)

            if (ref.current) {
                ref.current.value = ''
                ref.current.files = null
            }
        })
    }

    function handleDelete() {
        onChange(null, null)
    }

    return {
        ref,
        onChange: handleChange,
        onDelete: handleDelete
    }
}
