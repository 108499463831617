import { SimpleOption } from '../../../../components/Option'
import { controlList, storagePage, filterForm } from '../../storage'
import writeXlsxFile, { Schema } from 'write-excel-file'
import { get } from '../../../../utilities/http'
import { buildPath } from '../../../../sections/ModalPrint/utils'
import moment from 'moment'

export default function OptionExcel() {
    async function download() {
        const { data } = await get(
            buildPath('/v5.6/apis/input_parts', [
                ['company_id', controlList.filter.getParam('company_id')],
                ['start_date', controlList.filter.getParam('start_date')],
                ['end_date', controlList.filter.getParam('end_date')],
                ['warehouse_id', controlList.filter.getParam('warehouse_id')],
                ['stowage_id', controlList.filter.getParam('stowage_id')],
                [
                    'minimal_stowage_id',
                    controlList.filter.getParam('minimal_stowage_id')
                ]
            ])
        )
        const list_data: any = data.result
        const excelData = []
        const start_date_param = controlList.filter.getParam('start_date')
        const start_date = start_date_param
            ? moment(start_date_param).format('DD/MM/YYYY')
            : ''
        const end_date_param = controlList.filter.getParam('start_date')
        const end_date = end_date_param
            ? moment(end_date_param).format('DD/MM/YYYY')
            : ''
        excelData.push([
            {
                value: 'ADVANCE LABORATORIOS Y AGUAS, INC.',
                fontWeight: 'bold'
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: 'Tipo de Documento:',
                wrap: true,
                fontWeight: 'bold',
                align: 'right'
            },
            {
                value: 'Parte de Entrada'
            }
        ])
        excelData.push([
            {
                value: 'Fecha Inicial',
                wrap: true,
                align: 'right'
            },
            {
                value: start_date,
                wrap: true,
                align: 'right'
            }
        ])
        excelData.push([
            {
                value: 'Fecha Final',
                wrap: true
            },
            {
                value: end_date,
                wrap: true
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: 'Código',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Descripción',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Fecha',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Cantidad',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Costo',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Total',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Total Documento',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            }
        ])
        let totalSum = 0
        list_data.forEach(input_part => {
            // Agregar una fila en blanco si no es la primera recepción
            let totalCostDocument = 0
            let totalQuantity = 0
            const date = moment(input_part.document.decision_date)
                .tz(storagePage.timezone)
                .format('DD/MM/YYYY')
            excelData.push([
                {
                    value: 'NÚMERO:',
                    wrap: true,
                    align: 'right'
                },
                {
                    value: input_part.document.code,
                    wrap: true,
                    fontWeight: 'bold'
                },
                {
                    value: date,
                    wrap: true,
                    align: 'right',
                    fontWeight: 'bold'
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                }
            ])
            input_part.input_part_products.forEach(item => {
                // Construir un objeto con los datos de la recepción y el producto
                const rowData = {
                    number: input_part.document.code,
                    date: moment(input_part.document.decision_date)
                        .tz(storagePage.timezone)
                        .format('DD/MM/YYYY'),
                    product_code: item.product.code,
                    product_description: item.product.description,
                    quantity: item.quantity,
                    average_unit_cost: item.income_unit_cost,
                    total_cost: item.quantity * item.income_unit_cost,
                    full_cost: ''
                }
                totalQuantity += item.quantity
                totalCostDocument += item.quantity * item.income_unit_cost
                // Agregar la fila al arreglo de datos del Excel
                excelData.push([
                    {
                        value: rowData.product_code,
                        wrap: true
                    },
                    {
                        value: rowData.product_description,
                        wrap: true
                    },
                    {
                        value: '',
                        wrap: true
                    },
                    {
                        value: rowData.quantity,
                        wrap: true,
                        align: 'right'
                    },
                    {
                        value: rowData.average_unit_cost,
                        wrap: true,
                        align: 'right'
                    },
                    {
                        value: rowData.total_cost,
                        wrap: true,
                        align: 'right'
                    },
                    {
                        value: '',
                        wrap: true
                    }
                ])
            })
            totalSum += totalCostDocument
            excelData.push([
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: totalQuantity,
                    wrap: true,
                    align: 'right',
                    fontWeight: 'bold'
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: '',
                    wrap: true
                },
                {
                    value: totalCostDocument,
                    wrap: true,
                    align: 'right',
                    fontWeight: 'bold'
                }
            ])
        })
        excelData.push([
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: '',
                wrap: true
            },
            {
                value: 'TOTAL GENERAL',
                wrap: true,
                fontWeight: 'bold'
            },
            {
                value: totalSum,
                wrap: true,
                fontWeight: 'bold',
                align: 'right'
            }
        ])
        // const schema: Schema<any> = [
        //     {
        //         column: 'Recep N°',
        //         width: 20,
        //         value: d => {
        //             return d.reception_code
        //         }
        //     },
        //     {
        //         column: 'Fecha',
        //         width: 20,
        //         value: d => {
        //             return d.date
        //         }
        //     },
        //     {
        //         column: 'Proveedor',
        //         width: 30,
        //         value: d => {
        //             return d.provider
        //         }
        //     },
        //     {
        //         column: 'No.Factura',
        //         width: 20,
        //         value: d => {
        //             return d.invoice
        //         }
        //     },
        //     {
        //         column: 'Orden de C.',
        //         width: 20,
        //         value: d => {
        //             return d.purchase_code
        //         }
        //     },
        //     {
        //         column: 'Bodega',
        //         width: 20,
        //         value: d => {
        //             return d.stowage
        //         }
        //     },
        //     {
        //         column: 'Código',
        //         width: 30,
        //         value: d => {
        //             return d.product_code
        //         }
        //     },
        //     {
        //         column: 'Descripción',
        //         width: 60,
        //         value: d => {
        //             return d.product_description
        //         }
        //     },
        //     {
        //         column: 'Cant',
        //         width: 20,
        //         type: Number,
        //         format: '0',
        //         value: d => {
        //             return d.quantity
        //         }
        //     },
        //     {
        //         column: 'C/U',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.average_unit_cost
        //         }
        //     },
        //     {
        //         column: 'Total',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.total_cost
        //         }
        //     },
        //     {
        //         column: 'Total RC',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.full_cost
        //         }
        //     }
        // ]
        await writeXlsxFile(excelData, {
            fileName: `listado_partes_de_entrada.xlsx`,
            columns: [30, 60, 20, 10, 20, 25, 30].map(width => ({ width })),
            fontSize: 10
        })
    }

    return <SimpleOption onClick={download}>Exportar en excel</SimpleOption>
}
