import { SVGProps } from 'react'

const EventBusy = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.093 4.333h2.084A4.179 4.179 0 0 1 38.343 8.5v29.167a4.179 4.179 0 0 1-4.166 4.166H5.01a4.179 4.179 0 0 1-4.167-4.166V8.5A4.179 4.179 0 0 1 5.01 4.333h2.083V.167h4.167v4.166h16.667V.167h4.166v4.166ZM5.01 37.667h29.167V16.833H5.01v20.834Zm0-25V8.5h29.167v4.167H5.01Zm8.938 21.812-2.209-2.208 5.084-5.084-5.084-5.083 2.208-2.208 5.084 5.083 5.083-5.083 2.209 2.208-5.084 5.084 5.084 5.083-2.209 2.208-5.083-5.083-5.083 5.083Z"
        />
    </svg>
)

export default EventBusy
