import moment from 'moment'
import DatePicker from '../../../../../components/Datepicker'
import DropContent from '../../../../../components/DropContent'
import {
    FormControl,
    IterateOptions
} from '../../../../../components/FormUtilities'
import SelectTouch from '../../../../../components/SelectTouch'
import { Option } from '../../../../../config/types/component'
// import OptionComponent from '../../../../../components/Option'
import Category from '../../../../../models/Category'
import Stowage from '../../../../../models/Stowage'
import {
    controlList,
    filterForm,
    reqSummaryData,
    reqSubStowage
} from '../../../storage'
import { render } from 'redity'
import { Keys } from '../../../constants'
import MinimalStowage from '../../../../../models/MinimalStowage'
import useGet from '../../../../../hooks/useGet'

function CategoryField() {
    const { props, data } = filterForm.useSelect<Category>(
        'category_id',
        '/v5.6/apis/categories'
    )

    function handleChange(option: Option) {
        props.onChange(option)
        reqSummaryData.setQuery('category_id', option.value)
    }

    return (
        <FormControl label="Categoría">
            <SelectTouch
                {...props}
                placeholder="Seleccionar categoría"
                onChange={handleChange}
            >
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function DateField() {
    const { props } = filterForm.useDate('date')

    function handleChange(option: Option) {
        props.onChange(option)
        const date = moment(filterForm.store().date).format('YYYY-MM-DD')
        reqSummaryData.setQuery('date', date)
    }

    return (
        <FormControl label="Fecha" className="mt-1">
            <DatePicker
                {...props}
                placeholder="Fecha"
                onChange={handleChange}
            />
        </FormControl>
    )
}

function StowageSelect() {
    const { props, data } = filterForm.useSelect<Stowage>('stowage_id', () => {
        const company_id = controlList.filter.params.company_id
        return `/v5.6/apis/stowages?company_id=${company_id}&require_all_substowages=false&require_stowage_value=false`
    })

    function handleChange(option: Option) {
        props.onChange(option)
        filterForm.setValue('minimal_stowage_id', null)
        reqSummaryData.setQuery('stowage_id', option.value)
        reqSummaryData.setQuery('require_all_substowages', 'false')
        reqSummaryData.setQuery('require_stowage_value', 'false')
        reqSummaryData.deleteQuery('minimal_stowage_id')
        reqSubStowage.setParam('stowage_id', option.value)
        reqSubStowage.load()
    }

    return (
        <FormControl label="Bodega" className="mt-1">
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar bodega"
            >
                <IterateOptions data={data || []} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function SubStowage() {
    const { props } = filterForm.useSelect<MinimalStowage>('minimal_stowage_id')
    const { data, props: prosSearch } = useGet<MinimalStowageResponse[]>(
        reqSubStowage.observer(
            `/v5.6/apis/stowages/:stowage_id/substowages`,
            true
        ),
        []
    )
    let disabled = true
    if (filterForm.store().stowage_id) {
        const arrayStowage = [
            'Bodega Pedidos',
            'Bodega Remisionada',
            'Bodega Garantía Proveedor'
        ]
        disabled = !arrayStowage.includes(filterForm.store().stowage_id.label)
    }

    function handleChange(option: Option) {
        props.onChange(option)
        reqSummaryData.setQuery('minimal_stowage_id', option.value)
    }

    return (
        <FormControl label="Sub Bodega" className="mt-1">
            <SelectTouch
                {...props}
                {...prosSearch}
                disabled={disabled}
                placeholder="Seleccionar sub bodega"
                onChange={handleChange}
            >
                <IterateOptions data={data.result} label="substowage_name" />
            </SelectTouch>
        </FormControl>
    )
}

interface MinimalStowageResponse extends MinimalStowage {
    substowage_name: string
}

// function Alert() {
//     const { props } = filterForm.useSelect('cost_alert')

//     function handleChange(option: Option) {
//         props.onChange(option)
//         reqSummaryData.setQuery('cost_alert', option.value)
//     }

//     return (
//         <FormControl label="Alerta costeo" className="mt-1">
//             <SelectTouch
//                 {...props}
//                 placeholder="Seleccionar alerta de costeo"
//                 onChange={handleChange}
//             >
//                 <OptionComponent label="Activo" value="true" />
//                 <OptionComponent label="Inactivo" value="false" />
//             </SelectTouch>
//         </FormControl>
//     )
// }

export default function FiltersForm() {
    const { props } = controlList.useForm(
        filterForm,
        form => ({
            date: form.date ? moment(form.date).format('DD/MM/YYYY') : null
        }),
        form => ({
            date: form.date ? moment(form.date).format('YYYY-MM-DD') : null
        })
    )

    async function handleApply() {
        await reqSummaryData.load()
        await props.onApply()
        render(Keys.HEADER)
        render(Keys.EXPORT)
    }

    return (
        <DropContent {...props} onApply={handleApply} label="Filtros">
            <CategoryField />
            <DateField />
            <StowageSelect />
            <SubStowage />
            {/* <Alert /> */}
        </DropContent>
    )
}
