import { FormEvent, useEffect } from 'react'
import { Option } from '../../../../../../../config/types/component'
import useHelper from '../../../../../../../hooks/useHelper'
import { parseFile } from '../../../../../../../utilities/fileTools'
import { post, put } from '../../../../../../../utilities/http'
import Notify from '../../../../../../../utilities/Notify'
import { Progress } from '../../../../../../../utilities/progress'
import val from '../../../../../../../utilities/val'
import { storagePage } from '../../../../../storage'
import { ResponseLogisticalCost } from '../../../../../types'
import Aditional from '../../../../SectionAdditional/aditional'
import {
    EventBySubmit,
    FieldStatesValues,
    HelperFieldStatesValues
} from './types'

export default function useSubmit(
    values: FieldStatesValues,
    events: EventBySubmit,
    logisticalCost?: ResponseLogisticalCost
) {
    const { helper, setHelper } = useHelper<HelperFieldStatesValues>()

    useEffect(() => {
        setHelper({
            ...helper,
            concept: ''
        })
    }, [values.concept])

    useEffect(() => {
        setHelper({
            ...helper,
            currency: ''
        })
    }, [values.currency])

    useEffect(() => {
        setHelper({
            ...helper,
            cost: ''
        })
    }, [values.cost])

    useEffect(() => {
        setHelper({
            ...helper,
            exchangeRate: ''
        })
    }, [values.exchangeRate])

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const v = val(values)
        v.concept.condition((v: null | Option) =>
            v === null || (v && v.value === '0')
                ? 'Es requerido el concepto'
                : ''
        )
        v.currency.isEmpty('Es requerido la moneda')
        v.cost.isEmpty('Es requerido ingresar el costo')
        v.exchangeRate.isEmpty('Es requerido ingresar el tipo de cambio') // TC

        const result = v.runtest()
        if (result) {
            setHelper(result)
            return
        }

        const bodyPost: Aditional.BodyLCGeneralCreate = {
            agent_id: values.agent ? parseInt(values.agent.value) : null,
            document_number: values.documentNumber,
            type_logistical_cost_source_id: 5,
            currency_id: values.currency.id,
            logistical_concept_id: parseInt(values.concept.value),
            logistical_declaration_id: storagePage.logistical_declaration.id,
            version: storagePage.version,
            original_cost: parseFloat(values.cost),
            exchange_to_local: parseFloat(values.exchangeRate),
            reception_ids: values.rcs.map(rc => rc.reception_id),
            files: values.files.map(file => parseFile(file)),
            applicable_product_ids: values.productIds
        }

        const bodyPut: Aditional.BodyLCGeneralUpdate = {
            agent_id: logisticalCost?.agent ? logisticalCost.agent.id : null,
            document_number: logisticalCost?.document_number || '',
            currency_id: values.currency.id,
            original_cost: parseFloat(values.cost),
            exchange_to_local: parseFloat(values.exchangeRate),
            reception_ids: values.rcs.map(rc => rc.reception_id),
            new_files: values.files
                .filter(file => !file.id)
                .map(file => parseFile(file)),
            files_to_delete: logisticalCost
                ? logisticalCost.files
                      .filter(
                          file =>
                              !values.files.find(_file => _file.id === file.id)
                      )
                      .map(file => file.id)
                : [],
            applicable_product_ids: values.productIds
        }

        events.onOpenClose(false)
        Progress.open('Cargando')
        const path = logisticalCost
            ? `/v5.6/apis/logistical_costs/general/${logisticalCost.id}`
            : '/v5.6/apis/logistical_costs/general'
        const method = logisticalCost ? put : post
        const body = logisticalCost ? bodyPut : bodyPost
        const { error } = await method(path, body)
        Progress.close()

        if (error) {
            if (error.type === 'dependency' || error.type === 'development') {
                Notify.error('Se ha encontrado un error')
            } else {
                Object.entries(error.errors).forEach(([key]) => {
                    if (key === 'logistical_concept_id') {
                        setHelper({
                            ...helper,
                            concept:
                                'El concepto de pago ya está siendo usado en el formulario'
                        })
                    }
                })
            }
            events.onOpenClose(true)
        } else {
            Notify.success('La operación se ha completado exitosamente')
            events.onDone()
        }
    }

    return {
        submit,
        helper,
        setHelper
    }
}
