import 'moment/locale/es'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import BtnActionTable from '../../../../components/BtnActionTable'
import Icon from '../../../../components/Icon'
import ProductInventoryView from './ProductInventoryView'
import ProductPurchaseOrder from './ProductPurchaseOrder'
import { view } from '../../../../sections/View'
import ReactPopover from '../../../../components/ReactPopover'
import { storagePage } from './../../storage'
import CloseRequisition from './CloseRequisition'
import Rules from '../../../../components/Rules'

export default function Products() {
    async function handleViewInventory(requisition_product: any) {
        view(
            `/v5.6/apis/requisitions/products/provider?requisition_product_id=${requisition_product.id}`,
            () => ({
                title: 'Inventario del producto',
                Component: ProductInventoryView
            })
        )
    }

    async function handleViewOrder(requisition_product: any) {
        storagePage.requisition_product_id = requisition_product.id
        view(
            `/v5.6/apis/requisitions/products/provider?requisition_product_id=${requisition_product.id}`,
            () => ({
                title: 'Orden de compra del producto',
                Component: ProductPurchaseOrder
            })
        )
    }

    return (
        <>
            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>Descripción del producto</th>
                        <th>Cantidad solicitada</th>
                        <th>Cantidad en OC</th>
                        <th>Cantidad pendiente</th>
                        <th>Estado</th>
                        <th className="cell-actions">Acciones</th>
                    </tr>
                </THead>
                <TBody>
                    {storagePage.products.map((item, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                                <Typography
                                    type="paragraph"
                                    className={'font-bold'}
                                >
                                    {item.product.description}
                                </Typography>
                                <Typography type="small" color="gray">
                                    {item.product.code}
                                </Typography>
                            </td>
                            <td>{item.quantity}</td>
                            <td>
                                {item.quantity_purchase_products}
                                <Rules cu="CU218">
                                    {item.quantity_purchase_products > 0 && (
                                        <Icon
                                            onClick={() =>
                                                handleViewOrder(item)
                                            }
                                            pointer
                                            name="search"
                                            className="ml-2"
                                        ></Icon>
                                    )}
                                </Rules>
                            </td>
                            <td>
                                {item.quantity -
                                    item.quantity_purchase_products}
                            </td>
                            <td>
                                {item.is_closed ? 'Cerrado' : 'Abierto'}
                                {item.is_closed && (
                                    <ReactPopover
                                        component={
                                            <Icon
                                                className="text-yellow-500 ml-2"
                                                name="warning"
                                            />
                                        }
                                    >
                                        {item.reason}
                                    </ReactPopover>
                                )}
                            </td>
                            <td>
                                <div className="group-btn-options">
                                    <Rules cu="CU217">
                                        <BtnActionTable
                                            title="Visualizar inventario"
                                            iconName="bs-binoculars"
                                            onClick={() =>
                                                handleViewInventory(item)
                                            }
                                        />
                                    </Rules>
                                    <Rules cu="CU219">
                                        <CloseRequisition
                                            requisition_product_id={item.id}
                                            disabled={item.is_closed}
                                        ></CloseRequisition>
                                    </Rules>
                                </div>
                            </td>
                        </tr>
                    ))}
                    {storagePage.products.length === 0 && (
                        <Caption colSpan={5}>
                            No hay registros disponibles, seleccionar por
                            busqueda de código o nombre de producto{' '}
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
