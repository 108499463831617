import { Option } from '../../../../config/types/component'
import useStorage from '../../../../hooks/useStorage'
import { setCompanySelected } from '../../../../utilities/controlStorage'
import { formInformation, reqStowageDestiny } from '../../storage'

export default function useCompany() {
    const { props } = formInformation.useSelect('company')
    const { companiesFromSession } = useStorage()

    const data = companiesFromSession
        .filter(company => company.is_enabled)
        .map(company => ({
            id: company.id,
            name: company.name,
            image_url: company.country.image_url
        }))

    function handleChange(option: Option) {
        const company = companiesFromSession.find(
            _cp => _cp.id === parseInt(option.value)
        )
        setCompanySelected(company)
        reqStowageDestiny.setQuery('company_id', option.value)
        reqStowageDestiny.load()
        formInformation.setProps(
            'stowage_destination',
            { disabled: false },
            false
        )
        formInformation.setProps(
            'sub_stowage_destination',
            { disabled: true },
            false
        )
        formInformation.setValue('stowage_destination', null)
        formInformation.setValue('sub_stowage_destination', null)
        props.onChange(option)
    }

    return { props, handleChange, companies: data }
}
