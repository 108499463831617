import moment from 'moment'
import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowMonth(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [
        {
            align: 'center',
            value: 'MES',
            fontWeight: 'bold'
        }
    ]

    const workingPeriodIds = data
        .map(d => d.working_period_id)
        .filter((v, i, a) => a.indexOf(v) === i)

    workingPeriodIds.forEach(working_period_id => {
        const d_filtereds = data.filter(
            d => d.working_period_id === working_period_id
        )
        d_filtereds.forEach((d, index) => {
            row.push({
                value: d_filtereds.length - index
            })
            row.push({
                value: ''
            })
            row.push({
                value: ''
            })
            row.push({
                align: 'center',
                fontWeight: 'bold',
                value: getMonth(d.working_period.month),
                borderStyle: 'thin'
            })
            return row.push({
                value: '',
                backgroundColor: COLOR.gray
            })
        })
    })

    return addRowStyles(row, [borderThin])
}

function getMonth(index) {
    const month = moment.months(index - 1)
    return (month.charAt(0) + month.slice(1)).toUpperCase()
}
