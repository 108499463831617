import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import { formInformation, reqReception, storagePage } from '../../storage'
import InputField from '../../../../components/InputField'
import useCompany from './useCompany'
import useGet from '../../../../hooks/useGet'
import Reception from '../../../../models/Reception'
import useReception from './useReception'
import useEstimatedAmount from './useEstimatedAmount'
import NumeralField from '../../../../components/NumeralField'
import { getCurrency } from '../../Utils/getCurrency'

function Company() {
    const { props, companies, handleChange } = useCompany()

    return (
        <FormControl label="Compañía" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar compañía"
                label="Compañía"
                clear={false}
                disabled={storagePage.mode === 'edition'}
            >
                <IterateOptions data={companies} label="name" image="image" />
            </SelectTouch>
        </FormControl>
    )
}

function PurchaseReceipt() {
    const { props, handleChange } = useReception()
    const { data, loading } = useGet<Reception[]>(
        reqReception.observer('/v5.6/apis/receptions/', true),
        []
    )
    return (
        <FormControl label="Recepción de compra" required>
            <SelectTouch
                {...props}
                loading={loading}
                onChange={option => handleChange(option, data)}
                placeholder="Seleccionar recepción de compra"
                label="Recepción de compra"
                disabled={storagePage.mode === 'edition'}
            >
                <IterateOptions data={data.result} label="code" />
            </SelectTouch>
        </FormControl>
    )
}

function PurchaseCost() {
    const { props } = formInformation.useInput('purchaseCost')

    return (
        <FormControl label="Costo de compra">
            <InputField {...props} readOnly />
        </FormControl>
    )
}

function EstimatedAmount() {
    const { props, handleChange } = useEstimatedAmount()

    return (
        <FormControl label={`Monto estimado (${getCurrency()})`} required>
            <NumeralField {...props} decimal={2} onChange={handleChange} />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <Company />
            <PurchaseReceipt />
            <PurchaseCost />
            <EstimatedAmount />
        </>
    )
}
