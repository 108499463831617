import { useHistory } from 'react-router'
import BtnActionTable from '../../../components/BtnActionTable'
import Rules from '../../../components/Rules'
import Delete from '../../../sections/Delete'
import { controlList } from '../storage'
import { SubcategoryData } from '../types'
import useSubmit from '../useSubmit'

function Edit({ subcategory }: { subcategory: SubcategoryData }) {
    const open = useSubmit(subcategory.id)
    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={() =>
                open({
                    name: subcategory.name,
                    category_id: {
                        label: subcategory.category.name,
                        value: subcategory.category.id.toString()
                    }
                })
            }
        />
    )
}

export default function Actions({
    subcategory
}: {
    subcategory: SubcategoryData
}) {
    const { push } = useHistory()
    return (
        <>
            <Rules cu="CU047">
                <Edit subcategory={subcategory} />
            </Rules>
            <Rules cu="CU050">
                <BtnActionTable
                    title="Editar"
                    iconName="bs-tag"
                    onClick={() =>
                        push(
                            `/configuration/subcategories/${subcategory.id}/factor`
                        )
                    }
                />
            </Rules>
            <Rules cu="CU048">
                <Delete
                    path={`/v5.6/apis/subcategories/${subcategory.id}`}
                    control={controlList}
                >
                    ¿Está seguro de eliminar la subcategoría{' '}
                    <b className="text-primary">{subcategory.name}</b>?
                </Delete>
            </Rules>
        </>
    )
}
