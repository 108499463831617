import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import Button from '../../../../components/Button'
import TableRCs from './Tables/TableRCs'
import TableInternationalCosts from './Tables/TableInternationalCosts'
import TableNationalization from './Tables/TableNationalization'
import TableAditionalNationalCosts from '../SectionAdditional/tables/TableAditionalNationalCosts'
import useSubmitRC from './FormRC/useSubmitRC'
import { storagePage } from '../../storage'
import useSubmitCostGeneral from '../SectionAdditional/FormCostGeneral/useSubmitCostGeneral'
import useSubmitCostByProduct from '../SectionAdditional/FormCostByProduct/useSubmitCostByProduct'

export default function SectionCosts() {
    const open = useSubmitRC()
    const type = storagePage.type
    const openCostGeneralNational = useSubmitCostGeneral('national')
    const openCostByProductNa = useSubmitCostByProduct('national')

    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Recepciones de compra">
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => open()}
                    >
                        + Agregar
                    </Button>
                </PanelHeader>
                <PanelBody>
                    <TableRCs />
                </PanelBody>
            </Panel>
            {type === 'international' && (
                <Panel mode="form">
                    <PanelHeader title="Costos internacionales" />
                    <PanelBody>
                        <TableInternationalCosts />
                    </PanelBody>
                </Panel>
            )}
            {type === 'international' && (
                <Panel mode="form">
                    <PanelHeader title="Costos de nacionalización" />
                    <PanelBody>
                        <TableNationalization />
                    </PanelBody>
                </Panel>
            )}
            {type === 'national' && (
                <Panel mode="form">
                    <PanelHeader title="Costos nacionales adicionales">
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            onClick={() => openCostByProductNa()}
                        >
                            + Costo por producto
                        </Button>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            className="ml-2"
                            onClick={() => openCostGeneralNational()}
                        >
                            + Costo general
                        </Button>
                    </PanelHeader>
                    <PanelBody>
                        <TableAditionalNationalCosts />
                    </PanelBody>
                </Panel>
            )}
        </>
    )
}
