import { Page, Document, StyleSheet } from '@react-pdf/renderer'
import { controlList, storagePage } from '../../storage'
import moment from 'moment'
import { BalanceHistory } from '../../types'
import Header from './Header'
import Body from './Body'

const styles = StyleSheet.create({
    section: {
        padding: '30px 30px'
    },
    page: {
        fontSize: '9px',
        fontFamily: 'Helvetica',
        marginBottom: '10px',
        color: '#2A2A5B'
    }
})

export default function Pdf({ data }: { data: BalanceHistory[] }) {
    const company = storagePage.originalCompanies.find(
        comp =>
            comp.id ===
            parseInt(controlList.filter.getOption('company_id').value)
    )

    return (
        <Document title={moment().format('DD-MM-YYYY')}>
            <Page size="A4" style={styles.section}>
                <Header company={company} data={data} />
                <Body data={data} />
            </Page>
        </Document>
    )
}
