import { ReactNode } from 'react'
import { PropsProgressDialog } from './type'
import Loading from '../Loading'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import './progressDialog.css'

export default function ProgressDialog({
    open = false,
    children
}: PropsProgressDialog & { children: ReactNode }) {
    return createPortal(
        <div
            className={classNames('modal progress-component', {
                'progress-component-open': open
            })}
        >
            <div className="modal-box max-w-xl relative">
                <div className="py-8 px-4">
                    <div className="mb-4 text-center h-44">
                        <Loading>{children}</Loading>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('modal-contain')
    )
}
