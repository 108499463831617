import { render, useRender } from 'redity'
import { UseSelectData, UseSelectsData } from './types'
import Base from './Base'
import { ChangeEvent, useState } from 'react'
import { Option } from '../../config/types/component'
import { DriveFile } from '../Drive'
import { LatLng } from '../../components/MapView/types'

export default class FormEvents<T> extends Base<T> {
    useInput = <K extends keyof T>(fieldName: K) => {
        useRender(this.keyRender, fieldName as string)
        const props = this.getValues(fieldName)
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)
        const onChange = (ev: any) => {
            const value = ev.target.value
            this.store.set(fieldName, value)
            this.store.helper(fieldName, '')
            clear()
            render(this.keyRender, fieldName as string)
        }

        return {
            error,
            props: {
                ...props,
                onChange
            },
            getError
        }
    }

    useSelect<D>(
        fieldName: keyof T,
        /**
         * @deprecated
         */
        pathRequest: (() => string) | string = ''
    ): UseSelectData<T, D> {
        useRender(this.keyRender, fieldName as string)
        const { value, ...props } = this.getValues(fieldName)
        const { data, onSearch, ...nextProps } = this.useSearcher<D>(
            fieldName,
            pathRequest
        )
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)
        const onChange = (option: Option) => {
            if (option.value === '0' || option.value === '') {
                this.store.set(fieldName, null)
            } else {
                this.store.set(fieldName, option as any)
            }
            this.store.helper(fieldName, '')
            clear()
            render(this.keyRender, fieldName as string)
        }

        return {
            error,
            props: {
                option: value,
                ...nextProps,
                ...props,
                onChange,
                onSearch: pathRequest ? onSearch : null
            },
            result: data,
            data,
            getError
        }
    }

    useSelects<D>(
        fieldName: keyof T,
        pathRequest: (() => string) | string = ''
    ): UseSelectsData<T, D> {
        useRender(this.keyRender, fieldName as string)
        const { value, ...props } = this.getValues(fieldName)
        const { data, onSearch, ...nextProps } = this.useSearcher<D>(
            fieldName,
            pathRequest
        )
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)

        const onChange = (options: Option[]) => {
            this.store.set(fieldName, options as any)
            this.store.helper(fieldName, '')
            clear()
            render(this.keyRender, fieldName as string)
        }

        return {
            error,
            props: {
                options: value,
                ...props,
                ...nextProps,
                onChange,
                onSearch: pathRequest ? onSearch : null
            },
            data,
            getError
        }
    }

    useCheck<K extends keyof T>(fieldName: K) {
        const r = useRender(this.keyRender, fieldName as string)
        const { value, ...props } = this.getValues(fieldName)
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)

        const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
            const checked: boolean = ev.target.checked
            this.store.set(fieldName, checked as any)
            this.store.helper(fieldName, '')
            clear()
            r()
        }

        return {
            error,
            render: () => r(),
            props: {
                checked: value,
                onChange: handleChange,
                ...props
            },
            getError
        }
    }

    useUploads<K extends keyof T>(fieldName: K) {
        const r = useRender(this.keyRender, fieldName as string)
        const { value, helper, ...props } = this.getValues(fieldName)
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)

        const handleChange = (files: DriveFile[]) => {
            this.store.set(fieldName, files as any)
            this.store.helper(fieldName, '')
            clear()
            r()
        }

        return {
            error,
            props: {
                files: value,
                onChange: handleChange,
                helper,
                ...props
            },
            getError
        }
    }

    useUpload<K extends keyof T>(fieldName: K) {
        const r = useRender(this.keyRender, fieldName as string)
        const { value, helper, ...props } = this.getValues(fieldName)
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)

        const handleChange = (file: DriveFile | File) => {
            this.store.set(fieldName, file as any)
            this.store.helper(fieldName, '')
            clear()
            r()
        }

        return {
            error,
            props: {
                file: value,
                onChange: handleChange,
                helper,
                ...props
            },
            getError
        }
    }

    useResults<D>(fieldName: keyof T, pathRequest?: any) {
        const { data, props, error, getError } = this.useSelects<D>(
            fieldName,
            pathRequest
        )
        const {
            onChange,
            onSearch,
            search,
            options: opts,
            ...nextProps
        } = props
        const handleSelect = (options: Option[]) => {
            onChange(options)
        }

        const handleChange = (ev: any) => {
            onSearch(ev)
        }

        return {
            error,
            data,
            props: {
                onSelect: handleSelect,
                onChange: handleChange,
                value: search,
                options: opts,
                ...nextProps
            },
            getError
        }
    }

    useDate<K extends keyof T>(fieldName: K) {
        const r = useRender(this.keyRender, fieldName as string)
        const { value: v, helper, ...props } = this.getValues(fieldName)
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)

        const handleChange = (value: string) => {
            this.store.set(fieldName, value as any)
            this.store.helper(fieldName, '')
            clear()
            r()
        }

        return {
            error,
            props: {
                value: v,
                onChange: handleChange,
                helper,
                ...props
            },
            getError
        }
    }

    useMap<K extends keyof T>(fieldName: K) {
        const r = useRender(this.keyRender, fieldName as string)
        const { value, helper, ...props } = this.getValues(fieldName)
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)
        const [center, setCenter] = useState<LatLng>(
            () =>
                value || {
                    lat: 38.2850563,
                    lng: -100.3448311
                }
        )

        const handleMarker = (latLng: LatLng) => {
            this.store.set(fieldName, latLng as any)
            this.store.helper(fieldName, '')
            clear()
        }

        const handlePlaceChange = (_, latLng: LatLng) => {
            this.store.set(fieldName, latLng as any)
            setCenter(latLng)
            this.store.helper(fieldName, '')
            clear()
            r()
        }

        return {
            error,
            props: {
                center,
                onMarker: handleMarker,
                helper,
                onSelect: handlePlaceChange,
                position: value,
                ...props
            },
            getError
        }
    }

    useImage<K extends keyof T>(fieldName: K) {
        const r = useRender(this.keyRender, fieldName as string)
        const { value, helper, ...props } = this.getValues(fieldName)
        const { error, getError, clear } =
            this.errorControl.getErrorByField(fieldName)

        const handleChange = (imageUrl: string) => {
            this.store.set(fieldName, imageUrl as any)
            this.store.helper(fieldName, '')
            clear()
            r()
        }

        return {
            error,
            props: {
                onChange: handleChange,
                imageUrl: value,
                helper,
                ...props
            },
            getError
        }
    }

    useValue<K extends keyof T>(fieldName: K) {
        useRender(this.keyRender, fieldName as string)
        const { value, helper, ...props } = this.getValues(fieldName)
        const { error, getError } = this.errorControl.getErrorByField(fieldName)

        return {
            props: {
                value: value as T[K],
                helper: helper as string,
                ...(props as Record<string, any>)
            },
            error,
            getError
        }
    }
}
