import Option from '../Option'
import FormControl from './FormControl'
import FormControls from './FormControls'
import ControlDivider from './ControlDivider'
import { PropsLabelInline, PropsEmpty, PropsIterateOption } from './types'
import classNames from 'classnames'

function Empty({ data, message = 'No se encontraron resultados' }: PropsEmpty) {
    return data.length === 0 ? (
        <div className="text-xs text-gray-400 py-2">{message}</div>
    ) : null
}

function IterateOptions<T>({
    data,
    label,
    label2,
    image = null,
    icon = null,
    strikeOut
}: PropsIterateOption<T>) {
    return (
        <>
            {data.map(d => (
                <Option
                    key={d.id || d.value}
                    label={d[label] as any}
                    label2={(d[label2] || null) as any}
                    value={d.id?.toString() || d.value}
                    image={(d[image] || null) as any}
                    icon={(d[icon] || null) as any}
                    strikeOut={(d[strikeOut] || false) as boolean}
                />
            ))}
            <Empty data={data} />
        </>
    )
}

function LabelInline({ children, label, className }: PropsLabelInline) {
    return (
        <label
            className={classNames(
                'flex items-center text-neutral text-xs',
                className
            )}
        >
            {children} <div className="ml-2">{label}</div>
        </label>
    )
}

export {
    FormControl,
    FormControls,
    Empty,
    IterateOptions,
    ControlDivider,
    LabelInline
}
