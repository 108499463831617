import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import ReadOnly from '../../../../components/ReadOnly'
import { formManagementRQ, storagePage, reqRequisition } from '../../storage'
import useGet from '../../../../hooks/useGet'
import { Option } from '../../../../config/types/component'
import { render } from 'redity'
import { RequisitionManagement } from '../../types'

function Requisition() {
    const { props } = formManagementRQ.useSelect('requisition')
    const { data, props: propsSearch } = useGet<RequisitionManagement[]>(
        reqRequisition.observer(
            '/v5.6/apis/requisitions/manage?limit=20&order=desc&sort=id',
            true
        ),
        []
    )
    if (!storagePage.initLoad && formManagementRQ.store().requisition) {
        const requisition = data.result.find(
            rq => rq.id === parseInt(formManagementRQ.store().requisition.value)
        )
        if (requisition) {
            storagePage.requisition = requisition
            storagePage.products = requisition.requisition_products
            storagePage.initLoad = true
            render('requisition_data')
        }
    }

    async function handleChange(option: Option) {
        if (option.value === '0') {
            storagePage.requisition = null
            storagePage.products = []
            render('requisition_data')
        } else {
            const requisition = data.result.find(
                rq => rq.id === parseInt(option.value)
            )
            storagePage.requisition = requisition
            storagePage.products = requisition.requisition_products
            render('requisition_data')
        }
        props.onChange(option)
    }

    return (
        <FormControl label="Requisición">
            <SelectTouch
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar requisición"
                label="Requisición"
            >
                <IterateOptions data={data.result} label="code" />
            </SelectTouch>
        </FormControl>
    )
}

function StowageDestination() {
    const stowage_destination = storagePage.requisition
        ? getSubStowages(storagePage.requisition.minimal_stowage)
        : '-'

    return (
        <FormControl label="Bodega destino">
            <ReadOnly value={stowage_destination}></ReadOnly>
        </FormControl>
    )
}

export default function Fields() {
    return (
        <>
            <Requisition />
            <StowageDestination />
        </>
    )
}

function getSubStowages(minimal_stowage: any) {
    const name = minimal_stowage.stowage.name
    const type_stowage_id = minimal_stowage.stowage.type_stowage.id

    if ([2, 11].includes(type_stowage_id)) {
        return `${name} - ${minimal_stowage.order_sub_stowage.order.number}`
    } else if (type_stowage_id === 6) {
        return `${name} - ${minimal_stowage.gp_sub_stowage.order.number}`
    } else if (type_stowage_id === 7) {
        return `${name} - ${minimal_stowage.client_sub_stowage.client.name}`
    } else {
        return name
    }
}
