import Company from '../../../models/Company'
import Country from '../../../models/Country'
import ListFormControl from '../../../sections/ListForm/ListFormControl'
import { data } from '../storage'
import { DataList, DataTable, CDUOption } from '../types'
import getCompanyDeparmentRoleUser from './getCompanyDepartmentRoleUser'

function getCompanyDepartmentUser(optionsCDU: CDUOption[], role_id: number) {
    const cdus = data.companyDepartmentUsers.filter(cdu => {
        const cdru = getCompanyDeparmentRoleUser(cdu.id, role_id)
        if (cdru) {
            return cdru.is_enabled
        }
        return false
    })

    return optionsCDU.filter(op => cdus.find(cdu => cdu.id === op.id))
}

export default function createList(
    keyRender: string,
    dataTable: DataTable,
    companies: Array<Company & { country: Country }>
) {
    if (data.controlLists[keyRender]) {
        return data.controlLists[keyRender]
    }

    const controlList = new ListFormControl<DataList>(keyRender, [
        '#',
        'Rol',
        'Compañía'
    ])

    function getImage(company_id: number) {
        const companyFinded = companies.find(
            company => company.id === company_id
        )
        if (companyFinded) {
            return {
                image: companyFinded.country.image_url
            }
        }
        return {}
    }

    controlList.setList(
        dataTable.roles
            .filter(role => role.memory)
            .map(role => ({
                role_id: role.id,
                name: role.name,
                cduSelecteds: getCompanyDepartmentUser(
                    role.cduOptions,
                    role.id
                ).map(cdu => ({
                    value: cdu.id.toString(),
                    label: cdu.name,
                    ...getImage(cdu.company_id)
                })),
                cduOptions: role.cduOptions
            }))
    )

    data.controlLists[keyRender] = controlList

    return controlList
}
