import { THeadValue } from '../../utilities/Control/ControlList'
import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('PURCHASE_ORDERS', [
    'TABLE',
    'FORM_FILTER',
    'PANEL_HEADER',
    'FORM_DIALOG_CLOSE_PURCHASE'
])

const commonCols: THeadValue[] = [
    '#',
    { value: 'code', label: 'Código' },
    { value: 'provider_commercial_name', label: 'Proveedor' },
    { value: 'requesting_user_name', label: 'Solicitante' },
    { value: 'type_purchase_name', label: 'Tipo de OC' },
    'Total bruto',
    { value: 'required_date', label: 'Fecha requerida' }
]

export const tHeadsPending: THeadValue[] = [...commonCols, 'Acciones']
export const tHeadsSending: THeadValue[] = [...commonCols, 'Acciones']
export const tHeadsConfirm: THeadValue[] = [
    ...commonCols,
    { value: 'provider_confirmation_date', label: 'Fecha confirmada' },
    'Progreso',
    'Acciones'
]

export const tHeadsClose: THeadValue[] = [
    ...commonCols,
    { value: 'provider_confirmation_date', label: 'Fecha confirmada' },
    'Progreso',
    'Acciones'
]
