export type KeysEnter<T> = Record<keyof T, string>
export type KeysCollection<T> = { MAIN: string } & Record<keyof T, string>

/**
 * @deprecated use createKeys
 */
export default function keysCollection<T>(
    key_main: string,
    keys: KeysEnter<T>
): KeysCollection<T> {
    const keys_collection: Record<string, string> = {
        MAIN: key_main
    }
    for (const key in keys) {
        keys_collection[key] = `${key_main}_${keys[key]}`
    }

    return keys_collection as KeysCollection<T>
}
