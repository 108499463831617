import './dropImage.css'
import classNames from 'classnames'
import Icon from '../Icon'
import Loading from '../Loading'
import { PropsDropImage } from './types'
import Helper from '../Helper'
import Drive from '../../utilities/Drive'
import { DRIVE_VIEW } from '../../config/credentials'
import { useState } from 'react'
import { expectSize, parseFileSize } from '../../utilities/fileTools'

export default function DropImage({
    className = '',
    helper = '',
    onChange = () => null,
    imageUrl = '',
    maxSizeFile = 10000000
}: PropsDropImage) {
    const [loading, setLoading] = useState(false)
    async function handleChange(ev) {
        const files: FileList = ev.target.files
        if (files.length === 0) return
        if (!files[0].type.match('image/jpeg|image/jpg|image/png')) {
            alert('archivo no soportado')
            return
        }
        if (!expectSize(Array.from(files), maxSizeFile)) {
            alert(`Soporte máximo de ${parseFileSize(maxSizeFile)}`)
            return
        }
        const drive = new Drive()
        drive.getAuth()
        setLoading(true)
        const response = await drive.upload(files[0])
        setLoading(false)
        onChange(DRIVE_VIEW + response.drive_id)
    }

    return (
        <>
            <div
                className={classNames(
                    'drop-image',
                    { 'border-red-500': !!helper },
                    className
                )}
                onDrop={handleChange}
            >
                {!loading && !imageUrl && (
                    <input
                        tabIndex={-1}
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        multiple={false}
                        onChange={handleChange}
                        className="opacity-0 w-full h-full absolute left-0 top-0 cursor-pointer"
                    />
                )}
                {imageUrl && (
                    <div className="remove" onClick={() => onChange('')}>
                        <Icon name="bs-trash" className="text-white" />
                    </div>
                )}
                {imageUrl && (
                    <div className="content-image">
                        <img src={imageUrl} />
                    </div>
                )}
                {!imageUrl && (
                    <div className="w-full h-full text-center flex flex-col items-center justify-center">
                        {!loading && (
                            <>
                                <Icon
                                    name="cloud-upload"
                                    size={40}
                                    className="mb-2 text-accent block"
                                />
                                <p className="text-xs text-gray-400 px-4">
                                    Haz clic o arrastra una imagen para añadirle
                                </p>
                            </>
                        )}
                        {loading && <Loading scale={1} />}
                    </div>
                )}
            </div>
            <Helper>{helper}</Helper>
        </>
    )
}
