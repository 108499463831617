import BtnActionTable from '../../../../components/BtnActionTable'
import Toggle from '../../../../components/Toggle'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { Popup } from '../../../../utilities/popup'
import { tableWithdrawal } from '../../storage'
import { Step3 } from '../../types'
import EditWithdrawal from './EditWithdrawal'

function Main({
    control,
    index
}: {
    control: FormEvents<Step3.TableWithdrawal>
    index: number
}) {
    const { props } = control.useCheck('is_main')
    const { country, enabled } = control.store()

    function handleChange(ev: any) {
        props.onChange(ev)
        const checked = ev.target.checked as boolean
        if (checked) {
            tableWithdrawal.list.forEach((data, i) => {
                if (data.store().country.id === country.id && index !== i) {
                    data.setValue('is_main', false)
                }
            })
        } else {
            const withdrawalFinded = tableWithdrawal.list.find(
                (data, i) =>
                    data.store().country.id === country.id && index !== i
            )
            if (withdrawalFinded) {
                withdrawalFinded.setValue('is_main', true)
            }
        }
    }

    return <Toggle {...props} onChange={handleChange} disabled={!enabled} />
}

export default function TableWithdrawal() {
    async function handleDel(index) {
        const withdrawal = tableWithdrawal.get(index)
        if (withdrawal.id) {
            const result = await Popup.delete(
                'Eliminar registro',
                <>
                    Se removerá la dirección de retiro de la lista ¿Está seguro
                    de eliminar la dirección de retiro{' '}
                    <b>{withdrawal.place_retreat}</b>?
                </>
            )
            if (!result) return
        }

        const withdrawalDeleted = tableWithdrawal.del(index)
        if (withdrawalDeleted.is_main) {
            const withdrawalIndex = tableWithdrawal
                .getDataList()
                .findIndex(
                    data => data.country.id === withdrawalDeleted.country.id
                )
            withdrawalIndex > -1 &&
                tableWithdrawal.put(withdrawalIndex, { is_main: true })
        }
    }

    return (
        <TableList control={tableWithdrawal} scrollHeight={250}>
            {(retreat, control, e) => (
                <tr key={e}>
                    <td>
                        <img
                            src={retreat.country.image_url}
                            className="w-8 max-w-min"
                        />
                    </td>
                    <td>{retreat.place_retreat}</td>
                    <td>
                        <Main control={control} index={e - 1} />
                    </td>
                    <td>{retreat.address}</td>
                    <td>
                        <div className="group-btn-options">
                            <EditWithdrawal retreat={retreat} index={e - 1} />
                            <BtnActionTable
                                title="Eliminar"
                                iconName="bs-trash"
                                disabled={!retreat.enabled}
                                onClick={() => handleDel(e - 1)}
                            />
                        </div>
                    </td>
                </tr>
            )}
        </TableList>
    )
}
