import { formInformation } from '../../storage'
import { loadProducts } from '../../Utils/loadProducts'

export default function useEstimatedAmount() {
    const { props } = formInformation.useInput('estimatedAmount')

    function handleChange(value) {
        props.onChange(value)
        loadProducts()
    }

    return {
        props,
        handleChange
    }
}
