import 'moment/locale/es'
import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import Typography from '../../../../components/Typography'
import { GetTraspase } from '../../types'

export default function TraspaseView({ data }: { data: GetTraspase }) {
    return (
        <>
            <Typography type="header-3" className="mb-4 mt-4">
                Productos del movimiento
            </Typography>
            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>Producto</th>
                        <th>Cantidad solicitante</th>
                    </tr>
                </THead>
                <TBody>
                    {data.classic_traspase_products.map((item, i) => (
                        <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td>
                                <Typography
                                    type="paragraph"
                                    className={'font-bold'}
                                >
                                    {item.traspase_product.product.name}
                                </Typography>
                                <Typography type="small" color="gray">
                                    {item.traspase_product.product.code}
                                </Typography>
                            </td>
                            <td>{item.traspase_product.quantity}</td>
                        </tr>
                    ))}
                    {data.classic_traspase_products.length === 0 && (
                        <Caption colSpan={3}>
                            No se encontraron registros
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}
