import { FormControl } from '../../../../../../components/FormUtilities'
import InputField from '../../../../../../components/InputField'
import { formCostGeneral } from '../../../SectionAdditional/storageStep3'

function FieldDocumentNumber() {
    const { props } = formCostGeneral.useInput('document_number')

    return (
        <FormControl label="Nro. de documento">
            <InputField {...props} placeholder="Ingresar número de documento" />
        </FormControl>
    )
}

export default FieldDocumentNumber
