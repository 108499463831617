import Checkbox from '../../../../components/Checkbox'
import Icon from '../../../../components/Icon'
import { TableList } from '../../../../sections/ListForm'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import FormEvents from '../../../../utilities/FormEvents'
import { TableRQ } from '../../types'
import Alerts from './Alerts'
import useCheckbox from './useCheckbox'
import useProviderPrice from './useProviderPrice'
import useQuantityBuy from './useQuantityBuy'
import useTableRequisitions from './useTableRequisitions'

export default function Table() {
    const { tableRequisition } = useTableRequisitions()

    return (
        <TableList
            control={tableRequisition}
            scrollHeight={500}
            theadWhiteSpace="normal"
            tbody={(rq, control, e) => (
                <tr key={e}>
                    <td>{e}</td>
                    <td>{rq.rq}</td>
                    <td>
                        <p>{rq.stowage_name}</p>
                        {rq.stawage_code && <p>{rq.stawage_code}</p>}
                    </td>
                    <td>
                        <p>{rq.product_description}</p>
                        <span className="text-2xs text-gray-400">
                            {rq.product_code}
                        </span>
                    </td>
                    <td>{rq.open_days}</td>
                    <Alerts rq={rq} />
                    <td>{rq.pending_amount}</td>
                    <QuantityBuy index={e - 1} control={control} />
                    <ProviderPrice index={e - 1} control={control} />
                    <Action index={e - 1} control={control} />
                </tr>
            )}
        />
    )
}

function QuantityBuy({ control, index }: PropsTd) {
    const { quantity_buy, open } = useQuantityBuy(control, index)
    return (
        <td>
            {quantity_buy}{' '}
            <Icon
                name="bs-pencil"
                disabled={quantity_buy === 0}
                onClick={() =>
                    open({
                        quantity: (quantity_buy || '').toString()
                    })
                }
                pointer
            />
        </td>
    )
}

function ProviderPrice({ control, index }: PropsTd) {
    const { symbol, provider_price, open, quantity_buy } = useProviderPrice(
        control,
        index
    )
    return (
        <td>
            {symbol} {buildNumberFormat(parseFloat(provider_price))}{' '}
            <Icon
                name="bs-pencil"
                disabled={quantity_buy === 0}
                onClick={() =>
                    open({
                        price: (provider_price || '').toString()
                    })
                }
                pointer
            />
        </td>
    )
}

function Action({ control }: PropsTd) {
    const { props, handleChange } = useCheckbox(control)

    return (
        <td>
            <Checkbox {...props} onChange={handleChange} />
        </td>
    )
}

interface PropsTd {
    control: FormEvents<TableRQ>
    index: number
}
