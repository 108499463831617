import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../storage'
import { ResponseLDR } from '../../../types'

export default function Result({
    logisticalDeclarationReception
}: {
    logisticalDeclarationReception: ResponseLDR[]
}) {
    return (
        <div className="flex justify-end mt-4 font-bold text-xs">
            <div className="bg-gray-200 py-2 px-10 col-span-2 w-56">
                Diferencia de costo
            </div>
            <div className="bg-gray-200 py-2 px-4 w-28">
                {storagePage.currentCurrency.symbol}{' '}
                {buildNumberFormat(
                    logisticalDeclarationReception.reduce(function (a, b) {
                        if (!b.reception.costing_estimate) return a + 0

                        const val =
                            b.logistical_cost -
                            (b.reception.costing_estimate?.estimate_cost || 0)

                        return a + val
                    }, 0)
                )}
            </div>
        </div>
    )
}
