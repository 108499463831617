export default function Logout(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 93.614 93.614"
            {...props}
        >
            <path d="M80.463 51.983c-.051 0-.102-.008-.152-.008v30.721H10.914V13.303h30.717c0-.06-.006-.118-.006-.178V2.77c0-.129.016-.253.018-.381H2.049A2.05 2.05 0 000 4.438v87.126a2.05 2.05 0 002.049 2.05h87.126a2.049 2.049 0 002.049-2.05V51.967c-.114.003-.229.018-.342.018l-10.419-.002z" />
            <path d="M64.776 16.958L25.688 56.047a2 2 0 000 2.827l9.045 9.045c.391.392.902.587 1.414.587.512 0 1.023-.195 1.414-.587l39.087-39.087 9.611 9.609a1.997 1.997 0 003.4-1.19L93.6 2.226a2.006 2.006 0 00-.574-1.638 1.99 1.99 0 00-1.637-.573L56.366 3.956a2 2 0 00-1.188 3.401l9.598 9.601z" />
        </svg>
    )
}
