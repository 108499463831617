import Panel, { PanelBody } from '../../../components/Panel'
import TablePresentations from './TablePresentations'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import FilterPresentations from './FilterPresentations'

export default function Presentations() {
    const state = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterPresentations />
                    <TablePresentations />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
