import { Link } from 'react-router-dom'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import SearchField from '../../../components/SearchField'
import useStorage from '../../../hooks/useStorage'
import { controlList } from '../storage'
import {
    SectionFiltersGrid,
    FlexBox,
    FlexLeft,
    FlexRight
} from '../../../components/UtilsResponsive/UtilsResponsive.style'

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            size="auto"
            fullWidth={true}
            placeholder="Buscar por nombre de agente"
            className="ml-0"
        />
    )
}

function TypeAgent() {
    const { props } = controlList.useFilter('type_agent_id')
    const { typeAgents } = useStorage()
    return (
        <Dropdown {...props} label="Tipo de agente">
            <IterateOptions data={typeAgents} label="name" />
        </Dropdown>
    )
}

export default function FilterAgents() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <TypeAgent />
                </FlexLeft>
                <FlexRight>
                    <Link to="/configuration/agents/creation">
                        <Button size="sm">Nuevo</Button>
                    </Link>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
