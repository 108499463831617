import { FormControl } from '../../../../components/FormUtilities'
import NumeralField from '../../../../components/NumeralField'
import { formFactor } from '../../storage'

function SpecialFactor() {
    const { props, getError } = formFactor.useInput('special_factor')
    const helper = getError()

    return (
        <FormControl className="mb-2" required label="Factor especial">
            <NumeralField
                {...props}
                placeholder="Ingresar factor especial"
                autoFocus
                decimal={2}
                max={999}
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

export default function FormContact() {
    return (
        <>
            <SpecialFactor />
        </>
    )
}
