import { render, useRender } from 'redity'
import NumeralField from '../../../../../components/NumeralField'
import Typography from '../../../../../components/Typography'
import useGet from '../../../../../hooks/useGet'
import useStorage from '../../../../../hooks/useStorage'
import { TableList } from '../../../../../sections/ListForm'
import FormEvents from '../../../../../utilities/FormEvents'
import { Keys } from '../../../constants'
import { storagePage } from '../../../storage'
import Cost from '../../SectionCosts/cost'
import Aditional from '../aditional'
import {
    formCostByProduct,
    reqLDCostDistribution2,
    tableCostByProductControl
} from '../storageStep3'
import { runOperationFields } from './utilities'

export default function Table() {
    const { logistical_declaration, version } = storagePage
    const { data } = useGet<Cost.ResponseProductCostDistribution[]>(
        reqLDCostDistribution2.observer(
            `/v5.6/apis/logistical_declaration/${logistical_declaration.id}/version/${version}/get_cost_distributions`,
            true
        ),
        []
    )

    if (tableCostByProductControl.list.length === 0) {
        tableCostByProductControl.setList(
            data.result.map(d => ({
                id: d.product_id,
                product_name: d.product.name,
                product_code: d.product.code,
                original_cost: '0.00',
                cost: 0
            }))
        )
    }

    return (
        <>
            <label className="label">
                <span className="label-text text-gray-500 w-full">
                    Costo por producto *
                </span>
            </label>
            <TableList
                control={tableCostByProductControl}
                scrollHeight={350}
                className="mb-4"
            >
                {(_, control, e) => <Row key={e} control={control} />}
            </TableList>
            <Specifications />
        </>
    )
}

function Row({
    control
}: {
    control: FormEvents<Aditional.ModalTableCostProduct>
}) {
    const { props } = control.useInput('original_cost')

    const tooltip = props.helper
    const { product_name, product_code } = control.store()
    const { currency, exchange_to_local } = formCostByProduct.store()
    const { currencies } = useStorage()

    function getSymbol() {
        return currencies.find(c => c.id.toString() === currency.value).symbol
    }

    function handleChange(ev) {
        props.onChange(ev)
        render(Keys.TABLE_DISTRIBUTION_BY_PRODUCT_SPECIFICATIONS)
        runOperationFields()
    }

    return (
        <tr className={tooltip && 'bg-red-100'}>
            <td>
                <Typography type="paragraph" className="font-bold">
                    {product_name}
                </Typography>
                <Typography type="small">{product_code}</Typography>
            </td>
            <td>
                <NumeralField
                    prefix={currency ? getSymbol() : '?'}
                    {...props}
                    onChange={handleChange}
                    helper=""
                    decimal={2}
                />
            </td>
            <td>
                {storagePage.currentCurrency.symbol}{' '}
                {(Number(props.value) * Number(exchange_to_local)).toFixed(2)}
            </td>
        </tr>
    )
}

function Specifications() {
    useRender(Keys.TABLE_DISTRIBUTION_BY_PRODUCT_SPECIFICATIONS)
    const { currency } = formCostByProduct.store()
    const { currencies } = useStorage()
    const list = tableCostByProductControl.getDataList()
    const { exchange_to_local } = formCostByProduct.store()

    function getSymbol() {
        return currencies.find(c => c.id.toString() === currency.value).symbol
    }

    return (
        <div className="flex justify-end mt-4 mb-4 font-bold text-xs">
            <div className="bg-gray-200 py-2 pl-4 pr-24">
                {currency ? getSymbol() : '?'}{' '}
                {list
                    .reduce(function (a, b) {
                        return a + Number(b.original_cost)
                    }, 0)
                    .toFixed(2)}
            </div>
            <div className="bg-gray-200 py-2 pl-24 pr-4">
                {storagePage.currentCurrency.symbol}{' '}
                {list
                    .reduce(function (a, b) {
                        return (
                            a +
                            Number(b.original_cost) * Number(exchange_to_local)
                        )
                    }, 0)
                    .toFixed(2)}
            </div>
        </div>
    )
}
