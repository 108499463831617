import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import RoutingContext from '../../contexts/routing-context'
import useRule from '../../hooks/useRule'
import { mathPath } from '../../utilities/routing'
import { IconName } from '../Icon/types'

export interface BreadcrumbItem {
    label: string
    icon: IconName
    path: string | null
}

function iterateSubs({ items, epic, route, sub }) {
    const base = epic.routes.find(_route => _route.path === epic.path) || epic
    items.push({
        label: epic.label,
        icon: 'directory',
        path: base.cu && useRule(base.cu) ? base.path : null
    })

    items.push({
        label: route?.title || route.label,
        icon: 'directory',
        path: route.Component ? route.path : null
    })

    const pathSplits = sub.path.split('/')
    for (let i = 3; i < pathSplits.length - 1; i++) {
        const findedRoute = route.routes.find(
            _s => _s.path === pathSplits.slice(0, i + 1).join('/')
        )
        if (findedRoute) {
            items.push({
                label: findedRoute.label || findedRoute.title,
                icon: 'directory',
                path: findedRoute.path
            })
        }
    }

    if (sub.Component) {
        items.push({
            label: sub.title,
            icon: null,
            path: null
        })
    } else {
        items.push({
            label:
                sub.path !== route.path ? sub?.title || sub.label : sub.label,
            icon: null,
            path: null
        })
    }
}

function iterateRoutes({ route, pathname, items, epic }) {
    const base = epic.routes.find(_route => _route.path === epic.path) || epic
    if (route.routes) {
        for (const sub of route.routes) {
            if (mathPath(pathname, sub.path)) {
                iterateSubs({ items, epic, route, sub })
                return
            }
        }
    }
    if (mathPath(pathname, route.path)) {
        items.push({
            label: epic.label,
            icon: 'directory',
            path: base.cu && useRule(base.cu) ? base.path : null
        })
        items.push({
            label: route?.title || 'Página no encontrada',
            icon: null,
            path: null
        })
    }
}

export default function useBreadcrumb() {
    const { epicsModules } = useContext(RoutingContext)
    const { pathname } = useLocation()
    const items: BreadcrumbItem[] = []
    for (const epic of epicsModules) {
        if (epic.path === pathname) {
            items.push({
                label: epic.label,
                icon: 'directory',
                path: epic.path
            })
            break
        }
        if (epic.routes) {
            epic.routes.forEach(route => {
                iterateRoutes({ route, pathname, items, epic })
            })
        }
    }

    return items
}
