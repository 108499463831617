import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import { cts, ocList, storagePage } from './storage'

export default function useInitial() {
    const [pageState, setPageState] = useState(StateCase.LOADING)

    useEffect(() => {
        ocList.filter.setParams('purchase_state_id', 1)
        ocList.filter.changeParams('sort', 'code')
        ocList.filter.changeParams('order', 'desc')
        const company = getCompanySelected()

        ocList.filter.setOption('company_id', {
            label: company.name,
            value: company.id.toString(),
            image: company.country.image_url
        })
        storagePage.timezone = company.timezone

        ocList.load().then(result => {
            if (result) {
                setPageState(StateCase.SUCCESS)
            } else {
                setPageState(StateCase.ERROR)
            }
        })

        return () => {
            ocList.filter.restart()
            cts.step('step1')
        }
    }, [])

    return {
        pageState
    }
}
