import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import Control from './Controls'
import Data from './Data'
import Table from './Table'

export default function Providers() {
    const { state } = useInitial()
    return (
        <Initial pageState={state}>
            <Data />
            <Panel>
                <PanelBody>
                    <Control />
                    <Table />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
