import ControlTabs from '../../utilities/ControlTabs'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { FormWarehouse, StoragePage } from './types'

export const controlTabs = new ControlTabs()

export const storagePage: StoragePage = {
    warehouse: null,
    warehouseId: null,
    mode: 'creation',
    loadingCompanies: false
}

export const formWarehouse = new FormEvents<FormWarehouse>(Keys.FORM, {
    address: '',
    code: '',
    comment: '',
    companies: [],
    country: null,
    image_url: '',
    latLng: {
        lat: 38.2850563,
        lng: -100.3448311
    },
    name: ''
})
