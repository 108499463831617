import { render } from 'redity'
import moment from 'moment'
import { Exception } from '../../utilities/http/type'
import { Popup } from '../../utilities/popup'
import { formTraspase, productsControl, storagePage } from './storage'
import { Form, TraspasePOSTBody, TraspasePUTBody } from './types'

export default function useSubmit(traspase_id?: number) {
    const { submit, validation, observer } = formTraspase.useSubmit(
        traspase_id
            ? `/v5.6/apis/traspases/${traspase_id}`
            : '/v5.6/apis/traspases',
        {
            message: '¿Está seguro de guardar cambios del traspaso?',
            done: '/movements/traspases',
            method: traspase_id ? 'put' : 'post',
            onError: error => {
                handleError(error)
            }
        }
    )

    validation(v => {
        v.company.isEmpty('Se requiere seleccionar una compañía')
        v.warehouse.isEmpty('Se requiere seleccionar un almacén')
        v.stowage_origin.isEmpty('Se requiere seleccionar una bodega origen')
        v.stowage_destination.isEmpty(
            'Se requiere seleccionar una bodega destino'
        )
        const {
            current_type_stowage_id_origin,
            current_type_stowage_id_destination
        } = storagePage
        if (
            current_type_stowage_id_origin === 2 ||
            current_type_stowage_id_origin === 6 ||
            current_type_stowage_id_origin === 11
        ) {
            v.sub_stowage_origin.isEmpty(
                'Se requiere seleccionar una sub bodega origen'
            )
        }
        if (
            current_type_stowage_id_destination === 2 ||
            current_type_stowage_id_destination === 11
        ) {
            v.sub_stowage_destination.isEmpty(
                'Se requiere seleccionar una sub bodega destino'
            )
        }

        if (productsControl.list.length === 0) {
            formTraspase.store.helper(
                'traspase_products',
                'Se requiere como mínimo un producto'
            )
            render(formTraspase.keyRender, 'traspase_products')
            return false
        }

        return productsControl.test((_v, f) => {
            _v.amount_to_transfer
                .isMinor(1, 'El valor mínimo es 1')
                .isGreater(
                    f.quantity_available,
                    'El valor es mayor a la cantidad disponible'
                )
                .isEmpty('Se requiere ingresar la cantidad a solicitar')
        })
    })

    observer<TraspasePOSTBody | TraspasePUTBody>(f => getBody(f, traspase_id))
    return submit
}

function getBody(
    f: Form,
    traspase_id?: number
): TraspasePOSTBody | TraspasePUTBody {
    const isDelete = (logicalProductId: number, isRegistered: boolean) => {
        if (!isRegistered) {
            return {}
        }

        return {
            deleted: !storagePage.traspase.classic_traspase_products.find(
                ctp =>
                    ctp.traspase_product.output_operation.logical_product_id ===
                    logicalProductId
            )
        }
    }

    if (traspase_id) {
        return {
            description: f.description,
            traspase_products: [
                ...productsControl.getDataList().map(lp => ({
                    id: lp.id,
                    logical_product_id: lp.logical_product_id,
                    amount_to_transfer: parseInt(lp.amount_to_transfer),
                    ...isDelete(lp.logical_product_id, !!lp.id)
                })),
                ...storagePage.current_traspase_products
                    .filter(
                        ctp =>
                            !productsControl
                                .getDataList()
                                .map(lp => lp.logical_product_id)
                                .includes(
                                    ctp.output_operation.logical_product_id
                                )
                    )
                    .map(ctp => ({
                        id: ctp.id,
                        amount_to_transfer: 0,
                        logical_product_id:
                            ctp.output_operation.logical_product_id,
                        deleted: true
                    }))
            ]
        }
    }

    const products = productsControl.getDataList()

    const date = moment(f.date_request).tz(storagePage.timezone).parseZone()
    const newDate = moment
        .tz(date.format('YYYY-MM-DD'), storagePage.timezone)
        .add(1, 'minute')

    return {
        ...(traspase_id
            ? {}
            : {
                  warehouse_id: parseInt(f.warehouse.value),
                  origin_minimal_stowage_id: f.sub_stowage_origin
                      ? parseInt(f.sub_stowage_origin.value)
                      : storagePage.minimal_stowage_origin_id,
                  destination_minimal_stowage_id: f.sub_stowage_destination
                      ? parseInt(f.sub_stowage_destination.value)
                      : storagePage.minimal_stowage_destiny_id
              }),
        description: f.description || null,
        request_date: newDate.toISOString(true),
        traspase_products: products.map(product => ({
            logical_product_id: product.logical_product_id,
            amount_to_transfer: parseInt(product.amount_to_transfer)
        }))
    }
}

function handleError(error: Exception) {
    if (error.errors?.logical_product_id) {
        Popup.error(
            'Inventario negativo',
            error.errors.logical_product_id.description
        )
    }
}
