import Fields from './Fields'
import { FormControls } from '../../../../components/FormUtilities'

export default function Information() {
    return (
        <FormControls>
            <Fields />
        </FormControls>
    )
}
