import moment from 'moment'
import ModalRequisition from '../../../modals/ModalRequisition'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import buildName from '../../../utilities/buildName'
import { controlList, controlTabs, storagePage } from '../storage'

export default function TableRequisitions() {
    const tab = controlTabs.value

    // const color_type_document_approval = {
    //     1: 'text-yellow-500',
    //     2: 'text-red-500',
    //     3: 'text-green-500',
    //     4: 'text-yellow-500'
    // }

    return (
        <List control={controlList}>
            {(requisition, e) => (
                <tr key={requisition.id}>
                    <td>{e}</td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <b
                            {...{
                                className: 'underline cursor-pointer',
                                onClick: () =>
                                    view(
                                        `/v5.6/crm/requisitions/${requisition.id}`,
                                        () => ({
                                            title: `DETALLE DE REQUISICIÓN`,
                                            Component: ModalRequisition
                                        }),
                                        'lg'
                                    )
                            }}
                        >
                            {requisition.code}
                        </b>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <span
                            className={
                                !requisition.minimal_stowage.stowage.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {/* <p>{requisition.minimal_stowage.stowage.name}</p> */}
                            {requisition.minimal_stowage.order_sub_stowage ? (
                                <p
                                    className={
                                        !requisition.minimal_stowage
                                            .order_sub_stowage.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {requisition.minimal_stowage
                                        .order_sub_stowage.order.number +
                                        ' - ' +
                                        requisition.minimal_stowage
                                            .order_sub_stowage.order.client
                                            ?.name}
                                </p>
                            ) : (
                                <>
                                    <p>
                                        {
                                            requisition.minimal_stowage.stowage
                                                .name
                                        }
                                    </p>
                                    {requisition.minimal_stowage
                                        .client_sub_stowage && (
                                        <p
                                            className={
                                                !requisition.minimal_stowage
                                                    .client_sub_stowage
                                                    .is_enabled
                                                    ? 'line-through'
                                                    : ''
                                            }
                                        >
                                            {
                                                requisition.minimal_stowage
                                                    .client_sub_stowage.client
                                                    .name
                                            }
                                        </p>
                                    )}
                                    {requisition.minimal_stowage
                                        .gp_sub_stowage && (
                                        <p
                                            className={
                                                !requisition.minimal_stowage
                                                    .gp_sub_stowage.is_enabled
                                                    ? 'line-through'
                                                    : ''
                                            }
                                        >
                                            {
                                                requisition.minimal_stowage
                                                    .gp_sub_stowage.purchase
                                                    .code
                                            }
                                        </p>
                                    )}
                                </>
                            )}
                        </span>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <div className="flex items-center">
                            <img
                                src={
                                    requisition.provider_company.provider
                                        .country.image_url
                                }
                                className="mr-2 w-8"
                            />
                            <p
                                className={`${
                                    !requisition.provider_company.provider
                                        .is_enabled && 'line-through'
                                }`}
                            >
                                {
                                    requisition.provider_company.provider
                                        .commercial_name
                                }
                            </p>
                        </div>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        <p
                            className={
                                !requisition.document.requesting_user.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {buildName(
                                requisition.document.requesting_user.name,
                                requisition.document.requesting_user.lastname
                            )}
                        </p>
                    </td>
                    {/* <td>
                        {requisition.document.document_approver_user ? (
                            <li
                                className={
                                    color_type_document_approval[
                                        requisition.document
                                            .type_document_approval_id
                                    ]
                                }
                            >
                                <span className="text-primary">
                                    {buildName(
                                        requisition.document
                                            .document_approver_user.name,
                                        requisition.document
                                            .document_approver_user.lastname
                                    )}
                                </span>
                            </li>
                        ) : (
                            '-'
                        )}
                    </td> */}
                    <td style={{ whiteSpace: 'normal' }}>
                        {tab !== 'step1' &&
                            requisition.document.decision_date !== null &&
                            moment(requisition.document.decision_date)
                                .tz(storagePage.timezone)
                                .format('DD/MM/YYYY')}
                        {tab === 'step1' &&
                            requisition.document.request_date !== null &&
                            moment(requisition.document.request_date)
                                .tz(storagePage.timezone)
                                .format('DD/MM/YYYY')}
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {requisition.client ? requisition.client.name : '-'}
                    </td>
                    {/* <td>
                        {tab !== 'step1' && false && (
                            <Icon pointer name="download" className="mr-2" />
                        )}
                        {tab === 'step1' && false && <NextActions />}
                    </td> */}
                </tr>
            )}
        </List>
    )
}

// function NextActions() {
//     const { push } = useHistory()
//     return (
//         <>
//             <Icon pointer name="check-cirle" className="mr-2" />
//             <Icon pointer name="times" className="mr-2" />
//             <Icon pointer name="download" />
//             <Popover
//                 className="ml-2"
//                 position="bottom-right-left"
//                 element=".panel--form-datatable"
//                 component={<Icon name="more-h" />}
//             >
//                 <Option
//                     label="Editar"
//                     value="0"
//                     icon="bs-pencil"
//                     onClick={() => push(`/requisition/1/edition`)}
//                 />
//                 <Delete
//                     path={`/v5.6/apis/requisition/1`}
//                     loadingMessage="Cancelando traspaso"
//                     label="Cancelar"
//                     control={controlList}
//                 >
//                     Si está seguro de cancelar la solicitud de traspaso{' '}
//                     <b>TR060005</b>?, indique el motivo de su cancelación.
//                 </Delete>
//                 <Option label="Exportar" value="1" icon="download" />
//             </Popover>
//         </>
//     )
// }
