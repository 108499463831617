import FieldUpload from './FieldUpload'
import Modal, {
    ModalBody,
    ModalFooter
} from '../../../../../../components/Modal'
import Button from '../../../../../../components/Button'
import { ModalGeneralCostProps } from './types'
import { useState } from 'react'
import { FormControls } from '../../../../../../components/FormUtilities'
import FieldConcept from './FieldConcept'
import FieldCurrency from './FieldCurrency'
import FieldExchangeRate from './FieldExchangeRate'
import FieldCost from './FieldCost'
import FieldLocalCost from './FieldLocalCost'
import useFieldStates from './hooks/useFieldStates'
import useSubmit from './hooks/useSubmit'
import FieldAgent from './FieldAgent'
import FieldDocumentNumber from './FieldDocumentNumber'
import FieldApplyRCs from './FieldApplyRCs'

export default function ModalGeneralCost({
    element,
    onDone,
    edition,
    rcs
}: ModalGeneralCostProps) {
    const [isOpen, setOpen] = useState(false)
    const values = useFieldStates(edition)
    const { submit, helper, setHelper } = useSubmit(
        { ...values, rcs },
        {
            onDone,
            onOpenClose: setOpen
        },
        edition
    )

    function openHandler() {
        setHelper({})
        if (!edition) {
            values.setAgent(null)
            values.setDocumentNumber('')
            values.setConcept(null)
            values.setCurrency(null)
            values.setCost('0')
            values.setExchangeRate('1')
            values.setFiles([])
        }
        setOpen(true)
    }

    return (
        <>
            {element(openHandler)}
            <Modal
                onSubmit={submit}
                size="lg"
                open={isOpen}
                title="Agregar Costo General"
                onClose={() => setOpen(false)}
            >
                <ModalBody>
                    <div className="flex self-stretch">
                        <div className="w-4/6 pr-4">
                            <FormControls className="mb-4">
                                <FieldConcept
                                    isDisabled={!!edition}
                                    option={values.concept}
                                    onChange={values.setConcept}
                                    helper={helper.concept || ''}
                                />
                                <FieldCurrency
                                    currentCurrency={values.currency}
                                    onChange={values.setCurrency}
                                    helper={helper.currency || ''}
                                />
                            </FormControls>
                            <FormControls className="mb-4">
                                <FieldCost
                                    value={values.cost}
                                    onChange={values.setCost}
                                    symbol={
                                        values.currency
                                            ? values.currency.symbol
                                            : '?'
                                    }
                                    helper={helper.cost || ''}
                                />
                                <FieldExchangeRate
                                    value={values.exchangeRate}
                                    onChange={values.setExchangeRate}
                                    symbol={
                                        values.currency
                                            ? values.currency.symbol
                                            : '?'
                                    }
                                    helper={helper.exchangeRate || ''}
                                />
                                <FieldLocalCost
                                    value={values.localCost.toString()}
                                />
                            </FormControls>
                            <FormControls className="mb-4">
                                <FieldAgent />
                                <FieldDocumentNumber />
                            </FormControls>
                            <FieldUpload
                                files={values.files}
                                onChange={values.setFiles}
                            />
                        </div>
                        <div className="w-1/3 border-l border-dashed -mt-4 -mb-4 pt-4 pl-4">
                            <FieldApplyRCs
                                rcs={rcs}
                                productIds={values.productIds}
                                setProductIds={values.setProductIds}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="ghost" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button className="ml-2" type="submit">
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
