import { render } from 'redity'
import Dropdown from '../../../components/Dropdown'
import { Empty } from '../../../components/FormUtilities'
import Option from '../../../components/Option'
import { Option as OptionType } from '../../../config/types/component'
import useStorage from '../../../hooks/useStorage'
import { setCompanySelected } from '../../../utilities/controlStorage'
import { formOC } from '../../PurchaseOrder/storage'
import { Keys } from '../constants'
import { ocList, storagePage } from '../storage'
import FiltersContainer from './FiltersContainer'

export default function Filters() {
    return (
        <>
            <CompanyFilter />
            <FiltersContainer />
        </>
    )
}

function CompanyFilter() {
    const { companiesFromSession } = useStorage()
    const { props } = ocList.useFilter('company_id')

    const companies = companiesFromSession.filter(company => company.memory)

    function handleChange(option: OptionType) {
        props.onChange(option)
        const company = companiesFromSession.find(
            c => c.id.toString() === option.value
        )
        const optionCompany: OptionType = {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        }
        setCompanySelected(company)
        storagePage.timezone = company.timezone
        formOC.setValue('billing_company', optionCompany)
        formOC.setValue('destination_company', optionCompany)
        render(Keys.PANEL_HEADER)
    }

    return (
        <Dropdown
            {...props}
            label="Compañía"
            showLabel={false}
            onChange={handleChange}
            clear={false}
        >
            {companies.map(company => (
                <Option
                    key={company.id}
                    value={company.id.toString()}
                    label={company.name}
                    image={company.country.image_url}
                    strikeOut={!company.is_enabled}
                />
            ))}
            <Empty data={companies} />
        </Dropdown>
    )
}
