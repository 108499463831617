import { Option } from '../../../../config/types/component'
import Reception from '../../../../models/Reception'
import { Base } from '../../../../utilities/http/type'
import { formInformation } from '../../storage'
import { loadProducts } from '../../Utils/loadProducts'

export default function useReception() {
    const { props } = formInformation.useSelect('purchaseReceipt')

    function handleChange(option: Option, data: Base<Reception[]>) {
        formInformation.setValue(
            'purchaseCost',
            data.result
                .find(item => item.id === parseInt(option.value))
                .purchase_local_cost.toFixed(2)
                .toString()
        )
        formInformation.setValue('purchaseReceipt', option)
        props.onChange(option)
        loadProducts()
    }

    return {
        props,
        handleChange
    }
}
