import { get } from '../../../utilities/http'
import storage, { formPresentation, tablePresentations } from '../storage'
import { GroupPresentationInfo } from '../types'

export default async function loadGroupPresentation(groupPresentationId) {
    const { data, error } = await get<GroupPresentationInfo>(
        `/v5.6/apis/group_presentations/${groupPresentationId}`
    )
    if (error) return null
    storage.currentGroupPresentation = data.result
    const { name, presentation_products } = data.result
    storage.products.sets(
        presentation_products.map(pp => ({
            id: pp.product_id,
            code: pp.product.code,
            description: pp.product.description,
            amount: pp.amount.toString()
        }))
    )
    formPresentation.init({
        name,
        presentationProducts: presentation_products.map(pp => ({
            label: pp.product.name,
            value: pp.product_id.toString()
        }))
    })
    tablePresentations.setList(
        presentation_products.map(pp => ({
            id: pp.product_id,
            description: pp.product.description,
            code: pp.product.code,
            amount: pp.amount.toString()
        }))
    )

    return data.result
}
