import buildCompanyName from '../../../utilities/buildCompanyName'
import { get } from '../../../utilities/http'
import { formCatalog } from '../storage'
import { GetProductProviderCompany } from '../types'

export default async function initEdition(productProviderCompanyId: number) {
    const { error, data } = await get<GetProductProviderCompany>(
        `/v5.6/apis/product_provider_companies/${productProviderCompanyId}`
    )

    if (error) return false

    formCatalog.store.on('company', null)
    formCatalog.setProps(
        'company',
        {
            disabled: true
        },
        false
    )
    formCatalog.setProps(
        'product',
        {
            disabled: true
        },
        false
    )
    formCatalog.setProps(
        'currency',
        {
            disabled: false
        },
        false
    )

    const {
        product_company,
        company_currency,
        purchase_price,
        provider_code,
        provider_description
    } = data.result

    formCatalog.init({
        company: {
            label: buildCompanyName(product_company.company.name),
            value: product_company.company.id.toString(),
            image: product_company.company.country.image_url
        },
        product: {
            label: product_company.product_country.product.name,
            value: product_company.product_country.product.id.toString()
        },
        currency: {
            label: `(${company_currency.currency.symbol}) ${company_currency.currency.name}`,
            value: company_currency.currency_id.toString()
        },
        purchase_price: purchase_price.toString(),
        provider_code,
        provider_description
    })

    formCatalog.setParams('currency', {
        company_id: product_company.company_id
    })

    return true
}
