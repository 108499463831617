import * as React from 'react'

function MessageOpen(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg%22%3E"
            {...props}
        >
            <path d="M7.73 0h-.52L.321 3.99v7.94l.51.5h13.285l.511-.5V3.99L7.73 0zm-.255 1.01l5.753 3.32-1.4 1.59H3.122L1.783 4.33l5.692-3.32zm6.131 10.42H1.344V5.35l1.134 1.38.399.19h9.196l.399-.19 1.134-1.38v6.08z" />
        </svg>
    )
}

export default MessageOpen
