import { ListFormControl } from '../../../../sections/ListForm'
import ControlRequest from '../../../../utilities/ControlRequest'
import ControlTabs from '../../../../utilities/ControlTabs'
import FormEvents from '../../../../utilities/FormEvents'
import { Keys } from '../../constants'
import Cost from './cost'

export const tableRCsControl = new ListFormControl<Cost.TableRC>(
    Keys.TABLE_RCS,
    [
        '#',
        'RC',
        'OC',
        'Costo proveedor',
        'Tipo de cambio',
        'Costo',
        'Tipo de cambio',
        'Costo',
        'Acciones'
    ]
)

export const tableInternationalCostsControl = new ListFormControl<Cost.TableIC>(
    Keys.TABLE_INTERNATIONAL_COSTS,
    [
        '#',
        'Concepto',
        'Agente',
        'Tipo',
        'Costeo a RCs',
        'Monto',
        'Tipo de cambio',
        'Monto',
        'Acciones'
    ]
)

export const tableNationalizationControl = new ListFormControl<Cost.TableNC>(
    Keys.TABLE_NATIONALIZATION,
    [
        '#',
        'Concepto',
        'Agente',
        'Tipo',
        'Costeo a RCs',
        'Monto',
        'Tipo de cambio',
        'Monto',
        'Acciones'
    ]
)

export const reqRC = new ControlRequest()
export const reqModalRC = new ControlRequest()
export const reqLogisticalCost1 = new ControlRequest()
export const reqLogisticalCost2 = new ControlRequest()
export const reqLDCostDistribution = new ControlRequest()
export const ctsModalReception = new ControlTabs()
export const ctsModalPurchase = new ControlTabs() // A la espera del modal de OC

export const formRC = new FormEvents<Cost.FormRC>(Keys.FORM_RC, {
    purchase_dolar_cost: '0',
    exchange_rc_to_dolar: '1',
    purchase_dolar_cost_total: '0',
    purchase_local_cost: '0',
    exchange_dolar_to_local: '1',
    purchase_local_cost_total: '0',
    rc: null
})

export const formLogisticalCost = new FormEvents<Cost.FormLC>(
    Keys.FORM_LOGISTICAL_COST,
    {
        original_cost: '',
        exchange_to_local: '',
        total_cost: '',
        document_number: '',
        agent: null,
        files: [],
        rcs: [],
        productIds: []
    }
)

export const formDutyISCIVA = new FormEvents<Cost.FormLCDuty>(
    Keys.FORM_TARIFF_ISC_IVA,
    {
        logistical_concept: null,
        currency: null,
        original_cost: '',
        setting_local_cost: '',
        final_local_cost: '',
        exchange_to_local: '',
        total_cost: '',
        document_number: '',
        agent: null,
        files: [],
        rcs: [],
        productIds: []
    }
)

export const tableDutyControl = new ListFormControl<Cost.ModalTableDuty>(
    Keys.TABLE_TARIFFS,
    [
        'Producto',
        'FOB($)',
        'Flete($)',
        'Seguro($)',
        'CIF($)',
        '%Aranceles',
        'Aranceles($)'
    ]
)

export const tableISCControl = new ListFormControl<Cost.ModalTableISC>(
    Keys.TABLE_ISC,
    ['Producto', 'Costo', 'Costo']
)

export const tableIVAControl = new ListFormControl<Cost.ModalTableIva>(
    Keys.TABLE_IVA,
    [
        'Producto',
        'CIF',
        'Aranceles',
        'Tasa C/IVA',
        'ISC',
        'Total',
        '%IVA',
        'IVA'
    ]
)
