import Rules from '../../../components/Rules'
import LogisticalConcept from '../../../models/LogisticalConcept'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { controlList } from '../storage'

interface PropsLogisticalConceptADe {
    logisticalConcept: LogisticalConcept
}

export default function ActivDeactivate({
    logisticalConcept
}: PropsLogisticalConceptADe) {
    return (
        <Rules cu={logisticalConcept.is_enabled ? 'CU240' : 'CU241'}>
            <ActivateDeactivate
                isEnabled={logisticalConcept.is_enabled}
                control={controlList}
                body={{
                    logistical_concept_id: logisticalConcept.id
                }}
                className="ml-2"
            >
                <Active path="/v5.6/apis/logistical_concept/activate">
                    Al reactivar el concepto de pago, volverá a estar disponible
                    para operaciones de costeo ¿Estás seguro de reactivar el
                    concepto de pago{' '}
                    <b className="text-primary">{logisticalConcept.name}</b>?
                </Active>
                <Deactive path="/v5.6/apis/logistical_concept/deactivate">
                    Al desactivar el concepto de pago, no estará disponible para
                    operaciones de costeo. ¿Está seguro de desactivar el
                    concepto de pago{' '}
                    <b className="text-primary">{logisticalConcept.name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
