function Building(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 4h10v14H0V0h10v4zM2 16h2v-2H2v2zm2-4H2v-2h2v2zM2 8h2V6H2v2zm2-4H2V2h2v2zm2 12h2v-2H6v2zm2-4H6v-2h2v2zM6 8h2V6H6v2zm2-4H6V2h2v2zm10 12V6h-8v2h2v2h-2v2h2v2h-2v2h8zm-2-8h-2v2h2V8zm-2 4h2v2h-2v-2z"
            />
        </svg>
    )
}

export default Building
