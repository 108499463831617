import classNames from 'classnames'
import { ReactNode } from 'react'

export default function Helper({ children }: { children: ReactNode }) {
    return (
        <label
            className={classNames('label', {
                hidden: !children
            })}
        >
            <span className="label-text-alt text-red-600">{children}</span>
        </label>
    )
}
