import Checkbox from '../../../components/Checkbox'
import { FormControl } from '../../../components/FormUtilities'
import FormEvents from '../../../utilities/FormEvents'
import { DataPermision } from '../storage'

interface PropsCheck {
    control: FormEvents<DataPermision>
    disabled: boolean
}

export default function Check({ control, disabled }: PropsCheck) {
    const { props } = control.useCheck('active')
    return (
        <FormControl label={control.store().name} mode="inline" col="span-2">
            <Checkbox {...props} disabled={disabled} />
        </FormControl>
    )
}
