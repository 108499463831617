import Button from '../../../../components/Button'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import TableContacts from './TableContacts'
import TooltipContact from './TooltipContact'
import useContact from './useContact'

export default function Contact() {
    const open = useContact()
    return (
        <Panel mode="form">
            <PanelHeader
                title="Personal de contacto"
                helper={<TooltipContact />}
            >
                <Button
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => open()}
                >
                    + Agregar
                </Button>
            </PanelHeader>
            <PanelBody>
                <TableContacts />
            </PanelBody>
        </Panel>
    )
}
