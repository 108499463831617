import buildCompanyName from '../../../utilities/buildCompanyName'
import { GetRequisition } from '../type'
import GeneralInformation from './GeneralInformation'
import RequisitionProducts from './RequisitionProducts'

export default function ModalInfo({ data }: { data: GetRequisition }) {
    return (
        <>
            <div className="body__head mb-4">
                <div className="title">
                    <h1>REQUISICIÓN {data.code}</h1>
                </div>
                <div>
                    <div className="company_badge">
                        <img src={data.document.company.country.image_url} />
                        <b>{buildCompanyName(data.document.company.name)}</b>
                    </div>
                </div>
            </div>
            <GeneralInformation data={data} />
            <RequisitionProducts data={data} />
        </>
    )
}
