export default function Grain(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.667 2c0 .917-.75 1.667-1.667 1.667-.916 0-1.666-.75-1.666-1.667S11.084.333 12 .333c.917 0 1.667.75 1.667 1.667zM.333 5.333c0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666C3.667 6.25 2.917 7 2 7S.333 6.25.333 5.333zm3.334 3.334C3.667 7.75 4.417 7 5.334 7 6.25 7 7 7.75 7 8.667c0 .916-.75 1.666-1.667 1.666-.916 0-1.666-.75-1.666-1.666zM2 10.333c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667 1.667-.75 1.667-1.667-.75-1.667-1.667-1.667zm6.667 0C7.75 10.333 7 11.083 7 12s.75 1.667 1.667 1.667 1.667-.75 1.667-1.667-.75-1.667-1.667-1.667zm1.667-1.666C10.334 7.75 11.084 7 12 7c.917 0 1.667.75 1.667 1.667 0 .916-.75 1.666-1.667 1.666-.916 0-1.666-.75-1.666-1.666zm-1.667-5C7.75 3.667 7 4.417 7 5.333 7 6.25 7.75 7 8.667 7s1.667-.75 1.667-1.667c0-.916-.75-1.666-1.667-1.666zM3.667 2c0-.917.75-1.667 1.667-1.667C6.25.333 7 1.083 7 2s-.75 1.667-1.667 1.667c-.916 0-1.666-.75-1.666-1.667z"
            />
        </svg>
    )
}
