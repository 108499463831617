export const CURRENCY__DOLAR = 1
export const CURRENCY__COLON = 3

export const LOGISTICAL_CONCEPT__IVA = 1
export const LOGISTICAL_CONCEPT__FREIGHT = 2
export const LOGISTICAL_CONCEPT__DUTY = 3
export const LOGISTICAL_CONCEPT__SAFE = 4
export const LOGISTICAL_CONCEPT__PENALIZATION = 5
export const LOGISTICAL_CONCEPT__RATE_S_IVA = 6
export const LOGISTICAL_CONCEPT__RATE_C_IVA = 7
export const LOGISTICAL_CONCEPT__ISC = 8
export const LOGISTICAL_CONCEPT__EXTRA_CIF = 9
export const LOGISTICAL_CONCEPT__SETTING_DUTY = 10
export const LOGISTICAL_CONCEPT__SETTING_IVA = 11

export const TYPE_LOGISTICAL_COST_SOURCE__INTERNATIONAL = 1
export const TYPE_LOGISTICAL_COST_SOURCE__NATIONALIZATION = 2
export const TYPE_LOGISTICAL_COST_SOURCE__ADITIONAL_INTERNATIONAL = 3
export const TYPE_LOGISTICAL_COST_SOURCE__ADITIONAL_NATIONAL = 4
export const TYPE_LOGISTICAL_COST_SOURCE__ADITIONAL_SETTING = 5
export const TYPE_LOGISTICAL_COST_SOURCE__ADITIONAL_OTHER = 6

export const TYPE_LOGISTICAL_DECLARATION__INTERNATIONAL = 1
export const TYPE_LOGISTICAL_DECLARATION__NATIONAL = 2

export const TYPE_LOGISTICAL_COST__GENERAL = 1
export const TYPE_LOGISTICAL_COST__BY_PRODUCT = 2

export const TYPE_DOCUMENT__TRASPASE = 11
export const TYPE_DOCUMENT__COSTING = 9
export const TYPE_DOCUMENT__COSTING_ESTIMATE = 10
export const TYPE_DOCUMENT__INPUT_PART = 13
export const TYPE_DOCUMENT__CONSUMER_VOUCHER = 12
export const TYPE_DOCUMENT__RECEPTION = 14
export const TYPE_DOCUMENT__CREDIT_NOTE = 16
export const TYPE_DOCUMENT__INVOICE = 15

export const TYPE_RECEPTION__ESTANDAR = 1
export const TYPE_RECEPTION__ANTICIPATED = 2

export const TYPE_ORDER_OPERATION__INPUT_TRASPASE = 4
export const TYPE_ORDER_OPERATION__OUTPUT_TRASPASE = 6
export const TYPE_ORDER_OPERATION__INPUT_PART = 2
export const TYPE_ORDER_OPERATION__CONSUME_VOUCHER = 7

export const PURCHASE_STATE__CONFIRMATED = 3
export const PURCHASE_STATE__CLOSED = 4

export const TYPE_DOCUMENT_APPROBAL__CONFIRMATED = 3

export const TYPE_INVOICE__PROFORMA = 1
export const TYPE_INVOICE__INVOICE = 2
export const TYPE_INVOICE__CANCELED = 3

export const TYPE_PURCHASE__NATIONAL = 1
export const TYPE_PURCHASE__INTERNATIONAL = 2
