import classNames from 'classnames'
import Icon from '../Icon'
import { PropsChecking } from './types'

export default function Checking({ checked, className = '' }: PropsChecking) {
    return (
        <>
            {checked && (
                <Icon
                    name="done"
                    size={14}
                    className={classNames('text-success', className)}
                />
            )}
            {!checked && (
                <Icon
                    name="point"
                    size={3}
                    className={classNames('text-gray-400', className)}
                />
            )}
        </>
    )
}
