import classnames from 'classnames'
import {
    PropsCaption,
    PropsDataTable,
    PropsScrollTable,
    PropsTHead
} from './types'
import { ReactNode } from 'react'
import './datatable.css'
import { Context } from './contexts'
import { TSort } from './TSort'

export { TSort }

export default function DataTable({
    children,
    className = '',
    scrollHeight = 0
}: PropsDataTable) {
    return (
        <div
            {...(scrollHeight && { style: { maxHeight: `${scrollHeight}px` } })}
            className={classnames(
                `panel--form-datatable datatable w-full`,
                {
                    'fix-head': scrollHeight,
                    'overflow-x-auto ': scrollHeight
                },
                className
            )}
        >
            <table className="min-w-full overflow-y-visible overflow-x-hidden rounded table-auto text-left w-full">
                {children}
            </table>
        </div>
    )
}

export function THead({
    children,
    value = '',
    order = 'asc',
    onSort = () => null
}: PropsTHead) {
    return (
        <thead className="thead">
            <Context.Provider value={{ value, onSort, order }}>
                {children}
            </Context.Provider>
        </thead>
    )
}

export function TBody({ children }: { children: ReactNode }) {
    return (
        <tbody className="tbody text-gray-600 text-sm font-univers-light">
            {children}
        </tbody>
    )
}

export function ScrollTable({
    children,
    scrollHeight,
    className = ''
}: PropsScrollTable) {
    return (
        <div
            className={classnames('scroll-table', className)}
            style={{ height: `${scrollHeight}px` }}
        >
            {children}
        </div>
    )
}

export function Caption({
    children,
    colSpan
}: PropsCaption & { children: ReactNode }) {
    return (
        <tr>
            <td colSpan={colSpan}>
                <div className="text-center">{children}</div>
            </td>
        </tr>
    )
}
