import { ReactNode } from 'react'
import { PropsDialog } from './types'
import { createPortal } from 'react-dom'
import Button from '../Button'
import './dialog.css'
import Icon from '../Icon'
import classNames from 'classnames'

export default function Dialog({
    title,
    children,
    open = false,
    onResult,
    cancel = 'Cancelar',
    accept = 'Acceptar',
    color = 'primary',
    icon = 'trash',
    className = ''
}: PropsDialog & { children: ReactNode }) {
    return createPortal(
        <div
            className={classNames('modal modal--sm dialog', {
                'dialog-open': open
            })}
        >
            <div className="modal-box max-w-xl relative rounded-md">
                <Icon
                    name="close"
                    pointer
                    size={12}
                    className="absolute top-7 right-7 z-1 text-gray-500"
                    onClick={() => onResult(false)}
                />
                <div className="pt-4 px-4">
                    <div className="text-center mb-2">
                        <Icon
                            name={icon}
                            className={classNames(`text-${color}`, className)}
                            size={60}
                        />
                    </div>
                    <div className="mb-4">
                        <h2 className="text-center text-xl font-univers-bold-cn mb-2">
                            {title}
                        </h2>
                        <div className="text-center text-sm font-univers-light text-gray-500">
                            {children}
                        </div>
                    </div>
                </div>
                <div className="text-right md:block border-t -mx-6 px-6 pt-4 -mb-2">
                    {cancel && (
                        <Button
                            className="mr-2 normal-case"
                            color="ghost"
                            onClick={() => onResult(false)}
                        >
                            {cancel}
                        </Button>
                    )}
                    {accept && (
                        <Button
                            className="normal-case"
                            color={color}
                            onClick={() => onResult(true)}
                        >
                            {accept}
                        </Button>
                    )}
                </div>
            </div>
        </div>,
        document.getElementById('modal-contain')
    )
}
