import { ListFormControl } from '../../sections/ListForm'
import ControlRequest from '../../utilities/ControlRequest'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import {
    StoragePage,
    Form,
    ProductEstimateResponse,
    GETEstimateCostProducts
} from './types'

export const storagePage: StoragePage = {
    costing_estimate: null,
    cost_estimate_id: null,
    mode: 'creation'
}

export const formInformation = new FormEvents<Form>(Keys.FORM, {
    company: null,
    purchaseReceipt: null,
    purchaseCost: '',
    estimatedAmount: '',
    comment: ''
})

export const responseSimulate: GETEstimateCostProducts = {
    product_estimate_response: [],
    total_purchase_cost: 0,
    total_estimate_cost: 0
}

export const reqReception = new ControlRequest()

export const productsControl = new ListFormControl<ProductEstimateResponse>(
    Keys.TABLE_PRODUCTS,
    [
        '#',
        'Producto',
        'Cantidad',
        'Valor unitario de compra',
        'Valor unitario estimado',
        'Costo de compra',
        'Costo estimado'
    ]
)
