import { useParams } from 'react-router'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import useCancel from '../../../hooks/useCancel'
import { formLicense } from '../storage'
import { Body } from '../types'
import { getBodyAdd, getBodyEdit, getBodyRenew } from '../utilities/getBodies'

export default function Header() {
    type Params = { license_id: string; license_id_for_renovate?: string }
    const { license_id, license_id_for_renovate } = useParams<Params>()
    const { onClick } = useCancel(formLicense, '/configuration/licenses')
    const pathName = license_id_for_renovate ? '/renew' : ''
    const { submit, validation, observer } = formLicense.useSubmit(
        license_id || license_id_for_renovate
            ? `/v5.6/apis/licenses/${
                  license_id || license_id_for_renovate
              }${pathName}`
            : '/v5.6/apis/licenses',
        {
            message: '¿Está seguro de guardar cambios de licencia?',
            done: '/configuration/licenses',
            method: license_id || license_id_for_renovate ? 'put' : 'post'
        }
    )

    validation((v, f) => {
        v.name
            .minLength(2, 'El número de caracteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el nombre')
        v.company.isEmpty('Se requiere seleccionar una compañía')
        v.code
            .minLength(2, 'El número de caracteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el código')
        v.type_licenses.isEmpty('Se requiere seleccionar tipos de licencias')
        v.issue_date.isEmpty('Se requiere ingresar la fecha de emisión')
        if (f.due_date_is_enabled) {
            v.due_date.isEmpty('Se requiere ingresar la fecha de vencimiento')
        }
        v.files.isEmpty('Se requiere de un archivo como mínimo')
        v.description
            .minLength(2, 'El número de caracteres válidos es 2 - 3000')
            .isEmpty('Se requiere ingresar una descripción')
    })

    observer<Body>(f => {
        if (license_id_for_renovate) return getBodyRenew(f)
        return license_id ? getBodyEdit(f) : getBodyAdd(f)
    })

    return (
        <PageHeader subtitle="(*) Complete todos los campos para la licencia">
            <Button color="ghost" onClick={onClick}>
                Cancelar
            </Button>
            <Button className="ml-2" onClick={() => submit()}>
                Guardar
            </Button>
        </PageHeader>
    )
}
