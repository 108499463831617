const Money = props => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 17 12"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.666.333H2.333C1.408.333.674 1.075.674 2L.666 12a1.66 1.66 0 0 0 1.667 1.667h13.333c.925 0 1.667-.742 1.667-1.667V2A1.66 1.66 0 0 0 15.666.333Zm-7.5 10.834h1.667v-.834h.833a.836.836 0 0 0 .833-.833V7a.836.836 0 0 0-.833-.833h-2.5v-.834h3.333V3.667H9.833v-.834H8.166v.834h-.833a.836.836 0 0 0-.834.833V7c0 .458.375.833.834.833h2.5v.834H6.499v1.666h1.667v.834ZM2.333 12h13.333V2H2.333v10Z"
            fill={props.color}
        />
    </svg>
)

export default Money
