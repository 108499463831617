import { formStowage } from '../storage'
import { GetStowageView } from '../../../types/stowages'
import Country from '../../../models/Country'
import buildNumberFormat from '../../../utilities/buildNumberFormat'

export function initialEdition(data: GetStowageView, countries: Country[]) {
    const country = countries.find(
        _country => _country.id === data.company.country_id
    )

    formStowage.init({
        company: {
            label: data.company.name,
            image: country.image_url,
            value: data.company_id.toString()
        },
        currency_symbol: data.currency.symbol,
        name: data.name,
        type_stowage: {
            label: data.type_stowage.name,
            value: data.type_stowage.id.toString()
        },
        client: data.client
            ? {
                  label: data.client.name,
                  value: data.client.id.toString()
              }
            : {
                  label: '',
                  value: '0'
              },
        max_amount: data.max_amount
            ? buildNumberFormat(data.max_amount)
            : buildNumberFormat(0)
    })
}
