import moment from 'moment'
import { Colors } from '../../../../config/types/component'
import useRule from '../../../../hooks/useRule'
import { view } from '../../../../sections/View'
import { GetPurchases } from '../../../../types/purchase'
import buildName from '../../../../utilities/buildName'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import ModalPurchase from '../../../../modals/ModalPurchase'

export default function CommonCols({ item }: { item: GetPurchases[number] }) {
    const math = useRule('CU249')

    const purchase_state = {
        1: 'Pendiente',
        2: 'Enviado a proveedor',
        3: 'Confirmado',
        4: 'Cerrado'
    }

    const purchase_state_colors: Record<number, Colors> = {
        1: 'warning',
        2: 'info',
        3: 'success',
        4: 'ghost'
    }

    function handleClick() {
        // if (!math) return
        view(
            `/v5.6/apis/purchases/${item.id}`,
            () => {
                return {
                    title: `DETALLE DE OC`,
                    Component: ModalPurchase,
                    badge: purchase_state[item.purchase_state.id],
                    colorBadge: purchase_state_colors[item.purchase_state.id]
                }
            },
            'lg'
        )
    }

    return (
        <>
            <td>
                <p
                    onClick={handleClick}
                    className={math ? 'underline cursor-pointer' : ''}
                >
                    {item.code}
                </p>
            </td>
            <td
                className={
                    !item.provider_company.provider.is_enabled
                        ? 'line-through'
                        : ''
                }
            >
                {item.provider_company.provider.commercial_name}
            </td>
            <td
                className={
                    !item.document.requesting_user.is_enabled
                        ? 'line-through'
                        : ''
                }
            >
                {buildName(
                    item.document.requesting_user.name,
                    item.document.requesting_user.lastname
                )}
            </td>
            <td>{item.type_purchase.name}</td>
            <td>
                {item.currency.symbol}{' '}
                {buildNumberFormat(item.total_gross_amount)}
            </td>
            <td>
                {moment(item.required_date)
                    .tz(item.invoicing_company.timezone)
                    .format('DD/MM/YYYY')}
            </td>
        </>
    )
}
