import Dropdown from '../../../../components/Dropdown'
import Rules from '../../../../components/Rules'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../../components/UtilsResponsive/UtilsResponsive.style'
import OptionExcel from './Export/OptionExcel'
import OptionPDF from './Export/OptionPDF'
import CheckboxOnlyClients from './Filters/CheckboxOnlyClients'
import CheckboxOnlyOrders from './Filters/CheckboxOnlyOrders'
import CheckboxStock from './Filters/CheckboxStock'
import CompanyFilter from './Filters/CompanyFilter'
import FiltersForm from './Filters/FiltersForm'
import Search from './Filters/Search'

export default function Control() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <CheckboxStock />
                    <CompanyFilter />
                    <FiltersForm />
                </FlexLeft>
                <FlexRight>
                    <Dropdown label="Exportar" icon="download" clear={false}>
                        <Rules cu="CU107">
                            <OptionPDF />
                        </Rules>
                        <Rules cu="CU130">
                            <OptionExcel />
                        </Rules>
                    </Dropdown>
                </FlexRight>
            </FlexBox>
            <FlexBox>
                <FlexLeft>
                    <CheckboxOnlyClients />
                    <CheckboxOnlyOrders />
                </FlexLeft>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
