import BtnActionTable from '../../../../components/BtnActionTable'
import Rules from '../../../../components/Rules'
import OpenDialog from '../../../../sections/OpenDialog'
import { controlList } from '../../storage'
import { InputPartData } from '../../types'

export default function CancelInputPart({
    input_part
}: {
    input_part: InputPartData
}) {
    return (
        <Rules cu={'CU181'}>
            <OpenDialog
                element={click => (
                    <BtnActionTable
                        title="Anular"
                        iconName="bs-x"
                        onClick={click}
                        disabled={false}
                    />
                )}
                title="ANULAR PARTE DE ENTRADA"
                path="/v5.6/apis/input_parts/cancel"
                icon="bs-x-circle"
                color="error"
                control={controlList}
                body={{
                    input_part_id: input_part.id
                }}
                method="put"
                accept="Sí, anular"
            >
                ¿Está seguro de anular la parte de entrada{' '}
                {input_part.document.code}?
            </OpenDialog>
        </Rules>
    )
}
