import { useEffect, useState } from 'react'
import { Empty, FormControl } from '../../../../components/FormUtilities'
import Option from '../../../../components/Option'
import SelectTouch from '../../../../components/SelectTouch'
import Currency from '../../../../models/Currency'
import { get } from '../../../../utilities/http'
import { MoneyProps } from './types'

export default function MoneyField({
    option,
    setOption,
    helper,
    optionCompany
}: MoneyProps) {
    const [currencies, setCurrencies] = useState<Currency[]>([])

    useEffect(() => {
        setOption(null)
        if (optionCompany && optionCompany.value !== '0') {
            get<Currency[]>(
                `/v5.6/core/currencies?company_id=${optionCompany.value}`
            ).then(({ data, error }) => {
                if (!error) {
                    setCurrencies(data.result)
                }
            })
        } else {
            setCurrencies([])
        }
    }, [optionCompany])

    return (
        <FormControl label="Moneda" required className="mb-2">
            <SelectTouch
                placeholder="Seleccionar moneda"
                option={option}
                onChange={setOption}
                helper={helper}
            >
                {currencies.map(currency => (
                    <Option
                        key={currency.id}
                        label={`${currency.name} (${currency.symbol})`}
                        value={currency.id.toString()}
                    />
                ))}
                <Empty data={currencies} />
            </SelectTouch>
        </FormControl>
    )
}
