import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList, controlTabs } from '../storage'
import TraspaseView from './TraspaseView'
import { ActionApproved } from './Actions'
import { MinimalStowageItem, TraspaseItem } from '../types'
import useRule from '../../../hooks/useRule'
import Traspase from '../../../models/Traspase'
import Typography from '../../../components/Typography'
import buildName from '../../../utilities/buildName'
import { storagePage } from '../../Traspases/storage'
import moment from 'moment'
import Rules from '../../../components/Rules'
import ExportExcel from './Options/ExportExcel'
import CancelTraspase from './Options/CancelTraspase'
import BtnActionTable from '../../../components/BtnActionTable'
import { useHistory } from 'react-router-dom'

function Code({ data }: { data: TraspaseItem }) {
    const math = useRule('CU149')

    return (
        <div
            {...(math && {
                className: 'font-univers-bold mb-1 underline cursor-pointer',
                onClick: () => {
                    view<Traspase>(
                        `/v5.6/apis/traspases/${data.id}`,
                        () => ({
                            title: `TRASPASO ${data.document.code}`,
                            Component: TraspaseView,
                            badge: data.document.type_document_approval.name,
                            colorBadge:
                                data.document.type_document_approval_id === 3
                                    ? 'success'
                                    : 'info'
                        }),
                        'md'
                    )
                }
            })}
        >
            <Typography type="paragraph" className="font-bold">
                {data.code}
            </Typography>
        </div>
    )
}
export default function Table() {
    const { push } = useHistory()

    const value = controlTabs.value
    return (
        <List control={controlList}>
            {(data, e) => (
                <tr key={data.id}>
                    <td>{e}</td>
                    <td>
                        <Code data={data} />
                    </td>
                    <td
                        style={{ whiteSpace: 'normal' }}
                        className={`${
                            !data.document.requesting_user.is_enabled &&
                            'line-through'
                        }`}
                    >
                        {buildName(
                            data.document.requesting_user.name,
                            data.document.requesting_user.lastname
                        )}
                    </td>
                    {/* <td>
                        <ul className="list-disc list-inside">
                            {data.document.document_approver_users.map(
                                (item, i) => (
                                    <li
                                        key={i}
                                        className={
                                            colorTypeDocumentApproval[
                                                item
                                                    .type_user_document_approval_id
                                            ].bullet
                                        }
                                    >
                                        <span
                                            className={`text-primary ${
                                                !item.user.is_enabled &&
                                                'line-through'
                                            }`}
                                        >
                                            {buildName(
                                                item.user.name,
                                                item.user.lastname
                                            )}
                                        </span>
                                    </li>
                                )
                            )}
                        </ul>
                    </td> */}
                    <td
                        style={{ whiteSpace: 'normal' }}
                        className={`${
                            !data.company_warehouse.warehouse.is_enabled &&
                            'line-through'
                        }`}
                    >
                        {`${data.company_warehouse.warehouse.name}`}
                    </td>
                    <td
                        style={{ whiteSpace: 'normal' }}
                        className={`${
                            !data.origin_minimal_stowage.stowage.is_enabled &&
                            'line-through'
                        }`}
                    >
                        {getSubStowages(data.origin_minimal_stowage)}
                    </td>
                    <td
                        style={{ whiteSpace: 'normal' }}
                        className={`${
                            !data.origin_minimal_stowage.stowage.is_enabled &&
                            'line-through'
                        }`}
                    >
                        {getSubStowages(data.destination_minimal_stowage)}
                    </td>
                    <td>
                        {data.document.decision_date !== null &&
                            moment(data.document.decision_date)
                                .tz(storagePage.timezone)
                                .format('DD/MM/YYYY')}
                        {/* {value === 'step1' &&
                            data.document.request_date !== null &&
                            moment(data.document.request_date)
                                .tz(storagePage.timezone)
                                .format('DD/MM/YYYY')} */}
                    </td>
                    {/* {value === 'step1' && (
                        <td>
                            <Badge
                                color={
                                    colorTypeDocumentApproval[
                                        data.document.type_document_approval.id
                                    ].badge
                                }
                            >
                                {data.document.type_document_approval.name}
                            </Badge>
                        </td>
                    )} */}
                    <td>
                        <div className="group-btn-options">
                            {!data.document.is_annuled && (
                                <Rules cu="CU151">
                                    <BtnActionTable
                                        title="Editar"
                                        iconName="bs-pencil"
                                        disabled={!storagePage.company_enabled}
                                        onClick={() =>
                                            push(
                                                `/movements/traspases/${data.id}/edition`
                                            )
                                        }
                                    />
                                </Rules>
                            )}
                            {/* {value === 'step1' && <ActionPending traspase={data} />} */}
                            {value === 'step1' && (
                                <ActionApproved traspase={data} />
                            )}
                            <Rules cu={'CU316'}>
                                <ExportExcel traspase_id={data.id} />
                            </Rules>
                            <CancelTraspase traspase={data} />
                            {/* {value === 'step3' && <ActionOther traspase={data} />}
                        {value === 'step4' && <ActionOther traspase={data} />} */}
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}

function getSubStowages(minimal_stowage: MinimalStowageItem) {
    const name = minimal_stowage.stowage.name
    const type_stowage_id = minimal_stowage.type_stowage.id

    if ([2, 11].includes(type_stowage_id)) {
        return (
            <>
                <p>
                    {' '}
                    {minimal_stowage.order_sub_stowage.order.number +
                        ' - ' +
                        minimal_stowage.order_sub_stowage.order.client?.name}
                </p>
            </>
        )
    } else {
        return <p>{name}</p>
    }
}
