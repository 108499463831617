import ListFormControl from '../../sections/ListForm/ListFormControl'
import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
import {
    ModalData,
    ProductCompanyData,
    TableProductProviderCompanyData
} from './types'

export const productCompanyData: ModalData = {
    productCompany: null
}

export const controlList = new ControlList<ProductCompanyData>(Keys.MAIN, {
    path: '/v5.6/apis/product_companies/principal_providers',
    theads: [
        '#',
        { value: 'product_code', label: 'Código de producto' },
        { value: 'product_name', label: 'Descripción de producto' },
        { value: 'provider_commercial_name', label: 'Proveedor Principal' },
        { value: 'company_name', label: 'Compañía compradora' },
        'Costo proveedor',
        { value: 'provider_company_is_enabled', label: 'Estado' },
        'Acciones'
    ]
})

export const listProductProviderCompany =
    new ListFormControl<TableProductProviderCompanyData>(
        'ProductProviderCompany',
        ['Nombres', 'Precio de compra', 'Principal']
    )
