import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'
import { ProductData } from './types'

export const controlList = new ControlList<ProductData>(Keys.MAIN, {
    path: '/v5.6/apis/products',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'name', label: 'Descripción' },
        'Compañía',
        { value: 'brand_name', label: 'Marca' },
        { value: 'category_name', label: 'Categoría' },
        { value: 'subcategory_name', label: 'Subcategorías' },
        'Acciones'
    ]
})
