import './modalInfo.css'
import { PropsModal } from '../Modal/types'
import Modal from '../Modal'

export default function ModalInfo({
    title = '',
    open = false,
    size = 'md',
    children,
    onSubmit = () => null,
    onClose = () => null,
    isCentered = false
}: PropsModal) {
    return (
        <Modal
            title={title}
            open={open}
            size={size}
            onSubmit={onSubmit}
            onClose={onClose}
            isCentered={isCentered}
            className="modal--info"
        >
            {children}
        </Modal>
    )
}
