import moment from 'moment'
import { render } from 'redity'
import { parseFile } from '../../utilities/fileTools'
import { formPurchaseReceipt, productsControl, storagePage } from './storage'
import { BodyPost, BodyPut, ProductsForInput } from './types'

export default function useSubmit(purchase_receipt_id?: number) {
    const { submit, validation, observer } = formPurchaseReceipt.useSubmit(
        purchase_receipt_id
            ? `/v5.6/apis/receptions/${purchase_receipt_id}`
            : '/v5.6/apis/receptions',
        {
            message: '¿Está seguro de guardar la recepción?',
            done: '/movements/purchase_receipts',
            method: purchase_receipt_id ? 'put' : 'post'
        }
    )

    let products_confirmed: ProductsForInput[] = []

    validation(v => {
        products_confirmed = productsControl
            .getDataList()
            .filter(product => product.confirmed)

        v.company.isEmpty('Se requiere seleccionar la compañía')
        v.files.isEmpty('Se requiere un archivo como mínimo')
        v.warehouse.isEmpty('Se requiere seleccionar un almacén')
        v.typeRC.isEmpty(
            'Se requiere seleccionar un tipo de recepción de compra'
        )
        v.shipment.maxLength(
            180,
            'El número máximo de caracteres válidos es 180'
        )
        v.numberInvoiceFinal.maxLength(
            180,
            'El número máximo de caracteres válidos es 180'
        )
        v.numberInvoiceShipping.maxLength(
            180,
            'El número máximo de caracteres válidos es 180'
        )
        v.exchangeRate.isEmpty('Se requiere una tasa de cambio').isGreater(
            // eslint-disable-next-line no-loss-of-precision
            99999999999999999999.99,
            'El número máximo es de 20 cifras'
        )
        v.comment.maxLength(
            3000,
            'El número máximo de caracteres válidos es 3000'
        )
        v.receiptDate.isEmpty('Se requiere seleccionar una fecha de recepción')
        if (products_confirmed.length === 0) {
            productsControl.setMessage(
                'Se requiere confirmar al menos un producto'
            )
            render(formPurchaseReceipt.keyRender)
            return false
        }
    })

    observer<BodyPost | BodyPut>(f => {
        if (purchase_receipt_id) {
            return {
                number_shipment: f.shipment.trim(),
                exchange_rate: parseFloat(f.exchangeRate),
                provider_local_amount: parseFloat(f.providerLocalAmount),
                description: f.comment.trim(),
                number_invoice_final: f.numberInvoiceFinal.trim() || null,
                number_invoice_shipping: f.numberInvoiceShipping.trim() || null,
                invoice_final_date: f.invoiceFinalDate
                    ? moment(f.invoiceFinalDate).toISOString(true)
                    : null,
                invoice_shipping_date: f.invoiceShippingDate
                    ? moment(f.invoiceShippingDate).toISOString(true)
                    : null,
                files: f.files.map(file => parseFile(file)),

                purchases: [
                    {
                        purchase_id: parseInt(f.purchase.value),
                        purchase_products: products_confirmed.map(product => ({
                            purchase_product_id: product.id,
                            amount_to_receive: parseInt(
                                product.quantity_requested
                            )
                        }))
                    }
                ]
            }
        }
        const date = moment(f.receiptDate).tz(storagePage.timezone).parseZone()
        const newDate = moment
            .tz(date.format('YYYY-MM-DD'), storagePage.timezone)
            .add(1, 'minute')

        return {
            company_id: parseInt(f.company.value),
            warehouse_id: parseInt(f.warehouse.value),
            type_reception_id: parseInt(f.typeRC.value),
            number_shipment: f.shipment.trim() || null,
            number_invoice_final: f.numberInvoiceFinal.trim() || null,
            number_invoice_shipping: f.numberInvoiceShipping.trim() || null,
            invoice_final_date: f.invoiceFinalDate
                ? moment(f.invoiceFinalDate).toISOString(true)
                : null,
            invoice_shipping_date: f.invoiceShippingDate
                ? moment(f.invoiceShippingDate).toISOString(true)
                : null,
            exchange_rate: parseFloat(f.exchangeRate),
            provider_local_amount: parseFloat(f.providerLocalAmount),
            request_date: newDate.toISOString(true),
            description: f.comment.trim() || null,
            files: f.files.map(file => parseFile(file)),
            purchases: [
                {
                    purchase_id: parseInt(f.purchase.value),
                    purchase_products: products_confirmed.map(product => ({
                        purchase_product_id: product.id,
                        amount_to_receive: parseInt(product.quantity_requested)
                    }))
                }
            ]
        }
    })

    return submit
}
