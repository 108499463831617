import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import ControlRequest from '../../utilities/ControlRequest'
import { ConsumerVoucherData, StoragePage } from './types'
import moment from 'moment'

export const controlList = new ControlList<ConsumerVoucherData>(Keys.MAIN, {
    path: '/v5.6/apis/search_consumer_vouchers',
    theads: [
        '#',
        { value: 'code', label: 'Código' },
        { value: 'user_name', label: 'Usuario' },
        { value: 'warehouse_name', label: 'Almacén' },
        'Bodega',
        'Etiqueta',
        { value: 'date', label: 'Fecha de emisión' },
        'Estado',
        'Acciones'
    ]
})
export const reqSummaryData = new ControlRequest()
controlList.badgeRemove('start_date', false)
controlList.badgeRemove('end_date', false)
export const filterForm = new FormEvents<any>(Keys.FILTER_FORM, {
    warehouse_id: null,
    stowage_id: null,
    minimal_stowage_id: null,
    start_date: null,
    end_date: null
})

export const reqStowage = new ControlRequest()
export const reqWarehouse = new ControlRequest()
export const reqSubStowage = new ControlRequest()

controlList.onDeleteBadge = key => {
    if (key === 'stowage_id') {
        controlList.filter.deleteFilter('minimal_stowage_id')
        filterForm.setValue('minimal_stowage_id', null)
    }
}

controlList.badgeRemove('company_id', false)

export const storagePage: StoragePage = {
    consumer_voucher: null,
    company_enabled: false,
    timezone: ''
}

export const currency_base = {
    id: 0,
    created_at: '',
    updated_at: '',
    deleted: false,
    core_id: 0,
    name: '',
    code: '',
    symbol: '',
    is_enabled: false
}
