import LogisticalConcept from '../../../../../models/LogisticalConcept'
import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowCost(
    concept: LogisticalConcept,
    data: LogisticalDeclarationExcelData[],
    name_cost_sources: string[]
) {
    const row: Row[] = [
        {
            value: concept.name
        }
    ]
    data.forEach(d => {
        const cost = searchCostRelatedToConcept(concept, d, name_cost_sources)
        row.push({
            value: cost ? cost.original_cost : 0,
            format: '0.00'
        })
        row.push({
            value: cost ? cost.exchange_to_local : 0,
            format: '0.00'
        })
        row.push({
            value: cost ? getCellAgent(cost) : ''
        })
        row.push({
            value: cost ? cost.local_cost : 0,
            format: '0.00'
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}

function searchCostRelatedToConcept(
    concept: LogisticalConcept,
    d: LogisticalDeclarationExcelData,
    name_cost_sources: string[]
) {
    let cost = null
    for (const name_cost_source of name_cost_sources) {
        cost = d[name_cost_source].find(
            nc => nc.logistical_concept_id === concept.id
        )
        if (cost) {
            break
        }
    }
    return cost
}

function getCellAgent(logistical_cost: any) {
    const array_text = []
    if (logistical_cost.agent) {
        array_text.push(logistical_cost.agent.name)
    }
    if (logistical_cost.document_number) {
        array_text.push(logistical_cost.document_number)
    }
    return array_text.join(' - ')
}
