import moment from 'moment'
import { render } from 'redity'
import DatePicker from '../../../../components/Datepicker'
import { FormControl } from '../../../../components/FormUtilities'
import Textarea from '../../../../components/Textarea'
import useGet from '../../../../hooks/useGet'
import useRule from '../../../../hooks/useRule'
import useStorage from '../../../../hooks/useStorage'
import WorkingPeriod from '../../../../models/WorkingPeriod'
import {
    formConsumptionVoucher,
    productsControl,
    reqProducts,
    reqWorkingPeriod
} from '../../storage'

function IssueDate() {
    const { companiesFromSession } = useStorage()
    const company = companiesFromSession.find(
        c => c.id === parseInt(formConsumptionVoucher.store().company.value)
    )
    const { props, getError } = formConsumptionVoucher.useDate('request_date')
    const math = useRule('CU999')

    const helper = getError('ERROR-0000104')

    const { data } = useGet<WorkingPeriod>(
        reqWorkingPeriod.observer(
            '/v5.6/apis/working_periods/first_working_period/:company_id',
            true
        )
    )

    const { month, year, created_at } = data.result || {}
    const minDate =
        month && year
            ? moment(created_at)
                  .tz(company.timezone)
                  .parseZone()
                  .toISOString(true)
            : null

    function handleChange(isoString: string) {
        props.onChange(isoString)
        reqProducts.setQuery(
            'operation_date',
            moment(isoString).format('YYYY-MM-DD')
        )
        render('badge_label')
        if (reqProducts.hasQuery('origin_minimal_stowage_id')) {
            formConsumptionVoucher.setValue('products', [])
            reqProducts.deleteQuery('not_logical_product_ids')
            productsControl.setList([])
            reqProducts.load()
            productsControl.renderMain()
        }
    }

    return (
        <FormControl label="Fecha de emisión" required>
            <DatePicker
                disabled={!math && month && year}
                minDate={minDate}
                maxDate={moment()
                    .tz(company.timezone)
                    .parseZone()
                    .toISOString(true)}
                {...props}
                onChange={handleChange}
                helper={props.helper || helper}
                placeholder="Seleccionar fecha de emisión"
            />
        </FormControl>
    )
}

function Description() {
    const { props } = formConsumptionVoucher.useInput('description')
    return (
        <FormControl label="Descripción" col="span-6" required>
            <Textarea {...props} placeholder="Ingrese una descripción" />
        </FormControl>
    )
}

export default function FIelds3() {
    return (
        <>
            <IssueDate />
            <Description />
        </>
    )
}
