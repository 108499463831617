export default function More(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M14 8H8v6H6V8H0V6h6V0h2v6h6v2z" />
        </svg>
    )
}
