import { render } from 'redity'
import { KEY_POPUP } from './constants'
import { statePopup, stateUtils } from './storage'

export async function wait(): Promise<boolean> {
    const result: boolean = await new Promise(resolve => {
        stateUtils.resolve = resolve
        render(KEY_POPUP)
    })
    statePopup.set('open', false)
    render(KEY_POPUP)
    return result
}
