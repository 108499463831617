export function getMinimalStowageName(minimal_stowage) {
    let name = minimal_stowage.stowage.name

    if (minimal_stowage.order_sub_stowages.length > 0) {
        name =
            minimal_stowage.order_sub_stowages[0].order.number +
            ' - ' +
            minimal_stowage.order_sub_stowages[0].order.client.name
    }

    if (minimal_stowage.gp_sub_stowages.length > 0) {
        name = name + ' - ' + minimal_stowage.gp_sub_stowages[0].purchase.code
    }
    return name
}
