import Panel, { PanelBody } from '../../../components/Panel'
import TableRequisitions from './TableRequisitions'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import Filters from './Filters'
import { controlList, controlTabs } from '../storage'
import {
    tHeadsApproved,
    tHeadsCanceled,
    tHeadsPending,
    tHeadsRejected
} from '../constants'

// controlTabs.setSection('Pendientes', TableRequisitions, 'alert')
controlTabs.setSection('Aprobados', TableRequisitions, 'check-cirle')
// controlTabs.setSection('Rechazados', TableRequisitions, 'times')
// controlTabs.setSection('Cancelados', TableRequisitions, 'trash')

export default function Requisitions() {
    const state = useInitial()
    const value = controlTabs.useStep(to => {
        controlList.filter.params.page = 1
        if (to === 'step2') {
            controlList.thead = tHeadsPending
            controlList.filter.params.document_approval = 'Pendientes'
            controlList.done().then(() => {
                controlList.renderMain()
            })
        }
        if (to === 'step2') {
            controlList.thead = tHeadsApproved
            controlList.filter.params.document_approval = 'Aprobados'
            controlList.done().then(() => {
                controlList.renderMain()
            })
        }
        if (to === 'step3') {
            controlList.thead = tHeadsRejected
            controlList.filter.params.document_approval = 'Rechazados'
            controlList.done().then(() => {
                controlList.renderMain()
            })
        }
        if (to === 'step4') {
            controlList.thead = tHeadsCanceled
            controlList.filter.params.document_approval = 'Cancelados'
            controlList.done().then(() => {
                controlList.renderMain()
            })
        }
        return true
    })

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <Filters />
                    <controlTabs.SectionTabs
                        value={value}
                        type="tab"
                        mode="simple"
                        className="mb-4 mt-2"
                    />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
