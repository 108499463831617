import { Autocomplete } from '@react-google-maps/api'
import { useState } from 'react'
import { PropsMapSearch } from './types'
import SearchField from '../SearchField'

export default function MapSearch({ onPlaceChanged }: PropsMapSearch) {
    const [autocomplete, setAutocomplete] =
        useState<google.maps.places.Autocomplete>(null)
    const [search, setSearch] = useState('')

    function handlePlace() {
        if (autocomplete) {
            const result = autocomplete.getPlace()
            if (result) {
                const lat = result.geometry.location.lat()
                const lng = result.geometry.location.lng()
                onPlaceChanged(result, {
                    lat,
                    lng
                })
            }
        }
    }
    return (
        <div className="map-search">
            <Autocomplete onLoad={setAutocomplete} onPlaceChanged={handlePlace}>
                <SearchField
                    value={search}
                    onChange={ev => setSearch(ev.target.value)}
                    fullWidth
                    placeholder="Búsqueda"
                    onClear={() => setSearch('')}
                />
            </Autocomplete>
        </div>
    )
}
