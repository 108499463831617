import Badge from '../Badge'
import { PropsEnable } from './types'

export default function Enable({ enabled, className = '' }: PropsEnable) {
    return (
        <Badge color={enabled ? 'success' : 'error'} className={className}>
            {enabled ? 'Activo' : 'Inactivo'}
        </Badge>
    )
}
