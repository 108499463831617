import moment from 'moment'
import buildCompanyName from '../../utilities/buildCompanyName'
import {
    formInputPart,
    productsControl,
    reqProducts,
    reqStowage,
    reqWarehouse,
    reqWorkingPeriod,
    storagePage,
    reqSubStowage
} from './storage'
import { GETInputPart, LogicalProductForInput } from './types'

export default function useInitialEdition() {
    return function load(InputPart: GETInputPart) {
        const {
            company_warehouse,
            minimal_stowage,
            input_part_label,
            input_part_products,
            document
        } = InputPart

        const company = company_warehouse.company
        formInputPart.setValue('company', {
            label: buildCompanyName(company.name),
            value: company.id.toString(),
            image: company.country.image_url
        })

        storagePage.symbol = storagePage.companies.find(
            c => c.id === company.id
        ).local_currency.symbol

        reqWarehouse.setQuery('company_id', company.id)
        reqStowage.setQuery('company_id', company.id)
        formInputPart.setProps('company', {
            disabled: true
        })

        const warehouse = company_warehouse.warehouse
        formInputPart.setValue('warehouse', {
            label: warehouse.name,
            value: warehouse.id.toString()
        })
        formInputPart.setProps('warehouse', { disabled: true })

        const stowage = minimal_stowage.stowage
        formInputPart.setValue('stowage', {
            label: stowage.name,
            value: stowage.id.toString()
        })
        formInputPart.setProps('stowage', { disabled: true })
        formInputPart.setValue(
            'request_date',
            moment(document.request_date)
                .tz(storagePage.timezone)
                .toISOString(true)
        )
        formInputPart.setProps('request_date', { disabled: true })
        reqProducts.setQuery(
            'operation_date',
            moment(document.request_date)
                .tz(storagePage.timezone)
                .format('YYYY-MM-DD')
        )

        const listStowages = [2, 6, 11]

        formInputPart.setProps('sub_stowage', { disabled: true })

        if (listStowages.includes(minimal_stowage.stowage.type_stowage_id)) {
            formInputPart.setValue('sub_stowage', {
                value: minimal_stowage.id.toString(),
                label: minimal_stowage.order_sub_stowage.order.number
            })
            reqSubStowage.setParam('stowage_id', stowage.id)
        }

        formInputPart.setValue('tag', {
            label: input_part_label.name,
            value: input_part_label.id.toString()
        })

        formInputPart.setValue('description', document.description)

        const logicalProductForInput: LogicalProductForInput[] =
            input_part_products.map(cvp => ({
                id: cvp.id,
                logical_product_id: cvp.operation.logical_product_id,
                name: cvp.product.name,
                code: cvp.product.code,
                presentation: cvp.product.presentation_product
                    ? cvp.product.presentation_product.amount.toString()
                    : '',
                quantity: cvp.quantity.toString(),
                stock: 0,
                average: cvp.income_unit_cost.toString(),
                total: cvp.quantity * cvp.income_unit_cost
            }))
        productsControl.setList(logicalProductForInput)
        const options = logicalProductForInput.map(lpfv => ({
            label: lpfv.name,
            value: lpfv.logical_product_id.toString()
        }))
        formInputPart.setValue('products', options)
        formInputPart.setProps('products', { disabled: false })
        reqWorkingPeriod.setParam('company_id', company.id)
        reqProducts.setQuery('origin_minimal_stowage_id', minimal_stowage.id)
        reqProducts.setQuery(
            'not_logical_product_ids',
            options.map(option => option.value)
        )
    }
}
