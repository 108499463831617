import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { formManagementRQ, storagePage } from './storage'

export default function useInitial() {
    const [pageState, setState] = useState<StateCase>(StateCase.LOADING)

    async function load() {
        setState(StateCase.SUCCESS)
    }

    useEffect(() => {
        load()
        return () => {
            formManagementRQ.init()
            storagePage.requisition_product_id = null
            storagePage.initLoad = false
            storagePage.openDialog = false
            storagePage.products = []
            storagePage.requisition = null
        }
    }, [])

    return {
        pageState
    }
}
