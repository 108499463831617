import { useHistory } from 'react-router'
import { mergeExpect } from '../../utilities/dataTools'
import { Popup } from '../../utilities/popup'
import submit from '../../utilities/submit'
import { storagePage, tableProducts } from './storage'
import { Body } from './types'

const getBody = (): Body => {
    const [new_product_ids, product_to_deleted_ids] = mergeExpect(
        tableProducts.getDataList(),
        storagePage.license.product_licenses
            .filter(product_license => product_license.memory)
            .map(product_license => product_license.product)
    )
        .mapping(product => !product.product_license_id && product.id)
        .separate(product => product.id)

    return {
        new_product_ids,
        product_to_deleted_ids
    }
}

export default function useSubmit() {
    const { push } = useHistory()
    async function handleClick() {
        const { success, error } = await submit(
            `/v5.6/apis/licenses/${storagePage.license.id}/relate_products`,
            {
                confirm:
                    '¿Está seguro de guardar cambios de asignar productos válidos para licencia?',
                body: getBody(),
                method: 'put'
            }
        )

        if (error) {
            Popup.error(
                'Algo salió mal...',
                'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
            )
        }

        if (success) {
            push('/configuration/licenses')
        }
    }

    return handleClick
}
