import { ListFormControl } from '../../../../sections/ListForm'
import ControlRequest from '../../../../utilities/ControlRequest'
import { Keys } from '../../constants'

/**
 * Step 4
 */

/**
 * @deprecated
 */
export const tableDistributionbyRC = new ListFormControl<any>(
    Keys.TABLE_DISTRIBUTION_BY_RC,
    [
        '#',
        'RC',
        'OC',
        'Monto costeado actual',
        'Monto a costear nuevo',
        'Monto a costear nuevo con penalización'
    ]
)

/**
 * @deprecated
 */
export const tableDistributionByProduct = new ListFormControl<any>(
    Keys.TABLE_DISTRIBUTION_BY_PRODUCT,
    [
        '#',
        'Producto',
        'Valor de ingreso unitario actual',
        'Valor de ingreso nuevo'
    ]
)

export const reqRC3 = new ControlRequest()
export const reqPCD = new ControlRequest()
