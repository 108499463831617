import classNames from 'classnames'
import moment from 'moment'
import InputField from '../InputField'
import Icon from '../Icon'
import Calendar from './Subcomponents/Calendar'
import useOut from '../../hooks/useOut'
import { PropsDatePicker } from './type'
import Context from './context'
import { useState } from 'react'

export default function DatePicker({
    fullWidth = true,
    disabled = false,
    helper = '',
    onChange,
    value,
    format = 'DD/MM/YYYY',
    position = 'left',
    listPosition = 'bottom',
    minDate = null,
    maxDate = null,
    placeholder = '',
    className = '',
    minimize = false,
    infoZ = ' z-20'
}: PropsDatePicker) {
    const date = value ? moment(value).parseZone() : null
    const [viewDate] = useState(() => date || moment())
    const { ref, isOpen, setOpen } = useOut([])

    function handleChange(val: string) {
        if (disabled) return
        onChange(val)
        setOpen(false)
    }

    function handleClick() {
        if (disabled) return
        if (!isOpen) {
            setOpen(true)
        }
    }

    function handleFocus() {
        setOpen(true)
    }

    return (
        <Context.Provider
            value={{
                date,
                format,
                minDate: minDate ? moment(minDate).parseZone() : null,
                maxDate: maxDate ? moment(maxDate).parseZone() : null,
                onChange: handleChange
            }}
        >
            <div
                className={classNames(
                    `relative datepicker bg-transparent` + infoZ + ` h-auto`,
                    {
                        'w-full': fullWidth,
                        'max-w-xs': !fullWidth,
                        'cursor-pointer': !disabled,
                        'cursor-not-allowed': disabled
                    },
                    className
                )}
                onClick={handleClick}
                ref={ref}
            >
                <InputField
                    onFocus={handleFocus}
                    readOnly
                    disabled={disabled}
                    className={classNames('w-full')}
                    style={
                        minimize
                            ? {
                                  borderColor: '#29A6FF',
                                  color: '#29A6FF'
                              }
                            : {}
                    }
                    helper={helper}
                    placeholder={placeholder}
                    value={date ? date.format(format) : ''}
                    size={minimize ? 'sm' : 'md'}
                />
                <Icon
                    name="calendar"
                    className={`absolute right-3 ${
                        minimize ? 'top-2 text-accent' : 'top-3 text-gray-400'
                    }`}
                    size={16}
                />
                <div
                    className={classNames(
                        `w-full border-solid border border-gray-400 absolute bg-white box-border z-10 select-none flex flex-col opacity-0 scale-0 transition-all delay-50`,
                        {
                            'left-0': position === 'left',
                            'right-0': position !== 'left',
                            'bottom-10': listPosition === 'top',
                            'top-10': listPosition === 'bottom',
                            'invisible text-center': !isOpen,
                            'opacity-100 visible shadow-lg block': isOpen
                        }
                    )}
                >
                    <Calendar viewDate={viewDate} />
                </div>
            </div>
        </Context.Provider>
    )
}
