import { data, ModuleForm } from '../storage'
import { RolePermissionData } from '../types'

export default function assignPermissionRoleId(
    modules: ModuleForm[],
    rolePermission: RolePermissionData
) {
    data.rolePermission = rolePermission
    for (const permissionRole of rolePermission.permission_roles) {
        modules.forEach(mod => {
            const permission = mod.permissions.find(
                per => per.store().id === permissionRole.permission_id
            )

            if (permission) {
                if (!mod.groupPermision.store().active) {
                    mod.groupPermision.store.set(
                        'active',
                        permissionRole.is_enabled
                    )
                }
                permission.store.set('permission_role_id', permissionRole.id)
                permission.store.set('active', permissionRole.is_enabled)
                permission.store.init(permission.store)
            }
        })
    }
}
