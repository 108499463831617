import { expectValue } from './utilities'
import { PropsFractionField } from './type'
import InputField from '../InputField'
import classNames from 'classnames'
import buildNumberFormat from '../../utilities/buildNumberFormat'
import { ChangeEvent } from 'react'

export default function FractionField({
    value = '',
    onChange = () => null,
    max = 9999999999,
    negative = false,
    prefix = null,
    size = 'normal',
    fullWidth = true,
    className = '',
    decimal = 0,
    ...props
}: PropsFractionField) {
    const options = {
        max,
        negative,
        decimal
    }

    function handleChange(ev: ChangeEvent<HTMLInputElement>) {
        const clearedValue = ev.target.value.split(',').join('')
        const val = expectValue(clearedValue, options)

        onChange({
            ...ev,
            target: {
                ...ev.target,
                value: val
            }
        })
    }

    function handleBlur(ev) {
        const clearedValue = value.split(',').join('')

        onChange({
            ...ev,
            target: {
                ...ev.target,
                value: buildNumberFormat(Number(clearedValue), decimal)
            }
        })
    }

    return (
        <div className="relative">
            <InputField
                {...props}
                onChange={handleChange}
                value={value}
                regex={null}
                size={size}
                className={classNames(
                    `${fullWidth && 'w-full'} ${prefix && 'pl-14'}`,
                    className
                )}
                onBlur={handleBlur}
            />
            {prefix && (
                <div className="absolute bg-primary text-white top-0 left-0 px-4 py-3 text-xs rounded-l font-univers-bold">
                    {prefix}
                </div>
            )}
        </div>
    )
}
