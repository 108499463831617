import Ul from '../../../components/Ul'
import { GetWarehouseView } from '../../../types/warehouses'

export default function WarehouseView({ data }: { data: GetWarehouseView }) {
    return (
        <>
            <Ul className="mb-4">
                <li className="list-outside">
                    País: <b>{data.country.name}</b>
                </li>
                <li className="list-outside	">
                    Código de almacén: <b>{data.code}</b>
                </li>
                <li className="list-outside	">
                    Nombre de almacén: <b>{data.name}</b>
                </li>
                <li className="list-outside	">
                    Dirección fiscal: <b>{data.address.address}</b>
                </li>
                <li className="list-outside	">
                    Companías relacionadas:
                    <div className="mt-1">
                        {data.companies.map((company, i) => {
                            const underscore =
                                i === data.companies.length - 1 ? '' : ' - '

                            if (company.company.is_enabled)
                                return (
                                    <b>{company.company.name + underscore}</b>
                                )
                            else {
                                return (
                                    <b className="line-through">
                                        {company.company.name + underscore}
                                    </b>
                                )
                            }
                        })}
                    </div>
                </li>
            </Ul>
        </>
    )
}
