import { render } from 'redity'
import { Option } from '../../../config/types/component'
import useStorage from '../../../hooks/useStorage'
import { setCompanySelected } from '../../../utilities/controlStorage'
import {
    formPurchaseReceipt,
    productsControl,
    reqPurchase,
    reqWarehouse,
    reqWorkingPeriod,
    storagePage
} from '../storage'
import moment from 'moment'
import { Keys } from '../constants'

export default function useCompany() {
    const { props } = formPurchaseReceipt.useSelect('company')
    const { companiesFromSession } = useStorage()

    const companies = companiesFromSession
        .filter(company => company.is_enabled)
        .map(company => ({
            id: company.id,
            name: company.name,
            image: company.country.image_url
        }))

    function handleChange(option: Option) {
        formPurchaseReceipt.setProps('warehouse', { disabled: false }, false)
        formPurchaseReceipt.setValue('warehouse', null)
        reqWarehouse.setQuery('company_id', option.value)
        reqWarehouse.load()
        reqWorkingPeriod.setParam('company_id', option.value)
        reqWorkingPeriod.load()
        formPurchaseReceipt.setValue('purchase', null)
        reqPurchase.setParam('company_id', option.value)
        reqPurchase.load()

        productsControl.setList([])
        productsControl.renderMain()

        const company = companiesFromSession.find(
            _cp => _cp.id.toString() === option.value
        )
        storagePage.timezone = company.timezone
        storagePage.localCurrency = company.local_currency
        setCompanySelected(company)
        storagePage.receipt_products = {}
        render(Keys.MOUNT)
        const date = moment().tz(storagePage.timezone).parseZone()
        formPurchaseReceipt.setValue('receiptDate', date.toISOString(true))

        props.onChange(option)
        render('PROVIDER_LOCAL_AMOUNT')
    }

    return {
        props,
        companies,
        handleChange
    }
}
