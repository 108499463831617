import { SimpleOption } from '../../../../components/Option'
import { controlList, storagePage } from '../../storage'
import writeXlsxFile, { Schema } from 'write-excel-file'
import { get } from '../../../../utilities/http'
import { buildPath } from '../../../../sections/ModalPrint/utils'
import moment from 'moment'
import { Head, Kardex } from '../../types'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'

export default function OptionExcel() {
    async function download() {
        const { data } = await get(
            buildPath('/v5.6/apis/kardex', [
                ['company_id', controlList.filter.getParam('company_id')],
                ['product_id', controlList.filter.getParam('product_id')],
                ['start_date', controlList.filter.getParam('start_date')],
                ['end_date', controlList.filter.getParam('end_date')]
            ])
        )
        const list_data: any = data.result
        const excelData = []
        const head: Head = controlList.response?.head
        const start_date = moment(
            controlList.filter.getParam('start_date')
        ).format('DD/MM/YYYY')
        const end_date = moment(controlList.filter.getParam('end_date')).format(
            'DD/MM/YYYY'
        )
        const product = controlList.filter.getOption('product_id')
        const productLabel = product.label2 + ' - ' + product.label3
        const range_date = start_date + ' - ' + end_date
        const currency = storagePage.currency
        let def_currency: string
        let v_i_U: string
        let v_p_u: string
        let has_to: string
        let to_have: string
        let cost_balance: string
        if (currency.symbol === '€') {
            def_currency = '€ EURO'
            v_i_U = '(€)' + ' V.I.U.'
            v_p_u = '(€)' + ' V.P.U.'
            has_to = '(€)' + ' Debe'
            to_have = '(€)' + ' Haber'
            cost_balance = '(€)' + ' Saldo'
        } else {
            def_currency = currency.symbol + ' ' + currency.name
            v_i_U = '(' + currency.symbol + ')' + 'V.I.U.'
            v_p_u = '(' + currency.symbol + ')' + ' V.P.U.'
            has_to = '(' + currency.symbol + ')' + ' Debe'
            to_have = '(' + currency.symbol + ')' + ' Haber'
            cost_balance = '(' + currency.symbol + ')' + ' Saldo'
        }
        excelData.push([
            {
                value: 'ADVANCE LABORATORIOS Y AGUAS, INC.',
                fontWeight: 'bold'
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: 'PRODUCTO',
                fontWeight: 'bold',
                wrap: true,
                alignVertical: 'center'
            },
            {
                value: productLabel,
                wrap: true
            }
        ])
        excelData.push([
            {
                value: 'RANGO DE FECHA ',
                wrap: true,
                fontWeight: 'bold'
            },
            {
                value: range_date,
                wrap: true
            }
        ])
        excelData.push([
            {
                value: 'MONEDA',
                wrap: true,
                fontWeight: 'bold'
            },
            {
                value: def_currency,
                wrap: true
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: ''
            }
        ])
        excelData.push([
            {
                value: 'Número',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Transacción',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Fecha',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Bodega',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Tipo',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Entrada',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Salida',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: 'Saldo',
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: v_i_U,
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: v_p_u,
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: has_to,
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: to_have,
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            },
            {
                value: cost_balance,
                fontWeight: 'bold',
                align: 'center',
                wrap: true
            }
        ])
        if (head && list_data > 0) {
            const totalInventory =
                typeof head.total_inventory !== 'undefined'
                    ? head.total_inventory
                    : 0
            excelData.push([
                {
                    value: '',
                    align: 'center',
                    wrap: true
                },
                {
                    value: head.transaction || '',
                    wrap: true
                },
                {
                    value: '',
                    align: 'center',
                    wrap: true
                },
                {
                    value: '',
                    align: 'center',
                    wrap: true
                },
                {
                    value: '',
                    align: 'center',
                    wrap: true
                },
                {
                    value: buildNumberFormat(head.input || 0),
                    align: 'center',
                    wrap: true
                },
                {
                    value: buildNumberFormat(head.output || 0),
                    align: 'center',
                    wrap: true
                },
                {
                    value: totalInventory,
                    align: 'center',
                    wrap: true
                },
                {
                    value: buildNumberFormat(head.income_unit_cost || 0),
                    align: 'center',
                    wrap: true
                },
                {
                    value: buildNumberFormat(head.average_unit_cost || 0),
                    align: 'center',
                    wrap: true
                },
                {
                    value: buildNumberFormat(head.has_to || 0),
                    align: 'center',
                    wrap: true
                },
                {
                    value: buildNumberFormat(head.to_have || 0),
                    align: 'center',
                    wrap: true
                },
                {
                    value: buildNumberFormat(head.cost_balance || 0),
                    align: 'center',
                    wrap: true
                }
            ])
        }
        list_data.forEach(item => {
            // Agregar una fila en blanco si no es la primera recepción
            const date = moment(item.operation_date)
                .tz(storagePage.timezone)
                .format('DD/MM/YYYY')
            excelData.push([
                {
                    value: item.document.code,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.type_operation.name,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: date,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.minimal_stowage_name,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.document.type_document.name,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.input,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.output,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.total_inventory,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.income_unit_cost,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.average_unit_cost,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.hast_to,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.to_have,
                    wrap: true,
                    align: 'center'
                },
                {
                    value: item.cost_balance,
                    wrap: true,
                    align: 'center'
                }
            ])
        })
        // const schema: Schema<any> = [
        //     {
        //         column: 'Recep N°',
        //         width: 20,
        //         value: d => {
        //             return d.reception_code
        //         }
        //     },
        //     {
        //         column: 'Fecha',
        //         width: 20,
        //         value: d => {
        //             return d.date
        //         }
        //     },
        //     {
        //         column: 'Proveedor',
        //         width: 30,
        //         value: d => {
        //             return d.provider
        //         }
        //     },
        //     {
        //         column: 'No.Factura',
        //         width: 20,
        //         value: d => {
        //             return d.invoice
        //         }
        //     },
        //     {
        //         column: 'Orden de C.',
        //         width: 20,
        //         value: d => {
        //             return d.purchase_code
        //         }
        //     },
        //     {
        //         column: 'Bodega',
        //         width: 20,
        //         value: d => {
        //             return d.stowage
        //         }
        //     },
        //     {
        //         column: 'Código',
        //         width: 30,
        //         value: d => {
        //             return d.product_code
        //         }
        //     },
        //     {
        //         column: 'Descripción',
        //         width: 60,
        //         value: d => {
        //             return d.product_description
        //         }
        //     },
        //     {
        //         column: 'Cant',
        //         width: 20,
        //         type: Number,
        //         format: '0',
        //         value: d => {
        //             return d.quantity
        //         }
        //     },
        //     {
        //         column: 'C/U',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.average_unit_cost
        //         }
        //     },
        //     {
        //         column: 'Total',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.total_cost
        //         }
        //     },
        //     {
        //         column: 'Total RC',
        //         width: 20,
        //         type: Number,
        //         format: '0.00',
        //         value: d => {
        //             return d.full_cost
        //         }
        //     }
        // ]
        await writeXlsxFile(excelData, {
            fileName: `listado_de_kardex.xlsx`,
            columns: [20, 20, 20, 30, 20, 10, 10, 15, 15, 20, 25, 25, 30].map(
                width => ({ width })
            ),
            fontSize: 10
        })
    }

    return <SimpleOption onClick={download}>Exportar en excel</SimpleOption>
}
