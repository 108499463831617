import useGet from '../../../../../hooks/useGet'
import { storagePage } from '../../../storage'
import {
    reqLogisticalCost4,
    tableAditionalNationalCostControl
} from '../storageStep3'
import { ResponseLogisticalCost } from '../../../types'
import { render } from 'redity'
import { Keys } from '../../../constants'
import { useEffect } from 'react'

export default function useTableAditionalNationalCosts() {
    const { logistical_declaration, version } = storagePage
    const { data, loading } = useGet<ResponseLogisticalCost[]>(
        reqLogisticalCost4.observer(
            `4>/v5.6/apis/logistical_declarations/${logistical_declaration.id}/logistical_costs?type_logistical_cost_source_id=4&version=${version}&sort=logistical_concept_id`,
            true
        ),
        []
    )

    function getReception(
        lcrs: ResponseLogisticalCost['logistical_cost_receptions']
    ) {
        return lcrs.map(lcr => lcr.reception.code).join(', ')
    }

    tableAditionalNationalCostControl.setList(
        data.result.map(lc => ({
            id: lc.id,
            document_number: lc.document_number,
            agent_name: lc.agent ? lc.agent.name : '',
            concept: lc.logistical_concept.name,
            type: lc.type_logistical_cost.name,
            type_logistica_cost_id: lc.type_logistical_cost_id,
            logistical_concept_id: lc.logistical_concept.id,
            cost_to_receipt: lc.in_all_receptions
                ? 'Todos'
                : getReception(lc.logistical_cost_receptions) || '-',
            original_cost: lc.original_cost,
            exchange_rate: lc.exchange_to_local,
            local_cost: lc.local_cost + lc.setting_local_cost,
            setting_local_cost: lc.setting_local_cost,
            symbol: lc.currency.symbol,
            has_files: lc.files.length > 0
        }))
    )

    useEffect(() => {
        if (!loading) {
            render(Keys.TOTAL_COST)
        }
    }, [loading])

    return {
        tableAditionalNationalCostControl,
        currentSymbol: storagePage.currentCurrency.symbol
    }
}
