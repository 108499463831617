function LocationCheck(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 15 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.445.868C3.463.868.222 4.11.222 8.092c0 5.03 6.284 12.045 6.555 12.344l.668.74.669-.74c.27-.299 6.555-7.315 6.555-12.344 0-3.982-3.242-7.224-7.224-7.224zm0 17.581c-1.968-2.357-5.417-7.16-5.417-10.357a5.422 5.422 0 015.417-5.418 5.422 5.422 0 015.418 5.418c0 3.458-3.837 8.452-5.418 10.357zm-2.97-9.996l1.589 1.598 4.154-4.153L11.49 7.18l-5.427 5.427-2.871-2.872 1.282-1.282z"
            />
        </svg>
    )
}

export default LocationCheck
