import ListFormControl from '../sections/ListForm/ListFormControl'
import FormEvents from './FormEvents'

export type ExpectControls =
    | FormEvents<any>
    | ListFormControl<any>
    | Array<FormEvents<any> | ListFormControl<any>>

export default function hasChanges(controls: ExpectControls) {
    function expect(control: FormEvents<any> | ListFormControl<any>) {
        if (control instanceof FormEvents) {
            return control.store.change()
        }

        if (control instanceof ListFormControl) {
            return !!control.list.find(d => d.store.change())
        }
    }

    if (Array.isArray(controls)) {
        for (const control of controls) {
            if (expect(control)) {
                return true
            }
        }
    } else {
        return expect(controls)
    }
}
