import classNames from 'classnames'
import { PropsCheckbox } from './types'

export default function Checkbox({ className, ...props }: PropsCheckbox) {
    return (
        <input
            {...props}
            type="checkbox"
            className={classNames(
                'checkbox checkbox-sm checkbox-accent',
                className
            )}
        />
    )
}
