import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList, storagePage } from '../storage'
import { GetReception, ReceptionData } from '../type'
import moment from 'moment'
import { useHistory } from 'react-router'
import Pdf from './Pdf'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import buildName from '../../../utilities/buildName'
import { viewPrint } from '../../../sections/ModalPrint'
import BtnActionTable from '../../../components/BtnActionTable'
import ModalReception from '../../../modals/ModalReception'

export default function Table() {
    const { push } = useHistory()

    function ViewerPdf({ data }: { data: GetReception }) {
        return <Pdf data={data} />
    }

    function LoadPDF({ reception }: { reception: ReceptionData }) {
        function handleClick() {
            viewPrint(`/v5.6/apis/receptions/${reception.id}`, () => ({
                Component: ViewerPdf
            }))
        }

        return (
            <BtnActionTable
                title="Imprimir"
                iconName="bs-printer"
                onClick={handleClick}
            />
        )
    }

    const math = useRule('CU229')
    return (
        <List control={controlList}>
            {(data, e) => (
                <tr key={data.id}>
                    <td>{e}</td>
                    <td>
                        <b
                            {...(math && {
                                className: 'underline cursor-pointer',
                                onClick: () =>
                                    view(
                                        `/v5.6/apis/receptions/${data.id}`,
                                        () => ({
                                            title: `DETALLE DE RC`,
                                            Component: ModalReception
                                        }),
                                        'lg'
                                    )
                            })}
                        >
                            {data.code}
                        </b>
                    </td>
                    <td>{data.type_reception.name}</td>
                    <td>
                        <p
                            className={
                                !data.document.user.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {buildName(
                                data.document.user.name,
                                data.document.user.lastname
                            )}
                        </p>
                    </td>
                    <td>
                        <p
                            className={
                                !data.company_warehouse.warehouse.is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {data.company_warehouse.warehouse.name}
                        </p>
                    </td>
                    <td>{data.purchase.provider.business_name}</td>
                    <td>{data.purchase.code}</td>
                    <td>{data.purchase.type_purchase.name}</td>
                    <td>
                        {moment(data.reception_date)
                            .tz(storagePage.timezone)
                            .format('DD/MM/YYYY')}
                    </td>
                    {/* <td>
                        {data.document.is_annuled ? (
                            <Badge color="error"> Anulado </Badge>
                        ) : (
                            ''
                        )}
                    </td> */}
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU227">
                                <BtnActionTable
                                    title="Editar"
                                    iconName="bs-pencil"
                                    disabled={data.document.has_accounting}
                                    onClick={() =>
                                        push(
                                            `/movements/purchase_receipts/${data.id}/edition`
                                        )
                                    }
                                />
                            </Rules>
                            <Rules cu="CU230">
                                <LoadPDF reception={data} />
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
