import { parseFile } from '../../../utilities/fileTools'
import { storagePage } from '../storage'
import { Form, Body } from '../types'

export function getBodyAdd(form: Form): Body {
    const type_license_ids = form.type_licenses.map(type_license =>
        parseInt(type_license.value)
    )
    return {
        company_id: parseInt(form.company.value),
        type_license_ids,
        code: form.code.trim(),
        name: form.name.trim(),
        issue_date: form.issue_date,
        ...(form.due_date_is_enabled && { due_date: form.due_date }),
        files: form.files.map(f => parseFile(f)),
        description: form.description
    }
}

export function getBodyEdit(form: Form): Body {
    const type_license_ids = form.type_licenses.map(type_license =>
        parseInt(type_license.value)
    )
    const files_to_deleted = storagePage.license.files.filter(
        l_file => !form.files.find(file => file.id && l_file.id === file.id)
    )

    const new_files = form.files.filter(f => !f.id)
    return {
        type_license_ids,
        code: form.code.trim(),
        name: form.name.trim(),
        issue_date: form.issue_date,
        ...(form.due_date_is_enabled && { due_date: form.due_date }),
        description: form.description,
        file_to_deleted_ids: files_to_deleted.map(f => f.id),
        new_files: new_files.map(f => parseFile(f))
    }
}

export function getBodyRenew(form: Form): Body {
    const type_license_ids = form.type_licenses.map(type_license =>
        parseInt(type_license.value)
    )
    return {
        type_license_ids,
        code: form.code.trim(),
        name: form.name.trim(),
        issue_date: form.issue_date,
        ...(form.due_date_is_enabled && { due_date: form.due_date }),
        files: form.files.map(f => parseFile(f)),
        description: form.description
    }
}
