import Dropdown from '../../../../components/Dropdown'
import Option from '../../../../components/Option'
import useStorage from '../../../../hooks/useStorage'
import { Option as OptionType } from '../../../../config/types/component'
import { setCompanySelected } from '../../../../utilities/controlStorage'
import { providerList } from '../../storage'

export function CompanyFilter() {
    const { props } = providerList.useFilter('company_id')

    const { companiesFromSession } = useStorage()

    function handleChange(option: OptionType) {
        props.onChange(option)
        const company = companiesFromSession.find(
            c => c.id.toString() === option.value
        )
        setCompanySelected(company)
    }

    return (
        <Dropdown
            {...props}
            label="Compañía"
            showLabel={false}
            onChange={handleChange}
        >
            {companiesFromSession.map(company => (
                <Option
                    key={company.id}
                    value={company.id.toString()}
                    label={company.name}
                    image={company.country.image_url}
                    strikeOut={!company.memory}
                />
            ))}
        </Dropdown>
    )
}
