import useGet from '../../../../../hooks/useGet'
import { storagePage } from '../../../storage'
import { ResponseLDR } from '../../../types'
import { reqRC, tableRCsControl } from '../storageStep2'

export default function useTableRC() {
    const { data, loading } = useGet<ResponseLDR[]>(
        reqRC.observer(
            `/v5.6/apis/logistical_declarations/${storagePage.logistical_declaration.id}/receptions?is_enabled=true&version=${storagePage.version}`,
            true
        ),
        []
    )

    if (!loading) {
        tableRCsControl.setList(
            data.result.map(ldr => ({
                id: ldr.id,
                rc: ldr.reception.code,
                reception_id: ldr.reception.id,
                oc: ldr.reception.purchases
                    .map(purchase => purchase.code)
                    .join(', '),
                provider_cost: ldr.purchase_original_cost,
                exchange_rc_to_dolar: ldr.exchange_rc_to_dolar,
                purchase_dolar_cost: ldr.purchase_dolar_cost,
                exchange_dolar_to_local: ldr.exchange_dolar_to_local,
                purchase_local_cost: ldr.purchase_local_cost,
                symbol: ldr.reception.currency.symbol,
                is_costing_estimate: !!ldr.reception.costing_estimate,
                purchase_id: ldr.reception.purchase_id
            }))
        )
    }

    storagePage.currentFobDolar = data.result.reduce(function (a, b) {
        return a + b.purchase_dolar_cost
    }, 0)

    storagePage.currentFobLocal = data.result.reduce(function (a, b) {
        return a + b.purchase_local_cost
    }, 0)

    return {
        tableRCsControl,
        fobDolar: storagePage.currentFobDolar,
        fobLocal: storagePage.currentFobLocal,
        localSymbol: storagePage.currentCurrency.symbol
    }
}
