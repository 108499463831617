import Button from '../../../components/Button'
import SearchField from '../../../components/SearchField'
import useSubmit from '../useSubmit'
import { controlList, storagePage } from '../storage'
import Rules from '../../../components/Rules'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import useStorage from '../../../hooks/useStorage'
import { Option } from '../../../config/types/component'
import { render, useRender } from 'redity'
import { Keys } from '../constants'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { setCompanySelected } from '../../../utilities/controlStorage'

function Company() {
    const { companiesFromSession } = useStorage()
    const { props } = controlList.useFilter('company_id')

    function handleChange(option: Option) {
        const company = companiesFromSession.find(
            _cp => _cp.id === parseInt(option.value)
        )
        const symbol = company?.local_currency?.symbol
        setCompanySelected(company)
        storagePage.company = { ...option, span: symbol }
        props.onChange(option)
        render(Keys.BUTTON_NEW)
    }

    return (
        <Dropdown
            label="Compañía"
            {...props}
            onChange={handleChange}
            clear={false}
            showLabel={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    name: company.name,
                    image_url: company.country.image_url,
                    id: company.id,
                    strikeOut: !company.is_enabled
                }))}
                label="name"
                image="image_url"
                strikeOut="strikeOut"
            />
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por nombre de bodega"
            size="auto"
            fullWidth={true}
        />
    )
}

export default function Filters() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Company />
                </FlexLeft>
                <FlexRight>
                    <ButtonNew />
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}

function ButtonNew() {
    useRender(Keys.BUTTON_NEW)
    const openModal = useSubmit()
    const { companiesFromSession } = useStorage()

    async function handleOpen() {
        openModal()
    }

    const currentCompany = companiesFromSession.find(
        company => company.id === parseInt(storagePage.company?.value)
    )

    return (
        <Rules cu="CU100">
            <Button
                size="sm"
                className="ml-2"
                onClick={() => handleOpen()}
                disabled={!currentCompany?.is_enabled}
            >
                Nuevo
            </Button>
        </Rules>
    )
}
