import { formProductLicense, storagePage, tableProducts } from '../storage'
import setParamsSearch from './setParamsSearch'

export default function build() {
    formProductLicense.init({
        products: storagePage.license.product_licenses
            .filter(pl => pl.memory)
            .map(p => ({
                value: p.product.id.toString(),
                label: p.product.name
            }))
    })

    const products = storagePage.license.product_licenses
        .filter(pl => pl.memory)
        .map(p => ({
            id: p.product_id,
            product_license_id: p.id,
            product_name: p.product.name,
            brand_name: p.product.brand.name,
            subcategory_name: p.product.subcategory.name,
            is_enabled: p.is_enabled
        }))

    tableProducts.setList(products)

    storagePage.products.sets(
        storagePage.license.product_licenses.map(
            product_license => product_license.product
        )
    )

    setParamsSearch(true)
}
