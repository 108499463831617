import { useLayoutEffect, useRef, useState } from 'react'
import { PropsPopover } from './types'

export default function usePopover(
    position: PropsPopover['element'],
    element: string
) {
    const iconRef = useRef<HTMLDivElement>()
    const tooltipRef = useRef<HTMLDivElement>()
    const observer = new MutationObserver(mutationList => {
        mutationList.forEach(mutation => {
            if (mutation.addedNodes.length) {
                setPosition()
            }
            if (mutation.removedNodes.length) {
                setPosition()
            }
        })
    })
    const observerOptions = {
        attributes: false,
        childList: true,
        subtree: true,
        characterData: false,
        attributeOldValue: false,
        characterDataOldValue: false
    }

    function cbTimeout() {
        const x = iconRef.current?.getBoundingClientRect().x
        const y = iconRef.current?.getBoundingClientRect().y
        const widthTooltip = tooltipRef.current?.clientWidth
        const heightTooltip = tooltipRef.current?.clientHeight
        const sizeContentIconVertical = iconRef.current?.clientHeight + 5
        const sizeContentIconHorizontal = iconRef.current?.clientWidth
        let topSize
        let rightSize
        if (position === 'bottom') {
            topSize = y + sizeContentIconVertical
            rightSize = x - widthTooltip / 2 + sizeContentIconHorizontal / 2
        }
        if (position === 'top') {
            topSize = y - heightTooltip
            rightSize = x - widthTooltip / 2 + sizeContentIconHorizontal / 2
        }

        if (position === 'bottom-right') {
            topSize = y + sizeContentIconVertical
            rightSize = x + sizeContentIconHorizontal - 5
        }
        if (position === 'bottom-left') {
            topSize = y + sizeContentIconVertical
            rightSize = x - widthTooltip
        }
        if (position === 'bottom-right-left') {
            topSize = y + sizeContentIconVertical
            rightSize = x - widthTooltip + sizeContentIconHorizontal + 5
        }
        if (tooltipRef.current) {
            tooltipRef.current.style.top = `${topSize}px`
            tooltipRef.current.style.left = `${rightSize}px`
        }
    }

    function setPosition() {
        if (!tooltipRef.current) return
        setTimeout(cbTimeout, 250)
    }
    useLayoutEffect(() => {
        if (element) {
            const toObserve = document.querySelector(element)
            observer.observe(toObserve, observerOptions)
        }
        setPosition()

        window.addEventListener('resize', setPosition)
        return () => {
            observer.disconnect()
            window.removeEventListener('resize', setPosition)
        }
    }, [])
    const [active, setActive] = useState(false)

    const handleMouseOverIcon = () => {
        if (active !== true) {
            setActive(true)
        }
    }
    let timer
    const handleMouseLeaveIcon = () => {
        timer = setTimeout(() => {
            setActive(false)
        }, 300)
    }
    const handleMouseEnterTooltip = () => {
        clearTimeout(timer)
    }
    const handleMouseLeaveTooltip = () => {
        setActive(false)
    }

    return {
        iconRef,
        handleMouseOverIcon,
        handleMouseLeaveIcon,
        tooltipRef,
        active,
        handleMouseEnterTooltip,
        handleMouseLeaveTooltip
    }
}
