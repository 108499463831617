import { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'
import './modal.css'
import { PropsModal } from './types'

export default function ModalPrint({
    open = false,
    size = 'lg',
    children,
    onSubmit = () => null
}: PropsModal) {
    return createPortal(
        <div
            className={classnames('modal p-0', {
                'modal-open': open,
                'modal--sm': size === 'sm',
                'modal--md': size === 'md',
                'modal--lg': size === 'lg'
            })}
        >
            <form className={classnames('modal-box', {})} onSubmit={onSubmit}>
                <div className="text-left">{open && children}</div>
            </form>
        </div>,
        document.querySelector('#modal-contain')
    )
}

export function ModalBody({ children }: { children: ReactNode }) {
    return (
        <div
            className="overflow-y-auto h-full -mx-6 -mt-6"
            style={{ maxHeight: '80vh' }}
        >
            <div className="">{children}</div>
        </div>
    )
}

export function ModalFooter({ children }: { children: ReactNode }) {
    return (
        <div className="flex justify-end pt-4 px-6 border-t -mx-6 -mb-2 px-6 mb-10">
            {children}
        </div>
    )
}
