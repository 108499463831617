import {
    ControlDivider,
    FormControl
} from '../../../../components/FormUtilities'
import NumeralField from '../../../../components/NumeralField'
import Toggle from '../../../../components/Toggle'
import { formInformation, formNorms } from '../../storage'

export default function Fields() {
    return (
        <>
            <LifeTime />
            <Refrigeration />
        </>
    )
}

function LifeTime() {
    const { props, getError } = formNorms.useInput('lifetime')
    const helper = getError()
    return (
        <FormControl
            label="Vida útil del producto"
            required={
                parseInt(formInformation.store().type_product.value) !== 2
            }
        >
            <NumeralField
                {...props}
                placeholder="Ingresar vida útil del producto en meses"
                max={999999}
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

function Refrigeration() {
    const { props } = formNorms.useCheck('refrigeration')
    return (
        <FormControl
            label="Refrigeración"
            labelComponent={<Toggle {...props} />}
        >
            <ControlDivider>
                <MinTemperature />
                <MaxTemperature />
            </ControlDivider>
        </FormControl>
    )
}

function MinTemperature() {
    const { props, getError } = formNorms.useInput('min_temperature')
    const helper = getError('ERROR-XXXXXXX')
    return (
        <NumeralField
            {...props}
            placeholder="Mín. temperatura"
            prefix="C°"
            helper={helper || props.helper}
            decimal={2}
            negative
            max={999.99}
        />
    )
}

function MaxTemperature() {
    const { props, getError } = formNorms.useInput('max_temperature')
    const helper = getError('ERROR-XXXXXXX')
    return (
        <NumeralField
            {...props}
            placeholder="Máx. temperatura"
            prefix="C°"
            decimal={2}
            negative
            helper={helper || props.helper}
            max={999.99}
        />
    )
}
