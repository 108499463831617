import { DRIVE_DOWNLOAD } from '../../config/app'
import { parseFileSize, getIconFile } from '../../utilities/fileTools'
import Icon from '../Icon'
import { PropsFileItem } from './types'

export default function FileItem({
    onDelete,
    name,
    driveId,
    size,
    lastModified
}: PropsFileItem) {
    return (
        <div className="upload-files--file">
            <div className="flex ">
                <img
                    src={getIconFile(name)}
                    className="mr-2 inline w-6 self-start"
                />
                <div className="self-center">
                    <p className="leading-4 text-sm break-all">{name}</p>
                    <span className="text-xs text-gray-400 leading-3">
                        {parseFileSize(size, 'kb')} / Modificado el{' '}
                        {lastModified}
                    </span>
                </div>
            </div>
            <div className="relative flex items-center">
                {driveId && (
                    <a
                        href={`${DRIVE_DOWNLOAD}${driveId}`}
                        className=""
                        download={name}
                        rel="noreferrer"
                    >
                        <Icon
                            name="download"
                            className="text-secondary mx-1"
                            pointer
                        />
                    </a>
                )}
                <Icon
                    name={`${driveId ? 'trash' : 'loading'}`}
                    pointer
                    className={`text-secondary mx-1 ${
                        !driveId && 'animate-spin'
                    } `}
                    onClick={onDelete}
                />
            </div>
        </div>
    )
}
