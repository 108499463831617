import moment from 'moment'
import Avatar from '../../../components/Avatar'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { tableLicense } from '../storage'
import InfoLicense from './InfoLicense'

export default function TableHistory() {
    function handleClick(id: number) {
        view(`/v5.6/apis/license_versions/${id}`, () => ({
            Component: InfoLicense,
            title: 'Información de la licencia'
        }))
    }

    return (
        <List control={tableLicense}>
            {license_version => (
                <tr key={license_version.id}>
                    <td>
                        <b
                            className="underline cursor-pointer"
                            onClick={() => handleClick(license_version.id)}
                        >
                            V{license_version.version}
                        </b>
                    </td>
                    <td>
                        {moment(
                            license_version.updated_at ||
                                license_version.created_at
                        ).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td>
                        <Avatar
                            src={license_version.user.avatar_url}
                            label={`${license_version.user.name} ${license_version.user.lastname}`}
                            link
                        />
                    </td>
                </tr>
            )}
        </List>
    )
}
