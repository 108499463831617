import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import useGet from '../../../../hooks/useGet'
import Warehouse from '../../../../models/Warehouse'
import { formConsumptionVoucher, reqWarehouse } from '../../storage'
import useCompany from './useCompany'

function Company() {
    const { props, companies, handleChange } = useCompany()

    return (
        <FormControl label="Compañía" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar compañía"
                label="Compañía"
                clear={false}
            >
                <IterateOptions data={companies} label="name" image="image" />
            </SelectTouch>
        </FormControl>
    )
}

function WarehouseField() {
    const { props } = formConsumptionVoucher.useSelect('warehouse')
    const { data, loading } = useGet<Warehouse[]>(
        reqWarehouse.observer(
            '/v5.6/apis/warehouses/company?is_enabled=true',
            true
        ),
        []
    )

    return (
        <FormControl label="Almacén" required>
            <SelectTouch
                {...props}
                loading={loading}
                placeholder="Seleccionar almacén"
                label="Almacén"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <Company />
            <WarehouseField />
        </>
    )
}
