import { controlList, formRoles } from './storage'
import Form from './View/Form'

export default function useSubmit(roleId?: number) {
    const { submit, validation, observer } = formRoles.useSubmit(
        roleId ? `/v5.6/apis/roles/${roleId}` : '/v5.6/apis/roles',
        {
            method: roleId ? 'put' : 'post',
            done: controlList
        }
    )

    validation(v => {
        v.name.minLength(2, 'El número de caracteres válidos es 2 - 180')
        v.name.maxLength(180, 'El número de caracteres válidos es 2 - 180')
        v.name.isEmpty('Se requiere ingresar el nombre')
        v.department_id.isEmpty('Se requiere seleccionar un departamento')
    })

    observer(data => ({
        name: data.name,
        ...(!roleId && {
            department_id: parseInt(data.department_id.value)
        })
    }))

    return formRoles.useModal(Form, {
        title: roleId ? 'Editar Rol' : 'Nuevo Rol',
        onSubmit: submit,
        size: 'sm',
        observer: data => {
            formRoles.setProps('department_id', { disabled: !!roleId }, true)
            return data
        },
        isCentered: true
    })
}
