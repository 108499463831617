import { Option } from '../../../../../config/types/component'
import useStorage from '../../../../../hooks/useStorage'
import { formDispatch } from '../../../storage'

export default function useTypeDispatch() {
    const { props } = formDispatch.useSelect('type_dispatch')
    const { typeDispatches } = useStorage()

    function handleChange(option: Option) {
        props.onChange(option)

        if (option.value === '0') {
            formDispatch.setProps('place_dispatch', { disabled: true }, false)
            formDispatch.setProps(
                'place_dispatch_text',
                { disabled: true },
                false
            )
            formDispatch.setProps('address', { disabled: true }, false)
        } else {
            formDispatch.setProps('place_dispatch', { disabled: false }, false)
            formDispatch.setProps(
                'place_dispatch_text',
                { disabled: false },
                false
            )
            formDispatch.setProps('address', {
                disabled: option.value !== '4'
            })
        }

        formDispatch.setValue('place_dispatch', null)
        formDispatch.setValue('place_dispatch_text', '')
        formDispatch.setValue('address', '')
        formDispatch.setValue('address_select', null)
    }

    return {
        props,
        typeDispatches,
        handleChange
    }
}
