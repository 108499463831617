import moment from 'moment'
import { render } from 'redity'
import useRule from '../../hooks/useRule'
import { formInputPart, productsControl, storagePage } from './storage'
import { BodyPost, BodyPut, Form } from './types'

export default function useSubmit(input_part_id?: number) {
    const { submit, validation, observer } = formInputPart.useSubmit(
        input_part_id
            ? `/v5.6/apis/input_parts/${input_part_id}`
            : '/v5.6/apis/input_part',
        {
            message: '¿Está seguro de guardar el parte de entrada?',
            done: '/movements/input_parts',
            method: input_part_id ? 'put' : 'post'
        }
    )
    const math = useRule('CU177')
    validation(v => {
        v.company.isEmpty('Se requiere seleccionar una compañía')
        v.warehouse.isEmpty('Se requiere seleccionar un almacén')
        v.stowage.isEmpty('Se requiere seleccionar una bodega')
        const { current_type_stowage_id_origin } = storagePage
        if (
            current_type_stowage_id_origin === 2 ||
            current_type_stowage_id_origin === 6 ||
            current_type_stowage_id_origin === 11
        ) {
            v.sub_stowage.isEmpty('Se requiere seleccionar una sub bodega')
        }
        v.tag.isEmpty('Se requiere seleccionar una etiqueta')
        if (math) {
            v.request_date.isEmpty(
                'Se requiere seleccionar una fecha de emisión'
            )
        }
        v.description
            .minLength(2, 'El número de caracteres válidos es 2 - 3000')
            .isEmpty('Se requiere ingresar la descripción')

        if (productsControl.list.length === 0) {
            formInputPart.store.helper(
                'products',
                'Se requiere como mínimo un producto'
            )
            render(formInputPart.keyRender, 'products')
            return false
        }

        return productsControl.test(_v => {
            _v.quantity
                .isMinor(1, 'El valor mínimo es 1')
                .isEmpty('Se requiere ingresar la cantidad de entrada')
            _v.average.isEmpty('Se requiere ingresar el valor de ingreso')
        })
    })

    observer<BodyPost | BodyPut>(f => getBody(f, input_part_id))

    return submit
}

function getBody(f: Form, input_part_id?: number): BodyPost | BodyPut {
    const isDelete = (logicalProductId: number, isRegistered: boolean) => {
        if (!isRegistered) {
            return {}
        }

        return {
            deleted: !storagePage.inputPart.input_part_products.find(
                cvp => cvp.operation.logical_product_id === logicalProductId
            )
        }
    }

    if (input_part_id) {
        return {
            label_id: parseInt(f.tag.value),
            description: f.description,
            input_part_products: productsControl.getDataList().map(lp => ({
                id: lp.id,
                logical_product_id: lp.logical_product_id,
                amount: parseInt(lp.quantity),
                average_unit_cost: Number(lp.average),
                ...isDelete(lp.logical_product_id, !!lp.id)
            }))
        }
    }
    const date = moment(f.request_date).tz(storagePage.timezone).parseZone()
    const newDate = moment
        .tz(date.format('YYYY-MM-DD'), storagePage.timezone)
        .add(1, 'minute')

    return {
        company_id: parseInt(f.company.value),
        warehouse_id: parseInt(f.warehouse.value),
        stowage_id: parseInt(f.stowage.value),
        minimal_stowage_id: f.sub_stowage
            ? parseInt(f.sub_stowage.value)
            : storagePage.current_stowage_origin_id,
        label_id: parseInt(f.tag.value),
        request_date: newDate.toISOString(true),
        description: f.description,
        input_part_products: productsControl.getDataList().map(lp => ({
            logical_product_id: lp.logical_product_id,
            amount: parseFloat(lp.quantity),
            average_unit_cost: Number(lp.average)
        }))
    }
}
