import { createContext } from 'react'
import { PropsTHead } from './types'

export const Context = createContext<
    Omit<PropsTHead, 'classname' | 'children'>
>({
    value: '',
    order: 'asc',
    onSort: () => null
})
