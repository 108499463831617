import classnames from 'classnames'
import { ReactNode } from 'react'
import Typography from '../Typography'
import './panel.css'
import { PropPanel, PropsPanelHeader } from './types'

export function PanelHeader({
    title,
    children,
    helper = null
}: PropsPanelHeader) {
    return (
        <div className="panel-header">
            <div className="flex items-center">
                <Typography type="header-2" className={`${!!helper && 'mr-2'}`}>
                    {title}
                </Typography>
                {helper}
            </div>
            <div>{children}</div>
        </div>
    )
}

export function PanelBody({
    children,
    className = ''
}: {
    children: ReactNode
    className?: string
}) {
    return <div className={classnames(`panel-body`, className)}>{children}</div>
}

export default function Panel({
    children,
    className = '',
    mode = 'normal'
}: PropPanel) {
    return (
        <div
            className={classnames(
                `panel`,
                { [`panel--${mode}`]: true },
                className
            )}
        >
            {children}
        </div>
    )
}
