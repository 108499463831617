import { controlList, storagePage } from '../storage'
import { SimpleOption } from '../../../components/Option'
import Dropdown from '../../../components/Dropdown'
import { Option as OptionType } from '../../../config/types/component'
import { IterateOptions } from '../../../components/FormUtilities'
import useStorage from '../../../hooks/useStorage'
import Pdf from './Pdf'
import Product from '../../../models/Product'
import loadHead from '../utilities/loadHead'
import moment from 'moment'
import Rules from '../../../components/Rules'
import { viewPrint } from '../../../sections/ModalPrint'
import DatePicker from '../../../components/Datepicker'
import { Keys } from '../constants'
import { useRender, render } from 'redity'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { setCompanySelected } from '../../../utilities/controlStorage'
import { buildPath } from '../../../sections/ModalPrint/utils'
import OptionExcel from './Export/OptionExcel'

function Company() {
    const { props } = controlList.useFilter('company_id')
    const { companiesFromSession } = useStorage()
    async function handleChange(option: OptionType) {
        const company = companiesFromSession.find(
            comp => comp.id === parseInt(option.value)
        )
        setCompanySelected(company)
        storagePage.currency = company.local_currency
        props.onChange(option)
        await loadHead()
        await props.onChange(option)
    }

    return (
        <Dropdown
            {...props}
            label="Compañía"
            clear={false}
            onChange={handleChange}
            showLabel={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    name: company.name,
                    image_url: company.country.image_url,
                    id: company.id,
                    disabled: !company.is_enabled
                }))}
                label="name"
                image="image_url"
                strikeOut="disabled"
            />
        </Dropdown>
    )
}

function ProductSelect() {
    const { data, props } = controlList.useFilter<Product>(
        'product_id',
        '/v5.6/apis/products'
    )

    async function handleChange(option: OptionType) {
        const product = data.find(item => item.id === parseInt(option.value))
        props.onChange(option)
        controlList.filter.setOption('product_id', {
            value: product.id.toString(),
            label: product.code,
            label2: product.code,
            label3: product.name
        } as any)
        await loadHead()
    }
    return (
        <Dropdown
            label="Producto de Kardex"
            {...props}
            onChange={handleChange}
            clear={false}
            fullWidth={true}
            className="justify-start"
        >
            <IterateOptions data={data} label="name" label2="code" />
        </Dropdown>
    )
}

function DateRange() {
    const { value } = controlList.useParam('end_date')

    async function changeHandler(isoString: string) {
        const date = moment(isoString)
            .tz(storagePage.timezone)
            .format('YYYY-MM-DD')
        controlList.filter.setParams(
            'start_date',
            moment
                .tz('2020-01-01 12:00', storagePage.timezone)
                .format('YYYY-MM-DD')
        )
        controlList.filter.setParams('end_date', date)
        controlList.render('end_date')
        render(Keys.HEADER)
        if (controlList.filter.params.product_id) {
            await controlList.load()
            controlList.render()
            loadHead()
        }
    }

    return (
        <div className="w-64">
            <DatePicker
                value={value}
                onChange={changeHandler}
                fullWidth={false}
                minimize
                className="mt-0 mb-0"
            />
        </div>
    )
}

function Export() {
    useRender(Keys.EXPORT)
    function handleClick() {
        const path = buildPath('/v5.6/apis/kardex', [
            ['company_id', controlList.filter.getParam('company_id')],
            ['product_id', controlList.filter.getParam('product_id')],
            ['start_date', controlList.filter.getParam('start_date')],
            ['end_date', controlList.filter.getParam('end_date')]
        ])
        viewPrint(path, () => ({
            Component: Pdf
        }))
    }

    return <SimpleOption onClick={handleClick}>Exportar en .pdf</SimpleOption>
}

export default function Filters() {
    return (
        <SectionFiltersGrid>
            <ProductSelect />
            <FlexBox>
                <FlexLeft>
                    <Company />
                    <DateRange />
                </FlexLeft>
                <FlexRight>
                    <Dropdown label="Exportar" icon="download" clear={false}>
                        {/* <Rules cu="CU130"> */}
                        <OptionExcel />
                        <Export />
                        {/* </Rules> */}
                    </Dropdown>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
