import Badge from '../../../components/Badge'
import Typography from '../../../components/Typography'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { GetStowages, GetStowageView } from '../../../types/stowages'
import { controlList, storagePage } from '../storage'
import useSubmit from '../useSubmit'
import ActiveDeactivate from './ActiveDeactivate'
import StowageView from './StowageView'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import BtnActionTable from '../../../components/BtnActionTable'

function Edit({ stowage }: { stowage: GetStowages }) {
    const openModal = useSubmit(stowage.id)

    return (
        <Rules cu="CU101">
            <BtnActionTable
                title="Editar"
                iconName="bs-pencil"
                onClick={openModal}
                disabled={!stowage.company.is_enabled}
            />
        </Rules>
    )
}

export default function Table() {
    return (
        <List control={controlList}>
            {(data, i) => (
                <tr key={data.id}>
                    <td>{i}</td>
                    <td>
                        <Name title={data.name} stowage_id={data.id} />
                    </td>
                    <td>{data.type_stowage.name}</td>
                    <td>{data.type_stowage.is_inventory ? 'Sí' : 'No'}</td>
                    <td>
                        {storagePage.company.span}{' '}
                        {buildNumberFormat(data.stowage_value)}
                    </td>
                    <td>
                        {data.is_enabled ? (
                            <Badge color="success">Activo</Badge>
                        ) : (
                            <Badge color="error">Inactivo</Badge>
                        )}
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Edit stowage={data} />
                            <ActiveDeactivate stowage={data} />
                            <Rules cu="CU102">
                                <Delete
                                    path={`/v5.6/apis/stowages/${data.id}`}
                                    control={controlList}
                                    disabled={!data.company.is_enabled}
                                >
                                    Esta acción es irreversible. ¿Estás seguro
                                    de eliminar la bodega{' '}
                                    <b className="text-primary">{data.name}</b>?
                                </Delete>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}

function Name({ title, stowage_id }: { title: string; stowage_id: number }) {
    const math = useRule('CU098')

    return (
        <div
            {...(math && {
                className: 'font-univers-bold mb-1 underline cursor-pointer',
                onClick: () => {
                    view<GetStowageView>(
                        `/v5.6/apis/stowages/${stowage_id}`,
                        warehouse => ({
                            title: warehouse.name,
                            Component: StowageView
                        }),
                        'sm'
                    )
                }
            })}
        >
            <Typography type="paragraph" className="font-bold">
                {title}
            </Typography>
        </div>
    )
}
