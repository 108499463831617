import useGet from '../../../../../hooks/useGet'
import { storagePage } from '../../../storage'
import {
    reqLogisticalCost2,
    tableNationalizationControl
} from '../storageStep2'
import { ResponseLogisticalCost } from '../../../types'
import {
    LOGISTICAL_CONCEPT__DUTY,
    LOGISTICAL_CONCEPT__ISC,
    LOGISTICAL_CONCEPT__IVA,
    LOGISTICAL_CONCEPT__PENALIZATION,
    LOGISTICAL_CONCEPT__RATE_C_IVA,
    LOGISTICAL_CONCEPT__RATE_S_IVA
} from '../../../../../config/constants'

export default function useTableNationalization() {
    const { data, loading } = useGet<ResponseLogisticalCost[]>(
        reqLogisticalCost2.observer(
            `2>/v5.6/apis/logistical_declarations/${storagePage.logistical_declaration.id}/logistical_costs?type_logistical_cost_source_id=2&version=${storagePage.version}&sort=logistical_concept_id`,
            true
        ),
        []
    )

    function getReception(
        lcrs: ResponseLogisticalCost['logistical_cost_receptions']
    ) {
        return lcrs.map(lcr => lcr.reception.code).join(', ')
    }

    let dolar = 0
    let local = 0

    let iva_dolar = 0
    let iva_local = 0

    if (!loading) {
        tableNationalizationControl.setList(
            data.result.map(lc => {
                if (
                    lc.logistical_concept_id === LOGISTICAL_CONCEPT__DUTY ||
                    lc.logistical_concept_id ===
                        LOGISTICAL_CONCEPT__PENALIZATION ||
                    lc.logistical_concept_id ===
                        LOGISTICAL_CONCEPT__RATE_S_IVA ||
                    lc.logistical_concept_id ===
                        LOGISTICAL_CONCEPT__RATE_C_IVA ||
                    lc.logistical_concept_id === LOGISTICAL_CONCEPT__ISC
                ) {
                    dolar = dolar + lc.original_cost
                    local = local + lc.local_cost + lc.setting_local_cost
                }

                if (lc.logistical_concept_id === LOGISTICAL_CONCEPT__IVA) {
                    iva_dolar = iva_dolar + lc.original_cost
                    iva_local =
                        iva_local + lc.local_cost + lc.setting_local_cost
                }

                return {
                    id: lc.id,
                    document_number: lc.document_number,
                    agent_name: lc.agent ? lc.agent.name : '',
                    concept: lc.logistical_concept.name,
                    type: lc.type_logistical_cost.name,
                    logistical_concept_id: lc.logistical_concept.id,
                    cost_to_receipt: lc.in_all_receptions
                        ? 'Todos'
                        : getReception(lc.logistical_cost_receptions),
                    original_cost: lc.original_cost,
                    exchange_rate: lc.exchange_to_local,
                    setting_local_cost: lc.setting_local_cost,
                    local_cost: lc.local_cost + lc.setting_local_cost,
                    symbol: lc.currency.symbol,
                    has_files: lc.files.length > 0
                }
            })
        )
    }

    return {
        tableNationalizationControl,
        currentSymbol: storagePage.currentCurrency.symbol,
        cifDutyRateIscDolar: storagePage.currentCifDolar + dolar,
        cifDutyRateIscLocal: storagePage.currentCifLocal + local,
        iva_dolar,
        iva_local
    }
}
