import Rules from '../../../components/Rules'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { LicenseData } from '../types'
import { controlList } from '../storage'

interface PropsActiveDeactive {
    license: LicenseData
}

export default function ActiveDeactivate({ license }: PropsActiveDeactive) {
    return (
        <Rules cu={license.is_enabled ? 'CU055' : 'CU056'}>
            <ActivateDeactivate
                isEnabled={license.is_enabled}
                control={controlList}
                body={{
                    license_id: license.id
                }}
                disabled={!license.company.memory}
            >
                <Active
                    path="/v5.6/apis/licenses/activate"
                    label="Activar licencia"
                >
                    La licencia regresará a estar activa. ¿Está seguro de
                    reactivar la licencia{' '}
                    <b className="text-primary">{license.name}</b>?
                </Active>
                <Deactive
                    path="/v5.6/apis/licenses/deactivate"
                    label="Desactivar licencia"
                >
                    La relación con productos se mantendrá, la licencia se
                    comportará como vencida. ¿Está seguro de desactivar licencia{' '}
                    <b className="text-primary">{license.name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
