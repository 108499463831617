import Icon from '../../../../components/Icon'
import Typography from '../../../../components/Typography'
import { TableList } from '../../../../sections/ListForm'
import FormEvents from '../../../../utilities/FormEvents'
import { Popup } from '../../../../utilities/popup'
import { tableProductsControl } from '../../storage'
import ReactPopover from '../../../../components/ReactPopover'
import useProduct from './useProduct'
import moment from 'moment'
import BtnActionTable from '../../../../components/BtnActionTable'

export default function Table() {
    return (
        <div style={{ minHeight: '400px' }}>
            <TableList control={tableProductsControl} scrollHeight={350}>
                {(_, control, e) => (
                    <Row key={e} control={control} index={e - 1} />
                )}
            </TableList>
        </div>
    )
}

function Row({ control, index }: { control: FormEvents<any>; index: number }) {
    const data = control.store()

    async function handleDelete() {
        const result = await Popup.delete(
            'Eliminar producto',
            <>
                Se removerá el producto de la lista. ¿Estás seguro de eliminar
                el producto{' '}
                <b>
                    {
                        data.product_data.product_company.product_country
                            .product.description
                    }
                </b>
                ?
            </>
        )
        if (result) {
            tableProductsControl.del(index)
        }
    }
    const { getError } = control.useValue('product_id')

    const helper = getError(
        'ERROR-0000315',
        'El producto no se puede comprar en la compañía',
        `products.${index}.product_id`
    )

    return (
        <tr className={helper && 'bg-red-100'}>
            <td>{index + 1}</td>
            <td>
                <Typography type="paragraph" className="font-bold">
                    {
                        data.product_data.product_company.product_country
                            .product.description
                    }
                </Typography>
                <Typography type="small">
                    {
                        data.product_data.product_company.product_country
                            .product.code
                    }
                </Typography>
            </td>
            <td>
                <Typography type="paragraph" className="font-bold">
                    {data.product_data.provider_description}
                </Typography>
                <Typography type="small">
                    {data.product_data.provider_code}
                </Typography>
            </td>
            <td>{data.quantity}</td>
            <td>
                {data.product_data.product_company.product_country.product
                    .alert_licenses.expired_licenses.length === 0 &&
                data.product_data.product_company.product_country.product
                    .alert_licenses.licenses_to_expired.length === 0 ? (
                    '-'
                ) : (
                    <ReactPopover
                        component={
                            <Icon name="warning" className="text-error" />
                        }
                    >
                        {data.product_data.product_company.product_country
                            .product.alert_licenses.expired_licenses.length !==
                            0 && (
                            <>
                                <b>Permiso(s) expirado(s)</b>
                                {data.product_data.product_company.product_country.product.alert_licenses.expired_licenses.map(
                                    (license, i) => (
                                        <p key={i}>{`${license.name}: ${moment(
                                            license.due_date
                                        ).format('DD/MM/YYYY')}`}</p>
                                    )
                                )}
                            </>
                        )}
                        {data.product_data.product_company.product_country
                            .product.alert_licenses.licenses_to_expired
                            .length !== 0 && (
                            <>
                                <b>Permiso(s) por expirar</b>
                                {data.product_data.product_company.product_country.product.alert_licenses.licenses_to_expired.map(
                                    (license, _i) => (
                                        <p key={_i}>{`${license.name}: ${moment(
                                            license.due_date
                                        ).format('DD/MM/YYYY')}`}</p>
                                    )
                                )}
                            </>
                        )}
                    </ReactPopover>
                )}
            </td>
            <td>
                <div className="group-btn-options">
                    <Edit index={index} />
                    <BtnActionTable
                        title="Eliminar"
                        iconName="bs-trash"
                        onClick={handleDelete}
                    />
                    {helper && (
                        <ReactPopover
                            component={
                                <Icon
                                    className="text-red-600 ml-2"
                                    name="warning"
                                />
                            }
                        >
                            <p>{helper}</p>
                        </ReactPopover>
                    )}
                </div>
            </td>
        </tr>
    )
}

function Edit({ index }: { index: number }) {
    const open = useProduct(index)

    return <BtnActionTable title="Editar" iconName="bs-pencil" onClick={open} />
}
