import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import useCancel from '../../hooks/useCancel'
import useStorage from '../../hooks/useStorage'
import {
    controlTabs,
    storagePage,
    formInformation,
    tableProductsControl,
    reqStowageDestiny
} from './storage'

export default function useInitial() {
    const { companiesFromSession } = useStorage()
    const [pageState, setPageState] = useState<StateCase>(StateCase.LOADING)
    const { onClick } = useCancel(
        [formInformation, tableProductsControl],
        '/requisitions'
    )

    function load() {
        const current_company = formInformation.store().company

        if (current_company === null) {
            const company = companiesFromSession[0]
            formInformation.setValue('company', {
                label: company.name,
                image: company.country.image_url,
                value: company.id.toString()
            })
            reqStowageDestiny.setQuery('company_id', company.id)
        } else {
            reqStowageDestiny.setQuery('company_id', current_company.value)
        }

        formInformation.setProps('sub_stowage_destination', {
            disabled: true
        })
        formInformation.setProps('catalogue_company', {
            disabled: true
        })

        setPageState(StateCase.SUCCESS)
    }

    useEffect(() => {
        load()
        return () => {
            storagePage.requisition = null
            storagePage.requisitionId = null
            storagePage.mode = 'creation'
            storagePage.step1Done = false

            controlTabs.value = 'step1'

            formInformation.init()
            tableProductsControl.setList([])
        }
    }, [])

    return {
        pageState,
        cancel: onClick
    }
}
