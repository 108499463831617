import Checkbox from '../../../../../components/Checkbox'
import { controlList, reqSummaryData } from '../../../storage'
import { render } from 'redity'
import { Keys } from '../../../constants'

export default function CheckboxOnlyClients() {
    const { value, setParam } = controlList.useParam('only_clients')
    async function onChange() {
        const temp = value === 'true'
        setParam((!temp).toString())
        reqSummaryData.setQuery('only_clients', (!temp).toString())
        await reqSummaryData.load()
        render(Keys.HEADER)
    }

    return (
        <div className="mr-2">
            <label className="flex items-center text-neutral text-xs underline">
                <Checkbox checked={value === 'true'} onChange={onChange} />{' '}
                <div className="ml-1">Solo bodega cliente</div>
            </label>
        </div>
    )
}
