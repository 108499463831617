import { GetWarehouse } from '../../../types/warehouse'
import buildCompanyName from '../../../utilities/buildCompanyName'
import { formWarehouse } from '../storage'

export default function initialEdition(warehouse: GetWarehouse) {
    formWarehouse.init({
        address: warehouse.address.address,
        code: warehouse.code,
        comment: warehouse.comment,
        image_url: warehouse.image_url,
        companies: warehouse.companies
            .filter(company => company.is_enabled)
            .map(warehouse_company => ({
                value: warehouse_company.company.id.toString(),
                label: buildCompanyName(warehouse_company.company.name),
                image: warehouse_company.company.country.image_url
            })),
        country: {
            value: warehouse.country.id.toString(),
            label: warehouse.country.name,
            image: warehouse.country.image_url
        },
        latLng: {
            lat: warehouse.latitude,
            lng: warehouse.longitude
        },
        name: warehouse.name
    })
    formWarehouse.setParams('companies', {})
}
