import Icon from '../../../../components/Icon'
import useRemove from '../../../../hooks/useRemove'
import { TableList } from '../../../../sections/ListForm'
import { tablePaymentConditions } from '../../storage'
import { TablePaymentConditionData } from '../../types'
import usePaymentCondition from './usePaymentCondition'

function Edit({
    index,
    data
}: {
    index: number
    data: TablePaymentConditionData
}) {
    const open = usePaymentCondition(index)
    return (
        <Icon
            name="bs-pencil"
            className="mr-2"
            pointer
            onClick={() =>
                open({
                    payment_condition: {
                        value: data.payment_condition.id.toString(),
                        label: data.payment_condition.name
                    },
                    company: {
                        value: data.company.id.toString(),
                        label: data.company.name
                    },
                    description: data.description
                })
            }
        />
    )
}

export default function TablePaymentConditions() {
    const del = useRemove(tablePaymentConditions)
    return (
        <TableList control={tablePaymentConditions} scrollHeight={250}>
            {(data, _, e) => (
                <tr key={e}>
                    <td>{data.payment_condition.name}</td>
                    <td>
                        <div className="flex">
                            <img
                                src={data.company.image}
                                className="w-8 mr-2"
                            />
                            <p>{data.company.name}</p>
                        </div>
                    </td>
                    <td>{data.description}</td>
                    <td>
                        <Edit data={data} index={e - 1} />
                        <Icon
                            name="bs-trash"
                            pointer
                            onClick={() => del(e - 1)}
                        />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
