/**
 * @deprecated use StateCase
 */
export enum PageState {
    LOADING,
    SUCCESS,
    ERROR
}

export enum StateCase {
    LOADING,
    SUCCESS,
    ERROR
}

export enum PageMode {
    EDITION,
    READ_ONLY,
    CREATION
}
