import Initial from '../../../components/Initial'
import Header from './Header'
import { useParams } from 'react-router'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import { controlTabs, storagePage } from '../storage'
import useInitial from '../useInitial'

controlTabs.setSection('Información General', Step1)
controlTabs.setSection('Configuraciones', Step2)
controlTabs.setSection('Direcciones Despacho/Retiro', Step3)

export default function Provider() {
    const { pageState, cancel } = useInitial()
    const { provider_id } = useParams<{ provider_id?: string }>()
    const value = controlTabs.useStep(to => {
        if (storagePage.mode === 'edition') return true

        if (to === 'step1') return true
        if (to === 'step2' && storagePage.step1Done) return true
        if (to === 'step3' && storagePage.step2Done) return true

        return false
    })

    return (
        <Initial pageState={pageState}>
            <Header onCancel={cancel} />
            <controlTabs.SectionTabs
                value={value}
                type={provider_id ? 'tab' : 'step'}
                className="max-w-screen-2xl"
            />
        </Initial>
    )
}
