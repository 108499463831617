import { InitialData } from '../../../config/load'
import { GetProduct } from '../../../types/product'
import { parseDriveFile } from '../../../utilities/fileTools'
import {
    storagePage,
    formInformation,
    formNorms,
    tableCompaniesControl,
    tablePermitsControl,
    tableProductsControl,
    tableTaxesControl,
    tableFiscalCodeControl
} from '../storage'
import setParamsSearchProducts from './setParamsSearchProducts'

export default function prepareForEdition(
    product: GetProduct,
    countries: InitialData['countries']
) {
    /**
     * Step 1
     */
    formInformation.init({
        name: product.name,
        manufacturer_code: product.manufacturer_code,
        presentation: product.presentation || '',
        rank: product.rank || '',
        model: product.model || '',
        quantity: product.quantity || '',
        brand: {
            label: product.brand.name,
            value: product.brand.id.toString()
        },
        product_files: product.files.map(f => parseDriveFile(f)),
        type_product: {
            label: product.type_product.name,
            value: product.type_product.id.toString()
        },
        type_communications: product.product_type_communications
            .filter(ptc => ptc.memory)
            .map(ptc => ({
                label: ptc.type_communication.name,
                value: ptc.type_communication.id.toString()
            })),
        hazardous: product.is_hazardous,
        precursor: product.is_precursor,
        description_generate: product.description,
        category: {
            label: product.subcategory.category.name,
            value: product.subcategory.category_id.toString()
        },
        subcategory: {
            label: product.subcategory.name,
            value: product.subcategory.id.toString()
        },
        code: product.code
    })

    if (!product.brand.is_enabled) {
        formInformation.setProps('brand', { disabled: true })
    } else {
        formInformation.setProps('brand', { disabled: false })
    }

    /**
     * Step 2
     */

    const min_temperature = product.min_temperature
        ? product.min_temperature.toString()
        : ''

    const max_temperature = product.max_temperature
        ? product.max_temperature.toString()
        : ''

    formNorms.init({
        lifetime: product.lifetime > 0 ? product.lifetime.toString() : '',
        refrigeration: product.refrigeration,
        min_temperature,
        max_temperature,
        air_transport: product.air_transport,
        marine_transport: product.marine_transport,
        ground_transport: true,
        storable_products: product.non_storable_products.map(nsprod => ({
            value: nsprod.id.toString(),
            label: nsprod.name
        }))
    })

    const nonStorabledProducts = product.non_storable_products.map(nsprod => ({
        id: nsprod.id,
        name: nsprod.name,
        code: nsprod.code,
        category_name: nsprod?.subcategory.category.name,
        subcategory_name: nsprod.subcategory.name
    }))

    tableProductsControl.setList(nonStorabledProducts)
    storagePage.products.sets(product.non_storable_products)
    setParamsSearchProducts()

    /**
     * Step 3
     */

    tableCompaniesControl.setList(
        product.product_countries
            .map(pc =>
                pc.product_companies.map(pcom => ({
                    company_id: pcom.company_id,
                    can_sell: pcom.can_sell,
                    can_buy: pcom.can_buy,
                    image: pcom.company.country.image_url,
                    company_name: pcom.company.name,
                    is_enabled: pcom.company.is_enabled
                }))
            )
            .flat()
    )

    tableFiscalCodeControl.setList(
        product.product_countries
            .map(pc =>
                pc.product_companies.map(pcom => ({
                    company_id: pcom.company_id,
                    country_image: pcom.company.country.image_url,
                    company_name: pcom.company.name,
                    is_enabled: pcom.company.is_enabled,
                    fiscal_code: pcom.fiscal_code || ''
                }))
            )
            .flat()
    )

    tableTaxesControl.setList(
        product.product_countries.map(product_country => {
            const country = countries.find(
                _country => product_country.country_id === _country.id
            )

            return {
                country_id: country.id,
                tax: (product_country.tax || '').toString(),
                image: country.image_url,
                country_name: country.name
            }
        })
    )

    tablePermitsControl.setList(
        product.product_countries.map(pc => {
            const country = countries.find(
                _country => pc.country_id === _country.id
            )

            return {
                country_id: country.id,
                nationalization_permission: pc.nationalization_permission,
                shipment_permission: pc.shipment_permission,
                ministry_health_permission: pc.ministry_health_permission,
                new_files: pc.files.map(f => parseDriveFile(f)),
                image: country.image_url,
                country_name: country.name
            }
        })
    )
}
