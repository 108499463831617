import BtnActionTable from '../../../components/BtnActionTable'
import Rules from '../../../components/Rules'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { controlList } from '../storage'
import { GroupPresentationData } from '../types'
import useSubmit from '../useSubmit'

function Edit({
    groupPresentation
}: {
    groupPresentation: GroupPresentationData
}) {
    const openModal = useSubmit(groupPresentation.id)

    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={openModal}
        />
    )
}

export default function TablePresentations() {
    return (
        <List control={controlList}>
            {(groupPresentation, i) => (
                <tr key={groupPresentation.id}>
                    <td>{i}</td>
                    <td>{groupPresentation.name}</td>
                    <td>{groupPresentation.n_presentations}</td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU069">
                                <Edit groupPresentation={groupPresentation} />
                            </Rules>
                            <Rules cu="CU070">
                                <Delete
                                    path={`/v5.6/apis/group_presentations/${groupPresentation.id}`}
                                    control={controlList}
                                >
                                    ¿Está seguro de eliminar la presentación{' '}
                                    <b className="text-primary">
                                        {groupPresentation.name}
                                    </b>
                                    ?
                                </Delete>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
