import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import { FormControls } from '../../../components/FormUtilities'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'
import Header from './Header'
import useInitial from '../useInitial'

export default function License() {
    const { pageState, title } = useInitial()

    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title={title} />
                    <PanelBody>
                        <FormControls>
                            <Fields1 />
                            <Fields2 />
                            <Fields3 />
                        </FormControls>
                    </PanelBody>
                </Panel>
            </div>
            <Header />
        </Initial>
    )
}
