import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'
import SelectTouch from '../../../../components/SelectTouch'
import useStorage from '../../../../hooks/useStorage'
import { formInfo } from '../../storage'

function Name() {
    const { props, getError } = formInfo.useInput('business_name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un proveedor con el mismo nombre'
    )

    return (
        <FormControl label="Razón social" required>
            <InputField
                {...props}
                placeholder="Ingresar razón social"
                helper={props.helper || helper}
            />
        </FormControl>
    )
}

function Commercial() {
    const { props, getError } = formInfo.useInput('commercial_name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un proveedor con el mismo nombre comercial'
    )
    return (
        <FormControl label="Nombre comercial" required>
            <InputField
                {...props}
                helper={props.helper || helper}
                placeholder="Ingresar nombre comercial"
            />
        </FormControl>
    )
}

function TypeIdentification() {
    const { props } = formInfo.useSelect('type_tax_identification')
    const { typeTaxIdentifications } = useStorage()
    return (
        <FormControl label="Tipo de identificación Fiscal" required>
            <SelectTouch
                {...props}
                placeholder="Seleccionar tipo de identificación fiscal"
                label="Tipo de identificación fiscal"
            >
                <IterateOptions data={typeTaxIdentifications} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function Document() {
    const { props } = formInfo.useInput('tax_identification_number')
    return (
        <FormControl label="Documento de identificación fiscal" required>
            <InputField
                {...props}
                placeholder="Ingresar documento de identificación fiscal"
            />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <Name />
            <Commercial />
            <TypeIdentification />
            <Document />
        </>
    )
}
