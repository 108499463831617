import { useHistory } from 'react-router-dom'
import { useRender } from 'redity'
import Button from '../../../components/Button'
import DataTable, { Caption, TBody, THead } from '../../../components/DataTable'
import Typography from '../../../components/Typography'
import { Keys } from '../constant'
import { storagePage } from '../storage'
import ReportState from './ReportState'

export default function ImportResult() {
    return (
        <>
            <ReportState />
            <Typography className="mb-2" type="header-3">
                Reporte de errores
            </Typography>
            <DataTable scrollHeight={750}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>Fila</th>
                        <th>Error</th>
                    </tr>
                </THead>
                <ReportsBody />
            </DataTable>
            <ButtonBack />
        </>
    )
}

function ReportsBody() {
    useRender(Keys.TABLE_RESULT)
    const reports = storagePage.reports

    return (
        <TBody>
            {reports.length === 0 && (
                <Caption colSpan={3}>Sin errores encontrados</Caption>
            )}
            {reports.map((report, i) => (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{report.row}</td>
                    <td>{report.error}</td>
                </tr>
            ))}
        </TBody>
    )
}

function ButtonBack() {
    useRender(Keys.BUTTON_BACK)
    const { push } = useHistory()
    const isDone = storagePage.importState === 'done'

    return (
        <>
            {isDone && (
                <Button
                    className="mt-10 mx-auto block"
                    color="success"
                    onClick={() => push('/configuration/products')}
                >
                    Regresar al listado
                </Button>
            )}
        </>
    )
}
