import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import FilterLicenses from './FilterLicenses'
import Table from './Table'

export default function Licenses() {
    const state = useInitial()

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <FilterLicenses />
                    <Table />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
