import { Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'
import { controlTabs, storagePage } from '../../storage'
import { MinimalStowageItem, RequisitionView } from '../../types'

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    subrow: {
        width: '50%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    grouprow: {
        width: '50%',
        flexDirection: 'column'
    },
    header2: {
        width: '50%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header1: {
        width: '25%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    headerFull: {
        width: '100%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    itemFull: {
        width: '100%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textTransform: 'uppercase'
    },
    item2: {
        width: '50%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textTransform: 'uppercase'
    },
    item1: {
        width: '25%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textTransform: 'uppercase'
    }
})

export default function Table1({ data }: { data: RequisitionView }) {
    const value = controlTabs.value
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.header1}>SOLICITANTE</Text>
                <Text style={styles.header1}>FECHA DE APROBACIÓN</Text>
                <Text style={styles.header2}>BODEGA DESTINO</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.item1}>
                    {`${data.document.requesting_user.name} ${data.document.requesting_user.lastname}`}
                </Text>
                <Text style={styles.item1}>
                    {moment(data.document.request_date)
                        .tz(storagePage.timezone)
                        .format('DD/MM/YYYY')}
                </Text>
                <Text style={styles.item2}>
                    {data.minimal_stowage.stowage.name}
                </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.header2}>SUB BODEGA</Text>
                <Text style={styles.header2}>CLIENTE</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.item2}>
                    {getSubStowageName(data.minimal_stowage)}
                </Text>
                <Text style={styles.item2}>
                    {getClientName(data.minimal_stowage)}
                </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.header2}>PROVEEDOR</Text>
                <Text style={styles.header2}>ESTADO</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.item2}>
                    {data.provider_company.provider.business_name}
                </Text>
                <Text style={styles.item2}>
                    {value === 'step1' ? 'Abierto' : 'Cerrado'}
                </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.headerFull}>COMENTARIO</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.itemFull}>
                    {data.document.description || '-'}
                </Text>
            </View>
        </>
    )
}

function getSubStowageName(minimal_stowage: MinimalStowageItem) {
    if (minimal_stowage.order_sub_stowage) {
        return minimal_stowage.order_sub_stowage.order.number
    } else if (minimal_stowage.gp_sub_stowage) {
        return minimal_stowage.gp_sub_stowage.purchase.code
    } else {
        return '-'
    }
}

function getClientName(minimal_stowage: MinimalStowageItem) {
    if (minimal_stowage.order_sub_stowage) {
        return minimal_stowage.order_sub_stowage.order.client.name
    } else {
        return '-'
    }
}
