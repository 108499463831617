import { useRender } from 'redity'
import Badge from '../../components/Badge'
import ControlList from '../../utilities/Control/ControlList'

interface PropsBadges {
    control: ControlList<any>
}

export default function Badges({ control }: PropsBadges) {
    useRender(control.keyRender, control.indexs.BADGES)
    const filters = control.filter.getBadges()

    async function handleClose(key: string) {
        control.filter.deleteFilter(key)
        control.loading = true
        control.onDeleteComponentBadge(key)
        control.renderMain()
        await control.done()
        control.renderMain()
    }

    return (
        <div className={filters.length > 0 ? 'my-2' : 'mb-2'}>
            {filters.map((filter, i) => (
                <Badge
                    key={i}
                    {...(filter.remove
                        ? { onClose: () => handleClose(filter.key) }
                        : {})}
                    className="mr-2"
                >
                    {filter.label}
                </Badge>
            ))}
        </div>
    )
}
