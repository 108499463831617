import { formContact, tableContacts } from '../storage'

export function addContact() {
    const { name, position, email, phone_number } = formContact.store()
    const list = tableContacts.list.map(d => d.store())
    const hasMain = !!tableContacts.list.find(d => d.store().is_main)
    tableContacts.setList([
        ...list,
        {
            name: name.trim(),
            position: position.trim(),
            phone_number: phone_number,
            email: email.trim(),
            is_main: !hasMain
        }
    ])
}

export function updateContact(index: number) {
    const { name, position, email, phone_number } = formContact.store()
    tableContacts.setList(
        tableContacts.list.map((d, i) => {
            if (i === index) {
                return {
                    ...d.store(),
                    name: name.trim(),
                    position: position.trim(),
                    phone_number: phone_number,
                    email: email.trim()
                }
            }

            return d.store()
        })
    )
}

export function emailExists(email: string, index?: number) {
    let i = 0
    for (const data of tableContacts.getDataList()) {
        if (data.email.toLowerCase() === email.trim().toLowerCase()) {
            if (index === i) {
                i++
                continue
            }
            return true
        }
        i++
    }
    return false
}

export function nameExists(name: string, index?: number) {
    let i = 0
    for (const data of tableContacts.list) {
        if (data.store().name.toLowerCase() === name.trim().toLowerCase()) {
            if (index === i) {
                i++
                continue
            }
            return true
        }
        i++
    }
    return false
}
