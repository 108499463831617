import Rules from '../../../components/Rules'
import Warehouse from '../../../models/Warehouse'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { controlList } from '../storage'

interface PropsActionADe {
    warehouse: Warehouse
}

export default function ActionActivateDeactivate({
    warehouse
}: PropsActionADe) {
    return (
        <Rules cu={warehouse.is_enabled ? 'CU097' : 'CU124'}>
            <ActivateDeactivate
                isEnabled={warehouse.is_enabled}
                control={controlList}
                body={{
                    warehouse_id: warehouse.id
                }}
                className="ml-2"
            >
                <Active path={`/v5.6/apis/warehouses/activate`}>
                    Al reactivar el almacén, estará disponible para operaciones
                    de movimientos de inventario. ¿Estás seguro de reactivar el
                    almacén <b className="text-primary">{warehouse.name}</b>?
                </Active>
                <Deactive path={`/v5.6/apis/warehouses/deactivate`}>
                    Al desactivar el almacén, dejará de estar disponible para
                    operaciones de inventario ¿Estás seguro de desactivar el
                    almacén <b className="text-primary">{warehouse.name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
