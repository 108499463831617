import { render, useRender } from 'redity'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import { DataModalSection, StatesModalSection } from './types'

const Key = 'MODAL_SECTION'

function Empty() {
    return <></>
}

const states: StatesModalSection<any> = {
    isOpen: false,
    title: '',
    mode: StateCase.LOADING,
    Component: Empty,
    path: null,
    data: null,
    onSubmit: () => null,
    size: 'md',
    errorView: <Empty />,
    isCentered: false
}

export default function ModalSection() {
    useRender(Key)

    const tools = {
        open: () => {
            states.isOpen = true
            render(Key)
        },
        close: () => {
            states.isOpen = false
            render(Key)
        }
    }

    return (
        <Modal
            open={states.isOpen}
            title={states.title}
            onClose={closeModal}
            onSubmit={ev => states.onSubmit(ev, tools)}
            size={states.size}
            isCentered={states.isCentered}
        >
            <ModalBody>
                {states.mode === StateCase.SUCCESS && (
                    <states.Component data={states.data} />
                )}
                {states.mode === StateCase.LOADING && (
                    <Loading height="240px" />
                )}
                {states.mode === StateCase.ERROR && states.errorView}
            </ModalBody>
            <ModalFooter>
                <Button onClick={closeModal} color="ghost">
                    Cancelar
                </Button>
                <Button className="ml-2" type="submit">
                    Guardar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

async function load<T>(path: string) {
    states.mode = StateCase.LOADING
    render(Key)
    const response = await get<T>(path)
    if (response.error) {
        states.mode = StateCase.ERROR
        return null
    } else {
        states.data = response.data.result
        states.mode = StateCase.SUCCESS
        return response.data.result
    }
}

export function closeModal() {
    states.isOpen = false
    states.title = ''
    states.mode = StateCase.LOADING
    render(Key)
}

export function useModal<T>(
    Component: (props: PropsModalSection<T>) => JSX.Element,
    dataModal: DataModalSection
) {
    let onOpen: (data: T) => void = () => null

    async function open(data: T = null) {
        states.onSubmit = dataModal.onSubmit || (() => null)
        states.isOpen = true
        states.title = dataModal.title
        states.Component = Component
        states.size = dataModal.size || 'md'
        states.isCentered = dataModal.isCentered || false
        states.errorView = (
            <div className="flex h-60 flex-col items-center justify-center">
                <p>Hubo problemas al cargar los datos</p>
                <Button
                    className="mt-2"
                    color="warning"
                    onClick={async () => {
                        await load(states.path)
                        onOpen(states.data)
                        render(Key)
                    }}
                >
                    Recargar
                </Button>
            </div>
        )

        if (dataModal.path) {
            states.path = dataModal.path
            const resultData = await load<T>(dataModal.path)
            if (resultData) {
                onOpen(resultData)
            }
            render(Key)
        } else {
            states.data = data
            states.mode = StateCase.SUCCESS
            onOpen(data)
        }
        render(Key)
    }

    return {
        open,
        close: closeModal,
        onOpen: cb => {
            onOpen = cb
        }
    }
}

export interface PropsModalSection<T> {
    data: T
}
