import { useState } from 'react'
import Button from '../../../../components/Button'
import Modal, { ModalBody, ModalFooter } from '../../../../components/Modal'
import BuyerCompanyField from './BuyerCompanyField'
import useSubmit from './hooks/useSubmit'
import useValues from './hooks/useValues'
import ImportField from './ImportField'
import MoneyField from './MoneyField'
import TableImport from './TableImport'
import { ModalImportProps } from './types'
import UploadStates from './UploadStates'

export default function ModalImport({ element, onDone }: ModalImportProps) {
    const [isOpen, setOpen] = useState(false)
    const {
        optionCompany,
        setOptionCompany,
        optionMoney,
        setOptionMoney,
        file,
        setFile,
        excelRendered,
        setExcelRendered
    } = useValues()
    const {
        submit,
        helper,
        setHelper,
        uploadStates,
        rowsError,
        setRowsError,
        setUploadStates
    } = useSubmit(
        {
            excelRendered,
            optionCompany,
            optionMoney
        },
        closeHandler,
        onDone
    )

    const isLoading = !!uploadStates.find(states => states.status === 'loading')

    function closeHandler() {
        setOpen(false)
        setOptionCompany(null)
        setOptionMoney(null)
        setExcelRendered(null)
        setFile(null)
        setHelper({})
        setRowsError([])
        setUploadStates([])
    }

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                open={isOpen}
                title="Importar productos"
                size="lg"
                onSubmit={submit}
                onClose={() => {
                    if (!isLoading) closeHandler()
                }}
            >
                <ModalBody>
                    <div className="flex gap-x-6">
                        <div className="w-full max-w-xs">
                            <BuyerCompanyField
                                option={optionCompany}
                                setOption={setOptionCompany}
                                helper={helper.company}
                            />
                            <MoneyField
                                option={optionMoney}
                                optionCompany={optionCompany}
                                setOption={setOptionMoney}
                                helper={helper.money}
                            />
                            <ImportField
                                file={file}
                                setFile={setFile}
                                setExcelRendered={setExcelRendered}
                                helper={helper.importExcel}
                            />
                        </div>
                        <div className="w-full">
                            <h4 className="mt-2 -mb-2 text-gray-500">
                                Reporte de errores
                            </h4>
                            <UploadStates uploadStates={uploadStates} />
                            <TableImport rowsError={rowsError} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={closeHandler}
                        color="ghost"
                        disabled={isLoading}
                    >
                        Cancelar
                    </Button>
                    <Button className="ml-2" type="submit" loading={isLoading}>
                        Importar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
