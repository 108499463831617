import { useHistory } from 'react-router'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList, storagePage } from '../storage'
import { ConsumerVoucherData, GetConsumerVoucher } from '../types'
import moment from 'moment'
import Pdf from './Pdf'
import IsActive from './BadgeActive'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import buildName from '../../../utilities/buildName'
import { viewPrint } from '../../../sections/ModalPrint'
import BtnActionTable from '../../../components/BtnActionTable'
import RunAccountingContainer, { RunAccounting } from '../RunAccounting'
import ModalConsumerVoucher from '../../../modals/ModalConsumerVoucher'
import ExportExcel from './ExportExcel'
import CancelConsumerVoucher from './Options/CancelConsumerVoucher'

function ViewerPdf({ data }: { data: GetConsumerVoucher }) {
    return <Pdf data={data} />
}

export default function Table() {
    const { push } = useHistory()

    function LoadPDF({
        consumer_voucher
    }: {
        consumer_voucher: ConsumerVoucherData
    }) {
        function handleClick() {
            viewPrint(
                `/v5.6/apis/consumer_voucher/${consumer_voucher.id}`,
                () => ({
                    Component: ViewerPdf
                })
            )
        }

        return (
            <BtnActionTable
                title="Imprimir"
                iconName="bs-printer"
                onClick={handleClick}
            />
        )
    }

    const math = useRule('CU171')

    return (
        <List control={controlList}>
            {(consumer_voucher, e) => (
                <tr key={consumer_voucher.id}>
                    <td>{e}</td>
                    <td>
                        <b
                            {...(math && {
                                className: 'underline cursor-pointer',
                                onClick: () =>
                                    view(
                                        `/v5.6/apis/consumer_voucher/${consumer_voucher.id}`,
                                        () => ({
                                            title: `DETALLE DE VALE DE CONSUMO`,
                                            Component: ModalConsumerVoucher
                                        }),
                                        'lg'
                                    )
                            })}
                        >
                            {consumer_voucher.document.code}
                        </b>
                    </td>
                    <td>
                        <p
                            className={
                                !consumer_voucher.document.requesting_user
                                    .is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {buildName(
                                consumer_voucher.document.requesting_user.name,
                                consumer_voucher.document.requesting_user
                                    .lastname
                            )}
                        </p>
                    </td>
                    <td>
                        <p
                            className={
                                !consumer_voucher.company_warehouse.warehouse
                                    .is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {consumer_voucher.company_warehouse.warehouse.name}
                        </p>
                    </td>
                    <td>
                        <span
                            className={
                                !consumer_voucher.minimal_stowage.stowage
                                    .is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            <p>
                                {consumer_voucher.minimal_stowage.stowage.name}
                            </p>

                            {consumer_voucher.minimal_stowage
                                .order_sub_stowage && (
                                <p>
                                    {consumer_voucher.minimal_stowage
                                        .order_sub_stowage.order.number +
                                        ' - ' +
                                        consumer_voucher.minimal_stowage
                                            .order_sub_stowage.order.client
                                            .name}
                                </p>
                            )}
                        </span>
                    </td>
                    <td>
                        <p
                            className={
                                !consumer_voucher.consumer_voucher_label
                                    .is_enabled
                                    ? 'line-through'
                                    : ''
                            }
                        >
                            {consumer_voucher.consumer_voucher_label.name}
                        </p>
                    </td>
                    <td>
                        {moment(consumer_voucher.document.decision_date)
                            .tz(storagePage.timezone)
                            .format('DD/MM/YYYY')}
                    </td>
                    <td>
                        <IsActive
                            enabled={!consumer_voucher.document.is_annuled}
                        />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            {!consumer_voucher.document.is_annuled && (
                                <Rules cu="CU169">
                                    <BtnActionTable
                                        title="Editar"
                                        iconName="bs-pencil"
                                        disabled={
                                            !storagePage.company_enabled ||
                                            consumer_voucher.document
                                                .has_accounting
                                        }
                                        onClick={() =>
                                            push(
                                                `/movements/consumption_vouchers/${consumer_voucher.id}/edition`
                                            )
                                        }
                                    />
                                </Rules>
                            )}

                            <Rules cu="CU170">
                                <LoadPDF consumer_voucher={consumer_voucher} />
                            </Rules>
                            <Rules cu="CU315">
                                <ExportExcel
                                    consumer_voucher_id={consumer_voucher.id}
                                />
                            </Rules>
                            <CancelConsumerVoucher
                                consumer_voucher={consumer_voucher}
                            />
                            <Rules cu="CU311">
                                <RunAccountingContainer
                                    control={controlList}
                                    body={{
                                        consumer_voucher_id: consumer_voucher.id
                                    }}
                                    disabled={
                                        !storagePage.company_enabled ||
                                        consumer_voucher.document.has_accounting
                                    }
                                >
                                    <RunAccounting
                                        path={`/v5.6/apis/consumer_vouchers/run_accounting`}
                                    >
                                        Se generarán los asientos contables para
                                        el vale de consumo. Luego de esta acción
                                        el vale de consumo no podrá ser
                                        modificado, esta acción es irreversible.
                                        ¿Está seguro de generar asientos
                                        contables para el vale de consumo{' '}
                                        <b>{consumer_voucher.document.code}</b>?
                                    </RunAccounting>
                                </RunAccountingContainer>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
