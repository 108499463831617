import moment from 'moment'
import {
    Empty,
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import Option from '../../../../components/Option'
import SelectTouch from '../../../../components/SelectTouch'
import useGet from '../../../../hooks/useGet'
import Agent from '../../../../models/Agent'
import TypeShipment from '../../../../models/TypeShipment'
import WorkingPeriod from '../../../../models/WorkingPeriod'
import { formGeneral, reqWorkingPeriod } from './storageStep1'

function TaxPeriod() {
    const { props } = formGeneral.useSelect('working_period')
    const { data } = useGet<WorkingPeriod[]>(
        reqWorkingPeriod.observer(
            '/v5.6/apis/working_periods?is_enabled=true',
            true
        ),
        []
    )

    function capitalize(word: string) {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }

    return (
        <FormControl label="Periodo fiscal" required>
            <SelectTouch {...props} placeholder="Seleccionar periodo fiscal">
                {data.result.map(working_period => (
                    <Option
                        key={working_period.id}
                        value={working_period.id.toString()}
                        label={capitalize(
                            moment(
                                `01/${working_period.month}/${working_period.year}`,
                                'DD/MM/YYYY'
                            ).format('MMMM YYYY')
                        )}
                    />
                ))}
                <Empty data={data.result} />
            </SelectTouch>
        </FormControl>
    )
}

function AgentField() {
    const { props, data } = formGeneral.useSelect<Agent>(
        'agent',
        '/v5.6/apis/agents?is_enabled=true'
    )
    return (
        <FormControl label="Agente aduanal">
            <SelectTouch {...props} placeholder="Seleccionar agente">
                <IterateOptions data={data} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function TypeShipmentField() {
    const { props } = formGeneral.useSelect('type_shipment')
    const { data } = useGet<TypeShipment[]>('/v5.6/apis/type_shipments/all', [])

    return (
        <FormControl label="Tipo de embarque" required>
            <SelectTouch
                {...props}
                placeholder="Seleccionar Tipo de Embarque"
                helper={props.helper}
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function Fields2() {
    return (
        <>
            <TaxPeriod />
            <AgentField />
            <TypeShipmentField />
        </>
    )
}
