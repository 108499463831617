import { render, useRender } from 'redity'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import { ModalBody, ModalFooter } from '../../components/Modal'
import ModalInfo from '../../components/ModalInfo'
import { StateCase } from '../../config/enums'
import { get } from '../../utilities/http'
import { HttpResponse } from '../../utilities/http/type'
import { State, ViewOpenData } from './types'

const Null = () => <></>

const initialState = (): State<any> => ({
    isOpen: false,
    title: '',
    subtitle: '',
    badge: '',
    Component: Null,
    data: null,
    mode: StateCase.LOADING,
    cb: () => null,
    path: '',
    size: 'md'
})

const state = initialState()

export default function View() {
    const r = useRender('MODAL_VIEW')
    function handleClose() {
        const values = initialState()
        Object.keys(values).forEach(key => {
            state[key] = values[key]
        })
        r()
    }

    return (
        <ModalInfo
            open={state.isOpen}
            title={state.title}
            onClose={handleClose}
            size={state.size}
        >
            <ModalBody>
                {state.mode === StateCase.SUCCESS && (
                    <state.Component {...state.props} data={state.data} />
                )}
                {state.mode === StateCase.LOADING && <Loading height="240px" />}
                {state.mode === StateCase.ERROR && (
                    <div className="flex h-60 flex-col items-center justify-center">
                        <p>Hubo problemas al cargar los datos</p>
                        <Button
                            className="mt-2"
                            color="warning"
                            onClick={() => view(state.path, state.cb)}
                        >
                            Recargar
                        </Button>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <div></div>
                <Button onClick={handleClose} color="ghost">
                    Cerrar
                </Button>
            </ModalFooter>
        </ModalInfo>
    )
}

export async function view<T>(
    path: string,
    cb: (data: T) => ViewOpenData<T>,
    size?: 'sm' | 'md' | 'lg'
) {
    state.isOpen = true
    state.size = size || 'md'
    state.cb = cb
    state.path = path
    state.mode = StateCase.LOADING
    render('MODAL_VIEW')
    const response: HttpResponse<T> = await get<T>(path)
    if (response.error) {
        state.mode = StateCase.ERROR
        render('MODAL_VIEW')
        return
    }
    const result = cb(response.data.result)
    state.title = result.title
    state.subtitle = result.subtitle || ''
    state.badge = result.badge || ''
    state.colorBadge = result.colorBadge || 'info'
    state.props = result.props || {}
    state.data = response.data.result
    state.Component = result.Component
    state.mode = StateCase.SUCCESS
    render('MODAL_VIEW')
}
