import useGet from '../../../../../hooks/useGet'
import { reqRC3 } from '../../SectionCostDistribution/storageStep4'
import { storagePage } from '../../../storage'
import { ResponseLDR } from '../../../types'

export default function useTableDistributionbyRC() {
    const { logistical_declaration, version } = storagePage
    const { data } = useGet<ResponseLDR[]>(
        reqRC3.observer(
            `/v5.6/apis/logistical_declarations/${logistical_declaration.id}/receptions?is_enabled=true&version=${version}`,
            true
        ),
        []
    )

    return {
        logical_declaration_receptions: data.result,
        currentSymbol: storagePage.currentCurrency.symbol
    }
}
