import { Empty, FormControl } from '../../../components/FormUtilities'
import Option from '../../../components/Option'
import SelectTouch from '../../../components/SelectTouch'
import { Option as OptionType } from '../../../config/types/component'
import useGet from '../../../hooks/useGet'
import Purchase from '../../../models/Purchase'
import { get } from '../../../utilities/http'
import {
    formPurchaseReceipt,
    productsControl,
    reqPurchase,
    storagePage
} from '../storage'
import { GETProductsForTraspase, ProductsForInput } from '../types'
import { useRender, render } from 'redity'
import { Keys } from '../constants'
import FormEvents from '../../../utilities/FormEvents'

let value = ''

export default function SelectOC() {
    const { props } = formPurchaseReceipt.useSelect('purchase')
    const { loading, data } = useGet<Purchase[]>(
        reqPurchase.observer(
            '/v5.6/apis/companies/:company_id/confirmed_purchases',
            true
        ),
        []
    )

    async function handleSelect(nextOption: OptionType) {
        props.onChange(nextOption)
        productsControl.setList([])
        productsControl.renderMain()

        let list = []
        if (parseInt(nextOption.value) > 0) {
            if (storagePage.purchaseReceipt?.id) {
                const response = await get<GETProductsForTraspase[]>(
                    `/v5.6/apis/purchases/${nextOption.value}/purchase_products_to_receive?to_create=false`
                )

                list = response.data.result.map(product => {
                    return {
                        ...product,
                        quantity_requested: (
                            product.quantity -
                            product.quantity_received_other_rcs
                        ).toString(),
                        confirmed: false,
                        helper: ''
                    }
                })
            } else {
                const response = await get<GETProductsForTraspase[]>(
                    `/v5.6/apis/purchases/${nextOption.value}/purchase_products_to_receive?to_create=true`
                )

                list = response.data.result.map(product => {
                    return {
                        ...product,
                        quantity_requested: (
                            product.quantity -
                            product.quantity_received_other_rcs
                        ).toString(),
                        confirmed: false,
                        helper: ''
                    }
                })
            }
        }

        productsControl.setList(list)
        productsControl.renderMain()
        storagePage.receipt_products = {}
        render(Keys.MOUNT)
    }

    function handleChange(ev) {
        value = ev.target.value
        if (value === '') {
            reqPurchase.deleteQuery('search')
        } else {
            reqPurchase.setQuery('search', value)
        }
        reqPurchase.load()
        render(Keys.MOUNT)
    }

    return (
        <FormControl required label="Orden de compra" className="mb-4">
            <SelectTouch
                {...props}
                helper={props.helper}
                loading={loading}
                onSearch={handleChange}
                onChange={handleSelect}
                placeholder="Seleccionar la orden de compra que desea recepcionar"
                label="Orden de compra"
                search={value}
                disabled={storagePage.mode !== 'creation'}
            >
                {data.result.map(purchase => (
                    <Option
                        key={purchase.id}
                        value={purchase.id.toString()}
                        label={purchase.code}
                    />
                ))}
                <Empty data={data.result} />
            </SelectTouch>
        </FormControl>
    )
}
