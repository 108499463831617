import { ModuleForm } from '../storage'

export default function getBody(mods: ModuleForm[]) {
    const body = {
        permission_roles: []
    }
    for (const mod of mods) {
        for (const permission of mod.permissions) {
            const { active, id, permission_role_id } = permission.store()
            if (!active && !permission_role_id) continue
            if (permission_role_id) {
                body.permission_roles.push({
                    id: permission_role_id,
                    is_enabled: permission.store().active
                })
            } else {
                body.permission_roles.push({
                    is_enabled: permission.store().active,
                    permission_id: id
                })
            }
        }
    }

    return body
}
