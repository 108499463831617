import Permission from '../../models/Permission'
import Session from '../../models/Session'
import User from '../../models/User'
import { get, setAuth } from '../http'
import cus from '../../cus.json'
import CU, { DefinitionCU } from '../../config/types/cu'
import getRouting from './getRouting'
import { SESSION } from '../../config/app'
import Company from '../../models/Company'
import { API_CORE_URL, PUBLIC_URL_LOGIN } from '../../config/credentials'
import moment from 'moment'

interface GetSession extends User {
    session: Session
    permissions: Permission[]
    company_principal: Company
}

export default async function auth(token: string | null) {
    const tokenSaved = localStorage.getItem('token')
    if (token) {
        setAuth(token)
    } else if (tokenSaved) {
        setAuth(tokenSaved)
    }
    const sessionResponse = await get<GetSession>(
        '/v5.6/apis/users/get_session'
    )
    if (sessionResponse.error || !sessionResponse?.data?.result) {
        localStorage.clear()
        window.location.replace(PUBLIC_URL_LOGIN)
    }

    const {
        id,
        name,
        lastname,
        email,
        avatar_url,
        company_principal,
        session,
        permissions
    } = sessionResponse.data.result

    try {
        const res = await fetch(`${API_CORE_URL}/apps/erp/get-token`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                username: SESSION.core_username,
                password: SESSION.core_password,
                end_date: moment(session.end_token).toISOString(true)
            })
        })

        if (res.ok) {
            const result: { end_date: string; token_erp: string } =
                await res.json()
            localStorage.setItem('core_token_erp', result.token_erp)
        }
    } catch (error) {}

    const CUs: CU[] = []
    for (const permission of permissions) {
        const cuData = cus[permission.id] as DefinitionCU
        if (cuData) {
            CUs.push(cuData.cu)
        }
    }
    localStorage.setItem('expireIn', session.end_token)
    const expireIn = moment.utc(session.end_token).local()
    const now = moment()
    const timeOut = moment.duration(expireIn.diff(now)).asMilliseconds()
    return {
        user: {
            id,
            fullName: `${name} ${lastname}`,
            email,
            companyId: company_principal?.id || null,
            avatar: avatar_url
        },
        expireIn: session.end_token,
        timeOut: timeOut,
        routing: getRouting(id, CUs),
        CUs
    }
}
