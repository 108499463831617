import { FormControl, FormControls } from '../../../../components/FormUtilities'
import TableStorage from './Table'
import Alert from '../../../../components/Alert'
import Badge from '../../../../components/Badge'
import SearchProducts from './SearchProducts'
import moment from 'moment'
import { formConsumptionVoucher } from '../../storage'
import { useRender } from 'redity'

function BadgeDate() {
    const { request_date } = formConsumptionVoucher.store()
    useRender('badge_label')
    return (
        <Badge className="mt-2">
            {moment(request_date).parseZone().format('LL')}
        </Badge>
    )
}

export default function Information() {
    return (
        <>
            <Alert className="mb-2">
                Recordar que la cantidad disponible varia según la fecha de vale
                de consumo
            </Alert>
            <FormControls className="mb-4">
                <SearchProducts />
                <FormControl label="Fecha" col="span-2">
                    <BadgeDate />
                </FormControl>
            </FormControls>
            <TableStorage />
        </>
    )
}
