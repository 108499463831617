import { useParams } from 'react-router'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import ReadOnly from '../../../components/ReadOnly'
import SelectTouch from '../../../components/SelectTouch'
import useStorage from '../../../hooks/useStorage'
import { setCompanySelected } from '../../../utilities/controlStorage'
import { Option as OptionType } from '../../../config/types/component'
import { formLicense } from '../storage'

function Name() {
    const { props, getError } = formLicense.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe una licencia con el mismo nombre'
    )
    const helper2 = getError('ERROR-XXXXXXX')
    return (
        <FormControl label="Licencia" required>
            <InputField
                {...props}
                placeholder="Ingresar nombre de la licencia"
                helper={helper || helper2 || props.helper}
            />
        </FormControl>
    )
}

function Company() {
    type Params = { license_id?: string; license_id_for_renovate?: string }
    const { license_id, license_id_for_renovate } = useParams<Params>()
    const { props } = formLicense.useSelect('company')
    const { companiesFromSession } = useStorage()

    const data = companiesFromSession
        .filter(company => company.is_enabled)
        .map(company => ({
            id: company.id,
            name: company.name,
            image: company.country.image_url
        }))

    function handleChange(option: OptionType) {
        props.onChange(option)
        const company = companiesFromSession.find(
            comp => comp.id === parseInt(option.value)
        )
        setCompanySelected(company)
    }

    return (
        <FormControl label="Compañía" required>
            {(license_id || license_id_for_renovate) && (
                <ReadOnly
                    value={props.option?.label}
                    image={props.option?.image}
                />
            )}
            {!(license_id || license_id_for_renovate) && (
                <SelectTouch
                    {...props}
                    placeholder="Seleccionar compañía"
                    label="Compañía"
                    onChange={handleChange}
                >
                    <IterateOptions data={data} label="name" image="image" />
                </SelectTouch>
            )}
        </FormControl>
    )
}

function Code() {
    const { props, getError } = formLicense.useInput('code')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe una licencia con el mismo código'
    )

    return (
        <FormControl label="Código" required>
            <InputField
                {...props}
                placeholder="Ingresar código de la licencia"
                helper={props.helper || helper}
            />
        </FormControl>
    )
}

function Version() {
    const { props } = formLicense.useInput('version')

    return (
        <FormControl label="Versión">
            <InputField {...props} readOnly />
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <Name />
            <Company />
            <Code />
            <Version />
        </>
    )
}
