import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import TableAditionalInternationalCosts from './tables/TableAditionalInternationalCosts'
import TableAditionalNationalCosts from './tables/TableAditionalNationalCosts'
import Button from '../../../../components/Button'
import useSubmitCostByProduct from './FormCostByProduct/useSubmitCostByProduct'
import useSubmitCostGeneral from './FormCostGeneral/useSubmitCostGeneral'

export default function SectionAdditional() {
    const openCostGeneralInternational = useSubmitCostGeneral('international')
    const openCostGeneralNational = useSubmitCostGeneral('national')
    const openCostByProductIn = useSubmitCostByProduct('international')
    const openCostByProductNa = useSubmitCostByProduct('national')

    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Costos internacionales adicionales">
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => openCostByProductIn()}
                    >
                        + Costo por producto
                    </Button>
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        className="ml-2"
                        onClick={() => openCostGeneralInternational()}
                    >
                        + Costo general
                    </Button>
                </PanelHeader>
                <PanelBody>
                    <TableAditionalInternationalCosts />
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Costos nacionales adicionales">
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => openCostByProductNa()}
                    >
                        + Costo por producto
                    </Button>
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        className="ml-2"
                        onClick={() => openCostGeneralNational()}
                    >
                        + Costo general
                    </Button>
                </PanelHeader>
                <PanelBody>
                    <TableAditionalNationalCosts />
                </PanelBody>
            </Panel>
        </>
    )
}
