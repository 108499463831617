import { useState, useEffect } from 'react'
import { StateCase } from '../../../config/enums'

import { controlList } from '../storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)
    useEffect(() => {
        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })
    }, [])

    return state
}
