import classNames from 'classnames'
import { PropsAvatar } from './types'
import styled from 'styled-components'

const AvatarStyled = styled.div`
    // Small devices (landscape phones, 576px and up)
    /* @media (min-width: 300px) {
        display: none;
    } */
`

export default function Avatar({
    src,
    label,
    className = '',
    onClick = () => null,
    link = false,
    lineThrough = false,
    cursorPointer = false
}: PropsAvatar) {
    const classUnderline = lineThrough ? 'underline' : ''
    const classPointer = cursorPointer ? 'cursor-pointer' : ''
    return (
        <AvatarStyled
            className={classNames('flex items-center group-avatar', className)}
            onClick={onClick}
        >
            <div className="avatar mr-2">
                <div className="rounded-full w-8 h-8">
                    <img src={src} />
                </div>
            </div>
            <p
                className={classNames('font-univers-bold text-xs capitalize', {
                    [`text-primary ${classUnderline} ${classPointer}`]: link,
                    'line-through': lineThrough
                })}
            >
                {label}
            </p>
        </AvatarStyled>
    )
}
