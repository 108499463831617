import { render, useRender } from 'redity'
import Alert from '../../../../components/Alert'
import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import Tooltip from '../../../../components/Tooltip'
import Ul from '../../../../components/Ul'
import { Keys } from '../../constants'
import {
    tableSaleDiscountsControl,
    storagePage,
    formSaleDiscounts
} from '../../storage'
import { initialEditionSaleDiscount } from '../../utilities/initialEditionSaleDiscount'
import SaleDiscounts from './SaleDiscounts'
import SelectCompany from './SelectCompany'
import useSubmit from './useSubmit'

export default function Step2() {
    useRender('Step2')

    const submit = useSubmit()
    const { modeStep2 } = storagePage

    async function changeMode() {
        if (
            formSaleDiscounts.test(v => {
                v.company.isEmpty('Se requiere seleccionar la compañía')
            })
        ) {
            storagePage.modeStep2 = 'edition'
            formSaleDiscounts.setProps('company', { disabled: true })
            render('Step2')
        }
    }

    async function cancel() {
        initialEditionSaleDiscount(storagePage.productPrice)
        storagePage.modeStep2 = 'view'
        formSaleDiscounts.setProps('company', { disabled: false })
        render('Step2')
    }

    return (
        <>
            <Panel mode="form">
                <PanelHeader
                    title="Rango de descuento"
                    helper={<TooltipSaleDiscounts />}
                >
                    {modeStep2 === 'view' && (
                        <ButtonEdition onClick={() => changeMode()} />
                    )}
                    {modeStep2 === 'edition' && (
                        <>
                            <Button
                                size="sm"
                                color="ghost"
                                onClick={() => cancel()}
                            >
                                Cancelar
                            </Button>
                            <Button
                                size="sm"
                                className="ml-2"
                                color="accent"
                                onClick={() => submit()}
                            >
                                Guardar
                            </Button>
                        </>
                    )}
                </PanelHeader>
                <PanelBody>
                    <Alert className="mb-2">
                        Para ingresar los rangos de descuentos por compañia,
                        primero debe seleccionar la compañia a registrar.
                    </Alert>
                    <Ul className="mb-4 mt-4">
                        <li>
                            Producto:
                            <b className="ml-2">
                                {
                                    storagePage.productPrice.product_country
                                        .product.name
                                }
                            </b>
                        </li>
                        <li>
                            Código:
                            <b className="ml-2">
                                {
                                    storagePage.productPrice.product_country
                                        .product.code
                                }
                            </b>
                        </li>
                    </Ul>
                    <SelectCompany />
                    <SaleDiscounts />
                </PanelBody>
            </Panel>
        </>
    )
}

function ButtonEdition({ onClick }) {
    useRender(Keys.DISCOUNT_BUTTON_EDITION)
    const { enabledEditionDiscount } = storagePage

    return (
        <Button
            size="sm"
            color="accent"
            disabled={!enabledEditionDiscount}
            onClick={onClick}
        >
            Editar
        </Button>
    )
}

function TooltipSaleDiscounts() {
    const { message } = tableSaleDiscountsControl.useHelpers()
    return (
        <>
            {message && (
                <Tooltip content={message}>
                    <Icon name="warning" className="text-red-500" />
                </Tooltip>
            )}
        </>
    )
}
