import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { render } from 'redity'
import { StateCase } from '../../config/enums'
import { Option } from '../../config/types/component'
import useCancel from '../../hooks/useCancel'
import useStorage from '../../hooks/useStorage'
import { GetPurchase } from '../../types/purchase'
import buildCompanyName from '../../utilities/buildCompanyName'
import { getCompanySelected } from '../../utilities/controlStorage'
import { get } from '../../utilities/http'
import {
    ct,
    formOC,
    reqDeliveryCountry,
    reqLocate,
    reqRequisition,
    storagePage,
    tableRequisition
} from './storage'

export default function useInitial() {
    const [pageState, setPageState] = useState(StateCase.LOADING)
    const { onClick } = useCancel([formOC], '/purchase_orders')
    const { countries } = useStorage()
    const { purchase_id } = useParams<{ purchase_id?: string }>()
    const mode = purchase_id ? 'edition' : 'creation'
    storagePage.mode = mode

    async function load() {
        if (mode === 'creation') {
            const company = getCompanySelected()

            const optionCompany: Option = {
                label: company.name,
                value: company.id.toString(),
                image: company.country.image_url
            }
            const { billing_company, destination_company: dc } = formOC.store()
            if (!billing_company && !dc) {
                formOC.init({
                    ...formOC.store(),
                    billing_company: optionCompany,
                    destination_company: optionCompany
                })
            }
            formOC.setValue(
                'date',
                moment().tz(company.timezone).toISOString(true)
            )
            storagePage.timezone = company.timezone
            setPageState(StateCase.SUCCESS)
            return
        }

        const { data, error } = await get<GetPurchase>(
            `/v5.6/apis/purchases/${purchase_id}`
        )

        if (error) {
            setPageState(StateCase.ERROR)
            return
        }

        storagePage.purchase = data.result

        const {
            invoicing_company,
            destination_company,
            provider_company,
            type_purchase,
            priority_purchase,
            required_date,
            document,
            payment_condition_description,
            shipping_type_purchase,
            shipping_method_purchase,
            relation_address,
            purchase_products,
            id
        } = data.result
        storagePage.timezone = invoicing_company.timezone
        const getCountry = (country_id: number) =>
            countries.find(country => country.id === country_id)
        formOC.init({
            billing_company: {
                label: buildCompanyName(invoicing_company.name),
                value: invoicing_company.id.toString(),
                image: getCountry(invoicing_company.country_id).image_url
            },
            destination_company: {
                label: buildCompanyName(destination_company.name),
                value: destination_company.id.toString(),
                image: getCountry(destination_company.country_id).image_url
            },
            provider: {
                label: provider_company.provider.commercial_name,
                value: provider_company.provider.id.toString()
            },
            type_oc: type_purchase.name,
            priority: {
                label: priority_purchase.name,
                value: priority_purchase.id.toString()
            },
            date: moment(required_date)
                .tz(invoicing_company.timezone)
                .toISOString(true),
            payment_condition: payment_condition_description || '',
            comment: document.description || '',
            shipping_type: {
                label: shipping_type_purchase.name,
                value: shipping_type_purchase.id.toString()
            },
            country_dispatch_withdrawal: {
                label: getCountry(relation_address.country_id).name,
                value: relation_address.country_id.toString(),
                image: getCountry(relation_address.country_id).image_url
            },
            shipping_way: {
                label: shipping_method_purchase.name,
                value: shipping_method_purchase.id.toString()
            },
            place_dispatch_pick_up: {
                label: getLocate(relation_address, shipping_method_purchase.id),
                value: getLocate(relation_address, shipping_method_purchase.id)
            },
            address: {
                label: relation_address.address,
                value: relation_address.id.toString()
            }
        })

        formOC.setProps('country_dispatch_withdrawal', {
            disabled: false
        })
        formOC.setProps('place_dispatch_pick_up', {
            disabled: false
        })
        formOC.setProps('address', {
            disabled: false
        })

        reqLocate.setParam('provider_id', provider_company.provider.id)
        reqLocate.setParam(
            'mode_address',
            shipping_type_purchase.id === 1
                ? 'dispatch_addresses'
                : 'withdrawal_addresses'
        )
        reqLocate.setQuery('country_id', relation_address.country_id)
        reqRequisition.setQuery('invoicing_company_id', invoicing_company.id)
        reqRequisition.setQuery(
            'destination_company_id',
            destination_company.id
        )
        reqRequisition.setQuery('purchase_id', id)
        reqRequisition.setQuery('provider_id', provider_company.provider.id)
        reqDeliveryCountry.setParam('provider_id', provider_company.provider.id)
        reqDeliveryCountry.setQuery(
            'shipping_type_purchase_id',
            shipping_type_purchase.id
        )

        purchase_products
            .filter(pp => pp.is_enabled)
            .forEach(pp => {
                storagePage.req_products_actived[pp.requisition_product_id] = {
                    quantity_buy: pp.quantity,
                    provider_price: pp.unit_purchase_price
                }
            })
        setPageState(StateCase.SUCCESS)
        reqLocate.load().then(({ data: dataResult }) => {
            const result = dataResult.result.find(
                d =>
                    d.locate ===
                    getLocate(relation_address, shipping_method_purchase.id)
            )
            if (result) {
                storagePage.addresses = result.addresses
                render(formOC.keyRender, 'address')
            }
        })
        reqDeliveryCountry.load()
        formOC.setProps('billing_company', { disabled: true })
        formOC.setProps('destination_company', { disabled: true })
        formOC.setProps('provider', { disabled: true })
    }

    useEffect(() => {
        load()
        return () => {
            storagePage.step1Done = false
            storagePage.purchase = null
            storagePage.mode = 'creation'
            ct.value = 'step1'
            storagePage.req_products_actived = {}
            formOC.init()
            formOC.clearProps()
            tableRequisition.setList([])
        }
    }, [])

    return { pageState, onCancel: onClick }
}

function getLocate(
    relation_address: GetPurchase['relation_address'],
    shipping_type_purchase_id: number
) {
    if (shipping_type_purchase_id === 1) {
        if (relation_address.company) {
            return relation_address.company.name
        } else if (relation_address.warehouse) {
            return relation_address.warehouse.name
        } else if (relation_address.agent) {
            return relation_address.agent.name
        } else {
            return relation_address.locate
        }
    } else {
        return relation_address.locate
    }
}
