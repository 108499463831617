import { FormControl } from '../../../components/FormUtilities'
import MapView, { MarkerPoint } from '../../../components/MapView'
import { formWarehouse } from '../storage'

export default function GoogleMap() {
    const { props } = formWarehouse.useMap('latLng')
    return (
        <FormControl col="span-6" label="">
            <MapView {...props} search>
                <MarkerPoint {...props} />
            </MapView>
        </FormControl>
    )
}
