import classnames from 'classnames'
import { PropsButton } from './types'

export default function Button({
    children,
    color = 'accent',
    onClick = () => null,
    className = '',
    type = 'button',
    disabled = false,
    outline = false,
    loading = false,
    block = false,
    size = 'normal',
    circle = false,
    wide = false
}: PropsButton) {
    const classNamesBtn = classnames(
        `font-univers-light font-normal btn tracking-wider capitalize`,
        {
            [`btn-${color}`]: true,
            [`btn-${size}`]: true,
            'btn-circle': circle,
            loading,
            'btn-block': block,
            'btn-wide': wide,
            'btn-outline': outline
        },
        className
    )

    return (
        <button
            type={type}
            className={classNamesBtn}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    )
}
