import { SVGProps } from 'react'

const ThumbUp = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.82 17.333h7.5c.692 0 1.284-.416 1.534-1.016l2.517-5.876c.075-.191.116-.391.116-.608V8.166c0-.916-.75-1.666-1.666-1.666h-5.259l.792-3.808.025-.267c0-.342-.142-.658-.367-.883L11.13.667 5.637 6.158c-.3.3-.483.717-.483 1.175v8.334c0 .916.75 1.666 1.667 1.666Zm0-10 3.617-3.617-1.116 4.45h7.5v1.667l-2.5 5.833h-7.5V7.333Zm-3.333 0H.154v10h3.333v-10Z"
        />
    </svg>
)

export default ThumbUp
