import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { controlList, controlTabs, filterForm, storagePage } from './storage'
import { formInformation } from './../Requisition/storage'
import { getCompanySelected } from '../../utilities/controlStorage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)
    useEffect(() => {
        const company = getCompanySelected()
        controlList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })

        formInformation.setValue('company', {
            label: company.name,
            image: company.country.image_url,
            value: company.id.toString()
        })
        storagePage.company_enabled = company.is_enabled
        storagePage.timezone = company.timezone
        controlList.filter.params.document_approval = 'Aprobados'

        controlList.filter.changeParams('sort', 'requisition_code')
        controlList.filter.changeParams('order', 'desc')

        filterForm.setParams('stowage_id', {})
        filterForm.setParams('provider_id', {})

        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })

        return () => {
            filterForm.init()
            controlTabs.step('step1')
        }
    }, [])

    return state
}
