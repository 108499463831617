import { GetAgents } from '../../types/agent'
import ControlList from '../../utilities/Control/ControlList'
import { Keys } from './constants'

export const controlList = new ControlList<GetAgents[number]>(Keys.MAIN, {
    path: '/v5.6/apis/agents',
    theads: [
        '#',
        { value: 'name', label: 'Agente' },
        { value: 'country_name', label: 'País de ubicación' },
        'Tipo de agente',
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})
