import moment from 'moment'
import FileView from '../../../../components/FileView'
import Ul from '../../../../components/Ul'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import { parseDriveFile } from '../../../../utilities/fileTools'
import { LicenseInfo } from '../../types'

export default function Info({ data }: { data: LicenseInfo }) {
    return (
        <div className="grid grid-cols-2 gap-x-10 gap-y-4">
            <Ul>
                <li>
                    Compañía:{' '}
                    <img
                        src={data.company.country.image_url}
                        alt=""
                        className="w-5 inline mr-1"
                    />{' '}
                    <b className={`${!data.company.memory && 'line-through'}`}>
                        {buildCompanyName(data.company.name)}
                    </b>
                </li>
                <li>
                    Código: <b>{data.code}</b>
                </li>
                <li>
                    Fecha de emisión:{' '}
                    <b>{moment(data.issue_date).format('DD/MM/YYYY')}</b>
                </li>
                <li>
                    Tipo de licencia:{' '}
                    <b>
                        {data.license_type_licenses
                            .filter(ltl => ltl.memory)
                            .map(ltl => ltl.type_license.name)
                            .join(' - ')}
                    </b>
                </li>
            </Ul>
            <Ul>
                <li>
                    Licencia: <b>{data.name}</b>
                </li>
                <li>
                    Versión: <b>V{data.version}</b>
                </li>
                <li>
                    Fecha de vencimiento:{' '}
                    <b>
                        {data.due_date
                            ? moment(data.due_date).format('DD/MM/YYYY')
                            : '-'}
                    </b>
                </li>
            </Ul>
            <Ul className="col-span-2 mb-4">
                <li>
                    Descripción:{' '}
                    <b className="break-words">{data.description}</b>
                </li>
            </Ul>
            {data.files.map(f => (
                <FileView key={f.drive_id} file={parseDriveFile(f)} />
            ))}
        </div>
    )
}
