import classnames from 'classnames'
import './pagination.css'
import { getEnumeration } from './utilities'
import Icon from '../Icon'
import { PropsItem, PropsPagination } from './type'
import Limit from './Limit'

function Item({ children, active, disabled = false, onClick }: PropsItem) {
    return (
        <div
            className={classnames('pagination-item', { active, disabled })}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default function Pagination({
    pages,
    page,
    limit = 10,
    className = '',
    onChange = () => null,
    onLimit = () => null
}: PropsPagination) {
    return (
        <div className={classnames('pagination', className)}>
            <Limit handleLimit={onLimit} limit={limit} />
            <div className="pagination-enumeration">
                <Item
                    active={false}
                    disabled={page === 1}
                    onClick={() => onChange(page - 1)}
                >
                    <Icon name="arrow-left" />
                </Item>
                {getEnumeration(pages, page).map(n => (
                    <Item
                        key={n}
                        active={n === page}
                        disabled={n === -1 || n === -2}
                        onClick={() => onChange(n)}
                    >
                        {n === -1 || n === -2 ? '...' : n}
                    </Item>
                ))}
                <Item
                    active={false}
                    disabled={page >= pages}
                    onClick={() => onChange(page + 1)}
                >
                    <Icon name="arrow-right" />
                </Item>
            </div>
        </div>
    )
}
