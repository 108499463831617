import Dropdown from '../../../components/Dropdown'
import Option from '../../../components/Option'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { Option as OptionType } from '../../../config/types/component'
import useStorage from '../../../hooks/useStorage'
import { setCompanySelected } from '../../../utilities/controlStorage'
import { controlList } from '../storage'

function Company() {
    const { props } = controlList.useFilter('company_id')
    const { companiesFromSession } = useStorage()

    function handleChange(option: OptionType) {
        props.onChange(option)
        const company = companiesFromSession.find(
            c => c.id.toString() === option.value
        )
        setCompanySelected(company)
    }

    return (
        <Dropdown
            {...props}
            label="Compañía"
            showLabel={false}
            onChange={handleChange}
        >
            {companiesFromSession.map(company => (
                <Option
                    key={company.id}
                    value={company.id.toString()}
                    label={company.name}
                    image={company.country.image_url}
                    strikeOut={!company.is_enabled}
                />
            ))}
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por código y nombre de producto"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

export default function FilterProviders() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Company />
                </FlexLeft>
                <FlexRight></FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
