import { useHistory } from 'react-router'
import { formWarehouse, storagePage } from './storage'
import { BodyWarehouse, FormWarehouse } from './types'

export default function useSubmit() {
    const { push } = useHistory()

    const { mode, warehouseId } = storagePage
    const path =
        mode === 'edition' || warehouseId ? `/${storagePage.warehouseId}` : ''

    const { observer, validation, submit } =
        formWarehouse.useSubmit<FormWarehouse>(`/v5.6/apis/warehouses${path}`, {
            message: '¿Está seguro de guardar cambios de Almacén?',
            done: warehouse => {
                storagePage.warehouseId = warehouse.id
                push('/configuration/warehouses')
            },
            method: mode === 'edition' || warehouseId ? 'put' : 'post'
        })

    validation(v => {
        if (storagePage.mode === 'creation') {
            v.country.isEmpty('Se requiere seleccionar el país')
        }
        v.code
            .minLength(2, 'El número de carácteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el código')
        v.name
            .minLength(2, 'El número de carácteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el nombre')
        v.address
            .minLength(2, 'El número de carácteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar la dirección')
        v.companies.isEmpty('Se requiere de una compañía como mínimo')
    })

    observer<BodyWarehouse>(form => ({
        address: form.address,
        code: form.code.trim(),
        name: form.name.trim(),
        company_ids: form.companies.map(company => parseInt(company.value)),
        country_id: parseInt(form.country.value),
        image_url:
            form.image_url ||
            'https://drive.google.com/a/bitzone.lat/uc?id=1uH-JHBAdV0gCu3MtymAa3slnkS-QY-YE',
        latitude: form.latLng.lat,
        longitude: form.latLng.lng
    }))

    return submit
}
