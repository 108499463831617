import Rules from '../../../components/Rules'
import Role from '../../../models/Role'
import ActivateDeactivate, {
    Active,
    Deactive
} from '../../../sections/ActivateDeactivate'
import { controlList } from '../storage'

interface PropsRoleADe {
    role: Role
}

export default function RoleActivateDeactivate({ role }: PropsRoleADe) {
    return (
        <Rules cu={role.is_enabled ? 'CU006' : 'CU005'}>
            <ActivateDeactivate
                isEnabled={role.is_enabled}
                control={controlList}
                body={{
                    role_id: role.id
                }}
            >
                <Active path="/v5.6/apis/roles/activate" label="Activar rol">
                    ¿Estás seguro de activar el rol{' '}
                    <b className="text-primary">{role.name}</b>?
                </Active>
                <Deactive
                    path="/v5.6/apis/roles/deactivate"
                    label="Desactivar rol"
                >
                    ¿Estás seguro de desactivar el rol{' '}
                    <b className="text-primary">{role.name}</b>?
                </Deactive>
            </ActivateDeactivate>
        </Rules>
    )
}
