import { formInformation, storagePage } from './storage'
import { BodyPost, BodyPut } from './types'

export default function useSubmit() {
    const costing_estimate_id = storagePage.cost_estimate_id
    const { submit, validation, observer } = formInformation.useSubmit(
        costing_estimate_id
            ? `/v5.6/apis/costing_estimates/${costing_estimate_id}`
            : '/v5.6/apis/costing_estimates',
        {
            message: '¿Está seguro de guardar el costeo anticipado?',
            done: '/costs/cost_estimates',
            method: costing_estimate_id ? 'put' : 'post'
        }
    )

    validation(v => {
        v.purchaseReceipt.isEmpty('Se requiere seleccionar una compañía')
        v.estimatedAmount.isEmpty('Se requiere ingresar el monto estimado')
        v.estimatedAmount.isMinor(1, 'El monto estimado debe ser mayor a 0')
    })

    observer<BodyPost | BodyPut>(f => {
        if (costing_estimate_id) {
            return {
                comment: f.comment,
                estimate_cost: parseFloat(f.estimatedAmount)
            }
        }
        return {
            comment: f.comment,
            estimate_cost: parseFloat(f.estimatedAmount),
            company_id: parseInt(f.company.value),
            reception_id: parseInt(f.purchaseReceipt.value)
        }
    })
    return submit
}
