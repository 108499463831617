import { storagePage } from '../../../storage'
import Cost from '../cost'
import {
    formRC,
    reqLogisticalCost1,
    reqLogisticalCost2,
    reqModalRC,
    reqRC,
    tableRCsControl
} from '../storageStep2'
import Form from '.'
import { reqLogisticalCost4 } from '../../SectionAdditional/storageStep3'
import { reqPCD } from '../../SectionCostDistribution/storageStep4'
import { render } from 'redity'
import { Keys } from '../../../constants'
import { reqRC2 } from '../../SectionSettings/storage'

export default function useSubmitRC(logiticalDeclarationReceptionId?: number) {
    const { submit, validation, observer } = formRC.useSubmit(
        logiticalDeclarationReceptionId
            ? `/v5.6/apis/logistical_declaration_receptions/${logiticalDeclarationReceptionId}`
            : '/v5.6/apis/logistical_declaration_receptions',
        {
            method: logiticalDeclarationReceptionId ? 'put' : 'post',
            done: async () => {
                await reqRC.load()
                await reqLogisticalCost1.load()
                reqLogisticalCost2.load()
                reqLogisticalCost4.load()
                reqRC2.load()
                reqPCD.load()
                render(Keys.TOTAL_COST)
            }
        }
    )

    validation(v => {
        v.rc.isEmpty('Se requiere seleccionar la requisición')
        v.exchange_rc_to_dolar.isEmpty('Se requiere ingresar el tipo de cambio')
        v.exchange_dolar_to_local.isEmpty(
            'Se requiere ingresar el tipo de cambio'
        )
    })

    observer<Cost.PostBodyLDR | Cost.PutBodyLDR>(f => ({
        ...(!logiticalDeclarationReceptionId && {
            logistical_declaration_id: storagePage.logistical_declaration.id,
            reception_id: parseInt(f.rc.value)
        }),
        version: storagePage.version,
        exchange_rc_to_dolar: Number(f.exchange_rc_to_dolar),
        exchange_dolar_to_local: Number(f.exchange_dolar_to_local)
    }))

    const open = formRC.useModal(Form, {
        title: logiticalDeclarationReceptionId
            ? 'Editar recepción de compra'
            : 'Agregar recepción de compra',
        onSubmit: submit,
        size: 'md'
    })

    return function handleOpen(form?: Cost.FormRC, symbol?: string) {
        storagePage.currentReception = null
        storagePage.currentSymbolReception = symbol || '?'
        if (tableRCsControl.getDataList().length === 0) {
            reqModalRC.deleteQuery('not_reception_ids')
        } else {
            reqModalRC.setQuery(
                'not_reception_ids',
                tableRCsControl.getDataList().map(d => d.reception_id)
            )
        }

        if (logiticalDeclarationReceptionId) {
            formRC.setProps('rc', { disabled: true })
        } else {
            formRC.setProps('rc', { disabled: false })
        }
        open(form)
    }
}
