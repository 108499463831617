export default function buildName(name: string, lastname: string): string {
    const firstName = name.split(' ')[0]
    const firstLastname = lastname.split(' ')[0]
    const split = lastname.split(' ')
    let letter = ''
    if (split.length > 1) {
        if (split[1].length > 0) {
            letter = split[1][0] + '.'
        }
    }
    return `${firstName || ''} ${firstLastname || ''} ${letter}`
}
