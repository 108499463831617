import Alert from '../Alert'
import Icon from '../Icon'
import { PropsBlockAlert } from './types'

export default function BlockAlert({
    children,
    open,
    type = null,
    onClose
}: PropsBlockAlert) {
    return (
        <div className="relative">
            {open && (
                <Alert type={type} className="my-0">
                    {children}
                    <Icon
                        name="close"
                        className="absolute right-8 top-5"
                        pointer
                        onClick={onClose}
                    />
                </Alert>
            )}
        </div>
    )
}
