import { formInformation, reqCatalogueCompany } from '../../storage'
import useGet from '../../../../hooks/useGet'
import { ProductProviderCountryCompany } from '../../types'
import buildCompanyName from '../../../../utilities/buildCompanyName'
import { useEffect } from 'react'

export default function useStowageDestination() {
    const { props } = formInformation.useSelect('catalogue_company')
    const { data } = useGet<ProductProviderCountryCompany[]>(
        reqCatalogueCompany.observer(
            '/v5.6/apis/product_provider_countries/companies'
        ),
        []
    )

    const catalogueCompanies = data.result
        .filter(catalogue_company => catalogue_company.is_enabled)
        .map(catalogue_company => ({
            id: catalogue_company.company.id,
            name: buildCompanyName(catalogue_company.company.name),
            image: catalogue_company.company.country.image_url
        }))

    useEffect(() => {
        if (reqCatalogueCompany.hasQuery('provider_id')) {
            reqCatalogueCompany.load()
        }
    }, [])

    return {
        props,
        catalogueCompanies
    }
}
