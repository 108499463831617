import { useHistory } from 'react-router-dom'
import Icon from '../Icon'
import useBreadcrumb, { BreadcrumbItem } from './useBreadcrumb'

export default function Breadcrumb() {
    const { push } = useHistory()
    const items: BreadcrumbItem[] = useBreadcrumb()
    return (
        <ul>
            {items.map((item, i) => (
                <li key={i}>
                    <a
                        className={item.path ? '' : 'pointer-events-none'}
                        onClick={() => push(item.path)}
                    >
                        {item.icon && (
                            <Icon
                                name={item.icon}
                                current="stroke"
                                className="mr-2 text-black"
                            />
                        )}
                        {item.label}
                    </a>
                </li>
            ))}
        </ul>
    )
}
