import { ModalTools } from '../../../../sections/ModalSection/types'
import { formCreditLine, tableCreditLine } from '../../storage'
import { TableCreditLineData } from '../../types'
import FormCreditLine from './FormCreditLine'

export default function useCreditLine(index?: number) {
    function onSubmit(ev, modalTools: ModalTools) {
        ev.preventDefault()
        const result = formCreditLine.test(v => {
            v.amount.isEmpty('Se requiere ingresar el monto')
            v.currency.isEmpty('Se requiere seleccionar la moneda')
            v.company.isEmpty('Se requiere seleccionar la compañía')
        })

        if (!result) return

        const { currency, amount, company } = formCreditLine.store()

        const data: TableCreditLineData = {
            id: 1,
            symbol: '$/',
            amount: parseInt(amount),
            company: {
                name: company.label,
                id: parseInt(company.value),
                image: company.image
            },
            currency_id: parseInt(currency.value),
            currency_name: currency.label
        }

        if (index === undefined) {
            tableCreditLine.push(data)
        } else {
            tableCreditLine.put(index, data)
        }
        modalTools.close()
    }

    return formCreditLine.useModal(FormCreditLine, {
        size: 'sm',
        onSubmit,
        title:
            index === undefined
                ? 'Agregar Línea de Crédito'
                : 'Editar Línea de Crédito'
    })
}
