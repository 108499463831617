import { memo } from 'react'
import { Empty, FormControl } from '../../../../../../components/FormUtilities'
import Option from '../../../../../../components/Option'
import SelectTouch from '../../../../../../components/SelectTouch'
import { Option as OptionType } from '../../../../../../config/types/component'
import useStorage from '../../../../../../hooks/useStorage'
import Currency from '../../../../../../models/Currency'

function FieldCurrency({
    currentCurrency,
    onChange,
    helper
}: FieldCurrencyProps) {
    const { currencies } = useStorage()

    function changeHandler(option: OptionType) {
        const nextCurrency = currencies.find(
            currency => currency.id.toString() === option.value
        )
        onChange(nextCurrency)
    }

    return (
        <FormControl label="Moneda" required col="span-3">
            <SelectTouch
                onChange={changeHandler}
                placeholder="Seleccionar moneda"
                option={
                    currentCurrency
                        ? {
                              label: `${currentCurrency.name} (${currentCurrency.symbol})`,
                              value: currentCurrency.id.toString()
                          }
                        : null
                }
                helper={helper}
            >
                {currencies.map(currency => (
                    <Option
                        key={currency.id}
                        label={`${currency.name} (${currency.symbol})`}
                        value={currency.id.toString()}
                    />
                ))}
                <Empty data={currencies} />
            </SelectTouch>
        </FormControl>
    )
}

export default memo(FieldCurrency)

export interface FieldCurrencyProps {
    currentCurrency: Currency | null
    onChange: (nextCurrency: Currency) => void
    helper: string
}
