import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import TableDepartment from './TableDepartment'
import Initial from '../../../components/Initial'
import useInitial from '../useInitial'
import { PageHeader } from '../../../components/PagesUtilities'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import useSubmit from '../useSubmit'
import getListTables from '../utilities/getListTables'

export default function RolesUser() {
    const { user_id } = useParams<{ user_id: string }>()
    const { state, cancel } = useInitial(user_id)
    const { handleSubmit } = useSubmit(user_id)

    return (
        <Initial pageState={state}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title="Asignar roles al usuario" />
                    <PanelBody>
                        {getListTables().map(data => (
                            <Fragment key={data.department.id}>
                                <Typography
                                    type="header-3"
                                    className="mt-6 mb-4"
                                >
                                    {data.department.name}
                                </Typography>
                                <TableDepartment data={data} />
                            </Fragment>
                        ))}
                    </PanelBody>
                </Panel>
            </div>
            <PageHeader subtitle="Seleccione los roles para el usuario">
                <Button color="ghost" className="ml-2" onClick={cancel}>
                    Cancelar
                </Button>
                <Button className="ml-2" onClick={handleSubmit}>
                    Guardar
                </Button>
            </PageHeader>
        </Initial>
    )
}
