import classNames from 'classnames'
import { Moment } from 'moment'
import { useContext } from 'react'
import Context from '../context'
import { PropsDay } from '../type'

export default function Day({ day, viewDate }: PropsDay) {
    const { date, maxDate, minDate, onChange } = useContext(Context)
    function handleSelect(_day: Moment) {
        onChange(_day.toISOString(true))
    }
    const active = date
        ? day.format('DD-MM-YYYY') === date.format('DD-MM-YYYY')
        : false

    let disabledMin = false
    if (minDate) {
        const m = minDate.format('YYYY-MM-DD')
        disabledMin = day.isBefore(m)
    }
    let disabledMax = false
    if (maxDate) {
        const m = maxDate.format('YYYY-MM-DD')
        disabledMax = day.isAfter(m)
    }
    const otherMonth = date ? viewDate.month() !== day.month() : false
    return (
        <div
            className={classNames(
                `text-base h-6 w-full m-auto cursor-pointer rounded-full transition-all delay-50 hover:border-transparent hover:bg-primary hover:text-white hover:w-6`,
                {
                    'rounded-full border-transparent text-white bg-primary cursor-pointer w-6':
                        active,
                    'rounded-full border-transparent pointer-events-none opacity-20 w-6':
                        disabledMin || disabledMax,
                    'border-transparent text-gray-400 bg-white cursor-pointer':
                        otherMonth
                }
            )}
            onClick={() => handleSelect(day)}
        >
            {day.date()}
        </div>
    )
}
