export default function IconNotFound(props) {
    return (
        <svg
            width={150}
            height={150}
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M139.453 23.438H10.547a5.86 5.86 0 00-5.86 5.859V37.5h140.625v-8.203a5.85 5.85 0 00-1.715-4.144 5.855 5.855 0 00-4.144-1.715zM75 96.156c9.06 0 16.406-7.345 16.406-16.406 0-9.06-7.345-16.406-16.406-16.406-9.06 0-16.406 7.345-16.406 16.406 0 9.06 7.345 16.406 16.406 16.406z"
                fill="#C6C6C6"
            />
            <path
                d="M139.453 21.094H10.547a8.212 8.212 0 00-8.203 8.203v91.406a8.213 8.213 0 008.203 8.203h128.906a8.21 8.21 0 008.203-8.203V29.297a8.21 8.21 0 00-8.203-8.203zm3.516 99.609a3.52 3.52 0 01-3.516 3.516H10.547a3.52 3.52 0 01-3.516-3.516v-80.86H142.97v80.86zm0-85.547H7.031v-5.86a3.519 3.519 0 013.516-3.515h128.906a3.519 3.519 0 013.516 3.516v5.86z"
                fill="#151A6A"
            />
            <path
                d="M11.719 32.813h2.344a2.344 2.344 0 100-4.688h-2.344a2.343 2.343 0 100 4.688zM21.094 32.813h2.343a2.344 2.344 0 100-4.688h-2.343a2.343 2.343 0 100 4.688zM30.469 32.813h2.343a2.344 2.344 0 100-4.688H30.47a2.343 2.343 0 100 4.688zM75 98.5a18.75 18.75 0 10-18.75-18.75A18.771 18.771 0 0075 98.5zm0-32.813A14.063 14.063 0 1160.937 79.75 14.079 14.079 0 0175 65.687z"
                fill="#151A6A"
            />
            <path
                d="M67.483 87.267a2.342 2.342 0 003.315 0L75 83.065l4.202 4.202a2.344 2.344 0 003.315-3.315l-4.202-4.202 4.202-4.202a2.343 2.343 0 10-3.315-3.315L75 76.435l-4.202-4.202a2.343 2.343 0 10-3.315 3.315l4.202 4.202-4.202 4.202a2.343 2.343 0 000 3.315z"
                fill="#151A6A"
            />
        </svg>
    )
}
