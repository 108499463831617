import { render } from 'redity'
import {
    FormControl,
    FormControls
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import NumeralField from '../../../../../components/NumeralField'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../storage'
import { formDutyISCIVA, tableISCControl } from '../storageStep2'
import { Keys } from '../../../constants'

function PurchaseCost1({ isExchangeHidden }: { isExchangeHidden: boolean }) {
    const { props } = formDutyISCIVA.useInput('original_cost')

    return (
        <FormControl
            label="Monto ($)"
            col={isExchangeHidden ? 'span-3' : 'span-2'}
        >
            <InputField {...props} disabled />
        </FormControl>
    )
}

function ExchangeRate() {
    const { props } = formDutyISCIVA.useInput('exchange_to_local')

    function handleChange(ev) {
        props.onChange(ev)
        const { original_cost, exchange_to_local } = formDutyISCIVA.store()
        const total = Number(original_cost) * Number(exchange_to_local)
        formDutyISCIVA.setValue('total_cost', buildNumberFormat(total))
        tableISCControl.renderMain()
    }

    return (
        <FormControl
            label={`Tipo de cambio ($ a ${storagePage.currentCurrency.symbol})`}
            required
            col="span-2"
        >
            <NumeralField {...props} onChange={handleChange} decimal={4} />
        </FormControl>
    )
}

function PurchaseCost2({ isExchangeHidden }: { isExchangeHidden: boolean }) {
    const { props } = formDutyISCIVA.useInput('total_cost')
    const { symbol } = storagePage.currentCurrency

    return (
        <FormControl
            label={`Monto (${symbol})`}
            col={isExchangeHidden ? 'span-3' : 'span-2'}
        >
            <InputField {...props} disabled />
        </FormControl>
    )
}

function SettingLocalCost() {
    const { props } = formDutyISCIVA.useInput('setting_local_cost')
    const { symbol } = storagePage.currentCurrency

    function handleChange(ev) {
        props.onChange(ev)
        const { setting_local_cost, total_cost } = formDutyISCIVA.store()
        const final_duty = Number(total_cost) + Number(setting_local_cost)
        formDutyISCIVA.setValue(
            'final_local_cost',
            buildNumberFormat(final_duty)
        )
        render(Keys.TABLE_STEP_2_DUTY_SPECIFICATIONS)
    }

    return (
        <FormControl label={`Monto de ajuste (${symbol})`} col="span-3">
            <NumeralField
                {...props}
                onChange={handleChange}
                decimal={2}
                negative
            />
        </FormControl>
    )
}

function FinalDutyCost() {
    const { props } = formDutyISCIVA.useInput('final_local_cost')
    const { symbol } = storagePage.currentCurrency

    return (
        <FormControl label={`Monto final (${symbol})`} col="span-3">
            <InputField {...props} disabled />
        </FormControl>
    )
}

export default function Fields1({
    isExchangeHidden = false
}: {
    isExchangeHidden?: boolean
}) {
    return (
        <>
            <FormControls className="mb-4">
                <PurchaseCost1 isExchangeHidden={isExchangeHidden} />
                {!isExchangeHidden && <ExchangeRate />}
                <PurchaseCost2 isExchangeHidden={isExchangeHidden} />
            </FormControls>
            {isExchangeHidden && (
                <FormControls className="mb-4">
                    <SettingLocalCost />
                    <FinalDutyCost />
                </FormControls>
            )}
        </>
    )
}
