function Drag(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0v4h20V0H0zm20 16H0v-4h20v4zM4 13H2v2h2v-2zM2 3h2V1H2v2zm-2 7h20V6H0v4zm4-3H2v2h2V7z"
            />
        </svg>
    )
}

export default Drag
