import {
    FormControl,
    FormControls,
    IterateOptions
} from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import SelectMultiple from '../../../components/SelectMultiple'
import SelectTouch from '../../../components/SelectTouch'
import useStorage from '../../../hooks/useStorage'
import Country from '../../../models/Country'
import { formAgent } from '../storage'

function Agent() {
    const { props, getError } = formAgent.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un agente con el mismo nombre'
    )
    return (
        <FormControl label="Agente" required>
            <InputField
                {...props}
                placeholder="Ingresar nombre del agente"
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

function CountrySelect() {
    const { props, data } = formAgent.useSelect<Country>(
        'country',
        '/v5.6/apis/countries'
    )
    return (
        <FormControl label="País de ubicación" required>
            <SelectTouch
                {...props}
                label="Seleccionar"
                placeholder="Seleccionar país"
            >
                <IterateOptions data={data} label="name" image="image_url" />
            </SelectTouch>
        </FormControl>
    )
}

function TypeAgent() {
    const { props } = formAgent.useSelects('type_agents')
    const { storage } = useStorage()
    return (
        <FormControl label="Tipo de agente" required col="span-6">
            <SelectMultiple
                {...props}
                placeholder="Seleccionar tipo de agente"
                label="Seleccionar"
                selectTouchInherit={true}
            >
                <IterateOptions data={storage.typeAgents} label="name" />
            </SelectMultiple>
        </FormControl>
    )
}

function Address() {
    const { props } = formAgent.useInput('fiscal_address')
    return (
        <FormControl label="Dirección fiscal" required col="span-6">
            <InputField
                {...props}
                placeholder="Ingresar dirección fiscal del agente"
                maxLength={300}
            />
        </FormControl>
    )
}

export default function Fields() {
    return (
        <FormControls>
            <Agent />
            <CountrySelect />
            <TypeAgent />
            <Address />
        </FormControls>
    )
}
