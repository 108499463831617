import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import Ul from '../../../../components/Ul'
import TablePrices from './TablePrices'
import { storagePage } from '../../storage'

export default function Step1() {
    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Detalle de precio del producto" />
                <PanelBody>
                    <Ul className="mb-4">
                        <li>
                            Producto:
                            <b className="ml-2">
                                {
                                    storagePage.productPrice.product_country
                                        .product.name
                                }
                            </b>
                        </li>
                        <li>
                            Código:
                            <b className="ml-2">
                                {
                                    storagePage.productPrice.product_country
                                        .product.code
                                }
                            </b>
                        </li>
                    </Ul>
                    <TablePrices></TablePrices>
                </PanelBody>
            </Panel>
        </>
    )
}
