import { Marker } from '@react-google-maps/api'
import { PropsMarkerPoint } from './types'

export default function MarkerPoint({ onMarker, ...props }: PropsMarkerPoint) {
    function handleonDragEnd(ev: google.maps.MapMouseEvent) {
        const lat = ev.latLng.lat()
        const lng = ev.latLng.lng()
        onMarker({ lat, lng })
    }

    return <Marker {...props} draggable onDragEnd={handleonDragEnd} />
}
