import classnames from 'classnames'
import { ReactNode } from 'react'
import Icon from '../Icon'

export interface DataNotify {
    key: string
    title: string
    children: ReactNode
    type: 'success' | 'warning' | 'danger' | 'error' | 'info'
    show: boolean
    onClick: (ev: any) => void
}

export interface PropsNotification {
    data: DataNotify[]
    show: boolean
}

export default function Notification({ data }: PropsNotification) {
    return (
        <div className="fixed top-0 right-4 pt-4 px-4 overflow-hidden z-50">
            {data.map(({ key, onClick, type, children, show }) => (
                <div
                    key={key}
                    className={classnames(
                        'flex overflow-hidden bg-white rounded-md shadow-lg dark:bg-gray-800 mb-4 notification-fade-in items-center w-96 border',
                        {
                            'opacity-0': !show,
                            'opacity-100': show
                        }
                    )}
                    style={{ height: '64px' }}
                >
                    <div className="flex items-center px-3">
                        <div
                            className={classnames('h-12 rounded-full', {
                                'bg-green-500': type === 'success',
                                'bg-yellow-400': type === 'warning',
                                'bg-red-500':
                                    type === 'error' || type === 'danger',
                                'bg-secondary': type === 'info'
                            })}
                            style={{
                                width: '4px'
                            }}
                        ></div>
                        {type === 'success' && (
                            <Icon
                                name="check-cirle"
                                className="text-green-500 ml-3"
                                size={20}
                            />
                        )}
                        {(type === 'error' || type === 'danger') && (
                            <Icon
                                name="times"
                                className="text-red-500 ml-3"
                                size={20}
                            />
                        )}
                        {type === 'info' && (
                            <Icon
                                name="circle-exclamation"
                                className="text-secondary ml-3"
                                size={20}
                            />
                        )}
                        {type === 'warning' && (
                            <Icon
                                name="warning"
                                className="text-yellow-400 ml-3"
                                size={20}
                            />
                        )}
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-200 font-univers-light pr-14">
                        {children}
                    </p>
                    <Icon
                        name="close"
                        className="absolute right-5 text-gray-500"
                        size={12}
                        pointer
                        onClick={onClick}
                    />
                </div>
            ))}
        </div>
    )
}
