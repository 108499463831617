import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { GetInvoice } from '../type'

export default function Products({ data }: { data: GetInvoice }) {
    return (
        <div className="body__tables">
            <div className="column">
                <div className="card_info">
                    <h3>PRODUCTOS</h3>
                    <table className="table_info">
                        <thead>
                            <tr>
                                <th>Reng</th>
                                <th>Código</th>
                                <th>Descripción</th>
                                <th>Unidad</th>
                                <th>Cantidad</th>
                                <th>Precio unit.</th>
                                <th>Total</th>
                                {/* <th>%</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data.invoice_products.map((product, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{product.code}</td>
                                    <td>{product.description}</td>
                                    <td>UN</td>
                                    <td>{parseInt(product.cantidad)}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        {' '}
                                        {buildNumberFormat(
                                            parseFloat(product.unit_price)
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {' '}
                                        {buildNumberFormat(
                                            parseFloat(product.unit_price) *
                                                parseInt(product.cantidad)
                                        )}
                                    </td>
                                    {/* <td>7%</td> */}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td
                                    colSpan={6}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Subtotal
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {' '}
                                    {buildNumberFormat(data.subtotal)}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={6}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Total descuentos
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {buildNumberFormat(data.total_discounts)}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={6}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Total recargos
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {buildNumberFormat(data.total_recharges)}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={6}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Impuestos
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {' '}
                                    {buildNumberFormat(data.total_tax)}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={6}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Total
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {' '}
                                    {buildNumberFormat(data.total)}
                                </td>
                            </tr>
                            <tr></tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}
