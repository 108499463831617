import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import MoreH from './MoreH'
import MoreV from './MoreV'
import Location from './Location'
import ArrowBottom from './ArrowBottom'
import LockOpen from './LockOpen'
import Loop from './Loop'
import Warning from './Warning'
import Build from './Build'
import LockClose from './LockClose'
import Pencil from './Pencil'
import Close from './Close'
import CircleClose from './CircleClose'
import Trash from './Trash'
import Times from './Times'
import Save from './Save'
import Alert from './Alert'
import Alarm from './Alarm'
import Ban from './Ban'
import PowerOff from './PowerOff'
import Search from './Search'
import CloudUpload from './CloudUpload'
import HelpOutline from './HelpOutline'
import Hand from './Hand'
import Transfer from './Transfer'
import Assignment from './Assignment'
import AssignmentLate from './AssignmentLate'
import CheckCircle from './CheckCircle'
import LibraryBooks from './LibraryBooks'
import AssignmentReturned from './AssignmentReturned'
import AssignmentChecked from './AssignmentChecked'
import TransferWithStation from './TransferWithStation'
import Filter from './Filter'
import Alert2 from './Alert2'
import Menu from './Menu'
import Calendar from './Calendar'
import Print from './Print'
import History from './History'
import Ballot from './Ballot'
import Thermostate from './Thermostate'
import Boat from './Boat'
import Unarchive from './Unarchive'
import Label from './Label'
import MapPlace from './MapPlace'
import Plus from './Plus'
import Refresh from './Refresh'
import Dashboard from './Dashboard'
import Products from './Products'
import Shop from './Shop'
import Storage from './Storage'
import Message from './Message'
import Cart from './Cart'
import LocationCheck from './LocationCheck'
import LocationQuestion from './LocationQuestion'
import FindPage from './FindPage'
import Drag from './Drag'
import CircleWarning from './CircleWarning'
import Dollar from './Dollar'
import ArrowBack from './ArrowBack'
import ArrowForward from './ArrowForward'
import Clipboard from './Clipboard'
import Comments from './Comments'
import Building from './Building'
import StickyNote from './StickyNote'
import Flag from './Flag'
import Loading from './Loading'
import Bell from './Bell'
import Directory from './Directory'
import Avatar from './Avatar'
import MessageOpen from './MessageOpen'
import WifiOff from './WifiOff'
import MessageClose from './MessageClose'
import Download from './Download'
import AllInbox from './AllInbox'
import Grain from './Grain'
import Logout from './Logout'
import User from './User'
import Done from './Done'
import DeleteSweep from './DeleteSweep'
import LocalOffer from './LocalOffer'
import More from './More'
import Point from './Point'
import Star from './Star'
import Send from './Send'
import ThumbUp from './ThumbUp'

import { IconName, PropsIcon } from './types'
import { CSSProperties } from 'react'
import classnames from 'classnames'
import Money from './Money'
import Configuration from './Configuration'
import Eye from './Eye'
import ThumbDown from './ThumbDown'
import LocalShipping from './LocalShipping'
import EventBusy from './EventBudy'
import AirPort from './AirPort'
import Description from './Description'
import {
    ChevronDownIcon,
    ChevronUpIcon,
    BeakerIcon
} from '@heroicons/react/solid'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import {
    BsBinoculars,
    BsTable,
    BsCardChecklist,
    BsCurrencyDollar,
    BsCoin,
    BsCurrencyExchange,
    BsArrowRepeat,
    BsLock,
    BsUnlock,
    BsArrowLeftRight,
    BsDiagram3,
    BsFiles,
    BsFileSpreadsheet,
    BsGear,
    BsInbox,
    BsTag,
    BsTruck,
    BsPencil,
    BsPrinter,
    BsWrench,
    BsTrash,
    BsEye,
    BsBagX,
    BsHandThumbsUp,
    BsHandThumbsDown,
    BsCursor,
    BsThreeDots,
    BsCalendarX,
    BsDownload,
    BsExclamationTriangle,
    BsCartX,
    BsFileEarmarkExcel,
    BsX,
    BsXLg,
    BsXCircle
} from 'react-icons/bs'

const Icons: Record<IconName, (props: any) => JSX.Element> = {
    'bs-x-circle': BsXCircle,
    'bs-x-lg': BsXLg,
    'bs-x': BsX,
    'bs-file-earmark-excel': BsFileEarmarkExcel,
    'bs-cart-x': BsCartX,
    'bs-exclamation-triangle': BsExclamationTriangle,
    'bs-download': BsDownload,
    'bs-calendar-x': BsCalendarX,
    'bs-binoculars': BsBinoculars,
    'bs-table': BsTable,
    'bs-card-check-list': BsCardChecklist,
    'bs-currency-dollar': BsCurrencyDollar,
    'bs-coin': BsCoin,
    'bs-currency-exchange': BsCurrencyExchange,
    'bs-arrow-repeat': BsArrowRepeat,
    'bs-lock': BsLock,
    'bs-unlock': BsUnlock,
    'bs-three-dots': BsThreeDots,
    'bs-cursor': BsCursor,
    'bs-hand-thumbs-down': BsHandThumbsDown,
    'bs-hand-thumbs-up': BsHandThumbsUp,
    'bs-bag-x': BsBagX,
    'bs-eye': BsEye,
    'bs-trash': BsTrash,
    'bs-wrench': BsWrench,
    'bs-printer': BsPrinter,
    'bs-pencil': BsPencil,
    'bs-files': BsFiles,
    'bs-inbox': BsInbox,
    'bs-truck': BsTruck,
    'bs-file-spread-sheet': BsFileSpreadsheet,
    'bs-tag': BsTag,
    'bs-diagram-3': BsDiagram3,
    'bs-arrow-left-right': BsArrowLeftRight,
    'bs-gear': BsGear,
    'ai-outline-shopping-cart': AiOutlineShoppingCart,
    'chevron-down': ChevronDownIcon,
    'chevron-up': ChevronUpIcon,
    beaker: BeakerIcon,
    'arrow-left': ArrowLeft,
    'arrow-right': ArrowRight,
    'more-h': MoreH,
    'more-v': MoreV,
    location: Location,
    'arrow-bottom': ArrowBottom,
    'lock-open': LockOpen,
    loop: Loop,
    warning: Warning,
    build: Build,
    'lock-close': LockClose,
    pencil: Pencil,
    close: Close,
    'circle-close': CircleClose,
    trash: Trash,
    times: Times,
    save: Save,
    alert: Alert,
    alarm: Alarm,
    ban: Ban,
    'power-off': PowerOff,
    search: Search,
    'cloud-upload': CloudUpload,
    'help-outline': HelpOutline,
    hand: Hand,
    transfer: Transfer,
    assignment: Assignment,
    'assignment-late': AssignmentLate,
    'assignment-checked': AssignmentChecked,
    'check-cirle': CheckCircle,
    'library-books': LibraryBooks,
    'assignment-returned': AssignmentReturned,
    'transfer-with-station': TransferWithStation,
    filter: Filter,
    alert2: Alert2,
    menu: Menu,
    calendar: Calendar,
    print: Print,
    history: History,
    ballot: Ballot,
    thermostate: Thermostate,
    boat: Boat,
    unarchive: Unarchive,
    label: Label,
    mapplace: MapPlace,
    plus: Plus,
    refresh: Refresh,
    dashboard: Dashboard,
    products: Products,
    shop: Shop,
    storage: Storage,
    message: Message,
    cart: Cart,
    'location-check': LocationCheck,
    'location-question': LocationQuestion,
    'find-page': FindPage,
    drag: Drag,
    'circle-exclamation': CircleWarning,
    dollar: Dollar,
    'arrow-back': ArrowBack,
    'arrow-forward': ArrowForward,
    clipboard: Clipboard,
    comments: Comments,
    building: Building,
    'sticky-note': StickyNote,
    flag: Flag,
    loading: Loading,
    bell: Bell,
    directory: Directory,
    avatar: Avatar,
    'message-open': MessageOpen,
    'wifi-off': WifiOff,
    'message-close': MessageClose,
    download: Download,
    'all-inbox': AllInbox,
    grain: Grain,
    logout: Logout,
    user: User,
    done: Done,
    'delete-sweep': DeleteSweep,
    'local-offer': LocalOffer,
    more: More,
    point: Point,
    star: Star,
    money: Money,
    configuration: Configuration,
    eye: Eye,
    send: Send,
    thumb_up: ThumbUp,
    thumb_down: ThumbDown,
    local_shipping: LocalShipping,
    event_busy: EventBusy,
    'air-port': AirPort,
    description: Description
}

export default function Icon({
    name,
    size = 17,
    pointer = false,
    disabled = false,
    className = '',
    current = 'fill',
    ...props
}: PropsIcon) {
    const style: CSSProperties = {
        fontSize: `${size}px`,
        ...(pointer && { cursor: 'pointer' }),
        ...(disabled && {
            pointerEvents: 'none',
            opacity: '.5',
            cursor: 'default'
        }),
        display: 'inline'
    }

    const nextProps = {
        style,
        className: classnames(`inline-block ${current}-current`, className),
        ...props
    }
    const Component = Icons[name]
    return <Component {...nextProps} />
}
