import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { controlList, reqYears } from './storage'
import moment from 'moment'
import { getCompanySelected } from '../../utilities/controlStorage'

export default function useInitial() {
    const [pageState, setPageState] = useState(StateCase.LOADING)
    const year = moment().format('YYYY')

    useEffect(() => {
        controlList.filter.changeParams('sort', 'id')
        const company = getCompanySelected()

        controlList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })

        controlList.filter.setOption('year', {
            value: year,
            label: year
        })

        controlList.filter.setParams('year', year)
        reqYears.setParam('company_id', company.id)

        controlList.load().then(response => {
            if (response) {
                setPageState(StateCase.SUCCESS)
            } else {
                setPageState(StateCase.ERROR)
            }
        })
    }, [])

    return pageState
}
