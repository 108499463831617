import 'moment/locale/es'
import Ul from '../../../components/Ul'
import Badge from '../../../components/Badge'
import DataTable, { Caption, TBody, THead } from '../../../components/DataTable'
import Typography from '../../../components/Typography'
import moment from 'moment'
import {
    MinimalStowageItem,
    RequisitionView as RequisitionViewType
} from '../types'

export default function RequisitionView({
    data
}: {
    data: RequisitionViewType
}) {
    const color = {
        1: 'warning',
        2: 'error',
        3: 'success',
        4: 'warning'
    }
    return (
        <>
            <Typography type="header-3" className="mb-4">
                Información de la requisición
            </Typography>
            <div className="grid grid-cols-2 gap-4 mb-4">
                <Ul className="mb-4">
                    <li>
                        Compañía:
                        <b className="ml-2">
                            <img
                                src={data.document.company.country.image_url}
                                className="mr-2 w-6 inline-block"
                            />
                            {data.document.company.name}
                        </b>
                    </li>
                    <li>
                        Solicitante:{' '}
                        <b>
                            {`${data.document.requesting_user.name} ${data.document.requesting_user.lastname}`}
                        </b>
                    </li>
                    <li>
                        Bodega destino:{' '}
                        <b>{data.minimal_stowage.stowage.name}</b>
                    </li>
                </Ul>
                <Ul className="mb-4">
                    <li>
                        Proveedor:{' '}
                        <b>{data.provider_company.provider.business_name}</b>
                    </li>
                    <li>
                        Fecha de solicitud:{' '}
                        <b>
                            {moment(data.document.request_date).format(
                                'DD/MM/YYYY'
                            )}
                        </b>
                    </li>
                    <li>
                        Sub bodega destino:{' '}
                        <b>{getSubStowages(data.minimal_stowage)}</b>
                    </li>
                </Ul>
            </div>
            <Typography type="header-3" className="mb-4">
                Información de la aprobación
            </Typography>
            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>Rol</th>
                        <th>Usuario</th>
                        <th>Estado</th>
                        <th>Fecha de aprobación</th>
                    </tr>
                </THead>
                <TBody>
                    {data.document.document_approver_users.map((item, i) => (
                        <tr key={item.id}>
                            <td>{i + 1}</td>

                            <td>{item.user.role || '-'}</td>
                            <td>{`${item.user.name} ${item.user.lastname}`}</td>
                            <td>
                                <Badge
                                    color={
                                        color[
                                            item.type_user_document_approval_id
                                        ]
                                    }
                                >
                                    {item.type_user_document_approval.name}
                                </Badge>
                            </td>
                            <td>
                                {moment(item.created_at).format('DD/MM/YYYY')}
                            </td>
                        </tr>
                    ))}
                    {data.document.document_approver_users.length === 0 && (
                        <Caption colSpan={5}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
            <Typography type="header-3" className="mb-4 mt-4">
                Productos solicitados
            </Typography>
            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>Descripción del producto (ERP)</th>
                        <th>Descripción del producto (Proveedor)</th>
                        <th>Cantidad solicitante</th>
                    </tr>
                </THead>
                <TBody>
                    {data.classic_requisition_products.map((item, i) => (
                        <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td>
                                <Typography
                                    type="paragraph"
                                    className={'font-bold'}
                                >
                                    {item.requisition_product.product.name}
                                </Typography>
                                <Typography type="small" color="gray">
                                    {item.requisition_product.product.code}
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    type="paragraph"
                                    className={'font-bold'}
                                >
                                    {
                                        item.requisition_product
                                            .product_provider_company
                                            .provider_description
                                    }
                                </Typography>
                                <Typography type="small" color="gray">
                                    {
                                        item.requisition_product
                                            .product_provider_company
                                            .provider_code
                                    }
                                </Typography>
                            </td>
                            <td>{item.requisition_product.quantity} </td>
                        </tr>
                    ))}
                    {data.classic_requisition_products.length === 0 && (
                        <Caption colSpan={5}>
                            No se encontraron resultados
                        </Caption>
                    )}
                </TBody>
            </DataTable>
        </>
    )
}

function getSubStowages(minimal_stowage: MinimalStowageItem) {
    if (minimal_stowage.order_sub_stowage) {
        return <b>{minimal_stowage.order_sub_stowage.order.number}</b>
    } else if (minimal_stowage.gp_sub_stowage) {
        return <b>{minimal_stowage.gp_sub_stowage.purchase.code}</b>
    } else {
        return <b>{'-'}</b>
    }
}
