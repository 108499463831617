import { Option } from '../../../config/types/component'
import { tableDispatch } from '../storage'
import { Step3 } from '../types'

export function dispatchExist(option: Option, index?: number) {
    const tableDataDispatchs = tableDispatch.getDataList()

    if (index !== undefined) {
        return tableDataDispatchs.find(
            (item, i) =>
                item.place_dispatch_id === parseInt(option.value) &&
                item.place_dispatch === option.label &&
                i !== index
        )
    }
    return tableDataDispatchs.find(
        item =>
            item.place_dispatch_id === parseInt(option.value) &&
            item.place_dispatch === option.label
    )
}

export function addressExist(option: Option, index?: number) {
    const tableDataDispatchs = tableDispatch.getDataList()

    if (index !== undefined) {
        return tableDataDispatchs.find(
            (item, i) =>
                item.address_id === parseInt(option.value) &&
                item.address === option.label &&
                i !== index
        )
    }
    return tableDataDispatchs.find(
        item =>
            item.address_id === parseInt(option.value) &&
            item.address === option.label
    )
}

export function textAddressExist(
    value: string,
    form: Step3.FormDispatch,
    index?: number
) {
    const tableDataDispatchs = tableDispatch.getDataList()

    if (index !== undefined) {
        return tableDataDispatchs.find(
            (item, i) =>
                item.place_dispatch === form.place_dispatch_text &&
                item.address === form.address &&
                item.country.name === form.country.label &&
                i !== index
        )
    }

    return tableDataDispatchs.find(
        item =>
            item.place_dispatch === form.place_dispatch_text &&
            item.address === form.address &&
            item.country.name === form.country.label
    )
}
