import { useRender } from 'redity'
import Tooltip from '../../../../../components/Tooltip'
import { Keys } from '../../../constants'
import {
    formDutyISCIVA,
    tableDutyControl,
    tableISCControl,
    tableIVAControl
} from '../storageStep2'

export function DutySpecifications() {
    const list = tableDutyControl.getDataList()
    useRender(Keys.TABLE_STEP_2_DUTY_SPECIFICATIONS)

    return (
        <div className="flex items-center justify-end mt-4 mb-4 font-bold text-xs">
            <div className="bg-gray-200 py-2" style={{ width: '9%' }}>
                <Tooltip content="FOB">
                    $ {list.reduce((a, b) => a + b.fob, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '10%' }}>
                <Tooltip content="Flete">
                    $ {list.reduce((a, b) => a + b.freight, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '12%' }}>
                <Tooltip content="Seguro">
                    $ {list.reduce((a, b) => a + b.secure, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '30%' }}>
                <Tooltip content="CIF">
                    $ {list.reduce((a, b) => a + b.cif, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '20%' }}>
                <Tooltip content="Aranceles">
                    ${' '}
                    {list
                        .reduce(function (a, b) {
                            return a + (b.cif / 100) * Number(b.percentage)
                        }, 0)
                        .toFixed(2)}
                </Tooltip>
            </div>
        </div>
    )
}

export function IVASpecifications() {
    useRender(Keys.TABLE_STEP_2_IVA_SPECIFICATIONS)
    const list = tableIVAControl.getDataList()
    return (
        <div className="flex items-center justify-end mt-4 mb-4 font-bold text-xs">
            <div className="bg-gray-200 py-2" style={{ width: '11%' }}>
                <Tooltip content="CIF">
                    $ {list.reduce((a, b) => a + b.cif, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '25%' }}>
                <Tooltip content="Aranceles">
                    $ {list.reduce((a, b) => a + b.duty, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '8%' }}>
                <Tooltip content="ISC">
                    $ {list.reduce((a, b) => a + b.isc, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '25%' }}>
                <Tooltip content="Total">
                    $ {list.reduce((a, b) => a + b.total, 0).toFixed(2)}
                </Tooltip>
            </div>
            <div className="bg-gray-200 py-2" style={{ width: '10%' }}>
                <Tooltip content="IVA">
                    ${' '}
                    {list
                        .reduce(function (a, b) {
                            return a + (b.total / 100) * Number(b.iva_percent)
                        }, 0)
                        .toFixed(2)}
                </Tooltip>
            </div>
        </div>
    )
}

export function ISCSpecifications() {
    useRender(Keys.TABLE_STEP_2_ISC_SPECIFICATIONS)
    const list = tableISCControl.getDataList()
    const { exchange_to_local } = formDutyISCIVA.store()

    return (
        <div className="flex justify-end mt-4 mb-4 font-bold text-xs">
            <div className="bg-gray-200 py-2 px-4 w-52">
                ${' '}
                {list
                    .reduce((a, b) => a + Number(b.original_cost), 0)
                    .toFixed(2)}
            </div>
            <div className="bg-gray-200 py-2 w-52 px-16 text-right col-span-2">
                ${' '}
                {(
                    list.reduce((a, b) => a + Number(b.original_cost), 0) *
                    Number(exchange_to_local)
                ).toFixed(2)}
            </div>
        </div>
    )
}
