import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { storagePage } from '../../../storage'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { LogisticalDeclarationPDFData } from '../../../types'
import HelveticaBold from '../../../../../assets/fonts/HelveticaBold.otf'
import { generalStyles } from '../GeneralStyles'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const styles = StyleSheet.create({
    ...generalStyles,
    container_extra_international_costs: {
        marginTop: '14px'
    },
    cellCount: {
        width: '6%'
    },
    cellConcept: {
        width: '25%'
    },
    cellAgent: {
        display: 'flex',
        flexDirection: 'column',
        width: '21%'
    },
    cellAmountDolar: {
        width: '18%'
    },
    cellExchange: {
        width: '12%'
    },
    cellAmountLocal: {
        width: '18%'
    },
    cellFooterName: {
        width: '58%',
        fontFamily: 'helvetica-bold'
    },
    cell100: {
        width: '100%',
        textAlign: 'center'
    }
})

export default function TableExtraInternationalCosts({
    data
}: {
    data: LogisticalDeclarationPDFData
}) {
    const sym = storagePage.currency

    return (
        <View style={styles.container_extra_international_costs}>
            <Text style={styles.container__title}>
                Costos Internacionales Adicionales
            </Text>
            <View style={styles.table}>
                <View style={styles.row}>
                    <Text style={[styles.table__th, styles.cellCount]}>#</Text>
                    <Text style={[styles.table__th, styles.cellConcept]}>
                        Concepto
                    </Text>
                    <Text style={[styles.table__th, styles.cellAgent]}>
                        Agente
                    </Text>
                    <Text style={[styles.table__th, styles.cellAmountDolar]}>
                        Monto
                    </Text>
                    <Text style={[styles.table__th, styles.cellExchange]}>
                        TC
                    </Text>
                    <Text style={[styles.table__th, styles.cellAmountLocal]}>
                        Monto
                    </Text>
                </View>
                {data.extra_international_costs.map((d, i) => (
                    <View key={i} style={styles.row}>
                        <Text style={[styles.table__td, styles.cellCount]}>
                            {i + 1}
                        </Text>
                        <Text style={[styles.table__td, styles.cellConcept]}>
                            {d.logistical_concept.name}
                        </Text>
                        <View style={[styles.table__td, styles.cellAgent]}>
                            {d.agent && <Text>{d.agent.name}</Text>}
                            {d.agent && <Text>{d.document_number}</Text>}
                            {!d.agent && <Text>-</Text>}
                        </View>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellAmountDolar,
                                styles.textRight
                            ]}
                        >
                            {d.currency.symbol}{' '}
                            {buildNumberFormat(d.original_cost)}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellExchange,
                                styles.textRight
                            ]}
                        >
                            {buildNumberFormat(d.exchange_to_local)}
                        </Text>
                        <Text
                            style={[
                                styles.table__td,
                                styles.cellAmountLocal,
                                styles.textRight
                            ]}
                        >
                            {sym} {''}
                            {buildNumberFormat(d.local_cost)}
                        </Text>
                    </View>
                ))}
                {data.extra_international_costs.length === 0 && (
                    <View style={styles.row}>
                        <Text style={[styles.table__td, styles.cell100]}>
                            Sin costos registrados
                        </Text>
                    </View>
                )}
            </View>
        </View>
    )
}
