import { SVGProps } from 'react'

const ThumbDown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5.667H5c-.691 0-1.283.416-1.533 1.016L.95 7.558a1.646 1.646 0 0 0-.117.608v1.667c0 .917.75 1.667 1.667 1.667h5.258l-.791 3.808-.025.267c0 .341.141.658.367.883l.883.875 5.492-5.491c.3-.3.483-.717.483-1.176V2.333c0-.917-.75-1.666-1.667-1.666Zm0 10-3.617 3.616L10 9.833H2.5V8.166L5 2.333h7.5v8.334Zm6.667-10h-3.334v10h3.334v-10Z"
        />
    </svg>
)

export default ThumbDown
