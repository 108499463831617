import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import Initial from '../../../components/Initial'
import { StateCase } from '../../../config/enums'
import CreditLine from './CreditLine'
import PaymentConditions from './PaymentConditions'
import PaymentMethods from './PaymentMethods'
import useCancel from '../../../hooks/useCancel'

export default function FinancialInformation() {
    const { onClick } = useCancel([], '/configuration/providers')
    return (
        <Initial pageState={StateCase.SUCCESS}>
            <div className="container_page--form">
                <CreditLine />
                <PaymentConditions />
                <PaymentMethods />
            </div>
            <PageHeader subtitle="(*) Complete todos los campos para la información financiera">
                <Button color="ghost" onClick={onClick}>
                    Cancelar
                </Button>
                <Button className="ml-2">Guardar</Button>
            </PageHeader>
        </Initial>
    )
}
