import { Popup } from '../../../../utilities/popup'
import submit from '../../../../utilities/submit'
import {
    controlTabs,
    formConfig,
    storagePage,
    tableCompaniesControl
} from '../../storage'
import { Step2 } from '../../types'
import { initStep2 } from '../../utilities/initialEdition'

export default function useStep2Submit() {
    const { mode, step2Done } = storagePage

    async function handleSubmit() {
        const providerId = storagePage.providerId
        const { specific_packing_list, specific_tracking_number } =
            formConfig.store()
        const provider_companies = tableCompaniesControl
            .getDataList()
            .map(tc => ({
                company_id: tc.id,
                is_enabled: tc.is_enabled
            }))

        const body: Step2.BodyConfig = {
            specific_packing_list,
            specific_tracking_number,
            provider_companies
        }

        const { success, error, data } = await submit<Step2.ResponseResult>(
            `/v5.6/apis/providers/${providerId}/availability`,
            {
                body,
                message: '¿Está seguro de guardar cambios en Configuraciones?',
                method: mode === 'edition' || step2Done ? 'put' : 'post'
            }
        )

        if (error) {
            Popup.error(
                'Algo salió mal...',
                'Estamos trabajando para resolver el problema. Por favor intenta nuevamente realizar la operación.'
            )
        }

        if (success) {
            storagePage.step2Done = true
            if (storagePage.mode === 'edition') {
                storagePage.provider = {
                    ...storagePage.provider,
                    ...data
                }
            }
            initStep2(data)
            controlTabs.next()
        }
    }

    return handleSubmit
}
