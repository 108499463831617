import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import NumeralField from '../../../../components/NumeralField'
import Option from '../../../../components/Option'
import SelectTouch from '../../../../components/SelectTouch'
import useStorage from '../../../../hooks/useStorage'
import { formCreditLine } from '../../storage'

function Line() {
    const { props } = formCreditLine.useInput('amount')
    return (
        <FormControl label="Monto de línea" required className="mb-2">
            <NumeralField
                {...props}
                decimal={3}
                max={999999999999}
                placeholder="Ingresar monto de línea"
            />
        </FormControl>
    )
}

function Currency() {
    const { props } = formCreditLine.useSelect('currency')
    return (
        <FormControl label="Moneda" required className="mb-2">
            <SelectTouch
                {...props}
                placeholder="Seleccionar moneda"
                label="Moneda"
            >
                <Option value="s" label="asf" />
            </SelectTouch>
        </FormControl>
    )
}

function Company() {
    const { props } = formCreditLine.useSelect('company')
    const { storage } = useStorage()
    return (
        <FormControl label="Compañía aplicables" required>
            <SelectTouch
                {...props}
                placeholder="Seleccionar compañías"
                label="Seleccionar compañías"
            >
                <IterateOptions
                    data={storage.companies.map(c => ({
                        ...c,
                        image_url: c.country.image_url
                    }))}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}

export default function FormCreditLine() {
    return (
        <>
            <Line />
            <Currency />
            <Company />
        </>
    )
}
