import classNames from 'classnames'
import { PropsRadioButton } from './types'

export default function RadioButton({
    color = 'primary',
    className,
    ...props
}: PropsRadioButton) {
    return (
        <input
            {...props}
            type="radio"
            className={classNames(`radio radio-sm radio-${color}`, className)}
        />
    )
}
