import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { storagePage } from '../../storage'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { BalanceHistory } from '../../types'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '4px'

const styles = StyleSheet.create({
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC',
        borderBottom: '1px solid #CCCCCC'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    table__td: {
        display: 'flex',
        flexDirection: 'row',
        padding: '6px',
        fontSize: fontSize,
        borderLeft: '1px solid #CCCCCC'
    },
    table__th: {
        backgroundColor: '#CCCCCC',
        padding: '4px',
        fontSize: fontSize,
        fontFamily: 'helvetica-bold'
    },
    textRight: {
        textAlign: 'right'
    },
    cellCount: {
        width: '30px'
    },
    cellProductCode: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100px'
    },
    cellProductDescription: {
        width: '160px'
    },
    cellCategory: {
        width: '80px'
    },
    cellQuantity: {
        width: '45px'
    },
    cellAverageCost: {
        width: '80px'
    },
    cellBalance: {
        width: '80px'
    },
    cellNormal: {
        width: '60px',
        wrap: true
    },
    tam_1: {
        width: '20px',
        wrap: true
    },
    tam_2: {
        width: '48px',
        wrap: true
    },
    tam_3: {
        width: '48px',
        wrap: true
    },
    tam_4: {
        width: '60px',
        wrap: true
    },
    tam_5: {
        width: '60px',
        wrap: true
    },
    tam_6: {
        width: '60px',
        wrap: true
    },
    tam_7: {
        width: '60px',
        wrap: true
    },
    tam_8: {
        width: '60px',
        wrap: true
    },
    tam_9: {
        width: '60px',
        wrap: true
    },
    tam_10: {
        width: '60px',
        wrap: true
    },
    tam_11: {
        width: '60px',
        wrap: true
    },
    tam_12: {
        width: '60px',
        wrap: true
    },
    tam_13: {
        width: '60px',
        wrap: true
    }
})

export default function Body({ data }: { data: any[] }) {
    const sym = storagePage.currency.symbol

    return (
        <View style={styles.table}>
            <View style={styles.row}>
                <Text style={[styles.table__th, styles.tam_1]}>#</Text>
                <Text style={[styles.table__th, styles.tam_2]}>OC</Text>
                <Text style={[styles.table__th, styles.tam_3]}>
                    Estado de OC
                </Text>
                <Text style={[styles.table__th, styles.tam_4]}>
                    Código de producto
                </Text>
                <Text style={[styles.table__th, styles.tam_5]}>
                    Descripción del producto
                </Text>
                <Text style={[styles.table__th, styles.tam_6]}>Proveedor</Text>
                <Text style={[styles.table__th, styles.tam_7]}>
                    Fecha de confirmación de OC
                </Text>
                <Text style={[styles.table__th, styles.tam_8]}>
                    Requisición
                </Text>
                <Text style={[styles.table__th, styles.tam_9]}>
                    Bodega destino
                </Text>
                <Text style={[styles.table__th, styles.tam_10]}>Cliente</Text>
                <Text style={[styles.table__th, styles.tam_11]}>
                    Cantidad en tránsito
                </Text>
                <Text style={[styles.table__th, styles.tam_12]}>
                    Precio unitario
                </Text>
                <Text style={[styles.table__th, styles.tam_13]}>
                    Precio total
                </Text>
            </View>
            {data.map((d, i) => (
                <View key={i} style={styles.row}>
                    <Text style={[styles.table__td, styles.tam_1]}>
                        {i + 1}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_2]}>
                        {d.purchase_code}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_3]}>
                        {d.purchase_status_name}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_4]}>
                        {d.product_code}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_5]}>
                        {d.product_description}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_6]}>
                        {d.provider_business_name}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_7]}>
                        {d.purchase_confirmation_date
                            ? d.purchase_confirmation_date
                                  .replace(/T/, ' ')
                                  .replace(/\.\d+.*$/, '')
                            : ''}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_8]}>
                        {d.requisition_code}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_9]}>
                        {d.destination_stowage_name}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_10]}>
                        {d.client_name}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_11]}>
                        {d.quantity}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_12]}>
                        {d.unit_price}
                    </Text>
                    <Text style={[styles.table__td, styles.tam_13]}>
                        {d.total_price.toFixed(2)}
                    </Text>
                </View>
            ))}
        </View>
    )
}
