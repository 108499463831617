import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('FINANCIAL_INFORMATION', [
    'TABLE_CREDIT_LINE',
    'FORM_CREDIT_LINE',
    'TABLE_PAYMENT_CONDITIONS',
    'FORM_PAYMENT_CONDITION',
    'TABLE_PAYMENT_METHODS',
    'FORM_PAYMENT_METHOD'
])
