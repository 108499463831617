import Icon from '../../../../components/Icon'
import useRemove from '../../../../hooks/useRemove'
import { TableList } from '../../../../sections/ListForm'
import { tableCreditLine } from '../../storage'
import { TableCreditLineData } from '../../types'
import useCreditLine from './useCreditLine'

function Edit({ index, data }: { index: number; data: TableCreditLineData }) {
    const open = useCreditLine(index)
    return (
        <Icon
            name="bs-pencil"
            className="mr-2"
            pointer
            onClick={() =>
                open({
                    amount: data.amount.toString(),
                    company: {
                        value: data.company.id.toString(),
                        label: data.company.name
                    },
                    currency: {
                        value: data.currency_id.toString(),
                        label: data.currency_name
                    }
                })
            }
        />
    )
}

export default function TableCreditLine() {
    const del = useRemove(tableCreditLine)
    return (
        <TableList control={tableCreditLine} scrollHeight={250}>
            {(data, _, e) => (
                <tr key={e}>
                    <td>
                        {data.symbol}
                        {data.amount}
                    </td>
                    <td>{data.currency_name}</td>
                    <td>
                        <div className="flex">
                            <img
                                src={data.company.image}
                                className="w-8 mr-2"
                            />
                            <p>{data.company.name}</p>
                        </div>
                    </td>
                    <td>
                        <Edit data={data} index={e - 1} />
                        <Icon
                            name="bs-trash"
                            pointer
                            onClick={() => del(e - 1)}
                        />
                    </td>
                </tr>
            )}
        </TableList>
    )
}
