import { StateCase } from '../../config/enums'
import ContainerPage from '../ContainerPage'
import Loading from '../Loading'
import Page500 from '../Page500'
import Panel, { PanelBody } from '../Panel'
import { PropsInitial } from './types'

export default function Initial({
    pageState,
    children,
    fluid = false,
    mode = 'normal',
    loadingMessage = 'Cargando Datos'
}: PropsInitial) {
    if (pageState === StateCase.LOADING) {
        return (
            <ContainerPage fluid={fluid}>
                <Loading height="350px">{loadingMessage}</Loading>
            </ContainerPage>
        )
    }
    if (pageState === StateCase.ERROR) {
        return (
            <ContainerPage fluid={fluid}>
                <Panel mode={mode}>
                    <PanelBody>
                        <Page500 />
                    </PanelBody>
                </Panel>
            </ContainerPage>
        )
    }
    return <ContainerPage fluid={fluid}>{children}</ContainerPage>
}
