import ListFormControl from '../../sections/ListForm/ListFormControl'
import ControlRequest from '../../utilities/ControlRequest'
import ControlTabs from '../../utilities/ControlTabs'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { Step1, Step2, Step3, StoragePage } from './types'

export const controlTabs = new ControlTabs()

export const storagePage: StoragePage = {
    provider: null,
    providerId: null,
    mode: 'creation',
    step1Done: false,
    step2Done: false,
    currentAgentWithdrawalAddresses: []
}

/**
 * Step 1
 */

export const formInfo = new FormEvents<Step1.FormInfo>(Keys.FORM1, {
    business_name: '',
    commercial_name: '',
    type_tax_identification: null,
    tax_identification_number: '',
    country: null,
    link_web_page: '',
    fiscal_address: '',
    description: ''
})

formInfo.setParams('country', { limit: 10, sort: 'name' })

export const tableContactsControl =
    new ListFormControl<Step1.TableProviderContact>(Keys.TABLE_CONTACTS, [
        'Nombres',
        'Principal',
        'Cargo',
        'Teléfono',
        'Email',
        'Acciones'
    ])

export const formContact = new FormEvents<Step1.FormContact>(
    Keys.FORM_CONTACT,
    {
        name: '',
        position: '',
        phone_number: '',
        email: '',
        id: 1
    }
)

/**
 * Step 2
 */

export const tableCompaniesControl = new ListFormControl<Step2.TableCompany>(
    Keys.TABLE_COMPANIES,
    ['País', 'Compañía', 'Compra']
)

export const formConfig = new FormEvents<Step2.FormConfig>(Keys.FORM_CONFIG, {
    specific_packing_list: false,
    specific_tracking_number: false
})

/**
 * Step 3
 */

export const tableDispatch = new ListFormControl<Step3.TableDispatch>(
    Keys.TABLE_DISPATCH,
    [
        'País',
        'Lugar de despacho',
        'Tipo destino',
        'Principal',
        'Dirección',
        'Acciones'
    ]
)

export const formDispatch = new FormEvents<Step3.FormDispatch>(
    Keys.FORM_DISPATCH,
    {
        country: null,
        type_dispatch: null,
        place_dispatch: null,
        place_dispatch_text: '',
        address: '',
        address_select: null
    }
)

export const reqAgent = new ControlRequest()
export const reqWarehouse = new ControlRequest()

export const tableWithdrawal = new ListFormControl<Step3.TableWithdrawal>(
    Keys.TABLE_RETREAT,
    ['País', 'Lugar de retiro', 'Principal', 'Dirección', 'Acciones']
)

export const formWithdrawal = new FormEvents<Step3.FormWithdrawal>(
    Keys.TABLE_RETREAT,
    {
        country: null,
        place_retreat: '',
        address: ''
    }
)

formWithdrawal.setParams('country', {
    limit: 10,
    sort: 'name'
})
