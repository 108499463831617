import classNames from 'classnames'
import RegexTypes from '../../utilities/regexTypes'
import Helper from '../Helper'
import { PropsTextarea } from './types'

function expectValue(value: string, regex: RegExp) {
    return value.replace(regex, '')
}

export default function Textarea({
    helper = '',
    className,
    regex = RegexTypes.alphaNumber,
    maxLength = 3000,
    onChange = () => null,
    ...props
}: PropsTextarea) {
    function handleChange(ev) {
        const val = regex
            ? expectValue(ev.target.value, regex)
            : ev.target.value
        if (val === props.value) return
        onChange({
            ...ev,
            target: {
                ...ev.target,
                value: val
            }
        })
    }

    return (
        <>
            <textarea
                {...props}
                maxLength={maxLength}
                onChange={handleChange}
                className={classNames(
                    'textarea h-24 textarea-bordered',
                    {
                        'textarea-error': !!helper
                    },
                    className
                )}
            />
            <Helper>{helper}</Helper>
        </>
    )
}
