import { ChangeEvent, useState } from 'react'
import { useRender } from 'redity'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import SearchField from '../../../../components/SearchField'
import Typography from '../../../../components/Typography'
import buildNumberFormat from '../../../../utilities/buildNumberFormat'
import { Keys } from '../../constants'
import { reqRequisition, storagePage } from '../../storage'
import Table from './Table'

export default function ProductsToBuy() {
    return (
        <Panel mode="form">
            <PanelHeader title="Requisiciones de compra">
                <Mount />
            </PanelHeader>
            <PanelBody>
                <Search />
                <Table />
            </PanelBody>
        </Panel>
    )
}

function Mount() {
    useRender(Keys.MOUNT)
    const mount = Object.values(storagePage.req_products_actived)
        .map(values => values.provider_price * values.quantity_buy)
        .reduce((a, b) => a + b, 0)

    return <Typography type="header-2">{buildNumberFormat(mount)}</Typography>
}

function Search() {
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)

    async function handleChange(ev: ChangeEvent<HTMLInputElement>) {
        const val = ev.target.value
        setValue(val)
        if (val === '') {
            reqRequisition.deleteQuery('search')
        } else {
            reqRequisition.setQuery('search', val)
        }
        setLoading(true)
        const { error } = await reqRequisition.load()
        if (!error) {
            setLoading(false)
        }
    }

    async function handleClear() {
        setValue('')
        reqRequisition.deleteQuery('search')
        setLoading(true)
        const { error } = await reqRequisition.load()
        if (!error) {
            setLoading(false)
        }
    }

    return (
        <SearchField
            value={value}
            fullWidth
            loading={loading}
            className="mb-5"
            placeholder="Buscar por código de requisición, código o descripción de producto por proveedor"
            onChange={handleChange}
            onClear={handleClear}
        />
    )
}
