import { useContext } from 'react'
import BtnActionTable from '../../../components/BtnActionTable'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Option from '../../../components/Option'
import Context from './context'
import { PropsActionRunAccounting } from './types'

export default function RunAccounting({
    children,
    accept,
    label,
    notify = 'La operación se ha completado exitosamente',
    title = 'Generar asientos contables',
    ...props
}: PropsActionRunAccounting) {
    const { onClick, className, type, disabled } = useContext(Context)
    function handleClick() {
        onClick({
            ...props,
            title,
            notify,
            accept: accept || 'Sí, generar asientos',
            message: children
        })
    }

    return (
        <>
            {!label && (
                <BtnActionTable
                    title="Generar asientos contables"
                    iconName="bs-currency-dollar"
                    disabled={disabled}
                    onClick={handleClick}
                />
            )}
            {label && (
                <>
                    {type === 'option' && (
                        <Option
                            onClick={handleClick}
                            icon="bs-currency-dollar"
                            disabled={disabled}
                            value="0"
                            label={label}
                        />
                    )}
                    {type === 'button' && (
                        <Button
                            className={className}
                            onClick={handleClick}
                            size="sm"
                            color="success"
                            disabled={disabled}
                        >
                            <Icon
                                name="bs-currency-dollar"
                                size={14}
                                className="mr-1"
                            />{' '}
                            {label}
                        </Button>
                    )}
                </>
            )}
        </>
    )
}
