import { useEffect } from 'react'
import { storagePage } from './storage'

export default function useInitial() {
    useEffect(() => {
        return () => {
            storagePage.importState = 'nothing'
            storagePage.fields = []
            storagePage.file = null
            storagePage.messagesResult = []
            storagePage.reports = []
            storagePage.excelDataRendered = null
        }
    }, [])
}
