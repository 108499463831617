import profile from '../../assets/img/Elon_Musk.jpg'
import Icon from '../Icon'
import useOut from '../../hooks/useOut'
import classNames from 'classnames'
import PerfilBox from './PerfilBox'
import { useContext } from 'react'
import AuthContext from '../../contexts/auth-context'

function NotificationItem() {
    return (
        <div className="px-3 hover:bg-base-200 cursor-pointer transition delay-75">
            <div className="flex justify-between items-center py-3">
                <div className="avatar">
                    <div className="rounded-full w-9 h-9">
                        <img src={profile} />
                    </div>
                </div>
                <div className="text-gray-800 text-2xs w-52">
                    <p className="text-primary font-bold">Daniel Mayers</p>
                    <p className="text-gray-500 font-thin">
                        Rechazo la orden de compra OC00013
                    </p>
                    <p className="text-accent font-bold">hace 5 min</p>
                </div>
                <Icon
                    name="message-close"
                    className="text-info"
                    cursor="pointer"
                />
            </div>
            <hr />
        </div>
    )
}

function NotificationBox({ component: Component, notifications }) {
    const { ref, isOpen, setOpen } = useOut([])

    function handleClick() {
        setOpen(!isOpen)
    }
    return (
        <div className="relative mr-7 z-30 hidden" ref={ref}>
            <div className="w-min relative" onClick={handleClick}>
                {Component}
                <div className="h-4 w-4 bg-blue-400 absolute rounded-full -top-1 -right-2 flex items-center justify-center">
                    <p className="text-2xs">{notifications.length}</p>
                </div>
            </div>
            <div
                className={classNames(
                    'absolute top-7 right-0 bg-white  w-80 rounded-lg  py-3 shadow-lg transition-all delay-100 ease-in-out',
                    {
                        'invisible opacity-0': !isOpen,
                        'opacity-100 visible shadow-lg block': isOpen
                    }
                )}
            >
                <div className="flex justify-between pb-2 items-start px-4">
                    <p className="text-sm text-primary font-thin">
                        Notificaciones
                    </p>
                    <p className="underline text-2xs text-gray-400 font-thin cursor-pointer">
                        Marcar todos como leidos
                    </p>
                </div>
                <hr />
                <div className="overflow-auto h-96">
                    {notifications.map(item => (
                        <NotificationItem key={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default function SessionUser() {
    const { user } = useContext(AuthContext)
    const notifications = [1, 2, 3, 4, 5, 6]
    return (
        <div className="flex items-center">
            <NotificationBox
                component={<Icon name="bell" pointer size={18} />}
                notifications={notifications}
            />
            <PerfilBox
                component={
                    <>
                        <div className="avatar">
                            <div className="rounded-full w-11 h-11">
                                <img src={user.avatar} />
                            </div>
                        </div>
                    </>
                }
            />
        </div>
    )
}
