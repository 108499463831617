import moment from 'moment'
import FileView from '../../../components/FileView'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { parseDriveFile } from '../../../utilities/fileTools'
import { companySelected } from '../storage'
import { GetReception } from '../type'

export default function GeneralInformation({ data }: { data: GetReception }) {
    const localCurrency = data.company_warehouse.company.currency
    const company = companySelected

    return (
        <div className="body__cards">
            <div className="column">
                <div className="card_info">
                    <h3>INFORMACIÓN GENERAL</h3>
                    <ul className="list_info">
                        <li>
                            <p>Usuario</p>
                            <p>
                                <span
                                    className={
                                        data.document.user.is_enabled
                                            ? ''
                                            : 'line-through'
                                    }
                                >
                                    {data.document.user.name}{' '}
                                    {data.document.user.lastname}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>Fecha de recepción</p>
                            <p>
                                {moment(data.reception_date)
                                    .tz(company.timezone)
                                    .format('DD/MM/YYYY')}
                            </p>
                        </li>
                        <li>
                            <p>Almacén</p>
                            <p>
                                <span
                                    className={
                                        data.company_warehouse.warehouse
                                            ? ''
                                            : 'line-through'
                                    }
                                >
                                    {data.company_warehouse.warehouse.name}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>Tipo de RC</p>
                            <p>{data.type_reception.name}</p>
                        </li>
                        <li>
                            <p>Embarque</p>
                            <p>
                                {data.number_shipment
                                    ? data.number_shipment
                                    : '-'}
                            </p>
                        </li>
                        <li>
                            <p>Tipo de cambio</p>
                            <p>{data.exchange_rate.toFixed(4)}</p>
                        </li>
                        <li>
                            <p>Estado</p>
                            <p>{data.document.is_annuled ? 'Anulado' : '-'}</p>
                        </li>
                        <li>
                            <p>Comentario</p>
                            <p>
                                {data.document.description
                                    ? data.document.description
                                    : '-'}
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="card_info">
                    <h3>ORDEN DE COMPRA</h3>
                    <ul className="list_info">
                        <li>
                            <p>Código</p>
                            <p>{data.purchase.code}</p>
                        </li>
                        <li>
                            <p>Tipo OC</p>
                            <p>{data.purchase.type_purchase.name}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <div className="card_info">
                    <h3>INFORMACIÓN DE LAS FACTURAS</h3>
                    <ul className="list_info">
                        <li>
                            <p>Nro. de factura de importación</p>
                            <p>{data.number_invoice_shipping || '-'}</p>
                        </li>
                        <li>
                            <p>Fecha de importación de factura</p>
                            <p>
                                {data.invoice_shipping_date
                                    ? moment(data.invoice_shipping_date).format(
                                          'DD/MM/YYYY'
                                      )
                                    : '-'}
                            </p>
                        </li>
                        <li>
                            <p>Nro. de factura final</p>
                            <p>{data.number_invoice_final || '-'}</p>
                        </li>
                        <li>
                            <p>Fecha final de factura</p>
                            <p>
                                {data.invoice_final_date
                                    ? moment(data.invoice_final_date).format(
                                          'DD/MM/YYYY'
                                      )
                                    : '-'}
                            </p>
                        </li>
                        <li>
                            <p>Monto Proveedor ({localCurrency.symbol})</p>
                            <p>
                                {buildNumberFormat(data.provider_local_amount)}
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="card_info">
                    <h3>DOCUMENTOS</h3>
                    <div className="doc-container">
                        {data.files.map(f => (
                            <FileView key={f.id} file={parseDriveFile(f)} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
