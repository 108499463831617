export default function labelToKeyName(label: string) {
    const ofThis = 'ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç'
    const to = 'AAAAAEEEEIIIIOOOOUUUUNCaaaaaeeeeiiiioooouuuunc'
    const regex = new RegExp('[' + ofThis + ']', 'ug')

    const labelCleaned = label
        .toLowerCase()
        .replaceAll(' ', '_')
        .replace('.', '')
        .replace(regex, match => to.charAt(ofThis.indexOf(match)))

    if (labelCleaned === 'descripcion_corta') {
        return 'name'
    }

    if (labelCleaned === 'rango/concentracion') {
        return labelCleaned.replace('/concentracion', '')
    }

    if (labelCleaned === 'tipo_de_envase') {
        return 'presentacion'
    }

    if (labelCleaned === 'codigo_fabricante/proveedor') {
        return labelCleaned.replace('/proveedor', '')
    }

    if (labelCleaned === 'tiempo_de_vida_(meses)') {
        return labelCleaned.replace('_(meses)', '')
    }

    if (labelCleaned === 'temp_min_(°c)') {
        return labelCleaned.replace('_(°c)', '')
    }

    if (labelCleaned === 'temp_max_(°c)') {
        return labelCleaned.replace('_(°c)', '')
    }

    return labelCleaned
}
