import { PropsSearchResults } from '../components/SearchResult/types'
import { PropsSelectMultiple } from '../components/SelectMultiple/types'
import { PropsSelectTouch } from '../components/SelectTouch/types'
import { Option } from '../config/types/component'
import { HooksInfo } from './FormEvents/types'

export function removeDuplicates<T>(list: T[], propName: keyof T): T[] {
    const listFiltered: T[] = []
    list.forEach(data => {
        if (!listFiltered.find(d => d[propName] === data[propName])) {
            listFiltered.push(data)
        }
    })

    return listFiltered
}

interface Props {
    select: Omit<PropsSelectTouch, 'children' | 'placeholder'>
    selects: Omit<PropsSelectMultiple, 'children' | 'placeholder'>
    search_result: Omit<PropsSearchResults, 'children' | 'placeholder'>
}

export interface DataMemory<T> {
    push: (data: T) => void
    sets: (dataList: T[]) => T[]
    get: (id: number) => T
    getList: () => T[]
    clear: () => void
    observer: <D, P extends 'select' | 'selects' | 'search_result'>(
        formEventHooksInfo: HooksInfo<D, T, Props[P]>,
        inputType?: P
    ) => HooksInfo<D, T, Props[P]>
    /**
     * @deprecated
     */
    getCurrent: () => T
    /**
     * @deprecated
     */
    getCurrents: () => T[]
    del: (id: number) => T[]
    update: (id: number, data: T) => T[]
}

/**
 * @alpha
 * @version alpha
 */
export function dataMemory<T extends { id: number }>(): DataMemory<T> {
    const list: Map<number, T> = new Map()
    let current: T = null
    let currents: T[] = []

    function push(data: T) {
        list.set(data.id, data)
    }

    function sets(dataList: T[]): T[] {
        dataList.forEach(data => {
            list.set(data.id, data)
        })
        return getList()
    }

    function get(id: number): T {
        return list.get(id) || null
    }

    function getList() {
        return Array.from(list.values())
    }

    function clear() {
        list.clear()
    }

    function del(id: number): T[] {
        list.delete(id)
        return getList()
    }

    function update(id: number, data: T) {
        list.set(id, data)
        return getList()
    }

    function observer<D, P extends 'select' | 'selects' | 'search_result'>(
        formEventHooksInfo: HooksInfo<D, T, Props[P]>,
        inputType: P = 'select' as P
    ): HooksInfo<D, T, Props[P]> {
        sets(formEventHooksInfo.data || formEventHooksInfo.result)

        if (inputType === 'select') {
            const props = formEventHooksInfo.props as Props['select']
            current = props.option
                ? list.get(parseInt(props.option.value))
                : null
            const change = props.onChange
            formEventHooksInfo.props.onChange = (option: Option) => {
                current = list.get(parseInt(option.value))
                change(option)
            }
        }

        if (inputType === 'selects') {
            const props = formEventHooksInfo.props as Props['selects']
            currents = Array.from(list.values()).filter(data =>
                props.options.find(option => parseInt(option.value) === data.id)
            )
            const change = formEventHooksInfo.props.onChange
            formEventHooksInfo.props.onChange = (options: Option[]) => {
                currents = Array.from(list.values()).filter(data =>
                    options.find(option => parseInt(option.value) === data.id)
                )
                change(options)
            }
        }

        if (inputType === 'search_result') {
            const props = formEventHooksInfo.props as Props['search_result']
            currents =
                Array.from(list.values()).filter(data =>
                    props.options.find(
                        option => parseInt(option.value) === data.id
                    )
                ) || []
            const select = props.onSelect
            props.onSelect = (options: Option[]) => {
                currents = Array.from(list.values()).filter(data =>
                    options.find(option => parseInt(option.value) === data.id)
                )
                select(options)
            }
        }

        return formEventHooksInfo
    }

    return {
        getList,
        sets,
        get,
        push,
        clear,
        observer,
        getCurrent: () => current,
        getCurrents: () => currents,
        del,
        update
    }
}

export function mergeExpect<
    T extends { id?: number },
    L extends { id: number }
>(data1: T[], data2: L[]) {
    const newData: any[] = []
    const arrDeleted: L[] = []

    data2.forEach(data => {
        if (!data1.find(_ => _.id === data.id)) {
            arrDeleted.push(data)
        }
    })

    function expectData(response) {
        if (response) {
            newData.push(response)
        }
    }

    function deleted(cbfn: (data: L) => any) {
        arrDeleted.forEach(data => expectData(cbfn(data)))

        return newData
    }

    function pass() {
        return newData
    }

    function separate(cbfn: (data: L) => any): [any, any] {
        const dataDeleted = arrDeleted
            .map(data => cbfn(data))
            .filter(data => data)

        return [newData, dataDeleted]
    }

    interface MappingExpect<K> {
        pass: () => K[]
        deleted: (cbfn: (data: L) => Partial<K> | void | null) => K[]
        separate: (cbfn: (data: L) => Partial<K> | void | null) => [K[], K[]]
    }

    function mapping<K>(cbfn: (data: T) => K | void | null): MappingExpect<K> {
        data1.forEach(data => expectData(cbfn(data)))

        return {
            deleted,
            pass,
            separate
        }
    }

    return {
        mapping
    }
}
