import { tableWithdrawal } from '../storage'
import { Step3 } from '../types'

export function retreatExist(
    name: string,
    form: Step3.FormWithdrawal,
    index?: number
) {
    const tableWithdrawals = tableWithdrawal.getDataList()

    if (index !== undefined) {
        return tableWithdrawals.find(
            (item, i) =>
                item.place_retreat === form.place_retreat &&
                item.address === form.address &&
                item.country.name === form.country.label &&
                i !== index
        )
    }

    return tableWithdrawals.find(
        item =>
            item.place_retreat === form.place_retreat &&
            item.address === form.address &&
            item.country.name === form.country.label
    )
}
