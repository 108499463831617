import { FormControl } from '../../../../components/FormUtilities'
import InputField from '../../../../components/InputField'

import RegexTypes from '../../../../utilities/regexTypes'
import { formContact } from '../../storage'

function Name() {
    const { props } = formContact.useInput('name')
    return (
        <FormControl label="Nombre" required className="mb-2">
            <InputField
                {...props}
                placeholder="Ingresar nombre de contacto"
                autoFocus
            />
        </FormControl>
    )
}

function Position() {
    const { props } = formContact.useInput('position')
    return (
        <FormControl label="Cargo" required className="mb-2">
            <InputField {...props} placeholder="Ingresar cargo del contacto" />
        </FormControl>
    )
}

function Email() {
    const { props } = formContact.useInput('email')
    return (
        <FormControl label="Email" required className="mb-2">
            <InputField
                {...props}
                placeholder="Ingresar email del contacto"
                regex={RegexTypes.email}
            />
        </FormControl>
    )
}

function Phone() {
    const { props } = formContact.useInput('phone_number')
    return (
        <FormControl label="Teléfono" required>
            <InputField
                {...props}
                placeholder="Ingresar teléfono del contacto"
            />
        </FormControl>
    )
}

export default function FormContact() {
    return (
        <>
            <Name />
            <Position />
            <Email />
            <Phone />
        </>
    )
}
