import { Option } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import Country from '../../../../../models/Country'
import { formOC, reqDeliveryCountry, reqLocate } from '../../../storage'

export default function useCountryDispatchWithdrawal() {
    const { props } = formOC.useSelect('country_dispatch_withdrawal')
    const { data } = useGet<Country[]>(
        reqDeliveryCountry.observer(
            '/v5.6/apis/providers/:provider_id/delivery_countries'
        ),
        []
    )

    function handleChange(option: Option) {
        props.onChange(option)

        if (option.value === '0') {
            reqLocate.deleteQuery('country_id')
            formOC.setProps(
                'place_dispatch_pick_up',
                {
                    disabled: true
                },
                false
            )
        } else {
            reqLocate.setQuery('country_id', option.value)
            if (
                reqLocate.hasParam('mode_address') &&
                reqLocate.hasParam('provider_id')
            ) {
                formOC.setProps(
                    'place_dispatch_pick_up',
                    {
                        disabled: false
                    },
                    false
                )
                reqLocate.load()
            }
        }
        formOC.setValue('place_dispatch_pick_up', null)
    }

    return {
        props,
        countries: data.result,
        handleChange
    }
}
