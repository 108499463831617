import Company from '../models/Company'
import Country from '../models/Country'
import Currency from '../models/Currency'
import TypeAgent from '../models/TypeAgent'
import TypeCommunication from '../models/TypeCommunication'
import TypeDispatch from '../models/TypeDispatch'
import TypeStowage from '../models/TypeStowage'
import TypeLicense from '../models/TypeLicense'
import TypeProduct from '../models/TypeProduct'
import TypeTaxIdentification from '../models/TypeTaxIdentification'
import { initialStorage, Req } from '../utilities/controlStorage'
import ShippingTypePurchase from '../models/ShippingTypePurchase'
import ShippingMethodPurchase from '../models/ShippingMethodPurchase'

/**
 * Tipado de los datos
 */
export interface InitialData {
    typeProducts: TypeProduct[]
    countries: Country[]
    typeComunications: TypeCommunication[]
    companies: Array<Company & { country: Country }>
    originalCompanies: Array<Company & { country: Country }>
    typeLicenses: TypeLicense[]
    countriesAdvance: Country[]
    typeAgents: TypeAgent[]
    typeTaxIdentifications: TypeTaxIdentification[]
    typeDispatches: TypeDispatch[]
    currencies: Currency[]
    companiesFromSession: Array<
        Company & { country: Country } & { local_currency: Currency | null }
    >
    typeStowages: TypeStowage[]
    countriesAdvanceActiveCompany: Country[]
    shippingTypePurchases: ShippingTypePurchase[]
    shippingMethodPurchases: ShippingMethodPurchase[]
}

/**
 * Agrega datos iniciales
 */
export const initialData: InitialData = initialStorage({
    typeProducts: [],
    countries: [],
    typeComunications: [],
    companies: [],
    originalCompanies: [],
    typeLicenses: [],
    countriesAdvance: [],
    typeAgents: [],
    typeTaxIdentifications: [],
    typeDispatches: [],
    currencies: [],
    companiesFromSession: [],
    typeStowages: [],
    countriesAdvanceActiveCompany: [],
    shippingTypePurchases: [],
    shippingMethodPurchases: []
})

/**
 * Cargar datos despues de la sesión
 */
export async function loadData(): Promise<InitialData> {
    const load = new Req({
        typeProducts: '/v5.6/apis/type_products',
        countries: '1>/v5.6/apis/countries',
        typeComunications: '/v5.6/apis/type_communications',
        companies: '1>/v5.6/apis/companies',
        originalCompanies: '2>/v5.6/apis/companies',
        typeLicenses: '/v5.6/apis/type_licenses',
        countriesAdvance: '2>/v5.6/apis/countries?has_companies=true',
        typeAgents: '/v5.6/apis/type_agents',
        typeTaxIdentifications: '/v5.6/apis/type_tax_identifications/all',
        typeDispatches: '/v5.6/apis/type_dispatches/all',
        currencies: '/v5.6/core/currencies',
        companiesFromSession: '/v5.6/apis/companies/companies_from_session',
        typeStowages: '/v5.6/apis/type_stowages?is_manual=true',
        countriesAdvanceActiveCompany:
            '3>/v5.6/apis/countries?has_activate_companies=true&sort=id',
        shippingTypePurchases: '/v5.6/apis/shipping_type_purchases',
        shippingMethodPurchases: '/v5.6/apis/shipping_method_purchases'
    })
    return load.result()
}
