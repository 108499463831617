import { useEffect } from 'react'
import { render } from 'redity'
import {
    FormControl,
    FormControls
} from '../../../../../components/FormUtilities'
import NumeralField from '../../../../../components/NumeralField'
import Option from '../../../../../components/Option'
import SelectTouch from '../../../../../components/SelectTouch'
import { Option as OptionType } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import { get } from '../../../../../utilities/http'
import Notify from '../../../../../utilities/Notify'
import {
    formProduct,
    formInformation,
    storagePage,
    tableProductsControl,
    reqProducts
} from '../../../storage'
import { InventoryInformation, ItemProduct, PropsEmpty } from '../../../types'

let value = ''

export function Search() {
    const provider_id = formInformation.store().provider.value
    const company_id = formInformation.store().catalogue_company.value
    const requesting_company_id = formInformation.store().company.value

    const { props } = formProduct.useSelect('catalogue_products')
    const { loading, data } = useGet<ItemProduct[]>(
        reqProducts.observer(
            `/v5.6/apis/products/catalogue?provider_id=${provider_id}&company_id=${company_id}`
        ),
        []
    )

    async function handleSelect(nextOption: OptionType) {
        props.onChange(nextOption)
        if (nextOption.value === '0') {
            formProduct.setValue('quantity', null)
            formProduct.setValue('inventory', [])
            formProduct.setValue('product', null)
            render('ProductData')
            return
        }
        const option = nextOption
        const product = data.result.find(
            item =>
                item.product_company.product_country.product.id ===
                parseInt(option.value)
        )

        storagePage.loadingProductForm = true
        render('ProductData')

        get<InventoryInformation[]>(
            `/v5.6/apis/requisitions/products/inventory?product_id=${option.value}&company_id=${requesting_company_id}`
        ).then(response => {
            if (response.error && response.error.type !== 'cancel') {
                Notify.error('Hubo un error al traer los datos')
                storagePage.loadingProductForm = false
                render('ProductData')
            } else if (!response.error) {
                formProduct.setValue('inventory', response.data.result)
                formProduct.setValue('product', product)

                value =
                    product.product_company.product_country.product.description
                render(formProduct.keyRender, 'ProductData')

                storagePage.loadingProductForm = false
                render('ProductData')
            }
        })
    }

    const products = data.result
        .filter(product_catalogue => {
            return !tableProductsControl
                .getDataList()
                .find(item => item.product_data.id === product_catalogue.id)
        })
        .map(product_catalogue => ({
            id: product_catalogue.product_company.product_country.product.id,
            name: product_catalogue.product_company.product_country.product
                .description,
            code: product_catalogue.product_company.product_country.product.code
        }))

    function handleChange(ev) {
        value = ev.target.value
        if (value === '') {
            reqProducts.deleteQuery('search')
        } else {
            reqProducts.setQuery('search', value)
        }
        reqProducts.load()
        render(formProduct.keyRender, 'ProductData')
    }

    return (
        <FormControl label="Producto" col="span-4" className="mb-4">
            <SelectTouch
                {...props}
                helper={props.helper}
                loading={loading}
                onSearch={handleChange}
                onChange={handleSelect}
                placeholder="Buscar por código o descripción de producto"
                label="Productos"
                search={value}
            >
                {products.map(logicalProduct => (
                    <Option
                        key={logicalProduct.id}
                        value={logicalProduct.id.toString()}
                        label={logicalProduct.name}
                        label2={logicalProduct.code}
                    />
                ))}
                <Empty data={products} />
            </SelectTouch>
        </FormControl>
    )
}

function Empty({ data, message = 'No se encontraron resultados' }: PropsEmpty) {
    return data.length === 0 ? (
        <div className="text-xs text-gray-400 py-2">{message}</div>
    ) : null
}

export function Quantity() {
    const { props, getError } = formProduct.useInput('quantity')
    const helper = getError()

    return (
        <FormControl label="Cantidad" col="span-2" className="mb-4">
            <NumeralField
                {...props}
                placeholder="Ingresar cantidad"
                autoFocus
                helper={helper || props.helper}
            ></NumeralField>
        </FormControl>
    )
}

export default function SearchProducts() {
    useEffect(() => {
        reqProducts.load()
        value = ''
        render(formProduct.keyRender)
    }, [])
    return (
        <FormControls>
            <Search />
            <Quantity />
        </FormControls>
    )
}
