import classNames from 'classnames'
import { useRender } from 'redity'
import DataTable, { Caption, TBody, THead } from '../../components/DataTable'
import ListFormControl from './ListFormControl'
import { PropsListForm } from './types'

export default function TableList<T>({
    children,
    tbody,
    control,
    className = '',
    scrollHeight = null,
    theadWhiteSpace = 'nowrap',
    filter = () => true
}: PropsListForm<T>) {
    return (
        <DataTable className={className} scrollHeight={scrollHeight}>
            <Head control={control} theadWhiteSpace={theadWhiteSpace} />
            <Body control={control} filter={filter}>
                {tbody || children}
            </Body>
        </DataTable>
    )
}

function Head({
    control,
    theadWhiteSpace
}: {
    control: ListFormControl<any>
    theadWhiteSpace:
        | 'normal'
        | 'pre'
        | 'nowrap'
        | 'pre-wrap'
        | 'pre-line'
        | 'break-spaces'
}) {
    useRender(control.keyRender, control.indexs.BODY)
    return (
        <THead>
            <tr>
                {control.thead.map((val, i) => (
                    <th
                        className={classNames('', {
                            'cell-actions':
                                val.toString().toLowerCase() === 'acciones'
                        })}
                        style={{ whiteSpace: theadWhiteSpace }}
                        key={i}
                    >
                        {typeof val === 'string' ? val : val.label}
                    </th>
                ))}
            </tr>
        </THead>
    )
}

function Body<T>({ control, children, filter }: PropsListForm<T>) {
    useRender(control.keyRender, control.indexs.BODY)
    const list = control.list.filter(data => filter(data.store()))
    return (
        <TBody>
            {list.map((data, i) => children(data.store(), data, i + 1))}
            {list.length === 0 && (
                <Caption colSpan={control.thead.length}>
                    No existen registros
                </Caption>
            )}
        </TBody>
    )
}
