import FormEvents from '../../utilities/FormEvents'
import ListFormControl from '../../sections/ListForm/ListFormControl'
import { Keys } from './constants'
import {
    StoragePage,
    FormInformation,
    FormNorms,
    TableFormCompany,
    TableFormPermit,
    TableFormTax,
    TableProductData,
    TableFormFiscalCode
} from './types'
import { generateDescription, generateProductCode } from './utilities/generate'
import ControlTabs from '../../utilities/ControlTabs'
import { dataMemory } from '../../utilities/dataTools'

export const storagePage: StoragePage = {
    product: null,
    step2Done: false,
    mode: 'creation',
    products: dataMemory()
}

export const controlTabs = new ControlTabs()

/**
 * Step 1
 */

export const formInformation = new FormEvents<FormInformation>(Keys.STEP1, {
    name: '',
    manufacturer_code: '',
    presentation: '',
    rank: '',
    model: '',
    quantity: '',
    brand: null,
    product_files: [],
    type_product: null,
    type_communications: [],
    hazardous: false,
    precursor: false,
    description_generate: '',
    category: null,
    subcategory: null,
    code: ''
})

formInformation.store.on('type_product', function (option) {
    if (option && option.value === '2') {
        formInformation.setProps('type_communications', { disabled: false })
    } else {
        formInformation.setProps(
            'type_communications',
            { disabled: true },
            false
        )
        formInformation.setValue('type_communications', [])
    }
})

formInformation.store.on('brand', function (option) {
    generateDescription('brand', option)
    generateProductCode('brand', option)
})

formInformation.store.on('name', function (value) {
    generateDescription('name', value)
})

formInformation.store.on('model', function (value) {
    generateDescription('model', value)
})

formInformation.store.on('rank', function (value) {
    generateDescription('rank', value)
})

formInformation.store.on('presentation', function (value) {
    generateDescription('presentation', value)
})

formInformation.store.on('quantity', function (value) {
    generateDescription('quantity', value)
})

formInformation.setProps('subcategory', { disabled: true })

formInformation.store.on('category', function (option) {
    generateProductCode('category', option)
    if (option) {
        formInformation.setProps('subcategory', { disabled: false }, false)
        formInformation.setParams('subcategory', {
            category_id: option.value
        })
    } else {
        formInformation.setProps('subcategory', { disabled: true }, false)
    }
    formInformation.setValue('subcategory', null)
})

formInformation.store.on('subcategory', function (option) {
    generateProductCode('subcategory', option)
})

formInformation.store.on('manufacturer_code', function (value) {
    generateProductCode('manufacturer_code', value)
})

/**
 * Step 2
 */

export const formNorms = new FormEvents<FormNorms>(Keys.STEP2, {
    lifetime: '',
    refrigeration: false,
    max_temperature: '',
    min_temperature: '',
    air_transport: false,
    marine_transport: false,
    ground_transport: true,
    storable_products: []
})

formNorms.store.on('refrigeration', function (checked) {
    formNorms.setProps('min_temperature', { disabled: !checked })
    formNorms.setProps('max_temperature', { disabled: !checked })
    if (!checked) {
        formNorms.setValue('min_temperature', '')
        formNorms.setValue('max_temperature', '')
    }
})

export const tableProductsControl = new ListFormControl<TableProductData>(
    Keys.TABLE_PRODUCTS,
    ['#', 'Producto', 'Categoría', 'Subcategoría', 'Acciones']
)

/**
 * Step 3
 */

export const tableCompaniesControl = new ListFormControl<TableFormCompany>(
    Keys.COMPANIES,
    ['País', 'Compañia', 'Compra', 'Venta']
)

export const tableFiscalCodeControl = new ListFormControl<TableFormFiscalCode>(
    Keys.FISCAL_CODE,
    ['País', 'Compañía', 'Código fiscal de producto']
)

export const tableTaxesControl = new ListFormControl<TableFormTax>(Keys.TAXES, [
    'País',
    'IVA'
])

export const tablePermitsControl = new ListFormControl<TableFormPermit>(
    Keys.PERMITS,
    ['País', 'Importación', 'Exportación', 'Otros']
)
