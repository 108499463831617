import DataTable, {
    Caption,
    TBody,
    THead
} from '../../../../components/DataTable'
import { LicenseInfo } from '../../types'

export default function Products({ data }: { data: LicenseInfo }) {
    const product_licenses = data.product_licenses.filter(
        product_license => product_license.memory
    )

    return (
        <DataTable scrollHeight={450}>
            <THead>
                <tr>
                    <th>Producto</th>
                    <th>Marca</th>
                    <th>Subcategoría</th>
                </tr>
            </THead>
            <TBody>
                {product_licenses.map(pl => (
                    <tr key={pl.id}>
                        <td>
                            <p className="-mb-1">{pl.product.name}</p>
                            <span className="text-2xs">{pl.product.code}</span>
                        </td>
                        <td>{pl.product.brand.name}</td>
                        <td>{pl.product.subcategory.name}</td>
                    </tr>
                ))}
                {product_licenses.length === 0 && (
                    <Caption colSpan={3}>No se encontraron resultados</Caption>
                )}
            </TBody>
        </DataTable>
    )
}
