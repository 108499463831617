import moment from 'moment'
import ControlList from '../../utilities/Control/ControlList'
import ControlRequest from '../../utilities/ControlRequest'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { BalanceHistory, FilterFormFields, StoragePage } from './types'

export const storagePage: StoragePage = {
    header: {
        total_balance: 0,
        quantity_cost_alerts: 0,
        quantity_products: 0,
        current_date: moment().toISOString(true)
    },
    currency: null,
    companies: [],
    originalCompanies: []
}

export const controlList = new ControlList<BalanceHistory>(Keys.MAIN, {
    path: '2>/v5.6/apis/balance_histories',
    theads: [
        '#',
        { value: 'product_code', label: 'Código de producto' },
        { value: 'product_name', label: 'Descripción de producto' },
        'Categoría',
        'Cantidad',
        'Costo promedio',
        'Saldo',
        'Alerta costeo'
    ]
})

export const reqSummaryData = new ControlRequest()
export const reqSubStowage = new ControlRequest()

controlList.badgeRemove('date', false)
controlList.badgeRemove('company_id', false)

export const filterForm = new FormEvents<FilterFormFields>(Keys.FILTER_FORM, {
    category_id: null,
    date: moment().format('YYYY-MM-DD'),
    stowage_id: null,
    minimal_stowage_id: null,
    cost_alert: null
})

controlList.onDeleteBadge = key => {
    if (key === 'stowage_id') {
        controlList.filter.deleteFilter('minimal_stowage_id')
        filterForm.setValue('minimal_stowage_id', null)
    }
}
