import { Option } from '../../../../config/types/component'
import useGet from '../../../../hooks/useGet'
import MinimalStowage from '../../../../models/MinimalStowage'
import {
    formInformation,
    reqProducts,
    reqProviders,
    reqSubStowageDestiny
} from '../../storage'

export default function useSubStowageDestiny() {
    const { props } = formInformation.useSelect('sub_stowage_destination')
    const { data, props: propsSearch } = useGet<DestinyStowageValue[]>(
        reqSubStowageDestiny.observer(
            `/v5.6/apis/stowages/:stowage_id/substowages`,
            true
        ),
        []
    )

    function handleChange(option: Option) {
        reqProviders.setQuery('order_number', option.label)
        reqProducts.setQuery('order_number', option.label)
        reqProviders.load()
        reqProducts.load()
        formInformation.setValue('provider', null)
        props.onChange(option)
    }

    return {
        props,
        propsSearch,
        handleChange,
        data: data
    }
}

interface DestinyStowageValue extends MinimalStowage {
    substowage_name: string
}
