import { memo } from 'react'
import { FormControl } from '../../../../../../components/FormUtilities'
import Upload from '../../../../../../components/Upload'
import { DriveFile } from '../../../../../../utilities/Drive'

function FieldUpload({ files, onChange }: FieldUploadProps) {
    return (
        <FormControl label="Archivos adjuntos " col="span-6" row="span-3">
            <Upload files={files} onChange={onChange} />
        </FormControl>
    )
}

export default memo(FieldUpload)

export interface FieldUploadProps {
    files: DriveFile[]
    onChange: (files: DriveFile[]) => void
}
