import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import useCancel from '../../../hooks/useCancel'
import { formAgent } from '../storage'
import useSubmit from '../useSubmit'

export default function Header() {
    const { onClick } = useCancel(formAgent, '/configuration/agents')
    const submit = useSubmit()

    return (
        <PageHeader subtitle="(*) Complete todos los campos para el agente">
            <Button onClick={onClick} color="ghost">
                Cancelar
            </Button>
            <Button className="ml-2" onClick={() => submit()}>
                Guardar
            </Button>
        </PageHeader>
    )
}
