import Button from '../../components/Button'
import IconError from './IconError'
import { useHistory } from 'react-router-dom'

export interface PropsPage500 {
    link?: string
}

export default function Page500({ link }: PropsPage500) {
    const { replace } = useHistory()
    function handleClick() {
        if (link) {
            replace(link)
        } else {
            window.location.reload()
        }
    }
    return (
        <div className="flex flex-col items-center py-14">
            <IconError />
            <h2 className="text-2xl font-univers-bold text-primary">
                Algo salió mal...
            </h2>
            <p className="text-gray-500 text-sm my-5 text-center">
                Ha ocurrido un error con el servidor y la petición no podría
                completarse. <br />
                Por favor intente refrescar la página.
            </p>
            <div className="flex items-center justify-center">
                <Button color="primary" onClick={handleClick}>
                    Refrescar
                </Button>
            </div>
        </div>
    )
}
