import { Switch, Route } from 'react-router-dom'
import { ItemRoute } from './config/routes/types'
import Page404 from './pages/Page404'

export default function Routes({ pages }: { pages: ItemRoute[] }) {
    return (
        <Switch>
            {pages
                .filter(page => page.Component)
                .map((page, i) => (
                    <Route key={i} exact path={page.path}>
                        <page.Component />
                    </Route>
                ))}
            <Route path="*">
                <Page404 />
            </Route>
        </Switch>
    )
}
