import React from 'react'
import classnames from 'classnames'
import RegexTypes from '../../utilities/regexTypes'
import './inputfield.css'
import Helper from '../Helper'

function expectValue(value: string, regex: RegExp) {
    return value.replace(regex, '')
}
// TODO: crear un archivo regexTypes donde colocar las regex que se usen. (ubicacion?)
export default React.forwardRef(function InputField(
    {
        className = '',
        helper = '',
        onChange = () => null,
        regex = RegexTypes.alphaNumber,
        maxLength = 180,
        size = 'normal',
        readOnly = false,
        disabled = false,
        ...props
    }: any,
    ref
) {
    function handleChange(ev) {
        const val = regex
            ? expectValue(ev.target.value, regex)
            : ev.target.value
        if (val === props.value) return
        onChange({
            ...ev,
            target: {
                ...ev.target,
                value: val
            }
        })
    }
    return (
        <>
            <input
                {...props}
                onChange={handleChange}
                type="text"
                readOnly={readOnly}
                maxLength={maxLength}
                className={classnames(
                    `input input-${size} inputfield font-univers-light`,
                    className,
                    {
                        'input-error': !!helper
                    }
                )}
                autoComplete="off"
                ref={ref}
                disabled={disabled}
            />
            <Helper>{helper}</Helper>
        </>
    )
})
