import Checkbox from '../../../../components/Checkbox'
import {
    ControlDivider,
    FormControl,
    LabelInline
} from '../../../../components/FormUtilities'
import { formNorms } from '../../storage'

export default function Fields2() {
    return (
        <FormControl label="Medio de transporte" col="span-3">
            <ControlDivider divider="3">
                <AirTransport />
                <MarineTransport />
                <GroundTransport />
            </ControlDivider>
        </FormControl>
    )
}

function AirTransport() {
    const { props } = formNorms.useCheck('air_transport')
    return (
        <LabelInline label="Aéreo">
            <Checkbox {...props} />
        </LabelInline>
    )
}

function MarineTransport() {
    const { props } = formNorms.useCheck('marine_transport')
    return (
        <LabelInline label="Marítimo">
            <Checkbox {...props} />
        </LabelInline>
    )
}

function GroundTransport() {
    const { props } = formNorms.useCheck('ground_transport')
    return (
        <LabelInline label="Terrestre">
            <Checkbox {...props} disabled />
        </LabelInline>
    )
}
