export default abstract class Control {
    readonly keyRender: string
    protected memories: Record<string, Map<string, any>> = {}

    constructor(keyRender: string) {
        this.keyRender = keyRender
    }

    abstract renderMain(): boolean
    abstract done(): Promise<boolean>
}
