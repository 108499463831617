interface DataCompany {
    isSell?: boolean | string
    code: string
    isBuy?: boolean | string
    fiscalCode?: string
}

export interface DataCompanyBuilded {
    compra: boolean | string
    venta: boolean | string
    codigo: string
    codigo_fiscal: string
}

export default class BuildDataCompany {
    private list: Record<string, DataCompany> = {}

    setValue(fieldNameCompany: string, value: boolean | number | string) {
        let type: 'none' | 'buy' | 'sell' | 'fiscal_code' = 'none'
        if (fieldNameCompany.search('_venta') > -1) {
            type = 'buy'
        } else if (fieldNameCompany.search('_compra') > -1) {
            type = 'sell'
        } else if (fieldNameCompany.search('codigo_fiscal_') > -1) {
            type = 'fiscal_code'
        }

        const parsedValue = toBooleanOrDefault(value)
        const code = fieldNameCompany.replace(/\D/g, '')

        this.list[code] = {
            ...this.list[code],
            code
        }

        if (type === 'sell') {
            this.list[code].isSell = parsedValue
        }

        if (type === 'buy') {
            this.list[code].isBuy = parsedValue
        }

        if (type === 'fiscal_code') {
            this.list[code].fiscalCode = value ? value.toString().trim() : null
        }
    }

    build(fieldNameCompany: string): DataCompanyBuilded {
        const code = fieldNameCompany.replace(/\D/g, '')
        const data = this.list[code]

        return {
            compra: data.isSell,
            venta: data.isBuy,
            codigo: data.code,
            codigo_fiscal: data.fiscalCode
        }
    }
}

function isBoolean(str) {
    if (!isNaN(str) && Number.isInteger(parseFloat(str))) {
        if (parseFloat(str) === 1 || parseFloat(str) === 0) {
            return true
        }
        return false
    }
    return false
}

function toBooleanOrDefault(value) {
    if (isBoolean(value)) {
        return !!Number(value)
    } else {
        return (value || '').toString() || undefined
    }
}
