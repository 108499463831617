import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'
import useSubmit from './../useSubmit'
import useCancel from '../../../hooks/useCancel'
import { formTraspase, productsControl } from './../storage'
import { useParams } from 'react-router'

export default function Header() {
    const { traspase_id } = useParams<{ traspase_id?: string }>()
    const submit = useSubmit(traspase_id ? parseInt(traspase_id) : undefined)
    const { onClick } = useCancel(
        [formTraspase, productsControl],
        '/movements/traspases'
    )

    return (
        <PageHeader subtitle="(*) Complete todos los campos para agregar">
            <Button color="ghost" onClick={onClick}>
                Cancelar
            </Button>
            <Button className="ml-2" onClick={() => submit()}>
                Guardar
            </Button>
        </PageHeader>
    )
}
