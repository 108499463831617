import buildCompanyName from '../../../utilities/buildCompanyName'
import { GetInputPart } from '../type'
import GeneralInformation from './GeneralInformation'
import InputPartProducts from './InputPartProducts'

export default function ModalInfo({ data }: { data: GetInputPart }) {
    return (
        <>
            <div className="body__head mb-4">
                <div className="title">
                    <h1>PARTE DE ENTRADA {data.document.code}</h1>
                </div>
                <div>
                    <div className="company_badge">
                        <img
                            src={
                                data.company_warehouse.company.country.image_url
                            }
                        />
                        <b>
                            {buildCompanyName(
                                data.company_warehouse.company.name
                            )}
                        </b>
                    </div>
                </div>
            </div>
            <GeneralInformation data={data} />
            <InputPartProducts data={data} />
        </>
    )
}
