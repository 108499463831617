import { FormControl } from '../../../../../../components/FormUtilities'
import Helper from '../../../../../../components/Helper'
import { tableRCsControl } from '../../../SectionCosts/storageStep2'
import RC from './RC'
import TableProducts from './TableProducts'
import { formCostGeneral } from '../../storageStep3'

export default function Fields2() {
    const tableRCs = tableRCsControl.getDataList()
    const { props } = formCostGeneral.useValue('rcs')
    const { props: productIds } = formCostGeneral.useValue('productIds')

    return (
        <>
            <FormControl
                label="Aplicar a RC's"
                col="span-6"
                className="mb-4"
                required
            >
                {tableRCs.map(d => (
                    <RC
                        value={d.reception_id.toString()}
                        key={d.reception_id}
                        checked={
                            !!props.value.find(
                                option =>
                                    option.value === d.reception_id.toString()
                            )
                        }
                        label={d.rc}
                    />
                ))}
                <Helper>{props.helper}</Helper>
            </FormControl>
            <TableProducts
                rcIds={props.value.map(v => Number(v.value))}
                productIds={productIds.value}
                setProductIds={_productIds =>
                    formCostGeneral.setValue('productIds', _productIds)
                }
            />
        </>
    )
}
