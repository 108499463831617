import { useHistory } from 'react-router-dom'
import Avatar from '../../../components/Avatar'
import Enable from '../../../components/Enable'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import List from '../../../sections/List'
import { controlList, UserData } from '../storage'
import flagPlanetEarth from '../../../assets/img/flag_planet_earth.svg'
import buildCompanyName from '../../../utilities/buildCompanyName'
import BtnActionTable from '../../../components/BtnActionTable'

function Name({ data }: { data: UserData }) {
    const math = useRule('CU010')
    return (
        <td>
            <Avatar
                src={data.avatar_url}
                label={`${data.name} ${data.lastname}`}
                link={math}
                lineThrough={!data.is_enabled}
            />
        </td>
    )
}

export default function TableUsers() {
    const { push } = useHistory()
    return (
        <List control={controlList}>
            {(data, e) => (
                <tr key={data.id}>
                    <td>{e}</td>
                    <Name data={data} />
                    <td>
                        <div className="flex item-center">
                            <img
                                src={
                                    data.principal_company?.country
                                        ?.image_url || flagPlanetEarth
                                }
                                alt=""
                                className="mx-3 w-8"
                            />
                            {data.principal_company
                                ? buildCompanyName(data.principal_company.name)
                                : ' - '}
                        </div>
                    </td>
                    <td>
                        <Enable enabled={data.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU009">
                                <BtnActionTable
                                    title="Asignar roles"
                                    iconName="bs-card-check-list"
                                    disabled={!data.is_enabled}
                                    onClick={() =>
                                        push(`/administration/users/${data.id}`)
                                    }
                                />
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
