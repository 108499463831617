import { useEffect } from 'react'
import buildCompanyName from '../../../utilities/buildCompanyName'
import { ctsView } from '../storage'
import { GetReception } from '../type'
import GeneralInformation from './GeneralInformation'
import ReceptionProducts from './ReceptionProducts'

ctsView.setSection('Información general', GeneralInformation)
ctsView.setSection('Productos recepcionados', ReceptionProducts)

export default function ModalInfo({ data }: { data: GetReception }) {
    const value = ctsView.useStep()
    useEffect(() => {
        return () => {
            ctsView.value = 'step1'
        }
    })

    return (
        <>
            <div className="body__head">
                <div className="title">
                    <h1>RECEPCION DE COMPRA {data.code}</h1>
                </div>
                <div>
                    <div className="company_badge">
                        <img
                            src={
                                data.company_warehouse.company.country.image_url
                            }
                        />
                        <b>
                            {buildCompanyName(
                                data.company_warehouse.company.name
                            )}
                        </b>
                    </div>
                </div>
            </div>
            <ctsView.SectionTabs
                value={value}
                type="tab"
                mode="simple"
                className="mb-4"
                data={data}
            />
        </>
    )
}
