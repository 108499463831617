import DataTable, { TBody, THead } from '../../../../../components/DataTable'
import buildNumberFormat from '../../../../../utilities/buildNumberFormat'
import { storagePage } from '../../../storage'
import { ResponseLDR } from '../../../types'

export default function TableDistribution({
    logisticalDeclarationReception
}: {
    logisticalDeclarationReception: ResponseLDR[]
}) {
    return (
        <DataTable scrollHeight={350} className="sticky">
            <THead>
                <tr>
                    <th>#</th>
                    <th>Recepción de compra</th>
                    <th>OC</th>
                    <th>Monto estimado</th>
                    <th>Monto real</th>
                    <th>Diferencia</th>
                </tr>
            </THead>
            <TBody>
                {logisticalDeclarationReception.map((item, i) => (
                    <tr key={item.id}>
                        <td>{i + 1}</td>
                        <td>{item.reception.code}</td>
                        <td>
                            {item.reception.purchases
                                .map(purchase => purchase.code)
                                .join(', ')}
                        </td>
                        <td>
                            {storagePage.currentCurrency.symbol}{' '}
                            {item.reception.costing_estimate
                                ? `${buildNumberFormat(
                                      item.reception.costing_estimate
                                          .estimate_cost
                                  )}`
                                : '-'}
                        </td>
                        <td>
                            {storagePage.currentCurrency.symbol}{' '}
                            {buildNumberFormat(
                                item.logistical_cost - item.penalized_local_cost
                            )}
                        </td>
                        <td>
                            {storagePage.currentCurrency.symbol}{' '}
                            {item.reception.costing_estimate
                                ? buildNumberFormat(
                                      item.logistical_cost -
                                          (item.reception.costing_estimate
                                              ?.estimate_cost || 0)
                                  )
                                : 0}
                        </td>
                    </tr>
                ))}
            </TBody>
        </DataTable>
    )
}
