import 'moment/locale/es'
import Logo from '../../../../assets/img/logo.png'
import { Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import HelveticaBold from '../../../../assets/fonts/HelveticaBold.otf'
import { storagePage } from '../../storage'
import moment from 'moment'
import Company from '../../../../models/Company'
import { GetTraspase } from '../../types'

Font.register({ family: 'helvetica-bold', src: HelveticaBold })

const fontSize = '9px'

const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '14px'
    },
    companyWatemark: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '7px'
    },
    title: {
        fontSize: '16px',
        fontFamily: 'helvetica-bold',
        marginTop: '25px',
        marginBottom: '20px'
    },
    companyWatemark__logo: {
        height: '38px',
        width: '70px'
    },
    companyWatemark__info: {
        maxWidth: '30%'
    },
    fontBold: {
        fontFamily: 'helvetica-bold'
    },
    list_container: {
        display: 'flex',
        borderBottom: '2px dotted rgb(128, 126, 126)'
    },
    list: {
        display: 'flex',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingBottom: '10px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontSize: fontSize
    },
    list__element__name: {
        fontFamily: 'helvetica-bold',
        fontSize: fontSize
    },
    list__element__value: {
        fontSize: fontSize
    }
})

export default function Header({
    company,
    data
}: {
    company: Company
    data: GetTraspase
}) {
    moment.locale('es')

    return (
        <>
            <View style={styles.header}>
                <View style={styles.companyWatemark}>
                    <Image style={styles.companyWatemark__logo} src={Logo} />
                    <View style={styles.companyWatemark__info}>
                        <Text style={styles.fontBold}>{company.name}</Text>
                        {company.fiscal_document === '' && (
                            <Text>
                                {company.type_fiscal_document}:{' '}
                                {company.fiscal_document}
                            </Text>
                        )}
                        <Text>{company.address}</Text>
                    </View>
                </View>
                <View style={styles.title}>
                    <Text>TRASPASO {data.code}</Text>
                </View>
                <View style={styles.list_container}>
                    <View style={styles.list}>
                        <View>
                            <Text style={styles.list__element__name}>
                                Solicitante
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.document.requesting_user.name}{' '}
                                {data.document.requesting_user.lastname}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.list__element__name}>
                                Almacén
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.company_warehouse.warehouse.name}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.list__element__name}>
                                Bodega origen
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.origin_minimal_stowage.type_stowage.id ===
                                    7 &&
                                    data.origin_minimal_stowage
                                        .client_sub_stowage.client.name}
                                {data.origin_minimal_stowage.type_stowage.id !==
                                    7 &&
                                    data.origin_minimal_stowage.stowage.name}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.list__element__name}>
                                Sub Bodega origen
                            </Text>
                            <Text style={styles.list__element__value}>
                                {[2, 11].includes(
                                    data.origin_minimal_stowage.type_stowage.id
                                ) &&
                                    data.origin_minimal_stowage
                                        .order_sub_stowage.order.number}
                                {data.origin_minimal_stowage.type_stowage.id ===
                                    6 &&
                                    data.origin_minimal_stowage.gp_sub_stowage
                                        .order.number}
                                {![2, 6, 11].includes(
                                    data.origin_minimal_stowage.type_stowage.id
                                ) && ' - '}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.list}>
                        <View>
                            <Text style={styles.list__element__name}>
                                Bodega destino
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.destination_minimal_stowage.type_stowage
                                    .id === 7 &&
                                    data.destination_minimal_stowage
                                        .client_sub_stowage.client.name}
                                {data.destination_minimal_stowage.type_stowage
                                    .id !== 7 &&
                                    data.destination_minimal_stowage.stowage
                                        .name}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.list__element__name}>
                                Sub bodega destino
                            </Text>
                            <Text style={styles.list__element__value}>
                                {[2, 11].includes(
                                    data.destination_minimal_stowage
                                        .type_stowage.id
                                ) &&
                                    data.destination_minimal_stowage
                                        .order_sub_stowage.order.number}
                                {data.destination_minimal_stowage.type_stowage
                                    .id === 6 &&
                                    data.destination_minimal_stowage
                                        .gp_sub_stowage.order.number}
                                {![2, 6, 11].includes(
                                    data.destination_minimal_stowage
                                        .type_stowage.id
                                ) && ' - '}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.list__element__name}>
                                Cant. a traspasar
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.classic_traspase_products.reduce(
                                    function (previous, current) {
                                        return (
                                            previous +
                                            current.traspase_product.quantity
                                        )
                                    },
                                    0
                                )}{' '}
                                {''} Unidades
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.list__element__name}>
                                Fecha aprobación
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.document.decision_date &&
                                    moment(data.document.decision_date)
                                        .tz(storagePage.timezone)
                                        .format('DD/MM/YYYY')}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.list__element__name}>
                                Estado
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.document.type_document_approval.name}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.list}>
                        <View>
                            <Text style={styles.list__element__name}>
                                Motivo
                            </Text>
                            <Text style={styles.list__element__value}>
                                {data.document.description || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )
}
