export function parsePath(path: string): string {
    const [val, _path] = path.split('>')
    if (!isNaN(parseInt(val))) {
        return _path
    }
    return path
}

export function getPathWithoutParams(path: string) {
    const [pathCleaned] = path.split('?')
    return pathCleaned
}

export function expectIsAPIFake(path: string) {
    const [key, _path] = path.split(':')

    if (key.search(/\?/) > -1)
        return {
            isFake: false,
            path
        }

    if (key === 'fake') {
        return {
            isFake: 'fake',
            path: _path || path
        }
    }

    return {
        isFake: false,
        path: path
    }
}
