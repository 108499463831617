import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SearchResult from '../../../../components/SearchResult'
import { Option } from '../../../../config/types/component'
import { GetProduct } from '../../../../types/product'
import { formNorms, storagePage, tableProductsControl } from '../../storage'

const observer = storagePage.products.observer

export default function Fields3() {
    const { data: products, props } = observer(
        formNorms.useResults<GetProduct['non_storable_products'][number]>(
            'storable_products',
            () => '/v5.6/apis/products'
        ),
        'search_result'
    )

    function handleSelect(options: Option[]) {
        props.onSelect(options)
        const productsData = storagePage.products
            .getList()
            .filter(product =>
                options.find(option => parseInt(option.value) === product.id)
            )

        tableProductsControl.setList(
            productsData.map(product => ({
                id: product.id,
                name: product.name,
                code: product.code,
                category_name: product.subcategory.category.name,
                subcategory_name: product.subcategory.name
            }))
        )
        tableProductsControl.renderMain()
    }

    return (
        <FormControl label="Productos incompatibles" col="span-6">
            <SearchResult
                {...props}
                placeholder="Seleccionar productos incompatibles"
                onSelect={handleSelect}
                selectTouchInherit={true}
            >
                <IterateOptions data={products} label="name" label2="code" />
            </SearchResult>
        </FormControl>
    )
}
