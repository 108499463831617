import sttore from 'sttore'
import { PropsDialog } from '../../components/Dialog/types'
import { StateUtils } from './types'

export const statePopup = sttore<Omit<PropsDialog, 'onResult'>>({
    open: false,
    title: '',
    cancel: 'No, cancelar',
    accept: '',
    color: 'success',
    icon: 'circle-exclamation'
})

export const stateUtils: StateUtils = {
    resolve: null,
    children: ''
}
