import { GetReception } from '../type'
import TableReceptionProducts from './components/TableReceptionProducts'
import TableCostingReceptionProducts from './components/TableCostingReceptionProducts'

export default function TabReceptionProducts({ data }: { data: GetReception }) {
    return (
        <div className="body__tables">
            <div className="column">
                <div className="card_info">
                    <h3>PRODUCTOS DE LA RECEPCIÓN</h3>
                    <TableReceptionProducts data={data} />
                </div>
                <div className="card_info">
                    <h3>COSTOS DE RECEPCIÓN DESGLOSADOS</h3>
                    <TableCostingReceptionProducts data={data} />
                </div>
            </div>
        </div>
    )
}
