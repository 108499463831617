import { useEffect, useState } from 'react'
import { loadData, initialData, InitialData } from '../config/load'
import { localStorageInitialData } from '../utilities/controlStorage'
import { AuthData, initialAuthContext } from '../contexts/auth-context'
import { RoutingTool } from '../contexts/routing-context'
import auth from '../utilities/auth'
import useQuery from './useQuery'
import { SESSION } from '../config/app'
import { PUBLIC_URL_LOGIN } from '../config/credentials'

interface SessionState extends AuthData {
    step: 'logging' | 'load_data' | 'ready' | 'error'
    initialData: InitialData
    routing: RoutingTool
}

export default function useSession() {
    const { token } = useQuery<{ token: string }>()
    const [state, setState] = useState<SessionState>({
        ...initialAuthContext,
        step: 'logging',
        initialData,
        routing: {
            epicsModules: [],
            pages: []
        }
    })

    const tokenFiltered =
        (token || '').search('#/') > -1 ? token.split('#/').join('') : token

    useEffect(() => {
        auth(tokenFiltered || null)
            .then(async response => {
                const initialDataResponse = await loadData()
                if (initialDataResponse === null) {
                    setState({ ...state, step: 'error' })
                } else {
                    localStorageInitialData(initialDataResponse)
                    setState({
                        user: response.user,
                        expireIn: response.expireIn,
                        routing: response.routing,
                        CUs: response.CUs,
                        step: 'ready',
                        initialData: initialDataResponse
                    })
                    setTimeout(function () {
                        localStorage.clear()
                        window.location.replace(
                            PUBLIC_URL_LOGIN
                            // 'https://coredev.somosadvance.com/signin'
                        )
                    }, response.timeOut)
                }
            })
            .catch(() => {
                if (!SESSION.username || !SESSION.password) {
                    window.location.replace(
                        PUBLIC_URL_LOGIN
                        // 'https://coredev.somosadvance.com/signin'
                    )
                    return
                }
                setState({ ...state, step: 'error' })
                localStorage.removeItem('token')
            })
    }, [])

    return state
}
