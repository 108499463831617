import { GetProvider } from '../../../types/provider'
import buildCompanyName from '../../../utilities/buildCompanyName'

interface AddressDispatch {
    image: string
    place_dispatch: string
    type_dispatch: string
    main: boolean
    address: string
}

export default function getDispatches(
    provider: GetProvider
): AddressDispatch[] {
    const addressesDispatch: AddressDispatch[] = []
    provider.warehouse_dispatch_addresses.forEach(warehouseDispatchAddress => {
        addressesDispatch.push({
            image: warehouseDispatchAddress.country.image_url,
            place_dispatch:
                warehouseDispatchAddress.warehouse_dispatch.warehouse.name,
            type_dispatch: warehouseDispatchAddress.type_dispatch.name,
            main: warehouseDispatchAddress.is_main,
            address:
                warehouseDispatchAddress.warehouse_dispatch.warehouse.address
                    .address
        })
    })

    provider.agent_dispatch_addresses.forEach(agentDispatchAddress => {
        addressesDispatch.push({
            image: agentDispatchAddress.country.image_url,
            place_dispatch:
                agentDispatchAddress.agent_dispatch.agent_withdrawal_address
                    .agent.name,
            type_dispatch: agentDispatchAddress.type_dispatch.name,
            main: agentDispatchAddress.is_main,
            address:
                agentDispatchAddress.agent_dispatch.agent_withdrawal_address
                    .address.address
        })
    })

    provider.company_dispatch_addresses.forEach(companyDispatchAddress => {
        addressesDispatch.push({
            image: companyDispatchAddress.country.image_url,
            place_dispatch: buildCompanyName(
                companyDispatchAddress.company_dispatch.company.name
            ),
            type_dispatch: companyDispatchAddress.type_dispatch.name,
            main: companyDispatchAddress.is_main,
            address: companyDispatchAddress.company_dispatch.company.address
        })
    })

    provider.other_dispatch_addresses.forEach(otherDispatchAddress => {
        addressesDispatch.push({
            image: otherDispatchAddress.country.image_url,
            place_dispatch: otherDispatchAddress.other_dispatch.address.address,
            type_dispatch: otherDispatchAddress.type_dispatch.name,
            main: otherDispatchAddress.is_main,
            address: otherDispatchAddress.other_dispatch.address.address
        })
    })

    return addressesDispatch
}
