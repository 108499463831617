import DropContent from '../../../components/DropContent'
import { FormControl, IterateOptions } from '../../../components/FormUtilities'
import SelectTouch from '../../../components/SelectTouch'
import useGet from '../../../hooks/useGet'
import WarehouseType from '../../../models/Warehouse'
import {
    controlList,
    filterForm,
    reqWarehouse,
    reqStowageDestination,
    reqSubStowageOrigin
} from '../storage'
import useStowageDestination from './useStowageDestination'
import useStowageOrigin from './useStowageOrigin'
import useSubStowageDestination from './useSubStowageDestination'
import useSubStowageOrigin from './useSubStowageOrigin'

function Warehouse() {
    const { props } = filterForm.useSelect<WarehouseType>('warehouse_id')

    const { data, loading } = useGet<WarehouseType[]>(
        reqWarehouse.observer('/v5.6/apis/warehouses/company/', true),
        []
    )

    return (
        <FormControl label="Almacén">
            <SelectTouch
                {...props}
                loading={loading}
                placeholder="Seleccionar almacén"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function StowageOrigin() {
    const { props, handleChange, propsSearch, data } = useStowageOrigin()

    return (
        <FormControl label="Bodega origen">
            <SelectTouch
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar bodega origen"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function StowageDestination() {
    const { props, handleChange, propsSearch, data } = useStowageDestination()

    return (
        <FormControl label="Bodega destino">
            <SelectTouch
                disabled
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar bodega destino"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

function SubStowageOrigin() {
    const { props, handleChange, propsSearch, data } = useSubStowageOrigin()

    return (
        <FormControl label="Sub bodega origen">
            <SelectTouch
                disabled
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar sub bodega origen"
            >
                <IterateOptions data={data.result} label="substowage_name" />
            </SelectTouch>
        </FormControl>
    )
}

function SubStowageDestination() {
    const { props, propsSearch, data, handleChange } =
        useSubStowageDestination()

    return (
        <FormControl label="Sub bodega destino">
            <SelectTouch
                disabled
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar sub bodega destino"
            >
                <IterateOptions data={data.result} label="substowage_name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function FiltersForm() {
    const { props } = controlList.useForm(filterForm)

    function handleClear() {
        reqStowageDestination.setQuery('origin_stowage_id', null)
        reqSubStowageOrigin.setParam('stowage_id', null)
        props.onClear()
    }

    return (
        <DropContent
            {...props}
            label="Filtros"
            className="ml-2"
            onClear={handleClear}
        >
            <Warehouse />
            <StowageOrigin />
            <StowageDestination />
            <SubStowageOrigin />
            <SubStowageDestination />
        </DropContent>
    )
}
