import BtnActionTable from '../../../../components/BtnActionTable'
import Popover from '../../../../components/Popover'
import Rules from '../../../../components/Rules'
import OptionExport from './OptionExport'

export default function PopoverMore() {
    return (
        <Rules cu="CU294">
            <Popover
                component={<BtnActionTable iconName="bs-three-dots" />}
                position="bottom-right-left"
                element=".control"
            >
                <OptionExport />
            </Popover>
        </Rules>
    )
}
