import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import ActivateDeactivate from './ActivateDeactivate'
import { controlList } from '../storage'
import useSubmit from '../useSubmit'
import Enable from '../../../components/Enable'
import Rules from '../../../components/Rules'
import LogisticalConcept from '../../../models/LogisticalConcept'
import BtnActionTable from '../../../components/BtnActionTable'

function Edit({ concept }: { concept: LogisticalConcept }) {
    const open = useSubmit(concept.id)
    return (
        <BtnActionTable
            title="Editar"
            iconName="bs-pencil"
            onClick={() => open(concept)}
        />
    )
}

export default function TablePaymentConcepts() {
    return (
        <List control={controlList}>
            {(concept, e) => (
                <tr key={concept.id}>
                    <td>{e}</td>
                    <td>
                        <p
                            className={
                                !concept.is_enabled ? 'line-through' : ''
                            }
                        >
                            {concept.name}
                        </p>
                    </td>
                    <td>{concept.number_documents}</td>
                    <td>
                        <Enable enabled={concept.is_enabled} />
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU239">
                                <Edit concept={concept} />
                            </Rules>
                            <Rules cu="CU242">
                                <Delete
                                    path={`/v5.6/apis/logistical_concept/${concept.id}`}
                                    loadingMessage="Eliminando concepto de pago"
                                    control={controlList}
                                    disabled={concept.number_documents > 0}
                                >
                                    Esta acción es irreversible. ¿Estás seguro
                                    de eliminar el concepto de pago{' '}
                                    <b>{concept.name}</b>?
                                </Delete>
                            </Rules>
                            <Rules cu={concept.is_enabled ? 'CU240' : 'CU241'}>
                                <ActivateDeactivate
                                    logisticalConcept={concept}
                                ></ActivateDeactivate>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
