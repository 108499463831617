import {
    FormControl,
    IterateOptions
} from '../../../../components/FormUtilities'
import SelectTouch from '../../../../components/SelectTouch'
import useCompany from './useCompany'
import useStowageDestination from './useStowageDestination'

function CompanyField() {
    const { props, handleChange, companies } = useCompany()

    return (
        <FormControl label="Companía" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar compañía"
                label="Compañía"
                clear={false}
            >
                <IterateOptions
                    data={companies}
                    label="name"
                    image="image_url"
                />
            </SelectTouch>
        </FormControl>
    )
}

function StowageDestiny() {
    const { props, stowages, handleChange, propsSearch } =
        useStowageDestination()

    return (
        <FormControl label="Bodega destino" required>
            <SelectTouch
                {...props}
                {...propsSearch}
                onChange={handleChange}
                placeholder="Seleccionar bodega destino"
                label="Bodega destino"
            >
                <IterateOptions data={stowages} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export default function Fields1() {
    return (
        <>
            <CompanyField />
            <StowageDestiny />
        </>
    )
}
