import { FormControl } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import { formWarehouse } from '../storage'

function Name() {
    const { props, getError } = formWarehouse.useInput('name')
    const helper = getError(
        'ERROR-0000102',
        'Ya existe un almacén con el mismo nombre'
    )

    return (
        <FormControl required label="Nombre de almacén">
            <InputField
                {...props}
                placeholder="Ingresar nombre de almacén"
                helper={helper || props.helper}
            />
        </FormControl>
    )
}

function Address() {
    const { props, getError } = formWarehouse.useInput('address')
    const helper = getError()

    return (
        <FormControl required label="Dirección fiscal" col="span-6">
            <InputField
                {...props}
                placeholder="Ingresar dirección fiscal"
                helper={helper || props.helper}
                maxLength={300}
            />
        </FormControl>
    )
}

export default function Fields2() {
    return (
        <>
            <Name />
            <Address />
        </>
    )
}
