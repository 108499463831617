import { Option } from '../../config/types/component'
import { SelectListProvider } from '../../contexts/select-context'
import useOut from '../../hooks/useOut'
import Loading from '../Loading'
import SearchField from '../SearchField'
import { PropsSearchResults } from './types'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

export default function SearchResults({
    children,
    helper = '',
    onChange = () => null,
    onSelect = () => null,
    loading,
    label = 'Resultado',
    options = [],
    ...props
}: PropsSearchResults) {
    const { ref, isOpen, setOpen } = useOut([])
    const selectBodyRef = useRef<HTMLDivElement>()
    function setPosition() {
        if (!selectBodyRef.current) return
        const y = ref.current?.getBoundingClientRect().y
        const x = ref.current?.getBoundingClientRect().x
        const scrollY = window.pageYOffset
        const widthSelect = ref.current?.clientWidth

        selectBodyRef.current.style.width = `${widthSelect}px`
        selectBodyRef.current.style.top = `${y + scrollY + 42}px`
        selectBodyRef.current.style.left = `${x}px`
    }
    useEffect(() => {
        const timer = setTimeout(() => setPosition(), 200)
        window.addEventListener('scroll', setPosition)
        window.addEventListener('resize', setPosition)
        return () => {
            clearTimeout(timer)
            window.removeEventListener('scroll', setPosition)
            window.removeEventListener('resize', setPosition)
        }
    }, [])

    function handleOption(opt: Option) {
        if (opt.value === '0' || opt.value === '') {
            onSelect([])
            return
        }
        const finded = options.find(_op => _op.value === opt.value)
        if (finded) {
            const optionsFiltered = options.filter(
                option => option.value !== opt.value
            )
            onSelect(optionsFiltered)
        } else {
            onSelect([...options, opt])
        }
    }

    function handleChange(ev: any) {
        onChange(ev)
    }

    function handleClick() {
        setOpen(true)
    }

    return (
        <div ref={ref} className="relative">
            <SearchField
                {...props}
                fullWidth
                onChange={handleChange}
                onClear={() => handleChange({ target: { value: '' } })}
                onClick={handleClick}
                helper={helper}
                size="full"
            />
            {createPortal(
                <SelectListProvider
                    isOpen={isOpen}
                    onOption={handleOption}
                    label={label}
                    className="w-full top-auto z-select-options"
                    search=""
                    onSearch={null}
                    onAccept={() => setOpen(false)}
                    optionsChecks={options.map(option => option.value)}
                    ref={selectBodyRef}
                >
                    {loading ? <Loading scale={0.7} /> : children}
                </SelectListProvider>,
                document.querySelector('#select-contain')
            )}
        </div>
    )
}
