import Initial from '../../../components/Initial'
import Panel, { PanelBody } from '../../../components/Panel'
import useInitial from '../useInitial'
import { controlTabs, controlList } from '../storage'
import Filters from './Filters'
import Table from './Table'
import { tHeadsApproved } from '../constants'

// controlTabs.setSection('Pendientes', Table, 'alert')
controlTabs.setSection('Aprobados', Table, 'check-cirle')
// controlTabs.setSection('Rechazados', Table, 'times')
// controlTabs.setSection('Cancelados', Table, 'trash')

export default function Traspases() {
    const { state } = useInitial()
    const value = controlTabs.useStep(() => {
        controlList.filter.params.status_id = 3
        controlList.thead = tHeadsApproved
        controlList.load().then(() => {
            controlList.render()
        })
        // switch (to) {
        //     // case 'step1':
        //     //     controlList.filter.params.status_id = 3
        //     //     controlList.thead = tHeadsPending
        //     //     controlList.load().then(() => {
        //     //         controlList.render()
        //     //     })
        //     //     break
        //     case 'step1':
        //         controlList.filter.params.status_id = 3
        //         controlList.thead = tHeadsApproved
        //         controlList.load().then(() => {
        //             controlList.render()
        //         })
        //         break
        //     // case 'step3':
        //     //     controlList.filter.params.status_id = 2
        //     //     controlList.thead = tHeadsRejected
        //     //     controlList.load().then(() => {
        //     //         controlList.render()
        //     //     })
        //     //     break
        //     // default:
        //     //     controlList.filter.params.status_id = 4
        //     //     controlList.thead = tHeadsCanceled
        //     //     controlList.load().then(() => {
        //     //         controlList.render()
        //     //     })
        // }

        return true
    })

    return (
        <Initial pageState={state}>
            <Panel>
                <PanelBody>
                    <Filters />
                    <controlTabs.SectionTabs
                        value={value}
                        type="tab"
                        mode="simple"
                        className="mb-4 mt-2"
                    />
                </PanelBody>
            </Panel>
        </Initial>
    )
}
