import { Link } from 'react-router-dom'
import Button from '../../../components/Button'
import { PageHeader } from '../../../components/PagesUtilities'

export default function Header() {
    return (
        <PageHeader subtitle="(*) Complete todos los campos para agregar">
            <Link to="/requisitions/management_requisition">
                <Button color="ghost">Regresar al listado</Button>
            </Link>
        </PageHeader>
    )
}
