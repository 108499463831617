import moment from 'moment'
import { useState } from 'react'
import { renders, useRender, render } from 'redity'
import { Sttore } from 'sttore'
import { PropsDropContent } from '../../../components/DropContent/types'
import { PropsSearchField } from '../../../components/SearchField/type'
import { Option } from '../../../config/types/component'
import FormEvents from '../../FormEvents'
import { get, post } from '../../http'
import { HttpResponse, Base } from '../../http/type'
import Control from '../Control'
import filterTools, { FilterTools } from '../tools/filterTools'
import { ConfigLabel, ConfigValue } from '../types'
import useLoadSearch from './useLoadSearch'

export type THeadValue = string | { label: string; value: string }
export interface Options {
    path?: string
    theads: THeadValue[]
    method?: 'post' | 'get'
}

export default class ControlList<T> extends Control {
    path: string
    private method: 'post' | 'get' = 'get'
    private body: Record<string, any> = {}
    loading = false
    loadingSearch = false
    thead: Array<THeadValue> = []
    filter: FilterTools
    readonly indexs = {
        BODY: 'BODY',
        HEAD: 'HEAD',
        SEARCH: 'SEARCH',
        PAGINATION: 'PAGINATION',
        BADGES: 'BADGES',
        FORM: 'FORM'
    }

    list: Array<T> = []
    response: Base<T[]> = null

    constructor(keyRender: string, options: Options) {
        super(keyRender)
        this.path = options.path || ''
        this.thead = options.theads
        this.method = options.method || 'get'
        this.filter = filterTools(options.path)
    }

    private setResult(result: HttpResponse<Array<T>>) {
        this.list = result.data?.result || []
        this.response = result.data
        this.filter.setPagination(result.data?.pagination)
    }

    /**
     * @deprecated use render
     * @example
     * controlList.render()
     */
    renderMain(): boolean {
        renders(this.keyRender)
        return true
    }

    render(index?: string) {
        if (index) {
            render(this.keyRender, index)
        } else {
            renders(this.keyRender)
        }
    }

    async load(
        path?: string,
        body: Record<string, any> = null
    ): Promise<boolean> {
        if (path) {
            this.path = path
            this.filter.setPath(path)
        }

        if (body) {
            this.body = body
        }

        const send = this.method === 'post' ? post : get
        const result = await send<Array<T>>(this.filter.fullPath(), this.body)
        if (result.error?.type === 'cancel') return false
        this.setResult(result)
        this.loading = false
        return result.error === null
    }

    /**
     * @deprecated use load, solo se ha renombrado
     * @example
     * controlList.load()
     */
    async done(
        path?: string,
        body: Record<string, any> = null
    ): Promise<boolean> {
        return this.load(path, body)
    }

    useSearch(): { data: any; props: PropsSearchField } {
        useRender(this.keyRender, this.indexs.SEARCH)
        const handleSearch = async (ev: any) => {
            const value = ev.target.value
            this.filter.params.search = value
            this.loading = true
            this.loadingSearch = true
            this.filter.params.page = 1
            renders(this.keyRender)
            const result = await this.done()
            this.loadingSearch = false
            if (result) {
                renders(this.keyRender)
            }
        }

        return {
            data: null,
            props: {
                loading: this.loadingSearch,
                value: this.filter.params.search,
                onChange: handleSearch,
                onClear: () => handleSearch({ target: { value: '' } })
            }
        }
    }

    useFilter<D>(name: string, path?: string) {
        const r = useRender(this.keyRender, name)
        const option = this.filter.getOption(name)
        const { data, search, loading, onSearch } = useLoadSearch<D>(path)

        const onChange = async (op: Option) => {
            if (op?.value === '0' || op?.value === '') {
                this.filter.deleteFilter(name)
            } else {
                this.filter.setOption(name, op)
            }
            this.filter.params.page = 1
            this.loading = true
            renders(this.keyRender)
            path && onSearch({ target: { value: '' } })
            r()
            const result = await this.done()
            if (result) {
                renders(this.keyRender)
            }
        }

        return {
            data,
            props: {
                onChange,
                option,
                name,
                search,
                loading,
                onSearch: path ? onSearch : null
            }
        }
    }

    onDeleteComponentBadge: (key: string) => void = () => null
    onDeleteBadge: (key: string) => void = () => null

    private loadByForm = async () => {
        this.loading = true
        this.filter.params.page = 1
        renders(this.keyRender)
        const result = await this.done()
        if (result) {
            renders(this.keyRender)
        }
    }

    private getLabels = (configLabel: ConfigLabel<any>, store: Sttore<any>) => {
        return typeof configLabel === 'function'
            ? configLabel(store())
            : configLabel || {}
    }

    renderForm = () => {
        render(this.keyRender, this.indexs.FORM)
    }

    useForm<F>(
        formEvent: FormEvents<F>,
        configLabel?: ConfigLabel<F>,
        configValue?: ConfigValue<F>
    ): PropsUseForm {
        useRender(this.keyRender, this.indexs.FORM)
        const countChanges = () =>
            Object.values(formEvent.store.changes()).length
        const [count, setCount] = useState(() => countChanges())

        this.renderForm = () => {
            setCount(countChanges())
        }

        const apply = async () => {
            const labels = this.getLabels(configLabel, formEvent.store)
            const valuesConfigured = configValue
                ? configValue(formEvent.store())
                : {}

            const values = formEvent.store()
            Object.keys(values).forEach(key => {
                const valueConfigured = valuesConfigured[key] as
                    | Option
                    | string
                    | boolean
                const value =
                    valueConfigured ||
                    (values[key] as Option | string | boolean)
                if (value === '' || value === null) {
                    this.filter.deleteFilter(key)
                    return
                }

                if (typeof value === 'object') {
                    this.filter.setOption(key, value)
                }
                if (typeof value === 'string' || typeof value === 'boolean') {
                    this.filter.setOption(key, {
                        label: labels[key] || value.toString(),
                        value: value.toString()
                    })
                }
            })

            setCount(countChanges())
            await this.loadByForm()
        }

        this.onDeleteComponentBadge = (key: string) => {
            const value = formEvent.store()[key]
            if (value !== undefined) {
                if (typeof value === 'string') {
                    formEvent.setValue(key as keyof F, '' as any)
                } else {
                    formEvent.setValue(key as keyof F, null)
                }
            }
            this.onDeleteBadge(key)
            setCount(countChanges())
        }

        const clear = () => {
            formEvent.init()
            renders(formEvent.keyRender)
        }

        return {
            data: null,
            props: {
                onApply: apply,
                count,
                onClear: clear
            }
        }
    }

    badgeRemove(filterName: string, active: boolean) {
        this.filter.badgeRemove(filterName, active)
    }

    useDatePicker(startDateName: string, endDateName: string) {
        const r = useRender(this.keyRender, startDateName)
        const startDate = this.filter.getOption(startDateName)
        const endDate = this.filter.getOption(endDateName)

        const onChange = async (start: string, end: string) => {
            this.filter.setOption(startDateName, {
                label: moment(start).format('DD-MM-YYYY'),
                value: start
            })
            this.filter.setOption(endDateName, {
                label: moment(end).format('DD-MM-YYYY'),
                value: end
            })
            this.filter.params.page = 1
            renders(this.keyRender)
            r()
            const result = await this.done()
            if (result) {
                renders(this.keyRender)
            }
        }

        return {
            data: null,
            props: {
                onChange,
                startDate,
                endDate
            }
        }
    }

    useParam(fieldName: string) {
        useRender(this.keyRender, fieldName)
        const handleChange = async (value: string) => {
            this.filter.params[fieldName] = value
            this.loading = true
            this.filter.params.page = 1
            renders(this.keyRender)
            const result = await this.load()
            if (result) {
                renders(this.keyRender)
            }
        }

        return {
            value: this.filter.params[fieldName] || '',
            setParam: handleChange
        }
    }
}

interface PropsUseForm {
    data: any
    props: Omit<PropsDropContent, 'label' | 'children'>
}
