import SearchField from '../../../components/SearchField'
import { controlList, reqStowage, filterForm, storagePage } from '../storage'
import Dropdown from '../../../components/Dropdown'
import { IterateOptions } from '../../../components/FormUtilities'
import useStorage from '../../../hooks/useStorage'
import FiltersForm from './FiltersForm'
import { Option as OptionType } from '../../../config/types/component'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { setCompanySelected } from '../../../utilities/controlStorage'
function Company() {
    const { props } = controlList.useFilter('company_id')
    const { companiesFromSession } = useStorage()

    function handleChange(option: OptionType) {
        controlList.filter.params.page = 1
        controlList.filter.deleteFilter('stowage_id')
        filterForm.setValue('stowage_id', null)
        reqStowage.setQuery('company_id', option.value)

        const company = companiesFromSession.find(
            _company => _company.id === parseInt(option.value)
        )

        storagePage.company_enabled = company.is_enabled
        storagePage.timezone = company.timezone
        setCompanySelected(company)
        reqStowage.load()
        controlList.renderForm()
        props.onChange(option)
    }

    return (
        <Dropdown
            {...props}
            onChange={handleChange}
            label="Compañía"
            showLabel={false}
            clear={false}
        >
            <IterateOptions
                data={companiesFromSession.map(company => ({
                    ...company,
                    image_url: company.country.image_url,
                    strikeOut: !company.memory
                }))}
                label="name"
                image="image_url"
            />
        </Dropdown>
    )
}

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por código de requisición o pedido"
            size="auto"
            fullWidth={true}
            className="ml-0"
        />
    )
}

export default function Filters() {
    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft>
                    <Company />
                    <FiltersForm />
                </FlexLeft>
                <FlexRight></FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
