import { Keys } from './constants'
import ControlList from '../../utilities/Control/ControlList'
import FormEvents from '../../utilities/FormEvents'
import { FormStowage, StoragePage } from './types'
import { GetStowages } from './../../types/stowages'
import buildNumberFormat from '../../utilities/buildNumberFormat'

export const controlList = new ControlList<GetStowages>(Keys.MAIN, {
    path: '/v5.6/apis/stowages',
    theads: [
        '#',
        { value: 'name', label: 'Bodega' },
        { value: 'type_stowage', label: 'Tipo de bodega' },
        { value: 'type_inventory', label: 'Tipo de inventario' },
        'Monto de inventario',
        { value: 'is_enabled', label: 'Estado' },
        'Acciones'
    ]
})
controlList.onDeleteComponentBadge = key => {
    if (key === 'company_id') {
        storagePage.company = null
    }
}

export const formStowage = new FormEvents<FormStowage>(Keys.MODAL_FORM, {
    company: null,
    name: '',
    type_stowage: null,
    client: null,
    max_amount: buildNumberFormat(0),
    currency_symbol: ''
})

export const storagePage: StoragePage = {
    company: null,
    modeModal: 'creation'
}

formStowage.setProps('client', { disabled: true })
formStowage.store.on('type_stowage', function (option) {
    let disabled = true
    if (storagePage.modeModal === 'creation') {
        disabled = option ? option.value !== '7' : true
    }

    formStowage.setProps('client', { disabled })
    if (disabled) formStowage.setValue('client', null)
})
