import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { controlList } from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)
    useEffect(() => {
        controlList.filter.changeParams('sort', 'commercial_name')
        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })
    }, [])

    return state
}
