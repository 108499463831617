import classNames from 'classnames'
import useOut from '../../hooks/useOut'
import Button from '../Button'
import Icon from '../Icon'
import { PropsDropContent } from './types'

export default function DropContent({
    label,
    className,
    children,
    onApply = () => null,
    onClear = () => null,
    onOpen = () => null,
    count = 0
}: PropsDropContent) {
    const { ref, isOpen, setOpen } = useOut([])

    function handleClick() {
        onOpen()
        setOpen(true)
    }

    return (
        <div ref={ref} className="drop-content relative">
            <button
                onClick={handleClick}
                className={classNames(
                    `btn btn-sm font-univers-light btn-secondary text-xs`,
                    className
                )}
            >
                <Icon name="filter" className="mr-1" size={14} />
                {label}
                {count ? (
                    <div className="rounded-full w-4 h-4 text-xs bg-white text-primary ml-2">
                        {count}
                    </div>
                ) : (
                    ''
                )}
            </button>
            {isOpen && (
                <div
                    className={classNames(
                        'absolute rounded shadow-lg bg-white border py-4 px-6 z-20 zoom-in right-0 top-9 w-80',
                        {
                            hidden: !isOpen
                        },
                        className
                    )}
                >
                    <div className="flex justify-between mb-3">
                        <label
                            htmlFor=""
                            className="text-accent font-univers text-xs"
                        >
                            {label}
                        </label>
                    </div>
                    {children}
                    <div className="flex justify-end pt-4 mt-4">
                        <Button
                            className="mr-2"
                            size="sm"
                            color="ghost"
                            onClick={onClear}
                        >
                            Limpiar
                        </Button>
                        <Button
                            className="dropdown-close"
                            size="sm"
                            color="accent"
                            onClick={onApply}
                        >
                            Aplicar
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}
