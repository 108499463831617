import BtnActionTable from '../../../../components/BtnActionTable'
import { viewPrint } from '../../../../sections/ModalPrint'
import { GetPurchases } from '../../../../types/purchase'
import Pdf from '../Pdf'

export default function LoadPDF({ order }: { order: GetPurchases[number] }) {
    function handleClick() {
        viewPrint(`/v5.6/apis/purchases/${order.id}`, () => ({
            Component: Pdf
        }))
    }
    return (
        <BtnActionTable
            title="Imprimir"
            iconName="bs-printer"
            onClick={handleClick}
        />
    )
}
