import modal from '../../sections/modal'
import { Popup } from '../../utilities/popup'
import { initialEdition } from './Utilities/initialEdition'
import { storagePage, controlList, formStowage } from './storage'
import Form from './View/Form'
import { get } from '../../utilities/http'
import { GetStowageView } from '../../types/stowages'
import { ModalTools } from '../../sections/modal/types'
import { FormEvent } from 'react'
import useStorage from '../../hooks/useStorage'
import Country from '../../models/Country'
import { clearFractionNumber } from '../../utilities/buildNumberFormat'

export default function useSubmit(stowageId?: number) {
    const { countries } = useStorage()
    const { submit, validation, observer } = formStowage.useSubmit(
        stowageId ? `/v5.6/apis/stowages/${stowageId}` : '/v5.6/apis/stowages',
        {
            method: stowageId ? 'put' : 'post',
            done: controlList
        }
    )

    validation((v, f) => {
        v.name
            .minLength(2, 'El número de caracteres válidos es 2 - 180')
            .maxLength(180, 'El número de caracteres válidos es 2 - 180')
            .isEmpty('Se requiere ingresar el nombre')
        if (f.max_amount !== '') {
            v.max_amount
                .isMinor(1, 'El número debe ser mayor o igual a 1')
                .isGreater(
                    9999999999.99,
                    'El número debe ser menor o igual a 9999999999.99'
                )
        }

        if (storagePage.modeModal === 'creation') {
            v.type_stowage.isEmpty('Se requiere seleccionar el tipo de bodega')
            if (f.type_stowage && f.type_stowage.value === '7') {
                v.client.isEmpty('Se requiere seleccionar el cliente')
            }
            v.company.isEmpty('Se requiere seleccionar la compañía')
        }
    })

    observer<any>(form => ({
        name: form.name,
        max_amount: form.max_amount
            ? clearFractionNumber(form.max_amount)
            : null,
        ...(storagePage.modeModal === 'creation' && {
            company_id: parseInt(form.company.value),
            type_stowage_id: parseInt(form.type_stowage.value),
            ...(form.type_stowage &&
                form.type_stowage.value === '7' && {
                    client_id: parseInt(form.client.value)
                })
        })
    }))

    return function openModal() {
        modal(
            Form,
            async () => {
                return onOpen(submit, countries, stowageId)
            },
            'sm'
        )
    }
}

// Sonarlint
async function onOpen(
    submit: (
        ev?: FormEvent<HTMLFormElement>,
        statesModal?: ModalTools
    ) => Promise<void>,
    countries: Country[],
    stowageId?: number
) {
    formStowage.init()

    if (!stowageId && storagePage.company === null) {
        Popup.error('Error', 'Se debe seleeccionar una compañía')
        return null
    }

    if (stowageId) {
        storagePage.modeModal = 'edition'
        const { data, error } = await get<GetStowageView>(
            `/v5.6/apis/stowages/${stowageId}`
        )
        formStowage.setProps('type_stowage', { disabled: true })
        formStowage.setProps('client', { disabled: true })

        if (!error) initialEdition(data.result, countries)
    } else {
        storagePage.modeModal = 'creation'
        formStowage.setValue('company', storagePage.company)
        formStowage.setValue('currency_symbol', storagePage.company.span)
    }

    return {
        title: stowageId ? 'Editar bodega' : 'Nueva bodega',
        onSubmit: submit
    }
}
