import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import TableDistributionByProduct from './TableDistributionByProduct'
import TableDistributionbyRC from './TableDistributionbyRC'

export default function SectionCostDistribution() {
    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Distribución por RC" />
                <PanelBody>
                    <TableDistributionbyRC />
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader title="Distribución por Producto" />
                <PanelBody>
                    <TableDistributionByProduct />
                </PanelBody>
            </Panel>
        </>
    )
}
