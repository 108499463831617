import {
    Empty,
    FormControl,
    IterateOptions
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import Option from '../../../../../components/Option'
import SelectTouch from '../../../../../components/SelectTouch'
import useStorage from '../../../../../hooks/useStorage'
import { formOC } from '../../../storage'
import useBillingCompany from './useBillingCompany'
import useDestinationCompany from './useDestinationCompany'
import useProviderField from './useProviderField'

export default function Fields1() {
    return (
        <>
            <BillingCompany />
            <DestinationCompany />
            <ProviderField />
            <TypeOC />
        </>
    )
}

function BillingCompany() {
    const { props, handleChange } = useBillingCompany()

    return (
        <FormControl label="Compañía de facturación" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar compañía de facturación"
                clear={false}
            >
                <CompanyOptions />
            </SelectTouch>
        </FormControl>
    )
}

function DestinationCompany() {
    const { props, handleChange } = useDestinationCompany()

    return (
        <FormControl label="Compañía destino" required>
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar compañía destino"
                clear={false}
            >
                <CompanyOptions />
            </SelectTouch>
        </FormControl>
    )
}

function ProviderField() {
    const { props, providers, handleChange, helper, propsSearch } =
        useProviderField()

    return (
        <FormControl label="Proveedor" required>
            <SelectTouch
                {...props}
                {...propsSearch}
                placeholder="Seleccionar proveedor"
                onChange={handleChange}
                helper={helper || props.helper}
            >
                <IterateOptions data={providers} label="commercial_name" />
            </SelectTouch>
        </FormControl>
    )
}

function TypeOC() {
    const { props } = formOC.useInput('type_oc')

    return (
        <FormControl label="Tipo de orden de compra">
            <InputField {...props} placeholder="-" disabled />
        </FormControl>
    )
}

function CompanyOptions() {
    const { companiesFromSession } = useStorage()

    const companies = companiesFromSession.filter(company => company.is_enabled)
    return (
        <>
            {companies.map(company => (
                <Option
                    label={company.name}
                    value={company.id.toString()}
                    image={company.country.image_url}
                    key={company.id}
                />
            ))}
            <Empty data={companies} />
        </>
    )
}
