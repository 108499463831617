import { FormControl } from '../../../components/FormUtilities'
import InputField from '../../../components/InputField'
import { formPurchaseReceipt, storagePage } from '../storage'
import DatePicker from '../../../components/Datepicker'
import NumeralField from '../../../components/NumeralField'
import { useRender } from 'redity'

function NumberInvoiceShipping() {
    const { props } = formPurchaseReceipt.useInput('numberInvoiceShipping')

    return (
        <FormControl label="Nro. de factura de importación">
            <InputField
                {...props}
                placeholder="Ingresar número de factura de importación"
            />
        </FormControl>
    )
}

function NumberInvoiceFinal() {
    const { props } = formPurchaseReceipt.useInput('numberInvoiceFinal')

    return (
        <FormControl label="Nro. de factura final">
            <InputField
                {...props}
                placeholder="Ingresar número de factura final"
            />
        </FormControl>
    )
}

function InvoiceShippingDate() {
    const { props } = formPurchaseReceipt.useDate('invoiceShippingDate')
    return (
        <FormControl label="Fecha de factura de importación">
            <DatePicker
                {...props}
                placeholder="Seleccionar fecha de factura de importación"
            />
        </FormControl>
    )
}

function InvoiceFinalDate() {
    const { props } = formPurchaseReceipt.useDate('invoiceFinalDate')
    return (
        <FormControl label="Fecha final de factura">
            <DatePicker
                {...props}
                placeholder="Seleccionar fecha final de factura"
            />
        </FormControl>
    )
}

function ProviderLocalAmount() {
    useRender('PROVIDER_LOCAL_AMOUNT')
    const { props } = formPurchaseReceipt.useInput('providerLocalAmount')
    const localCurrency = storagePage.localCurrency
    const label = `Monto proveedor (${
        localCurrency ? localCurrency.symbol : '-'
    })`
    return (
        <FormControl label={label}>
            <NumeralField
                {...props}
                decimal={2}
                placeholder="Ingresar monto proveedor"
            />
        </FormControl>
    )
}

export default function Fields4() {
    return (
        <>
            <NumberInvoiceShipping />
            <NumberInvoiceFinal />
            <InvoiceShippingDate />
            <InvoiceFinalDate />
            <ProviderLocalAmount />
        </>
    )
}
