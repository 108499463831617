import DataTable, { TBody, THead } from '../../../components/DataTable'
import Typography from '../../../components/Typography'
import { ProductIncompatible } from '../types'

export default function ModalProducts({
    data
}: {
    data: ProductIncompatible[]
}) {
    return (
        <>
            <div className="mb-4">
                <span>
                    El producto presenta normas de almacenaje con los siguientes
                    productos.
                </span>
            </div>
            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th>Producto</th>
                    </tr>
                </THead>
                <TBody>
                    {data.map((item, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                                <Typography
                                    type="paragraph"
                                    className={'font-bold'}
                                >
                                    {item.name}
                                </Typography>
                                <Typography type="small" color="gray">
                                    {item.code}
                                </Typography>
                            </td>
                        </tr>
                    ))}
                </TBody>
            </DataTable>
        </>
    )
}
