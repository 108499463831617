import { useHistory } from 'react-router'
import ReactTooltip from 'react-tooltip'
import BtnActionTable from '../../../components/BtnActionTable'
import Rules from '../../../components/Rules'
import useRule from '../../../hooks/useRule'
import Delete from '../../../sections/Delete'
import List from '../../../sections/List'
import { view } from '../../../sections/View'
import { controlList } from '../storage'
import { ProductView } from '../types'
import Product from './Product'

function Code({ code, product_id }: { code: string; product_id: number }) {
    const math = useRule('CU071')

    return (
        <div
            {...(math && {
                className: 'underline cursor-pointer',
                onClick: () =>
                    view<ProductView>(
                        `/v5.6/apis/products/${product_id}`,
                        product => ({
                            title: product.name,
                            subtitle: product.code,
                            Component: Product
                        })
                    )
            })}
        >
            <b>{code}</b>
        </div>
    )
}

export default function TableProducts() {
    const { push } = useHistory()
    return (
        <List control={controlList}>
            {(product, i) => (
                <tr key={product.id}>
                    <td>{i}</td>
                    <td>
                        <Code code={product.code} product_id={product.id} />
                    </td>
                    <td className="max-w-md" style={{ whiteSpace: 'normal' }}>
                        {product.description}
                    </td>
                    <td className="max-w-sm">
                        <div className="flex" style={{ flexFlow: 'row wrap' }}>
                            {product.product_countries.map(pc =>
                                pc.product_companies
                                    .filter(
                                        pcom => pcom.can_buy || pcom.can_sell
                                    )
                                    .map((pcom, j) => (
                                        <div key={j}>
                                            <img
                                                data-for="company"
                                                data-tip={pcom.company.name}
                                                src={
                                                    pcom.company.country
                                                        .image_url
                                                }
                                                className="mr-2 w-8 my-1"
                                                style={{ minWidth: '32px' }}
                                            />
                                            <ReactTooltip
                                                id="company"
                                                place="top"
                                                type="dark"
                                                effect="solid"
                                                multiline={true}
                                            />
                                        </div>
                                    ))
                            )}
                        </div>
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {product.brand.name}
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {product.subcategory.category.name}
                    </td>
                    <td style={{ whiteSpace: 'normal' }}>
                        {product.subcategory.name}
                    </td>
                    <td>
                        <div className="group-btn-options">
                            <Rules cu="CU065">
                                <BtnActionTable
                                    title="Editar"
                                    iconName="bs-pencil"
                                    onClick={() =>
                                        push(
                                            `/configuration/products/${product.id}/edition`
                                        )
                                    }
                                />
                            </Rules>
                            <Rules cu="CU066">
                                <Delete
                                    path={`/v5.6/apis/products/${product.id}`}
                                    control={controlList}
                                >
                                    ¿Está seguro de eliminar el producto{' '}
                                    <b className="text-primary">
                                        {product.name}
                                    </b>
                                    ?
                                </Delete>
                            </Rules>
                        </div>
                    </td>
                </tr>
            )}
        </List>
    )
}
