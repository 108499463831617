import Button from '../../../components/Button'
import Rules from '../../../components/Rules'
import SearchField from '../../../components/SearchField'
import {
    FlexBox,
    FlexLeft,
    FlexRight,
    SectionFiltersGrid
} from '../../../components/UtilsResponsive/UtilsResponsive.style'
import { controlList } from '../storage'
import useSubmit from '../useSubmit'

function Search() {
    const { props } = controlList.useSearch()
    return (
        <SearchField
            {...props}
            placeholder="Buscar por nombre de concepto"
            size="auto"
            fullWidth={true}
        />
    )
}

export default function FiltersPaymentConcept() {
    const open = useSubmit()

    return (
        <SectionFiltersGrid>
            <Search />
            <FlexBox>
                <FlexLeft></FlexLeft>
                <FlexRight>
                    <Rules cu="CU238">
                        <Button color="accent" size="sm" onClick={() => open()}>
                            Nuevo
                        </Button>
                    </Rules>
                </FlexRight>
            </FlexBox>
        </SectionFiltersGrid>
    )
}
