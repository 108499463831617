import classNames from 'classnames'
import { PropsToggle } from './types'

export default function Toggle({
    className,
    checked = true,
    color = 'primary',
    onChange = () => null,
    size = 'sm',
    disabled = false
}: PropsToggle) {
    return (
        <input
            onChange={onChange}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            className={classNames(
                `toggle toggle-${size}`,
                {
                    [`toggle-${color}`]: true
                },
                className
            )}
        ></input>
    )
}
