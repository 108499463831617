import Icon from '../../../../components/Icon'
import { Step1 } from '../../types'
import usePrice from './usePrice'

export default function EditContact({ data }: { data: Step1.TablePrices }) {
    const open = usePrice(data.id)

    return (
        <Icon
            name="bs-pencil"
            className="mr-2"
            pointer
            disabled={!data.company.is_enabled}
            onClick={() => open(data)}
        />
    )
}
