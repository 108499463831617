import { Option } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import useStorage from '../../../../../hooks/useStorage'
import { GetProvider } from '../../../../../types/provider'
import {
    formOC,
    reqDeliveryCountry,
    reqLocate,
    reqRequisition,
    reqProviders
} from '../../../storage'

export default function useProviderField() {
    const { companiesFromSession } = useStorage()
    const { props, getError } = formOC.useSelect('provider')
    const { data, props: propsSearch } = useGet<GetProvider[]>(
        reqProviders.observer(
            '1>/v5.6/apis/providers?is_enabled=true&limit=20',
            true
        ),
        []
    )

    const helper = getError(
        'ERROR-0000244',
        'El proveedor debe poder vender al menos un producto en la compañía a facturar',
        'provider_id'
    )

    function handleChange(option: Option) {
        props.onChange(option)

        if (option.value === '0') {
            formOC.setValue('type_oc', '')
            reqDeliveryCountry.deleteParam('provider_id')
            formOC.setProps(
                'country_dispatch_withdrawal',
                {
                    disabled: true
                },
                false
            )
            reqLocate.deleteParam('provider_id')
        } else {
            const { destination_company } = formOC.store()
            const provider = data.result.find(
                prov => prov.id.toString() === option.value
            )

            const country_id = companiesFromSession.find(
                company => company.id.toString() === destination_company.value
            )?.country_id

            if (!country_id) {
                formOC.setValue('type_oc', '')
            } else {
                const isInternational = provider.country_id !== country_id
                formOC.setValue(
                    'type_oc',
                    isInternational ? 'Internacional' : 'Nacional'
                )
            }

            // País de despacho/retiro
            reqDeliveryCountry.setParam('provider_id', option.value)
            if (reqDeliveryCountry.hasQuery('shipping_type_purchase_id')) {
                reqDeliveryCountry.load()
                formOC.setProps(
                    'country_dispatch_withdrawal',
                    {
                        disabled: false
                    },
                    false
                )
            }

            // Lugar de despacho/retiro
            reqLocate.setParam('provider_id', option.value)
            reqRequisition.setQuery('provider_id', option.value)
        }

        formOC.setValue('country_dispatch_withdrawal', null)
        formOC.setValue('place_dispatch_pick_up', null)
        formOC.setProps(
            'place_dispatch_pick_up',
            {
                disabled: true
            },
            false
        )
        formOC.setProps('address', { disabled: true }, false)
        formOC.setValue('address', null)
    }

    return {
        props,
        propsSearch,
        providers: data.result,
        handleChange,
        helper
    }
}
