import {
    formTraspase,
    productsControl,
    reqProducts,
    reqStowageDestiny,
    reqStowageOrigin,
    reqSubStowageDestiny,
    reqSubStowageOrigin,
    storagePage
} from '../../storage'
import { StowageResponse } from '../../types'
import useGet from '../../../../hooks/useGet'
import { Option } from '../../../../config/types/component'

export default function useStowageOrigin() {
    const { props } = formTraspase.useSelect('stowage_origin')
    const { data, props: propsSearch } = useGet<StowageResponse[]>(
        reqStowageOrigin.observer(
            '/v5.6/apis/stowages?limit=10&is_enabled=true&is_enabled_for_minimal_stowage=true&require_all_substowages=false',
            true
        ),
        []
    )

    function handleChange(option: Option) {
        if (option.value === '0') {
            formTraspase.setProps(
                'stowage_destination',
                { disabled: true },
                false
            )
            formTraspase.setProps(
                'sub_stowage_origin',
                { disabled: true },
                false
            )
            formTraspase.setProps('traspase_products', {
                disabled: true
            })
        } else {
            storagePage.stowages_origin = data.result
            formTraspase.setProps(
                'stowage_destination',
                { disabled: false },
                false
            )

            const { type_stowage_id, minimal_stowage_principal }: any =
                data.result.find(
                    stowage => stowage.id === parseInt(option.value)
                )

            storagePage.current_type_stowage_id_origin = type_stowage_id

            const isPGR =
                type_stowage_id === 2 || // Pedidos (P)
                type_stowage_id === 6 || // Garantía proveedor (G)
                type_stowage_id === 11 // Remisionada (R)

            formTraspase.setProps(
                'sub_stowage_origin',
                { disabled: !isPGR },
                false
            )

            if (isPGR) {
                reqProducts.deleteQuery('origin_minimal_stowage_id')
                formTraspase.setProps(
                    'traspase_products',
                    {
                        disabled: true
                    },
                    false
                )
                reqSubStowageOrigin.setParam('stowage_id', option.value)
                reqSubStowageOrigin.load()
            } else {
                reqProducts.setQuery(
                    'origin_minimal_stowage_id',
                    minimal_stowage_principal.id
                )
                reqProducts.load()
                formTraspase.setProps(
                    'traspase_products',
                    {
                        disabled: false
                    },
                    false
                )
                storagePage.minimal_stowage_origin_id =
                    minimal_stowage_principal.id
            }

            reqStowageDestiny.setQuery('origin_stowage_id', option.value)
            reqStowageDestiny.load()
            if (minimal_stowage_principal != null) {
                reqSubStowageDestiny.setQuery(
                    'origin_minimal_stowage_id',
                    minimal_stowage_principal.id
                )
            }
        }

        formTraspase.setProps(
            'sub_stowage_destination',
            { disabled: true },
            false
        )
        formTraspase.setValue('stowage_destination', null)
        formTraspase.setValue('sub_stowage_origin', null)
        formTraspase.setValue('sub_stowage_destination', null)
        formTraspase.setValue('traspase_products', [])
        productsControl.setList([])
        reqProducts.deleteQuery('not_logical_product_ids')
        productsControl.renderMain()

        props.onChange(option)
    }

    return {
        props,
        stowages: data.result,
        handleChange,
        propsSearch
    }
}
