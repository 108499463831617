import moment from 'moment'
import { companySelected } from '../storage'
import { GetRequisition, MinimalStowageItem } from '../type'

export default function GeneralInformation({ data }: { data: GetRequisition }) {
    const company = companySelected

    return (
        <div className="body__cards mb-4">
            <div className="column">
                <div className="card_info">
                    <h3>INFORMACIÓN GENERAL</h3>
                    <ul className="list_info">
                        <li>
                            <p>Usuario</p>
                            <p>
                                <span
                                    className={
                                        data.document.requesting_user.is_enabled
                                            ? ''
                                            : 'line-through'
                                    }
                                >
                                    {data.document.requesting_user.name}{' '}
                                    {data.document.requesting_user.lastname}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>Fecha de solicitud</p>
                            <p>
                                {moment(data.document.request_date)
                                    .tz(company.timezone)
                                    .format('DD/MM/YYYY')}
                            </p>
                        </li>
                        <li>
                            <p>Proveedor</p>
                            <p>
                                <span
                                    className={
                                        !data.provider_company.provider
                                            .is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {
                                        data.provider_company.provider
                                            .commercial_name
                                    }
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>Catálogo de proveedor</p>
                            <div className="flex">
                                <img
                                    src={
                                        data.provider_company.company.country
                                            .image_url
                                    }
                                    className="w-6 ml-1 h-4 self-center"
                                />
                                <p>
                                    {data.provider_company.company.country.name}{' '}
                                    {' - '}
                                    {data.provider_company.company_id ===
                                    data.minimal_stowage.stowage.company_id
                                        ? 'Nacional'
                                        : 'Internacional'}
                                </p>
                            </div>
                        </li>
                        <li>
                            <p>Comentario</p>
                            <p>{data.document.description || '-'}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <div className="card_info">
                    <h3>BODEGA DESTINO</h3>
                    <ul className="list_info">
                        <li>
                            <p>Bodega destino</p>
                            <p>
                                <span
                                    className={
                                        !data.minimal_stowage.stowage.is_enabled
                                            ? 'line-through'
                                            : ''
                                    }
                                >
                                    {data.minimal_stowage.stowage.name}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p> Sub bodega destino</p>
                            <p>{getSubStowageName(data.minimal_stowage)}</p>
                        </li>
                        <li>
                            <p> Cliente</p>
                            <p>{getClientName(data.minimal_stowage)}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

function getSubStowageName(minimal_stowage: MinimalStowageItem) {
    if (minimal_stowage.order_sub_stowage) {
        return (
            <span
                className={
                    !minimal_stowage.order_sub_stowage.is_enabled
                        ? 'line-through'
                        : ''
                }
            >
                {minimal_stowage.order_sub_stowage.order.number}
            </span>
        )
    } else if (minimal_stowage.gp_sub_stowage) {
        return (
            <span
                className={
                    !minimal_stowage.gp_sub_stowage.is_enabled
                        ? 'line-through'
                        : ''
                }
            >
                {minimal_stowage.gp_sub_stowage.purchase.code}
            </span>
        )
    } else {
        return <>{'-'}</>
    }
}

function getClientName(minimal_stowage: MinimalStowageItem) {
    if (minimal_stowage.order_sub_stowage) {
        return (
            <span
                className={
                    !minimal_stowage.order_sub_stowage.order.client.is_enabled
                        ? 'line-through'
                        : ''
                }
            >
                {minimal_stowage.order_sub_stowage.order.client.name}
            </span>
        )
    } else {
        return <>{'-'}</>
    }
}
