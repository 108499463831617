import Layout from './components/Layout'
import Routes from './Routes'
import Loading from './components/Loading'
import AuthContext from './contexts/auth-context'
import useSession from './hooks/useSession'
import StorageContext from './contexts/storage-context'
import RoutingContext from './contexts/routing-context'
import server from './assets/img/server.svg'
import Typography from './components/Typography'
import Button from './components/Button'
import GlobalComponents from './GlobalComponents'
import Login from './pages/Login'
import useQuery from './hooks/useQuery'

export default function App() {
    const storageToken = localStorage.getItem('token')
    const { token } = useQuery<{ token: string }>()
    let autenticated = false
    if (typeof storageToken !== 'undefined' && storageToken !== null) {
        autenticated = true
    }
    if (typeof token === 'string') {
        autenticated = true
    }
    return <>{autenticated ? <InitialPage /> : <Login />}</>
}

function InitialPage() {
    const { step, initialData, routing, ...state } = useSession()
    return (
        <AuthContext.Provider value={state}>
            <StorageContext.Provider value={initialData}>
                <RoutingContext.Provider value={routing}>
                    {step === 'ready' && (
                        <Layout>
                            <Routes pages={routing.pages} />
                        </Layout>
                    )}
                    {(step === 'logging' || step === 'load_data') && (
                        <div className="w-full h-screen flex justify-center items-center">
                            <Loading>
                                ADVANCE:{' '}
                                {step === 'logging'
                                    ? 'INICIANDO SESIÓN'
                                    : 'CARGANDO DATOS'}
                            </Loading>
                        </div>
                    )}
                    {step === 'error' && <Error />}
                    <GlobalComponents />
                </RoutingContext.Provider>
            </StorageContext.Provider>
        </AuthContext.Provider>
    )
}

function Error() {
    return (
        <div className="flex items-center justify-center w-full h-screen flex-col">
            <img src={server} alt="" />
            <Typography type="header-2" className="mt-2">
                Algo salió mal...
            </Typography>
            <p className="text-gray-500 max-w-sm text-center mt-4">
                Estamos trabajando para resolver el problema.
                <br />
                Por favor intenta nuevamente cargar la vista.
            </p>
            <Button
                className="mt-4"
                color="primary"
                onClick={() => location.reload()}
            >
                Volver a intentar
            </Button>
        </div>
    )
}
