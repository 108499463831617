import Panel, { PanelBody, PanelHeader } from '../../../components/Panel'
import Initial from '../../../components/Initial'
import { FormControls } from '../../../components/FormUtilities'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import Fields3 from './Fields3'
import Fields4 from './Fields4'
import SelectOC from './SelectOC'
import Table from './Table'
import useInitial from '../useInitial'
import { productsControl, storagePage } from '../storage'
import Tooltip from '../../../components/Tooltip'
import Icon from '../../../components/Icon'
import Header from './Header'
import { useRender } from 'redity'
import { Keys } from '../constants'
import Typography from '../../../components/Typography'
import buildNumberFormat from '../../../utilities/buildNumberFormat'

function TooltipProducts() {
    const { message } = productsControl.useHelpers()
    return (
        <>
            {message && (
                <Tooltip content={message}>
                    <Icon name="warning" className="text-red-500" />
                </Tooltip>
            )}
        </>
    )
}
function Mount() {
    useRender(Keys.MOUNT)
    const localCurrency = storagePage.localCurrency
    // product.purchase.currency.symbol
    const mount = Object.values(storagePage.receipt_products)
        .map(values => values.quantity_requested * values.unit_purchase_price)
        .reduce((a, b) => a + b, 0)
    let symbol: any = null
    symbol = storagePage.symbolCurrency
    if (symbol == null) {
        symbol = localCurrency?.symbol || ''
    }
    const formattedMount = symbol + ' ' + buildNumberFormat(mount)
    return <Typography type="header-2">{formattedMount}</Typography>
}
export default function Warehouse() {
    const { pageState } = useInitial()

    return (
        <Initial pageState={pageState}>
            <div className="container_page--form">
                <Panel mode="form">
                    <PanelHeader title="Información de la recepción" />
                    <PanelBody>
                        <FormControls>
                            <Fields1 />
                            <Fields2 />
                        </FormControls>
                        <Fields3 />
                    </PanelBody>
                </Panel>
                <Panel mode="form">
                    <PanelHeader title="Información de Facturas" />
                    <PanelBody>
                        <FormControls>
                            <Fields4 />
                        </FormControls>
                    </PanelBody>
                </Panel>
                <Panel mode="form">
                    <PanelHeader
                        title="Productos de la recepción"
                        helper={<TooltipProducts />}
                    >
                        <Mount />
                    </PanelHeader>
                    <PanelBody>
                        <SelectOC />
                        <Table />
                    </PanelBody>
                </Panel>
            </div>
            <Header />
        </Initial>
    )
}
