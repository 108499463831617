import { GetProductPrices } from '../../types/prices'
import ControlList from '../../utilities/Control/ControlList'

export const productPriceList = new ControlList<GetProductPrices>(
    'ProductPriceList',
    {
        path: '/v5.6/apis/product_companies/sale_prices',
        theads: [
            '#',
            {
                label: 'Código de producto',
                value: 'product_code'
            },
            {
                label: 'Descripción de producto',
                value: 'product_name'
            },
            {
                label: 'Compañía',
                value: 'company_name'
            },
            'Precio',
            {
                label: 'Activo para venta',
                value: 'can_sell'
            },
            'Acciones'
        ]
    }
)
