import {
    FormControl,
    FormControls,
    IterateOptions
} from '../../../components/FormUtilities'
import ReadOnly from '../../../components/ReadOnly'
import SearchResults from '../../../components/SearchResult'
import { Option } from '../../../config/types/component'
import { storagePage, formProductLicense, tableProducts } from '../storage'
import { ProductData } from '../types'
import setParamsSearch from '../utilities/setParamsSearch'

function License() {
    return (
        <FormControl label="Licencia">
            <ReadOnly value={storagePage.license?.name || ''} />
        </FormControl>
    )
}

function Company() {
    return (
        <FormControl label="Compañía">
            <ReadOnly
                value={storagePage.license?.company.name || ''}
                image={storagePage.license?.company.country.image_url || ''}
            />
        </FormControl>
    )
}

function SearchProducts() {
    const { props, data } = storagePage.products.observer(
        formProductLicense.useResults<ProductData>(
            'products',
            () => '/v5.6/apis/products'
        ),
        'search_result'
    )

    function handleSelect(options: Option[]) {
        props.onSelect(options)
        tableProducts.setList(
            storagePage.products.getCurrents().map(product => ({
                product_license_id:
                    storagePage.license.product_licenses
                        .filter(product_license => product_license.memory)
                        .find(
                            product_licenses =>
                                product_licenses.product_id === product.id
                        )?.id || undefined,
                id: product.id,
                product_name: product.name,
                brand_name: product.brand.name,
                subcategory_name: product.subcategory.name,
                is_enabled: true
            }))
        )
        setParamsSearch()
        tableProducts.renderMain()
    }

    return (
        <FormControl label="Búsqueda de productos" col="span-6">
            <SearchResults
                {...props}
                onSelect={handleSelect}
                placeholder="Buscar por código o nombre del producto"
            >
                <IterateOptions data={data} label2="code" label="name" />
            </SearchResults>
        </FormControl>
    )
}

export default function Fields() {
    return (
        <FormControls>
            <License />
            <Company />
            <SearchProducts />
        </FormControls>
    )
}
