import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import { formInformation } from '../CostEstimate/storage'
import { controlList, storagePage } from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)

    useEffect(() => {
        controlList.filter.changeParams('sort', 'id')
        const company = getCompanySelected()

        const option = {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url,
            span: company.local_currency.symbol
        }
        storagePage.company_enabled = company.is_enabled

        controlList.filter.setOption('company_id', option)
        formInformation.setValue('company', option)
        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })
    }, [])

    return { state }
}
