import {
    reqLogisticalCost3,
    formCostGeneral,
    reqLogisticalCost4
} from '../storageStep3'
import Form from '.'
import modal from '../../../../../sections/modal'
import Aditional from '../aditional'
import { get } from '../../../../../utilities/http'
import FileModel from '../../../../../models/FileModel'
import { loadForm, parseBodyCreation, parseBodyEdition } from './utilities'
import { render } from 'redity'
import { Keys } from '../../../constants'
import { reqPCD } from '../../SectionCostDistribution/storageStep4'
import { reqRC2 } from '../../SectionSettings/storage'

export default function useSubmitCostGeneral(
    type: 'national' | 'international',
    logistical_cost_id?: number
) {
    const { submit, validation, observer } = formCostGeneral.useSubmit(
        logistical_cost_id
            ? `/v5.6/apis/logistical_costs/general/${logistical_cost_id}`
            : '/v5.6/apis/logistical_costs/general',
        {
            method: logistical_cost_id ? 'put' : 'post',
            done: async () => {
                await Promise.all([
                    reqLogisticalCost3.load(),
                    reqLogisticalCost4.load()
                ])
                await reqRC2.load()
                reqPCD.load()
                render(Keys.TOTAL_COST)
            }
        }
    )

    let currentFiles: FileModel[] = []

    validation(v => {
        v.logistical_concept.isEmpty('Se requiere seleccionar el concepto')
        v.currency.isEmpty('Se requiere seleccionar la moneda')
        v.rcs.isEmpty(
            'Se requiere seleccionar al menos una recepción de compra'
        )
        v.original_cost
            .isMinor(0.0, 'El valor mínimo es 0')
            .isEmpty('Se requiere ingresar costo original')
        v.exchange_to_local
            .isMinor(0.01, 'El valor mínimo es 0.01')
            .isEmpty('Se requiere ingresar el cambio')
    })

    observer<Aditional.BodyLCGeneralCreate | Aditional.BodyLCGeneralUpdate>(f =>
        logistical_cost_id
            ? parseBodyEdition(f, currentFiles)
            : parseBodyCreation(f, type)
    )

    return function openModal() {
        modal(
            Form,
            async () => {
                if (logistical_cost_id) {
                    const { data } = await get<Aditional.ResponseLCGeneral>(
                        `/v5.6/apis/logistical_costs/general/${logistical_cost_id}`
                    )

                    currentFiles = data.result.files
                    loadForm(data.result)
                } else {
                    formCostGeneral.init()
                }

                return {
                    title: logistical_cost_id
                        ? 'Editar costo general'
                        : 'Agregar costo general',
                    onSubmit: submit
                }
            },
            'lg'
        )
    }
}
