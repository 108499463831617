import { useEffect } from 'react'
import { render } from 'redity'
import {
    FormControl,
    IterateOptions
} from '../../../../../components/FormUtilities'
import InputField from '../../../../../components/InputField'
import SelectTouch from '../../../../../components/SelectTouch'
import { Option } from '../../../../../config/types/component'
import useGet from '../../../../../hooks/useGet'
import useStorage from '../../../../../hooks/useStorage'
import Address from '../../../../../models/Address'
import Warehouse from '../../../../../models/Warehouse'
import { GetAgents } from '../../../../../types/agent'
import {
    formDispatch,
    reqAgent,
    reqWarehouse,
    storagePage
} from '../../../storage'

export default function PlaceDispatchFields() {
    const { props } = formDispatch.useSelect('place_dispatch')
    const { props: propsInput } = formDispatch.useInput('place_dispatch_text')

    const value = formDispatch.store().type_dispatch?.value || null

    if (value === '1') {
        return <AgentField props={props} />
    }
    if (value === '2') {
        return <WarehouseField props={props} />
    }
    if (value === '3') {
        return <CompanyField props={props} />
    }
    return <OtherField props={propsInput} />
}

export function AgentField({ props }) {
    const { data, reload, loading } = useGet<GetAgents>(
        reqAgent.observer(`/v5.6/apis/agents`, true),
        []
    )

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value === '0') {
            formDispatch.setProps('address_select', { disabled: true }, false)
            storagePage.currentAgentWithdrawalAddresses = []
        } else {
            storagePage.currentAgentWithdrawalAddresses =
                data.result.find(agent => agent.id.toString() === option.value)
                    ?.agent_withdrawal_addresses || []
            formDispatch.setProps('address_select', { disabled: false }, false)
        }
        formDispatch.setValue('address_select', null)
    }

    useEffect(() => {
        if (props.option === null) return
        reload().then(res => {
            storagePage.currentAgentWithdrawalAddresses =
                res.data.result.find(
                    agent => agent.id.toString() === props.option.value
                )?.agent_withdrawal_addresses || []

            render(formDispatch.keyRender, 'address_select')
        })
    }, [])

    return (
        <FormControl label="Lugar de despacho" required className="mb-2">
            <SelectTouch
                {...props}
                loading={loading}
                onChange={handleChange}
                placeholder="Seleccionar un agente"
                label="Tipo de despacho"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export function CompanyField({ props }) {
    const { companiesFromSession } = useStorage()

    const companies = companiesFromSession.filter(
        company =>
            company.country_id.toString() ===
            formDispatch.store().country?.value
    )

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value === '0') {
            formDispatch.setValue('address', '')
        } else {
            formDispatch.setValue(
                'address',
                companies.find(
                    company => company.id.toString() === option.value
                )?.address || ''
            )
        }
    }

    return (
        <FormControl label="Lugar de despacho" required className="mb-2">
            <SelectTouch
                {...props}
                onChange={handleChange}
                placeholder="Seleccionar una compañía"
                label="Tipo de despacho"
            >
                <IterateOptions data={companies} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export function WarehouseField({ props }) {
    const { data, loading } = useGet<Array<Warehouse & { address: Address }>>(
        reqWarehouse.observer(
            '/v5.6/apis/warehouses/:country_id?is_enabled=true',
            true
        ),
        []
    )

    function handleChange(option: Option) {
        props.onChange(option)
        if (option.value === '0') {
            formDispatch.setValue('address', '')
        } else {
            formDispatch.setValue(
                'address',
                data.result.find(
                    warehouse => warehouse.id.toString() === option.value
                )?.address?.address || ''
            )
        }
    }

    return (
        <FormControl label="Lugar de despacho" required className="mb-2">
            <SelectTouch
                {...props}
                loading={loading}
                onChange={handleChange}
                placeholder="Seleccionar un almacén"
                label="Tipo de despacho"
            >
                <IterateOptions data={data.result} label="name" />
            </SelectTouch>
        </FormControl>
    )
}

export function OtherField({ props }) {
    return (
        <FormControl label="Lugar de despacho" required className="mb-2">
            <InputField
                disabled
                placeholder="Ingresar lugar de despacho"
                {...props}
            />
        </FormControl>
    )
}
