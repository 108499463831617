import moment from 'moment'
import { Option } from '../../../../config/types/component'
import useStorage from '../../../../hooks/useStorage'
import { setCompanySelected } from '../../../../utilities/controlStorage'
import { storagePage } from '../../storage'
import { formGeneral, reqWorkingPeriod } from './storageStep1'

export default function useCompany() {
    const { companiesFromSession } = useStorage()
    const { props } = formGeneral.useSelect('company')

    function handleChange(option: Option) {
        props.onChange(option)
        reqWorkingPeriod.setQuery('company_id', option.value)
        reqWorkingPeriod.load()
        formGeneral.setProps(
            'working_period',
            {
                disabled: option.value === '0'
            },
            false
        )

        const company = companiesFromSession.find(
            _company => _company.id.toString() === option.value
        )

        storagePage.currentCurrency = company.local_currency
        storagePage.timezone = company.timezone
        setCompanySelected(company)

        formGeneral.setValue('working_period', null)
        const date = moment().tz(company.timezone).toISOString(true)
        formGeneral.setProps(
            'declaration_date',
            {
                maxDate: date
            },
            false
        )
        formGeneral.setValue('declaration_date', date)
    }

    return {
        props,
        handleChange,
        companies: companiesFromSession.filter(company => company.memory)
    }
}
