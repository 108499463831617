import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { getCompanySelected } from '../../utilities/controlStorage'
import { controlList } from './storage'

export default function useInitial() {
    const [state, setState] = useState(StateCase.LOADING)

    useEffect(() => {
        controlList.filter.changeParams('sort', 'product_code')
        const company = getCompanySelected()

        controlList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })

        controlList.done().then(result => {
            if (result) {
                setState(StateCase.SUCCESS)
            } else {
                setState(StateCase.ERROR)
            }
        })
    }, [])

    return state
}
