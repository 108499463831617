import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { GetPurchase } from '../../../../types/purchase'
import moment from 'moment'
import useStorage from '../../../../hooks/useStorage'

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoLine: {
        width: '100%',
        fontSize: '10px',
        textAlign: 'left',
        marginBottom: '5px'
    },
    infoLine2: {
        width: '100%',
        fontSize: '8px',
        textAlign: 'left',
        marginBottom: '5px'
    },
    subrow: {
        width: '50%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    grouprow: {
        width: '50%',
        flexDirection: 'column'
    },
    header: {
        width: '50%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    header2: {
        width: '100%',
        fontSize: '10px',
        fontFamily: 'univers-bold',
        backgroundColor: '#F6F6F6',
        textAlign: 'center',
        padding: '5px'
    },
    item: {
        width: '50%',
        fontSize: '10px',
        padding: '5px 10px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textTransform: 'uppercase'
    },
    item2: {
        width: '100%',
        fontSize: '10px',
        padding: '5px',
        height: '100%',
        textAlign: 'center',
        borderLeft: '1px solid #E2E2E2',
        borderRight: '1px solid #E2E2E2',
        textTransform: 'uppercase'
    }
})

export default function Table1({ data }: { data: GetPurchase }) {
    const { countries } = useStorage()

    const getCountry = (country_id: number) =>
        countries.find(country => country.id === country_id)
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.header}>PARA/TO</Text>
                <Text style={styles.header}>DE/FROM</Text>
            </View>
            <View style={styles.row}>
                <View style={styles.item}>
                    <Text style={styles.infoLine}>
                        {data.provider_company.provider.business_name}
                    </Text>
                    <Text style={styles.infoLine}>
                        {
                            getCountry(
                                data.provider_company.provider.country_id
                            ).name
                        }
                    </Text>
                </View>
                <View style={styles.item}>
                    <Text style={styles.infoLine}>
                        {data.invoicing_company.name}
                    </Text>
                    <Text style={styles.infoLine2}>
                        {data.invoicing_company.address}
                    </Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.subrow}>
                    <Text style={styles.header}>FECHA OC/P.O DATE</Text>
                    <Text style={styles.header}>CONDICIÓN DE PAGO</Text>
                </View>
                <Text style={styles.header}>
                    DIRECCIÓN DE ENTREGA/DELIVERY ADDRESS
                </Text>
            </View>
            <View style={styles.row}>
                <View style={styles.grouprow}>
                    <View style={styles.row}>
                        <Text style={styles.item}>
                            {moment(data.document.decision_date)
                                .tz(data.invoicing_company.timezone)
                                .format('DD/MM/YYYY')}
                        </Text>
                        <Text style={styles.item}>
                            {data.payment_condition_description
                                ? data.payment_condition_description
                                : '-'}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.header2}>MEDIO DE ENVIO/VÍA</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.item2}>
                            {data.shipping_method_purchase.name}
                        </Text>
                    </View>
                </View>
                <View style={styles.item}>
                    <Text style={styles.infoLine}>
                        {data.relation_address.agent?.name}
                        {data.relation_address.company?.name}
                        {data.relation_address.warehouse?.name}
                    </Text>
                    <Text style={styles.infoLine2}>
                        {data.relation_address.address}
                    </Text>
                </View>
            </View>
        </>
    )
}
