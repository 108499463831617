import classNames from 'classnames'
import { PUBLIC_URL } from '../../config/credentials'
import useOut from '../../hooks/useOut'
import Icon from '../Icon'
import { IconName } from '../Icon/types'

interface PropsPerfilItem {
    title: string
    icon: IconName
    onClick?: (ev: any) => void
}

function PerfilItem({ title, icon, onClick = () => null }: PropsPerfilItem) {
    return (
        <div
            className="px-4 rounded-lg hover:bg-base-200 cursor-pointer transition delay-75 w-full"
            onClick={onClick}
        >
            <div className="flex justify-between items-center py-3 text-gray-600">
                <div className="text-xs">
                    <p>{title}</p>
                </div>
                <Icon name={icon} cursor="pointer" />
            </div>
        </div>
    )
}

export default function PerfilBox({ component: Component }) {
    const { ref, isOpen, setOpen } = useOut([])
    function handleClick() {
        setOpen(!isOpen)
    }

    function handleLogout() {
        localStorage.clear()
        location.replace(PUBLIC_URL)
    }
    return (
        <div className="relative z-30" ref={ref}>
            <div
                className="flex items-center border-gray-300 cursor-pointer"
                onClick={handleClick}
            >
                {Component}
            </div>
            <div
                className={classNames(
                    'absolute right-0 bg-white w-56 rounded-lg shadow-lg transition-all delay-100 ease-linear',
                    {
                        'invisible opacity-0': !isOpen,
                        'opacity-100 visible shadow-lg block': isOpen
                    }
                )}
                style={{ top: '3.1rem' }}
            >
                <div className="flex justify-between items-start rounded-lg flex-col">
                    <PerfilItem title="Perfil de usuario" icon="user" />
                    <PerfilItem
                        title="Cerrar sesión"
                        icon="logout"
                        onClick={handleLogout}
                    />
                </div>
            </div>
        </div>
    )
}
