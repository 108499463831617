import {
    formCostByProduct,
    reqLDCostDistribution2,
    reqLogisticalCost3,
    reqLogisticalCost4,
    tableCostByProductControl
} from '../storageStep3'
import Form from '.'
import { loadForm, parseBodyCreation, parseBodyEdition } from './utilities'
import FileModel from '../../../../../models/FileModel'
import modal from '../../../../../sections/modal'
import { get } from '../../../../../utilities/http'
import Aditional from '../aditional'
import { render } from 'redity'
import { Keys } from '../../../constants'
import { reqPCD } from '../../SectionCostDistribution/storageStep4'
import { reqRC2 } from '../../SectionSettings/storage'

export default function useSubmitCostByProduct(
    type: 'national' | 'international',
    logistical_cost_id?: number
) {
    const { submit, validation, observer } = formCostByProduct.useSubmit(
        logistical_cost_id
            ? `/v5.6/apis/logistical_costs/specific/${logistical_cost_id}`
            : '/v5.6/apis/logistical_costs/specific',
        {
            method: logistical_cost_id ? 'put' : 'post',
            done: async () => {
                await Promise.all([
                    reqLogisticalCost3.load(),
                    reqLogisticalCost4.load()
                ])
                await reqRC2.load()
                reqPCD.load()
                render(Keys.TOTAL_COST)
            }
        }
    )

    let currentFiles: FileModel[] = []

    validation(v => {
        v.logistical_concept.isEmpty('Se requiere seleccionar el concepto')
        v.currency.isEmpty('Se requiere seleccionar la moneda')
        v.exchange_to_local.isEmpty('Se requiere ingresar el nombre')
        v.rcs.isEmpty(
            'Se requiere seleccionar al menos una recepción de compra'
        )
        return tableCostByProductControl.test(_v => {
            _v.original_cost.isEmpty('Se requiere ingresar el costo')
        })
    })

    observer(f =>
        logistical_cost_id
            ? parseBodyEdition(f, currentFiles)
            : parseBodyCreation(f, type)
    )

    return function openModal() {
        modal(
            Form,
            async () => {
                tableCostByProductControl.setList([])
                if (logistical_cost_id) {
                    const { data } = await get<Aditional.ResponseLCSpecific>(
                        `/v5.6/apis/logistical_costs/specific/${logistical_cost_id}`
                    )
                    currentFiles = data.result.files
                    loadForm(data.result)
                    reqLDCostDistribution2.setParam(
                        'logistical_declaration_id',
                        data.result.logistical_declaration_id
                    )
                } else {
                    formCostByProduct.init({
                        logistical_cost_id: null,
                        agent: null,
                        document_number: '',
                        logistical_concept: null,
                        currency: null,
                        cost: '0.00',
                        exchange_to_local: '1.00',
                        total_cost: '0.00',
                        rcs: [],
                        files: [],
                        productIds: []
                    })
                }

                return {
                    title: logistical_cost_id
                        ? 'Editar costo por producto'
                        : 'Agregar costo por producto',
                    onSubmit: submit
                }
            },
            'md'
        )
    }
}
