import moment from 'moment'
import 'moment/locale/es'
import Badge from '../../../components/Badge'
import DataTable, { TBody, THead } from '../../../components/DataTable'
import Typography from '../../../components/Typography'
import useGet from '../../../hooks/useGet'
import buildNumberFormat from '../../../utilities/buildNumberFormat'
import { controlList, storagePage, filterForm } from '../storage'
import { GeneralInformationByProduct, BalanceHistoryResponse } from '../types'
import { getMinimalStowageName } from '../utilities/getMinimalStowageName'

export default function InfoView({
    data,
    product_id
}: {
    data: BalanceHistoryResponse[]
    product_id: number
}) {
    const { data: resp } = useGet<GeneralInformationByProduct>(
        `/v5.6/apis/balance_histories/general_information/${product_id}?date=${moment(
            filterForm.store().date
        ).format('YYYY-MM-DD')}&company_id=${
            controlList.filter.params.company_id
        }`
    )
    const { total_balance, average_unit_cost, current_date } =
        resp?.result || {}

    moment.locale('es')
    const date = moment(current_date).format('LL')

    const sym = storagePage.currency.symbol

    return (
        <>
            <Typography type="header-3" className="mb-4">
                Listado de saldos
            </Typography>
            <div className="w-full stats mb-4 border-2">
                <div className="stat place-items-center place-content-center">
                    <div className="stat-title text-sm">Saldo total</div>
                    <div className="stat-value text-2xl">
                        {sym}{' '}
                        {total_balance ? buildNumberFormat(total_balance) : '-'}
                    </div>
                </div>
                <div className="stat place-items-center place-content-center">
                    <div className="stat-title text-sm">Costo Promedio</div>
                    <div className="stat-value text-2xl">
                        {sym}{' '}
                        {average_unit_cost
                            ? buildNumberFormat(average_unit_cost)
                            : '-'}
                    </div>
                </div>
                <div className="stat place-items-center place-content-center">
                    <div className="stat-title text-sm">
                        Inventario hasta el día
                    </div>
                    <div className="stat-desc">
                        <Badge color="info">{date}</Badge>
                    </div>
                </div>
            </div>
            <DataTable scrollHeight={500}>
                <THead>
                    <tr>
                        <th>#</th>
                        <th style={{ whiteSpace: 'normal' }}>Bodega</th>
                        <th style={{ whiteSpace: 'normal' }}>Cantidad</th>
                        <th style={{ whiteSpace: 'normal' }}>Saldo</th>
                        <th style={{ whiteSpace: 'normal' }}>Disponible</th>
                        <th style={{ whiteSpace: 'normal' }}>Bloqueado</th>
                    </tr>
                </THead>
                <TBody>
                    {data
                        .filter(
                            x => x.last_operation && x.last_operation.total > 0
                        )
                        .map((d, i) => (
                            <tr key={d.id}>
                                <td>{i + 1}</td>
                                <td>
                                    {getMinimalStowageName(d.minimal_stowage)}
                                </td>
                                <td>{d.last_operation?.total || 0}</td>
                                <td>
                                    {sym}{' '}
                                    {buildNumberFormat(
                                        average_unit_cost *
                                            (d.last_operation?.total || 0)
                                    )}
                                </td>
                                <td>
                                    {d.last_operation?.quantity_available || 0}
                                </td>
                                <td>
                                    {d.last_operation?.quantity_locked || 0}
                                </td>
                            </tr>
                        ))}
                </TBody>
            </DataTable>
        </>
    )
}
