import { useEffect, useState } from 'react'
import { StateCase } from '../../config/enums'
import { productPriceList } from './storage'
import { getCompanySelected } from '../../utilities/controlStorage'

export default function useInitial() {
    const [pageState, setPageState] = useState(StateCase.LOADING)

    useEffect(() => {
        productPriceList.filter.changeParams('sort', 'product_code')
        const company = getCompanySelected()

        productPriceList.filter.setOption('company_id', {
            value: company.id.toString(),
            label: company.name,
            image: company.country.image_url
        })

        productPriceList.done().then(result => {
            if (result) {
                setPageState(StateCase.SUCCESS)
            } else {
                setPageState(StateCase.ERROR)
            }
        })
    }, [])

    return pageState
}
