function Configuration(props) {
    return (
        <svg
            width={20}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m11.313 8.258 7.567 7.567a.815.815 0 0 1 0 1.175l-1.917 1.916a.83.83 0 0 1-1.175 0l-7.591-7.591a5.42 5.42 0 0 1-5.742-1.234C.538 8.183.363 5.191 1.913 3.075l3.192 3.191 1.183-1.175-3.2-3.208a5.417 5.417 0 0 1 7.025.542 5.421 5.421 0 0 1 1.2 5.833ZM8.496 9.275l7.884 7.883.733-.742-7.875-7.875a3.624 3.624 0 0 0 .733-1.633 3.724 3.724 0 0 0-1.033-3.3 3.757 3.757 0 0 0-2.867-1.092l2.575 2.575-3.533 3.534L2.538 6.05a3.723 3.723 0 0 0 1.1 2.866A3.727 3.727 0 0 0 6.83 9.958a3.643 3.643 0 0 0 1.666-.683Z"
            />
        </svg>
    )
}

export default Configuration
