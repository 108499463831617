import Checkbox from '../../../../../components/Checkbox'
import {
    ControlDivider,
    FormControl,
    LabelInline
} from '../../../../../components/FormUtilities'
import { formDutyISCIVA, tableRCsControl } from '../storageStep2'

export default function Fields2() {
    const { props } = formDutyISCIVA.useValue('rcs')
    const trcs = tableRCsControl.getDataList()

    return (
        <>
            <FormControl label="Aplicar a RC's" col="span-6" className="mb-4">
                <ControlDivider divider="3">
                    {trcs.map(trc => (
                        <RC
                            key={trc.reception_id}
                            label={trc.rc}
                            checked={
                                !!props.value.find(
                                    v => v.value === trc.reception_id.toString()
                                )
                            }
                        />
                    ))}
                </ControlDivider>
                {trcs.length === 0 && (
                    <span className="text-2xs text-gray-400">
                        Sin registros
                    </span>
                )}
            </FormControl>
        </>
    )
}

function RC({ label, checked }: { label: string; checked: boolean }) {
    return (
        <LabelInline label={label}>
            <Checkbox checked={checked} disabled />
        </LabelInline>
    )
}
