import { formAddress, tableAddress } from '../storage'

export function addAddress() {
    const { country, address, locate } = formAddress.store()
    const hasMain = !!tableAddress
        .getDataList()
        .find(d => d.is_main && d.country_id === parseInt(country.value))
    tableAddress.push({
        country_id: parseInt(country.value),
        country_name: country.label,
        country_image: country.image,
        address: address.trim(),
        locate: locate.trim(),
        is_main: !hasMain
    })
}

export function updateAddress(index: number) {
    const { country, address, locate } = formAddress.store()
    const hasMain = !!tableAddress
        .getDataList()
        .find(d => d.is_main && d.country_id === parseInt(country.value))
    tableAddress.put(index, {
        country_id: parseInt(country.value),
        country_name: country.label,
        country_image: country.image,
        address: address.trim(),
        locate: locate.trim(),
        is_main: !hasMain
    })
}
