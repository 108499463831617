import { memo, ReactNode } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import SubItem, { BlockSubItem } from './SubItem'
import { ItemRoute } from '../../../config/routes/types'
interface PropsItem {
    children: ReactNode
    subitems: Array<ItemRoute>
    path: string
    hasComponent: boolean
}

function Item({ children, path, subitems, hasComponent }: PropsItem) {
    let hasSubitem = false
    if (subitems) {
        for (const sub of subitems) {
            if (sub.label) {
                hasSubitem = true
                break
            }
        }
    }

    if (!hasSubitem && !hasComponent) return null

    const math = useRouteMatch({
        path,
        exact: !hasSubitem
    })

    return (
        <li>
            {!hasSubitem && (
                <Link
                    to={path}
                    className={classNames({
                        'cursor-default': math
                    })}
                >
                    <div
                        className={classNames('sidebar-menu-item', {
                            active: math
                        })}
                    >
                        <div
                            className={classNames('sidebar-menu-label', {
                                'bg-base-200 font-bold': math
                            })}
                        >
                            {children}
                        </div>
                    </div>
                </Link>
            )}
            {hasSubitem && (
                <BlockSubItem label={children}>
                    {subitems.map((sub, i) =>
                        sub.label ? (
                            <SubItem key={i} to={sub.path}>
                                {sub.label}
                            </SubItem>
                        ) : null
                    )}
                </BlockSubItem>
            )}
        </li>
    )
}

export default memo(Item)
