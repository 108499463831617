import Button from '../../../../components/Button'
import { FormControls } from '../../../../components/FormUtilities'
import Icon from '../../../../components/Icon'
import Panel, { PanelBody, PanelHeader } from '../../../../components/Panel'
import Tooltip from '../../../../components/Tooltip'
import { storagePage, tableContactsControl } from '../../storage'
import Fields1 from './Fields1'
import Fields2 from './Fields2'
import TableContacts from './TableContacts'
import useContact from './useContact'
import useStep1Submit from './useStep1Submit'

function TooltipContacts() {
    const { message } = tableContactsControl.useHelpers()
    return (
        <>
            {message && (
                <Tooltip content={message}>
                    <Icon name="warning" className="text-red-500" />
                </Tooltip>
            )}
        </>
    )
}

export default function Step1() {
    const open = useContact()
    const submit = useStep1Submit()
    const { mode } = storagePage

    return (
        <>
            <Panel mode="form">
                <PanelHeader title="Información de proveedor">
                    {mode === 'edition' && (
                        <Button size="sm" onClick={() => submit()}>
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <FormControls>
                        <Fields1 />
                        <Fields2 />
                    </FormControls>
                </PanelBody>
            </Panel>
            <Panel mode="form">
                <PanelHeader
                    title="Personal de contacto"
                    helper={<TooltipContacts />}
                >
                    <Button
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => open()}
                    >
                        + Agregar
                    </Button>
                    {mode === 'edition' && (
                        <Button
                            className="ml-2"
                            size="sm"
                            onClick={() => submit()}
                        >
                            Guardar
                        </Button>
                    )}
                </PanelHeader>
                <PanelBody>
                    <TableContacts />
                </PanelBody>
            </Panel>
        </>
    )
}
