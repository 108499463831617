import { FormEvent } from 'react'
import Modal from '../../../../sections/modal'
import { ModalTools } from '../../../../sections/ModalSection/types'
import { get } from '../../../../utilities/http'
import {
    formInformation,
    formProduct,
    storagePage,
    tableProductsControl
} from '../../storage'
import ProductForm from './ProductForm'
import { addProduct, updateProduct } from './../../utilities/crudProduct'

export default function useProduct(index?: number) {
    function handleSubmit(ev: FormEvent<HTMLFormElement>, modal: ModalTools) {
        ev.preventDefault()
        const result = formProduct.test(v => {
            v.product.isEmpty('Se requiere seleccionar un producto')
            v.quantity
                .isEmpty('Se requiere seleccionar una cantidad')
                .isMinor(1, 'La cantidad debe ser mayor a 0')
        })
        if (result) {
            modal.close()
            index !== undefined ? updateProduct(index) : addProduct()
            tableProductsControl.renderMain()
        }
    }

    return function openModal() {
        Modal(
            ProductForm,
            async () => {
                return onOpen(handleSubmit, index)
            },
            'md'
        )
    }
}

async function onOpen(
    submit: (ev?: FormEvent<HTMLFormElement>, modal?: ModalTools) => void,
    index?: number
) {
    formProduct.init()
    const productId = tableProductsControl.get(index)
        ? tableProductsControl.get(index).product_data.product_company
              .product_country.product.id
        : null

    if (productId) {
        storagePage.modeModal = 'edition'
        const provider_id = formInformation.store().provider.value
        const company_id = formInformation.store().catalogue_company.value

        const [resultProductCatalogue, resultInventory] = await Promise.all([
            get<any>(
                `/v5.6/apis/products/catalogue?provider_id=${provider_id}&company_id=${company_id}`
            ),
            get<any>(
                `/v5.6/apis/requisitions/products/inventory?product_id=${productId}&company_id=${company_id}`
            )
        ])

        if (!resultProductCatalogue.error && !resultInventory.error) {
            const product = resultProductCatalogue.data.result.find(
                item =>
                    item.product_company.product_country.product.id ===
                    productId
            )
            const quantity = tableProductsControl.get(index).quantity
            const product_data = tableProductsControl.get(index).product_data
            formProduct.setValue('product', product)
            formProduct.setValue('quantity', quantity)
            formProduct.setValue('inventory', resultInventory.data.result)
            formProduct.setValue('catalogue_products', {
                value: product_data.id.toString(),
                label: product_data.product_company.product_country.product
                    .description
            })
        }
    } else {
        storagePage.modeModal = 'creation'
    }

    return {
        title: productId ? 'Editar producto' : 'Agregar producto',
        onSubmit: submit
    }
}
