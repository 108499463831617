import { LogisticalDeclarationExcelData } from '../../../types'
import { Row } from './types'
import { addRowStyles, borderThin, COLOR } from './utils'

export function getRowCif(data: LogisticalDeclarationExcelData[]) {
    const row: Row[] = [
        { align: 'center', value: 'Valor CIF', fontWeight: 'bold' }
    ]
    data.forEach(d => {
        row.push({
            fontWeight: 'bold',
            value: d.data_summary.cif_original,
            format: '0.00'
        })
        row.push({
            value: ''
        })
        row.push({
            value: ''
        })
        row.push({
            align: 'center',
            fontWeight: 'bold',
            value: d.data_summary.cif_local,
            format: '0.00'
        })
        row.push({
            value: '',
            backgroundColor: COLOR.gray
        })
    })
    return addRowStyles(row, [borderThin])
}
